import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Input } from "rsuite";
import { RxCross2 } from "react-icons/rx";
import Pagination from "../../../../common/Pagination/Pagination";
import $ from "jquery";
import * as billingaction from "../../../../../action/BillingAnalysis/billing_analysis";

const BigBasketTab = ({
  cityList,
  errors,
  setErrors,
  disabled,
  setEditFormEditable,
  editButtonHide,
  submitButtonHide,
}) => {
  const dispatch = useDispatch();

  // big basket add
  const yearMonthBigBasketFocus = useRef();
  const cityBigBasketFocus = useRef();
  const revenueBigBasketFocus = useRef();
  const ordersBigBasketFocus = useRef();

  // big basket edit
  const edityearMonthBigBasketFocus = useRef();
  const editcityBigBasketFocus = useRef();
  const editrevenueBigBasketFocus = useRef();
  const editordersBigBasketFocus = useRef();

  const [bigBasketData, setBigBasketData] = useState({
    currentPage: 1,
    billingData: [],
    formId: "",
  });

  const [addBigBasketBillingData, setAddBigBasketBillingData] = useState({
    yearMonth: 0,
    city: "",
    revenue: 0,
    orders: 0,
  });

  const [editBigBasketBillingData, setEditBigBasketBillingData] = useState({
    yearMonth: 0,
    city: "",
    revenue: 0,
    orders: 0,
  });

  // bigBasket billing data
  useEffect(() => {
    dispatch(billingaction.get_bigbasket_billing())
      .then((data) => {
        let bigbasketbillingData = data.data;
        setBigBasketData({
          ...bigBasketData,
          billingData: bigbasketbillingData,
        });
      })
      .catch((err) => {
        toast.error(err);
      });
  }, []);
  // big basket add and update
  const onSubmitHandlerBigBasket = (e) => {
    e.preventDefault();
    setErrors({});
    let customErrors = {};

    if (!addBigBasketBillingData.yearMonth) {
      customErrors = {
        ...customErrors,
        yearMonth: "Please Select Year and Month",
      };
      yearMonthBigBasketFocus.current.focus();
    } else if (!addBigBasketBillingData.city) {
      customErrors = { ...customErrors, city: "Please Select city" };
      cityBigBasketFocus.current.focus();
    } else if (!addBigBasketBillingData.revenue) {
      customErrors = { ...customErrors, revenue: "Please Enter revenue" };
      revenueBigBasketFocus.current.focus();
    } else if (!addBigBasketBillingData.orders) {
      customErrors = { ...customErrors, orders: "Please Enter orders" };
      ordersBigBasketFocus.current.focus();
    }

    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }

    dispatch(billingaction.add_bigbasket_billing(addBigBasketBillingData))
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          $("#addExpenseFormBigBasket .close").click();
          let bigBasketBillingData = data.data;
          setBigBasketData({
            ...bigBasketData,
            billingData: bigBasketBillingData,
          });
          setEditFormEditable(false);
        } else if (!data.success) {
          toast.error(data.message);
        }
        setErrors({});
      })
      .catch((err) => {});

    setErrors({});

    setAddBigBasketBillingData({
      yearMonth: 0,
      city: "",
      revenue: 0,
      orders: 0,
    });
  };

  const onUpdateHandlerBigBasket = (e) => {
    e.preventDefault();
    setErrors({});
    let customErrors = {};

    if (!editBigBasketBillingData.yearMonth) {
      customErrors = {
        ...customErrors,
        yearMonth: "Please Select Year and Month",
      };
      edityearMonthBigBasketFocus.current.focus();
    } else if (!editBigBasketBillingData.city) {
      customErrors = { ...customErrors, city: "Please Select city" };
      editcityBigBasketFocus.current.focus();
    } else if (!editBigBasketBillingData.revenue) {
      customErrors = { ...customErrors, revenue: "Please Enter revenue" };
      editrevenueBigBasketFocus.current.focus();
    } else if (!editBigBasketBillingData.orders) {
      customErrors = { ...customErrors, orders: "Please Enter orders" };
      editordersBigBasketFocus.current.focus();
    }

    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }

    dispatch(
      billingaction.edit_bigbasket_billing(
        bigBasketData.formId,
        editBigBasketBillingData
      )
    )
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          $("#editExpenseFormBigBasket .close").click();
          let bigBasketBillingData = data.data;
          setBigBasketData({
            ...bigBasketData,
            billingData: bigBasketBillingData,
          });
          setEditFormEditable(false);
        } else if (!data.success) {
          toast.error(data.message);
        }
        setErrors({});
      })
      .catch((err) => {});

    setErrors({});
  };

  // big basket edit item
  const handleBigBasketEditClick = (item) => {
    setBigBasketData({ ...bigBasketData, formId: item._id });

    const formattedMonth =
      item.month < 10 && item.month > 0 ? `0${item.month}` : item.month;

    let yearMonth = `${item.year}-${formattedMonth}`;

    setEditBigBasketBillingData({
      yearMonth: yearMonth,
      city: item.city,
      revenue: item.revenue,
      orders: item.orders,
    });
  };

  const currentBigBasketTableData = useMemo(() => {
    const firstPageIndexBigBasket = (bigBasketData.currentPage - 1) * 10;
    const lastPageIndexBigBasket = firstPageIndexBigBasket + 10;
    return bigBasketData.billingData?.slice(
      firstPageIndexBigBasket,
      lastPageIndexBigBasket
    );
  }, [bigBasketData.currentPage, bigBasketData.billingData]);

  return (
    <>
      {" "}
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="" style={{ margin: "10px" }}>
          Big Basket Billing Analysis
        </h5>
        <a
          href="/"
          className="btn btn-warning btn-sm add-act-btn"
          data-toggle="modal"
          data-target="#addExpenseFormBigBasket"
        >
          Add
        </a>
      </div>
      <br />
      <div className="table-responsive mb-4 mt-4">
        {currentBigBasketTableData.length > 0 ? (
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Month</th>
                <th>Year</th>
                <th>Revenue</th>
                <th>Orders</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentBigBasketTableData.map((item) => {
                return (
                  <tr key={item._id}>
                    <td>{item.month}</td>
                    <td>{item.year}</td>
                    <td>{item.revenue}</td>
                    <td>{item.orders}</td>
                    <td>
                      <a
                        href="/"
                        onClick={() => handleBigBasketEditClick(item)}
                        className="btn btn-info btn-sm edit-btn edit-act-btn"
                        data-toggle="modal"
                        data-target="#editExpenseFormBigBasket"
                      >
                        Edit
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className="text-center">No data Available</p>
        )}
        {/* pagination */}
        <Pagination
          className="pagination-bar"
          currentPage={bigBasketData.currentPage}
          totalCount={bigBasketData.billingData.length}
          pageSize={10}
          onPageChange={(page) =>
            setBigBasketData({
              ...bigBasketData,
              currentPage: page,
            })
          }
        />
      </div>
      {/* BigBasket - add model */}
      <div
        className="modal fade"
        id="addExpenseFormBigBasket"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addExpenseFormTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <form onSubmit={onSubmitHandlerBigBasket}>
              <div className="modal-header">
                <h5 className="modal-title">Add BigBasket Billing</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <RxCross2 />
                </button>
              </div>
              <div className="modal-body">
                <div
                  className="customer-address-class"
                  id="customer-address-div-id"
                >
                  <div className="row" id="defaultaddressdiv0">
                    <div className="col-12">
                      <div id="append-div-id">
                        <div class="form-group row">
                          <div class="form-group col-6 required">
                            <label>Select Year and Month *</label>
                            <Input
                              type="month"
                              ref={yearMonthBigBasketFocus}
                              name="yearMonth"
                              value={addBigBasketBillingData?.yearMonth}
                              onChange={(value) =>
                                setAddBigBasketBillingData({
                                  ...addBigBasketBillingData,
                                  yearMonth: value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.yearMonth}
                            </span>
                          </div>
                          <div class="form-group col-md-6 required">
                            <label>Select City *</label>
                            <select
                              id="city"
                              ref={cityBigBasketFocus}
                              className="form-control selectpicker"
                              value={addBigBasketBillingData?.city}
                              onChange={(e) => {
                                setAddBigBasketBillingData({
                                  ...addBigBasketBillingData,
                                  city: e.target.value,
                                });
                              }}
                            >
                              <option value="">Select City</option>
                              {cityList.map((item) => {
                                return (
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            <span style={{ color: "red" }}>{errors?.city}</span>
                          </div>
                        </div>

                        <div class="form-group row">
                          <div class="form-group col-md-6 required">
                            <label>Revenue</label>
                            <input
                              type="number"
                              min="0"
                              name="revenue"
                              className="form-control"
                              ref={revenueBigBasketFocus}
                              placeholder="Enter Revenue"
                              value={addBigBasketBillingData?.revenue}
                              onChange={(e) =>
                                setAddBigBasketBillingData({
                                  ...addBigBasketBillingData,
                                  revenue: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.revenue}
                            </span>
                          </div>

                          <div class="form-group col-md-6 required">
                            <label>Orders</label>
                            <input
                              type="number"
                              min="0"
                              name="orders"
                              className="form-control"
                              ref={ordersBigBasketFocus}
                              placeholder="No of Orders"
                              value={addBigBasketBillingData?.orders}
                              onChange={(e) =>
                                setAddBigBasketBillingData({
                                  ...addBigBasketBillingData,
                                  orders: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.orders}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="reset"
                  data-dismiss="modal"
                  className="btn btn-danger "
                  onClick={() => setEditFormEditable(false)}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success save-btn">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* BigBasket - update model */}
      <div
        className="modal fade"
        id="editExpenseFormBigBasket"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editExpenseFormTitle"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <form onSubmit={onUpdateHandlerBigBasket}>
              <div className="modal-header">
                <h5 className="modal-title">BigBasket Billing</h5>
                <button
                  type="button"
                  className="btn btn-primary view-btn"
                  hidden={editButtonHide}
                  onClick={() => setEditFormEditable(true)}
                >
                  Edit
                </button>
              </div>

              <div className="modal-body">
                <div
                  className="customer-address-class"
                  id="customer-address-div-id"
                >
                  <div className="row" id="defaultaddressdiv0">
                    <div className="col-12">
                      <div id="append-div-id">
                        <div class="form-group row">
                          <div class="form-group col-6 required">
                            <label>Select Year and Month *</label>
                            <Input
                              disabled={disabled}
                              type="month"
                              ref={edityearMonthBigBasketFocus}
                              name="yearMonth"
                              value={editBigBasketBillingData?.yearMonth}
                              onChange={(value) =>
                                setEditBigBasketBillingData({
                                  ...editBigBasketBillingData,
                                  yearMonth: value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.yearMonth}
                            </span>
                          </div>
                          <div class="form-group col-md-6 required">
                            <label>Select City *</label>
                            <select
                              disabled={disabled}
                              id="city"
                              ref={editcityBigBasketFocus}
                              className="form-control selectpicker"
                              value={editBigBasketBillingData?.city}
                              onChange={(e) => {
                                setEditBigBasketBillingData({
                                  ...editBigBasketBillingData,
                                  city: e.target.value,
                                });
                              }}
                            >
                              <option value="">Select City</option>
                              {cityList.map((item) => {
                                return (
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            <span style={{ color: "red" }}>{errors?.city}</span>
                          </div>
                        </div>

                        <div class="form-group row">
                          <div class="form-group col-md-6 required">
                            <label>Revenue</label>
                            <input
                              disabled={disabled}
                              type="number"
                              min="0"
                              name="revenue"
                              className="form-control"
                              ref={editrevenueBigBasketFocus}
                              placeholder="Enter Revenue"
                              value={editBigBasketBillingData?.revenue}
                              onChange={(e) =>
                                setEditBigBasketBillingData({
                                  ...editBigBasketBillingData,
                                  revenue: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.revenue}
                            </span>
                          </div>

                          <div class="form-group col-md-6 required">
                            <label>Orders</label>
                            <input
                              disabled={disabled}
                              type="number"
                              min="0"
                              name="orders"
                              className="form-control"
                              ref={editordersBigBasketFocus}
                              placeholder="No of Orders"
                              value={editBigBasketBillingData?.orders}
                              onChange={(e) =>
                                setEditBigBasketBillingData({
                                  ...editBigBasketBillingData,
                                  orders: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.orders}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary view-btn"
                  hidden={submitButtonHide}
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default BigBasketTab;
