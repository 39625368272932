import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../DashBoard/Header";
import Sub_Header from "../../DashBoard/Sub_Header";
import SideBar from "../../DashBoard/SideBar";
import Footer from "../../DashBoard/Footer";
import Loader from "../../common/Loader/Loader";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import * as action from "../../../action/Dashboard/dashboard_action";
import * as Customeraction from "../../../action/Customer/customer_action";
import { DatePicker } from "rsuite";
// import {baseURL} from '../../../service/baseService';
const AddSalesPayout = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const monthFocus = useRef();
  const cityFocus = useRef();
  const yearMonthFocus = useRef();
  const yearFocus = useRef();
  const customerFocus = useRef();
  const riderFocus = useRef();
  const salesFocus = useRef();
  const payoutFocus = useRef();
  const vehicleFocus = useRef();
  const { loading } = useSelector(
    (state) => state.DashboardAddSalesPayoutReducer
  );
  const [input, setInput] = useState({
    year: "",
    month: "",
    city: "",
    customer: "",
    sales: "",
    payout: "",
    vehicle: "",
    rider: "",
    date: "",
  });
  const [cityList, setCityList] = useState([]);
  const [customerNameList, setCustomerNameList] = useState([]);
  const [values, setValues] = useState({
    city: "",
    customer: "",
  });
  useEffect(() => {
    dispatch(action.dashboard_city_list())
      .then((data) => {
        let newCustomerList = [];
        data.data.map((item) => {
          newCustomerList.push({ value: item._id, label: item.cityName });
        });
        setCityList(newCustomerList);
      })
      .catch({});
  }, {});
  useEffect(() => {
    dispatch(Customeraction.customer_list())
      .then((data) => {
        let newCustomerList = [];
        data.data.map((item) => {
          newCustomerList.push({ value: item._id, label: item.businessName });
        });
        setCustomerNameList(newCustomerList);
      })
      .catch({});
  }, []);
  const customFilter = (option, inputValue) => {
    const input = inputValue.toLowerCase();
    const filterText = new RegExp("^" + input + ".*");
    const label = option.data.label.toLowerCase();
    return filterText.test(label) && option.label.toLowerCase().includes(input);
  };
  const changeCityDropDown = (data) => {
    setValues({ ...values, city: data });
    setInput({ ...input, city: data.value });
  };
  const changeCustomerDropDown = (data) => {
    setValues({ ...values, customer: data });
    setInput({ ...input, customer: data.value });
  };
  var date = new Date();
  const [DatePick, setDatePick] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const getFormattedDate = (date) => {
    const cd = new Date(date);
    const year = cd.getFullYear();
    const month = String(cd.getMonth() + 1).padStart(2, "0");
    const day = String(cd.getDate()).padStart(2, "0");
    return year + "-" + month + "-" + day;
  };

  if (DatePick != null) {
    input.year = DatePick.getFullYear();
    input.month = DatePick.getMonth() + 1;
    input.date = getFormattedDate(
      new Date(
        new Date(DatePick.getFullYear(), DatePick.getMonth() + 1, 0).setHours(
          23,
          59,
          59,
          999
        )
      )
    );
  } else {
    input.year = "";
    input.month = "";
    input.date = "";
  }

  const onsubmitHandler = (e) => {
    e.preventDefault();
    setErrors({});
    let customErrors = {};
    if (input.date === "") {
      customErrors = { ...customErrors, date: "Please select year and month" };
      yearMonthFocus.current.focus();
    } else if (input.city === "") {
      customErrors = { ...customErrors, city: "Please select city" };
      cityFocus.current.focus();
    } else if (input.customer === "") {
      customErrors = { ...customErrors, customer: "Please select " };
      customerFocus.current.focus();
    } else if (input.sales === "") {
      customErrors = { ...customErrors, sales: "Please enter sales amount" };
      salesFocus.current.focus();
    } else if (input.payout === "") {
      customErrors = { ...customErrors, payout: "Please enter payout amount" };
      payoutFocus.current.focus();
    } else if (input.vehicle === "") {
      customErrors = { ...customErrors, vehicle: "Please enter no of vehicle" };
      vehicleFocus.current.focus();
    } else if (input.rider === "") {
      customErrors = { ...customErrors, rider: "Please enter no of rider" };
      riderFocus.current.focus();
    }
    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }
    let parameter = {
      year: input.year,
      month: input.month,
      city: input.city,
      customer: input.customer,
      sales: input.sales,
      payout: input.payout,
      vehicle: input.vehicle,
      rider: input.rider,
      date: input.date,
    };
    dispatch(action.dashboard_add_sales_payout(parameter))
      .then((data) => {
        if (data?.success) {
          toast.success(data.message);
          navigate("/adminside/sales-and-payout/view-sales-payout");
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Sub_Header />
      <div className="main-container" id="container">
        {loading ? <Loader /> : null}
        <div className="overlay"></div>
        <div className="search-overlay"></div>
        <SideBar />
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                <div className="widget-content widget-content-area br-6">
                  <h5 className="">Add Sales And Payout</h5>
                  <br />

                  <form onSubmit={onsubmitHandler}>
                    <div id="append-div-id">
                      <div class="form-group row">
                        <div class="form-group col-md-4 required">
                          <label for="inputFullname">
                            Select Year and Month *
                          </label>
                          <div ref={yearMonthFocus}>
                            <DatePicker
                              format="yyyy-MM"
                              ranges={[]}
                              value={DatePick}
                              onChange={(value) => setDatePick(value)}
                              style={{ width: 500 }}
                              placeholder="Select Month and Year"
                            />
                          </div>
                          <span style={{ color: "red" }}>{errors?.date}</span>
                        </div>
                        <div class="form-group col-md-4 required">
                          <label for="inputFullname">Select City *</label>
                          <Select
                            id="inputCustomer"
                            ref={cityFocus}
                            value={values.city || "Select"}
                            onChange={(value) => changeCityDropDown(value)}
                            options={cityList}
                            filterOption={customFilter}
                          />
                          <span style={{ color: "red" }}>{errors?.city}</span>
                        </div>
                      </div>

                      <div class="form-group row">
                        <div class="form-group col-md-4 required">
                          <label for="inputPaymentType">
                            Select Customer *
                          </label>
                          <Select
                            id="inputCustomer"
                            ref={customerFocus}
                            value={values.customer || "Select"}
                            onChange={(value) => changeCustomerDropDown(value)}
                            options={customerNameList}
                            filterOption={customFilter}
                          />
                          <span style={{ color: "red" }}>
                            {errors?.customer}
                          </span>
                        </div>
                        <div class="form-group col-md-4 required">
                          <label for="inputMobile">Sales Amount *</label>
                          <label
                            class="form-error-msg"
                            id="mobileErrorMsg"
                          ></label>
                          <input
                            type="number"
                            min="0"
                            class="form-control"
                            ref={salesFocus}
                            id="inputMobile"
                            name="sales"
                            placeholder="Enter Sales Amount"
                            onChange={(e) =>
                              setInput({ ...input, sales: e.target.value })
                            }
                            value={input.sales}
                          />
                          <span style={{ color: "red" }}>{errors?.sales}</span>
                        </div>
                        <div class="form-group col-md-4 required">
                          <label for="inputMobile">Payout Amount *</label>
                          <label
                            class="form-error-msg"
                            id="mobileErrorMsg"
                          ></label>
                          <input
                            type="number"
                            min="0"
                            class="form-control"
                            ref={payoutFocus}
                            id="inputMobile"
                            name="payout"
                            placeholder="Enter Payout Amount"
                            onChange={(e) =>
                              setInput({ ...input, payout: e.target.value })
                            }
                            value={input.payout}
                          />
                          <span style={{ color: "red" }}>{errors?.payout}</span>
                        </div>
                      </div>

                      <div class="form-group row">
                        <div class="form-group col-md-6 required">
                          <label for="address">No Of Vehicle *</label>
                          <input
                            type="number"
                            min="0"
                            name="vehicle"
                            ref={vehicleFocus}
                            class="form-control"
                            id="inputaddress"
                            placeholder="Enter No of Vehicle"
                            onChange={(e) =>
                              setInput({ ...input, vehicle: e.target.value })
                            }
                            value={input.vehicle}
                          />
                          <span style={{ color: "red" }}>
                            {errors?.vehicle}
                          </span>
                        </div>
                        <div class="form-group col-md-6 required">
                          <label for="address">No Of Rider *</label>
                          <input
                            type="number"
                            min="0"
                            name="rider"
                            ref={riderFocus}
                            class="form-control"
                            id="inputaddress"
                            placeholder="Enter No of Rider"
                            onChange={(e) =>
                              setInput({ ...input, rider: e.target.value })
                            }
                            value={input.rider}
                          />
                          <span style={{ color: "red" }}>{errors?.rider}</span>
                        </div>
                      </div>
                    </div>
                    <button type="reset" className="btn btn-danger mt-3">
                      Reset
                    </button>
                    &nbsp;
                    <button
                      type="submit"
                      className="btn btn-success mt-3 view-btn"
                    >
                      Add
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </>
  );
};
export default AddSalesPayout;
