import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../DashBoard/Header";
import Sub_Header from "../../DashBoard/Sub_Header";
import SideBar from "../../DashBoard/SideBar";
import Footer from "../../DashBoard/Footer";
import Loader from "../../common/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import * as action from "../../../action/Vehicle/vehicle_action";
import * as login_action from "../../../action/Auth/login_action";
import * as customer_action from "../../../action/Customer/customer_action";
import * as common from "../../../action/common/common_action";
import { baseURL } from "../../../service/baseService";

const AddVehicle = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const nameFocus = useRef();
  const numberFocus = useRef();
  const typeFocus = useRef();
  const insuranceNoFocus = useRef();
  const batteryNoFocus = useRef();
  const secondBatteryNoFocus = useRef();
  const chasisNoFocus = useRef();
  const cityFocus = useRef();
  const customerFocus = useRef();
  const bikeTypeIdFocus = useRef();
  const rcBookFileFocus = useRef();
  const insuranceFileFocus = useRef();
  const srNumberFocus = useRef();
  const telematicsUnitFocus = useRef();
  const leaseCompanyIdFocus = useRef();
  const leaseCompanyBatchIdFocus = useRef();
  const insuranceExpiryDateFocus = useRef();
  const vehicleHubIdFocus = useRef();
  const deactivateReasonFocus = useRef();
  const deactivateReasonInputFocus = useRef();
  const rcBookNoFocus = useRef();
  const DocumentsFocus = useRef();
  const [errors, setErrors] = useState({});
  const { loading } = useSelector((state) => state.addVehicleReducer);
  const [modalImgURL, setModalImgURL] = useState("");
  const [otherDocumentRow, setOtherDocumentRow] = useState([]);
  const [telematicsUnitList, setTelematicsUnitList] = useState([]);
  const [leaseCompanyList, setLeaseCompanyList] = useState([]);
  const [leaseCompanyBatchList, setLeaseCompanyBatchList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [values, setValues] = useState({
    cityValue: "",
    customerValue: "",
  });

  const [hubList, setHubList] = useState([]);
  const [bikeTypeList, setBikeType] = useState([]);
  const [bikeTypeDetails, setBikeDetails] = useState({
    registered: "",
    isActive: "",
    _id: "",
    bikeName: "",
    noOfBattery: "",
  });
  const [input, setInput] = useState({
    number: "",
    name: "",
    type: "",
    details: "",
    ownerName: "EVIFY Logitech Pvt Ltd",
    rcBookFile: "",
    insuranceFile: "",
    permitFile: "",
    insuranceExpiryDate: "",
    permitExpiryDate: "",
    insuranceNo: "",
    batteryNo: "",
    chasisNo: "",
    tracknowurl: "",
    rcBookNo: "",
    city: "",
    registered: 1,
    owned: 1,
    isActive: 1,
    leaseCompanyId: "",
    leaseCompanyBatchId: "",
    telematicsUnit: "",
    sr_number: "",
    vehicleHubId: "",
    bikeTypeId: "",
    secondBatteryNo: "",
    deactivateReason: "",
    deactivateReasonInput: "",
    customerId: "",
  });
  const uploadRCBookFile = (e) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file, file.name);
    dispatch(action.vehicle_file_upload(formData))
      .then((data) => {
        setInput({ ...input, rcBookFile: data.data.url });
      })
      .catch({});
  };
  const uploadInsuranceFile = (e) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file, file.name);
    dispatch(action.vehicle_file_upload(formData))
      .then((data) => {
        setInput({ ...input, insuranceFile: data.data.url });
      })
      .catch({});
  };
  const uploadPermitFile = (e) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file, file.name);
    dispatch(action.vehicle_file_upload(formData))
      .then((data) => {
        setInput({ ...input, permitFile: data.data.url });
      })
      .catch({});
  };
  const uploadOtherDocFile = (e, otherDocId) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file, file.name);
    dispatch(action.vehicle_file_upload(formData))
      .then((data) => {
        let oldotherDocument = otherDocumentRow.filter(
          (item) => item.id === otherDocId
        )?.[0];
        oldotherDocument = { ...oldotherDocument, docUrl: data.data.url };
        let newOtherDocument = [];
        otherDocumentRow.map((item) => {
          if (item.id === otherDocId) {
            newOtherDocument.push(oldotherDocument);
          } else newOtherDocument.push(item);
        });
        setOtherDocumentRow(newOtherDocument);
      })
      .catch({});
  };
  useEffect(() => {
    // city list
    dispatch(common.common_city_list())
      .then((data) => {
        let newCustomerList = [];
        data.data.map((item) => {
          newCustomerList.push({ value: item.cityId, label: item.cityName });
        });
        setCityList(newCustomerList);
      })
      .catch({});

    dispatch(customer_action.customer_list())
      .then((data) => {
        let newCustomerList = [];
        data.data.map((item) => {
          newCustomerList.push({
            value: item._id,
            label: item.businessName + " | " + item.name,
          });
        });
        setCustomerList(newCustomerList);
      })
      .catch({});
  }, []);
  const changeCityDropDown = (data) => {
    setValues({ ...values, cityValue: data });
    setInput({ ...input, city: data.value });
  };
  const changeCustomerDropDown = (data) => {
    setValues({ ...values, customerValue: data });
    setInput({ ...input, customerId: data.value });
  };
  useEffect(() => {
    dispatch(action.vehicle_telematics_unit_list_select())
      .then((data) => {
        setTelematicsUnitList(data.data);
      })
      .catch({});
  }, []);
  useEffect(() => {
    dispatch(action.vehicle_lease_company_list_select())
      .then((data) => {
        setLeaseCompanyList(data.data);
      })
      .catch({});
  }, []);
  useEffect(() => {
    dispatch(action.vehicle_hub_list_select())
      .then((data) => {
        setHubList(data.data);
      })
      .catch({});
  }, []);
  useEffect(() => {
    dispatch(action.vehicle_bike_type_select())
      .then((data) => {
        setBikeType(data.data);
      })
      .catch({});
  }, []);
  const addExtraDocumentsInForm = () => {
    if (otherDocumentRow.length > 0) {
      setOtherDocumentRow([
        ...otherDocumentRow,
        { id: otherDocumentRow.length + 1, docName: "", docUrl: "" },
      ]);
    } else {
      setOtherDocumentRow([{ id: 1, docName: "", docUrl: "" }]);
    }
  };
  const deleteExtraDocumentsInForm = (otherDocId, docUrl) => {
    if (docUrl != "") {
      const fparr = docUrl.split("/");
      const imageName = fparr[fparr.length - 1];
      const sendData = { name: imageName };

      dispatch(action.delete_vehicle_file(sendData))
        .then((data) => {})
        .catch({});
    }
    setOtherDocumentRow(
      otherDocumentRow.filter((item) => item.id !== otherDocId)
    );
  };
  const addExtraDocumentsValue = (e, otherDocId) => {
    let oldotherDocument = otherDocumentRow.filter(
      (item) => item.id === otherDocId
    )?.[0];
    oldotherDocument = { ...oldotherDocument, [e.target.name]: e.target.value };
    let newOtherDocument = [];
    otherDocumentRow.map((item) => {
      if (item.id === otherDocId) {
        newOtherDocument.push(oldotherDocument);
      } else newOtherDocument.push(item);
    });
    setOtherDocumentRow(newOtherDocument);
  };
  const handleToChange = (e) => {
    let leaseCompanyId = e.target.value;
    let leaseCompanyname =
      e.target[e.target.selectedIndex].getAttribute("name");
    setInput({
      ...input,
      leaseCompanyId: leaseCompanyId,
      ownerName: leaseCompanyname,
    });
    dispatch(action.vehicle_lease_company_details(leaseCompanyId))
      .then((data) => {
        let vehicle_lease_company_details = data.data.batch;
        let newBetchList = [];
        vehicle_lease_company_details.map((item) => {
          newBetchList.push({
            value: item._id,
            label: item.batchNumber,
          });
        });
        setLeaseCompanyBatchList(newBetchList);
      })
      .catch({});
  };
  const changeBikeType = (e) => {
    let bikeTypeId = e.target.value;
    setInput({ ...input, bikeTypeId: bikeTypeId });
    if (bikeTypeId) {
      dispatch(action.bike_type_details(bikeTypeId))
        .then((data) => {
          let bike_type_details = data.data;
          setBikeDetails({
            registered: bike_type_details.registered,
            isActive: bike_type_details.isActive,
            _id: bike_type_details._id,
            bikeName: bike_type_details.bikeName,
            noOfBattery: bike_type_details.noOfBattery,
          });
        })
        .catch({});
    } else {
      setBikeDetails({
        registered: "",
        isActive: "",
        _id: "",
        bikeName: "",
        noOfBattery: "",
      });
    }
  };
  const changeVehicleNumber = (e) => {
    let vehicle_number = e.target.value;
    let result = vehicle_number.toUpperCase();
    setInput({ ...input, number: result });
  };
  const customFilter = (option, inputValue) => {
    const input = inputValue.toLowerCase();
    const filterText = new RegExp("^" + input + ".*");
    const label = option.data.label.toLowerCase();
    return filterText.test(label) && option.label.toLowerCase().includes(input);
  };
  const onsubmitHandler = (e) => {
    e.preventDefault();
    setErrors({});
    let customErrors = {};
    let documentErrors = {};
    otherDocumentRow.map((item) => {
      let errors = {};
      if (item.docName === "") {
        errors = { ...errors, docName: "Please enter document Name" };
      } else if (item.docUrl === "") {
        errors = { ...errors, docUrl: "Please enter document URL" };
      } else if (Object.keys(errors).length > 0) {
        documentErrors[item.id] = errors;
      }
    });
    if (input.bikeTypeId === "") {
      customErrors = { ...customErrors, bikeTypeId: "Please select bike type" };
      bikeTypeIdFocus.current.focus();
    } else if (
      !/^[A-Z]{2}\d{2}[A-Z]{2}\d{4}$/.test(input.number) &&
      bikeTypeDetails.registered === 1
    ) {
      customErrors = { ...customErrors, number: "Please enter vehicle number" };
      numberFocus.current.focus();
    } else if (input.name === "" && bikeTypeDetails.registered === 2) {
      customErrors = { ...customErrors, name: "Please enter vehicle number" };
      nameFocus.current.focus();
    } else if (input.sr_number === "") {
      customErrors = { ...customErrors, sr_number: "Please enter sr number" };
      srNumberFocus.current.focus();
    } else if (input.type === "") {
      customErrors = { ...customErrors, type: "Please select vehicle type" };
      typeFocus.current.focus();
    } else if (
      !/[0-9a-zA-Z]{10,}/.test(input.insuranceNo) &&
      input.insuranceNo == ""
    ) {
      customErrors = {
        ...customErrors,
        insuranceNo: "Please enter at least 10 character",
      };
      insuranceNoFocus.current.focus();
    } else if (input.vehicleHubId === "") {
      customErrors = {
        ...customErrors,
        vehicleHubId: "Please select vehicle hub",
      };
      vehicleHubIdFocus.current.focus();
    } else if (input.leaseCompanyId === "" && input.owned === 2) {
      customErrors = {
        ...customErrors,
        leaseCompanyId: "Please select lease company id",
      };
      leaseCompanyIdFocus.current.focus();
    } else if (!/^[0-9a-zA-Z-/]+$/.test(input.batteryNo)) {
      customErrors = {
        ...customErrors,
        batteryNo: "Please enter battery number",
      };
      batteryNoFocus.current.focus();
    } else if (
      !/^[0-9a-zA-Z-/]+$/.test(input.secondBatteryNo) &&
      bikeTypeDetails.noOfBattery === 2
    ) {
      customErrors = {
        ...customErrors,
        secondBatteryNo: "Please enter second battery number",
      };
      secondBatteryNoFocus.current.focus();
    } else if (input.city === "") {
      customErrors = { ...customErrors, city: "Please select city" };
      cityFocus.current.focus();
    } else if (
      !/^[0-9a-zA-Z/]+$/.test(input.rcBookNo) &&
      bikeTypeDetails.registered === 1
    ) {
      customErrors = {
        ...customErrors,
        rcBookNo: "Please enter rc book number",
      };
      rcBookNoFocus.current.focus();
    } else if (
      input.leaseCompanyBatchId === "" &&
      input.owned === 2 &&
      input.leaseCompanyId !== ""
    ) {
      customErrors = {
        ...customErrors,
        leaseCompanyBatchId: "Please select lease company batch id",
      };
      leaseCompanyBatchIdFocus.current.focus();
    } else if (input.chasisNo === "") {
      customErrors = {
        ...customErrors,
        chasisNo: "Please enter chasis number",
      };
      chasisNoFocus.current.focus();
    } else if (input.telematicsUnit === "") {
      customErrors = {
        ...customErrors,
        telematicsUnit: "Please enter telematic unit",
      };
      telematicsUnitFocus.current.focus();
    } else if (input.insuranceFile === "") {
      customErrors = {
        ...customErrors,
        insuranceFile: "Please select insurance file",
      };
      insuranceFileFocus.current.focus();
    } else if (input.insuranceExpiryDate === "") {
      customErrors = {
        ...customErrors,
        insuranceExpiryDate: "Please select insurance expiry date",
      };
      insuranceExpiryDateFocus.current.focus();
    } else if (input.rcBookFile === "" && bikeTypeDetails.registered === 1) {
      customErrors = {
        ...customErrors,
        rcBookFile: "Please select Rc book file",
      };
      rcBookFileFocus.current.focus();
    } else if (Object.keys(documentErrors).length > 0) {
      customErrors = { ...customErrors, document: documentErrors };
      DocumentsFocus.current.focus();
    }
    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }
    let vehicle_number = new Date().getTime();
    vehicle_number = vehicle_number.toString();
    let documents = [];
    otherDocumentRow.map((item) => {
      documents.push({
        docName: item.docName,
        docUrl: item.docUrl,
      });
    });
    let parameter = {};
    if (input.owned == 1) {
      parameter = {
        number: input.number ? input.number : vehicle_number,
        name: input.name,
        type: input.type,
        details: input.details,
        ownerName: input.ownerName,
        rcBookFile: input.rcBookFile,
        insuranceFile: input.insuranceFile,
        // permitFile:input.permitFile,
        insuranceExpiryDate: input.insuranceExpiryDate,
        // permitExpiryDate:input.permitExpiryDate,
        insuranceNo: input.insuranceNo,
        batteryNo: input.batteryNo,
        chasisNo: input.chasisNo,
        tracknowurl: input.tracknowurl,
        rcBookNo: input.rcBookNo,
        city: input.city,
        registered: bikeTypeDetails.registered,
        owned: input.owned,
        isActive: input.isActive,
        telematicsUnit: input.telematicsUnit,
        sr_number: input.sr_number,
        deactivateReason: input.deactivateReason,
        deactivateReasonInput: input.deactivateReasonInput,
      };
    } else {
      parameter = {
        number: input.number ? input.number : vehicle_number,
        name: input.name,
        type: input.type,
        details: input.details,
        ownerName: input.ownerName,
        rcBookFile: input.rcBookFile,
        insuranceFile: input.insuranceFile,
        // permitFile:input.permitFile,
        insuranceExpiryDate: input.insuranceExpiryDate,
        // permitExpiryDate:input.permitExpiryDate,
        insuranceNo: input.insuranceNo,
        batteryNo: input.batteryNo,
        chasisNo: input.chasisNo,
        tracknowurl: input.tracknowurl,
        rcBookNo: input.rcBookNo,
        city: input.city,
        registered: bikeTypeDetails.registered,
        owned: input.owned,
        isActive: input.isActive,
        leaseCompanyId: input.leaseCompanyId,
        leaseCompanyBatchId: input.leaseCompanyBatchId,
        telematicsUnit: input.telematicsUnit,
        sr_number: input.sr_number,
        deactivateReason: input.deactivateReason,
        deactivateReasonInput: input.deactivateReasonInput,
      };
    }
    parameter.bikeTypeId = input.bikeTypeId;
    if (input.secondBatteryNo !== "") {
      parameter.secondBatteryNo = input.secondBatteryNo;
    }
    if (input.vehicleHubId !== "") {
      parameter.vehicleHubId = input.vehicleHubId;
    }
    if (documents.length > 0) {
      parameter.otherDocuments = documents;
    }
    if (input.customerId !== "") {
      parameter.customerId = input.customerId;
    }
    dispatch(action.add_vehicle(parameter))
      .then((data) => {
        navigate("/adminside/vehicle");
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Sub_Header />
      <div className="main-container" id="container">
        {loading ? <Loader /> : null}
        <div className="overlay"></div>
        <div className="search-overlay"></div>

        <SideBar></SideBar>
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                <div className="widget-content widget-content-area br-6">
                  <h5 className="">Add Vehicle</h5>
                  <br />
                  <form onSubmit={onsubmitHandler}>
                    <div id="append-div-id">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group row">
                            <div className="form-group col-md-12 required">
                              <label htmlFor="inputBikeType">
                                Select Bike Type *
                              </label>
                              <select
                                id="inputVehicleHub"
                                ref={bikeTypeIdFocus}
                                name="type"
                                className="form-control selectpicker"
                                onChange={(e) => changeBikeType(e)}
                                value={input.bikeTypeId}
                              >
                                <option value="">Select Bike Type</option>
                                {bikeTypeList.map((bikeType) => (
                                  <option
                                    value={bikeType._id}
                                    key={bikeType._id}
                                  >
                                    {bikeType.bikeName}
                                  </option>
                                ))}
                              </select>
                              <span style={{ color: "red" }}>
                                {errors?.bikeTypeId}
                              </span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="form-group col-md-12 required">
                              <label htmlFor="inputVehicleType">
                                Select Vehicle Type *
                              </label>
                              <select
                                id="inputVehicleType"
                                ref={typeFocus}
                                name="type"
                                className="form-control selectpicker"
                                onChange={(e) =>
                                  setInput({ ...input, type: e.target.value })
                                }
                                value={input.type}
                              >
                                <option value="">Select Vehicle Type</option>
                                <option value="2">2 Wheeler</option>
                                <option value="3">3 Wheeler</option>
                                <option value="4">4 Wheeler</option>
                              </select>
                              <span style={{ color: "red" }}>
                                {errors?.type}
                              </span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="form-group col-md-12 required">
                              <label htmlFor="inputVehicleBatteryNumber">
                                Vehicle Battery No. 1*
                              </label>
                              <input
                                className="form-control"
                                ref={batteryNoFocus}
                                id="inputVehicleBatteryNumber"
                                name="batteryNo"
                                placeholder="Enter Battery No."
                                onChange={(e) =>
                                  setInput({
                                    ...input,
                                    batteryNo: e.target.value,
                                  })
                                }
                                value={input.batteryNo}
                              />
                              <span style={{ color: "red" }}>
                                {errors?.batteryNo}
                              </span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="form-group col-md-12 required">
                              <label htmlFor="inputVehicleChasisNumber">
                                Vehicle Chasis No.*
                              </label>
                              <input
                                className="form-control"
                                ref={chasisNoFocus}
                                id="inputVehicleChasisNumber"
                                name="chasisNo"
                                placeholder="Enter Chasis No."
                                onChange={(e) =>
                                  setInput({
                                    ...input,
                                    chasisNo: e.target.value,
                                  })
                                }
                                value={input.chasisNo}
                              />
                              <span style={{ color: "red" }}>
                                {errors?.chasisNo}
                              </span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="form-group col-md-12 required">
                              <label htmlFor="tracknowurl">Track Now URL</label>
                              <input
                                className="form-control"
                                type="url"
                                id="tracknowurl"
                                name="tracknowurl"
                                placeholder="Enter Track Now URL"
                                onChange={(e) =>
                                  setInput({
                                    ...input,
                                    tracknowurl: e.target.value,
                                  })
                                }
                                value={input.tracknowurl}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          {bikeTypeDetails.registered === 1 ? (
                            <div className="form-group row">
                              <div className="form-group col-md-12 required">
                                <label htmlFor="inputVehicleNumber">
                                  Vehicle Number *
                                </label>
                                <input
                                  className="form-control"
                                  ref={numberFocus}
                                  id="inputVehicleNumber"
                                  name="number"
                                  placeholder="GJ00AA0000"
                                  onChange={(e) => changeVehicleNumber(e)}
                                  value={input.number}
                                />
                                <span style={{ color: "red" }}>
                                  {errors?.number}
                                </span>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {bikeTypeDetails.registered === 0 ? (
                            <div className="form-group row">
                              <div className="form-group col-md-12 required">
                                <label htmlFor="inputVehicleName">
                                  Vehicle Name *
                                </label>
                                <input
                                  className="form-control"
                                  ref={nameFocus}
                                  id="inputVehiclename"
                                  name="name"
                                  placeholder="Enter Vehicle Name"
                                  onChange={(e) =>
                                    setInput({ ...input, name: e.target.value })
                                  }
                                  value={input.name}
                                />
                                <span style={{ color: "red" }}>
                                  {errors?.name}
                                </span>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="form-group row">
                            <div className="form-group col-md-12 required">
                              <label htmlFor="inputInsuranceNo">
                                Vehicle Insurance Number *
                              </label>
                              <input
                                className="form-control"
                                ref={insuranceNoFocus}
                                id="inputInsuranceNo"
                                name="text"
                                placeholder="Enter Vehicle Insurance Number"
                                onChange={(e) =>
                                  setInput({
                                    ...input,
                                    insuranceNo: e.target.value,
                                  })
                                }
                                value={input.insuranceNo}
                              />
                              <span style={{ color: "red" }}>
                                {errors?.insuranceNo}
                              </span>
                            </div>
                          </div>

                          {bikeTypeDetails.noOfBattery === 2 ? (
                            <div className="form-group row">
                              <div className="form-group col-md-12 required">
                                <label htmlFor="inputVehicleBatteryNumber">
                                  Vehicle Battery No. 2*
                                </label>
                                <input
                                  className="form-control"
                                  ref={secondBatteryNoFocus}
                                  id="inputVehicleBatteryNumber"
                                  name="batteryNo"
                                  placeholder="Enter Battery No."
                                  onChange={(e) =>
                                    setInput({
                                      ...input,
                                      secondBatteryNo: e.target.value,
                                    })
                                  }
                                  value={input.secondBatteryNo}
                                />
                                <span style={{ color: "red" }}>
                                  {errors?.secondBatteryNo}
                                </span>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="form-group row">
                            <div className="form-group col-md-12 required">
                              <label htmlFor="inputCity">Select City *</label>
                              <Select
                                id="inputCity"
                                ref={cityFocus}
                                value={values.cityValue || "Select"}
                                onChange={changeCityDropDown}
                                options={cityList}
                                filterOption={customFilter}
                              />
                              <span style={{ color: "red" }}>
                                {errors?.city}
                              </span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="form-group col-md-12 required">
                              <label htmlFor="inputCity">Select Customer</label>
                              <Select
                                ref={customerFocus}
                                value={values.customerValue || "Select"}
                                onChange={(value) =>
                                  changeCustomerDropDown(value)
                                }
                                options={customerList}
                                filterOption={customFilter}
                              />
                              <span style={{ color: "red" }}>
                                {errors?.customer}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group row">
                            <div className="form-group col-md-12 required">
                              <label htmlFor="inputVehicleSrNumber">
                                Vehicle SR No.*
                              </label>
                              <input
                                type="number"
                                ref={srNumberFocus}
                                className="form-control"
                                id="inputVehicleSrNumber"
                                name="sr_number"
                                placeholder="Enter Sr No."
                                onChange={(e) =>
                                  setInput({
                                    ...input,
                                    sr_number: e.target.value,
                                  })
                                }
                                value={input.sr_number}
                              />
                              <span style={{ color: "red" }}>
                                {errors?.sr_number}
                              </span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="form-group col-md-12 required">
                              <label htmlFor="inputVehicleHub">
                                Select Vehicle Hub *
                              </label>
                              <select
                                id="inputVehicleHub"
                                ref={vehicleHubIdFocus}
                                name="type"
                                className="form-control selectpicker"
                                onChange={(e) =>
                                  setInput({
                                    ...input,
                                    vehicleHubId: e.target.value,
                                  })
                                }
                                value={input.vehicleHubId}
                              >
                                <option value="">Select Vehicle Hub</option>
                                {hubList.map((hub) => (
                                  <option key={hub._id} value={hub._id}>
                                    {hub.name}
                                  </option>
                                ))}
                              </select>
                              <span style={{ color: "red" }}>
                                {errors?.vehicleHubId}
                              </span>
                            </div>
                          </div>
                          {bikeTypeDetails.registered === 1 ? (
                            <div className="form-group row">
                              <div className="form-group col-md-12 required">
                                <label htmlFor="inputVehicleRCBookNumber">
                                  Vehicle RC Book No.*
                                </label>
                                <input
                                  className="form-control"
                                  ref={rcBookNoFocus}
                                  id="inputVehicleRCBookNumber"
                                  name="rcBookNo"
                                  placeholder="Enter RC Book No."
                                  onChange={(e) =>
                                    setInput({
                                      ...input,
                                      rcBookNo: e.target.value,
                                    })
                                  }
                                  value={input.rcBookNo}
                                />
                                <span style={{ color: "red" }}>
                                  {errors?.rcBookNo}
                                </span>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="form-group col-md-12 required">
                            <label htmlFor="inputVehicleTelematicsUnit">
                              Vehicle Telematics Unit *
                            </label>
                            <input
                              className="form-control"
                              ref={telematicsUnitFocus}
                              id="inputVehicleTelematicsUnit"
                              name="text"
                              placeholder="Enter Vehicle TelematicUnit"
                              onChange={(e) =>
                                setInput({
                                  ...input,
                                  telematicsUnit: e.target.value,
                                })
                              }
                              value={input.telematicsUnit}
                            />
                            <span style={{ color: "red" }}>
                              {errors?.telematicsUnit}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group row">
                            <div
                              className="form-group col-md-12 required"
                              id="vehicleStatusRegisted"
                            >
                              <label>Owned /Leased *</label>
                              <br />
                              <div
                                className="custom-control custom-radio custom-control-inline"
                                id="vehicleOwnedLeased"
                              >
                                {input.owned === 1 ? (
                                  <input
                                    type="radio"
                                    id="statusOwnedLeased1"
                                    name="vehicleOwnedLeasedStatus"
                                    className="custom-control-input"
                                    value="1"
                                    onClick={(e) =>
                                      setInput({
                                        ...input,
                                        owned: 1,
                                        ownerName: "EVIFY Logitech Pvt Ltd",
                                        leaseCompanyId: "",
                                      })
                                    }
                                    defaultChecked
                                  />
                                ) : (
                                  <input
                                    type="radio"
                                    id="statusOwnedLeased1"
                                    name="vehicleOwnedLeasedStatus"
                                    className="custom-control-input"
                                    value="1"
                                    onClick={(e) =>
                                      setInput({
                                        ...input,
                                        owned: 1,
                                        ownerName: "EVIFY Logitech Pvt Ltd",
                                        leaseCompanyId: "",
                                      })
                                    }
                                  />
                                )}
                                <label
                                  className="custom-control-label status-radio"
                                  htmlFor="statusOwnedLeased1"
                                >
                                  Owned
                                </label>
                              </div>
                              <div className="custom-control custom-radio custom-control-inline">
                                {input.owned === 2 ? (
                                  <input
                                    type="radio"
                                    id="statusOwnedLeased0"
                                    name="vehicleOwnedLeasedStatus"
                                    className="custom-control-input"
                                    value="0"
                                    onClick={(e) =>
                                      setInput({ ...input, owned: 2 })
                                    }
                                    defaultChecked
                                  />
                                ) : (
                                  <input
                                    type="radio"
                                    id="statusOwnedLeased0"
                                    name="vehicleOwnedLeasedStatus"
                                    className="custom-control-input"
                                    value="0"
                                    onClick={(e) =>
                                      setInput({ ...input, owned: 2 })
                                    }
                                  />
                                )}
                                <label
                                  className="custom-control-label status-radio"
                                  htmlFor="statusOwnedLeased0"
                                >
                                  Leased
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            {input.owned === 2 ? (
                              <div className="form-group col-md-12 required">
                                <label htmlFor="inputVehicleLeaseCompany">
                                  Select Vehicle Lease Company *
                                </label>
                                <select
                                  id="inputVehicleLeaseCompany"
                                  name="type"
                                  ref={leaseCompanyIdFocus}
                                  className="form-control selectpicker"
                                  onChange={(e) => handleToChange(e)}
                                  value={input.leaseCompanyId}
                                >
                                  <option value="">
                                    Select Vehicle Lease Company
                                  </option>
                                  {leaseCompanyList.map((leaseCompany) => (
                                    <>
                                      <option
                                        name={leaseCompany.name}
                                        value={leaseCompany._id}
                                      >
                                        {leaseCompany.name}
                                      </option>
                                    </>
                                  ))}
                                </select>
                                <span style={{ color: "red" }}>
                                  {errors?.leaseCompanyId}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="form-group row">
                            {input.owned === 2 &&
                            input.leaseCompanyId !== "" ? (
                              <div className="form-group col-md-12 required">
                                <label htmlFor="inputVehicleLeaseCompanyBatch">
                                  Select Batch *
                                </label>
                                <select
                                  id="inputVehicleLeaseCompanyBatch"
                                  ref={leaseCompanyBatchIdFocus}
                                  name="type"
                                  className="form-control selectpicker"
                                  onChange={(e) =>
                                    setInput({
                                      ...input,
                                      leaseCompanyBatchId: e.target.value,
                                    })
                                  }
                                  value={input.leaseCompanyBatchId}
                                >
                                  <option value="">Select Batch</option>
                                  {leaseCompanyBatchList.map(
                                    (leaseCompanyBatch) => (
                                      <>
                                        <option value={leaseCompanyBatch.value}>
                                          {leaseCompanyBatch.label}
                                        </option>
                                      </>
                                    )
                                  )}
                                </select>
                                <span style={{ color: "red" }}>
                                  {errors?.leaseCompanyBatchId}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="form-group col-md-12 required">
                          <label htmlFor="inputVehicleOwnername">
                            Owner Name *
                          </label>
                          <input
                            type="text"
                            disabled
                            className="form-control"
                            name="ownerName"
                            id="inputVehicleOwnername"
                            placeholder="Enter vehicle owner name"
                            required
                            onChange={(e) =>
                              setInput({ ...input, ownerName: e.target.value })
                            }
                            value={input.ownerName}
                          />
                        </div>
                      </div>

                      {bikeTypeDetails.registered === 1 ? (
                        <div className="form-group row">
                          <div className="form-group col-md-12 required">
                            <label htmlFor="rcBookFile">RC Book *</label>
                            <input
                              accept="image/jpg,image/jpeg,image/png,image/bmp,application/pdf"
                              ref={rcBookFileFocus}
                              multiple=""
                              type="file"
                              onChange={(e) => uploadRCBookFile(e)}
                              name="rcBookFile[]"
                              className="form-control p-2"
                              id="rcBookFile"
                            />
                            <span style={{ color: "red" }}>
                              {errors?.rcBookFile}
                            </span>
                            <br />
                            {input.rcBookFile !== "" ? (
                              input.rcBookFile.toString().endsWith("pdf") ? (
                                <a
                                  href={`${input.rcBookFile}`}
                                  className="btn btn-info"
                                  target="_blank"
                                >
                                  Show
                                </a>
                              ) : (
                                <img
                                  src={`${input.rcBookFile}`}
                                  alt="no-image"
                                  id="rcBookImgId"
                                  className="form-group p-2 img-responsive imgFile"
                                  onClick={() =>
                                    setModalImgURL(input.rcBookFile)
                                  }
                                  data-toggle="modal"
                                  data-target="#showImageModalCenter"
                                  style={{ width: "70px", height: "70px" }}
                                />
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="form-group row">
                        <div className="form-group col-md-12 required">
                          <label htmlFor="inputVehicleDetails">Details</label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputVehicleDetails"
                            placeholder="Enter vehicle details"
                            name="details"
                            onChange={(e) =>
                              setInput({ ...input, details: e.target.value })
                            }
                            value={input.details}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="form-group col-md-8 required">
                          <label htmlFor="insuranceFile">Insurance *</label>
                          <input
                            accept="image/jpg,image/jpeg,image/png,image/bmp,application/pdf"
                            ref={insuranceFileFocus}
                            multiple=""
                            type="file"
                            onChange={(e) => uploadInsuranceFile(e)}
                            name="insuranceFile[]"
                            className="form-control p-2"
                            id="insuranceFile"
                          />
                          <span style={{ color: "red" }}>
                            {errors?.insuranceFile}
                          </span>
                          <br />

                          {input.insuranceFile !== "" ? (
                            input.insuranceFile.toString().endsWith("pdf") ? (
                              <a
                                href={`${input.insuranceFile}`}
                                className="btn btn-info"
                                target="_blank"
                              >
                                Show
                              </a>
                            ) : (
                              <img
                                src={`${input.insuranceFile}`}
                                alt="no-image"
                                id="insuranceImgId"
                                className="form-group p-2 img-responsive imgFile"
                                onClick={() =>
                                  setModalImgURL(input.insuranceFile)
                                }
                                data-toggle="modal"
                                data-target="#showImageModalCenter"
                                style={{ width: "70px", height: "70px" }}
                              />
                            )
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="form-group col-md-4 required">
                          <label htmlFor="insuranceExpiry">Expiry Date *</label>
                          <span
                            id="addAnotherDocIdspan"
                            className="btn-warning btn-sm float-right ms-3 mb-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => addExtraDocumentsInForm()}
                          >
                            {" "}
                            Add Other Document{" "}
                          </span>
                          <input
                            type="date"
                            name="insuranceExpiryDate"
                            ref={insuranceExpiryDateFocus}
                            className="form-control datepicker"
                            id="insuranceExpiry"
                            onChange={(e) =>
                              setInput({
                                ...input,
                                insuranceExpiryDate: e.target.value,
                              })
                            }
                            value={input.insuranceExpiryDate}
                            autoComplete="off"
                          />
                          <span style={{ color: "red" }}>
                            {errors?.insuranceExpiryDate}
                          </span>
                        </div>
                      </div>

                      {otherDocumentRow.map((otherDocumentItem) => (
                        <>
                          <div
                            className="form-group row"
                            id={`doc${otherDocumentItem.id}`}
                            ref={DocumentsFocus}
                            tabIndex="-1"
                          >
                            <div className="form-group col-md-4 required">
                              <label
                                htmlFor={`inputDocumentName${otherDocumentItem.id}`}
                              >
                                Document Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="docName"
                                id={`inputDocumentName${otherDocumentItem.id}`}
                                placeholder="Enter document name"
                                onChange={(e) =>
                                  addExtraDocumentsValue(
                                    e,
                                    otherDocumentItem.id
                                  )
                                }
                              />
                              <span style={{ color: "red" }}>
                                {errors.document
                                  ? errors.document[otherDocumentItem.id]
                                      ?.docName
                                    ? errors.document[otherDocumentItem.id]
                                        ?.docName
                                    : ""
                                  : ""}
                              </span>
                            </div>
                            <div className="form-group col-md-8 required">
                              <label
                                htmlFor={`otherFile${otherDocumentItem.id}`}
                              >
                                Document File{" "}
                              </label>
                              <span
                                className="badge badge-danger float-right"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  deleteExtraDocumentsInForm(
                                    otherDocumentItem.id,
                                    otherDocumentItem.docUrl
                                  )
                                }
                                id={`delethisidspan${otherDocumentItem.id}`}
                              >
                                Delete This
                              </span>
                              <input
                                accept="image/jpg,image/jpeg,image/png,image/bmp"
                                multiple=""
                                type="file"
                                className="form-control p-2"
                                id={`otherFile${otherDocumentItem.id}`}
                                onChange={(e) =>
                                  uploadOtherDocFile(e, otherDocumentItem.id)
                                }
                              />
                              <span style={{ color: "red" }}>
                                {errors.document
                                  ? errors.document[otherDocumentItem.id]
                                      ?.docUrl
                                    ? errors.document[otherDocumentItem.id]
                                        ?.docUrl
                                    : ""
                                  : ""}
                              </span>
                              {otherDocumentItem.docUrl !== "" ? (
                                <img
                                  src={`${otherDocumentItem.docUrl}`}
                                  alt="no-image"
                                  id={`otherImgId${otherDocumentItem.id}`}
                                  className="form-group p-2 img-responsive imgFile"
                                  onClick={() =>
                                    setModalImgURL(otherDocumentItem.docUrl)
                                  }
                                  data-toggle="modal"
                                  data-target="#showImageModalCenter"
                                  style={{ width: "70px", height: "70px" }}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </>
                      ))}

                      <div
                        className="modal fade"
                        id="showImageModalCenter"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="showImageModalCenterLabel"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered modal-lg"
                          role="img"
                        >
                          <div className="modal-content">
                            <div className="modal-body">
                              {modalImgURL !== "" ? (
                                <img
                                  src={`${modalImgURL}`}
                                  alt="no-image"
                                  id="modalImgId"
                                  className="img-responsive"
                                  style={{ width: "100%", height: "100%" }}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="form-group required"
                        id="vehicleStatusMain"
                      >
                        <label>Vehicle Status *</label>
                        <div
                          className="custom-control custom-radio custom-control-inline"
                          id="vehicleStatus"
                        >
                          <input
                            type="radio"
                            defaultChecked={input.isActive === 1}
                            id="status1"
                            name="vehicleStatus"
                            className="custom-control-input"
                            value={1}
                            onClick={(e) => setInput({ ...input, isActive: 1 })}
                          />
                          <label
                            className="custom-control-label status-radio"
                            htmlFor="status1"
                          >
                            Active
                          </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            defaultChecked={input.isActive === 0}
                            id="status0"
                            name="vehicleStatus"
                            className="custom-control-input"
                            value={0}
                            onClick={(e) => setInput({ ...input, isActive: 0 })}
                          />
                          <label
                            className="custom-control-label status-radio"
                            htmlFor="status0"
                          >
                            Deactive
                          </label>
                        </div>
                      </div>
                      {input.isActive === 0 ? (
                        <div
                          className="form-group required"
                          id="vehicleStatusDeactiveMain"
                        >
                          <label>Vehicle Deactivate Reason *</label>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              value="At Hub"
                              onClick={(e) =>
                                setInput({
                                  ...input,
                                  deactivateReason: "At Hub",
                                })
                              }
                              id="flexRadioDefault1"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault1"
                            >
                              At Hub
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              value="At Dealership For Repair"
                              id="flexRadioDefault2"
                              onClick={(e) =>
                                setInput({
                                  ...input,
                                  deactivateReason: "At Dealership For Repair",
                                })
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault2"
                            >
                              At Dealership For Repair
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              value="Waiting For Spair Parts"
                              id="flexRadioDefault3"
                              onClick={(e) =>
                                setInput({
                                  ...input,
                                  deactivateReason: "Waiting For Spair Parts",
                                })
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault3"
                            >
                              Waiting For Spair Parts
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              value="Insurance Claim"
                              id="flexRadioDefault4"
                              onClick={(e) =>
                                setInput({
                                  ...input,
                                  deactivateReason: "Insurance Claim",
                                })
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault4"
                            >
                              Insurance Claim
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              value="Others"
                              id="flexRadioDefault4"
                              onClick={(e) =>
                                setInput({
                                  ...input,
                                  deactivateReason: "Others",
                                })
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault4"
                            >
                              Others
                            </label>
                          </div>
                          {input.deactivateReason === "Others" ? (
                            <div className="form-check">
                              <input
                                className="form-control col-md-6"
                                type="text"
                                name="deactivateReasonInput"
                                id="deactivateReasonInput"
                                onChange={(e) =>
                                  setInput({
                                    ...input,
                                    deactivateReasonInput: e.target.value,
                                  })
                                }
                                value={input.deactivateReasonInput}
                              />
                              <br />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <button type="reset" className="btn btn-danger mt-3">
                      Reset
                    </button>
                    &nbsp;
                    <button
                      type="submit"
                      className="btn btn-success mt-3 view-btn"
                    >
                      Add
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </>
  );
};
export default AddVehicle;
