import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import Header from "../../DashBoard/Header";
import Sub_Header from "../../DashBoard/Sub_Header";
import SideBar from "../../DashBoard/SideBar";
import Footer from "../../DashBoard/Footer";
import { ToastContainer, toast } from "react-toastify";
import * as common from "../../../action/common/common_action";
import * as Customeraction from "../../../action/Customer/customer_action";
import * as driveraction from "../../../action/Driver/driver_action";
import { Input } from "rsuite";
import { RxCross2 } from "react-icons/rx";
import $ from "jquery";
import Pagination from "../../common/Pagination/Pagination";

const RiderParcel = () => {
  const dispatch = useDispatch();
  // ref for display validation error
  const yearAndMonthFocus = useRef();
  const cityFocus = useRef();
  const customerFocus = useRef();
  const fulltimePickupParcelsFocus = useRef();
  const fulltimeDoneParcelsFocus = useRef();
  const partTimePickupParcelsFocus = useRef();
  const partTimeDoneParcelsFocus = useRef();

  const edityearAndMonthFocus = useRef();
  const editCityFocus = useRef();
  const editCustomerFocus = useRef();
  const editfulltimePickupParcelsFocus = useRef();
  const editfulltimeDoneParcelsFocus = useRef();
  const editpartTimePickupParcelsFocus = useRef();
  const editpartTimeDoneParcelsFocus = useRef();

  const [errors, setErrors] = useState({});
  const [cityList, setCityList] = useState([]);
  const [customerNameList, setCustomerNameList] = useState([]);
  const [formId, setFormId] = useState();
  const [parcelData, setParcelData] = useState([]);

  const [addRiderParcel, setAddRiderParcel] = useState({
    yearAndMonth: "",
    cityId: "",
    customerId: "",
    fulltimePickupParcels: "",
    fulltimeDoneParcels: "",
    partTimePickupParcels: "",
    partTimeDoneParcels: "",
  });

  const [editRiderParcel, setEditRiderParcel] = useState({
    yearAndMonth: "",
    cityId: "",
    customerId: "",
    fulltimePickupParcels: "",
    fulltimeDoneParcels: "",
    partTimePickupParcels: "",
    partTimeDoneParcels: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [disabled, setDisabled] = useState(true);
  const [editButtonHide, setEditButtonHide] = useState(false);
  const [submitButtonHide, setSubmitButtonHide] = useState(true);

  const setEditFormEditable = (type) => {
    if (type) {
      setDisabled(false);
      //   setCancelButtonHide(false);
      setSubmitButtonHide(false);
      setEditButtonHide(true);
    } else {
      setDisabled(true);
      //   setCancelButtonHide(true);
      setSubmitButtonHide(true);
      setEditButtonHide(false);
    }
  };

  // city list
  useEffect(() => {
    dispatch(common.common_city_list())
      .then((data) => {
        let newCustomerList = [];
        data.data.map((item) => {
          newCustomerList.push({ value: item._id, label: item.cityName });
        });
        setCityList(newCustomerList);
      })
      .catch({});
  }, {});

  useEffect(() => {
    dispatch(Customeraction.customer_list())
      .then((data) => {
        let newCustomerList = [];
        data.data.map((item) => {
          newCustomerList.push({ value: item._id, label: item.businessName });
        });
        setCustomerNameList(newCustomerList);
      })
      .catch({});
  }, []);

  useEffect(() => {
    dispatch(driveraction.driver_get_riders_parcel())
      .then((data) => {
        let parcels = data.data;
        setParcelData(parcels);
      })
      .catch((err) => {});
  }, []);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setErrors({});

    let customErrors = {};

    if (!addRiderParcel.yearAndMonth) {
      customErrors = {
        ...customErrors,
        yearAndMonth: "Please Select Year Month",
      };
      yearAndMonthFocus.current.focus();
    } else if (!addRiderParcel.cityId) {
      customErrors = { ...customErrors, cityId: "Please Select City" };
      cityFocus.current.focus();
    } else if (!addRiderParcel.customerId) {
      customErrors = { ...customErrors, customerId: "Please Select Customer" };
      customerFocus.current.focus();
    } else if (!addRiderParcel.fulltimePickupParcels) {
      customErrors = {
        ...customErrors,
        fulltimePickupParcels: "Please Enter No of Pickup Parcel",
      };
      fulltimePickupParcelsFocus.current.focus();
    } else if (!addRiderParcel.fulltimeDoneParcels) {
      customErrors = {
        ...customErrors,
        fulltimeDoneParcels: "Please Enter No of Done Parcel",
      };
      fulltimeDoneParcelsFocus.current.focus();
    } else if (!addRiderParcel.partTimePickupParcels) {
      customErrors = {
        ...customErrors,
        partTimePickupParcels: "Please Enter No of Pickup Parcel",
      };
      partTimePickupParcelsFocus.current.focus();
    } else if (!addRiderParcel.partTimeDoneParcels) {
      customErrors = {
        ...customErrors,
        partTimeDoneParcels: "Please Enter No of Done Parcel",
      };
      partTimeDoneParcelsFocus.current.focus();
    }

    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }

    dispatch(driveraction.driver_add_riders_parcel(addRiderParcel))
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          setParcelData(data?.data);
          $("#addRiderParcelForm .close").click();
          setErrors({});
        } else if (!data.success) {
          toast.error(data.message);
        }
      })
      .catch((err) => {});

    setErrors({});
    setAddRiderParcel({
      yearAndMonth: "",
      cityId: "",
      customerId: "",
      fulltimePickupParcels: "",
      fulltimeDoneParcels: "",
      partTimePickupParcels: "",
      partTimeDoneParcels: "",
    });
  };

  const onUpdateHandler = (e) => {
    e.preventDefault();
    setErrors({});

    setErrors({});
    let customErrors = {};

    if (!editRiderParcel.yearAndMonth) {
      customErrors = {
        ...customErrors,
        yearAndMonth: "Please Select Year Month",
      };
      edityearAndMonthFocus.current.focus();
    } else if (!editRiderParcel.cityId) {
      customErrors = { ...customErrors, cityId: "Please Select City" };
      editCityFocus.current.focus();
    } else if (!editRiderParcel.customerId) {
      customErrors = { ...customErrors, customerId: "Please Select Customer" };
      editCustomerFocus.current.focus();
    } else if (!editRiderParcel.fulltimePickupParcels) {
      customErrors = {
        ...customErrors,
        fulltimePickupParcels: "Please Enter No of Pickup Parcel",
      };
      editfulltimePickupParcelsFocus.current.focus();
    } else if (!editRiderParcel.fulltimeDoneParcels) {
      customErrors = {
        ...customErrors,
        fulltimeDoneParcels: "Please Enter No of Done Parcel",
      };
      editfulltimeDoneParcelsFocus.current.focus();
    } else if (!editRiderParcel.partTimePickupParcels) {
      customErrors = {
        ...customErrors,
        partTimePickupParcels: "Please Enter No of Pickup Parcel",
      };
      editpartTimePickupParcelsFocus.current.focus();
    } else if (!editRiderParcel.partTimeDoneParcels) {
      customErrors = {
        ...customErrors,
        partTimeDoneParcels: "Please Enter No of Done Parcel",
      };
      editpartTimeDoneParcelsFocus.current.focus();
    }

    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }

    dispatch(driveraction.driver_edit_riders_parcel(formId, editRiderParcel))
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          setParcelData(data?.data);
          $("#editRiderParcelForm .close").click();
          setEditFormEditable(false);
        } else if (!data.success) {
          toast.error(data.message);
        }
      })
      .catch((err) => {});

    setErrors({});
  };

  const handleEditClick = (item) => {
    setFormId(item._id);
    const formattedMonth =
      item.month < 10 && item.month > 0 ? `0${item.month}` : item.month;

    let yearMonth = `${item.year}-${formattedMonth}`;

    setEditRiderParcel({
      yearAndMonth: yearMonth,
      cityId: item.cityId,
      customerId: item.customerId,
      fulltimePickupParcels: item.fulltimePickupParcels,
      fulltimeDoneParcels: item.fulltimeDoneParcels,
      partTimePickupParcels: item.partTimePickupParcels,
      partTimeDoneParcels: item.partTimeDoneParcels,
    });

    setErrors({});
  };

  const handleCancelClick = () => {
    setEditFormEditable(false);
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * 10;
    const lastPageIndex = firstPageIndex + 10;
    return parcelData?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, parcelData]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Sub_Header />
      <div className="main-container" id="container">
        <div className="overlay"></div>
        <div className="search-overlay"></div>
        <SideBar />
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                <div className="widget-content widget-content-area br-6">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="" style={{ margin: "10px" }}>
                      Drivers Parcel Delivery
                    </h5>
                    <a
                      href="/"
                      className="btn btn-warning btn-sm add-act-btn"
                      data-toggle="modal"
                      data-target="#addRiderParcelForm"
                    >
                      Add
                    </a>
                  </div>

                  <br />
                  <div className="table-responsive mb-4 mt-4">
                    {1 ? (
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>Customer</th>
                            <th>Month</th>
                            <th>Year</th>
                            <th>City</th>
                            <th>Full Time Pickup Parcel</th>
                            <th>Full Time Done Parcel</th>
                            <th>Part Time Pickup Parcel</th>
                            <th>Part Time Done Parcel</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentTableData.map((item, i) => {
                            return (
                              <tr>
                                <td>{item.customerName}</td>
                                <td>{item.month}</td>
                                <td>{item.year}</td>
                                <td>{item.cityName}</td>
                                <td>{item.fulltimePickupParcels}</td>
                                <td>{item.fulltimeDoneParcels}</td>
                                <td>{item.partTimePickupParcels}</td>
                                <td>{item.partTimeDoneParcels}</td>
                                <td>
                                  <a
                                    href="/"
                                    onClick={() => handleEditClick(item)}
                                    className="btn btn-info btn-sm edit-btn edit-act-btn"
                                    data-toggle="modal"
                                    data-target="#editRiderParcelForm"
                                  >
                                    Edit
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <p className="text-center">No data Available</p>
                    )}

                    {/* pagination */}
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={parcelData.length}
                      pageSize={10}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>

      {/* add model */}
      <div
        className="modal fade"
        id="addRiderParcelForm"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addRiderParcelFormTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <form onSubmit={onSubmitHandler}>
              <div className="modal-header">
                <h5 className="modal-title" id="addHubModalCenterTitle">
                  Add Driver Parcel Delivery
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <RxCross2 />
                </button>
              </div>
              <div className="modal-body">
                <div
                  className="customer-address-class"
                  id="customer-address-div-id"
                >
                  <div className="row" id="defaultaddressdiv0">
                    <div className="col-12">
                      <div id="append-div-id">
                        <div className="form-group row">
                          <div className="form-group col-6 required">
                            <label>Select Year and Month *</label>
                            <Input
                              type="month"
                              ref={yearAndMonthFocus}
                              name="yearAndMonth"
                              value={addRiderParcel?.yearAndMonth}
                              onChange={(value) =>
                                setAddRiderParcel({
                                  ...addRiderParcel,
                                  yearAndMonth: value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.yearAndMonth}
                            </span>
                          </div>
                          <div className="form-group col-6  required">
                            <label htmlFor="cityId">Select City *</label>
                            <select
                              id="cityId"
                              ref={cityFocus}
                              className="form-control selectpicker"
                              value={addRiderParcel?.cityId}
                              onChange={(e) => {
                                setAddRiderParcel({
                                  ...addRiderParcel,
                                  cityId: e.target.value,
                                });
                              }}
                            >
                              <option value="">Select City</option>
                              {cityList.map((item) => {
                                return (
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            <span style={{ color: "red" }}>
                              {errors?.cityId}
                            </span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="form-group col-6 required">
                            <label htmlFor="customerId">
                              Select Customer *
                            </label>
                            <select
                              id="customerId"
                              ref={customerFocus}
                              className="form-control selectpicker"
                              value={addRiderParcel?.customerId}
                              onChange={(e) =>
                                setAddRiderParcel({
                                  ...addRiderParcel,
                                  customerId: e.target.value,
                                })
                              }
                            >
                              <option value="">Select Customer</option>
                              {customerNameList.map((item) => {
                                return (
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            <span style={{ color: "red" }}>
                              {errors?.customerId}
                            </span>
                          </div>
                        </div>
                        <h6 className="mb-2">Full Time</h6>
                        <div className="form-group row">
                          <div className="form-group col-6 required">
                            <label htmlFor="fulltimePickupParcels">
                              Enter Pickup Parcel
                            </label>
                            <Input
                              type="number"
                              min="0"
                              className="form-control"
                              placeholder="No of Pickup Parcel"
                              name="fulltimePickupParcels"
                              ref={fulltimePickupParcelsFocus}
                              id="fulltimePickupParcels"
                              value={addRiderParcel?.fulltimePickupParcels}
                              onChange={(value) =>
                                setAddRiderParcel({
                                  ...addRiderParcel,
                                  fulltimePickupParcels: value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.fulltimePickupParcels}
                            </span>
                          </div>
                          <div className="form-group col-6 required">
                            <label>Enter Done Parcel</label>
                            <Input
                              type="number"
                              min="0"
                              className="form-control"
                              placeholder="No of Done Parcel"
                              name="fulltimeDoneParcels"
                              ref={fulltimeDoneParcelsFocus}
                              id="fulltimeDoneParcels"
                              value={addRiderParcel?.fulltimeDoneParcels}
                              onChange={(value) =>
                                setAddRiderParcel({
                                  ...addRiderParcel,
                                  fulltimeDoneParcels: value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.fulltimeDoneParcels}
                            </span>
                          </div>
                        </div>
                        <h6 className="mb-2">Part Time</h6>
                        <div className="form-group row">
                          <div className="form-group col-6 required">
                            <label htmlFor="partTimeDoneParcels">
                              Enter Pickup Parcel
                            </label>
                            <Input
                              type="number"
                              min="0"
                              className="form-control"
                              placeholder="No of Pickup Parcel"
                              name="partTimePickupParcels"
                              ref={partTimePickupParcelsFocus}
                              id="partTimePickupParcels"
                              value={addRiderParcel?.partTimePickupParcels}
                              onChange={(value) =>
                                setAddRiderParcel({
                                  ...addRiderParcel,
                                  partTimePickupParcels: value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.partTimePickupParcels}
                            </span>
                          </div>
                          <div className="form-group col-6 required">
                            <label htmlFor="inputFullname">
                              Enter Done Parcel
                            </label>
                            <Input
                              type="number"
                              min="0"
                              className="form-control"
                              name="partTimeDoneParcels"
                              ref={partTimeDoneParcelsFocus}
                              id="partTimeDoneParcels"
                              value={addRiderParcel?.partTimeDoneParcels}
                              onChange={(value) =>
                                setAddRiderParcel({
                                  ...addRiderParcel,
                                  partTimeDoneParcels: value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.partTimeDoneParcels}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="reset"
                  data-dismiss="modal"
                  className="btn btn-danger "
                  onClick={handleCancelClick}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success save-btn">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* update model */}
      <div
        className="modal fade"
        id="editRiderParcelForm"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editRiderParcelFormTitle"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <form onSubmit={onUpdateHandler}>
              <div className="modal-header">
                <h5 className="modal-title" id="editRiderParcelFormTitle">
                  Driver Parcel Delivery
                </h5>
                <button
                  type="button"
                  className="btn btn-primary view-btn"
                  hidden={editButtonHide}
                  onClick={() => setEditFormEditable(true)}
                >
                  Edit
                </button>
              </div>

              <div className="modal-body">
                <div
                  className="customer-address-class"
                  id="customer-address-div-id"
                >
                  <div className="row" id="defaultaddressdiv0">
                    <div className="col-12">
                      <div id="append-div-id">
                        <div className="form-group row">
                          <div className="form-group col-6 required">
                            <label>Select Year and Month *</label>
                            <Input
                              disabled={disabled}
                              type="month"
                              ref={edityearAndMonthFocus}
                              name="yearAndMonth"
                              value={editRiderParcel?.yearAndMonth}
                              onChange={(value) =>
                                setEditRiderParcel({
                                  ...editRiderParcel,
                                  yearAndMonth: value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.yearAndMonth}
                            </span>
                          </div>
                          <div className="form-group col-6  required">
                            <label>Select City *</label>
                            <select
                              className="form-control selectpicker"
                              disabled={disabled}
                              ref={editCityFocus}
                              value={editRiderParcel?.cityId}
                              onChange={(e) =>
                                setEditRiderParcel({
                                  ...editRiderParcel,
                                  cityId: e.target.value,
                                })
                              }
                            >
                              <option value="">Select City</option>
                              {cityList.map((item) => {
                                return (
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            <span style={{ color: "red" }}>
                              {errors?.cityId}
                            </span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="form-group col-6 required">
                            <label>Select Customer *</label>
                            <select
                              className="form-control selectpicker"
                              disabled={disabled}
                              ref={editCustomerFocus}
                              value={editRiderParcel?.customerId}
                              onChange={(e) =>
                                setEditRiderParcel({
                                  ...editRiderParcel,
                                  customerId: e.target.value,
                                })
                              }
                            >
                              <option value="">Select Customer</option>
                              {customerNameList.map((item) => {
                                return (
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>

                            <span style={{ color: "red" }}>
                              {errors?.customerId}
                            </span>
                          </div>
                        </div>
                        <h6 className="mb-2">Full Time</h6>
                        <div className="form-group row">
                          <div className="form-group col-6 required">
                            <label htmlFor="fulltimePickupParcels">
                              Enter Pickup Parcel
                            </label>
                            <Input
                              disabled={disabled}
                              type="number"
                              min="0"
                              className="form-control"
                              placeholder="No of Pickup Parcel"
                              name="fulltimePickupParcels"
                              ref={editfulltimePickupParcelsFocus}
                              id="fulltimePickupParcels"
                              value={editRiderParcel?.fulltimePickupParcels}
                              onChange={(value) =>
                                setEditRiderParcel({
                                  ...editRiderParcel,
                                  fulltimePickupParcels: value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.fulltimePickupParcels}
                            </span>
                          </div>
                          <div className="form-group col-6 required">
                            <label>Enter Done Parcel</label>
                            <Input
                              disabled={disabled}
                              type="number"
                              min="0"
                              className="form-control"
                              placeholder="No of Done Parcel"
                              name="fulltimeDoneParcels"
                              ref={editfulltimeDoneParcelsFocus}
                              id="fulltimeDoneParcels"
                              value={editRiderParcel?.fulltimeDoneParcels}
                              onChange={(value) =>
                                setEditRiderParcel({
                                  ...editRiderParcel,
                                  fulltimeDoneParcels: value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.fulltimeDoneParcels}
                            </span>
                          </div>
                        </div>
                        <h6 className="mb-2">Part Time</h6>
                        <div className="form-group row">
                          <div className="form-group col-6 required">
                            <label htmlFor="partTimeDoneParcels">
                              Enter Pickup Parcel
                            </label>
                            <Input
                              disabled={disabled}
                              type="number"
                              min="0"
                              className="form-control"
                              placeholder="No of Pickup Parcel"
                              name="partTimePickupParcels"
                              ref={editpartTimePickupParcelsFocus}
                              id="partTimePickupParcels"
                              value={editRiderParcel?.partTimePickupParcels}
                              onChange={(value) =>
                                setEditRiderParcel({
                                  ...editRiderParcel,
                                  partTimePickupParcels: value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.partTimePickupParcels}
                            </span>
                          </div>
                          <div className="form-group col-6 required">
                            <label htmlFor="inputFullname">
                              Enter Done Parcel
                            </label>
                            <Input
                              disabled={disabled}
                              type="number"
                              min="0"
                              className="form-control"
                              name="partTimeDoneParcels"
                              ref={editpartTimeDoneParcelsFocus}
                              id="partTimeDoneParcels"
                              value={editRiderParcel?.partTimeDoneParcels}
                              onChange={(value) =>
                                setEditRiderParcel({
                                  ...editRiderParcel,
                                  partTimeDoneParcels: value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.partTimeDoneParcels}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary view-btn"
                  hidden={submitButtonHide}
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default RiderParcel;
