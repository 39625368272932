import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Footer from "../../DashBoard/Footer";
import Header from "../../DashBoard/Header";
import SideBar from "../../DashBoard/SideBar";
import Sub_Header from "../../DashBoard/Sub_Header";
import * as action from "../../../action/Setting/setting_action";
import * as vehicalaction from "../../../action/Vehicle/vehicle_action";
import Pagination from "../../common/Pagination/Pagination";
import $ from "jquery";

let PageSize = 10;

const ExpenseForm = () => {
  const dispatch = useDispatch();
  const categoriesFocus = useRef();
  const chargeFocus = useRef();
  const dateFocus = useRef();
  const remarksFocus = useRef();
  const dateEditFocus = useRef();
  const categoryEditFocus = useRef();
  const chargeEditFocus = useRef();
  const remarksEditFocus = useRef();

  const [disabled, setDisabled] = useState(true);
  const [editButtonHide, setEditButtonHide] = useState(false);
  const [submitButtonHide, setSubmitButtonHide] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [errors, setErrors] = useState({});

  const [formId, setFormId] = useState();
  const [expenseCategories, setExpenseCategories] = useState([]);
  const [category, setCategory] = useState({
    value: "",
    label: "",
  });

  const [editCategory, setEditCategory] = useState({
    value: "",
    label: "",
  });

  const [expenseCategoryData, setExpenseCategoryData] = useState({
    issueDate: "",
    categoryId: "",
    categoryName: "",
    repairCharges: "",
    remarks: "",
  });

  const [editExpenseCategoryData, setEditExpenseCategoryData] = useState({
    issueDate: "",
    categoryId: "",
    categoryName: "",
    repairCharges: "",
    remarks: "",
  });

  const [expenseIssuesData, setExpenseIssuesData] = useState([]);

  const setEditFormEditable = (type) => {
    if (type) {
      setDisabled(false);
      //   setCancelButtonHide(false);
      setSubmitButtonHide(false);
      setEditButtonHide(true);
    } else {
      setDisabled(true);
      //   setCancelButtonHide(true);
      setSubmitButtonHide(true);
      setEditButtonHide(false);
    }
  };

  //get all categories from expense configuration
  useEffect(() => {
    dispatch(action.Get_All_Expense_category())
      .then((data) => {
        let allExpense = data?.data;

        const expenseData = allExpense.map((item) => {
          return {
            categoryId: item._id,
            categoryName: item.category,
            repairCharges: item.repairCharges,
            remarks: item?.remarks,
          };
        });
        setExpenseCategories(expenseData);
      })
      .catch((err) => {
        toast.error(err);
      });
  }, []);

  useEffect(() => {
    dispatch(vehicalaction.Get_All_Expense_Form())
      .then((data) => {
        let allExpenseFormData = data?.data;
        setExpenseIssuesData(allExpenseFormData);
      })
      .catch((err) => {
        toast.error(err);
      });
  }, []);

  const changeCategory = (e) => {
    let category = e.target.value;

    expenseCategories.map((item) => {
      if (item.categoryId === category) {
        setExpenseCategoryData({
          ...expenseCategoryData,
          categoryId: category,
          repairCharges: item.repairCharges,
          categoryName: item.categoryName,
          remarks: item?.remarks,
        });

        setCategory({
          ...category,
          value: item.categoryId,
          label: item.categoryName,
        });
      }
    });
  };

  const changeUpdateCategory = (e) => {
    let category = e.target.value;

    expenseCategories.map((item) => {
      if (item.categoryId === category) {
        setEditExpenseCategoryData({
          ...editExpenseCategoryData,
          categoryId: category,
          repairCharges: item.repairCharges,
          categoryName: item.categoryName,
          remarks: item?.remarks,
        });

        setEditCategory({
          ...category,
          value: item.categoryId,
          label: item.categoryName,
        });
      }
    });
  };

  const changeDate = (e) => {
    let date = e.target.value;
    setExpenseCategoryData({
      ...expenseCategoryData,
      issueDate: date,
    });
  };

  const changeRepairCharge = (e) => {
    let charge = e.target.value;
    setExpenseCategoryData({
      ...expenseCategoryData,
      repairCharges: charge,
    });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    let formData = expenseCategoryData;

    setErrors({});
    let customErrors = {};
    if (!/^(\d{4})-(\d{2})-(\d{2})$/.test(formData.issueDate)) {
      customErrors = { ...customErrors, date: "Please Select Date" };
      dateFocus.current.focus();
    } else if (!/^[0-9a-zA-Z\-\s]+$/.test(formData.categoryName)) {
      customErrors = { ...customErrors, category: "Please Select Category" };
      categoriesFocus.current.focus();
    } else if (!/^[0-9a-zA-Z\-\s]+$/.test(formData.repairCharges)) {
      customErrors = {
        ...customErrors,
        charge: "Please Enter Repair Charges",
      };
      chargeFocus.current.focus();
    }

    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }

    dispatch(vehicalaction.Add_Expense_Form(formData))
      .then((data) => {
        toast.success(data.message);
        setExpenseIssuesData(data.data);
        $("#addExpenseForm .close").click();
      })
      .catch((err) => {
        toast.error(err);
      });

    setErrors({});
    setExpenseCategoryData({
      categoryId: "",
      repairCharges: "",
      categoryName: "",
      issueDate: "",
      remarks: "",
    });
  };

  const changeUpdateDate = (e) => {
    let date = e.target.value;
    setEditExpenseCategoryData({
      ...editExpenseCategoryData,
      issueDate: date,
    });
  };

  const onUpdateHandler = (e) => {
    e.preventDefault();

    let formData = editExpenseCategoryData;

    setErrors({});
    let customErrors = {};
    if (!/^(\d{4})-(\d{2})-(\d{2})$/.test(formData.issueDate)) {
      customErrors = { ...customErrors, date: "Please Select Date" };
      dateFocus.current.focus();
    } else if (!/^[0-9a-zA-Z\-\s]+$/.test(formData.categoryName)) {
      customErrors = { ...customErrors, category: "Please Select Category" };
      categoriesFocus.current.focus();
    } else if (!/^[0-9a-zA-Z\-\s]+$/.test(formData.repairCharges)) {
      customErrors = {
        ...customErrors,
        charge: "Please Enter Repair Charges",
      };
      chargeFocus.current.focus();
    }

    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }

    dispatch(vehicalaction.Edit_Expense_Issue(formId ? formId : null, formData))
      .then((data) => {
        toast.success(data.message);
        $("#editExpenseForm .close").click();
        setExpenseIssuesData(data.data);
        setEditFormEditable(false);
      })
      .catch((error) => {
        toast.error(error);
      });
    setErrors({});
  };

  const handleEditClick = (item) => {
    setFormId(item._id);
    setEditCategory({
      value: item.categoryId,
      label: item.categoryName,
    });
    setEditExpenseCategoryData({
      issueDate: item.issueDate.slice(0, 10),
      categoryId: item.categoryId,
      categoryName: item.categoryName,
      repairCharges: item.repairCharges,
      remarks: item?.remarks,
    });
  };

  const handleCancelClick = () => {
    setErrors({});
    setExpenseCategoryData({
      categoryId: "",
      repairCharges: "",
      categoryName: "",
      issueDate: "",
      remarks: "",
    });
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return expenseIssuesData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, expenseIssuesData]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Sub_Header />
      <div className="main-container" id="container">
        <div className="overlay"></div>
        <div className="search-overlay"></div>
        <SideBar />
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                <div className="widget-content widget-content-area br-6">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="" style={{ margin: "10px" }}>
                      Expense List
                    </h5>
                    <a
                      href="/"
                      className="btn btn-warning btn-sm add-act-btn"
                      data-toggle="modal"
                      data-target="#addExpenseForm"
                    >
                      Add
                    </a>
                  </div>

                  <div className="table-responsive mb-4 mt-4">
                    {currentTableData.length > 0 ? (
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Category</th>
                            <th>Charge</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentTableData.map((item, i) => {
                            return (
                              <tr key={i}>
                                <td>{item.issueDate.slice(0, 10)}</td>
                                <td>{item.categoryName}</td>
                                <td>{item.repairCharges}</td>
                                <td>
                                  <a
                                    href="/"
                                    onClick={() => handleEditClick(item)}
                                    className="btn btn-info btn-sm edit-btn edit-act-btn"
                                    data-toggle="modal"
                                    data-target="#editExpenseForm"
                                  >
                                    Edit
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <p className="text-center">No data Available</p>
                    )}
                    {/* pagination */}
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={expenseIssuesData.length}
                      pageSize={PageSize}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>

                  {/* add model */}
                  <div
                    className="modal fade"
                    id="addExpenseForm"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="addExpenseFormTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered modal-md"
                      role="document"
                    >
                      <div className="modal-content">
                        <form onSubmit={onSubmitHandler}>
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="addHubModalCenterTitle"
                            >
                              Add Expense Form
                            </h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <svg
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-x"
                              >
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                              </svg>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div id="append-div-id">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group ">
                                    <div className="form-group  required">
                                      <label htmlFor="date">Date *</label>
                                      <input
                                        type="date"
                                        name="date"
                                        ref={dateFocus}
                                        className="form-control datepicker"
                                        id="date"
                                        onChange={(e) => changeDate(e)}
                                        value={expenseCategoryData.issueDate}
                                        autoComplete="off"
                                      />
                                      <span style={{ color: "red" }}>
                                        {errors?.date}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <div className="form-group col-md-12 required">
                                      <label htmlFor="inputCategories">
                                        Categories *
                                      </label>
                                      <select
                                        id="inputCategories"
                                        ref={categoriesFocus}
                                        name="category"
                                        className="form-control selectpicker"
                                        onChange={(e) => changeCategory(e)}
                                        value={category.value}
                                      >
                                        <option>Select Categories</option>
                                        {expenseCategories.map((item) => {
                                          return (
                                            <option
                                              value={item.categoryId}
                                              key={item.categoryId}
                                            >
                                              {item.categoryName}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      <span style={{ color: "red" }}>
                                        {errors?.category}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <div className="form-group col-md-12 required">
                                      <label htmlFor="inputCharge">
                                        Charge *
                                      </label>
                                      <input
                                        type="number"
                                        min="0"
                                        className="form-control"
                                        id="inputCharge"
                                        ref={chargeFocus}
                                        placeholder="Enter Repair Charge"
                                        name="charge"
                                        onChange={(e) => changeRepairCharge(e)}
                                        defaultValue={
                                          expenseCategoryData.repairCharges
                                        }
                                        value={
                                          expenseCategoryData.repairCharges
                                        }
                                        autoComplete="off"
                                      />
                                      <span style={{ color: "red" }}>
                                        {errors?.charge}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <div className="form-group col-md-12 required">
                                      <label htmlFor="inputRemark">
                                        Remarks
                                      </label>
                                      <textarea
                                        type="text"
                                        className="form-control"
                                        id="inputRemark"
                                        ref={remarksFocus}
                                        placeholder="Enter Remarks for Expense"
                                        name="remarks"
                                        onChange={(e) =>
                                          setExpenseCategoryData({
                                            ...expenseCategoryData,
                                            remarks: e.target.value,
                                          })
                                        }
                                        value={expenseCategoryData.remarks}
                                        autoComplete="off"
                                      />
                                      <span style={{ color: "red" }}>
                                        {errors?.remarks}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="reset"
                              data-dismiss="modal"
                              className="btn btn-danger "
                              onClick={handleCancelClick}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="btn btn-success save-btn"
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  {/* update model */}
                  <div
                    className="modal fade"
                    id="editExpenseForm"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="editExpenseFormTitle"
                    style={{ display: "none" }}
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered modal-md"
                      role="document"
                    >
                      <div className="modal-content">
                        <form onSubmit={onUpdateHandler}>
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="editExpenseFormTitle"
                            >
                              Update Expense Form
                            </h5>
                            <button
                              type="button"
                              className="btn btn-primary view-btn"
                              hidden={editButtonHide}
                              onClick={() => setEditFormEditable(true)}
                            >
                              Edit
                            </button>
                          </div>

                          <div className="modal-body">
                            <div
                              className="customer-address-class"
                              id="customer-address-div-id"
                            >
                              <div className="row" id="defaultaddressdiv0">
                                <div className="col-md-12">
                                  <div className="form-group  required">
                                    <label htmlFor="date">Date *</label>
                                    <input
                                      disabled={disabled}
                                      type="date"
                                      name="date"
                                      ref={dateEditFocus}
                                      className="form-control datepicker"
                                      id="date"
                                      onChange={(e) => changeUpdateDate(e)}
                                      value={editExpenseCategoryData.issueDate}
                                      autoComplete="off"
                                    />
                                    <span style={{ color: "red" }}>
                                      {errors?.date}
                                    </span>
                                  </div>

                                  <div className="form-group required">
                                    <label htmlFor="inputCategories">
                                      Categories *
                                    </label>
                                    <select
                                      disabled={disabled}
                                      type="text"
                                      ref={categoryEditFocus}
                                      className="form-control"
                                      name="cateory"
                                      id="inputName-update"
                                      placeholder="Enter Name"
                                      value={editCategory.value}
                                      onChange={(e) => changeUpdateCategory(e)}
                                    >
                                      <option>Select Categories</option>
                                      {expenseCategories.map((item) => {
                                        return (
                                          <option
                                            value={item.categoryId}
                                            key={item.categoryId}
                                          >
                                            {item.categoryName}
                                          </option>
                                        );
                                      })}
                                    </select>
                                    <span style={{ color: "red" }}>
                                      {errors?.category}
                                    </span>

                                    <br />
                                    <label htmlFor="inputCharge-add">
                                      Charge *
                                    </label>
                                    <input
                                      disabled={disabled}
                                      type="number"
                                      min="0"
                                      className="form-control"
                                      ref={chargeEditFocus}
                                      name="repairCharges"
                                      id="inputCharge-add"
                                      placeholder="Enter Charge Amount"
                                      onChange={(e) =>
                                        setEditExpenseCategoryData({
                                          ...editExpenseCategoryData,
                                          repairCharges: e.target.value,
                                        })
                                      }
                                      value={
                                        editExpenseCategoryData.repairCharges
                                      }
                                    />
                                    <span style={{ color: "red" }}>
                                      {errors?.repairCharges}
                                    </span>

                                    <div className="form-group row">
                                      <div className="form-group col-md-12 required">
                                        <label htmlFor="inputRemark">
                                          Remarks
                                        </label>
                                        <textarea
                                          disabled={disabled}
                                          type="text"
                                          className="form-control"
                                          id="inputRemark"
                                          ref={remarksEditFocus}
                                          placeholder="Enter Remarks for Expense"
                                          name="remarks"
                                          onChange={(e) =>
                                            setEditExpenseCategoryData({
                                              ...editExpenseCategoryData,
                                              remarks: e.target.value,
                                            })
                                          }
                                          value={
                                            editExpenseCategoryData.remarks
                                          }
                                          autoComplete="off"
                                        />
                                        <span style={{ color: "red" }}>
                                          {errors?.remarks}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-danger "
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              Close
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary view-btn"
                              hidden={submitButtonHide}
                            >
                              Update
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </>
  );
};

export default ExpenseForm;
