import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
// import Loader from "../../common/Loader/Loader";
import Footer from "../../DashBoard/Footer";
import Header from "../../DashBoard/Header";
import SideBar from "../../DashBoard/SideBar";
import Sub_Header from "../../DashBoard/Sub_Header";
import Switch from "react-switch";
import * as action from "../../../action/Vehicle/vehicle_action";
import { useDispatch } from "react-redux";

const SettingVehicle = () => {
  const dispatch = useDispatch();
  const [toggleValue, setToggleValue] = useState(false);

  useEffect(() => {
    dispatch(action.Scan_Bike_Mandatory_Status())
      .then((data) => {
        setToggleValue(data.currentStatus);
      })
      .catch((err) => {
        toast.error(err);
      });
  }, []);

  const handleToggleChange = (value) => {
    dispatch(action.Scan_Bike_Mandatory(value))
      .then((data) => {
        setToggleValue(data.allowStatus);
        toast.success(data.message);
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Sub_Header />
      <div className="main-container" id="container">
        {/* {loading ? <Loader /> : null} */}
        <div className="overlay"></div>
        <div className="search-overlay"></div>

        <SideBar></SideBar>
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                <div className="widget-content widget-content-area br-6">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="m-2">Settings</h5>
                  </div>
                  <div className="d-flex justify-content-between align-items-center m-4">
                    <div>Scan Bike Mandatory </div>
                    <div>
                      <Switch
                        onChange={handleToggleChange}
                        checked={toggleValue}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </>
  );
};

export default SettingVehicle;
