import React from "react";
import { saveAs } from "file-saver";
import XLSX from "sheetjs-style";
import Button from "rsuite/Button";

const CODExportToExcel = ({ excelData }) => {
  const exportToExcel = async () => {
    // Custom column headers
    const headers = [
      "Date",
      "Driver ID",
      "Driver Name",
      "Number of Orders",
      "Amount",
    ];

    // Map the data to include custom headers and corresponding values
    const mappedData = excelData.map((item) => ({
      Date: item.date.slice(0, 10),
      "Driver ID": `EV${item.driverEvId}`,
      "Driver Name": item.driverName,
      "Number of Orders": item.orders,
      Amount: item.amount,
    }));

    const worksheet = XLSX.utils.json_to_sheet(mappedData, { header: headers });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const dataBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    saveAs(dataBlob, "CODOrders.xlsx");
  };
  return (
    <div>
      <Button
        color="green"
        appearance="primary"
        onClick={() => exportToExcel()}
      >
        Export Excel
      </Button>
    </div>
  );
};

export default CODExportToExcel;
