import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import Chart from "react-apexcharts";
import * as action from "../../../../action/Dashboard/dashboard_action";
import * as settingaction from "../../../../action/Setting/setting_action";
import { DateRangePicker } from "rsuite";

const ExpenseChart = () => {
  const dispatch = useDispatch();
  // repair expenses - driver issue categories
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [dateRangeInput, setDateRangeInput] = useState([]);
  const [repairFilterData, setRepairFilterData] = useState({
    categories: JSON.stringify([]),
    dateRange: JSON.stringify([]),
  });

  const [repairExpenseData, setRepairExpenseData] = useState({
    months: [],
    repairExpense: [],
    vehicles: [],
  });

  const months = [
    {
      value: 1,
      label: "January",
    },
    {
      value: 2,
      label: "February",
    },
    {
      value: 3,
      label: "March",
    },
    {
      value: 4,
      label: "April",
    },
    {
      value: 5,
      label: "May",
    },
    {
      value: 6,
      label: "June",
    },
    {
      value: 7,
      label: "July",
    },
    {
      value: 8,
      label: "August",
    },
    {
      value: 9,
      label: "September",
    },
    {
      value: 10,
      label: "October",
    },
    {
      value: 11,
      label: "November",
    },
    {
      value: 12,
      label: "December",
    },
  ];

  //  driver issue categories
  useEffect(() => {
    dispatch(settingaction.Get_All_Expense_category())
      .then((data) => {
        let categoriesData = data.data;

        const categories = categoriesData.map((category) => ({
          value: category._id,
          label: category.category,
        }));

        categories.sort((a, b) => b.value - a.value);

        setCategoryList(categories);
      })
      .catch((err) => {});
  }, []);

  // set daterange and category for repair expense chart
  useEffect(() => {
    let datRange = dateRangeInput;
    let categories = [];

    const isoDates = datRange?.map((dateString) => {
      const date = new Date(dateString);
      return date;
    });

    selectedCategory.map((item) => {
      categories.push(item.value);
    });

    setRepairFilterData({
      ...repairFilterData,
      dateRange: JSON.stringify(isoDates ? isoDates : []),
      categories: JSON.stringify(categories),
    });
  }, [dateRangeInput, selectedCategory]);

  // repair expenses
  useEffect(() => {
    dispatch(action.dashboard_repair_expense_monthly(repairFilterData))
      .then((data) => {
        let expenseRepairData = data?.data?.expenseRepairData;
        let expenseData = [];
        let vehicleRepairData = [];
        let monthsRangeList = [];

        expenseRepairData?.map((item) => {
          expenseData.push(item?.expense);
          vehicleRepairData.push(item?.vehicles);
          monthsRangeList.push(months[item?.month - 1].label);
        });

        setRepairExpenseData({
          ...repairExpenseData,
          months: monthsRangeList,
          repairExpense: expenseData,
          vehicles: vehicleRepairData,
        });
      })
      .catch({});
  }, [repairFilterData, dateRangeInput, selectedCategory]);

  // Monthly Repair Expense & No Of Vehicle Under Repair
  var repairExpenseChartoptions = {
    chart: {
      fontFamily: "Apple-System, sans-serif",
      height: 305,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    colors: ["#307BF6", "#f9c007"],
    stroke: {
      width: [0, 7],
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    series: [
      {
        name: "Repair Expense (₹)",
        type: "bar",
        data: repairExpenseData.repairExpense,
      },
      {
        name: "No. of Vehicle Under Repair ",
        type: "line",
        data: repairExpenseData.vehicles,
      },
    ],

    xaxis: {
      categories: repairExpenseData.months,
    },

    legend: {
      position: "bottom",
      containerMargin: {
        left: 35,
        right: 60,
      },
    },
  };

  return (
    <div className="mb-4 layout-spacing widget widget-chart-one">
      <div className="widget-heading">
        <h5 className="">
          Monthly Repair Expense & No Of Vehicle Under Repair
        </h5>
        <ul className="tabs  d-flex  justify-content-center align-items-center">
          <li>
            <a id="tb_1" className="tabmenu">
              Monthly
            </a>
          </li>
        </ul>
      </div>
      <ul className="list-unstyled  row ">
        <li className="mx-2  d-flex flex-column">
          <label>Date Range</label>

          <DateRangePicker
            value={dateRangeInput}
            onChange={(value) => {
              setDateRangeInput(value);
            }}
            placeholder="Select Date Range"
            styles={{ width: 150 }}
          />
        </li>
        <li className="mx-2">
          <label>Issue Categories</label>
          <Select
            isMulti
            id="inputYear"
            value={selectedCategory}
            onChange={(value) => {
              setSelectedCategory(value);
            }}
            options={categoryList}
            styles={{ width: 150 }}
          />
        </li>
      </ul>

      <div id="chart">
        {repairExpenseData.repairExpense.length > 0 ||
        repairExpenseData.vehicles.length > 0 ? (
          <Chart
            options={repairExpenseChartoptions}
            series={repairExpenseChartoptions.series}
            height={305}
          />
        ) : (
          <p className="text-center">No Data Found</p>
        )}
      </div>
    </div>
  );
};

export default ExpenseChart;
