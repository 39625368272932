import firebase from "firebase/compat/app";
import "firebase/compat/messaging";

// rest of the code remains the same

const firebaseConfig = {
  apiKey: "AIzaSyBmPt4-8vcN_2Njfvj0WP2eYzJxrUzXPlA",
  authDomain: "evify-app-627cf.firebaseapp.com",
  projectId: "evify-app-627cf",
  storageBucket: "evify-app-627cf.appspot.com",
  messagingSenderId: "458983408513",
  appId: "1:458983408513:web:4aa91b6943d6fa2271de80",
};

var userAgent = navigator.userAgent || navigator.vendor || window.opera;
var isiOSDevice = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
const firebaseApp = !isiOSDevice ? firebase.initializeApp(firebaseConfig) : {};

let messaging = null;

if (!isiOSDevice) {
  messaging = firebaseApp.messaging();
}

export const requestFirebaseNotificationPermission = () => {
  if (isiOSDevice) {
    return Promise.resolve(null);
  }

  return Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      return messaging.getToken().then((firebaseToken) => {
        return firebaseToken;
      });
    } else {
      throw new Error("Permission denied");
    }
  });
};

export const onMessageListener = () => {
  if (isiOSDevice) {
    return Promise.resolve(null);
  }

  return new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
};
