import {
  DRIVER_NOT_TAKEN_SELFIE_LIST_SUCCESSFULL,
  DRIVER_NOT_TAKEN_SELFIE_LIST_ERROR,
  DRIVER_NOT_TAKEN_SELFIE_LIST_LOADING,
} from "../../action/type";

const INITIAL_STATE = {
  userData: {},
  error_msg: "",
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DRIVER_NOT_TAKEN_SELFIE_LIST_LOADING: {
      return Object.assign({}, state, { loading: true });
    }
    case DRIVER_NOT_TAKEN_SELFIE_LIST_SUCCESSFULL: {
      return Object.assign({}, state, {
        driverIssuesData: action.data,
        loading: false,
      });
    }
    case DRIVER_NOT_TAKEN_SELFIE_LIST_ERROR: {
      return Object.assign({}, state, {
        error_msg: action.data.error_msg,
        loading: false,
      });
    }
    default:
      return state;
  }
};
