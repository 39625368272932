import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../DashBoard/Header";
import Sub_Header from "../../DashBoard/Sub_Header";
import SideBar from "../../DashBoard/SideBar";
import Footer from "../../DashBoard/Footer";
import Loader from "../../common/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { GoogleMap, LoadScriptNext } from "@react-google-maps/api";
import { Marker, InfoWindow } from "@react-google-maps/api";
import * as action from "../../../action/Customer/add_customer_action";
import * as login_action from "../../../action/Auth/login_action";
const AddCustomer = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const gstNumberFocus = useRef();
  const nameFocus = useRef();
  const ownershipFocus = useRef();
  const businessNameFocus = useRef();
  const businessTypeFocus = useRef();
  const emailFocus = useRef();
  const mobileFocus = useRef();
  const paymentTypeFocus = useRef();
  const planFocus = useRef();
  const shiftFocus = useRef();
  const addressFocus = useRef();
  const [errors, setErrors] = useState({});
  const { loading } = useSelector((state) => state.addCustomerReducer);
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  useEffect(() => {
    dispatch(login_action.state_list())
      .then((data) => {
        let newStateList = [];
        data.data.map((item) => {
          newStateList.push({
            value: item.stateId,
            label: item.stateName,
          });
        });
        setStateList(newStateList);
      })
      .catch({});
  }, []);
  const changeStateDropDown = (data, AddressId) => {
    let oldAddress = addressRow.filter((item) => item.id === AddressId)?.[0];
    oldAddress = { ...oldAddress, ["stateId"]: data.value };
    oldAddress = { ...oldAddress, ["state"]: data.label };
    oldAddress = { ...oldAddress, ["stateValue"]: data };
    let newAddress = [];
    addressRow.map((item) => {
      if (item.id === AddressId) {
        newAddress.push(oldAddress);
      } else newAddress.push(item);
    });
    setAddressRow(newAddress);
    dispatch(login_action.city_list_by_state(data.value))
      .then((data) => {
        let newCityList = [];
        data.data.map((item) => {
          newCityList.push({
            value: item.cityId,
            label: item.cityName,
          });
        });
        setCityList(newCityList);
      })
      .catch({});
  };
  const changeCityDropDown = (data, AddressId) => {
    let oldAddress = addressRow.filter((item) => item.id === AddressId)?.[0];
    oldAddress = { ...oldAddress, ["cityId"]: data.value };
    oldAddress = { ...oldAddress, ["city"]: data.label };
    oldAddress = { ...oldAddress, ["cityValue"]: data };
    let newAddress = [];
    addressRow.map((item) => {
      if (item.id === AddressId) {
        newAddress.push(oldAddress);
      } else newAddress.push(item);
    });
    setAddressRow(newAddress);
    // setAddress({...address, cityId:data.value, city:data.label});
  };
  const [input, setInput] = useState({
    gstNumber: "",
    colorCode: "#cfcfcf",
    name: "",
    ownership: "",
    businessName: "",
    businessType: "",
    email: "",
    mobile: "",
    paymentType: "",
  });
  const [addressRow, setAddressRow] = useState([
    {
      id: 1,
      address: "",
      dispatchAddress: "",
      pincode: "",
      country: "India",
      stateId: "",
      state: "",
      stateValue: "",
      cityId: "",
      city: "",
      cityValue: "",
      zone: "",
      landmark: "",
      contactPersonName: "",
      contactPersonMobile: "",
      latitude: "",
      longitude: "",
      isDefault: false,
    },
  ]);
  const [planRow, setPlanRow] = useState([
    { id: 1, plan: "", customer_price: "", days: "", driver_price: "" },
  ]);
  const [shiftRow, setShiftRow] = useState([
    { id: 1, shift_name: "", shift_start_time: "", shift_end_time: "" },
  ]);
  const containerStyle = {
    width: "100%",
    height: "400px",
  };
  const center = { lat: 21.17024, lng: 72.831062 };
  const changeLetLong = (e, AddressId) => {
    let latitude = e.latLng.lat();
    let longitude = e.latLng.lng();
    let oldAddress = addressRow.filter((item) => item.id === AddressId)?.[0];
    oldAddress = { ...oldAddress, ["latitude"]: latitude };
    oldAddress = { ...oldAddress, ["longitude"]: longitude };
    let newAddress = [];
    addressRow.map((item) => {
      if (item.id === AddressId) {
        newAddress.push(oldAddress);
      } else newAddress.push(item);
    });
    setAddressRow(newAddress);
    // setAddress({...address, latitude:latitude, longitude:longitude});
  };
  const addExtraPlanInForm = () => {
    setPlanRow([
      ...planRow,
      {
        id: planRow.length + 1,
        plan: "",
        customer_price: "",
        days: "",
        driver_price: "",
      },
    ]);
  };
  const addShiftInForm = () => {
    setShiftRow([
      ...shiftRow,
      {
        id: shiftRow.length + 1,
        shift_name: "",
        shift_start_time: "",
        shift_end_time: "",
      },
    ]);
  };
  const addAddressInForm = () => {
    setAddressRow([
      ...addressRow,
      {
        id: addressRow.length + 1,
        address: "",
        dispatchAddress: "",
        pincode: "",
        country: "India",
        stateId: "",
        state: "",
        stateValue: "",
        cityId: "",
        city: "",
        cityValue: "",
        zone: "",
        landmark: "",
        contactPersonName: "",
        contactPersonMobile: "",
        latitude: "",
        longitude: "",
        isDefault: false,
      },
    ]);
  };
  const deletAddressInForm = (AddressId) => {
    setAddressRow(addressRow.filter((item) => item.id !== AddressId));
  };
  const deletextraPlanInForm = (planId) => {
    setPlanRow(planRow.filter((item) => item.id !== planId));
  };
  const deletShiftInForm = (ShiftId) => {
    setShiftRow(shiftRow.filter((item) => item.id !== ShiftId));
  };
  const addPlanValue = (e, planId) => {
    let oldPlan = planRow.filter((item) => item.id === planId)?.[0];
    oldPlan = { ...oldPlan, [e.target.name]: e.target.value };
    let newPlan = [];
    planRow.map((item) => {
      if (item.id === planId) {
        newPlan.push(oldPlan);
      } else newPlan.push(item);
    });
    setPlanRow(newPlan);
  };
  const addShiftValue = (e, ShiftId) => {
    let oldShift = shiftRow.filter((item) => item.id === ShiftId)?.[0];
    oldShift = { ...oldShift, [e.target.name]: e.target.value };
    let newShift = [];
    shiftRow.map((item) => {
      if (item.id === ShiftId) {
        newShift.push(oldShift);
      } else newShift.push(item);
    });
    setShiftRow(newShift);
  };
  const addAddressValue = (e, AddressId) => {
    let oldAddress = addressRow.filter((item) => item.id === AddressId)?.[0];
    oldAddress = { ...oldAddress, [e.target.name]: e.target.value };
    let newAddress = [];
    addressRow.map((item) => {
      if (item.id === AddressId) {
        newAddress.push(oldAddress);
      } else newAddress.push(item);
    });
    setAddressRow(newAddress);
  };
  const customFilter = (option, inputValue) => {
    const input = inputValue.toLowerCase();
    const filterText = new RegExp("^" + input + ".*");
    const label = option.data.label.toLowerCase();
    return filterText.test(label) && option.label.toLowerCase().includes(input);
  };
  const onsubmitHandler = (e) => {
    e.preventDefault();
    setErrors({});
    let customErrors = {};
    let shiftErrors = {};
    let planErrors = {};
    planRow.map((item) => {
      let errors = {};
      if (input.paymentType == 2) {
        if (item.plan === "") {
          errors = { ...errors, plan: "Please enter plan name" };
        }
        if (item.customer_price === "") {
          errors = { ...errors, customer_price: "Please enter customer price" };
        }
        if (item.driver_price === "") {
          errors = { ...errors, driver_price: "Please enter driver price" };
        }
      } else {
        if (item.plan === "") {
          errors = { ...errors, plan: "Please enter plan name" };
        }
        if (item.customer_price === "") {
          errors = { ...errors, customer_price: "Please enter customer price" };
        }
        if (item.days === "") {
          errors = { ...errors, days: "Please enter days" };
        }
      }
      if (Object.keys(errors).length > 0) {
        planErrors[item.id] = errors;
      }
    });
    shiftRow.map((item) => {
      let errors = {};
      if (item.shift_name === "") {
        errors = { ...errors, shift_name: "Please enter shift name" };
      }
      if (item.shift_start_time === "") {
        errors = {
          ...errors,
          shift_start_time: "Please enter shift start time",
        };
      }
      if (item.shift_end_time === "") {
        errors = { ...errors, shift_end_time: "Please enter shift end time" };
      }
      if (Object.keys(errors).length > 0) {
        shiftErrors[item.id] = errors;
      }
    });

    let addressErrors = {};
    addressRow.map((item) => {
      let errors = {};
      if (!/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/.test(item.pincode)) {
        errors = { ...errors, pincode: "Please enter valid pincode" };
      }
      if (item.state === "") {
        errors = { ...errors, state: "Please select state" };
      }
      if (item.city === "") {
        errors = { ...errors, city: "Please select city" };
      }
      if (item.zone === "") {
        errors = { ...errors, zone: "Please enter zone" };
      }
      if (item.address === "") {
        errors = { ...errors, address: "Please enter address" };
      }
      if (item.dispatchAddress === "") {
        errors = {
          ...errors,
          dispatchAddress: "Please enter dispatch address",
        };
      }
      if (item.latitude === "") {
        errors = { ...errors, latitude: "Please enter latitude" };
      }
      if (item.longitude === "") {
        errors = { ...errors, longitude: "Please enter longitude" };
      }
      if (Object.keys(errors).length > 0) {
        addressErrors[item.id] = errors;
      }
    });
    if (
      !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(
        input.gstNumber
      )
    ) {
      customErrors = {
        ...customErrors,
        gstNumber: "Please enter valid GST Number",
      };
      gstNumberFocus.current.focus();
    } else if (input.name === "") {
      customErrors = { ...customErrors, name: "Please enter name" };
      nameFocus.current.focus();
    } else if (input.ownership === "") {
      customErrors = { ...customErrors, ownership: "Please enter ownership" };
      ownershipFocus.current.focus();
    } else if (input.businessName === "") {
      customErrors = {
        ...customErrors,
        businessName: "Please enter business name",
      };
      businessNameFocus.current.focus();
    } else if (input.businessType === "") {
      customErrors = {
        ...customErrors,
        businessType: "Please enter business type",
      };
      businessTypeFocus.current.focus();
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input.email)
    ) {
      customErrors = {
        ...customErrors,
        email: "Plase enter valid email address",
      };
      emailFocus.current.focus();
    } else if (!/^[6789]\d{9}$/.test(input.mobile)) {
      customErrors = {
        ...customErrors,
        mobile: "Please enter valid mobile number",
      };
      mobileFocus.current.focus();
    } else if (input.paymentType === "") {
      customErrors = {
        ...customErrors,
        paymentType: "Please select payment type",
      };
      paymentTypeFocus.current.focus();
    } else if (Object.keys(planErrors).length > 0) {
      customErrors = { ...customErrors, plan: planErrors };
      planFocus.current.focus();
    } else if (Object.keys(shiftErrors).length > 0) {
      customErrors = { ...customErrors, shift: shiftErrors };
      shiftFocus.current.focus();
    } else if (Object.keys(addressErrors).length > 0) {
      customErrors = { ...customErrors, address: addressErrors };
      addressFocus.current.focus();
    }
    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }

    let address = [];
    let shift = [];
    let plan = [];
    shiftRow.map((item) => {
      shift.push({
        shiftName: item.shift_name,
        shiftStartTime: item.shift_start_time,
        shiftEndTime: item.shift_end_time,
      });
    });
    addressRow.map((item) => {
      address.push({
        address: item.address,
        dispatchAddress: item.dispatchAddress,
        pincode: item.pincode,
        country: "India",
        stateId: item.stateId,
        state: item.state,
        cityId: item.cityId,
        city: item.city,
        zone: item.zone,
        landmark: item.landmark,
        contactPersonName: item.contactPersonName,
        contactPersonMobile: item.contactPersonMobile,
        latitude: item.latitude,
        longitude: item.longitude,
        isDefault: item.isDefault === "on" ? true : false,
      });
    });
    planRow.map((item) => {
      if (input.paymentType == 2) {
        plan.push({
          planName: item.plan,
          planPriceCustomer: item.customer_price,
          planPriceDriver: item.driver_price,
        });
      } else {
        plan.push({
          planName: item.plan,
          planPriceCustomer: item.customer_price,
          planDays: item.days,
        });
      }
    });
    let parameter = {
      gstNumber: input.gstNumber,
      colorCode: input.colorCode,
      name: input.name,
      ownership: input.ownership,
      businessName: input.businessName,
      businessType: input.businessType,
      email: input.email,
      mobile: input.mobile,
      paymentType: input.paymentType,
      addresses: address,
      shifts: shift,
      plans: plan,
    };
    dispatch(action.add_customer(parameter))
      .then((data) => {
        navigate("/adminside/customer");
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Sub_Header />
      <div className="main-container" id="container">
        {loading ? <Loader /> : null}
        <div className="overlay"></div>
        <div className="search-overlay"></div>

        <SideBar></SideBar>
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                <div className="widget-content widget-content-area br-6">
                  <h5 className="">Add Customer</h5>
                  <br />

                  <form onSubmit={onsubmitHandler}>
                    <div className="form-group row">
                      <div className="form-group col-md-9 required">
                        <label htmlFor="inputGstNumber">GST Number *</label>
                        <div className="input-group">
                          <input
                            className="form-control"
                            ref={gstNumberFocus}
                            id="inputGstNumber"
                            name="gstNumber"
                            placeholder="Enter a valid GST Number"
                            onChange={(e) =>
                              setInput({
                                ...input,
                                gstNumber: e.target.value.toUpperCase(),
                              })
                            }
                            value={input.gstNumber}
                          />
                        </div>
                        <span style={{ color: "red" }}>
                          {errors?.gstNumber}
                        </span>
                      </div>
                      <div className="form-group col-md-3 required">
                        <label htmlFor="inputColorCode">Color Code *</label>
                        <div className="input-group">
                          <input
                            type="color"
                            className="form-control"
                            id="inputColorCode"
                            name="colorCode"
                            placeholder="Enter a valid Color Code"
                            onChange={(e) =>
                              setInput({ ...input, colorCode: e.target.value })
                            }
                            value={input.colorCode}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="form-group col-md-6 required">
                        <label htmlFor="inputFullname">Full Name *</label>
                        <input
                          type="text"
                          className="form-control"
                          ref={nameFocus}
                          id="inputFullname"
                          placeholder="Enter Full Name"
                          onChange={(e) =>
                            setInput({ ...input, name: e.target.value })
                          }
                          value={input.name}
                        />
                        <span style={{ color: "red" }}>{errors?.name}</span>
                      </div>
                      <div className="form-group col-md-6 required">
                        <label htmlFor="inputOwnership">OwnerShip *</label>
                        <input
                          type="text"
                          className="form-control"
                          ref={ownershipFocus}
                          id="inputOwnership"
                          placeholder="i.e. Proprietorship"
                          onChange={(e) =>
                            setInput({ ...input, ownership: e.target.value })
                          }
                          value={input.ownership}
                        />
                        <span style={{ color: "red" }}>
                          {errors?.ownership}
                        </span>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="form-group col-md-6 required">
                        <label htmlFor="inputBusinessName">
                          Business Name *
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          ref={businessNameFocus}
                          id="inputBusinessName"
                          placeholder="Enter Business Name"
                          onChange={(e) =>
                            setInput({ ...input, businessName: e.target.value })
                          }
                          value={input.businessName}
                        />
                        <span style={{ color: "red" }}>
                          {errors?.businessName}
                        </span>
                      </div>
                      <div className="form-group col-md-6 required">
                        <label htmlFor="inputBusinessType">
                          Business Type *
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          ref={businessTypeFocus}
                          id="inputBusinessType"
                          placeholder="i.e. Retail Business"
                          onChange={(e) =>
                            setInput({ ...input, businessType: e.target.value })
                          }
                          value={input.businessType}
                        />
                        <span style={{ color: "red" }}>
                          {errors?.businessType}
                        </span>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="form-group col-md-6 required">
                        <label htmlFor="inputEmail">Email </label>
                        <label className="form-error-msg"></label>
                        <input
                          type="text"
                          className="form-control"
                          ref={emailFocus}
                          id="inputEmail"
                          placeholder="Enter Valid Email Address"
                          onChange={(e) =>
                            setInput({ ...input, email: e.target.value })
                          }
                          value={input.email}
                        />
                        <span style={{ color: "red" }}>{errors?.email}</span>
                      </div>
                      <div className="form-group col-md-6 required">
                        <label htmlFor="inputMobile">Mobile Number *</label>
                        <label
                          className="form-error-msg"
                          id="mobileErrorMsg"
                        ></label>
                        <input
                          type="text"
                          className="form-control"
                          ref={mobileFocus}
                          id="inputMobile"
                          placeholder="Enter Mobile Number"
                          onChange={(e) =>
                            setInput({ ...input, mobile: e.target.value })
                          }
                          value={input.mobile}
                        />
                        <span style={{ color: "red" }}>{errors?.mobile}</span>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="form-group col-md-4 required">
                        <label htmlFor="inputPaymentType">
                          Select Payment Type *
                        </label>
                        <select
                          name="inputPaymentType"
                          id="inputPaymentType"
                          ref={paymentTypeFocus}
                          className="form-control selectpicker"
                          onChange={(e) =>
                            setInput({ ...input, paymentType: e.target.value })
                          }
                          value={input.paymentType}
                        >
                          <option value="">Select Payment Type</option>
                          <option value="1">Monthly</option>
                          <option value="2">Per Parcel</option>
                        </select>
                        <span style={{ color: "red" }}>
                          {errors?.paymentType}
                        </span>
                      </div>
                    </div>

                    <div className="form-group" id="payment-div-id">
                      <div className="form-group row" id="plan0">
                        {planRow.map((planItem) => (
                          <div key={planItem.id}>
                            <div
                              className="form-group col-md-4 required"
                              ref={planFocus}
                              tabIndex="-1"
                            >
                              <label htmlFor={`inputPlanName${planItem.id}`}>
                                Plan *
                              </label>
                              {planItem.id === 1 ? (
                                <span
                                  className="btn btn-warning btn-sm float-right ms-3 mb-1"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => addExtraPlanInForm()}
                                >
                                  Add Extra Plan
                                </span>
                              ) : (
                                <span
                                  className="btn btn-danger btn-sm float-right ms-3 mb-1"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    deletextraPlanInForm(planItem.id)
                                  }
                                >
                                  Delete This
                                </span>
                              )}

                              <input
                                type="text"
                                className="form-control"
                                id={`inputPlanName${planItem.id}`}
                                placeholder="Enter Plan"
                                name="plan"
                                value={planItem.plan}
                                onChange={(e) => addPlanValue(e, planItem.id)}
                              />
                              <span style={{ color: "red" }}>
                                {errors.plan
                                  ? errors.plan[planItem.id]?.plan
                                    ? errors.plan[planItem.id]?.plan
                                    : ""
                                  : ""}
                              </span>
                            </div>
                            <div className="form-group col-md-4 required">
                              <label
                                htmlFor={`inputPriceCustomer${planItem.id}`}
                              >
                                Customer Price *
                              </label>
                              <input
                                type="number"
                                className="form-control allowdecimal"
                                name="customer_price"
                                value={planItem.customer_price}
                                id={`inputPriceCustomer${planItem.id}`}
                                placeholder="Enter Customer Price"
                                onChange={(e) => addPlanValue(e, planItem.id)}
                              />
                              <span style={{ color: "red" }}>
                                {errors.plan
                                  ? errors.plan[planItem.id]?.customer_price
                                    ? errors.plan[planItem.id]?.customer_price
                                    : ""
                                  : ""}
                              </span>
                            </div>
                            {input.paymentType == 1 ? (
                              <div className="form-group col-md-4 days-div required">
                                <label htmlFor={`inputDays${planItem.id}`}>
                                  Days *
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id={`inputDays${planItem.id}`}
                                  name="days"
                                  value={planItem.days}
                                  placeholder="enter Days"
                                  max="30"
                                  min="0"
                                  onChange={(e) => addPlanValue(e, planItem.id)}
                                />
                                <span style={{ color: "red" }}>
                                  {errors.plan
                                    ? errors.plan[planItem.id]?.days
                                      ? errors.plan[planItem.id]?.days
                                      : ""
                                    : ""}
                                </span>
                              </div>
                            ) : input.paymentType == 2 ? (
                              <div className="form-group col-md-4 driver-price-div required">
                                <label
                                  htmlFor={`inputPriceDriver${planItem.id}`}
                                >
                                  Driver Price *
                                </label>
                                <input
                                  type="number"
                                  name="driver_price"
                                  value={planItem.driver_price}
                                  className="form-control allowdecimal"
                                  id={`inputPriceDriver${planItem.id}`}
                                  placeholder="Enter Driver Price"
                                  maxlength="10"
                                  onChange={(e) => addPlanValue(e, planItem.id)}
                                />
                                <span style={{ color: "red" }}>
                                  {errors.plan
                                    ? errors.plan[planItem.id]?.driver_price
                                      ? errors.plan[planItem.id]?.driver_price
                                      : ""
                                    : ""}
                                </span>
                              </div>
                            ) : (
                              <div className="form-group col-md-4 days-div required">
                                <label htmlFor={`inputDays${planItem.id}`}>
                                  Days *
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id={`inputDays${planItem.id}`}
                                  name="days"
                                  value={planItem.days}
                                  placeholder="enter Days"
                                  max="30"
                                  min="0"
                                  onChange={(e) => addPlanValue(e, planItem.id)}
                                />
                                <span style={{ color: "red" }}>
                                  {errors.plan
                                    ? errors.plan[planItem.id]?.days
                                      ? errors.plan[planItem.id]?.days
                                      : ""
                                    : ""}
                                </span>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>

                    <div id="append-div-id">
                      {shiftRow.map((shiftItem) => (
                        <div key={shiftItem.id}>
                          <div className="d-flex justify-content-end">
                            {shiftItem.id === 1 ? (
                              <div
                                className="btn btn-warning btn-sm float-right ms-3 mb-1"
                                style={{ cursor: "pointer" }}
                                onClick={() => addShiftInForm()}
                              >
                                Add Extra Shifts
                              </div>
                            ) : (
                              <div
                                className="badge badge-danger float-right"
                                style={{ cursor: "pointer" }}
                                onClick={() => deletShiftInForm(shiftItem.id)}
                              >
                                Delete This
                              </div>
                            )}
                          </div>
                          <div
                            className="form-group row"
                            ref={shiftFocus}
                            tabIndex="-1"
                          >
                            <div className="form-group col-md-6">
                              <label htmlFor={`inputShiftName${shiftItem.id}`}>
                                Shift Name *
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id={`inputShiftName${shiftItem.id}`}
                                name="shift_name"
                                placeholder="Enter Shift Name"
                                value={shiftItem.shift_name}
                                onChange={(e) => addShiftValue(e, shiftItem.id)}
                              />
                              <span style={{ color: "red" }}>
                                {errors.shift
                                  ? errors.shift[shiftItem.id]?.shift_name
                                    ? errors.shift[shiftItem.id].shift_name
                                    : ""
                                  : ""}
                              </span>
                            </div>
                            <div className="form-group col-md-6">
                              <div className="form-group row">
                                <div className="form-group col-md-6">
                                  <label
                                    htmlFor={`inputShiftStartTime${shiftItem.id}`}
                                  >
                                    Shift Start Time *
                                  </label>
                                  <input
                                    id={`inputShiftStartTime${shiftItem.id}`}
                                    className="form-control flatpickr flatpickr-input active col-md-6"
                                    type="time"
                                    placeholder="Select Time.."
                                    name="shift_start_time"
                                    onChange={(e) =>
                                      addShiftValue(e, shiftItem.id)
                                    }
                                    value={shiftItem.shift_start_time}
                                  />
                                  <span style={{ color: "red" }}>
                                    {errors.shift
                                      ? errors.shift[shiftItem.id]
                                          ?.shift_start_time
                                        ? errors.shift[shiftItem.id]
                                            .shift_start_time
                                        : ""
                                      : ""}
                                  </span>
                                </div>
                                <div className="form-group col-md-6">
                                  <label
                                    htmlFor={`inputShiftEndTime${shiftItem.id}`}
                                  >
                                    Shift End Time *
                                  </label>
                                  <input
                                    id={`inputShiftEndTime${shiftItem.id}`}
                                    className="form-control flatpickr flatpickr-input active col-md-6"
                                    type="time"
                                    placeholder="Select Time.."
                                    name="shift_end_time"
                                    onChange={(e) =>
                                      addShiftValue(e, shiftItem.id)
                                    }
                                    value={shiftItem.shift_end_time}
                                  />
                                  <span style={{ color: "red" }}>
                                    {errors.shift
                                      ? errors.shift[shiftItem.id]
                                          ?.shift_end_time
                                        ? errors.shift[shiftItem.id]
                                            .shift_end_time
                                        : ""
                                      : ""}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <hr />

                    <h6>Customer Address</h6>
                    <hr />
                    {addressRow.map((address) => (
                      <div key={address.id}>
                        <div
                          className="col-xl-12 col-lg-12 col-sm-12  layout-spacing"
                          ref={addressFocus}
                          tabIndex="-1"
                        >
                          <div className="widget-content widget-content-area br-6">
                            <div
                              className="customer-address-class"
                              id="customer-address-div-id"
                            >
                              <div className="d-flex justify-content-end">
                                {address.id === 1 ? (
                                  <div
                                    className="btn btn-warning btn-sm float-right ms-3 mb-1"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => addAddressInForm()}
                                  >
                                    Add Address
                                  </div>
                                ) : (
                                  <div
                                    className="btn btn-danger btn-sm float-right ms-3 mb-1"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      deletAddressInForm(address.id)
                                    }
                                  >
                                    Delete This
                                  </div>
                                )}
                              </div>
                              <div
                                className="row"
                                id={`defaultaddressdiv${address.id}`}
                              >
                                <div className="col-sm-2">
                                  <div className="form-group required">
                                    <label
                                      htmlFor={`inputPincode${address.id}`}
                                    >
                                      Pincode *
                                    </label>
                                    <input
                                      type="text"
                                      name="pincode"
                                      className="form-control"
                                      id={`inputPincode${address.id}`}
                                      placeholder="Enter Pincode"
                                      onChange={(e) =>
                                        addAddressValue(e, address.id)
                                      }
                                      value={address.pincode}
                                    />
                                    <span style={{ color: "red" }}>
                                      {errors.address
                                        ? errors.address[address.id]?.pincode
                                          ? errors.address[address.id].pincode
                                          : ""
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="form-group required">
                                    <label
                                      htmlFor={`inputCountry${address.id}`}
                                    >
                                      Country *
                                    </label>
                                    <input
                                      disabled
                                      value={address.country}
                                      type="text"
                                      name="country"
                                      className="form-control"
                                      id={`inputCountry${address.id}`}
                                      placeholder="Enter Country"
                                      required
                                      onChange={(e) =>
                                        addAddressValue(e, address.id)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="form-group required">
                                    <label htmlFor={`inputState${address.id}`}>
                                      State *
                                    </label>
                                    <Select
                                      id={`inputState${address.id}`}
                                      value={address.stateValue || "Select"}
                                      onChange={(value) =>
                                        changeStateDropDown(value, address.id)
                                      }
                                      options={stateList}
                                      filterOption={customFilter}
                                    />
                                    <span style={{ color: "red" }}>
                                      {errors.address
                                        ? errors.address[address.id]?.state
                                          ? errors.address[address.id].state
                                          : ""
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-sm-2">
                                  <div className="form-group required">
                                    <label htmlFor={`inputCity${address.id}`}>
                                      City *
                                    </label>
                                    <Select
                                      id={`inputCity${address.id}`}
                                      value={address.cityValue || "Select"}
                                      onChange={(value) =>
                                        changeCityDropDown(value, address.id)
                                      }
                                      options={cityList}
                                      filterOption={customFilter}
                                    />
                                    <span style={{ color: "red" }}>
                                      {errors.address
                                        ? errors.address[address.id]?.city
                                          ? errors.address[address.id].city
                                          : ""
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-sm-2">
                                  <div className="form-group required">
                                    <label htmlFor={`inputZone${address.id}`}>
                                      Zone *
                                    </label>
                                    <input
                                      type="text"
                                      name="zone"
                                      className="form-control"
                                      id={`inputZone${address.id}`}
                                      placeholder="Enter Zone"
                                      onChange={(e) =>
                                        addAddressValue(e, address.id)
                                      }
                                      value={address.zone}
                                    />
                                    <span style={{ color: "red" }}>
                                      {errors.address
                                        ? errors.address[address.id]?.zone
                                          ? errors.address[address.id].zone
                                          : ""
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group mb-3 required">
                                <label htmlFor={`inputAddress${address.id}`}>
                                  Address *
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="address"
                                  id={`inputAddress${address.id}`}
                                  placeholder="1234 Main St"
                                  onChange={(e) =>
                                    addAddressValue(e, address.id)
                                  }
                                  value={address.address}
                                />
                                <span style={{ color: "red" }}>
                                  {errors.address
                                    ? errors.address[address.id]?.address
                                      ? errors.address[address.id].address
                                      : ""
                                    : ""}
                                </span>
                              </div>
                              <div className="form-group mb-3 required">
                                <label htmlFor={`dispatchAddress${address.id}`}>
                                  Godown/Dispatch Address *
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id={`dispatchAddress${address.id}`}
                                  name="dispatchAddress"
                                  placeholder="1234 Main St"
                                  onChange={(e) =>
                                    addAddressValue(e, address.id)
                                  }
                                  value={address.dispatchAddress}
                                />
                                <span style={{ color: "red" }}>
                                  {errors.address
                                    ? errors.address[address.id]
                                        ?.dispatchAddress
                                      ? errors.address[address.id]
                                          .dispatchAddress
                                      : ""
                                    : ""}
                                </span>
                              </div>
                              <div className="form-group mb-3">
                                <label htmlFor={`inputLandmark${address.id}`}>
                                  Landmark
                                </label>
                                <input
                                  type="text"
                                  name="landmark"
                                  className="form-control"
                                  id={`inputLandmark${address.id}`}
                                  placeholder="Landmark"
                                  onChange={(e) =>
                                    addAddressValue(e, address.id)
                                  }
                                  value={address.landmark}
                                />
                                <span style={{ color: "red" }}>
                                  {errors.address
                                    ? errors.address[address.id]?.landmark
                                      ? errors.address[address.id].landmark
                                      : ""
                                    : ""}
                                </span>
                              </div>

                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="form-group ">
                                    <label
                                      htmlFor={`inputContactPersonName${address.id}`}
                                    >
                                      Contact Person Name
                                    </label>
                                    <input
                                      type="text"
                                      name="contactPersonName"
                                      onChange={(e) =>
                                        addAddressValue(e, address.id)
                                      }
                                      className="form-control"
                                      id={`inputContactPersonName${address.id}`}
                                      placeholder="Enter Contact Person Name"
                                      value={address.contactPersonName}
                                    />
                                    <span style={{ color: "red" }}>
                                      {errors.address
                                        ? errors.address[address.id]
                                            ?.contactPersonName
                                          ? errors.address[address.id]
                                              .contactPersonName
                                          : ""
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group ">
                                    <label
                                      htmlFor={`inputContactPersonNumber${address.id}`}
                                    >
                                      Contact Person Number
                                    </label>
                                    <input
                                      type="text"
                                      name="contactPersonMobile"
                                      className="form-control"
                                      id={`inputContactPersonNumber${address.id}`}
                                      placeholder="Enter Contact Person Number"
                                      onChange={(e) =>
                                        addAddressValue(e, address.id)
                                      }
                                      value={address.contactPersonMobile}
                                    />
                                    <span style={{ color: "red" }}>
                                      {errors.address
                                        ? errors.address[address.id]
                                            ?.contactPersonMobile
                                          ? errors.address[address.id]
                                              .contactPersonMobile
                                          : ""
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="form-group col-sm-12">
                                  <LoadScriptNext googleMapsApiKey="AIzaSyB6PMCguHWiTCN0nLcEasXVxUmWOpHS5gY">
                                    <GoogleMap
                                      onClick={(e) =>
                                        changeLetLong(e, address.id)
                                      }
                                      mapContainerStyle={containerStyle}
                                      center={center}
                                      zoom={11}
                                    >
                                      <Marker
                                        visible={false}
                                        zIndex={1111}
                                        position={center}
                                      >
                                        <InfoWindow position={center}>
                                          {address.longitude ? (
                                            <label>
                                              "let" : {address.latitude} ,
                                              "long": {address.longitude}
                                            </label>
                                          ) : (
                                            <label>
                                              Click the map to get latitude /
                                              longitude!
                                            </label>
                                          )}
                                        </InfoWindow>
                                      </Marker>
                                    </GoogleMap>
                                  </LoadScriptNext>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="form-group required">
                                    <label
                                      htmlFor={`inputLatitude${address.id}`}
                                    >
                                      Latitude *
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="latitude"
                                      id={`inputLatitude${address.id}`}
                                      placeholder="Enter Latitude"
                                      onChange={(e) =>
                                        addAddressValue(e, address.id)
                                      }
                                      value={address.latitude}
                                    />
                                    <span style={{ color: "red" }}>
                                      {errors.address
                                        ? errors.address[address.id]?.latitude
                                          ? errors.address[address.id].latitude
                                          : ""
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group required">
                                    <label
                                      htmlFor={`inputLongitude${address.id}`}
                                    >
                                      Longitude *
                                    </label>
                                    <input
                                      type="number"
                                      name="longitude"
                                      className="form-control"
                                      id={`inputLongitude${address.id}`}
                                      placeholder="Enter Longitude"
                                      onChange={(e) =>
                                        addAddressValue(e, address.id)
                                      }
                                      value={address.longitude}
                                    />
                                    <span style={{ color: "red" }}>
                                      {errors.address
                                        ? errors.address[address.id]?.longitude
                                          ? errors.address[address.id].longitude
                                          : ""
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="form-check pl-0">
                                <div className="custom-control custom-checkbox checkbox-info">
                                  {address.isDefault === true ? (
                                    <input
                                      type="checkbox"
                                      name="isDefault"
                                      className="custom-control-input"
                                      id={`gridCheck${address.id}`}
                                      onChange={(e) =>
                                        addAddressValue(e, address.id)
                                      }
                                      checked
                                    />
                                  ) : (
                                    <input
                                      type="checkbox"
                                      name="isDefault"
                                      className="custom-control-input"
                                      id={`gridCheck${address.id}`}
                                      onChange={(e) =>
                                        addAddressValue(e, address.id)
                                      }
                                    />
                                  )}
                                  <label
                                    className="custom-control-label"
                                    htmlFor={`gridCheck${address.id}`}
                                  >
                                    This is a default address
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <button type="reset" className="btn btn-danger mt-3">
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-success mt-3 ml-3 view-btn"
                    >
                      Add
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <Footer></Footer>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddCustomer;
