import React from 'react'
import { format } from 'date-fns'
import ReactHTMLTableToExcel from "react-html-table-to-excel";
export const ExportToExcel = props => {
    const vehicleData = props.vehicleData;
	const customers = vehicleData[0].data;
	let tableData = [];
	vehicleData.map(item => {
		let monthName = item.monthName;
		let year = item.year;
		let month = item.month;
		let newData = item.data;
		let newarray = {monthName: monthName, year: year, month: month, data: []};
		customers.map(c => {
			let index = newData.findIndex( x => x.customername === c.customername );
			newarray.data.push(newData[index]);
		})
		tableData.push(newarray);
	})
	customers.shift();
	const changeDateFormate = (date) => {
        if (date) {
            let new_date = String(date);
            let ndate = new_date.split('T');
            let format_date = format(new Date(ndate[0]), 'dd MMM yyyy');
            return format_date;
        }
    }
    return (
      <>
	  	<ReactHTMLTableToExcel id="test-table-xls-button" className="download-table-xls-button btn btn-success" table="getDetails" filename="vehicleUseReport" sheet="tablexls" buttonText="Export as XLS"/>
		<table id="getDetails" style={{display:'none'}} >
		{/* <table id="getDetails"> */}
			<thead>
				<tr>
					<th rowSpan={2} style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Month & Year</th>
					<th colSpan={4} style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Total</th>
					{
						customers.map((item)=>(
							<th colSpan={4} style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>{item.customername}</th>
						))
					}
				</tr>
				<tr>
					<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Revenue</th>
					<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Payout</th>
					<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>No. Of Vehicles</th>
					<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>No. Of Riders</th>
					{
						customers.map((item)=>(
							<>
								<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Revenue</th>
								<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Payout</th>
								<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>No. Of Vehicles</th>
								<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>No. Of Riders</th>
							</>
						))
					}
				</tr>
			</thead>
			<tbody>
				{
					tableData.map((item)=>(
						<tr style={{textAlign: 'center'}}>
							<td>{item.monthName+' '+item.year}</td>
							{
								item.data.map((i)=>(
									<>
										<td>{i.revenue}</td>
										<td>{i.payout}</td>
										<td>{i.vehicle}</td>
										<td>{i.rider}</td>
									</>
								))
							}
						</tr>
					))
				}
			</tbody>
		</table>
	  </>
    );
};