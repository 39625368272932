import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import Chart from "react-apexcharts";
import * as action from "../../../../action/Dashboard/dashboard_action";

const TotalFleetVsDeployedFleet = () => {
  const dispatch = useDispatch();
  // total fleet vs deployed fleet (active and scan vehicle)
  const [ActiveVehicle, setActiveVehicle] = useState([]);
  const [ScanVehicle, setScanVehicle] = useState([]);
  const [vehicles, setVehicles] = useState({
    active: [],
    scan: [],
  });
  const [selectForFleetYear, setSelectForFleetYear] = useState();
  const [fleetYearList, setFleetYearList] = useState([]);
  const [fleetMonthList, setFleetMonthList] = useState([]);

  const [fleetMonth, setFleetMonth] = useState({
    value: 0,
    label: "All Months",
  });
  const [numberOfDays, setNumberOfDays] = useState([]);
  const [emptyData, setEmptyData] = useState(false);

  // months List
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const months = [
    {
      value: 1,
      label: "January",
    },
    {
      value: 2,
      label: "February",
    },
    {
      value: 3,
      label: "March",
    },
    {
      value: 4,
      label: "April",
    },
    {
      value: 5,
      label: "May",
    },
    {
      value: 6,
      label: "June",
    },
    {
      value: 7,
      label: "July",
    },
    {
      value: 8,
      label: "August",
    },
    {
      value: 9,
      label: "September",
    },
    {
      value: 10,
      label: "October",
    },
    {
      value: 11,
      label: "November",
    },
    {
      value: 12,
      label: "December",
    },
  ];

  //current Year
  const currentYear = {
    value: new Date().getFullYear(),
    label: new Date().getFullYear(),
  };

  // count number of days in month
  const getDaysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
  };

  // Total fleet Vs Deployed fleet (Active vs Scan)
  useEffect(() => {
    let fleetXData = months;
    fleetXData.push({
      value: 0,
      label: "All Months",
    });

    fleetXData.sort((a, b) => a.value - b.value);

    setFleetMonthList(fleetXData);

    dispatch(
      action.dashboard_vehicle_fleet_monthly(
        selectForFleetYear ? selectForFleetYear?.value : currentYear?.value,
        fleetMonth.value
      )
    )
      .then((data) => {
        setEmptyData(false);
        if (!data.success) {
          setEmptyData(true);
        }

        let days = getDaysInMonth(
          selectForFleetYear ? selectForFleetYear?.value : currentYear?.value,
          fleetMonth.value
        );

        let dayNumber = [];
        for (let d = 1; d <= days; d++) {
          dayNumber.push(d);
        }
        setNumberOfDays(dayNumber);
        let yearList = [];
        let active = [];
        let scan = [];
        let customerUpdatedData = [];
        let customerData = data.data;

        yearList = data?.yearList
          .map((year) => ({
            value: year,
            label: year,
          }))
          .sort((a, b) => b.value - a.value);

        if (fleetMonth.value === 0) {
          let i = 0;
          for (let j = 1; j <= 12; j++) {
            if (customerData[i] && customerData[i].month === j) {
              customerUpdatedData.push({
                month: customerData[i].month,
                activeVehicle: customerData[i].ActiveVehicle,
                scanVehicle: customerData[i].ScanVehicle,
              });
              i++;
            } else {
              customerUpdatedData.push({
                month: j,
                activeVehicle: 0,
                scanVehicle: 0,
              });
            }
          }

          for (let p = 0; p < customerUpdatedData.length; p++) {
            let k = 0;
            let m = 0;
            var sizeActive = 12 - customerUpdatedData[p].activeVehicle.length;
            var sizeScan = 12 - customerUpdatedData[p].scanVehicle.length;

            while (k < sizeActive) {
              customerUpdatedData[p].activeVehicle.push(0);
              k++;
            }

            while (m < sizeScan) {
              customerUpdatedData[p].scanVehicle.push(0);
              m++;
            }
          }

          customerUpdatedData.map((item) => {
            active.push(item.activeVehicle.toFixed());
            scan.push(item.scanVehicle.toFixed());
          });

          setActiveVehicle(active);
          setScanVehicle(scan);
        } else if (fleetMonth.value > 0 && fleetMonth.value < 13) {
          let k = 0;
          for (let j = 1; j <= days; j++) {
            if (customerData[k] && customerData[k].day === j) {
              customerUpdatedData.push({
                day: customerData[k].day,
                // activeVehicle: customerData[k].ActiveVehicle,
                scanVehicle: customerData[k].ScanVehicle,
              });
              k++;
            } else {
              customerUpdatedData.push({
                day: j,
                // activeVehicle: 0,
                scanVehicle: 0,
              });
            }
          }

          customerUpdatedData.map((item) => {
            scan.push(item.scanVehicle.toFixed());
          });

          setScanVehicle(scan);
        }

        setFleetYearList(yearList);
      })
      .catch({});
  }, [selectForFleetYear, fleetMonth]);

  // Total fleet Vs Deployed fleet (Active vs Scan) - Monthly
  var fleetMonthlyChartoptions = {
    chart: {
      fontFamily: "Apple-System, sans-serif",
      type: "bar",
      height: 300,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },

    colors: ["#49E396", "#358FFB"],
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    series: [
      {
        name: "Deployed fleet",
        data: ScanVehicle,
        // data: [8, 20, 35, 13, 20, 27, 17],
      },
      {
        name: "Total fleet",
        data: ActiveVehicle,
        // data: [10, 34, 67, 23, 89, 76, 60],
      },
    ],
    xaxis: {
      categories: monthNames,
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  };

  // Total fleet Vs Deployed fleet (Active vs Scan) - Daily
  var fleetDailyChartoptions = {
    chart: {
      fontFamily: "Apple-System, sans-serif",
      type: "bar",
      height: 300,
    },

    colors: ["#49E396"],

    series: [
      {
        name: "Deployed fleet",
        data: ScanVehicle,
      },
    ],
    xaxis: {
      labels: {
        show: true,
      },
      categories: numberOfDays,
    },
    yaxis: {
      labels: {
        show: true,
      },
      title: {
        text: "Daily Deployed Fleet (%)",
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "16px",
          fontWeight: 600,
          cssClass: "apexcharts-xaxis-title",
        },
      },
    },
    legend: {
      show: true,
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },

    responsive: [
      {
        breakpoint: 1000,
        yaxis: {
          categories: numberOfDays,
        },
        options: {
          plotOptions: {
            line: {
              horizontal: true,
              columnWidth: "80%",
            },
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div className="widget widget-chart-one">
      <div className="widget-heading">
        <h5 className=""> Total fleet Vs Deployed fleet </h5>
        <ul className="tabs  d-flex justify-content-between align-items-center">
          <li>
            <a id="tb_1" className="tabmenu">
              {fleetMonth.value === 0 ? "Monthly" : "Daily"}
            </a>
          </li>
        </ul>
      </div>
      <ul className="list-unstyled  row ">
        <li className="mx-2">
          <label>Month</label>
          <Select
            id="inputMonth"
            value={fleetMonth}
            onChange={(value) => {
              setFleetMonth(value);
            }}
            options={fleetMonthList}
          />
        </li>
        <li className="mx-2">
          <label>Year</label>
          <Select
            id="inputYear"
            defaultValue={currentYear}
            value={selectForFleetYear}
            onChange={(value) => {
              setSelectForFleetYear(value);
            }}
            options={fleetYearList}
          />
        </li>
      </ul>
      {emptyData ? (
        <p className="d-flex justify-content-center align-items-center">
          Data is Not Available
        </p>
      ) : fleetMonth.value === 0 ? (
        ScanVehicle.length > 0 || ActiveVehicle.length > 0 ? (
          <div id="vehiclechart">
            <Chart
              options={fleetMonthlyChartoptions}
              type="bar"
              series={fleetMonthlyChartoptions.series}
              height={300}
            />
          </div>
        ) : (
          <p className="text-center">No Data Found</p>
        )
      ) : ScanVehicle.length > 0 ? (
        <div id="vehiclechart">
          <Chart
            options={fleetDailyChartoptions}
            type="bar"
            series={fleetDailyChartoptions.series}
            height={300}
          />
        </div>
      ) : (
        <p className="text-center">No Data Found</p>
      )}
    </div>
  );
};

export default TotalFleetVsDeployedFleet;
