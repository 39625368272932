import React, { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import Select from "react-select";
import withReactContent from "sweetalert2-react-content";
import Footer from "../../DashBoard/Footer";
import Header from "../../DashBoard/Header";
import Sub_Header from "../../DashBoard/Sub_Header";
import SideBar from "../../DashBoard/SideBar";
import Loader from "../../common/Loader/Loader";
import { ExportToExcel } from "./Left_Driver_Survey_List_Excel";
import { GoogleMap, LoadScriptNext } from "@react-google-maps/api";
import { Marker, InfoWindow } from "@react-google-maps/api";
import "../../../assets/css/users/user-profile.css";
import "../../../assets/plugins/table/datatable/datatables.css";
import "../../../assets/plugins/table/datatable/dt-global_style.css";
import * as action from "../../../action/Driver/driver_action";
import $ from "jquery";
import { DateRangePicker } from "rsuite";
import subDays from "date-fns/subDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";

function Driver_List() {
  $.DataTable = require("datatables.net");
  const containerStyle = {
    width: "100%",
    height: "400px",
  };
  var date = new Date();
  const [filter, setFilter] = useState([
    new Date(date.getFullYear(), date.getMonth(), 1),
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
  ]);
  const start = new Date();
  start.setHours(0, 0, 0, 0);
  let end = new Date();
  end.setHours(23, 59, 59, 999);
  const center = { lat: 21.17024, lng: 72.831062 };
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.DriverListReducer);
  const { buttonLoading } = useSelector(
    (state) => state.LeftDriverSuevryListReducer
  );
  const tableRef = useRef();
  const [driverList, setDriverList] = useState([]);
  const [errors, setErrors] = useState({});
  const [selfieList, setSelfieList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [customerAddressList, setCustomerAddressList] = useState([]);
  const [customerShiftList, setCustomerShiftList] = useState([]);
  const [leftRidersList, setLeftRidersList] = useState([]);
  const [leftDriverData, setLeftDriverData] = useState({
    driverId: "",
    driverid: "",
    driverName: "",
    mobileNumber: "",
    date: "",
    riderMasterCode: "",
    reason: "",
    remarks: "",
    issueText: "",
  });
  const [leftSingleDriverData, setLeftSingleDriverData] = useState({
    driverId: "",
    driverid: "",
    driverName: "",
    mobileNumber: "",
    date: "",
    riderMasterCode: "",
    reason: "",
    remarks: "",
    issueText: "",
  });
  const [assignDriver, setAssignDriver] = useState({
    driverName: "",
    driverId: "",
    customerId: "",
    customerAddressId: "",
    customerShiftId: "",
  });
  const predefinedRanges = [
    {
      label: "Current month",
      value: [startOfMonth(new Date()), new Date()],
      placement: "left",
    },
    {
      label: "Last month",
      value: [
        startOfMonth(addMonths(new Date(), -1)),
        endOfMonth(addMonths(new Date(), -1)),
      ],
      placement: "left",
    },
    {
      label: "Last 30 days",
      value: [subDays(new Date(), 29), new Date()],
      placement: "left",
    },
    {
      label: "Last 60 days",
      value: [subDays(new Date(), 59), new Date()],
      placement: "left",
    },
  ];
  const setInputData = (e) => {
    setInput([new Date(e.target.value), new Date(e.target.value)]);
  };
  const [input, setInput] = useState([new Date(start), new Date(end)]);
  const [assignDriverValues, setAssignDriverValues] = useState({
    customerValue: "",
    customerAddressValue: "",
    customerShiftValue: "",
  });
  const showInput = () => {
    setLeftDriverData({ ...leftDriverData, issueText: "" });
    let AddInput = document.getElementById("issueText");
    AddInput.classList.toggle("d-none");
  };
  const assignDriverSet = (driver, driver_id) => {
    setAssignDriver({
      driverName: driver.name,
      driverId: driver_id,
      customerId: driver.customerId ? driver.customerId : "",
      customerAddressId: driver.customerAddressId
        ? driver.customerAddressId
        : "",
      customerShiftId: driver.customerShiftId ? driver.customerShiftId : "",
    });
    setAssignDriverValues({
      customerValue: driver.customerId
        ? {
            value: driver.customerId,
            label:
              driver.driverCustomerData.businessName +
              " | " +
              driver.driverCustomerData.name,
          }
        : "",
      customerAddressValue: driver.customerAddressId
        ? {
            value: driver.customerAddressId,
            label: driver.driverCustomerAddressData.address,
          }
        : "",
      customerShiftValue: driver.customerShiftId
        ? {
            value: driver.customerShiftId,
            label:
              driver.driverCustomerShiftData.shiftName +
              " | " +
              driver.driverCustomerShiftData.shiftStartTime +
              " | " +
              driver.driverCustomerShiftData.shiftEndTime,
          }
        : "",
    });
    if (driver.customerId) {
      dispatch(action.customer_address_list(driver.customerId))
        .then((data) => {
          let newAddressList = [];
          data.data.map((item) => {
            newAddressList.push({
              value: item._id,
              label: item.address,
            });
          });
          setCustomerAddressList(newAddressList);
        })
        .catch({});
      dispatch(action.customer_shift_list(driver.customerId))
        .then((data) => {
          let newShiftList = [];
          data.data.map((item) => {
            newShiftList.push({
              value: item._id,
              label:
                item.shiftName +
                " | " +
                item.shiftStartTime +
                " | " +
                item.shiftEndTime,
            });
          });
          setCustomerShiftList(newShiftList);
        })
        .catch({});
    } else {
      setCustomerAddressList([]);
      setCustomerShiftList([]);
    }
    dispatch(action.customer_list())
      .then((data) => {
        let newCustomerList = [];
        data.data.map((item) => {
          newCustomerList.push({
            value: item._id,
            label: item.businessName + " | " + item.name,
          });
        });
        setCustomerList(newCustomerList);
      })
      .catch({});
  };
  const changeAddressDropDown = (data) => {
    setAssignDriver({ ...assignDriver, customerAddressId: data.value });
    setAssignDriverValues({
      ...assignDriverValues,
      customerAddressValue: data,
    });
  };
  const changeShiftDropDown = (data) => {
    setAssignDriver({ ...assignDriver, customerShiftId: data.value });
    setAssignDriverValues({ ...assignDriverValues, customerShiftValue: data });
  };
  const changeCustomerDropDown = (data) => {
    const customer_id = data.value;
    setAssignDriver({
      driverName: assignDriver.driverName,
      driverId: assignDriver.driverId,
      customerId: customer_id,
      customerAddressId: "",
      customerShiftId: "",
    });
    setAssignDriverValues({ ...assignDriverValues, customerValue: data });
    dispatch(action.customer_address_list(customer_id))
      .then((data) => {
        let newAddressList = [];
        data.data.map((item) => {
          newAddressList.push({
            value: item._id,
            label: item.address,
          });
        });
        setCustomerAddressList(newAddressList);
      })
      .catch({});
    dispatch(action.customer_shift_list(customer_id))
      .then((data) => {
        let newShiftList = [];
        data.data.map((item) => {
          newShiftList.push({
            value: item._id,
            label:
              item.shiftName +
              " | " +
              item.shiftStartTime +
              " | " +
              item.shiftEndTime,
          });
        });
        setCustomerShiftList(newShiftList);
      })
      .catch({});
  };
  const activeDeactiveAlertPopup = (driver) => {
    dispatch(action.DriverActiveDeactive(driver[8]))
      .then((data) => {
        let newDriverList = [];
        driverList.map((item) => {
          if (item[8] === driver[8]) {
            let driver_data = data.data;
            newDriverList.push([
              item[0],
              item[1],
              item[2],
              item[3],
              item[4],
              item[5],
              driver_data.isActive,
              item[7],
              item[8],
              item[9],
              item[10],
            ]);
          } else {
            newDriverList.push(item);
          }
        });
        toast.success(data.msg);
        setDriverList(newDriverList);
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  const filterDriverList = (e) => {
    const filterStatus = e.target.value;
    dispatch(action.Driver_list())
      .then((data) => {
        let newDriverList = [];
        data.data.map((item) => {
          if (filterStatus == "1") {
            if (item.isActive == 1) {
              let shiftData = item.hasOwnProperty("driverCustomerShiftData")
                ? `${item.driverCustomerShiftData.shiftName} <br> ${item.driverCustomerShiftData.shiftStartTime} <br> ${item.driverCustomerShiftData.shiftEndTime}`
                : null;
              let customer_data = item.hasOwnProperty("driverCustomerData")
                ? item.driverCustomerData.businessName +
                  " | " +
                  item.driverCustomerData.name
                : null;
              let customer_address = item.hasOwnProperty(
                "driverCustomerAddressData"
              )
                ? item.driverCustomerAddressData.address
                : null;
              let vehicleData = item.hasOwnProperty("driverVehicleData")
                ? item.driverVehicleData.number +
                  " | " +
                  item.driverVehicleData.ownerName +
                  " | " +
                  item.driverVehicleData.details
                : null;
              newDriverList.push([
                "EV" + item.Driver_Id,
                item.name,
                item.mobile,
                vehicleData,
                customer_data,
                customer_address,
                shiftData,
                item.isActive,
                item._id,
                item._id,
                item,
              ]);
            }
          } else if (filterStatus == "0") {
            if (item.isActive == 0) {
              let shiftData = item.hasOwnProperty("driverCustomerShiftData")
                ? `${item.driverCustomerShiftData.shiftName} <br> ${item.driverCustomerShiftData.shiftStartTime} <br> ${item.driverCustomerShiftData.shiftEndTime}`
                : null;
              let customer_data = item.hasOwnProperty("driverCustomerData")
                ? item.driverCustomerData.businessName +
                  " | " +
                  item.driverCustomerData.name
                : null;
              let customer_address = item.hasOwnProperty(
                "driverCustomerAddressData"
              )
                ? item.driverCustomerAddressData.address
                : null;
              let vehicleData = item.hasOwnProperty("driverVehicleData")
                ? item.driverVehicleData.number +
                  " | " +
                  item.driverVehicleData.ownerName +
                  " | " +
                  item.driverVehicleData.details
                : null;
              newDriverList.push([
                "EV" + item.Driver_Id,
                item.name,
                item.mobile,
                vehicleData,
                customer_data,
                customer_address,
                shiftData,
                item.isActive,
                item._id,
                item._id,
                item,
              ]);
            }
          } else if (filterStatus == "2") {
            let shiftData = item.hasOwnProperty("driverCustomerShiftData")
              ? `${item.driverCustomerShiftData.shiftName} <br> ${item.driverCustomerShiftData.shiftStartTime} <br> ${item.driverCustomerShiftData.shiftEndTime}`
              : null;
            let customer_data = item.hasOwnProperty("driverCustomerData")
              ? item.driverCustomerData.businessName +
                " | " +
                item.driverCustomerData.name
              : null;
            let customer_address = item.hasOwnProperty(
              "driverCustomerAddressData"
            )
              ? item.driverCustomerAddressData.address
              : null;
            let vehicleData = item.hasOwnProperty("driverVehicleData")
              ? item.driverVehicleData.number +
                " | " +
                item.driverVehicleData.ownerName +
                " | " +
                item.driverVehicleData.details
              : null;
            newDriverList.push([
              "EV" + item.Driver_Id,
              item.name,
              item.mobile,
              vehicleData,
              customer_data,
              customer_address,
              shiftData,
              item.isActive,
              item._id,
              item._id,
              item,
            ]);
          }
        });
        setDriverList(newDriverList);
      })
      .catch({});
  };
  const DetachVehicleData = (driver_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't to detached vehicle!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, detached it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(action.driver_detached_vehicle(driver_id))
          .then((data) => {
            toast.success(data.msg);
          })
          .catch((error) => {
            toast.error(error);
          });
      }
    });
  };
  const DriverLogoff = (driver_id) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Log Off!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(action.driver_logoff(driver_id))
          .then((data) => {
            toast.success(data.msg);
          })
          .catch((error) => {
            toast.error(error);
          });
      }
    });
  };
  const DriverSelfieRequest = (driver_id) => {
    dispatch(action.driver_selfie_request(driver_id))
      .then((data) => {
        toast.success(data.msg);
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  useEffect(() => {
    dispatch(action.Driver_list())
      .then((data) => {
        let newDriverList = [];
        data.data.map((item) => {
          let shiftData = item.hasOwnProperty("driverCustomerShiftData")
            ? `${item.driverCustomerShiftData.shiftName} <br> ${item.driverCustomerShiftData.shiftStartTime} <br> ${item.driverCustomerShiftData.shiftEndTime}`
            : null;
          let customer_data = item.hasOwnProperty("driverCustomerData")
            ? item.driverCustomerData.businessName +
              " | " +
              item.driverCustomerData.name
            : null;
          let customer_address = item.hasOwnProperty(
            "driverCustomerAddressData"
          )
            ? item.driverCustomerAddressData.address
            : null;
          let vehicleData = item.hasOwnProperty("driverVehicleData")
            ? item.driverVehicleData.number +
              " | " +
              item.driverVehicleData.ownerName +
              " | " +
              item.driverVehicleData.details
            : null;
          newDriverList.push([
            "EV" + item.Driver_Id,
            item.name,
            item.mobile,
            vehicleData,
            customer_data,
            customer_address,
            shiftData,
            item.isActive,
            item._id,
            item._id,
            item,
          ]);
        });
        setDriverList(newDriverList);
      })
      .catch({});
  }, []);
  useEffect(() => {
    const tableName = "driver-list";
    const table = $(`#${tableName}`).DataTable({
      dom: "Bfrtip",
      buttons: ["copyHtml5", "excelHtml5", "pdfHtml5", "csvHtml5"],
      data: driverList,
      columns: [
        { width: "10%", title: "Driver Id" },
        { width: "10%", title: "Full Name" },
        { width: "10%", title: "Mobile" },
        { width: "10%", title: "Vehicle" },
        { width: "10%", title: "Customer/Company" },
        { width: "10%", title: "Customer Address" },
        { width: "10%", title: "Shift Time" },
        { width: "10%", title: "Status" },
        { width: "20%", title: "Action" },
      ],
      columnDefs: [
        {
          targets: [7],
          className: "center",
          createdCell: (td, cellData, rowData) => {
            const root = createRoot(td);
            root.render(
              <a
                style={{ cursor: "pointer" }}
                className={
                  rowData[7] === 1
                    ? "btn btn-success btn-sm status-act-btn"
                    : "btn btn-danger btn-sm status-act-btn"
                }
                onClick={() => activeDeactiveAlertPopup(rowData)}
              >
                {rowData[7] === 1 ? "Active" : "Deactive"}
              </a>
            );
          },
        },
        {
          targets: [8],
          className: "center",
          createdCell: (td, cellData, rowData) => {
            const root = createRoot(td);
            root.render(
              <>
                <a
                  style={{ cursor: "pointer" }}
                  href={`/adminside/driver/view/${rowData[8]}`}
                  className="btn btn-primary btn-sm mb-2"
                >
                  View
                </a>
                &nbsp;
                <a
                  style={{ cursor: "pointer" }}
                  href={`/adminside/driver/assign/${rowData[8]}`}
                  className="btn btn-info btn-sm mb-2"
                >
                  Assign Multiple
                </a>
                &nbsp;
                <a
                  style={{ cursor: "pointer", width: "100px" }}
                  onClick={() => DriverLogoff(rowData[8])}
                  className="btn btn-warning btn-sm mb-2"
                >
                  Log Off
                </a>
                &nbsp;
                <a
                  style={{ cursor: "pointer" }}
                  className="btn btn-dark btn-sm mb-2"
                  onClick={() => DriverSelfieRequest(rowData[8])}
                >
                  Selfie Request
                </a>
                &nbsp;
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => DetachVehicleData(rowData[8])}
                  className="btn btn-danger btn-sm mb-2"
                >
                  Detach Vehicle
                </a>
              </>
            );
          },
        },
      ],
      destroy: true, // I think some clean up is happening here
      searching: true,
      oLanguage: {
        oPaginate: {
          sPrevious:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>',
          sNext:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>',
        },
        //sInfo: "Showing page _PAGE_ of _PAGES_",
        sSearch:
          '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
        sSearchPlaceholder: "Search...",
        sLengthMenu: "Results :  _MENU_",
      },
    });
    // Extra step to do extra clean-up.
    return function () {
      table.destroy();
    };
  }, [driverList]);
  const LeftDriverForm = (data) => {
    setLeftDriverData({
      driverId: data[4],
      driverid: data[0],
      driverName: data[1],
      mobileNumber: data[2],
      date: "",
      riderMasterCode: "",
      reason: "",
      remarks: "",
      issueText: "",
    });
  };
  const LeftSingleDriverForm = (data) => {
    dispatch(action.Driver_Single_Survey_List(data[6]))
      .then((response) => {
        setLeftSingleDriverData({
          driverId: response.data.driverId,
          driverid: response.data.driverid,
          driverName: response.data.driverName,
          mobileNumber: response.data.mobileNumber,
          date: response.data.date,
          riderMasterCode: response.data.riderMasterCode,
          reason: response.data.reason,
          remarks: response.data.remarks,
          issueText:
            response.data.reason == "Other" ? response.data.issueText : "",
        });
      })
      .catch({});
  };

  useEffect(() => {
    dispatch(action.Driver_Not_Taken_Selfie_List())
      .then((data) => {
        let newDriverList = [];
        data.data.map((item) => {
          newDriverList.push([
            "EV" + item.Driver_Id,
            item.name,
            item.mobile,
            item._id,
            item._id,
            item,
          ]);
        });

        setSelfieList(newDriverList);
      })
      .catch({});
  }, []);
  useEffect(() => {
    dispatch(action.Left_Driver_survey_List(filter[0], filter[1]))
      .then((data) => {
        let newDriverList = [];
        data.data.map((item) => {
          newDriverList.push([
            item.driverid,
            item.driverName,
            item.mobileNumber,
            item.riderMasterCode,
            item.reason == "Other" ? item.issueText : item.reason,
            item._id,
            item._id,
            item,
          ]);
        });
        setLeftRidersList(newDriverList);
      })
      .catch({});
  }, [filter]);
  useEffect(() => {
    const tableName = "not-taken-salfie-list";
    const table = $(`#${tableName}`).DataTable({
      dom: "Bfrtip",
      buttons: ["copyHtml5", "excelHtml5", "pdfHtml5", "csvHtml5"],
      data: selfieList,
      columns: [
        { width: "10%", title: "Driver Id" },
        { width: "10%", title: "Full Name" },
        { width: "10%", title: "Mobile" },
        { width: "30%", title: "Action" },
      ],
      columnDefs: [
        {
          targets: [3],
          className: "center",
          createdCell: (td, cellData, rowData) => {
            const root = createRoot(td);
            root.render(
              <>
                <a
                  style={{ cursor: "pointer", width: "100px" }}
                  onClick={() => LeftDriverForm(rowData)}
                  className="btn btn-info btn-sm mb-2"
                  data-toggle="modal"
                  data-target="#LeftDriverModalCenter"
                >
                  Left
                </a>
                &nbsp;
              </>
            );
          },
        },
      ],
      destroy: true, // I think some clean up is happening here
      searching: true,
      oLanguage: {
        oPaginate: {
          sPrevious:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>',
          sNext:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>',
        },
        //sInfo: "Showing page _PAGE_ of _PAGES_",
        sSearch:
          '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
        sSearchPlaceholder: "Search...",
        sLengthMenu: "Results :  _MENU_",
      },
    });
    // Extra step to do extra clean-up.
    return function () {
      table.destroy();
    };
  }, [selfieList]);
  useEffect(() => {
    const tableName = "left-riders-list";
    const table = $(`#${tableName}`).DataTable({
      dom: "Bfrtip",
      buttons: ["copyHtml5", "excelHtml5", "pdfHtml5", "csvHtml5"],
      data: leftRidersList,
      columns: [
        { width: "10%", title: "Driver Id" },
        { width: "10%", title: "Full Name" },
        { width: "10%", title: "Mobile" },
        { width: "10%", title: "Rider Master Code" },
        { width: "10%", title: "Left Reason" },
        { width: "30%", title: "Action" },
      ],
      columnDefs: [
        {
          targets: [5],
          className: "center",
          createdCell: (td, cellData, rowData) => {
            const root = createRoot(td);
            root.render(
              <>
                <a
                  style={{ cursor: "pointer", width: "100px" }}
                  onClick={() => LeftSingleDriverForm(rowData)}
                  className="btn btn-info btn-sm mb-2"
                  data-toggle="modal"
                  data-target="#DisplayLeftDriverModalCenter"
                >
                  View Sarvey Form
                </a>
                &nbsp;
              </>
            );
          },
        },
      ],
      destroy: true, // I think some clean up is happening here
      searching: true,
      oLanguage: {
        oPaginate: {
          sPrevious:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>',
          sNext:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>',
        },
        //sInfo: "Showing page _PAGE_ of _PAGES_",
        sSearch:
          '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
        sSearchPlaceholder: "Search...",
        sLengthMenu: "Results :  _MENU_",
      },
    });
    // Extra step to do extra clean-up.
    return function () {
      table.destroy();
    };
  }, [leftRidersList]);
  const onsubmitHandler = (e) => {
    e.preventDefault();
    let parameter = {
      id: assignDriver.driverId,
      customerId: assignDriver.customerId,
      customerAddressId: assignDriver.customerAddressId,
      customerShiftId: assignDriver.customerShiftId,
    };
    dispatch(action.driver_assign_customer(parameter))
      .then((data) => {
        toast.success(data.msg);
        dispatch(action.Driver_list())
          .then((data) => {
            let newDriverList = [];
            data.data.map((item) => {
              let shiftData = item.hasOwnProperty("driverCustomerShiftData")
                ? `${item.driverCustomerShiftData.shiftName} <br> ${item.driverCustomerShiftData.shiftStartTime} <br> ${item.driverCustomerShiftData.shiftEndTime}`
                : null;
              let customer_data = item.hasOwnProperty("driverCustomerData")
                ? item.driverCustomerData.businessName +
                  " | " +
                  item.driverCustomerData.name
                : null;
              let customer_address = item.hasOwnProperty(
                "driverCustomerAddressData"
              )
                ? item.driverCustomerAddressData.address
                : null;
              let vehicleData = item.hasOwnProperty("driverVehicleData")
                ? item.driverVehicleData.number +
                  " | " +
                  item.driverVehicleData.ownerName +
                  " | " +
                  item.driverVehicleData.details
                : null;
              newDriverList.push([
                "EV" + item.Driver_Id,
                item.name,
                item.mobile,
                vehicleData,
                customer_data,
                customer_address,
                shiftData,
                item.isActive,
                item._id,
                item._id,
                item,
              ]);
            });
            setDriverList(newDriverList);
          })
          .catch({});
        $("#assignCustomerModalCenter .close").click();
        setAssignDriver({
          driverName: "",
          driverId: "",
          customerId: "",
          customerAddressId: "",
          customerShiftId: "",
        });
        setAssignDriverValues({
          customerValue: "",
          customerAddressValue: "",
          customerShiftValue: "",
        });
        setCustomerList([]);
        setCustomerShiftList([]);
        setCustomerShiftList([]);
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  const onsubmitsurveyHandler = (e) => {
    e.preventDefault();

    if (leftDriverData.date === "") {
    }
    let parameter = {
      driverId: leftDriverData.driverId,
      driverid: leftDriverData.driverid,
      driverName: leftDriverData.driverName,
      mobileNumber: leftDriverData.mobileNumber,
      date: leftDriverData.date,
      riderMasterCode: leftDriverData.riderMasterCode,
      reason: leftDriverData.reason,
      remarks: leftDriverData.remarks,
      issueText: leftDriverData.issueText,
    };
    dispatch(action.Left_Driver_survey_form(parameter))
      .then((data) => {
        toast.success(data.msg);
        dispatch(action.Driver_Not_Taken_Selfie_List())
          .then((data) => {
            let newDriverList = [];
            data.data.map((item) => {
              newDriverList.push([
                "EV" + item.Driver_Id,
                item.name,
                item.mobile,
                item._id,
                item._id,
                item,
              ]);
            });
            setSelfieList(newDriverList);
          })
          .catch({});
        dispatch(action.Left_Driver_survey_List())
          .then((data) => {
            let newDriverList = [];
            data.data.map((item) => {
              newDriverList.push([
                item.driverid,
                item.driverName,
                item.mobileNumber,
                item.riderMasterCode,
                item.reason == "Other" ? item.issueText : item.reason,
                item._id,
                item._id,
                item,
              ]);
            });
            setLeftRidersList(newDriverList);
          })
          .catch({});
        $("#LeftDriverModalCenter .close").click();
        setLeftDriverData({
          driverId: "",
          driverid: "",
          driverName: "",
          mobileNumber: "",
          date: "",
          riderMasterCode: "",
          reason: "",
          remarks: "",
          issueText: "",
        });
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Sub_Header />
      <div className="main-container" id="container">
        {loading ? <Loader /> : null}
        <div className="overlay"></div>
        <div className="search-overlay"></div>
        <SideBar />
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                <div className="widget-content widget-content-area br-6">
                  <ul
                    className="nav nav-tabs  mb-3 mt-3"
                    id="simpletab"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="list-tab"
                        data-toggle="tab"
                        href="#list"
                        role="tab"
                        aria-controls="list"
                        aria-selected="true"
                      >
                        List
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="tracking-tab"
                        data-toggle="tab"
                        href="#tracking"
                        role="tab"
                        aria-controls="tracking"
                        aria-selected="false"
                      >
                        Tracking
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="salfie-tab"
                        data-toggle="tab"
                        href="#salfies"
                        role="tab"
                        aria-controls="salfies"
                        aria-selected="false"
                      >
                        Not Taken Selfie
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="riders-tab"
                        data-toggle="tab"
                        href="#riders"
                        role="tab"
                        aria-controls="riders"
                        aria-selected="false"
                      >
                        Left Riders
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content" id="simpletabContent">
                    <div
                      className="tab-pane fade show active"
                      id="list"
                      role="tabpanel"
                      aria-labelledby="list-tab"
                    >
                      <div
                        className="form-group p-2 list-filter"
                        id="list-filter-id"
                      >
                        <label
                          htmlFor="inputDriverStatusSelect"
                          className="ml-1"
                        >
                          Status
                        </label>
                        <select
                          name="inputDriverStatusSelect"
                          id="inputDriverStatusSelect"
                          className="form-control selectpicker ml-2"
                          onChange={(e) => filterDriverList(e)}
                          style={{ width: "120px" }}
                        >
                          <option defaultValue="1">Active</option>
                          <option defaultValue="0">Deactive</option>
                          <option defaultValue="2">All</option>
                        </select>
                      </div>
                      <div className="table-responsive mb-4 mt-4">
                        <table
                          id="driver-list"
                          className="table table-hover"
                          style={{ width: "100%" }}
                        >
                          <thead>
                            <tr>
                              <th>Driver ID</th>
                              <th>Full Name</th>
                              <th>Mobile</th>
                              <th>Vehical</th>
                              <th>Customer/Company</th>
                              <th>Customer Address</th>
                              <th>Shift Time</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading ? (
                              <tr className="m-4">
                                <td colSpan="9">
                                  <div className="d-flex justify-content-center align-items-center m-4">
                                    <i className="fa fa-spinner fa-spin m-4"></i>
                                  </div>
                                </td>
                              </tr>
                            ) : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade show"
                      id="tracking"
                      role="tabpanel"
                      aria-labelledby="tracking-tab"
                    >
                      <div className="row">
                        <div className="form-group col-sm-12">
                          <LoadScriptNext googleMapsApiKey="AIzaSyB6PMCguHWiTCN0nLcEasXVxUmWOpHS5gY">
                            <GoogleMap
                              mapContainerStyle={containerStyle}
                              center={center}
                              zoom={11}
                            >
                              <Marker
                                visible={false}
                                zIndex={1111}
                                position={center}
                              ></Marker>
                            </GoogleMap>
                          </LoadScriptNext>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade show"
                      id="salfies"
                      role="tabpanel"
                      aria-labelledby="salfie-tab"
                    >
                      <div className="row">
                        <div className="form-group col-sm-12">
                          <div className="table-responsive mb-4 mt-4">
                            <table
                              id="not-taken-salfie-list"
                              className="table table-hover"
                              style={{ width: "100%" }}
                            ></table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade show"
                      id="riders"
                      role="tabpanel"
                      aria-labelledby="riders-tab"
                    >
                      <div className="row">
                        <div className="form-group col-sm-12">
                          <DateRangePicker
                            ranges={predefinedRanges}
                            value={filter}
                            onChange={setFilter}
                            style={{ width: 300 }}
                            placeholder="Select Date Range"
                          />{" "}
                          &nbsp;
                          {buttonLoading ? (
                            <button disabled class="btn btn-success">
                              <i class="fa fa-spinner fa-spin"></i>{" "}
                            </button>
                          ) : leftRidersList && leftRidersList.length ? (
                            <ExportToExcel
                              apiData={leftRidersList}
                              startDate={filter[0]}
                              endDate={filter[1]}
                            />
                          ) : (
                            <div className="btn btn-success">Export to XLS</div>
                          )}
                          <div className="table-responsive mb-4 mt-4">
                            <table
                              id="left-riders-list"
                              className="table table-hover"
                              style={{ width: "100%" }}
                            ></table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="LeftDriverModalCenter"
                role="dialog"
                aria-labelledby="LeftDriverModalCenterTitle"
                style={{ display: "none" }}
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg" role="document">
                  <div className="modal-content">
                    <form onSubmit={onsubmitsurveyHandler}>
                      <div className="modal-header">
                        <h5
                          className="modal-title"
                          id="LeftDriverModalCenterTitle"
                        >
                          Why are you left?
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <svg
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-x"
                          >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                          </svg>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div
                          className="customer-list-class"
                          id="customer-list-div-id"
                        >
                          <div className="form-group col-12 required">
                            <label htmlFor="inputDriverFullName">
                              DriverId:{" "}
                            </label>
                            <label id="inputDriverFullName">
                              {leftDriverData.driverid !== ""
                                ? leftDriverData.driverid
                                : "test driver"}
                            </label>
                          </div>

                          <div className="form-group col-md-12 required">
                            <label htmlFor="inputCustomerSelect">Name :</label>
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                disabled
                                name="driverName"
                                id="driverName"
                                value={leftDriverData.driverName}
                              />
                            </div>
                          </div>

                          <div className="form-group col-md-12 required">
                            <label htmlFor="inputCustomerAddressSelect">
                              Mobile Number :{" "}
                            </label>
                            <div className="form-group">
                              <input
                                type="number"
                                className="form-control"
                                disabled
                                name="mobileNumber"
                                id="mobileNumber"
                                value={leftDriverData.mobileNumber}
                              />
                            </div>
                          </div>

                          <div className="form-group col-md-12 required">
                            <label htmlFor="inputCustomerShiftSelect">
                              Date of Leaving :
                            </label>
                            <div className="form-group">
                              <input
                                type="date"
                                name="date"
                                className="form-control"
                                id="date"
                                onChange={(e) =>
                                  setLeftDriverData({
                                    ...leftDriverData,
                                    date: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group col-md-12 required">
                            <label htmlFor="inputCustomerShiftSelect">
                              Rider Master Code :
                            </label>
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="driverName"
                                id="driverName"
                                placeholder="Enter Rider Master Code"
                                value={leftDriverData.riderMasterCode}
                                onChange={(e) =>
                                  setLeftDriverData({
                                    ...leftDriverData,
                                    riderMasterCode: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group col-md-12 required">
                            <label htmlFor="inputCustomerShiftSelect">
                              Reason for Leaving :
                            </label>
                            <div className="form-group">
                              <input
                                type="checkbox"
                                name="reason"
                                id="evifyIssue"
                                value="Evify Issue"
                                onChange={(e) =>
                                  setLeftDriverData({
                                    ...leftDriverData,
                                    reason: e.target.value,
                                  })
                                }
                              />{" "}
                              Evify Issue
                              <input
                                type="checkbox"
                                name="reason"
                                id="personalReason"
                                value="Personal Reason"
                                onChange={(e) =>
                                  setLeftDriverData({
                                    ...leftDriverData,
                                    reason: e.target.value,
                                  })
                                }
                              />{" "}
                              Personal Reason
                              <input
                                type="checkbox"
                                name="reason"
                                id="growth"
                                value="Growth"
                                onChange={(e) =>
                                  setLeftDriverData({
                                    ...leftDriverData,
                                    reason: e.target.value,
                                  })
                                }
                              />{" "}
                              Growth
                              <input
                                type="checkbox"
                                name="reason"
                                id="others"
                                value="Other"
                                onChange={(e) =>
                                  setLeftDriverData({
                                    ...leftDriverData,
                                    reason: e.target.value,
                                  })
                                }
                              />{" "}
                              Other
                              {leftDriverData.reason === "Other" ? (
                                <div class="form-check">
                                  <input
                                    class="form-control col-md-6"
                                    type="text"
                                    name="deactivateReasonInput"
                                    id="deactivateReasonInput"
                                    onChange={(e) =>
                                      setLeftDriverData({
                                        ...leftDriverData,
                                        issueText: e.target.value,
                                      })
                                    }
                                    value={leftDriverData.issueText}
                                  />
                                  <br />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="form-group col-md-12 required">
                            <label htmlFor="inputCustomerShiftSelect">
                              Remarks :
                            </label>
                            <div className="form-group">
                              <textarea
                                name="remarks"
                                id="remarks"
                                value={leftDriverData.remarks}
                                cols="112"
                                rows="5"
                                className="form-control"
                                placeholder="Enter Remars"
                                onChange={(e) =>
                                  setLeftDriverData({
                                    ...leftDriverData,
                                    remarks: e.target.value,
                                  })
                                }
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn"
                          data-dismiss="modal"
                        >
                          <i className="flaticon-cancel-12"></i>Cancle
                        </button>
                        <button
                          type="submit"
                          id="saveLeftDriverSurveyFormBtn"
                          className="btn btn-primary view-btn"
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="DisplayLeftDriverModalCenter"
                role="dialog"
                aria-labelledby="DisplayLeftDriverModalCenterTitle"
                style={{ display: "none" }}
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg" role="document">
                  <div className="modal-content">
                    <form>
                      <div className="modal-header">
                        <h5
                          className="modal-title"
                          id="LeftDriverModalCenterTitle"
                        >
                          Why are you left?
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <svg
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-x"
                          >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                          </svg>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div
                          className="customer-list-class"
                          id="customer-list-div-id"
                        >
                          <div className="form-group col-12 required">
                            <label htmlFor="inputDriverFullName">
                              DriverId:{" "}
                            </label>
                            <label id="inputDriverFullName">
                              {leftSingleDriverData.driverid !== ""
                                ? leftSingleDriverData.driverid
                                : "test driver"}
                            </label>
                          </div>

                          <div className="form-group col-md-12 required">
                            <label htmlFor="inputCustomerSelect">Name :</label>
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                disabled
                                name="driverName"
                                id="driverName"
                                value={leftSingleDriverData.driverName}
                              />
                            </div>
                          </div>

                          <div className="form-group col-md-12 required">
                            <label htmlFor="inputCustomerAddressSelect">
                              Mobile Number :{" "}
                            </label>
                            <div className="form-group">
                              <input
                                type="number"
                                className="form-control"
                                disabled
                                name="mobileNumber"
                                id="mobileNumber"
                                value={leftSingleDriverData.mobileNumber}
                              />
                            </div>
                          </div>

                          <div className="form-group col-md-12 required">
                            <label htmlFor="inputCustomerShiftSelect">
                              Date of Leaving :
                            </label>
                            <div className="form-group">
                              <input
                                type="date"
                                name="date"
                                disabled
                                className="form-control"
                                id="date"
                              />
                            </div>
                          </div>
                          <div className="form-group col-md-12 required">
                            <label htmlFor="inputCustomerShiftSelect">
                              Rider Master Code :
                            </label>
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                disabled
                                name="driverName"
                                id="driverName"
                                placeholder="Enter Rider Master Code"
                                value={leftSingleDriverData.riderMasterCode}
                              />
                            </div>
                          </div>
                          <div className="form-group col-md-12 required">
                            <label htmlFor="inputCustomerShiftSelect">
                              Reason for Leaving :
                            </label>
                            <div className="form-group">
                              <input
                                type="checkbox"
                                name="reason"
                                id="evifyIssue"
                                disabled
                                value="Evify Issue"
                                checked={
                                  leftSingleDriverData.reason == "Evify Issue"
                                    ? true
                                    : false
                                }
                              />{" "}
                              Evify Issue
                              <input
                                type="checkbox"
                                name="reason"
                                id="personalReason"
                                disabled
                                value="Personal Reason"
                                checked={
                                  leftSingleDriverData.reason ==
                                  "Personal Reason"
                                    ? true
                                    : false
                                }
                              />{" "}
                              Personal Reason
                              <input
                                type="checkbox"
                                name="reason"
                                id="growth"
                                value="Growth"
                                disabled
                                checked={
                                  leftSingleDriverData.reason == "Growth"
                                    ? true
                                    : false
                                }
                              />{" "}
                              Growth
                              <input
                                type="checkbox"
                                name="reason"
                                id="others"
                                value="Other"
                                disabled
                                checked={
                                  leftSingleDriverData.reason == "Other"
                                    ? true
                                    : false
                                }
                              />{" "}
                              Other
                              {leftSingleDriverData.reason === "Other" ? (
                                <div class="form-check">
                                  <input
                                    class="form-control col-md-6"
                                    type="text"
                                    disabled
                                    name="deactivateReasonInput"
                                    id="deactivateReasonInput"
                                    value={leftSingleDriverData.issueText}
                                  />
                                  <br />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="form-group col-md-12 required">
                            <label htmlFor="inputCustomerShiftSelect">
                              Remarks :
                            </label>
                            <div className="form-group">
                              <textarea
                                name="remarks"
                                disabled
                                id="remarks"
                                cols="112"
                                rows="5"
                                className="form-control"
                                placeholder="Enter Remars"
                                value={leftSingleDriverData.remarks}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="assignCustomerModalCenter"
                role="dialog"
                aria-labelledby="assignCustomerModalCenterTitle"
                style={{ display: "none" }}
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg" role="document">
                  <div className="modal-content">
                    <form onSubmit={onsubmitHandler}>
                      <div className="modal-header">
                        <h5
                          className="modal-title"
                          id="assignCustomerModalCenterTitle"
                        >
                          Assign Customer/Company
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <svg
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-x"
                          >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                          </svg>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div
                          className="customer-list-class"
                          id="customer-list-div-id"
                        >
                          <div className="form-group col-12 required">
                            <label htmlFor="inputDriverFullName">
                              Driver:{" "}
                            </label>
                            <label id="inputDriverFullName">
                              {assignDriver.driverName !== ""
                                ? assignDriver.driverName
                                : "test driver"}
                            </label>
                          </div>

                          <div className="form-group col-md-12 required">
                            <label htmlFor="inputCustomerSelect">
                              Select Customer/Company *
                            </label>
                            <div className="form-group">
                              <Select
                                value={
                                  assignDriverValues.customerValue || "Select"
                                }
                                onChange={(value) =>
                                  changeCustomerDropDown(value)
                                }
                                options={customerList}
                                required
                              />
                            </div>
                          </div>

                          <div className="form-group col-md-12 required">
                            <label htmlFor="inputCustomerAddressSelect">
                              Select Address *
                            </label>
                            <div className="form-group">
                              <Select
                                value={
                                  assignDriverValues.customerAddressValue ||
                                  "Select"
                                }
                                onChange={changeAddressDropDown}
                                options={customerAddressList}
                                required
                              />
                            </div>
                          </div>

                          <div className="form-group col-md-12 required">
                            <label htmlFor="inputCustomerShiftSelect">
                              Select Shift *
                            </label>
                            <div className="form-group">
                              <Select
                                value={
                                  assignDriverValues.customerShiftValue ||
                                  "Select"
                                }
                                onChange={changeShiftDropDown}
                                options={customerShiftList}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn"
                          data-dismiss="modal"
                        >
                          <i className="flaticon-cancel-12"></i>Close
                        </button>
                        <button
                          type="submit"
                          id="assignCustomerToDriverBtn"
                          className="btn btn-primary view-btn"
                        >
                          Assign
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </>
  );
}
export default Driver_List;
