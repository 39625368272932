import React from "react";
import { useLocation } from "react-router-dom";

function SideBar() {
  const role = localStorage.getItem("role");
  const assignRoles = localStorage.getItem("assignRoles");
  const location = useLocation();
  let sidebarMenu = [];
  if (role === "H") {
    sidebarMenu = [
      {
        id: "dashboard",
        name: "Dashboard",
        link: "/adminside/hub/dashboard",
        Icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-home"
          >
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
        ),
        child: [],
      },
      {
        id: "vehicle",
        name: "Vehicle",
        link: "#",
        Icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-truck"
          >
            <rect x="1" y="3" width="15" height="13"></rect>
            <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
            <circle cx="5.5" cy="18.5" r="2.5"></circle>
            <circle cx="18.5" cy="18.5" r="2.5"></circle>
          </svg>
        ),
        child: [
          {
            name: "Vehicle List",
            link: "/adminside/hub/vehicle",
          },
        ],
      },
    ];
  } else if (role === "T") {
    sidebarMenu = [
      {
        id: "dashboard",
        name: "Dashboard",
        link: "/adminside/technician/dashboard",
        Icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-home"
          >
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
        ),
        child: [],
      },
      {
        id: "vehicle",
        name: "Vehicle",
        link: "#",
        Icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-truck"
          >
            <rect x="1" y="3" width="15" height="13"></rect>
            <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
            <circle cx="5.5" cy="18.5" r="2.5"></circle>
            <circle cx="18.5" cy="18.5" r="2.5"></circle>
          </svg>
        ),
        child: [
          {
            name: "Vehicle List",
            link: "/adminside/technician/vehicle",
          },
        ],
      },
    ];
  } else if (role === "W") {
    sidebarMenu = [
      {
        id: "dashboard",
        name: "Dashboard",
        link: "/adminside/watchman/dashboard",
        Icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-home"
          >
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
        ),
        child: [],
      },
    ];
  } else if (role === "O") {
    sidebarMenu = [
      {
        id: "dashboard",
        name: "Dashboard",
        link: "/adminside/owners/dashboard",
        Icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-home"
          >
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
        ),
        child: [],
      },
    ];
  } else {
    sidebarMenu = [
      {
        id: "dashboard",
        name: "Dashboard",
        link: "/adminside/dashboard",
        Icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-home"
          >
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
        ),
        child: [],
      },
      {
        id: "customer",
        name: "Customer",
        link: "#",
        Icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-users"
          >
            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
            <circle cx="9" cy="7" r="4"></circle>
            <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
          </svg>
        ),
        child: [
          {
            name: "Customer List",
            link: "/adminside/customer",
          },
          {
            name: "Add Customer",
            link: "/adminside/customer/add",
          },
          {
            name: "Report",
            link: "/adminside/customer/report",
          },
        ],
      },
      {
        id: "vehicle",
        name: "Vehicle",
        link: "#",
        Icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-truck"
          >
            <rect x="1" y="3" width="15" height="13"></rect>
            <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
            <circle cx="5.5" cy="18.5" r="2.5"></circle>
            <circle cx="18.5" cy="18.5" r="2.5"></circle>
          </svg>
        ),
        child: [
          {
            name: "Vehicle List",
            link: "/adminside/vehicle",
          },
          {
            name: "Add Vehicle",
            link: "/adminside/vehicle/add",
          },
          {
            name: "Settings",
            link: "/adminside/vehicle/settings",
          },
          {
            name: "Expense Form",
            link: "/adminside/vehicle/expenseForm",
          },
          {
            name: "Fleet Efficiency",
            link: "/adminside/vehicle/fleet-efficiency",
          },
        ],
      },
      {
        id: "driver",
        name: "Driver",
        link: "#",
        Icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-user-check"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
            <circle cx="8.5" cy="7" r="4"></circle>
            <polyline points="17 11 19 13 23 9"></polyline>
          </svg>
        ),
        child: [
          {
            name: "Driver List ",
            link: "/adminside/driver",
          },
          {
            name: "Add Driver",
            link: "/adminside/driver/add",
          },
          {
            name: "To Be Verified",
            link: "/adminside/driver/toBeVerify",
          },
          {
            name: "Daily Works",
            link: "/adminside/driver/dailyWorks",
          },
          {
            name: "Login Request",
            link: "/adminside/driver/loginRequest",
          },
          {
            name: "Category",
            link: "/adminside/driver/Category",
          },
          {
            name: "Issues",
            link: "/adminside/driver/issueList",
          },
          {
            name: "Attendance",
            link: "/adminside/driver/allDriverAttendance",
          },
          {
            name: "Driver Parcels Delivery",
            link: "/adminside/driver/parcels",
          },
          {
            name: "Driver Earning",
            link: "/adminside/driver/earning",
          },
          {
            name: "COD Orders",
            link: "/adminside/driver/cod-orders",
          },
        ],
      },
      {
        id: "admin",
        name: "Admin",
        link: "#",
        Icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-user"
          >
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
          </svg>
        ),
        child: [
          {
            name: " Admin List",
            link: "/adminside/admin",
          },
          {
            name: "Add Admin",
            link: "/adminside/admin/add",
          },
        ],
      },
      {
        id: "vehicleLeaseCompany",
        name: "Lease Company",
        link: "#",
        Icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-briefcase"
          >
            <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
            <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
          </svg>
        ),
        child: [
          {
            name: "Lease Company List",
            link: "/adminside/vehicleLeaseCompany",
          },
          {
            name: "Add Lease Company",
            link: "/adminside/vehicleLeaseCompany/add",
          },
        ],
      },

      {
        id: "vehicleHub",
        name: "Vehicle Hub",
        link: "#",
        Icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M6 23q-1.25 0-2.125-.875T3 20q0-1.25.875-2.125T6 17q.35 0 .65.075t.575.2L8.65 15.5q-.7-.775-.975-1.75T7.55 11.8l-2.025-.675q-.425.625-1.075 1T3 12.5q-1.25 0-2.125-.875T0 9.5q0-1.25.875-2.125T3 6.5q1.25 0 2.125.875T6 9.5v.2l2.025.7q.5-.9 1.338-1.525t1.887-.8V5.9q-.975-.275-1.613-1.063T9 3q0-1.25.875-2.125T12 0q1.25 0 2.125.875T15 3q0 1.05-.65 1.838T12.75 5.9v2.175q1.05.175 1.888.8t1.337 1.525L18 9.7v-.2q0-1.25.875-2.125T21 6.5q1.25 0 2.125.875T24 9.5q0 1.25-.875 2.125T21 12.5q-.8 0-1.463-.375t-1.062-1l-2.025.675q.15.975-.125 1.938T15.35 15.5l1.425 1.75q.275-.125.575-.188T18 17q1.25 0 2.125.875T21 20q0 1.25-.875 2.125T18 23q-1.25 0-2.125-.875T15 20q0-.5.163-.963t.437-.837l-1.425-1.775Q13.15 17 11.987 17T9.8 16.425L8.4 18.2q.275.375.438.838T9 20q0 1.25-.875 2.125T6 23Z"
            />
          </svg>
        ),
        child: [
          {
            name: "Vehicle Hub List",
            link: "/adminside/vehicleHub",
          },
        ],
      },
      {
        id: "bikeType",
        name: "Bike Type",
        link: "#",
        Icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M5 20.5A3.5 3.5 0 0 1 1.5 17A3.5 3.5 0 0 1 5 13.5A3.5 3.5 0 0 1 8.5 17A3.5 3.5 0 0 1 5 20.5M5 12a5 5 0 0 0-5 5a5 5 0 0 0 5 5a5 5 0 0 0 5-5a5 5 0 0 0-5-5m9.8-2H19V8.2h-3.2l-1.94-3.27c-.29-.5-.86-.83-1.46-.83c-.47 0-.9.19-1.2.5L7.5 8.29C7.19 8.6 7 9 7 9.5c0 .63.33 1.16.85 1.47L11.2 13v5H13v-6.5l-2.25-1.65l2.32-2.35m5.93 13a3.5 3.5 0 0 1-3.5-3.5a3.5 3.5 0 0 1 3.5-3.5a3.5 3.5 0 0 1 3.5 3.5a3.5 3.5 0 0 1-3.5 3.5m0-8.5a5 5 0 0 0-5 5a5 5 0 0 0 5 5a5 5 0 0 0 5-5a5 5 0 0 0-5-5m-3-7.2c1 0 1.8-.8 1.8-1.8S17 1.2 16 1.2S14.2 2 14.2 3S15 4.8 16 4.8Z"
            />
          </svg>
        ),
        child: [
          {
            name: "Bike Type",
            link: "/adminside/bikeType",
          },
        ],
      },
      {
        id: "SalesandPayout",
        name: "Sales and Payout",
        link: "#",
        Icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-home"
          >
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
        ),
        child: [
          {
            name: "Add Sales And Payout",
            link: "/adminside/sales-and-payout/add-sales-payout",
          },
          {
            name: "View Sales And Payout",
            link: "/adminside/sales-and-payout/view-sales-payout",
          },
        ],
      },
      {
        id: "settings",
        name: "Settings",
        link: "#",
        Icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <g
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            >
              <circle cx="12" cy="12" r="3" />
              <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83a2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33a1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0a2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82a1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2a2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83a2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2a2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51a1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0a2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" />
            </g>
          </svg>
        ),
        child: [
          {
            name: "Expense Configuration",
            link: "/adminside/settings/expense-configuration",
          },
        ],
      },
      {
        id: "billingAnalysis",
        name: "Billing Analysis",
        link: "/adminside/billing-analysis",
        Icon: (
          <svg
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="ui-svg-inline mr-2-5"
          >
            <g
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            >
              <path d="M21,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,14.05,2H10A3,3,0,0,0,7,5V6H6A3,3,0,0,0,3,9V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V18h1a3,3,0,0,0,3-3V9S21,9,21,8.94ZM15,5.41,17.59,8H16a1,1,0,0,1-1-1ZM15,19a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V9A1,1,0,0,1,6,8H7v7a3,3,0,0,0,3,3h5Zm4-4a1,1,0,0,1-1,1H10a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1h3V7a3,3,0,0,0,3,3h3Z"></path>
            </g>
          </svg>
        ),
        child: [],
      },
    ];
    if (role !== "A") {
      var array = assignRoles.split(",");
      sidebarMenu = [];
      if (array.includes("1")) {
        sidebarMenu.push({
          id: "dashboard",
          name: "Dashboard",
          link: "/adminside/dashboard",
          Icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-home"
            >
              <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
              <polyline points="9 22 9 12 15 12 15 22"></polyline>
            </svg>
          ),
          child: [],
        });
      }
      if (array.includes("2")) {
        let submenu = [];
        if (array.includes("3")) {
          submenu.push({
            name: "Customer List",
            link: "/adminside/customer",
          });
        }
        if (array.includes("24")) {
          submenu.push({
            name: "Add Customer",
            link: "/adminside/customer/add",
          });
        }
        if (array.includes("50")) {
          submenu.push({
            name: "Report",
            link: "/adminside/customer/report",
          });
        }
        sidebarMenu.push({
          id: "customer",
          name: "Customer",
          link: "#",
          Icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-users"
            >
              <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
              <circle cx="9" cy="7" r="4"></circle>
              <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
              <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
            </svg>
          ),
          child: submenu,
        });
      }
      if (array.includes("5")) {
        let submenu = [];
        if (array.includes("6")) {
          submenu.push({
            name: "Vehicle List",
            link: "/adminside/vehicle",
          });
        }
        if (array.includes("7")) {
          submenu.push({
            name: "Add Vehicle",
            link: "/adminside/vehicle/add",
          });
        }

        sidebarMenu.push({
          id: "vehicle",
          name: "Vehicle",
          link: "#",
          Icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-truck"
            >
              <rect x="1" y="3" width="15" height="13"></rect>
              <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
              <circle cx="5.5" cy="18.5" r="2.5"></circle>
              <circle cx="18.5" cy="18.5" r="2.5"></circle>
            </svg>
          ),
          child: submenu,
        });
      }
      if (array.includes("8")) {
        let submenu = [];
        if (array.includes("9")) {
          submenu.push({
            name: "Driver List ",
            link: "/adminside/driver",
          });
        }
        if (array.includes("10")) {
          submenu.push({
            name: "Add Driver",
            link: "/adminside/driver/add",
          });
        }
        if (array.includes("11")) {
          submenu.push({
            name: "To Be Verified",
            link: "/adminside/driver/toBeVerify",
          });
        }
        if (array.includes("12")) {
          submenu.push({
            name: "Daily Works",
            link: "/adminside/driver/dailyWorks",
          });
        }
        if (array.includes("13")) {
          submenu.push({
            name: "Login Request",
            link: "/adminside/driver/loginRequest",
          });
        }
        if (array.includes("51")) {
          submenu.push({
            name: "Issues",
            link: "/adminside/driver/issueList",
          });
        }
        if (array.includes("52")) {
          submenu.push({
            name: "Attendance",
            link: "/adminside/driver/allDriverAttendance",
          });
        }
        sidebarMenu.push({
          id: "driver",
          name: "Driver",
          link: "#",
          Icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-user-check"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
              <circle cx="8.5" cy="7" r="4"></circle>
              <polyline points="17 11 19 13 23 9"></polyline>
            </svg>
          ),
          child: submenu,
        });
      }
      if (array.includes("14")) {
        let submenu = [];
        if (array.includes("15")) {
          submenu.push({
            name: " Admin List",
            link: "/adminside/admin",
          });
        }
        if (array.includes("16")) {
          submenu.push({
            name: "Add Admin",
            link: "/adminside/admin/add",
          });
        }
        sidebarMenu.push({
          id: "admin",
          name: "Admin",
          link: "#",
          Icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-user"
            >
              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
            </svg>
          ),
          child: submenu,
        });
      }

      if (array.includes("35")) {
        let submenu = [];
        if (array.includes("36")) {
          submenu.push({
            name: "Lease Company List",
            link: "/adminside/vehicleLeaseCompany",
          });
        }
        if (array.includes("39")) {
          submenu.push({
            name: "Add Lease Company",
            link: "/adminside/vehicleLeaseCompany/add",
          });
        }
        sidebarMenu.push({
          id: "vehicleLeaseCompany",
          name: "Lease Company",
          link: "#",
          Icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-briefcase"
            >
              <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
              <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
            </svg>
          ),
          child: submenu,
        });
      }
      if (array.includes("45")) {
        let submenu = [];
        if (array.includes("46")) {
          submenu.push({
            name: "Vehicle Hub List",
            link: "/adminside/vehicleHub",
          });
        }
        sidebarMenu.push({
          id: "vehicleHub",
          name: "Vehicle Hub",
          link: "#",
          Icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-briefcase"
            >
              <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
              <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
            </svg>
          ),
          child: submenu,
        });
      }
      if (array.includes("53")) {
        let submenu = [];
        if (array.includes("54")) {
          submenu.push({
            name: "Bike Type",
            link: "/adminside/bikeType",
          });
        }
        sidebarMenu.push({
          id: "bikeType",
          name: "Bike Type",
          link: "#",
          Icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-briefcase"
            >
              <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
              <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
            </svg>
          ),
          child: submenu,
        });
      }
    }
  }

  return (
    <div className="sidebar-wrapper sidebar-theme">
      <nav id="sidebar">
        <div className="shadow-bottom"></div>
        <ul className="list-unstyled menu-categories" id="accordionExample">
          {sidebarMenu.map((item) => {
            return (
              <li className="menu" key={`list${item.id}`}>
                <a
                  href={`${item.link !== "#" ? item.link : `#${item.id}`}`}
                  data-toggle={item.link === "#" ? "collapse" : ""}
                  aria-expanded={
                    (item.link !== "#" && location.pathname === item.link) ||
                    item.child.filter((val) => val.link === location.pathname)
                      .length
                      ? "true"
                      : "false"
                  }
                  data-active={
                    (item.link !== "#" && location.pathname === item.link) ||
                    item.child.filter((val) => val.link === location.pathname)
                      .length
                      ? "true"
                      : "false"
                  }
                  className="dropdown-toggle sidebarmenu1"
                >
                  <div>
                    {item.Icon}
                    <span>{item.name}</span>
                  </div>
                  {item.child.length ? (
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-chevron-right"
                      >
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                    </div>
                  ) : null}
                </a>
                <ul
                  className={`collapse submenu list-unstyled ${
                    item.child.filter((val) => val.link === location.pathname)
                      .length
                      ? "show"
                      : ""
                  }`}
                  id={item.id}
                  data-parent="#accordionExample"
                >
                  {item.child.map((childItem, index) => {
                    return (
                      <li
                        key={`sublist-${index}`}
                        className={`sidebarmenu3 ${
                          childItem.link === location.pathname ? "active" : ""
                        }`}
                      >
                        <a href={childItem.link}> {childItem.name} </a>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
}

export default React.memo(SideBar);
