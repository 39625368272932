import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import Header from "../../DashBoard/Header";
import Sub_Header from "../../DashBoard/Sub_Header";
import SideBar from "../../DashBoard/SideBar";
import Footer from "../../DashBoard/Footer";
import { ToastContainer, toast } from "react-toastify";
import * as vehicleaction from "../../../action/Vehicle/vehicle_action";
import * as common from "../../../action/common/common_action";
import { Input } from "rsuite";
import { RxCross2 } from "react-icons/rx";
import $ from "jquery";
import Pagination from "../../common/Pagination/Pagination";

const FleetEfficiency = () => {
  const yearAndMonthFocus = useRef();
  const cityFocus = useRef();
  const utilizedBikesFocus = useRef();
  const unutilizedBikesFocus = useRef();
  const repairedBikesFocus = useRef();

  const editYearAndMonthFocus = useRef();
  const editCityFocus = useRef();
  const editutilizedBikesFocus = useRef();
  const editUnutilizedBikesFocus = useRef();
  const editrepairedBikesFocus = useRef();

  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

  const [cityList, setCityList] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [disabled, setDisabled] = useState(true);
  const [editButtonHide, setEditButtonHide] = useState(false);
  const [submitButtonHide, setSubmitButtonHide] = useState(true);

  const [formId, setFormId] = useState();
  const [fleetData, setFleetData] = useState([]);

  const [addFleetData, setAddFleetData] = useState({
    yearAndMonth: 0,
    cityId: "",
    utilizedBikes: 0,
    unutilizedBikes: 0,
    repairedBikes: 0,
  });

  const [editFleetData, setEditFleetData] = useState({
    yearAndMonth: 0,
    cityId: "",
    utilizedBikes: 0,
    unutilizedBikes: 0,
    repairedBikes: 0,
  });

  const setEditFormEditable = (type) => {
    if (type) {
      setDisabled(false);
      //   setCancelButtonHide(false);
      setSubmitButtonHide(false);
      setEditButtonHide(true);
    } else {
      setDisabled(true);
      //   setCancelButtonHide(true);
      setSubmitButtonHide(true);
      setEditButtonHide(false);
    }
  };

  // city list
  useEffect(() => {
    dispatch(common.common_city_list())
      .then((data) => {
        let newCustomerList = [];
        data.data.map((item) => {
          newCustomerList.push({ value: item._id, label: item.cityName });
        });
        setCityList(newCustomerList);
      })
      .catch({});
  }, {});

  useEffect(() => {
    dispatch(vehicleaction.Get_Fleet_Efficiency())
      .then((data) => {
        let fleetEfficiency = data.data;
        setFleetData(fleetEfficiency);
      })
      .catch((err) => {});
  }, []);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setErrors({});
    let customErrors = {};

    if (!addFleetData.yearAndMonth) {
      customErrors = {
        ...customErrors,
        yearAndMonth: "Please Select Year Month",
      };
      yearAndMonthFocus.current.focus();
    } else if (!addFleetData.cityId) {
      customErrors = { ...customErrors, cityId: "Please Select City" };
      cityFocus.current.focus();
    } else if (!addFleetData.utilizedBikes) {
      customErrors = {
        ...customErrors,
        utilizedBikes: "Please Enter No of Utilized Bikes",
      };
      utilizedBikesFocus.current.focus();
    } else if (!addFleetData.unutilizedBikes) {
      customErrors = {
        ...customErrors,
        unutilizedBikes: "Please Enter No of Unutilized Bikes",
      };
      unutilizedBikesFocus.current.focus();
    } else if (!addFleetData.repairedBikes) {
      customErrors = {
        ...customErrors,
        repairedBikes: "Please Enter No of Repair Bikes",
      };
      repairedBikesFocus.current.focus();
    }

    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }

    dispatch(vehicleaction.Add_Fleet_Efficiency(addFleetData))
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          setFleetData(data?.data);
          $("#addFleetEfficency .close").click();
        } else if (!data.success) {
          toast.error(data.message);
        }
      })
      .catch((err) => {});
  };

  const onUpdateHandler = (e) => {
    e.preventDefault();
    setErrors({});
    let customErrors = {};

    if (!editFleetData.yearAndMonth) {
      customErrors = {
        ...customErrors,
        yearAndMonth: "Please Select Year Month",
      };
      editYearAndMonthFocus.current.focus();
    } else if (!editFleetData.cityId) {
      customErrors = { ...customErrors, cityId: "Please Select City" };
      editCityFocus.current.focus();
    } else if (!editFleetData.utilizedBikes) {
      customErrors = {
        ...customErrors,
        utilizedBikes: "Please Enter No of Utilized Bikes",
      };
      editutilizedBikesFocus.current.focus();
    } else if (!editFleetData.unutilizedBikes) {
      customErrors = {
        ...customErrors,
        unutilizedBikes: "Please Enter No of Unutilized Bikes",
      };
      editUnutilizedBikesFocus.current.focus();
    } else if (!editFleetData.repairedBikes) {
      customErrors = {
        ...customErrors,
        repairedBikes: "Please Enter No of Repair Bikes",
      };
      editrepairedBikesFocus.current.focus();
    }

    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }

    dispatch(
      vehicleaction.Edit_Fleet_Efficiency(formId ? formId : null, editFleetData)
    )
      .then((data) => {
        if (data.success) {
          toast.success(data.message);

          $("#editFleetDataForm .close").click();
          setFleetData(data?.data);
          setEditFormEditable(false);
        } else if (!data.success) {
          toast.error(data.message);
        }
        setErrors({});
      })
      .catch((err) => {});

    setErrors({});
  };

  const handleEditClick = (item) => {
    setFormId(item._id);
    const formattedMonth =
      item.month < 10 && item.month > 0 ? `0${item.month}` : item.month;

    let yearMonth = `${item.year}-${formattedMonth}`;

    setEditFleetData({
      yearAndMonth: yearMonth,
      cityId: item.cityId,
      utilizedBikes: item.utilizedBikes,
      unutilizedBikes: item.unutilizedBikes,
      repairedBikes: item.repairedBikes,
    });

    setErrors({});
  };

  const handleCancelClick = () => {
    setEditFormEditable(false);
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * 10;
    const lastPageIndex = firstPageIndex + 10;
    return fleetData?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, fleetData]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Sub_Header />
      <div className="main-container" id="container">
        <div className="overlay"></div>
        <div className="search-overlay"></div>
        <SideBar />
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                <div className="widget-content widget-content-area br-6">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="" style={{ margin: "10px" }}>
                      Fleet Efficiency
                    </h5>
                    <a
                      href="/"
                      className="btn btn-warning btn-sm add-act-btn"
                      data-toggle="modal"
                      data-target="#addFleetEfficency"
                    >
                      Add
                    </a>
                  </div>

                  <br />
                  <div className="table-responsive mb-4 mt-4">
                    {1 ? (
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>Year-Month</th>
                            <th>City</th>
                            <th>Utilized Bike</th>
                            <th>Unutilized Bike</th>
                            <th>Repaired Bike</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentTableData.map((item, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  {item?.year}-{item?.month}
                                </td>
                                <td>{item?.cityName}</td>
                                <td>{item?.utilizedBikes}</td>
                                <td>{item?.unutilizedBikes}</td>
                                <td>{item?.repairedBikes}</td>
                                <td>
                                  <a
                                    href="/"
                                    onClick={() => handleEditClick(item)}
                                    className="btn btn-info btn-sm edit-btn edit-act-btn"
                                    data-toggle="modal"
                                    data-target="#editFleetDataForm"
                                  >
                                    Edit
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <p className="text-center">No data Available</p>
                    )}
                    {/* pagination */}
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={fleetData?.length}
                      pageSize={10}
                      onPageChange={(page) => setCurrentPage(page)}
                    ></Pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>

      {/* add model */}
      <div
        className="modal fade"
        id="addFleetEfficency"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addFleetEfficencyTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <form onSubmit={onSubmitHandler}>
              <div className="modal-header">
                <h5 className="modal-title" id="addHubModalCenterTitle">
                  Add Fleet Efficiency
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <RxCross2 />
                </button>
              </div>
              <div className="modal-body">
                <div
                  className="customer-address-class"
                  id="customer-address-div-id"
                >
                  <div className="row" id="defaultaddressdiv0">
                    <div className="col-12">
                      <div id="append-div-id">
                        <div class="form-group row">
                          <div class="form-group col-4 required">
                            <label>Select Year and Month *</label>
                            <Input
                              type="month"
                              ref={yearAndMonthFocus}
                              name="yearAndMonth"
                              value={addFleetData?.yearAndMonth}
                              onChange={(value) => {
                                setAddFleetData({
                                  ...addFleetData,
                                  yearAndMonth: value,
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>
                              {errors?.yearAndMonth}
                            </span>
                          </div>
                          <div class="form-group col-md-4 required">
                            <label>Select City *</label>
                            <select
                              id="cityId"
                              ref={cityFocus}
                              className="form-control selectpicker"
                              value={addFleetData?.cityId}
                              onChange={(e) => {
                                setAddFleetData({
                                  ...addFleetData,
                                  cityId: e.target.value,
                                });
                              }}
                            >
                              <option value="">Select City</option>
                              {cityList.map((item) => {
                                return (
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            <span style={{ color: "red" }}>
                              {errors?.cityId}
                            </span>
                          </div>
                        </div>

                        <div class="form-group row">
                          <div class="form-group col-md-4 required">
                            <label for="inputFullname">
                              Enter Utilized Bike
                            </label>
                            <input
                              type="number"
                              min="0"
                              name="utilizedBikes"
                              className="form-control"
                              ref={utilizedBikesFocus}
                              placeholder="No of utilizedBikes"
                              value={addFleetData?.utilizedBikes}
                              onChange={(e) =>
                                setAddFleetData({
                                  ...addFleetData,
                                  utilizedBikes: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.utilizedBikes}
                            </span>
                          </div>

                          <div class="form-group col-md-4 required">
                            <label for="inputFullname">
                              Enter Unutilized Bike
                            </label>
                            <input
                              type="number"
                              min="0"
                              name="unutilizedBikes"
                              className="form-control"
                              ref={unutilizedBikesFocus}
                              placeholder="No of UnutilizedBikes"
                              value={addFleetData?.unutilizedBikes}
                              onChange={(e) =>
                                setAddFleetData({
                                  ...addFleetData,
                                  unutilizedBikes: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.unutilizedBikes}
                            </span>
                          </div>

                          <div class="form-group col-md-4 required">
                            <label for="inputFullname">
                              Enter No of Repaired Bike
                            </label>
                            <input
                              type="number"
                              min="0"
                              name="repairedBikes"
                              className="form-control"
                              ref={repairedBikesFocus}
                              placeholder="No of Repair Bike"
                              value={addFleetData?.repairedBikes}
                              onChange={(e) =>
                                setAddFleetData({
                                  ...addFleetData,
                                  repairedBikes: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.repairedBikes}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="reset"
                  data-dismiss="modal"
                  className="btn btn-danger "
                  onClick={handleCancelClick}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success save-btn">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* update model */}
      <div
        className="modal fade"
        id="editFleetDataForm"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editFleetDataFormTitle"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <form onSubmit={onUpdateHandler}>
              <div className="modal-header">
                <h5 className="modal-title" id="editFleetDataFormTitle">
                  Fleet Efficiency
                </h5>
                <button
                  type="button"
                  className="btn btn-primary view-btn"
                  hidden={editButtonHide}
                  onClick={() => setEditFormEditable(true)}
                >
                  Edit
                </button>
              </div>

              <div className="modal-body">
                <div
                  className="customer-address-class"
                  id="customer-address-div-id"
                >
                  <div className="row" id="defaultaddressdiv0">
                    <div className="col-12">
                      <div id="append-div-id">
                        <div class="form-group row">
                          <div class="form-group col-4 required">
                            <label>Select Year and Month *</label>
                            <Input
                              disabled={disabled}
                              type="month"
                              ref={editYearAndMonthFocus}
                              name="yearAndMonth"
                              value={editFleetData?.yearAndMonth}
                              onChange={(value) =>
                                setEditFleetData({
                                  ...editFleetData,
                                  yearAndMonth: value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.yearAndMonth}
                            </span>
                          </div>
                          <div class="form-group col-md-4 required">
                            <label>Select City *</label>
                            <select
                              disabled={disabled}
                              id="cityId"
                              ref={editCityFocus}
                              className="form-control selectpicker"
                              value={editFleetData?.cityId}
                              onChange={(e) => {
                                setEditFleetData({
                                  ...editFleetData,
                                  cityId: e.target.value,
                                });
                              }}
                            >
                              <option value="">Select City</option>
                              {cityList.map((item) => {
                                return (
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            <span style={{ color: "red" }}>
                              {errors?.cityId}
                            </span>
                          </div>
                        </div>

                        <div class="form-group row">
                          <div class="form-group col-md-4 required">
                            <label for="inputFullname">
                              Enter Utilized Bike
                            </label>
                            <input
                              disabled={disabled}
                              type="number"
                              min="0"
                              name="utilizedBikes"
                              className="form-control"
                              ref={editutilizedBikesFocus}
                              placeholder="No of utilizedBikes"
                              value={editFleetData.utilizedBikes}
                              onChange={(e) =>
                                setEditFleetData({
                                  ...editFleetData,
                                  utilizedBikes: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.utilizedBikes}
                            </span>
                          </div>

                          <div class="form-group col-md-4 required">
                            <label for="inputFullname">
                              Enter Unutilized Bike
                            </label>
                            <input
                              disabled={disabled}
                              type="number"
                              min="0"
                              name="unutilizedBikes"
                              className="form-control"
                              ref={editUnutilizedBikesFocus}
                              placeholder="No of UnutilizedBikes"
                              value={editFleetData?.unutilizedBikes}
                              onChange={(e) =>
                                setEditFleetData({
                                  ...editFleetData,
                                  unutilizedBikes: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.unutilizedBikes}
                            </span>
                          </div>

                          <div class="form-group col-md-4 required">
                            <label for="inputFullname">
                              Enter No of Repaired Bike
                            </label>
                            <input
                              disabled={disabled}
                              type="number"
                              min="0"
                              name="repairedBikes"
                              className="form-control"
                              ref={editrepairedBikesFocus}
                              placeholder="No of Repair Bike"
                              value={editFleetData?.repairedBikes}
                              onChange={(e) =>
                                setEditFleetData({
                                  ...editFleetData,
                                  repairedBikes: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.repairedBikes}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary view-btn"
                  hidden={submitButtonHide}
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default FleetEfficiency;
