import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import Chart from "react-apexcharts";
import * as action from "../../../../action/Dashboard/dashboard_action";

const RiderAnalysis = () => {
  const dispatch = useDispatch();

  // months List
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  //current Year
  const currentYear = {
    value: new Date().getFullYear(),
    label: new Date().getFullYear(),
  };

  // rider analysis - new driver and left driver
  const [driverCount, setDriverCount] = useState({
    new: [],
    left: [],
    years: [],
  });
  const [selectForRidersYear, setSelectForRidersYear] = useState();

  // Rider Analysis - new driver vs left driver
  useEffect(() => {
    dispatch(
      action.dashboard_newrider_leftrider_monthly(
        selectForRidersYear?.value
          ? selectForRidersYear?.value
          : currentYear?.value
      )
    )
      .then((data) => {
        let yearList = [];
        let newDriverData = [];
        let leftDriverData = [];
        let driverData = data.data;

        leftDriverData = driverData?.leftDriver;
        newDriverData = driverData?.newDiver;

        yearList = driverData?.allYears
          .map((year) => ({
            value: year,
            label: year,
          }))
          .sort((a, b) => b.value - a.value);

        setDriverCount({
          ...driverCount,
          new: newDriverData,
          left: leftDriverData,
          years: yearList,
        });
      })
      .catch({});
  }, [selectForRidersYear]);

  // Rider Analysis - new driver vs left driver
  var RiderChartoptions = {
    chart: {
      fontFamily: "Apple-System, sans-serif",
      width: "100%",
      height: 400,
      type: "area",
      zoom: {
        enabled: false,
      },
    },
    colors: ["#57C5B6", "#FFB84C"],
    dataLabels: {
      enabled: false,
    },

    fill: {
      type: "gradient",
    },

    series: [
      {
        name: "New Driver",
        data: driverCount.new,
      },
      {
        name: "Left Driver",
        data: driverCount.left,
      },
    ],
    stroke: {
      curve: "smooth",
    },
    legend: {
      position: "bottom",
    },
    xaxis: {
      categories: monthNames,
    },
    responsive: [
      {
        breakpoint: 1000,
        yaxis: {
          categories: monthNames,
        },
        options: {
          plotOptions: {
            line: {
              horizontal: true,
              columnWidth: "80%",
            },
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div className=" h-auto layout-spacing">
      <div className="widget widget-chart-one">
        <div className="widget-heading">
          <h5 className=""> Rider Analysis </h5>
          <ul className="tabs  d-flex justify-content-between align-items-center">
            <li>
              <a id="tb_1" className="tabmenu">
                Monthly
              </a>
            </li>
          </ul>
        </div>
        <ul className="list-unstyled  d-flex justify-content-start align-items-center">
          <li className="mx-4">
            <label>Year</label>
            <Select
              id="inputYear"
              defaultValue={currentYear}
              value={selectForRidersYear}
              onChange={(value) => {
                setSelectForRidersYear(value);
              }}
              options={driverCount.years}
            />
          </li>
        </ul>
        <div id="vehiclechart">
          {driverCount?.new?.length > 0 || driverCount?.left?.length > 0 ? (
            <Chart
              options={RiderChartoptions}
              type="area"
              series={RiderChartoptions.series}
              height={300}
            />
          ) : (
            <p className="text-center">No Data Found</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RiderAnalysis;
