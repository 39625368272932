import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import * as action from "../../../../../../action/Dashboard/dashboard_action";
import { useDispatch } from "react-redux";

const BBNowChart = ({ monthNames, cityYearFilterData }) => {
  const dispatch = useDispatch();

  const [bbNowBillingData, setBBNowBillingData] = useState({
    revenue: [],
    orders: [],
  });

  // bbNow billing analysis
  useEffect(() => {
    dispatch(action.dashboard_bbnow_billing_chart(cityYearFilterData))
      .then((data) => {
        let bbNowData = data.data;
        let revenue = [];
        let orders = [];

        bbNowData.map((item) => {
          revenue.push(item.revenue);
          orders.push(item.orders);
        });

        setBBNowBillingData({
          ...bbNowBillingData,
          revenue: revenue,
          orders: orders,
        });
      })
      .catch({});
  }, [cityYearFilterData]);

  // BB Now - billing analysis
  var billingBBNowAnalysis = {
    chart: {
      height: 300,
      type: "line",
      fontFamily: "Apple-System, sans-serif",
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "BB Now",
      align: "center",
      margin: 10,
      offsetX: -10,
      offsetY: 10,
      floating: false,
      style: {
        fontSize: "20px",
        color: "#0e1726",
      },
    },
    stroke: {
      curve: "straight",
    },
    series: [
      {
        name: "Revenue",
        data: bbNowBillingData.revenue,
      },
      {
        name: "Orders",
        data: bbNowBillingData.orders,
      },
    ],
    xaxis: {
      categories: monthNames,
    },
    legend: {
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 1000,
        yaxis: {
          categories: monthNames,
        },
        options: {
          plotOptions: {
            bar: {
              horizontal: true,
              columnWidth: "80%",
            },
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div className="col" id="chart">
      <Chart
        options={billingBBNowAnalysis}
        type="line"
        series={billingBBNowAnalysis.series}
        height={300}
      />
    </div>
  );
};

export default BBNowChart;
