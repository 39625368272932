import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import Chart from "react-apexcharts";
import * as action from "../../../../action/Dashboard/dashboard_action";

const CustomerWiseRiders = () => {
  const dispatch = useDispatch();
  // customers wise monthly rider
  const [selectForCustomersYear, setSelectForCustomersYear] = useState();
  const [customersNameColorYearData, setCustomersNameColorYearData] = useState({
    customers: [],
    colors: [],
    years: [],
  });

  // months List
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  //current Year
  const currentYear = {
    value: new Date().getFullYear(),
    label: new Date().getFullYear(),
  };

  // Monthly Customer wise Rider in each Company Chart
  useEffect(() => {
    dispatch(
      action.dashboard_customer_details_daily(
        selectForCustomersYear?.value
          ? selectForCustomersYear?.value
          : currentYear?.value
      )
    )
      .then((data) => {
        let customerNewList = [];
        let customerColorList = [];
        let customerData = data?.data?.customerWiseRiderData;

        let yearList = data.data.yearList
          .map((year) => ({
            value: year,
            label: year,
          }))
          .sort((a, b) => b.value - a.value);

        // customers array and color array store data
        customerData.map((item) => {
          customerNewList.push({
            name: item._id.customerName,
            data: item.outData,
          });
          customerColorList.push(item._id.colorCode);
        });

        setCustomersNameColorYearData({
          ...customersNameColorYearData,
          customers: customerNewList,
          colors: customerColorList,
          years: yearList,
        });
      })
      .catch({});
  }, [selectForCustomersYear]);

  // Monthly Customer wise Rider in each Company Chart
  var CustomerChartoptions = {
    chart: {
      fontFamily: "Apple-System, sans-serif",
      width: "100%",
      height: 350,
      type: "bar",
    },
    colors: customersNameColorYearData.colors,
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },

    series: customersNameColorYearData.customers,
    xaxis: {
      categories: monthNames,
    },
    legend: {
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 1000,
        yaxis: {
          categories: monthNames,
        },
        options: {
          plotOptions: {
            bar: {
              horizontal: true,
              columnWidth: "80%",
            },
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div className=" layout-spacing">
      <div className="widget widget-chart-one">
        <div className="widget-heading">
          <h5 className="">Monthly Customer Wise Rider</h5>
          <ul className="tabs  d-flex justify-content-between align-items-center">
            <li>
              <a id="tb_1" className="tabmenu">
                Monthly
              </a>
            </li>
          </ul>
        </div>

        <ul className="list-unstyled  d-flex justify-content-start align-items-center">
          <li className="mx-4">
            <label>Year</label>
            <Select
              id="inputYear"
              defaultValue={currentYear}
              value={selectForCustomersYear}
              onChange={(value) => {
                setSelectForCustomersYear(value);
              }}
              options={customersNameColorYearData.years}
            />
          </li>
        </ul>
        <div id="chart">
          {customersNameColorYearData.customers.length > 0 ? (
            <Chart
              options={CustomerChartoptions}
              series={CustomerChartoptions.series}
              type="bar"
              height={350}
            />
          ) : (
            <p className="text-center">No Data Found</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerWiseRiders;
