export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESSFULL = "LOGIN_SUCCESSFULL";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT_LOADING = "LOGOUT_LOADING";
export const LOGOUT_SUCCESSFULL = "LOGOUT_SUCCESSFULL";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const NOTIFICATION_LOADING = "NOTIFICATION_LOADING";
export const NOTIFICATION_SUCCESSFULL = "NOTIFICATION_SUCCESSFULL";
export const NOTIFICATION_ERROR = "NOTIFICATION_ERROR";
export const CUSTOMER_LIST_SUCCESSFULL = "CUSTOMER_LIST_SUCCESSFULL";
export const CUSTOMER_LIST_ERROR = "CUSTOMER_LIST_ERROR";
export const CUSTOMER_LIST_LOADING = "CUSTOMER_LIST_LOADING";
export const CUSTOMER_ADD_SUCCESSFULL = "CUSTOMER_ADD_SUCCESSFULL";
export const CUSTOMER_ADD_ERROR = "CUSTOMER_ADD_ERROR";
export const CUSTOMER_ADD_LOADING = "CUSTOMER_ADD_LOADING";
export const CUSTOMER_ADD_ADDRESS_SUCCESSFULL =
  "CUSTOMER_ADD_ADDRESS_SUCCESSFULL";
export const CUSTOMER_ADD_ADDRESS_ERROR = "CUSTOMER_ADD_ADDRESS_ERROR";
export const CUSTOMER_ADD_ADDRESS_LOADING = "CUSTOMER_ADD_ADDRESS_LOADING";
export const CUSTOMER_EDIT_ADDRESS_SUCCESSFULL =
  "CUSTOMER_EDIT_ADDRESS_SUCCESSFULL";
export const CUSTOMER_EDIT_ADDRESS_ERROR = "CUSTOMER_EDIT_ADDRESS_ERROR";
export const CUSTOMER_EDIT_ADDRESS_LOADING = "CUSTOMER_EDIT_ADDRESS_LOADING";
export const CUSTOMER_STATUS_CHANGE_SUCCESSFULL =
  "CUSTOMER_STATUS_CHANGE_SUCCESSFULL";
export const CUSTOMER_STATUS_CHANGE_ERROR = "CUSTOMER_STATUS_CHANGE_ERROR";
export const CUSTOMER_STATUS_CHANGE_LOADING = "CUSTOMER_STATUS_CHANGE_LOADING";
export const CUSTOMER_ADD_SHIFT_SUCCESSFULL = "CUSTOMER_ADD_SHIFT_SUCCESSFULL";
export const CUSTOMER_ADD_SHIFT_ERROR = "CUSTOMER_ADD_SHIFT_ERROR";
export const CUSTOMER_ADD_SHIFT_LOADING = "CUSTOMER_ADD_SHIFT_LOADING";
export const CUSTOMER_EDIT_SHIFT_SUCCESSFULL =
  "CUSTOMER_EDIT_SHIFT_SUCCESSFULL";
export const CUSTOMER_EDIT_SHIFT_ERROR = "CUSTOMER_EDIT_SHIFT_ERROR";
export const CUSTOMER_EDIT_SHIFT_LOADING = "CUSTOMER_EDIT_SHIFT_LOADING";
export const CUSTOMER_EDIT_SUCCESSFULL = "CUSTOMER_EDIT_SUCCESSFULL";
export const CUSTOMER_EDIT_ERROR = "CUSTOMER_EDIT_ERROR";
export const CUSTOMER_EDIT_LOADING = "CUSTOMER_EDIT_LOADING";
export const CUSTOMER_DETAILS_SUCCESSFULL = "CUSTOMER_DETAILS_SUCCESSFULL";
export const CUSTOMER_DETAILS_ERROR = "CUSTOMER_DETAILS_ERROR";
export const CUSTOMER_DETAILS_LOADING = "CUSTOMER_DETAILS_LOADING";
export const ADDRESS_LIST_SUCCESSFULL = "ADDRESS_LIST_SUCCESSFULL";
export const ADDRESS_LIST_ERROR = "ADDRESS_LIST_ERROR";
export const ADDRESS_LIST_LOADING = "ADDRESS_LIST_LOADING";
export const ADDRESS_STATUS_CHANGE_SUCCESSFULL =
  "ADDRESS_STATUS_CHANGE_SUCCESSFULL";
export const ADDRESS_STATUS_CHANGE_ERROR = "ADDRESS_STATUS_CHANGE_ERROR";
export const ADDRESS_STATUS_CHANGE_LOADING = "ADDRESS_STATUS_CHANGE_LOADING";
export const SHIFT_LIST_SUCCESSFULL = "SHIFT_LIST_SUCCESSFULL";
export const SHIFT_LIST_ERROR = "SHIFT_LIST_ERROR";
export const SHIFT_LIST_LOADING = "SHIFT_LIST_LOADING";
export const SHIFT_STATUS_CHANGE_SUCCESSFULL =
  "SHIFT_STATUS_CHANGE_SUCCESSFULL";
export const SHIFT_STATUS_CHANGE_ERROR = "SHIFT_STATUS_CHANGE_ERROR";
export const SHIFT_STATUS_CHANGE_LOADING = "SHIFT_STATUS_CHANGE_LOADING";
export const VIHICLE_LIST_SUCCESSFULL = "VIHICLE_LIST_SUCCESSFULL";
export const VIHICLE_LIST_ERROR = "VIHICLE_LIST_ERROR";
export const VIHICLE_LIST_LOADING = "VIHICLE_LIST_LOADING";
export const CUSTOMER_GENERATE_INVOICE_SUCCESSFULL =
  "CUSTOMER_GENERATE_INVOICE_SUCCESSFULL";
export const CUSTOMER_GENERATE_INVOICE_ERROR =
  "CUSTOMER_GENERATE_INVOICE_ERROR";
export const CUSTOMER_GENERATE_INVOICE_LOADING =
  "CUSTOMER_GENERATE_INVOICE_LOADING";
export const CUSTOMER_LATEST_INVOICE_NO_SUCCESSFULL =
  "CUSTOMER_LATEST_INVOICE_NO_SUCCESSFULL";
export const CUSTOMER_LATEST_INVOICE_NO_ERROR =
  "CUSTOMER_LATEST_INVOICE_NO_ERROR";
export const CUSTOMER_LATEST_INVOICE_NO_LOADING =
  "CUSTOMER_LATEST_INVOICE_NO_LOADING";
export const UPLOAD_CUSTOMER_INVOICE_LOADING =
  "UPLOAD_CUSTOMER_INVOICE_LOADING";
export const UPLOAD_CUSTOMER_INVOICE_SUCCESSFULL =
  "UPLOAD_CUSTOMER_INVOICE_SUCCESSFULL";
export const UPLOAD_CUSTOMER_INVOICE_ERROR = "UPLOAD_CUSTOMER_INVOICE_ERROR";
export const CUSTOMER_INVOICE_ADDED_LOADING = "CUSTOMER_INVOICE_ADDED_LOADING";
export const CUSTOMER_INVOICE_ADDED_SUCCESSFULL =
  "CUSTOMER_INVOICE_ADDED_SUCCESSFULL";
export const CUSTOMER_INVOICE_ADDED_ERROR = "CUSTOMER_INVOICE_ADDED_ERROR";
export const CUSTOMER_INVOICE_LIST_LOADING = "CUSTOMER_INVOICE_LIST_LOADING";
export const CUSTOMER_INVOICE_LIST_SUCCESSFULL =
  "CUSTOMER_INVOICE_LIST_SUCCESSFULL";
export const CUSTOMER_INVOICE_LIST_ERROR = "CUSTOMER_INVOICE_LIST_ERROR";
export const DELETE_CUSTOMER_INVOICE_LOADING =
  "DELETE_CUSTOMER_INVOICE_LOADING";
export const DELETE_CUSTOMER_INVOICE_SUCCESSFULL =
  "DELETE_CUSTOMER_INVOICE_SUCCESSFULL";
export const DELETE_CUSTOMER_INVOICE_ERROR = "DELETE_CUSTOMER_INVOICE_ERROR";
export const VIHICLE_STATUS_CHANGE_SUCCESSFULL =
  "VIHICLE_STATUS_CHANGE_SUCCESSFULL";
export const VIHICLE_STATUS_CHANGE_ERROR = "VIHICLE_STATUS_CHANGE_ERROR";
export const VIHICLE_STATUS_CHANGE_LOADING = "VIHICLE_STATUS_CHANGE_LOADING";
export const VIHICLE_FILE_UPLOAD_SUCCESSFULL =
  "VIHICLE_FILE_UPLOAD_SUCCESSFULL";
export const VIHICLE_FILE_UPLOAD_ERROR = "VIHICLE_FILE_UPLOAD_ERROR";
export const VIHICLE_FILE_UPLOAD_LOADING = "VIHICLE_FILE_UPLOAD_LOADING";
export const VIHICLE_ADD_SUCCESSFULL = "VIHICLE_ADD_SUCCESSFULL";
export const VIHICLE_ADD_ERROR = "VIHICLE_ADD_ERROR";
export const VIHICLE_ADD_LOADING = "VIHICLE_ADD_LOADING";
export const VEHICLE_SCAN_MANDATORY = "VEHICLE_SCAN_MANDATORY";
export const VEHICLE_SCAN_MANDATORY_STATUS_CHANGE =
  "VEHICLE_SCAN_MANDATORY_STATUS_CHANGE";
export const VEHICLE_SCAN_MANDATORY_CURRENT_STATUS =
  "VEHICLE_SCAN_MANDATORY_CURRENT_STATUS";
export const VEHICLE_SCAN_MANDATORY_CURRENT_STATUS_GET_SUCCESS =
  "VEHICLE_SCAN_MANDATORY_CURRENT_STATUS_GET_SUCCESS";
export const VEHICLE_SCAN_MANDATORY_STATUS_GET_ERROR =
  "VEHICLE_SCAN_MANDATORY_STATUS_GET_ERROR";
export const VEHICLE_SCAN_MANDATORY_ERROR = "VEHICLE_SCAN_MANDATORY_ERROR";

export const ADD_EXPENSE_FORM_STATUS = "ADD_EXPENSE_FORM_STATUS";
export const ADD_EXPENSE_FORM_SUCCESS = "ADD_EXPENSE_FORM_SUCCESS";
export const ADD_EXPENSE_FORM_ERROR = "ADD_EXPENSE_FORM_ERROR";

export const GET_ALL_EXPENSE_FORM_STATUS = "GET_ALL_EXPENSE_FORM_STATUS";
export const GET_ALL_EXPENSE_FORM_SUCCESS = "GET_ALL_EXPENSE_FORM_SUCCESS";
export const GET_ALL_EXPENSE_FORM_ERROR = "GET_ALL_EXPENSE_FORM_ERROR";

export const EDIT_EXPENSE_ISSUE_STATUS = "EDIT_EXPENSE_ISSUE_STATUS";
export const EDIT_EXPENSE_ISSUE_SUCCESS = "EDIT_EXPENSE_ISSUE_SUCCESS";
export const EDIT_EXPENSE_ISSUE_ERROR = "EDIT_EXPENSE_ISSUE_ERROR";

export const ADD_FLEET_UTILIZED_STATUS = "ADD_FLEET_UTILIZED_STATUS";
export const ADD_FLEET_UTILIZED_SUCCESS = "ADD_FLEET_UTILIZED_SUCCESS";
export const ADD_FLEET_UTILIZED_ERROR = "ADD_FLEET_UTILIZED_ERROR";

export const GET_FLEET_UTILIZED_STATUS = "GET_FLEET_UTILIZED_STATUS";
export const GET_FLEET_UTILIZED_SUCCESS = "GET_FLEET_UTILIZED_SUCCESS";
export const GET_FLEET_UTILIZED_ERROR = "GET_FLEET_UTILIZED_ERROR";

export const EDIT_FLEET_UTILIZED_STATUS = "EDIT_FLEET_UTILIZED_STATUS";
export const EDIT_FLEET_UTILIZED_SUCCESS = "EDIT_FLEET_UTILIZED_SUCCESS";
export const EDIT_FLEET_UTILIZED_ERROR = "EDIT_FLEET_UTILIZED_ERROR";

export const GET_ONE_FLEET_UTILIZED_STATUS = "GET_ONE_FLEET_UTILIZED_STATUS";
export const GET_ONE_FLEET_UTILIZED_SUCCESS = "GET_ONE_FLEET_UTILIZED_SUCCESS";
export const GET_ONE_FLEET_UTILIZED_ERROR = "GET_ONE_FLEET_UTILIZED_ERROR";

export const GET_VEHICLE_STATUS_SUMMARY_STATUS =
  "GET_VEHICLE_STATUS_SUMMARY_STATUS";
export const GET_VEHICLE_STATUS_SUMMARY_SUCCESS =
  "GET_VEHICLE_STATUS_SUMMARY_SUCCESS";
export const GET_VEHICLE_STATUS_SUMMARY_ERROR =
  "GET_VEHICLE_STATUS_SUMMARY_ERROR";

export const VIHICLE_DELETE_FILE_SUCCESSFULL =
  "VIHICLE_DELETE_FILE_SUCCESSFULL";
export const VIHICLE_DELETE_FILE_ERROR = "VIHICLE_DELETE_FILE_ERROR";
export const VIHICLE_DELETE_FILE_LOADING = "VIHICLE_DELETE_FILE_LOADING";
export const VIHICLE_DETAILS_SUCCESSFULL = "VIHICLE_DETAILS_SUCCESSFULL";
export const VIHICLE_DETAILS_ERROR = "VIHICLE_DETAILS_ERROR";
export const VIHICLE_DETAILS_LOADING = "VIHICLE_DETAILS_LOADING";
export const VIHICLE_EDIT_SUCCESSFULL = "VIHICLE_EDIT_SUCCESSFULL";
export const VIHICLE_EDIT_ERROR = "VIHICLE_EDIT_ERROR";
export const VIHICLE_EDIT_LOADING = "VIHICLE_EDIT_LOADING";
export const VIHICLE_LEASE_COMPANY_LIST_SELECT_SUCCESSFULL =
  "VIHICLE_LEASE_COMPANY_LIST_SELECT_SUCCESSFULL";
export const VIHICLE_LEASE_COMPANY_LIST_SELECT_ERROR =
  "VIHICLE_LEASE_COMPANY_LIST_SELECT_ERROR";
export const VIHICLE_LEASE_COMPANY_LIST_SELECT_LOADING =
  "VIHICLE_LEASE_COMPANY_LIST_SELECT_LOADING";
export const VIHICLE_TELEMATICS_UNIT_LIST_SELECT_SUCCESSFULL =
  "VIHICLE_TELEMATICS_UNIT_LIST_SELECT_SUCCESSFULL";
export const VIHICLE_TELEMATICS_UNIT_LIST_SELECT_ERROR =
  "VIHICLE_TELEMATICS_UNIT_LIST_SELECT_ERROR";
export const VIHICLE_TELEMATICS_UNIT_LIST_SELECT_LOADING =
  "VIHICLE_TELEMATICS_UNIT_LIST_SELECT_LOADING";
export const VIHICLE_HUB_LIST_SELECT_SUCCESSFULL =
  "VIHICLE_HUB_LIST_SELECT_SUCCESSFULL";
export const VIHICLE_HUB_LIST_SELECT_ERROR = "VIHICLE_HUB_LIST_SELECT_ERROR";
export const VIHICLE_HUB_LIST_SELECT_LOADING =
  "VIHICLE_HUB_LIST_SELECT_LOADING";
export const ADD_VEHICLE_LEASE_COMPANY_LOADING =
  "ADD_VEHICLE_LEASE_COMPANY_LOADING";
export const ADD_VEHICLE_LEASE_COMPANY_SUCCESSFULL =
  "ADD_VEHICLE_LEASE_COMPANY_SUCCESSFULL";
export const ADD_VEHICLE_LEASE_COMPANY_ERROR =
  "ADD_VEHICLE_LEASE_COMPANY_ERROR";
export const FILE_UPLOAD_VEHICLE_LEASE_COMPANY_LOADING =
  "FILE_UPLOAD_VEHICLE_LEASE_COMPANY_LOADING";
export const FILE_UPLOAD_VEHICLE_LEASE_COMPANY_SUCCESSFULL =
  "FILE_UPLOAD_VEHICLE_LEASE_COMPANY_SUCCESSFULL";
export const FILE_UPLOAD_VEHICLE_LEASE_COMPANY_ERROR =
  "FILE_UPLOAD_VEHICLE_LEASE_COMPANY_ERROR";
export const VEHICLE_LEASE_COMPANY_LIST_LOADING =
  "VEHICLE_LEASE_COMPANY_LIST_LOADING";
export const VEHICLE_LEASE_COMPANY_LIST_SUCCESSFULL =
  "VEHICLE_LEASE_COMPANY_LIST_SUCCESSFULL";
export const VEHICLE_LEASE_COMPANY_LIST_ERROR =
  "VEHICLE_LEASE_COMPANY_LIST_ERROR";
export const VEHICLE_LEASE_COMPANY_STATUS_CHANGE_SUCCESSFULL =
  "VEHICLE_LEASE_COMPANY_STATUS_CHANGE_SUCCESSFULL";
export const VEHICLE_LEASE_COMPANY_STATUS_CHANGE_ERROR =
  "VEHICLE_LEASE_COMPANY_STATUS_CHANGE_ERROR";
export const VEHICLE_LEASE_COMPANY_STATUS_CHANGE_LOADING =
  "VEHICLE_LEASE_COMPANY_STATUS_CHANGE_LOADING";
export const VEHICLE_LEASE_COMPANY_DETAILS_SUCCESSFULL =
  "VEHICLE_LEASE_COMPANY_DETAILS_SUCCESSFULL";
export const VEHICLE_LEASE_COMPANY_DETAILS_ERROR =
  "VEHICLE_LEASE_COMPANY_DETAILS_ERROR";
export const VEHICLE_LEASE_COMPANY_DETAILS_LOADING =
  "VEHICLE_LEASE_COMPANY_DETAILS_LOADING";
export const VEHICLE_LEASE_COMPANY_EDIT_SUCCESSFULL =
  "VEHICLE_LEASE_COMPANY_EDIT_SUCCESSFULL";
export const VEHICLE_LEASE_COMPANY_EDIT_ERROR =
  "VEHICLE_LEASE_COMPANY_EDIT_ERROR";
export const VEHICLE_LEASE_COMPANY_EDIT_LOADING =
  "VEHICLE_LEASE_COMPANY_EDIT_LOADING";
export const VEHICLE_TELEMATICS_UNIT_LIST_LOADING =
  "VEHICLE_TELEMATICS_UNIT_LIST_LOADING";
export const VEHICLE_TELEMATICS_UNIT_LIST_SUCCESSFULL =
  "VEHICLE_TELEMATICS_UNIT_LIST_SUCCESSFULL";
export const VEHICLE_TELEMATICS_UNIT_LIST_ERROR =
  "VEHICLE_TELEMATICS_UNIT_LIST_ERROR";
export const ADD_VEHICLE_TELEMATICS_UNIT_SUCCESSFULL =
  "ADD_VEHICLE_TELEMATICS_UNIT_SUCCESSFULL";
export const ADD_VEHICLE_TELEMATICS_UNIT_ERROR =
  "ADD_VEHICLE_TELEMATICS_UNIT_ERROR";
export const ADD_VEHICLE_TELEMATICS_UNIT_LOADING =
  "ADD_VEHICLE_TELEMATICS_UNIT_LOADING";
export const VEHICLE_TELEMATICS_UNIT_STATUS_CHANGE_SUCCESSFULL =
  "VEHICLE_TELEMATICS_UNIT_STATUS_CHANGE_SUCCESSFULL";
export const VEHICLE_TELEMATICS_UNIT_STATUS_CHANGE_ERROR =
  "VEHICLE_TELEMATICS_UNIT_STATUS_CHANGE_ERROR";
export const VEHICLE_TELEMATICS_UNIT_STATUS_CHANGE_LOADING =
  "VEHICLE_TELEMATICS_UNIT_STATUS_CHANGE_LOADING";
export const VEHICLE_TELEMATICS_UNIT_DETAILS_SUCCESSFULL =
  "VEHICLE_TELEMATICS_UNIT_DETAILS_SUCCESSFULL";
export const VEHICLE_TELEMATICS_UNIT_DETAILS_ERROR =
  "VEHICLE_TELEMATICS_UNIT_DETAILS_ERROR";
export const VEHICLE_TELEMATICS_UNIT_DETAILS_LOADING =
  "VEHICLE_TELEMATICS_UNIT_DETAILS_LOADING";
export const VEHICLE_TELEMATICS_UNIT_EDIT_SUCCESSFULL =
  "VEHICLE_TELEMATICS_UNIT_EDIT_SUCCESSFULL";
export const VEHICLE_TELEMATICS_UNIT_EDIT_ERROR =
  "VEHICLE_TELEMATICS_UNIT_EDIT_ERROR";
export const VEHICLE_TELEMATICS_UNIT_EDIT_LOADING =
  "VEHICLE_TELEMATICS_UNIT_EDIT_LOADING";
export const VEHICLE_HUB_LIST_LOADING = "VEHICLE_HUB_LIST_LOADING";
export const VEHICLE_HUB_LIST_SUCCESSFULL = "VEHICLE_HUB_LIST_SUCCESSFULL";
export const VEHICLE_HUB_LIST_ERROR = "VEHICLE_HUB_LIST_ERROR";
export const ADD_VEHICLE_HUB_SUCCESSFULL = "ADD_VEHICLE_HUB_SUCCESSFULL";
export const ADD_VEHICLE_HUB_ERROR = "ADD_VEHICLE_HUB_ERROR";
export const ADD_VEHICLE_HUB_LOADING = "ADD_VEHICLE_HUB_LOADING";
export const VEHICLE_HUB_STATUS_CHANGE_SUCCESSFULL =
  "VEHICLE_HUB_STATUS_CHANGE_SUCCESSFULL";
export const VEHICLE_HUB_STATUS_CHANGE_ERROR =
  "VEHICLE_HUB_STATUS_CHANGE_ERROR";
export const VEHICLE_HUB_STATUS_CHANGE_LOADING =
  "VEHICLE_HUB_STATUS_CHANGE_LOADING";
export const VEHICLE_HUB_DETAILS_SUCCESSFULL =
  "VEHICLE_HUB_DETAILS_SUCCESSFULL";
export const VEHICLE_HUB_DETAILS_ERROR = "VEHICLE_HUB_DETAILS_ERROR";
export const VEHICLE_HUB_DETAILS_LOADING = "VEHICLE_HUB_DETAILS_LOADING";
export const VEHICLE_HUB_EDIT_SUCCESSFULL = "VEHICLE_HUB_EDIT_SUCCESSFULL";
export const VEHICLE_HUB_EDIT_ERROR = "VEHICLE_HUB_EDIT_ERROR";
export const VEHICLE_HUB_EDIT_LOADING = "VEHICLE_HUB_EDIT_LOADING";
export const VEHICLE_HUB_MANAGER_LIST_SUCCESSFULL =
  "VEHICLE_HUB_MANAGER_LIST_SUCCESSFULL";
export const VEHICLE_HUB_MANAGER_LIST_ERROR = "VEHICLE_HUB_MANAGER_LIST_ERROR";
export const VEHICLE_HUB_MANAGER_LIST_LOADING =
  "VEHICLE_HUB_MANAGER_LIST_LOADING";
export const ADMIN_LIST_LOADING = "ADMIN_LIST_LOADING";
export const ADMIN_LIST_SUCCESSFULL = "ADMIN_LIST_SUCCESSFULL";
export const ADMIN_LIST_ERROR = "ADMIN_LIST_ERROR";
export const ADMIN_ADD_LOADING = "ADMIN_ADD_LOADING";
export const ADMIN_ADD_SUCCESSFULL = "ADMIN_ADD_SUCCESSFULL";
export const ADMIN_ADD_ERROR = "ADMIN_ADD_ERROR";
export const ADMIN_DETAILS_LOADING = "ADMIN_DETAILS_LOADING";
export const ADMIN_DETAILS_SUCCESSFULL = "ADMIN_DETAILS_SUCCESSFULL";
export const ADMIN_DETAILS_ERROR = "ADMIN_DETAILS_ERROR";
export const ADMIN_EDIT_LOADING = "ADMIN_EDIT_LOADING";
export const ADMIN_EDIT_SUCCESSFULL = "ADMIN_EDIT_SUCCESSFULL";
export const ADMIN_EDIT_ERROR = "ADMIN_EDIT_ERROR";
export const DRIVER_LIST_SUCCESSFULL = "DRIVER_LIST_SUCCESSFULL";
export const DRIVER_LIST_ERROR = "DRIVER_LIST_ERROR";
export const DRIVER_LIST_LOADING = "DRIVER_LIST_LOADING";
export const DRIVER_STATUS_CHANGE_SUCCESSFULL =
  "DRIVER_STATUS_CHANGE_SUCCESSFULL";
export const DRIVER_STATUS_CHANGE_ERROR = "DRIVER_STATUS_CHANGE_ERROR";
export const DRIVER_STATUS_CHANGE_LOADING = "DRIVER_STATUS_CHANGE_LOADING";
export const DRIVER_FILE_UPLOAD_SUCCESSFULL = "DRIVER_FILE_UPLOAD_SUCCESSFULL";
export const DRIVER_FILE_UPLOAD_ERROR = "DRIVER_FILE_UPLOAD_ERROR";
export const DRIVER_FILE_UPLOAD_LOADING = "DRIVER_FILE_UPLOAD_LOADING";
export const DRIVER_DELETE_FILE_SUCCESSFULL = "DRIVER_DELETE_FILE_SUCCESSFULL";
export const DRIVER_DELETE_FILE_ERROR = "DRIVER_DELETE_FILE_ERROR";
export const DRIVER_DELETE_FILE_LOADING = "DRIVER_DELETE_FILE_LOADING";
export const DRIVER_ADD_SUCCESSFULL = "DRIVER_ADD_SUCCESSFULL";
export const DRIVER_ADD_ERROR = "DRIVER_ADD_ERROR";
export const DRIVER_ADD_LOADING = "DRIVER_ADD_LOADING";
export const DRIVER_DETACHED_VEHICLE_SUCCESSFULL =
  "DRIVER_DETACHED_VEHICLE_SUCCESSFULL";
export const DRIVER_DETACHED_VEHICLE_ERROR = "DRIVER_DETACHED_VEHICLE_ERROR";
export const DRIVER_DETACHED_VEHICLE_LOADING =
  "DRIVER_DETACHED_VEHICLE_LOADING";
export const DRIVER_LOGOFF_SUCCESSFULL = "DRIVER_LOGOFF_SUCCESSFULL";
export const DRIVER_LOGOFF_ERROR = "DRIVER_LOGOFF_ERROR";
export const DRIVER_LOGOFF_LOADING = "DRIVER_LOGOFF_LOADING";
export const DRIVER_SELFIE_REQUEST_SUCCESSFULL =
  "DRIVER_SELFIE_REQUEST_SUCCESSFULL";
export const DRIVER_SELFIE_REQUEST_ERROR = "DRIVER_SELFIE_REQUEST_ERROR";
export const DRIVER_SELFIE_REQUEST_LOADING = "DRIVER_SELFIE_REQUEST_LOADING";
export const DRIVER_ASSIGN_CUSTOMER_SUCCESSFULL =
  "DRIVER_ASSIGN_CUSTOMER_SUCCESSFULL";
export const DRIVER_ASSIGN_CUSTOMER_ERROR = "DRIVER_ASSIGN_CUSTOMER_ERROR";
export const DRIVER_ASSIGN_CUSTOMER_LOADING = "DRIVER_ASSIGN_CUSTOMER_LOADING";
export const DRIVER_DETAILS_SUCCESSFULL = "DRIVER_DETAILS_SUCCESSFULL";
export const DRIVER_DETAILS_ERROR = "DRIVER_DETAILS_ERROR";
export const DRIVER_DETAILS_LOADING = "DRIVER_DETAILS_LOADING";
export const DRIVER_EDIT_SUCCESSFULL = "DRIVER_EDIT_SUCCESSFULL";
export const DRIVER_EDIT_ERROR = "DRIVER_EDIT_ERROR";
export const DRIVER_EDIT_LOADING = "DRIVER_EDIT_LOADING";
export const UNVERIFIED_DRIVER_LIST_SUCCESSFULL =
  "UNVERIFIED_DRIVER_LIST_SUCCESSFULL";
export const UNVERIFIED_DRIVER_LIST_ERROR = "UNVERIFIED_DRIVER_LIST_ERROR";
export const UNVERIFIED_DRIVER_LIST_LOADING = "UNVERIFIED_DRIVER_LIST_LOADING";
export const DRIVER_VERIFY_SUCCESSFULL = "DRIVER_VERIFY_SUCCESSFULL";
export const DRIVER_VERIFY_ERROR = "DRIVER_VERIFY_ERROR";
export const DRIVER_VERIFY_LOADING = "DRIVER_VERIFY_LOADING";
export const DRIVER_DELETE_SUCCESSFULL = "DRIVER_DELETE_SUCCESSFULL";
export const DRIVER_DELETE_ERROR = "DRIVER_DELETE_ERROR";
export const DRIVER_DELETE_LOADING = "DRIVER_DELETE_LOADING";
export const DRIVER_WORK_LIST_SUCCESSFULL = "DRIVER_WORK_LIST_SUCCESSFULL";
export const DRIVER_WORK_LIST_ERROR = "DRIVER_WORK_LIST_ERROR";
export const DRIVER_WORK_LIST_LOADING = "DRIVER_WORK_LIST_LOADING";
export const DRIVER_ATTENDANCE_LIST_SUCCESSFULL =
  "DRIVER_ATTENDANCE_LIST_SUCCESSFULL";
export const DRIVER_ATTENDANCE_LIST_ERROR = "DRIVER_ATTENDANCE_LIST_ERROR";
export const DRIVER_ATTENDANCE_LIST_LOADING = "DRIVER_ATTENDANCE_LIST_LOADING";
export const DRIVER_MULTIPLE_ASSIGN_LIST_SUCCESSFULL =
  "DRIVER_MULTIPLE_ASSIGN_LIST_SUCCESSFULL";
export const DRIVER_MULTIPLE_ASSIGN_LIST_ERROR =
  "DRIVER_MULTIPLE_ASSIGN_LIST_ERROR";
export const DRIVER_MULTIPLE_ASSIGN_LIST_LOADING =
  "DRIVER_MULTIPLE_ASSIGN_LIST_LOADING";
export const DRIVER_MULTIPLE_ASSIGN_DELETE_SUCCESSFULL =
  "DRIVER_MULTIPLE_ASSIGN_DELETE_SUCCESSFULL";
export const DRIVER_MULTIPLE_ASSIGN_DELETE_ERROR =
  "DRIVER_MULTIPLE_ASSIGN_DELETE_ERROR";
export const DRIVER_MULTIPLE_ASSIGN_DELETE_LOADING =
  "DRIVER_MULTIPLE_ASSIGN_DELETE_LOADING";
export const DRIVER_ASSIGN_SINGLE_SUCCESSFULL =
  "DRIVER_ASSIGN_SINGLE_SUCCESSFULL";
export const DRIVER_ASSIGN_SINGLE_ERROR = "DRIVER_ASSIGN_SINGLE_ERROR";
export const DRIVER_ASSIGN_SINGLE_LOADING = "DRIVER_ASSIGN_SINGLE_LOADING";
export const ALL_DRIVER_WORK_LIST_SUCCESSFULL =
  "ALL_DRIVER_WORK_LIST_SUCCESSFULL";
export const ALL_DRIVER_WORK_LIST_ERROR = "ALL_DRIVER_WORK_LIST_ERROR";
export const ALL_DRIVER_WORK_LIST_LOADING = "ALL_DRIVER_WORK_LIST_LOADING";
export const ALL_UNDELIVERED_REASON_LIST_SUCCESSFULL =
  "ALL_UNDELIVERED_REASON_LIST_SUCCESSFULL";
export const ALL_UNDELIVERED_REASON_LIST_ERROR =
  "ALL_UNDELIVERED_REASON_LIST_ERROR";
export const ALL_UNDELIVERED_REASON_LIST_LOADING =
  "ALL_UNDELIVERED_REASON_LIST_LOADING";
export const DRIVER_WORK_DETAILS_SUCCESSFULL =
  "DRIVER_WORK_DETAILS_SUCCESSFULL";
export const DRIVER_WORK_DETAILS_ERROR = "DRIVER_WORK_DETAILS_ERROR";
export const DRIVER_WORK_DETAILS_LOADING = "DRIVER_WORK_DETAILS_LOADING";
export const DRIVER_MULTIPLE_FILE_UPLOAD_SUCCESSFULL =
  "DRIVER_MULTIPLE_FILE_UPLOAD_SUCCESSFULL";
export const DRIVER_MULTIPLE_FILE_UPLOAD_ERROR =
  "DRIVER_MULTIPLE_FILE_UPLOAD_ERROR";
export const DRIVER_MULTIPLE_FILE_UPLOAD_LOADING =
  "DRIVER_MULTIPLE_FILE_UPLOAD_LOADING";
export const DRIVER_EDIT_WORK_SUCCESSFULL = "DRIVER_EDIT_WORK_SUCCESSFULL";
export const DRIVER_EDIT_WORK_ERROR = "DRIVER_EDIT_WORK_ERROR";
export const DRIVER_EDIT_WORK_LOADING = "DRIVER_EDIT_WORK_LOADING";
export const GET_DRIVER_LOCATION_SUCCESSFULL =
  "GET_DRIVER_LOCATION_SUCCESSFULL";
export const GET_DRIVER_LOCATION_ERROR = "GET_DRIVER_LOCATION_ERROR";
export const GET_DRIVER_LOCATION_LOADING = "GET_DRIVER_LOCATION_LOADING";
export const GET_DRIVER_LOGIN_REQUEST_LIST_SUCCESSFULL =
  "GET_DRIVER_LOGIN_REQUEST_LIST_SUCCESSFULL";
export const GET_DRIVER_LOGIN_REQUEST_LIST_ERROR =
  "GET_DRIVER_LOGIN_REQUEST_LIST_ERROR";
export const GET_DRIVER_LOGIN_REQUEST_LIST_LOADING =
  "GET_DRIVER_LOGIN_REQUEST_LIST_LOADING";
export const APPROVE_DRIVER_LOGIN_REQUEST_SUCCESSFULL =
  "APPROVE_DRIVER_LOGIN_REQUEST_SUCCESSFULL";
export const APPROVE_DRIVER_LOGIN_REQUEST_ERROR =
  "APPROVE_DRIVER_LOGIN_REQUEST_ERROR";
export const APPROVE_DRIVER_LOGIN_REQUEST_LOADING =
  "APPROVE_DRIVER_LOGIN_REQUEST_LOADING";
export const DELETE_DRIVER_LOGIN_REQUEST_SUCCESSFULL =
  "DELETE_DRIVER_LOGIN_REQUEST_SUCCESSFULL";
export const DELETE_DRIVER_LOGIN_REQUEST_ERROR =
  "DELETE_DRIVER_LOGIN_REQUEST_ERROR";
export const DELETE_DRIVER_LOGIN_REQUEST_LOADING =
  "DELETE_DRIVER_LOGIN_REQUEST_LOADING";
export const HUB_VIHICLE_LIST_SUCCESSFULL = "HUB_VIHICLE_LIST_SUCCESSFULL";
export const HUB_VIHICLE_LIST_ERROR = "HUB_VIHICLE_LIST_ERROR";
export const HUB_VIHICLE_LIST_LOADING = "HUB_VIHICLE_LIST_LOADING";
export const HUB_VIHICLE_STATUS_CHANGE_SUCCESSFULL =
  "HUB_VIHICLE_STATUS_CHANGE_SUCCESSFULL";
export const HUB_VIHICLE_STATUS_CHANGE_ERROR =
  "HUB_VIHICLE_STATUS_CHANGE_ERROR";
export const HUB_VIHICLE_STATUS_CHANGE_LOADING =
  "HUB_VIHICLE_STATUS_CHANGE_LOADING";
export const HUB_VIHICLE_PARKED_CHANGE_SUCCESSFULL =
  "HUB_VIHICLE_PARKED_CHANGE_SUCCESSFULL";
export const HUB_VIHICLE_PARKED_CHANGE_ERROR =
  "HUB_VIHICLE_PARKED_CHANGE_ERROR";
export const HUB_VIHICLE_PARKED_CHANGE_LOADING =
  "HUB_VIHICLE_PARKED_CHANGE_LOADING";
export const HUB_VEHICLE_TECHNICIAN_LIST_SUCCESSFULL =
  "HUB_VEHICLE_TECHNICIAN_LIST_SUCCESSFULL";
export const HUB_VEHICLE_TECHNICIAN_LIST_ERROR =
  "HUB_VEHICLE_TECHNICIAN_LIST_ERROR";
export const HUB_VEHICLE_TECHNICIAN_LIST_LOADING =
  "HUB_VEHICLE_TECHNICIAN_LIST_LOADING";
export const HUB_VEHICLE_DASHBOARD_DETAILS_SUCCESSFULL =
  "HUB_VEHICLE_DASHBOARD_DETAILS_SUCCESSFULL";
export const HUB_VEHICLE_DASHBOARD_DETAILS_ERROR =
  "HUB_VEHICLE_DASHBOARD_DETAILS_ERROR";
export const HUB_VEHICLE_DASHBOARD_DETAILS_LOADING =
  "HUB_VEHICLE_DASHBOARD_DETAILS_LOADING";
export const TECHNICIAN_VEHICLE_DASHBOARD_DETAILS_SUCCESSFULL =
  "TECHNICIAN_VEHICLE_DASHBOARD_DETAILS_SUCCESSFULL";
export const TECHNICIAN_VEHICLE_DASHBOARD_DETAILS_ERROR =
  "TECHNICIAN_VEHICLE_DASHBOARD_DETAILS_ERROR";
export const TECHNICIAN_VEHICLE_DASHBOARD_DETAILS_LOADING =
  "TECHNICIAN_VEHICLE_DASHBOARD_DETAILS_LOADING";
export const TECHNICIAN_VEHICLE_LIST_SUCCESSFULL =
  "TECHNICIAN_VEHICLE_LIST_SUCCESSFULL";
export const TECHNICIAN_VEHICLE_LIST_ERROR = "TECHNICIAN_VEHICLE_LIST_ERROR";
export const TECHNICIAN_VEHICLE_LIST_LOADING =
  "TECHNICIAN_VEHICLE_LIST_LOADING";
export const TECHNICIAN_VEHICLE_REPAIRED_CHANGE_SUCCESSFULL =
  "TECHNICIAN_VEHICLE_REPAIRED_CHANGE_SUCCESSFULL";
export const TECHNICIAN_VEHICLE_REPAIRED_CHANGE_ERROR =
  "TECHNICIAN_VEHICLE_REPAIRED_CHANGE_ERROR";
export const TECHNICIAN_VEHICLE_REPAIRED_CHANGE_LOADING =
  "TECHNICIAN_VEHICLE_REPAIRED_CHANGE_LOADING";
export const HUB_VIHICLE_UPDATE_DEACTIVATE_REASON_SUCCESSFULL =
  "HUB_VIHICLE_UPDATE_DEACTIVATE_REASON_SUCCESSFULL";
export const HUB_VIHICLE_UPDATE_DEACTIVATE_REASON_ERROR =
  "HUB_VIHICLE_UPDATE_DEACTIVATE_REASON_ERROR";
export const HUB_VIHICLE_UPDATE_DEACTIVATE_REASON_LOADING =
  "HUB_VIHICLE_UPDATE_DEACTIVATE_REASON_LOADING";
export const HUB_VIHICLE_UPDATE_TECHNICIAN_SUCCESSFULL =
  "HUB_VIHICLE_UPDATE_TECHNICIAN_SUCCESSFULL";
export const HUB_VIHICLE_UPDATE_TECHNICIAN_ERROR =
  "HUB_VIHICLE_UPDATE_TECHNICIAN_ERROR";
export const HUB_VIHICLE_UPDATE_TECHNICIAN_LOADING =
  "HUB_VIHICLE_UPDATE_TECHNICIAN_LOADING";
export const WATCHMAN_VEHICLE_DASHBOARD_DETAILS_SUCCESSFULL =
  "WATCHMAN_VEHICLE_DASHBOARD_DETAILS_SUCCESSFULL";
export const WATCHMAN_VEHICLE_DASHBOARD_DETAILS_ERROR =
  "WATCHMAN_VEHICLE_DASHBOARD_DETAILS_ERROR";
export const WATCHMAN_VEHICLE_DASHBOARD_DETAILS_LOADING =
  "WATCHMAN_VEHICLE_DASHBOARD_DETAILS_LOADING";
export const WATCHMAN_VEHICLE_STATUS_CHANGE_ONFIELD_SUCCESSFULL =
  "WATCHMAN_VEHICLE_STATUS_CHANGE_ONFIELD_SUCCESSFULL";
export const WATCHMAN_VEHICLE_STATUS_CHANGE_ONFIELD_ERROR =
  "WATCHMAN_VEHICLE_STATUS_CHANGE_ONFIELD_ERROR";
export const WATCHMAN_VEHICLE_STATUS_CHANGE_ONFIELD_LOADING =
  "WATCHMAN_VEHICLE_STATUS_CHANGE_ONFIELD_LOADING";
export const WATCHMAN_VEHICLE_STATUS_CHANGE_PARKED_SUCCESSFULL =
  "WATCHMAN_VEHICLE_STATUS_CHANGE_PARKED_SUCCESSFULL";
export const WATCHMAN_VEHICLE_STATUS_CHANGE_PARKED_ERROR =
  "WATCHMAN_VEHICLE_STATUS_CHANGE_PARKED_ERROR";
export const WATCHMAN_VEHICLE_STATUS_CHANGE_PARKED_LOADING =
  "WATCHMAN_VEHICLE_STATUS_CHANGE_PARKED_LOADING";
export const BIKE_TYPE_LIST_LOADING = "BIKE_TYPE_LIST_LOADING";
export const BIKE_TYPE_LIST_SUCCESSFULL = "BIKE_TYPE_LIST_SUCCESSFULL";
export const BIKE_TYPE_LIST_ERROR = "BIKE_TYPE_LIST_ERROR";
export const ADD_BIKE_TYPE_SUCCESSFULL = "ADD_BIKE_TYPE_SUCCESSFULL";
export const ADD_BIKE_TYPE_ERROR = "ADD_BIKE_TYPE_ERROR";
export const ADD_BIKE_TYPE_LOADING = "ADD_BIKE_TYPE_LOADING";
export const BIKE_TYPE_STATUS_CHANGE_SUCCESSFULL =
  "BIKE_TYPE_STATUS_CHANGE_SUCCESSFULL";
export const BIKE_TYPE_STATUS_CHANGE_ERROR = "BIKE_TYPE_STATUS_CHANGE_ERROR";
export const BIKE_TYPE_STATUS_CHANGE_LOADING =
  "BIKE_TYPE_STATUS_CHANGE_LOADING";
export const BIKE_TYPE_DETAILS_SUCCESSFULL = "BIKE_TYPE_DETAILS_SUCCESSFULL";
export const BIKE_TYPE_DETAILS_ERROR = "BIKE_TYPE_DETAILS_ERROR";
export const BIKE_TYPE_DETAILS_LOADING = "BIKE_TYPE_DETAILS_LOADING";
export const BIKE_TYPE_EDIT_SUCCESSFULL = "BIKE_TYPE_EDIT_SUCCESSFULL";
export const BIKE_TYPE_EDIT_ERROR = "BIKE_TYPE_EDIT_ERROR";
export const BIKE_TYPE_EDIT_LOADING = "BIKE_TYPE_EDIT_LOADING";
export const BIKE_TYPE_DELETE_SUCCESSFULL = "BIKE_TYPE_DELETE_SUCCESSFULL";
export const BIKE_TYPE_DELETE_ERROR = "BIKE_TYPE_DELETE_ERROR";
export const BIKE_TYPE_DELETE_LOADING = "BIKE_TYPE_DELETE_LOADING";
export const WATCHMAN_GET_VEHICLE_DETAILS_SUCCESSFULL =
  "WATCHMAN_GET_VEHICLE_DETAILS_SUCCESSFULL";
export const WATCHMAN_GET_VEHICLE_DETAILS_ERROR =
  "WATCHMAN_GET_VEHICLE_DETAILS_ERROR";
export const WATCHMAN_GET_VEHICLE_DETAILS_LOADING =
  "WATCHMAN_GET_VEHICLE_DETAILS_LOADING";
export const CITY_LIST_SUCCESSFULL = "CITY_LIST_SUCCESSFULL";
export const CITY_LIST_ERROR = "CITY_LIST_ERROR";
export const CITY_LIST_LOADING = "CITY_LIST_LOADING";
export const STATE_LIST_SUCCESSFULL = "STATE_LIST_SUCCESSFULL";
export const STATE_LIST_ERROR = "STATE_LIST_ERROR";
export const STATE_LIST_LOADING = "STATE_LIST_LOADING";
export const CITY_LIST_BY_STATE_SUCCESSFULL = "CITY_LIST_BY_STATE_SUCCESSFULL";
export const CITY_LIST_BY_STATE_ERROR = "CITY_LIST_BY_STATE_ERROR";
export const CITY_LIST_BY_STATE_LOADING = "CITY_LIST_BY_STATE_LOADING";
export const DRIVER_SET_DEFAULT_CUSTOMER_SUCCESSFULL =
  "DRIVER_SET_DEFAULT_CUSTOMER_SUCCESSFULL";
export const DRIVER_SET_DEFAULT_CUSTOMER_ERROR =
  "DRIVER_SET_DEFAULT_CUSTOMER_ERROR";
export const DRIVER_SET_DEFAULT_CUSTOMER_LOADING =
  "DRIVER_SET_DEFAULT_CUSTOMER_LOADING";

export const ADD_RIDERS_EARNING_SUCCESSFULL = "ADD_RIDERS_EARNING_SUCCESSFULL";
export const ADD_RIDERS_EARNING_ERROR = "ADD_RIDERS_EARNING_ERROR";
export const ADD_RIDERS_EARNING_LOADING = "ADD_RIDERS_EARNING_LOADING";

export const GET_RIDERS_EARNING_SUCCESSFULL = "GET_RIDERS_EARNING_SUCCESSFULL";
export const GET_RIDERS_EARNING_ERROR = "GET_RIDERS_EARNING_ERROR";
export const GET_RIDERS_EARNING_LOADING = "GET_RIDERS_EARNING_LOADING";

export const EDIT_RIDERS_EARNING_SUCCESSFULL =
  "EDIT_RIDERS_EARNING_SUCCESSFULL";
export const EDIT_RIDERS_EARNING_ERROR = "EDIT_RIDERS_EARNING_ERROR";
export const EDIT_RIDERS_EARNING_LOADING = "EDIT_RIDERS_EARNING_LOADING";

export const ADD_RIDERS_PARCEL_SUCCESSFULL = "ADD_RIDERS_PARCEL_SUCCESSFULL";
export const ADD_RIDERS_PARCEL_ERROR = "ADD_RIDERS_PARCEL_ERROR";
export const ADD_RIDERS_PARCEL_LOADING = "ADD_RIDERS_PARCEL_LOADING";

export const GET_RIDERS_PARCEL_SUCCESSFULL = "GET_RIDERS_PARCEL_SUCCESSFULL";
export const GET_RIDERS_PARCEL_ERROR = "GET_RIDERS_PARCEL_ERROR";
export const GET_RIDERS_PARCEL_LOADING = "GET_RIDERS_PARCEL_LOADING";

export const EDIT_RIDERS_PARCEL_SUCCESSFULL = "EDIT_RIDERS_PARCEL_SUCCESSFULL";
export const EDIT_RIDERS_PARCEL_ERROR = "EDIT_RIDERS_PARCEL_ERROR";
export const EDIT_RIDERS_PARCEL_LOADING = "EDIT_RIDERS_PARCEL_LOADING";

export const GET_ADMIN_PROFILE_SUCCESSFULL = "GET_ADMIN_PROFILE_SUCCESSFULL";
export const GET_ADMIN_PROFILE_ERROR = "GET_ADMIN_PROFILE_ERROR";
export const GET_ADMIN_PROFILE_LOADING = "GET_ADMIN_PROFILE_LOADING";
export const UPDATE_ADMIN_PROFILE_SUCCESSFULL =
  "UPDATE_ADMIN_PROFILE_SUCCESSFULL";
export const UPDATE_ADMIN_PROFILE_ERROR = "UPDATE_ADMIN_PROFILE_ERROR";
export const UPDATE_ADMIN_PROFILE_LOADING = "UPDATE_ADMIN_PROFILE_LOADING";
export const BIKE_TYPE_LIST_SELECT_LOADING = "BIKE_TYPE_LIST_SELECT_LOADING";
export const BIKE_TYPE_LIST_SELECT_SUCCESSFULL =
  "BIKE_TYPE_LIST_SELECT_SUCCESSFULL";
export const BIKE_TYPE_LIST_SELECT_ERROR = "BIKE_TYPE_LIST_SELECT_ERROR";
export const DRIVER_LIST_SELECT_LOADING = "DRIVER_LIST_SELECT_LOADING";
export const DRIVER_LIST_SELECT_SUCCESSFULL = "DRIVER_LIST_SELECT_SUCCESSFULL";
export const DRIVER_LIST_SELECT_ERROR = "DRIVER_LIST_SELECT_ERROR";
export const DRIVER_LIST_VEHICLE_USE_LOADING =
  "DRIVER_LIST_VEHICLE_USE_LOADING";
export const DRIVER_LIST_VEHICLE_USE_SUCCESSFULL =
  "DRIVER_LIST_VEHICLE_USE_SUCCESSFULL";
export const DRIVER_LIST_VEHICLE_USE_ERROR = "DRIVER_LIST_VEHICLE_USE_ERROR";
export const CITIES_LIST_LOADING = "CITIES_LIST_LOADING";
export const CITIES_LIST_SUCCESSFULL = "CITIES_LIST_SUCCESSFULL";
export const CITIES_LIST_ERROR = "CITIES_LIST_ERROR";

export const DASHBOARD_VEHICLE_DETAILS_LOADING =
  "DASHBOARD_VEHICLE_DETAILS_LOADING";
export const DASHBOARD_VEHICLE_DETAILS_SUCCESSFULL =
  "DASHBOARD_VEHICLE_DETAILS_SUCCESSFULL";
export const DASHBOARD_VEHICLE_DETAILS_ERROR =
  "DASHBOARD_VEHICLE_DETAILS_ERROR";

export const DASHBOARD_DRIVER_DETAILS_LOADING =
  "DASHBOARD_DRIVER_DETAILS_LOADING";
export const DASHBOARD_DRIVER_DETAILS_SUCCESSFULL =
  "DASHBOARD_DRIVER_DETAILS_SUCCESSFULL";
export const DASHBOARD_DRIVER_DETAILS_ERROR = "DASHBOARD_DRIVER_DETAILS_ERROR";
export const DRIVER_ISSUES_LIST_LOADING = "DRIVER_ISSUES_LIST_LOADING";
export const DRIVER_ISSUES_LIST_SUCCESSFULL = "DRIVER_ISSUES_LIST_SUCCESSFULL";
export const DRIVER_ISSUES_LIST_ERROR = "DRIVER_ISSUES_LIST_ERROR";
export const DRIVER_ISSUES_IMAGES_LOADING = "DRIVER_ISSUES_IMAGES_LOADING";
export const DRIVER_ISSUES_IMAGES_SUCCESSFULL =
  "DRIVER_ISSUES_IMAGES_SUCCESSFULL";
export const DRIVER_ISSUES_IMAGES_ERROR = "DRIVER_ISSUES_IMAGES_ERROR";
export const GET_ALL_ATTENDANCE_LOADING = "GET_ALL_ATTENDANCE__LOADING";
export const GET_ALL_ATTENDANCE_SUCCESSFULL = "GET_ALL_ATTENDANCE_SUCCESSFULL";
export const GET_ALL_ATTENDANCE_ERROR = "GET_ALL_ATTENDANCE_ERROR";
export const GET_ALL_DRIVER_ATTENDANCE_LOADING =
  "GET_ALL_DRIVER_ATTENDANCE_LOADING";
export const GET_ALL_DRIVER_ATTENDANCE_SUCCESSFULL =
  "GET_ALL_DRIVER_ATTENDANCE_SUCCESSFULL";
export const GET_ALL_DRIVER_ATTENDANCE_ERROR =
  "GET_ALL_DRIVER_ATTENDANCE_ERROR";
export const GET_ALL_DRIVER_ATTENDANCE_AND_PARCEL_LOADING =
  "GET_ALL_DRIVER_ATTENDANCE_AND_PARCEL_LOADING";
export const GET_ALL_DRIVER_ATTENDANCE_AND_PARCEL_SUCCESSFULL =
  "GET_ALL_DRIVER_ATTENDANCE_AND_PARCEL_SUCCESSFULL";
export const GET_ALL_DRIVER_ATTENDANCE_AND_PARCEL_ERROR =
  "GET_ALL_DRIVER_ATTENDANCE_AND_PARCEL_ERROR";
export const HUB_VIHICLE_UPDATE_HUB_SUCCESSFULL =
  "HUB_VIHICLE_UPDATE_HUB_SUCCESSFULL";
export const HUB_VIHICLE_UPDATE_HUB_ERROR = "HUB_VIHICLE_UPDATE_HUB_ERROR";
export const HUB_VIHICLE_UPDATE_HUB_LOADING = "HUB_VIHICLE_UPDATE_HUB_LOADING";
export const HUB_VIHICLE_UPDATE_CUSTOMER_SUCCESSFULL =
  "HUB_VIHICLE_UPDATE_CUSTOMER_SUCCESSFULL";
export const HUB_VIHICLE_UPDATE_CUSTOMER_ERROR =
  "HUB_VIHICLE_UPDATE_CUSTOMER_ERROR";
export const HUB_VIHICLE_UPDATE_CUSTOMER_LOADING =
  "HUB_VIHICLE_UPDATE_CUSTOMER_LOADING";
export const DRIVER_ISSUES_CATEGORY_LIST_SUCCESSFULL =
  "DRIVER_ISSUES_CATEGORY_LIST_SUCCESSFULL";
export const DRIVER_ISSUES_CATEGORY_LIST_ERROR =
  "DRIVER_ISSUES_CATEGORY_LIST_ERROR";
export const DRIVER_ISSUES_CATEGORY_LIST_LOADING =
  "DRIVER_ISSUES_CATEGORY_LIST_LOADING";
export const DRIVER_ADD_ISSUES_CATEGORY_SUCCESSFULL =
  "DRIVER_ADD_ISSUES_CATEGORY_SUCCESSFULL";
export const DRIVER_ADD_ISSUES_CATEGORY_ERROR =
  "DRIVER_ADD_ISSUES_CATEGORY_ERROR";
export const DRIVER_ADD_ISSUES_CATEGORY_LOADING =
  "DRIVER_ADD_ISSUES_CATEGORY_LOADING";
export const DRIVER_DELETE_ISSUES_CATEGORY_SUCCESSFULL =
  "DRIVER_DELETE_ISSUES_CATEGORY_SUCCESSFULL";
export const DRIVER_DELETE_ISSUES_CATEGORY_ERROR =
  "DRIVER_DELETE_ISSUES_CATEGORY_ERROR";
export const DRIVER_DELETE_ISSUES_CATEGORY_LOADING =
  "DRIVER_DELETE_ISSUES_CATEGORY_LOADING";
export const DRIVER_UPDATE_ISSUES_CATEGORY_SUCCESSFULL =
  "DRIVER_UPDATE_ISSUES_CATEGORY_SUCCESSFULL";
export const DRIVER_UPDATE_ISSUES_CATEGORY_ERROR =
  "DRIVER_UPDATE_ISSUES_CATEGORY_ERROR";
export const DRIVER_UPDATE_ISSUES_CATEGORY_LOADING =
  "DRIVER_UPDATE_ISSUES_CATEGORY_LOADING";
export const DASHBOARD_CUSTOMER_DETAILS_DAILY_SUCCESSFULL =
  "DASHBOARD_CUSTOMER_DETAILS_DAILY_SUCCESSFULL";
export const DASHBOARD_CUSTOMER_DETAILS_DAILY_ERROR =
  "DASHBOARD_CUSTOMER_DETAILS_DAILY_ERROR";
export const DASHBOARD_CUSTOMER_DETAILS_DAILY_LOADING =
  "DASHBOARD_CUSTOMER_DETAILS_DAILY_LOADING";
export const DASHBOARD_RIDER_DETAILS_MONTHLY_SUCCESSFULL =
  "DASHBOARD_RIDER_DETAILS_MONTHLY_SUCCESSFULL";
export const DASHBOARD_RIDER_DETAILS_MONTHLY_ERROR =
  "DASHBOARD_RIDER_DETAILS_MONTHLY_ERROR";
export const DASHBOARD_RIDER_DETAILS_MONTHLY_LOADING =
  "DASHBOARD_RIDER_DETAILS_MONTHLY_LOADING";
export const DASHBOARD_VEHICLE_FLEET_MONTHLY_SUCCESSFULL =
  "DASHBOARD_VEHICLE_FLEET_MONTHLY_SUCCESSFULL";
export const DASHBOARD_VEHICLE_FLEET_MONTHLY_ERROR =
  "DASHBOARD_VEHICLE_FLEET_MONTHLY_ERROR";
export const DASHBOARD_VEHICLE_FLEET_MONTHLY_LOADING =
  "DASHBOARD_VEHICLE_FLEET_MONTHLY_LOADING";
export const DASHBOARD_DRIVER_WORK_MONTHLY_SUCCESSFULL =
  "DASHBOARD_DRIVER_WORK_MONTHLY_SUCCESSFULL";
export const DASHBOARD_DRIVER_WORK_MONTHLY_ERROR =
  "DASHBOARD_DRIVER_WORK_MONTHLY_ERROR";
export const DASHBOARD_DRIVER_WORK_MONTHLY_LOADING =
  "DASHBOARD_DRIVER_WORK_MONTHLY_LOADING";
export const ADMIN_ADD_CHARGES_SUCCESSFULL = "ADMIN_ADD_CHARGES_SUCCESSFULL";
export const ADMIN_ADD_CHARGES_ERROR = "ADMIN_ADD_CHARGES_ERROR";
export const ADMIN_ADD_CHARGES_LOADING = "ADMIN_ADD_CHARGES_LOADING";
export const ADMIN_CHARGES_LIST_SUCCESSFULL = "ADMIN_CHARGES_LIST_SUCCESSFULL";
export const ADMIN_CHARGES_LIST_ERROR = "ADMIN_CHARGES_LIST_ERROR";
export const ADMIN_CHARGES_LIST_LOADING = "ADMIN_CHARGES_LIST_LOADING";
export const ADMIN_EDIT_CHARGES_SUCCESSFULL = "ADMIN_EDIT_CHARGES_SUCCESSFULL";
export const ADMIN_EDIT_CHARGES_ERROR = "ADMIN_EDIT_CHARGES_ERROR";
export const ADMIN_EDIT_CHARGES_LOADING = "ADMIN_EDIT_CHARGES_LOADING";
export const ADMIN_DELETE_CHARGES_SUCCESSFULL =
  "ADMIN_DELETE_CHARGES_SUCCESSFULL";
export const ADMIN_DELETE_CHARGES_ERROR = "ADMIN_DELETE_CHARGES_ERROR";
export const ADMIN_DELETE_CHARGES_LOADING = "ADMIN_DELETE_CHARGES_LOADING";
export const DASHBOARD_REPAIRE_EXPENSE_MONTHLY_SUCCESSFULL =
  "DASHBOARD_REPAIRE_EXPENSE_MONTHLY_SUCCESSFULL";
export const DASHBOARD_REPAIRE_EXPENSE_MONTHLY_ERROR =
  "DASHBOARD_REPAIRE_EXPENSE_MONTHLY_ERROR";
export const DASHBOARD_REPAIRE_EXPENSE_MONTHLY_LOADING =
  "DASHBOARD_REPAIRE_EXPENSE_MONTHLY_LOADING";
export const DASHBOARD_MONTH_SUCCESSFULL = "DASHBOARD_MONTH_SUCCESSFULL";
export const DASHBOARD_MONTH_ERROR = "DASHBOARD_MONTH_ERROR";
export const DASHBOARD_MONTH_LOADING = "DASHBOARD_MONTH_LOADING";
export const DASHBOARD_ADD_SALES_PAYOUT_SUCCESSFULL =
  "DASHBOARD_ADD_SALES_PAYOUT_SUCCESSFULL";
export const DASHBOARD_ADD_SALES_PAYOUT_ERROR =
  "DASHBOARD_ADD_SALES_PAYOUT_ERROR";
export const DASHBOARD_ADD_SALES_PAYOUT_LOADING =
  "DASHBOARD_ADD_SALES_PAYOUT_LOADING";
export const DASHBOARD_SALES_PAYOUT_LIST_SUCCESSFULL =
  "DASHBOARD_SALES_PAYOUT_LIST_SUCCESSFULL";
export const DASHBOARD_SALES_PAYOUT_LIST_ERROR =
  "DASHBOARD_SALES_PAYOUT_LIST_ERROR";
export const DASHBOARD_SALES_PAYOUT_LIST_LOADING =
  "DASHBOARD_SALES_PAYOUT_LIST_LOADING";
export const DASHBOARD_VIEW_SALES_PAYOUT_SUCCESSFULL =
  "DASHBOARD_VIEW_SALES_PAYOUT_SUCCESSFULL";
export const DASHBOARD_VIEW_SALES_PAYOUT_ERROR =
  "DASHBOARD_VIEW_SALES_PAYOUT_ERROR";
export const DASHBOARD_VIEW_SALES_PAYOUT_LOADING =
  "DASHBOARD_VIEW_SALES_PAYOUT_LOADING";
export const DASHBOARD_EDIT_SALES_PAYOUT_SUCCESSFULL =
  "DASHBOARD_EDIT_SALES_PAYOUT_SUCCESSFULL";
export const DASHBOARD_EDIT_SALES_PAYOUT_ERROR =
  "DASHBOARD_EDIT_SALES_PAYOUT_ERROR";
export const DASHBOARD_EDIT_SALES_PAYOUT_LOADING =
  "DASHBOARD_EDIT_SALES_PAYOUT_LOADING";
export const DASHBOARD_EXCEL_SALES_PAYOUT_SUCCESSFULL =
  "DASHBOARD_EXCEL_SALES_PAYOUT_SUCCESSFULL";
export const DASHBOARD_EXCEL_SALES_PAYOUT_ERROR =
  "DASHBOARD_EXCEL_SALES_PAYOUT_ERROR";
export const DASHBOARD_EXCEL_SALES_PAYOUT_LOADING =
  "DASHBOARD_EXCEL_SALES_PAYOUT_LOADING";
export const DASHBOARD_CUSTOMER_GROWTH_SUCCESSFULL =
  "DASHBOARD_CUSTOMER_GROWTH_SUCCESSFULL";
export const DASHBOARD_CUSTOMER_GROWTH_ERROR =
  "DASHBOARD_CUSTOMER_GROWTH_ERROR";
export const DASHBOARD_CUSTOMER_GROWTH_LOADING =
  "DASHBOARD_CUSTOMER_GROWTH_LOADING";

export const DRIVER_WISE_SUBMITED_PARCEL_SUCCESS =
  "DRIVER_WISE_SUBMITED_PARCEL_SUCCESS";
export const DRIVER_WISE_SUBMITED_PARCEL_ERROR =
  "DRIVER_WISE_SUBMITED_PARCEL_ERROR";
export const DRIVER_WISE_SUBMITED_PARCEL_LOADING =
  "DRIVER_WISE_SUBMITED_PARCEL_LOADING";

export const DASHBOARD_CLIENT_PERFORMANCE_LOADING =
  "DASHBOARD_CLIENT_PERFORMANCE_LOADING";
export const DASHBOARD_CLIENT_PERFORMANCE_ERROR =
  "DASHBOARD_CLIENT_PERFORMANCE_ERROR";
export const DASHBOARD_CLIENT_PERFORMANCE_SUCCESS =
  "DASHBOARD_CLIENT_PERFORMANCE_SUCCESS";

export const DASHBOARD_LAST_MONTH_SALES_LOADING =
  "DASHBOARD_LAST_MONTH_SALES_LOADING";
export const DASHBOARD_LAST_MONTH_SALES_ERROR =
  "DASHBOARD_LAST_MONTH_SALES_ERROR";
export const DASHBOARD_LAST_MONTH_SALES_SUCCESS =
  "DASHBOARD_LAST_MONTH_SALES_SUCCESS";

export const DASHBOARD_LAST_QUARTER_SALES_LOADING =
  "DASHBOARD_LAST_QUARTER_SALES_LOADING";
export const DASHBOARD_LAST_QUARTER_SALES_ERROR =
  "DASHBOARD_LAST_QUARTER_SALES_ERROR";
export const DASHBOARD_LAST_QUARTER_SALES_SUCCESS =
  "DASHBOARD_LAST_QUARTER_SALES_SUCCESS";

export const DASHBOARD_REVENUE_PERFORMANCE_LOADING =
  "DASHBOARD_REVENUE_PERFORMANCE_LOADING";
export const DASHBOARD_REVENUE_PERFORMANCE_ERROR =
  "DASHBOARD_REVENUE_PERFORMANCE_ERROR";
export const DASHBOARD_REVENUE_PERFORMANCE_SUCCESS =
  "DASHBOARD_REVENUE_PERFORMANCE_SUCCESS";

export const DASHBOARD_REVENUE_CITIES_WISE_LOADING =
  "DASHBOARD_REVENUE_CITIES_WISE_LOADING";
export const DASHBOARD_REVENUE_CITIES_WISE_ERROR =
  "DASHBOARD_REVENUE_CITIES_WISE_ERROR";
export const DASHBOARD_REVENUE_CITIES_WISE_SUCCESS =
  "DASHBOARD_REVENUE_CITIES_WISE_SUCCESS";

export const DASHBOARD_UTILISED_FLEET_LOADING =
  "DASHBOARD_UTILISED_FLEET_LOADING";
export const DASHBOARD_UTILISED_FLEET_ERROR = "DASHBOARD_UTILISED_FLEET_ERROR";
export const DASHBOARD_UTILISED_FLEET_SUCCESS =
  "DASHBOARD_UTILISED_FLEET_SUCCESS";

export const DASHBOARD_RIDERS_PARCEL_DELIVERY_LOADING =
  "DASHBOARD_RIDERS_PARCEL_DELIVERY_LOADING";
export const DASHBOARD_RIDERS_PARCEL_DELIVERY_ERROR =
  "DASHBOARD_RIDERS_PARCEL_DELIVERY_ERROR";
export const DASHBOARD_RIDERS_PARCEL_DELIVERY_SUCCESS =
  "DASHBOARD_RIDERS_PARCEL_DELIVERY_SUCCESS";

export const DASHBOARD_RIDERS_EARNING_CHART_LOADING =
  "DASHBOARD_RIDERS_EARNING_CHART_LOADING";
export const DASHBOARD_RIDERS_EARNING_CHART_ERROR =
  "DASHBOARD_RIDERS_EARNING_CHART_ERROR";
export const DASHBOARD_RIDERS_EARNING_CHART_SUCCESS =
  "DASHBOARD_RIDERS_EARNING_CHART_SUCCESS";

export const DRIVER_LEFT_SURVEY_ADD_SUCCESSFULL =
  "DRIVER_LEFT_SURVEY_ADD_SUCCESSFULL";
export const DRIVER_LEFT_SURVEY_ADD_ERROR = "DRIVER_LEFT_SURVEY_ADD_ERROR";
export const DRIVER_LEFT_SURVEY_ADD_LOADING = "DRIVER_LEFT_SURVEY_ADD_LOADING";
export const DRIVER_LEFT_SURVEY_LIST_SUCCESSFULL =
  "DRIVER_LEFT_SURVEY_LIST_SUCCESSFULL";
export const DRIVER_LEFT_SURVEY_LIST_ERROR = "DRIVER_LEFT_SURVEY_LIST_ERROR";
export const DRIVER_LEFT_SURVEY_LIST_LOADING =
  "DRIVER_LEFT_SURVEY_LIST_LOADING";
export const DRIVER_NOT_TAKEN_SELFIE_LIST_SUCCESSFULL =
  "DRIVER_NOT_TAKEN_SELFIE_LIST_SUCCESSFULL";
export const DRIVER_NOT_TAKEN_SELFIE_LIST_ERROR =
  "DRIVER_NOT_TAKEN_SELFIE_LIST_ERROR";
export const DRIVER_NOT_TAKEN_SELFIE_LIST_LOADING =
  "DRIVER_NOT_TAKEN_SELFIE_LIST_LOADING";
export const DRIVER_SINGLE_SURVEY_LIST_SUCCESSFULL =
  "DRIVER_SINGLE_SURVEY_LIST_SUCCESSFULL";
export const DRIVER_SINGLE_SURVEY_LIST_ERROR =
  "DRIVER_SINGLE_SURVEY_LIST_ERROR";
export const DRIVER_SINGLE_SURVEY_LIST_LOADING =
  "DRIVER_SINGLE_SURVEY_LIST_LOADING";

export const ADD_EXPENSE_CATEGORY_LOADING = "ADD_EXPENSE_CATEGORY_LOADING";
export const ADD_EXPENSE_CATEGORY_SUCCESSFULL =
  "ADD_EXPENSE_CATEGORY_SUCCESSFULL";
export const ADD_EXPENSE_CATEGORY_ERROR = "ADD_EXPENSE_CATEGORY_ERROR";

export const GET_ALL_EXPENSE_CATEGORY_LOADING =
  "GET_ALL_EXPENSE_CATEGORY_LOADING";
export const GET_ALL_EXPENSE_CATEGORY_SUCCESSFULL =
  "GET_ALL_EXPENSE_CATEGORY_SUCCESSFULL";
export const GET_ALL_EXPENSE_CATEGORY_ERROR = "GET_ALL_EXPENSE_CATEGORY_ERROR";

export const EDIT_EXPENSE_CATEGORY_LOADING = "EDIT_EXPENSE_CATEGORY_LOADING";
export const EDIT_EXPENSE_CATEGORY_SUCCESSFULL =
  "EDIT_EXPENSE_CATEGORY_SUCCESSFULL";
export const EDIT_EXPENSE_CATEGORY_ERROR = "EDIT_EXPENSE_CATEGORY_ERROR";

export const ADD_SWIGGY_BILLING_LOADING = "ADD_SWIGGY_BILLING_LOADING";
export const ADD_SWIGGY_BILLING_SUCCESS = "ADD_SWIGGY_BILLING_SUCCESS";
export const ADD_SWIGGY_BILLING_ERROR = "ADD_SWIGGY_BILLING_ERROR";

export const EDIT_SWIGGY_BILLING_LOADING = "EDIT_SWIGGY_BILLING_LOADING";
export const EDIT_SWIGGY_BILLING_SUCCESS = "EDIT_SWIGGY_BILLING_SUCCESS";
export const EDIT_SWIGGY_BILLING_ERROR = "EDIT_SWIGGY_BILLING_ERROR";

export const GET_SWIGGY_BILLING_LOADING = "GET_SWIGGY_BILLING_LOADING";
export const GET_SWIGGY_BILLING_SUCCESS = "GET_SWIGGY_BILLING_SUCCESS";
export const GET_SWIGGY_BILLING_ERROR = "GET_SWIGGY_BILLING_ERROR";

export const ADD_ZOMATO_BILLING_LOADING = "ADD_ZOMATO_BILLING_LOADING";
export const ADD_ZOMATO_BILLING_SUCCESS = "ADD_ZOMATO_BILLING_SUCCESS";
export const ADD_ZOMATO_BILLING_ERROR = "ADD_ZOMATO_BILLING_ERROR";

export const EDIT_ZOMATO_BILLING_LOADING = "EDIT_ZOMATO_BILLING_LOADING";
export const EDIT_ZOMATO_BILLING_SUCCESS = "EDIT_ZOMATO_BILLING_SUCCESS";
export const EDIT_ZOMATO_BILLING_ERROR = "EDIT_ZOMATO_BILLING_ERROR";

export const GET_ZOMATO_BILLING_LOADING = "GET_ZOMATO_BILLING_LOADING";
export const GET_ZOMATO_BILLING_SUCCESS = "GET_ZOMATO_BILLING_SUCCESS";
export const GET_ZOMATO_BILLING_ERROR = "GET_ZOMATO_BILLING_ERROR";

export const ADD_BIGBASKET_BILLING_LOADING = "ADD_BIGBASKET_BILLING_LOADING";
export const ADD_BIGBASKET_BILLING_SUCCESS = "ADD_BIGBASKET_BILLING_SUCCESS";
export const ADD_BIGBASKET_BILLING_ERROR = "ADD_BIGBASKET_BILLING_ERROR";

export const EDIT_BIGBASKET_BILLING_LOADING = "EDIT_BIGBASKET_BILLING_LOADING";
export const EDIT_BIGBASKET_BILLING_SUCCESS = "EDIT_BIGBASKET_BILLING_SUCCESS";
export const EDIT_BIGBASKET_BILLING_ERROR = "EDIT_BIGBASKET_BILLING_ERROR";

export const GET_BIGBASKET_BILLING_LOADING = "GET_BIGBASKET_BILLING_LOADING";
export const GET_BIGBASKET_BILLING_SUCCESS = "GET_BIGBASKET_BILLING_SUCCESS";
export const GET_BIGBASKET_BILLING_ERROR = "GET_BIGBASKET_BILLING_ERROR";

export const ADD_BBNOW_BILLING_LOADING = "ADD_BBNOW_BILLING_LOADING";
export const ADD_BBNOW_BILLING_SUCCESS = "ADD_BBNOW_BILLING_SUCCESS";
export const ADD_BBNOW_BILLING_ERROR = "ADD_BBNOW_BILLING_ERROR";

export const EDIT_BBNOW_BILLING_LOADING = "EDIT_BBNOW_BILLING_LOADING";
export const EDIT_BBNOW_BILLING_SUCCESS = "EDIT_BBNOW_BILLING_SUCCESS";
export const EDIT_BBNOW_BILLING_ERROR = "EDIT_BBNOW_BILLING_ERROR";

export const GET_BBNOW_BILLING_LOADING = "GET_BBNOW_BILLING_LOADING";
export const GET_BBNOW_BILLING_SUCCESS = "GET_BBNOW_BILLING_SUCCESS";
export const GET_BBNOW_BILLING_ERROR = "GET_BBNOW_BILLING_ERROR";

export const ADD_FLIPKART_BILLING_LOADING = "ADD_FLIPKART_BILLING_LOADING";
export const ADD_FLIPKART_BILLING_SUCCESS = "ADD_FLIPKART_BILLING_SUCCESS";
export const ADD_FLIPKART_BILLING_ERROR = "ADD_FLIPKART_BILLING_ERROR";

export const EDIT_FLIPKART_BILLING_LOADING = "EDIT_FLIPKART_BILLING_LOADING";
export const EDIT_FLIPKART_BILLING_SUCCESS = "EDIT_FLIPKART_BILLING_SUCCESS";
export const EDIT_FLIPKART_BILLING_ERROR = "EDIT_FLIPKART_BILLING_ERROR";

export const GET_FLIPKART_BILLING_LOADING = "GET_FLIPKART_BILLING_LOADING";
export const GET_FLIPKART_BILLING_SUCCESS = "GET_FLIPKART_BILLING_SUCCESS";
export const GET_FLIPKART_BILLING_ERROR = "GET_FLIPKART_BILLING_ERROR";

export const ADD_BLUEDART_BILLING_LOADING = "ADD_BLUEDART_BILLING_LOADING";
export const ADD_BLUEDART_BILLING_SUCCESS = "ADD_BLUEDART_BILLING_SUCCESS";
export const ADD_BLUEDART_BILLING_ERROR = "ADD_BLUEDART_BILLING_ERROR";

export const EDIT_BLUEDART_BILLING_LOADING = "EDIT_BLUEDART_BILLING_LOADING";
export const EDIT_BLUEDART_BILLING_SUCCESS = "EDIT_BLUEDART_BILLING_SUCCESS";
export const EDIT_BLUEDART_BILLING_ERROR = "EDIT_BLUEDART_BILLING_ERROR";

export const GET_BLUEDART_BILLING_LOADING = "GET_BLUEDART_BILLING_LOADING";
export const GET_BLUEDART_BILLING_SUCCESS = "GET_BLUEDART_BILLING_SUCCESS";
export const GET_BLUEDART_BILLING_ERROR = "GET_BLUEDART_BILLING_ERROR";

export const DASHBOARD_SWIGGY_BILLING_CHART_LOADING =
  "DASHBOARD_SWIGGY_BILLING_CHART_LOADING";
export const DASHBOARD_SWIGGY_BILLING_CHART_ERROR =
  "DASHBOARD_SWIGGY_BILLING_CHART_ERROR";
export const DASHBOARD_SWIGGY_BILLING_CHART_SUCCESS =
  "DASHBOARD_SWIGGY_BILLING_CHART_SUCCESS";

export const DASHBOARD_ZOMATO_BILLING_CHART_LOADING =
  "DASHBOARD_ZOMATO_BILLING_CHART_LOADING";
export const DASHBOARD_ZOMATO_BILLING_CHART_ERROR =
  "DASHBOARD_ZOMATO_BILLING_CHART_ERROR";
export const DASHBOARD_ZOMATO_BILLING_CHART_SUCCESS =
  "DASHBOARD_ZOMATO_BILLING_CHART_SUCCESS";

export const DASHBOARD_BIGBASKET_BILLING_CHART_LOADING =
  "DASHBOARD_BIGBASKET_BILLING_CHART_LOADING";
export const DASHBOARD_BIGBASKET_BILLING_CHART_ERROR =
  "DASHBOARD_BIGBASKET_BILLING_CHART_ERROR";
export const DASHBOARD_BIGBASKET_BILLING_CHART_SUCCESS =
  "DASHBOARD_BIGBASKET_BILLING_CHART_SUCCESS";

export const DASHBOARD_BBNOW_BILLING_CHART_LOADING =
  "DASHBOARD_BBNOW_BILLING_CHART_LOADING";
export const DASHBOARD_BBNOW_BILLING_CHART_ERROR =
  "DASHBOARD_BBNOW_BILLING_CHART_ERROR";
export const DASHBOARD_BBNOW_BILLING_CHART_SUCCESS =
  "DASHBOARD_BBNOW_BILLING_CHART_SUCCESS";

export const DASHBOARD_FLIPKART_BILLING_CHART_LOADING =
  "DASHBOARD_FLIPKART_BILLING_CHART_LOADING";
export const DASHBOARD_FLIPKART_BILLING_CHART_ERROR =
  "DASHBOARD_FLIPKART_BILLING_CHART_ERROR";
export const DASHBOARD_FLIPKART_BILLING_CHART_SUCCESS =
  "DASHBOARD_FLIPKART_BILLING_CHART_SUCCESS";

export const DASHBOARD_BLUEDART_BILLING_CHART_LOADING =
  "DASHBOARD_BLUEDART_BILLING_CHART_LOADING";
export const DASHBOARD_BLUEDART_BILLING_CHART_ERROR =
  "DASHBOARD_BLUEDART_BILLING_CHART_ERROR";
export const DASHBOARD_BLUEDART_BILLING_CHART_SUCCESS =
  "DASHBOARD_BLUEDART_BILLING_CHART_SUCCESS";

export const DASHBOARD_FULLTIME_PARCEL_CHART_LOADING =
  "DASHBOARD_FULLTIME_PARCEL_CHART_LOADING";
export const DASHBOARD_FULLTIME_PARCEL_CHART_ERROR =
  "DASHBOARD_FULLTIME_PARCEL_CHART_ERROR";
export const DASHBOARD_FULLTIME_PARCEL_CHART_SUCCESS =
  "DASHBOARD_FULLTIME_PARCEL_CHART_SUCCESS";

export const DASHBOARD_PARTTIME_PARCEL_CHART_LOADING =
  "DASHBOARD_PARTTIME_PARCEL_CHART_LOADING";
export const DASHBOARD_PARTTIME_PARCEL_CHART_ERROR =
  "DASHBOARD_PARTTIME_PARCEL_CHART_ERROR";
export const DASHBOARD_PARTTIME_PARCEL_CHART_SUCCESS =
  "DASHBOARD_PARTTIME_PARCEL_CHART_SUCCESS";

export const GET_COD_ORDERS_SUCCESSFULL = "GET_COD_ORDERS_SUCCESSFULL";
export const GET_COD_ORDERS_ERROR = "GET_COD_ORDERS_ERROR";
export const GET_COD_ORDERS_LOADING = "GET_COD_ORDERS_LOADING";

export const ADD_COD_ORDERS_SUCCESSFULL = "ADD_COD_ORDERS_SUCCESSFULL";
export const ADD_COD_ORDERS_ERROR = "ADD_COD_ORDERS_ERROR";
export const ADD_COD_ORDERS_LOADING = "ADD_COD_ORDERS_LOADING";

export const EDIT_COD_ORDERS_SUCCESSFULL = "EDIT_COD_ORDERS_SUCCESSFULL";
export const EDIT_COD_ORDERS_ERROR = "EDIT_COD_ORDERS_ERROR";
export const EDIT_COD_ORDERS_LOADING = "EDIT_COD_ORDERS_LOADING";

export const COMMON_CITY_LIST_LOADING = "COMMON_CITY_LIST_LOADING";
export const COMMON_CITY_LIST_SUCCESSFULL = "COMMON_CITY_LIST_SUCCESSFULL";
export const COMMON_CITY_LIST_ERROR = "COMMON_CITY_LIST_ERROR";
