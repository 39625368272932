import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import Chart from "react-apexcharts";
import * as action from "../../../../action/Dashboard/dashboard_action";

const CustomerGrowth = () => {
  const dispatch = useDispatch();

  // months list
  const months = [
    {
      value: 1,
      label: "January",
    },
    {
      value: 2,
      label: "February",
    },
    {
      value: 3,
      label: "March",
    },
    {
      value: 4,
      label: "April",
    },
    {
      value: 5,
      label: "May",
    },
    {
      value: 6,
      label: "June",
    },
    {
      value: 7,
      label: "July",
    },
    {
      value: 8,
      label: "August",
    },
    {
      value: 9,
      label: "September",
    },
    {
      value: 10,
      label: "October",
    },
    {
      value: 11,
      label: "November",
    },
    {
      value: 12,
      label: "December",
    },
  ];

  //current get month
  const currentMonth = new Date().getMonth() + 1;

  //current Year
  const currentYear = {
    value: new Date().getFullYear(),
    label: new Date().getFullYear(),
  };

  // customer growth
  const [firstRevenue, setFirstRevenue] = useState([]);
  const [secondRevenue, setSecondRevenue] = useState([]);
  const [firstMonthGrowth, setFirstMonthGrowth] = useState();
  const [secondMonthGrowth, setSecondMonthGrowth] = useState();
  const [firstGrowthCustomerList, setFirstGrowthCustomerList] = useState([]);
  const [secondGrowthCustomerList, setSecondGrowthCustomerList] = useState([]);
  const [firstYearForGrowth, setFirstYearForGrowth] = useState();
  const [secondYearForGrowth, setSecondYearForGrowth] = useState();
  const [yearListGrowth, setYearListGrowth] = useState([]);
  const [firstColorList, setFirstColorList] = useState([]);
  const [secondColorList, setSecondColorList] = useState([]);

  // customer growth 1
  useEffect(() => {
    dispatch(
      action.dashboard_customer_growth(
        firstYearForGrowth ? firstYearForGrowth.value : currentYear.value,
        firstMonthGrowth ? firstMonthGrowth.value : currentMonth - 2
      )
    )
      .then((data) => {
        let firstCustomerList = [];
        let yearList = [];
        let revenue1 = [];
        let growth1Data = data?.data?.data;
        let colorsCode = [];

        yearList = data?.data?.yearData
          .map((year) => ({
            value: year,
            label: year,
          }))
          .sort((a, b) => b.value - a.value);

        growth1Data.map((item) => {
          item.customerData.map((it) => {
            firstCustomerList.push(it.customer.name);
            colorsCode.push(it.customer.colorCode);
            revenue1.push(+it.revenueShare.toFixed(2));
          });
        });

        setFirstColorList(colorsCode);
        setFirstRevenue(revenue1);
        setFirstGrowthCustomerList(firstCustomerList);
        setYearListGrowth(yearList);
      })
      .catch({});
  }, [firstYearForGrowth, firstMonthGrowth]);

  // customer growth 2
  useEffect(() => {
    dispatch(
      action.dashboard_customer_growth(
        secondYearForGrowth ? secondYearForGrowth.value : currentYear.value,
        secondMonthGrowth ? secondMonthGrowth.value : currentMonth - 1
      )
    )
      .then((data) => {
        let secondCustomerList = [];
        let colorsCode = [];
        let revenue2 = [];
        let growth2Data = data?.data?.data;

        growth2Data.map((item) => {
          item.customerData.map((it) => {
            secondCustomerList.push(it.customer.name);
            colorsCode.push(it.customer.colorCode);
            revenue2.push(+it.revenueShare.toFixed(2));
          });
        });

        setSecondColorList(colorsCode);
        setSecondRevenue(revenue2);
        setSecondGrowthCustomerList(secondCustomerList);
      })
      .catch({});
  }, [secondYearForGrowth, secondMonthGrowth]);

  // customer growth1
  var customerGrowth1 = {
    chart: {
      fontFamily: "Apple-System, sans-serif",
      height: 300,
      type: "donut",
    },

    colors: firstColorList,
    plotOptions: {
      pie: {
        donut: {
          size: "40%",
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: true,
      position: "bottom",
    },
    series: firstRevenue,
    // series: [38, 25, 13, 3, 6, 15],
    labels: firstGrowthCustomerList,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  //customer growth2
  var customerGrowth2 = {
    chart: {
      fontFamily: "Apple-System, sans-serif",
      height: 300,
      type: "donut",
    },
    colors: secondColorList,
    plotOptions: {
      pie: {
        donut: {
          size: "40%",
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: true,
      position: "bottom",
    },
    series: secondRevenue,
    labels: secondGrowthCustomerList,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div className="widget widget-chart-one">
      <div className="widget-heading ">
        <h5 className="">Customer Wise Growth Percentage </h5>
        <ul className="tabs tab-pills ">
          <li>
            <a id="tb_1" className="tabmenu" style={{ marginLeft: "15px" }}>
              Monthly
            </a>
          </li>
        </ul>
      </div>

      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
          <div className="tabs tab-pills row align-items-center">
            <div className="mx-2 ">
              <label>Months</label>
              <Select
                id="inputMonth"
                defaultValue={months[currentMonth - 3]}
                value={firstMonthGrowth}
                onChange={(value) => {
                  setFirstMonthGrowth(value);
                }}
                options={months}
              />
            </div>
            <div className="mx-2 ">
              <label>Year</label>
              <Select
                id="inputYear"
                defaultValue={currentYear}
                value={firstYearForGrowth}
                onChange={(value) => {
                  setFirstYearForGrowth(value);
                }}
                options={yearListGrowth}
              />
            </div>
          </div>
          {/* <p className="text-center my-4">
          {firstMonthGrowth
            ? firstMonthGrowth.label
            : months[currentMonth - 3].label}
        </p> */}

          {firstRevenue.length > 0 ? (
            <div className="my-4" id="growthchart">
              <Chart
                options={customerGrowth1}
                type="donut"
                series={customerGrowth1.series}
                height={400}
              />
            </div>
          ) : (
            <div className="mt-2">
              <p className="d-flex justify-content-center align-items-center">
                No Data Found
              </p>
            </div>
          )}
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
          <div className="tabs tab-pills row align-items-center">
            <div className="mx-2">
              <label>Months</label>
              <Select
                id="inputYear"
                defaultValue={months[currentMonth - 2]}
                value={secondMonthGrowth}
                onChange={(value) => {
                  setSecondMonthGrowth(value);
                }}
                options={months}
              />
            </div>
            <div className="mx-2">
              <label>Year</label>
              <Select
                id="inputYear"
                defaultValue={currentYear}
                value={secondYearForGrowth}
                onChange={(value) => {
                  setSecondYearForGrowth(value);
                }}
                options={yearListGrowth}
              />
            </div>
          </div>
          {/* <p className="text-center my-4">
          {secondMonthGrowth
            ? secondMonthGrowth.label
            : months[currentMonth - 2].label}
        </p> */}
          {secondRevenue.length > 0 ? (
            <div className="my-4" id="growthchart">
              <Chart
                options={customerGrowth2}
                type="donut"
                series={customerGrowth2.series}
                height={400}
              />
            </div>
          ) : (
            <div className="mt-2">
              <p className="d-flex justify-content-center align-items-center">
                No Data Found
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerGrowth;
