import React from "react";
function Footer() {
  return (
    <div className="footer-wrapper">
      <div className="footer-section f-section-1">
        <p className="">
          <b>Developed By Sapient Codelabs.</b>
        </p>
      </div>
      <div className="footer-section f-section-2">
        <p className="">Admin</p>
      </div>
    </div>
  );
}
export default Footer;
