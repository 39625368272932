import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import Header from "../../DashBoard/Header";
import Sub_Header from "../../DashBoard/Sub_Header";
import SideBar from "../../DashBoard/SideBar";
import Footer from "../../DashBoard/Footer";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { DateRangePicker, Input } from "rsuite";
import { RxCross2 } from "react-icons/rx";
import $ from "jquery";
import Pagination from "../../common/Pagination/Pagination";
import * as driveraction from "../../../action/Driver/driver_action";
import CODExportToExcel from "./CODExportToExcel";

const data = [
  {
    Date: "2023-06-01",
    DriverID: "EV2",
    DriverName: "Demo1",
    NoOfOrders: 123,
    Amount: 100,
  },
  {
    Date: "2023-05-31",
    DriverID: "EV23",
    DriverName: "Demo2",
    NoOfOrders: 123,
    Amount: 100,
  },
  {
    Date: "2023-05-23",
    DriverID: "EV67",
    DriverName: "Demo3",
    NoOfOrders: 123,
    Amount: 100,
  },
  {
    Date: "2023-05-27",
    DriverID: "EV32",
    DriverName: "Demo4",
    NoOfOrders: 123,
    Amount: 100,
  },
];

const CODOrders = () => {
  // ref for display validation error
  const dateFocus = useRef();
  const driverIdFocus = useRef();
  const ordersFocus = useRef();
  const amountFocus = useRef();

  const editdateFocus = useRef();
  const editdriverIdFocus = useRef();
  const editordersFocus = useRef();
  const editamountFocus = useRef();

  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

  const [codOrdersData, setCODOrdersData] = useState({
    currentPage: 1,
    ordersData: [],
    formId: "",
  });
  const [addCODOrders, setAddCODOrders] = useState({
    date: "",
    driverId: "",
    orders: "",
    amount: "",
  });

  const [editCODOrders, setEditCODOrders] = useState({
    date: "",
    driverId: "",
    orders: "",
    amount: "",
  });

  const [driverList, setDriverList] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [editButtonHide, setEditButtonHide] = useState(false);
  const [submitButtonHide, setSubmitButtonHide] = useState(true);

  const [codOrdersFilterData, setCodOrdersFilterData] = useState({
    dateRange: [],
    drivers: [],
  });

  const [selectedFiltersData, setSelectedFiltersData] = useState({
    dateRange: JSON.stringify([]),
    drivers: JSON.stringify([]),
  });

  const setEditFormEditable = (type) => {
    if (type) {
      setDisabled(false);
      //   setCancelButtonHide(false);
      setSubmitButtonHide(false);
      setEditButtonHide(true);
    } else {
      setDisabled(true);
      //   setCancelButtonHide(true);
      setSubmitButtonHide(true);
      setEditButtonHide(false);
    }
  };

  // get all drivers list
  useEffect(() => {
    dispatch(driveraction.Driver_list_select())
      .then((data) => {
        let driver_details = data.data;
        let newDriverList = [];
        driver_details.map((item) => {
          newDriverList.push({
            value: item._id,
            label: `EV${item.Driver_Id} - ${item.name}`,
          });
        });
        setDriverList(newDriverList);
      })
      .catch({});
  }, []);

  useEffect(() => {
    const codFilterData = () => {
      let dateRange = codOrdersFilterData?.dateRange;
      let drivers = codOrdersFilterData?.drivers;

      const driversArray = drivers?.map((item) => {
        return item.value;
      });

      setSelectedFiltersData({
        ...selectedFiltersData,
        dateRange: JSON.stringify(dateRange),
        drivers: JSON.stringify(driversArray),
      });
    };
    codFilterData();
  }, [codOrdersFilterData]);

  // cod orders data
  useEffect(() => {
    dispatch(driveraction.get_cod_oders(selectedFiltersData))
      .then((data) => {
        let ordersData = data.data;
        setCODOrdersData({
          ...codOrdersData,
          ordersData: ordersData,
        });
      })
      .catch((err) => {
        toast.error(err);
      });
  }, [selectedFiltersData]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setErrors({});

    let customErrors = {};

    if (!addCODOrders.date) {
      customErrors = { ...customErrors, date: "Please Select Date" };
      dateFocus.current.focus();
    } else if (!addCODOrders.driverId) {
      customErrors = {
        ...customErrors,
        driverId: "Please Enter Driver Id",
      };
      driverIdFocus.current.focus();
    } else if (!addCODOrders.orders) {
      customErrors = {
        ...customErrors,
        orders: "Please Enter No of Orders",
      };
      ordersFocus.current.focus();
    } else if (!addCODOrders.amount) {
      customErrors = {
        ...customErrors,
        amount: "Please Enter Amount",
      };
      amountFocus.current.focus();
    }

    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }

    dispatch(driveraction.add_cod_orders(addCODOrders))
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          $("#addCODOrders .close").click();
          let orderData = data.data;
          setCODOrdersData({ ...codOrdersData, ordersData: orderData });
          setEditFormEditable(false);
        } else if (!data.success) {
          toast.error(data.message);
        }
        setErrors({});
      })
      .catch((err) => {});

    setErrors({});
    setAddCODOrders({
      date: "",
      driverId: "",
      orders: "",
      amount: "",
    });
  };

  const onUpdateHandler = (e) => {
    e.preventDefault();
    setErrors({});

    setErrors({});
    let customErrors = {};

    if (!editCODOrders.date) {
      customErrors = { ...customErrors, date: "Please Select Date" };
      editdateFocus.current.focus();
    } else if (!editCODOrders.driverId) {
      customErrors = {
        ...customErrors,
        driverId: "Please Enter Driver Id",
      };
      editdriverIdFocus.current.focus();
    } else if (!editCODOrders.orders) {
      customErrors = {
        ...customErrors,
        orders: "Please Enter No of Orders",
      };
      editordersFocus.current.focus();
    } else if (!editCODOrders.amount) {
      customErrors = {
        ...customErrors,
        amount: "Please Enter Amount",
      };
      editamountFocus.current.focus();
    }

    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }

    dispatch(
      driveraction.edit_cod_orders(
        codOrdersData.formId ? codOrdersData.formId : null,
        editCODOrders
      )
    )
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          $("#editCODOrders .close").click();
          let orderData = data.data;
          setCODOrdersData({ ...codOrdersData, ordersData: orderData });
          setEditFormEditable(false);
        } else if (!data.success) {
          toast.error(data.message);
        }
        setErrors({});
      })
      .catch((err) => {});

    setErrors({});
  };

  const handleEditClick = (item) => {
    setCODOrdersData({ ...codOrdersData, formId: item._id });
    setEditCODOrders({
      date: item.date.slice(0, 10),
      driverId: item.driverId,
      orders: item.orders,
      amount: item.amount,
    });
  };

  const currentCODOrdersTableData = useMemo(() => {
    const firstPageIndexCODOrders = (codOrdersData.currentPage - 1) * 10;
    const lastPageIndexCODOrders = firstPageIndexCODOrders + 10;
    return codOrdersData.ordersData?.slice(
      firstPageIndexCODOrders,
      lastPageIndexCODOrders
    );
  }, [codOrdersData.currentPage, codOrdersData.ordersData]);

  const sortedData = codOrdersData?.ordersData?.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Sub_Header />
      <div className="main-container" id="container">
        <div className="overlay"></div>
        <div className="search-overlay"></div>
        <SideBar />
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                <div className="widget-content widget-content-area br-6">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="" style={{ margin: "10px" }}>
                      COD Orders
                    </h5>
                    <a
                      href="/"
                      className="btn btn-warning btn-sm add-act-btn"
                      data-toggle="modal"
                      data-target="#addCODOrders"
                    >
                      Add
                    </a>
                  </div>
                  <div className=" row mt-4 mx-2">
                    <DateRangePicker
                      placeholder="Select Date Range"
                      styles={{ width: 200 }}
                      value={codOrdersFilterData.dateRange}
                      onChange={(value) => {
                        setCodOrdersFilterData({
                          ...codOrdersFilterData,
                          dateRange: value,
                        });
                      }}
                    />

                    <Select
                      isMulti
                      name="drivers"
                      options={driverList}
                      className="basic-multi-select mx-4 w-25"
                      classNamePrefix="select"
                      placeholder="Select Drivers"
                      value={codOrdersFilterData.drivers}
                      onChange={(value) => {
                        setCodOrdersFilterData({
                          ...codOrdersFilterData,
                          drivers: value,
                        });
                      }}
                    />
                    <CODExportToExcel excelData={sortedData} />
                  </div>

                  <br />
                  <div className="table-responsive mb-4 mt-4">
                    {currentCODOrdersTableData?.length > 0 ? (
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Driver Id</th>
                            <th>Driver Name</th>
                            <th>No of Orders</th>
                            <th>Amount</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentCODOrdersTableData.map((item) => {
                            return (
                              <tr key={item._id}>
                                <td>{item.date.slice(0, 10)}</td>
                                <td>EV{item.driverEvId}</td>
                                <td>{item.driverName}</td>
                                <td>{item.orders}</td>
                                <td>{item.amount}</td>
                                <td>
                                  <a
                                    href="/"
                                    onClick={() => handleEditClick(item)}
                                    className="btn btn-info btn-sm edit-btn edit-act-btn"
                                    data-toggle="modal"
                                    data-target="#editCODOrders"
                                  >
                                    Edit
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <p className="text-center">No data Available</p>
                    )}

                    {/* pagination */}
                    <Pagination
                      className="pagination-bar"
                      currentPage={codOrdersData.currentPage}
                      totalCount={10}
                      pageSize={10}
                      onPageChange={(page) =>
                        setCODOrdersData({
                          ...codOrdersData,
                          currentPage: page,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>

      {/* add model */}
      <div
        className="modal fade"
        id="addCODOrders"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addCODOrdersTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <form onSubmit={onSubmitHandler}>
              <div className="modal-header">
                <h5 className="modal-title" id="addHubModalCenterTitle">
                  Add COD Orders
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <RxCross2 />
                </button>
              </div>
              <div className="modal-body">
                <div
                  className="customer-address-class"
                  id="customer-address-div-id"
                >
                  <div className="row" id="defaultaddressdiv0">
                    <div className="col-12">
                      <div id="append-div-id">
                        <div className="form-group row">
                          <div className="form-group col-4 required">
                            <label>Select Date *</label>
                            <Input
                              type="date"
                              ref={dateFocus}
                              name="date"
                              value={addCODOrders?.date}
                              onChange={(value) =>
                                setAddCODOrders({
                                  ...addCODOrders,
                                  date: value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>{errors?.date}</span>
                          </div>
                          <div class="form-group col-md-4 required">
                            <label>Select Driver *</label>
                            <select
                              ref={driverIdFocus}
                              className="form-control selectpicker"
                              value={addCODOrders?.driverId}
                              onChange={(e) =>
                                setAddCODOrders({
                                  ...addCODOrders,
                                  driverId: e.target.value,
                                })
                              }
                            >
                              <option value="">Select Driver</option>
                              {driverList.map((item) => {
                                return (
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            <span style={{ color: "red" }}>
                              {errors?.driverId}
                            </span>
                          </div>
                        </div>

                        <div className="form-group row">
                          <div className="form-group col-6 required">
                            <label htmlFor="orders">No Of Orders</label>
                            <Input
                              type="number"
                              min="0"
                              className="form-control"
                              placeholder="No of Orders"
                              name="orders"
                              ref={ordersFocus}
                              id="orders"
                              value={addCODOrders?.orders}
                              onChange={(value) =>
                                setAddCODOrders({
                                  ...addCODOrders,
                                  orders: value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.orders}
                            </span>
                          </div>
                          <div className="form-group col-6 required">
                            <label>Total Amount</label>
                            <Input
                              type="number"
                              min="0"
                              className="form-control"
                              placeholder="No of Done Parcel"
                              name="amount"
                              ref={amountFocus}
                              id="amount"
                              value={addCODOrders?.amount}
                              onChange={(value) =>
                                setAddCODOrders({
                                  ...addCODOrders,
                                  amount: value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.amount}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="reset"
                  data-dismiss="modal"
                  className="btn btn-danger "
                  onClick={() => setEditFormEditable(false)}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success save-btn">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* update model */}
      <div
        className="modal fade"
        id="editCODOrders"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editCODOrdersTitle"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <form onSubmit={onUpdateHandler}>
              <div className="modal-header">
                <h5 className="modal-title" id="editCODOrdersTitle">
                  COD Orders
                </h5>
                <button
                  type="button"
                  className="btn btn-primary view-btn"
                  hidden={editButtonHide}
                  onClick={() => setEditFormEditable(true)}
                >
                  Edit
                </button>
              </div>

              <div className="modal-body">
                <div
                  className="customer-address-class"
                  id="customer-address-div-id"
                >
                  <div className="row" id="defaultaddressdiv0">
                    <div className="col-12">
                      <div id="append-div-id">
                        <div className="form-group row">
                          <div className="form-group col-4 required">
                            <label>Select Date *</label>
                            <Input
                              disabled={disabled}
                              type="date"
                              ref={editdateFocus}
                              name="date"
                              value={editCODOrders?.date}
                              onChange={(value) =>
                                setEditCODOrders({
                                  ...editCODOrders,
                                  date: value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>{errors?.date}</span>
                          </div>

                          <div class="form-group col-md-4 required">
                            <label>Select Driver *</label>
                            <select
                              disabled={disabled}
                              ref={editdriverIdFocus}
                              className="form-control selectpicker"
                              value={editCODOrders?.driverId}
                              onChange={(e) =>
                                setEditCODOrders({
                                  ...editCODOrders,
                                  driverId: e.target.value,
                                })
                              }
                            >
                              <option value="">Select Driver</option>
                              {driverList.map((item) => {
                                return (
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            <span style={{ color: "red" }}>
                              {errors?.driverId}
                            </span>
                          </div>
                        </div>

                        <div className="form-group row">
                          <div className="form-group col-6 required">
                            <label htmlFor="orders">No Of Orders</label>
                            <Input
                              disabled={disabled}
                              type="number"
                              min="0"
                              className="form-control"
                              placeholder="No of Pickup Parcel"
                              name="orders"
                              ref={editordersFocus}
                              id="orders"
                              value={editCODOrders?.orders}
                              onChange={(value) =>
                                setEditCODOrders({
                                  ...editCODOrders,
                                  orders: value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.orders}
                            </span>
                          </div>
                          <div className="form-group col-6 required">
                            <label>Enter Total Amount</label>
                            <Input
                              disabled={disabled}
                              type="number"
                              min="0"
                              className="form-control"
                              placeholder="No of Done Parcel"
                              name="amount"
                              ref={editamountFocus}
                              id="amount"
                              value={editCODOrders?.amount}
                              onChange={(value) =>
                                setEditCODOrders({
                                  ...editCODOrders,
                                  amount: value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.amount}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary view-btn"
                  hidden={submitButtonHide}
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CODOrders;
