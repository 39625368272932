import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { format } from "date-fns";
import "../../../assets/css/users/user-profile.css";
import "../../../assets/plugins/table/datatable/datatables.css";
import "../../../assets/plugins/table/datatable/dt-global_style.css";
import * as action from "../../../action/TechnicianVehiclesAdmin/vehicle_action";
import $ from "jquery";
import { renderToString } from "react-dom/server";
const Vehicle_List = (props) => {
  $.DataTable = require("datatables.net");
  const dispatch = useDispatch();
  const [vehicleList, setVihicleList] = useState([]);
  const changeDateFormate = (date) => {
    if (date) {
      let new_date = String(date);
      let ndate = new_date.split("T");
      let format_date = format(new Date(ndate[0]), "dd MMM yyyy");
      let format_time = new Date(date).toLocaleTimeString("en-US");
      return format_date + "<br/>" + format_time;
    }
  };
  useEffect(() => {
    dispatch(action.Vehicle_list())
      .then((data) => {
        let newVehicleList = [];
        data.data.map((item) => {
          let driver_details = "";
          if (item.vehicleDriverData) {
            driver_details =
              item.vehicleDriverData.name +
              "<br/>" +
              changeDateFormate(item.vehicleDriverData.vehicleAssignDate);
          }
          let vehicleHubAdmin = "";
          if (item.vehicleHubData.hubadmin) {
            vehicleHubAdmin = item.vehicleHubData.hubadmin.name;
          }
          if (item.isRepaired === 2) {
            newVehicleList.push([
              item.sr_number ? item.sr_number : null,
              item.registered === 1
                ? item.number
                : item.name
                ? item.name
                : null,
              vehicleHubAdmin,
              item.ownerName,
              item.type,
              driver_details,
              item.isRepaired,
              item._id,
              item,
            ]);
          }
        });
        setVihicleList(newVehicleList);
      })
      .catch({});
  }, [props.reload]);
  const tableName = "repaired-vehicle-list";
  useEffect(() => {
    const table = $(`#${tableName}`).DataTable({
      dom: "Bfrtip",
      buttons: ["copyHtml5", "excelHtml5", "pdfHtml5", "csvHtml5"],
      data: vehicleList,
      columns: [
        { title: "Sr. Number" },
        { title: "Vehicle Number" },
        { title: "Vehicle Hub Admin" },
        { title: "Owner Name" },
        { title: "Type (In Wheeler)" },
        { title: "Driver" },
        { title: "Status" },
      ],
      columnDefs: [
        {
          targets: [6],
          className: "center",
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span
                style={{ cursor: "pointer" }}
                className={
                  rowData[6] === 1
                    ? "badge badge-pill badge-warning status-act-btn"
                    : rowData[6] === 2
                    ? "badge badge-pill badge-success status-act-btn"
                    : "badge badge-pill badge-danger status-act-btn"
                }
              >
                {" "}
                {rowData[6] === 1
                  ? "Working on"
                  : rowData[6] === 2
                  ? "Repaired"
                  : "Assigned"}{" "}
              </span>,
              td
            ),
        },
      ],
      destroy: true, // I think some clean up is happening here
      searching: true,
      oLanguage: {
        oPaginate: {
          sPrevious:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>',
          sNext:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>',
        },
        // sInfo: "Showing page _PAGE_ of _PAGES_",
        sSearch:
          '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
        sSearchPlaceholder: "Search...",
        sLengthMenu: "Results :  _MENU_",
      },
    });
    // Extra step to do extra clean-up.
    return function () {
      table.destroy();
    };
  }, [vehicleList]);
  return (
    <>
      {/* <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover  theme="light"/> */}
      {/* <h5 className="" style={{margin: '10px'}}>Repaired Vehicle List</h5> */}
      <div className="table-responsive mb-4 mt-4">
        <table
          id="repaired-vehicle-list"
          className="table table-hover"
          style={{ width: "100%" }}
        >
          <thead>
            <tr>
              <th>Sr. Number</th>
              <th>Vehicle Number</th>
              <th>Vehicle Hub Admin</th>
              <th>Owner Name</th>
              <th>Type (In Wheeler)</th>
              <th>Driver</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </>
  );
};
export default Vehicle_List;
