import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as action from "../../../action/Customer/customer_action";
import { DateRangePicker } from "rsuite";
import { format } from "date-fns";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import "rsuite/dist/rsuite.min.css";
import "../../../assets/css/users/user-profile.css";
import "../../../assets/plugins/table/datatable/datatables.css";
import "../../../assets/plugins/table/datatable/dt-global_style.css";
import $ from "jquery";
import moment from "moment";
import { baseURL } from "../../../service/baseService";
const GenerateInvoice = (props) => {
  $.DataTable = require("datatables.net");
  const dispatch = useDispatch();
  const [hiddenMonthly, sethiddenMonthly] = useState(true);
  const [hiddenPerParcel, sethiddenPerParcel] = useState(true);
  const [extraPaymentMain, setExtraPaymentMain] = useState(true);
  const [extraDeductionMain, setExtraDeductionMain] = useState(true);
  const [extraPaymentMainPerParcel, setExtraPaymentMainPerParcel] =
    useState(true);
  const [extraDeductionMainPerParcel, setExtraDeductionMainPerParcel] =
    useState(true);
  const [exportPdfBtn, setExportPdfBtn] = useState(false);
  const [showExtraPaymentBtn, setShowExtraPaymentBtn] = useState(false);
  const [saveExtraPaymentBtn, setSaveExtraPaymentBtn] = useState(true);
  const [cancelExtraPaymentBtn, setCancelExtraPaymentBtn] = useState(true);
  const [exportPdfPerParcelBtn, setExportPdfPerParcelBtn] = useState(false);
  const [showExtraPaymentPerParcelBtn, setShowExtraPaymentPerParcelBtn] =
    useState(false);
  const [saveExtraPaymentPerParcelBtn, setSaveExtraPaymentPerParcelBtn] =
    useState(true);
  const [cancelExtraPaymentPerParcelBtn, setCancelExtraPaymentPerParcelBtn] =
    useState(true);
  const [invoiceData, setInvoiceData] = useState({});
  const [latestCustomerInvoiceNumber, setLatestCustomerInvoiceNumber] =
    useState("");
  const [downloadInvoiceDivId, setDownloadInvoiceDivId] = useState("");
  const [monthlyInvoiceData, setmonthlyInvoiceData] = useState([]);
  const [perParcelIdInvoiceData, setPerParcelIdInvoiceData] = useState([]);
  var date = new Date();
  const [input, setInput] = useState([
    new Date(date.getFullYear(), date.getMonth(), 1),
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
  ]);
  const clickShowExtrabutton = () => {
    setExtraPaymentMain(false);
    setExtraDeductionMain(false);
    setExportPdfBtn(true);
    setShowExtraPaymentBtn(true);
    setSaveExtraPaymentBtn(false);
    setCancelExtraPaymentBtn(false);
  };
  const clickShowExtraPerParcelButton = () => {
    setExtraPaymentMainPerParcel(false);
    setExtraDeductionMainPerParcel(false);
    setExportPdfPerParcelBtn(true);
    setShowExtraPaymentPerParcelBtn(true);
    setSaveExtraPaymentPerParcelBtn(false);
    setCancelExtraPaymentPerParcelBtn(false);
  };
  const clickHideExtrabutton = () => {
    setExportPdfBtn(false);
    setShowExtraPaymentBtn(false);
    setSaveExtraPaymentBtn(true);
    setCancelExtraPaymentBtn(true);
  };
  const clickHideExtraPerParcelbutton = () => {
    setExportPdfPerParcelBtn(false);
    setShowExtraPaymentPerParcelBtn(false);
    setSaveExtraPaymentPerParcelBtn(true);
    setCancelExtraPaymentPerParcelBtn(true);
  };
  const clickCancelExtrabutton = () => {
    setExtraPaymentMain(true);
    setExtraDeductionMain(true);
    setExportPdfBtn(false);
    setShowExtraPaymentBtn(false);
    setSaveExtraPaymentBtn(true);
    setCancelExtraPaymentBtn(true);
  };
  const clickCancelExtraPerParcelbutton = () => {
    setExtraPaymentMainPerParcel(true);
    setExtraDeductionMainPerParcel(true);
    setExportPdfPerParcelBtn(false);
    setShowExtraPaymentPerParcelBtn(false);
    setSaveExtraPaymentPerParcelBtn(true);
    setCancelExtraPaymentPerParcelBtn(true);
  };
  const changeDateFormate = (date) => {
    if (date) {
      let new_date = String(date);
      let ndate = new_date.split("T");
      let format_date = format(new Date(ndate[0]), "dd MMM yyyy");
      return format_date;
    }
  };
  const clickExportPDFbutton = (divId) => {
    dispatch(action.customer_latest_invoice_no(props.customer))
      .then((data) => {
        setLatestCustomerInvoiceNumber(data.data.latestCustomerInvoiceNumber);
        setDownloadInvoiceDivId(divId);
      })
      .catch((error) => {});
  };
  const createPDFfromHTML = (id, filename, wantPdfData) => {
    var HTML_Width = id.offsetWidth;
    var HTML_Height = id.offsetHeight;
    var top_left_margin = 15;
    var PDF_Width = HTML_Width + top_left_margin * 2;
    var PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;

    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    return html2canvas(id).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const pdf = new jsPDF("p", "pt", [PDF_Width, PDF_Height]);
      pdf.addImage(
        imgData,
        "JPG",
        top_left_margin,
        top_left_margin,
        canvas_image_width,
        canvas_image_height
      );
      for (var i = 1; i <= totalPDFPages; i++) {
        pdf.addPage(PDF_Width, PDF_Height);
        pdf.addImage(
          imgData,
          "JPG",
          top_left_margin,
          -(PDF_Height * i) + top_left_margin * 4,
          canvas_image_width,
          canvas_image_height
        );
      }
      if (wantPdfData) return pdf.output("blob");
      else pdf.save(`${filename}.pdf`);
    });
  };
  const uploadPdfFile = (filename, fileData) => {
    const formData = new FormData();
    formData.append("pdf", fileData, `${filename}`);
    dispatch(action.upload_customer_invoice(formData))
      .then((data) => {
        if (data?.data) {
          const customerInvoicePayload = {
            invoiceNumber: latestCustomerInvoiceNumber,
            invoiceDate: format(new Date(), "yyyy-MM-dd"),
            invoiceUrl: data.data.url,
            invoiceData,
          };
          addCustomerInvoice(props.customer, customerInvoicePayload);
        } else {
          toast.error(data.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  const formatDate = (inputDate) => {
    return moment(inputDate).format("DD MMM YYYY");
  };
  const download_file = (fileURL, fileName) => {
    let save = document.createElement("a");
    save.href = baseURL + fileURL;
    save.target = "_blank";
    let filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
    save.download = fileName || filename;
    $("#invoiceModalCenter .close").click();
    save.click();
  };
  const addCustomerInvoice = (custId, invoiceData) => {
    dispatch(action.add_customer_invoice(custId, invoiceData))
      .then((data) => {
        if (data?.data) {
          toast.success(data.msg);
          const downloadUrl = data.data.invoiceUrl;
          download_file(downloadUrl, downloadUrl.split("/").splice(-1));
        } else {
          toast.error(data.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  const downloadCustomerInvoice = async () => {
    const input = document.getElementById(downloadInvoiceDivId);

    const filename = `${invoiceData.customerName}-${formatDate(
      invoiceData.startDate
    )}-${formatDate(invoiceData.endDate)}`;

    const pdfData = await createPDFfromHTML(input, filename, true);
    await uploadPdfFile(`${filename}.pdf`, pdfData);
  };
  const onsubmitHandler = (e) => {
    e.preventDefault();
    dispatch(
      action.generate_customer_invoice(props.customer, input[0], input[1])
    )
      .then((data) => {
        toast.success(data.msg);
        setInvoiceData(data.data);
        if (parseInt(data.data.customerPaymentType) === 1) {
          setmonthlyInvoiceData(data.data.invoiceDataArr);
          sethiddenMonthly(false);
          sethiddenPerParcel(true);
        } else {
          setPerParcelIdInvoiceData(data.data.invoiceDataArr);
          sethiddenMonthly(true);
          sethiddenPerParcel(false);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  useEffect(() => {
    const tableName = "customer-monthly-invoice-table-list";
    const table = $(`#${tableName}`).DataTable({
      bInfo: false,
      paging: false,
      bPaginate: false,
      searching: false,
    });
    // Extra step to do extra clean-up.
    return function () {
      table.destroy();
    };
  }, [monthlyInvoiceData]);
  useEffect(() => {
    const tableName = "customer-per-parcel-invoice-table-list";
    const table = $(`#${tableName}`).DataTable({
      bInfo: false,
      paging: false,
      bPaginate: false,
      searching: false,
    });
    // Extra step to do extra clean-up.
    return function () {
      table.destroy();
    };
  }, [perParcelIdInvoiceData]);
  return (
    <>
      <form onSubmit={onsubmitHandler}>
        <div className="form-group row col-md-12">
          {/* <input id="invoiceDateFilter" className="form-control flatpickr flatpickr-input active col-md-3" type="date" placeholder="Select Date.." autocomplete="off" /> */}
          <DateRangePicker
            value={input}
            onChange={setInput}
            style={{ width: 300 }}
            placeholder="Select Date Range"
          />
          <div className="form-group" id="\">
            <button
              type="submit"
              className="btn btn-success mt-1 ml-3 view-btn"
              onclick="generateInvoice(event)"
            >
              Generate Invoice
            </button>
          </div>
        </div>
      </form>
      <div hidden={hiddenMonthly} id="invoiceDetailsMonthlyId">
        <div id="invoiceDetailsMonthlyExportDivId">
          <div className="mt-5 mb-5 invisible">Invoice Details</div>
          <table
            id="customer-monthly-invoice-table-list"
            className="table table-hover"
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th>Customer Name:</th>
                <th colspan="4" id="customerNameId">
                  {invoiceData.customerName}
                </th>
              </tr>
              <tr>
                <th>GSTIN:</th>
                <th colspan="4" id="customerGstNumberId">
                  {invoiceData.customerGstNumber}
                </th>
              </tr>
              <tr>
                <th>SAC Code:</th>
                <th colspan="4" id="customerSacCodeId">
                  9956
                </th>
              </tr>
              <tr>
                <th>Date:</th>
                <th colspan="4" id="invoiceStartEndDateId">
                  {changeDateFormate(invoiceData.startDate)} To{" "}
                  {changeDateFormate(invoiceData.endDate)}
                </th>
              </tr>
              <tr>
                <th>Invoice Date:</th>
                <th colspan="4" id="invoiceDateId">
                  {changeDateFormate(invoiceData.invoiceDate)}
                </th>
              </tr>
              <tr>
                <th colspan="5"></th>
              </tr>
              <tr>
                <th>Vehicle Number</th>
                <th>Payment Amount (INR)</th>
                <th>CGST 9% (INR)</th>
                <th>SGST 9% (INR)</th>
                <th>Total (INR)</th>
              </tr>
            </thead>
            <tbody>
              {monthlyInvoiceData.map((invoiceItem) => (
                <>
                  <tr>
                    <td>{invoiceItem.vehicleNumber}</td>
                    <td>{invoiceItem.planPriceCustomer}</td>
                    <td>{invoiceItem.cgst}</td>
                    <td>{invoiceItem.sgst}</td>
                    <td>{invoiceItem.total}</td>
                  </tr>
                </>
              ))}
            </tbody>
            <tfoot>
              <tr
                id="extraPaymentMain"
                hidden={extraPaymentMain}
                className="extra-row-border"
              >
                <th id="extraPaymentKeyId" contenteditable>
                  Enter Extra Payment For
                </th>
                <th colspan="3"></th>
                <th
                  id="extraPaymentId"
                  contenteditable
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.keyCode === 8;"
                >
                  0
                </th>
              </tr>
              <tr
                id="extraDeductionMain"
                hidden={extraDeductionMain}
                className="extra-row-border"
              >
                <th id="extraDeductionKeyId">Deduction (INR)</th>
                <th colspan="3"></th>
                <th
                  id="extraDeductionId"
                  contenteditable
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.keyCode === 8;"
                >
                  0
                </th>
              </tr>
              <tr>
                <th colspan="4">Overtime Payment (INR)</th>
                <th id="overtimeId">{invoiceData.overtimePaymentAmount}</th>
              </tr>
              <tr>
                <th>Total Payment Amount (INR)</th>
                <th colspan="3"></th>
                <th id="totalPaymentAmountId">
                  {invoiceData.totalPaymentAmount}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="form-group" id="exportButtons">
          <button
            hidden={exportPdfBtn}
            type="button"
            className="btn btn-dark mt-3 mr-2"
            data-toggle="modal"
            data-target="#invoiceModalCenter"
            onClick={() =>
              clickExportPDFbutton("invoiceDetailsMonthlyExportDivId")
            }
          >
            Export PDF
          </button>
          <button
            hidden={showExtraPaymentBtn}
            type="button"
            className="btn btn-warning mt-3 mr-2"
            onClick={() => clickShowExtrabutton()}
          >
            Update Extra Payment
          </button>
          <button
            hidden={saveExtraPaymentBtn}
            type="button"
            className="btn btn-warning mt-3 mr-2"
            onClick={() => clickHideExtrabutton()}
          >
            Save Extra Payment
          </button>
          <button
            hidden={cancelExtraPaymentBtn}
            type="button"
            className="btn mt-3"
            onClick={() => clickCancelExtrabutton()}
          >
            Cancel
          </button>
        </div>
      </div>
      <div hidden={hiddenPerParcel} id="invoiceDetailsPerParcelId">
        <div id="invoiceDetailsPerParcelExportDivId">
          <div className="mt-5 mb-5 invisible">Invoice Details</div>
          <table
            id="customer-per-parcel-invoice-table-list"
            className="table table-hover"
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th>Customer Name:</th>
                <th colspan="6" id="customerNamePerParcelId">
                  {invoiceData.customerName}
                </th>
              </tr>
              <tr>
                <th>GSTIN:</th>
                <th colspan="6" id="customerGstNumberPerParcelId">
                  {invoiceData.customerGstNumber}
                </th>
              </tr>
              <tr>
                <th>SAC Code:</th>
                <th colspan="4" id="customerSacCodeId">
                  9956
                </th>
              </tr>
              <tr>
                <th>Date:</th>
                <th colspan="6" id="invoiceStartEndDatePerParcelId">
                  {changeDateFormate(invoiceData.startDate)} To{" "}
                  {changeDateFormate(invoiceData.endDate)}
                </th>
              </tr>
              <tr>
                <th>Invoice Date:</th>
                <th colspan="6" id="invoiceDatePerParcelId">
                  {changeDateFormate(invoiceData.invoiceDate)}
                </th>
              </tr>
              <tr>
                <th colspan="7"></th>
              </tr>
              <tr>
                <th>Vehicle Number</th>
                <th>Total Parcel</th>
                <th>Rate Per Parcel (INR)</th>
                <th>Payment Amount (INR)</th>
                <th>CGST 9% (INR)</th>
                <th>SGST 9% (INR)</th>
                <th>Total (INR)</th>
              </tr>
            </thead>
            <tbody>
              {perParcelIdInvoiceData.map((invoiceItem) => (
                <>
                  <tr>
                    <td>{invoiceItem.vehicleNumber}</td>
                    <td>{invoiceItem.parcelCount}</td>
                    <td>{invoiceItem.planPriceCustomer}</td>
                    <td>{invoiceItem.totalPlanPriceCustomer}</td>
                    <td>{invoiceItem.cgst}</td>
                    <td>{invoiceItem.sgst}</td>
                    <td>{invoiceItem.total}</td>
                  </tr>
                </>
              ))}
            </tbody>
            <tfoot>
              <tr
                id="extraPaymentMainPerParcel"
                hidden={extraPaymentMainPerParcel}
                className="extra-row-border"
              >
                <th id="extraPaymentKeyPerParcelId" contenteditable>
                  Enter Extra Payment For
                </th>
                <th colspan="5"></th>
                <th
                  id="extraPaymentPerParcelId"
                  contenteditable
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.keyCode === 8;"
                  onkeyup="updateValuesInInvoice('extraPaymentMainPerParcel')"
                >
                  0
                </th>
              </tr>
              <tr
                id="extraDeductionMainPerParcel"
                hidden={extraDeductionMainPerParcel}
                className="extra-row-border"
              >
                <th id="extraDeductionKeyPerParcelId">Deduction (INR)</th>
                <th colspan="5"></th>
                <th
                  id="extraDeductionPerParcelId"
                  contenteditable
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.keyCode === 8;"
                  onkeyup="updateValuesInInvoice('extraPaymentMainPerParcel')"
                >
                  0
                </th>
              </tr>
              <tr>
                <th colspan="6">Overtime Payment (INR)</th>
                <th id="overtimePerParcelId">0</th>
              </tr>
              <tr>
                <th>Total Payment Amount (INR)</th>
                <th colspan="5"></th>
                <th id="totalPaymentAmountPerParcelId">0</th>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="form-group" id="exportButtons">
          <button
            hidden={exportPdfPerParcelBtn}
            type="button"
            className="btn btn-dark mt-3"
            data-toggle="modal"
            data-target="#invoiceModalCenter"
            onClick={() =>
              clickExportPDFbutton("invoiceDetailsPerParcelExportDivId")
            }
          >
            Export PDF
          </button>{" "}
          &nbsp;
          <button
            hidden={showExtraPaymentPerParcelBtn}
            type="button"
            className="btn btn-warning mt-3"
            onClick={() => clickShowExtraPerParcelButton()}
          >
            Update Extra Payment
          </button>{" "}
          &nbsp;
          <button
            hidden={saveExtraPaymentPerParcelBtn}
            type="button"
            className="btn btn-warning mt-3"
            onClick={() => clickHideExtraPerParcelbutton()}
          >
            Save Extra Payment
          </button>{" "}
          &nbsp;
          <button
            hidden={cancelExtraPaymentPerParcelBtn}
            type="button"
            className="btn mt-3"
            onClick={() => clickCancelExtraPerParcelbutton()}
          >
            Cancel
          </button>
        </div>
      </div>
      <div
        className="modal fade"
        id="invoiceModalCenter"
        role="dialog"
        aria-labelledby="invoiceModalCenterTitle"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <form>
              <div className="modal-header">
                <h5 className="modal-title" id="invoiceModalCenterTitle">
                  Invoice
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <svg
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              </div>

              <div className="modal-body">
                <div
                  className="customer-invoice-class"
                  id="customer-invoice-div-id"
                >
                  <div className="form-group" id="defaultinvoicediv">
                    <div className="form-group col-md-6">
                      <label for="inputInvoiceNumber">Invoice Number *</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputInvoiceNumber"
                        placeholder="Enter Invoice Number"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.keyCode === 8;"
                        maxlength="20"
                        required
                        value={latestCustomerInvoiceNumber}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label for="inputInvoiceDate">Invoice Date *</label>
                      <input
                        id="inputInvoiceDate"
                        className="form-control flatpickr flatpickr-input active"
                        type="date"
                        value={format(new Date(), "yyyy-MM-dd")}
                        placeholder="Select Date.."
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button className="btn" data-dismiss="modal">
                  <i className="flaticon-cancel-12"></i>Discard
                </button>
                <button
                  type="button"
                  id="downloadInvoiceBtn"
                  className="btn btn-primary view-btn"
                  onClick={() => downloadCustomerInvoice()}
                >
                  Download
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default GenerateInvoice;
