import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Header from "../../DashBoard/Header";
import Sub_Header from "../../DashBoard/Sub_Header";
import SideBar from "../../DashBoard/SideBar";
import Footer from "../../DashBoard/Footer";
import { ToastContainer } from "react-toastify";
import * as action from "../../../action/Dashboard/dashboard_action";

import SwiggyTab from "./components/SwiggyTab/SwiggyTab";
import ZomatoTab from "./components/ZomatoTab/ZomatoTab";
import BigBasketTab from "./components/BigBasketTab/BigBasketTab";
import BBNowTab from "./components/BBNowTab/BBNowTab";
import FlipkartTab from "./components/FlipkartTab/FlipkartTab";
import BlueDartTab from "./components/BlueDartTab/BlueDartTab";

const BillingAnalysis = () => {
  const dispatch = useDispatch();

  const [cityList, setCityList] = useState([]);
  const [errors, setErrors] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [editButtonHide, setEditButtonHide] = useState(false);
  const [submitButtonHide, setSubmitButtonHide] = useState(true);

  const setEditFormEditable = (type) => {
    if (type) {
      setDisabled(false);
      setSubmitButtonHide(false);
      setEditButtonHide(true);
    } else {
      setDisabled(true);
      setSubmitButtonHide(true);
      setEditButtonHide(false);
    }
  };

  useEffect(() => {
    dispatch(action.dashboard_city_list())
      .then((data) => {
        let newCustomerList = [];
        data.data.map((item) => {
          newCustomerList.push({ value: item._id, label: item.cityName });
        });
        setCityList(newCustomerList);
      })
      .catch({});
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Sub_Header />
      <div className="main-container" id="container">
        <div className="overlay"></div>
        <div className="search-overlay"></div>
        <SideBar />
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                <div className="widget-content widget-content-area br-6">
                  <h5 className="" style={{ margin: "10px" }}>
                    Billing Analysis
                  </h5>
                  <ul
                    className="nav nav-tabs  mb-3 mt-3"
                    id="simpletab"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="swiggy-tab"
                        data-toggle="tab"
                        href="#swiggy"
                        role="tab"
                        aria-controls="swiggy"
                        aria-selected="true"
                      >
                        Swiggy
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="zomato-tab"
                        data-toggle="tab"
                        href="#zomato"
                        role="tab"
                        aria-controls="zomato"
                        aria-selected="false"
                      >
                        Zomato
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="bigBasket-tab"
                        data-toggle="tab"
                        href="#bigBasket"
                        role="tab"
                        aria-controls="bigBasket"
                        aria-selected="false"
                      >
                        BigBasket
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="bbNow-tab"
                        data-toggle="tab"
                        href="#bbNow"
                        role="tab"
                        aria-controls="bbNow"
                        aria-selected="false"
                      >
                        BB Now
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="flipkart-tab"
                        data-toggle="tab"
                        href="#flipkart"
                        role="tab"
                        aria-controls="flipkart"
                        aria-selected="false"
                      >
                        Flipkart
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="blueDart-tab"
                        data-toggle="tab"
                        href="#blueDart"
                        role="tab"
                        aria-controls="blueDart"
                        aria-selected="false"
                      >
                        BlueDart
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content" id="simpletabContent">
                    {/* swiggy tab */}
                    <div
                      className="tab-pane fade show active"
                      id="swiggy"
                      role="tabpanel"
                      aria-labelledby="swiggy-tab"
                    >
                      <SwiggyTab
                        cityList={cityList}
                        errors={errors}
                        setErrors={setErrors}
                        disabled={disabled}
                        setEditFormEditable={setEditFormEditable}
                        editButtonHide={editButtonHide}
                        submitButtonHide={submitButtonHide}
                      />
                    </div>
                    {/* zomato tab */}
                    <div
                      className="tab-pane "
                      id="zomato"
                      role="tabpanel"
                      aria-labelledby="zomato-tab"
                    >
                      <ZomatoTab
                        cityList={cityList}
                        errors={errors}
                        setErrors={setErrors}
                        disabled={disabled}
                        setEditFormEditable={setEditFormEditable}
                        editButtonHide={editButtonHide}
                        submitButtonHide={submitButtonHide}
                      />
                    </div>
                    {/* big basket tab */}
                    <div
                      className="tab-pane "
                      id="bigBasket"
                      role="tabpanel"
                      aria-labelledby="bigBasket-tab"
                    >
                      <BigBasketTab
                        cityList={cityList}
                        errors={errors}
                        setErrors={setErrors}
                        disabled={disabled}
                        setEditFormEditable={setEditFormEditable}
                        editButtonHide={editButtonHide}
                        submitButtonHide={submitButtonHide}
                      />
                    </div>
                    {/* BB Now tab */}
                    <div
                      className="tab-pane "
                      id="bbNow"
                      role="tabpanel"
                      aria-labelledby="bbNow-tab"
                    >
                      <BBNowTab
                        cityList={cityList}
                        errors={errors}
                        setErrors={setErrors}
                        disabled={disabled}
                        setEditFormEditable={setEditFormEditable}
                        editButtonHide={editButtonHide}
                        submitButtonHide={submitButtonHide}
                      />
                    </div>
                    {/* Flipkart tab */}
                    <div
                      className="tab-pane "
                      id="flipkart"
                      role="tabpanel"
                      aria-labelledby="flipkart-tab"
                    >
                      <FlipkartTab
                        cityList={cityList}
                        errors={errors}
                        setErrors={setErrors}
                        disabled={disabled}
                        setEditFormEditable={setEditFormEditable}
                        editButtonHide={editButtonHide}
                        submitButtonHide={submitButtonHide}
                      />
                    </div>
                    {/* BlueDart Now tab */}
                    <div
                      className="tab-pane "
                      id="blueDart"
                      role="tabpanel"
                      aria-labelledby="blueDart-tab"
                    >
                      <BlueDartTab
                        cityList={cityList}
                        errors={errors}
                        setErrors={setErrors}
                        disabled={disabled}
                        setEditFormEditable={setEditFormEditable}
                        editButtonHide={editButtonHide}
                        submitButtonHide={submitButtonHide}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </>
  );
};

export default BillingAnalysis;
