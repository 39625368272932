import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import Chart from "react-apexcharts";
import { Input } from "rsuite";
import * as action from "../../../../action/Dashboard/dashboard_action";
import {
  TbCircleArrowDownRightFilled,
  TbCircleArrowUpRightFilled,
} from "react-icons/tb";

const RevenuePerformance = ({ customerList, cityList }) => {
  const dispatch = useDispatch();

  // months list
  const months = [
    {
      value: 1,
      label: "January",
    },
    {
      value: 2,
      label: "February",
    },
    {
      value: 3,
      label: "March",
    },
    {
      value: 4,
      label: "April",
    },
    {
      value: 5,
      label: "May",
    },
    {
      value: 6,
      label: "June",
    },
    {
      value: 7,
      label: "July",
    },
    {
      value: 8,
      label: "August",
    },
    {
      value: 9,
      label: "September",
    },
    {
      value: 10,
      label: "October",
    },
    {
      value: 11,
      label: "November",
    },
    {
      value: 12,
      label: "December",
    },
  ];

  //current get month
  const currentMonth = new Date().getMonth();

  //current Year
  const currentYear = {
    value: new Date().getFullYear(),
    label: new Date().getFullYear(),
  };

  // get last month-year in format YYYY-MM
  const getLastMonthYear = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() - 1;

    // Adjust for month wrapping (e.g., January is 0, so December should be 11)
    const adjustedMonth = month < 0 ? 11 : month;

    const formattedDate = `${year}-${String(adjustedMonth + 1).padStart(
      2,
      "0"
    )}`;
    return formattedDate;
  };

  // rupee display to indian number system
  function numberWithCommas(x) {
    return x.toString().split(".")[0].length > 3
      ? x
          .toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  }

  //revenue performance
  const [selectedRevenueMonth, setSelectedRevenueMonth] = useState();
  const [selectedRevenueYear, setSelectedRevenueYear] = useState();
  const [revenuePerformance, setReveneuPerformance] = useState({
    totalRevenue: 0,
    data: [],
  });

  const [selectedRevenueFilterForFirst, setSelectedRevenueFilterForFirst] =
    useState({
      yearMonth: getLastMonthYear(),
      customer: "",
      city1: "",
      city2: "",
    });

  const [selectedRevenueFilterForSecond, setSelectedRevenueFilterForSecond] =
    useState({
      yearMonth: getLastMonthYear(),
      customer: "",
      city1: "",
      city2: "",
    });

  const [
    revenueCustomerWiseCityWiseFirst,
    setRevenueCustomerWiseCityWiseFirst,
  ] = useState({
    firstRevenue: 0,
    firstCity: "",
    secondRevenue: 0,
    secondCity: "",
  });

  const [
    revenueCustomerWiseCityWiseSecond,
    setRevenueCustomerWiseCityWiseSecond,
  ] = useState({
    firstRevenue: 0,
    firstCity: "",
    secondRevenue: 0,
    secondCity: "",
  });

  const [updatedcityList, setUpdatedCityList] = useState({
    cityList1: [],
    cityList2: [],
  });

  const [updatedcityListForSecond, setUpdatedCityListForSecond] = useState({
    cityList1: [],
    cityList2: [],
  });

  // revenue performance - client wise
  useEffect(() => {
    dispatch(
      action.dashboard_revenue_performace(
        selectedRevenueYear ? selectedRevenueYear.value : currentYear.value,
        selectedRevenueMonth
          ? selectedRevenueMonth.value
          : months[currentMonth - 1].value
      )
    )
      .then((data) => {
        let totalRevenue = data?.data?.totalRevenue;
        let revenuePerformanceData = data?.data?.result;

        setReveneuPerformance({
          totalRevenue: totalRevenue,
          data: revenuePerformanceData,
        });
      })
      .catch({});
  }, [selectedRevenueYear, selectedRevenueMonth]);

  // revenue performance data - radial chart1
  useEffect(() => {
    dispatch(
      action.dashboard_revenue_cities_wise(selectedRevenueFilterForFirst)
    )
      .then((data) => {
        let revenueData = data?.data;

        revenueData?.sort((a, b) => b.diffPercentage - a.diffPercentage);

        if (revenueData?.length === 2) {
          setRevenueCustomerWiseCityWiseFirst({
            firstRevenue: +revenueData[0].diffPercentage.toFixed(2),
            firstCity: revenueData[0].city,
            secondRevenue: +revenueData[1].diffPercentage.toFixed(2),
            secondCity: revenueData[1].city,
          });
        } else if (revenueData?.length === 1) {
          setRevenueCustomerWiseCityWiseFirst({
            firstRevenue: +revenueData[0].diffPercentage.toFixed(2),
            firstCity: revenueData[0].city,
            secondRevenue: 0,
            secondCity: "",
          });
        } else if (revenueData?.length === 0) {
          setRevenueCustomerWiseCityWiseFirst({
            firstRevenue: 0,
            firstCity: "",
            secondRevenue: 0,
            secondCity: "",
          });
        }
      })
      .catch({});
  }, [selectedRevenueFilterForFirst]);

  // revenue performance data - radial chart2
  useEffect(() => {
    dispatch(
      action.dashboard_revenue_cities_wise(selectedRevenueFilterForSecond)
    )
      .then((data) => {
        let revenueData = data?.data;

        revenueData?.sort((a, b) => b.diffPercentage - a.diffPercentage);

        if (revenueData?.length === 2) {
          setRevenueCustomerWiseCityWiseSecond({
            firstRevenue: +revenueData[0].diffPercentage.toFixed(2),
            firstCity: revenueData[0].city,
            secondRevenue: +revenueData[1].diffPercentage.toFixed(2),
            secondCity: revenueData[1].city,
          });
        } else if (revenueData?.length === 1) {
          setRevenueCustomerWiseCityWiseSecond({
            firstRevenue: +revenueData[0].diffPercentage.toFixed(2),
            firstCity: revenueData[0].city,
            secondRevenue: 0,
            secondCity: "",
          });
        } else if (revenueData?.length === 0) {
          setRevenueCustomerWiseCityWiseSecond({
            firstRevenue: 0,
            firstCity: "",
            secondRevenue: 0,
            secondCity: "",
          });
        }
      })
      .catch({});
  }, [selectedRevenueFilterForSecond]);

  useEffect(() => {
    const updatedCityList2 = cityList.filter((item) => {
      if (item !== selectedRevenueFilterForFirst.city1) {
        return item;
      }
    });

    setUpdatedCityList({
      ...updatedcityList,
      cityList2: updatedCityList2,
    });
  }, [selectedRevenueFilterForFirst]);

  useEffect(() => {
    const updatedCityList2 = cityList.filter((item) => {
      if (item !== selectedRevenueFilterForSecond.city1) {
        return item;
      }
    });

    setUpdatedCityListForSecond({
      ...updatedcityListForSecond,
      cityList2: updatedCityList2,
    });
  }, [selectedRevenueFilterForSecond]);
  // radical chart1
  var radicalChart1 = {
    series: [
      revenueCustomerWiseCityWiseFirst?.firstRevenue,
      revenueCustomerWiseCityWiseFirst?.secondRevenue,
    ],
    labels: [
      revenueCustomerWiseCityWiseFirst?.firstCity,
      revenueCustomerWiseCityWiseFirst?.secondCity,
    ],
    colors: ["#FFC764", "#EB455F"],
    height: 300,
    chart: {
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
          },
          total: {
            show: true,
            label: "",
            formatter: function (w) {
              return;
            },
          },
        },
      },
    },
    stroke: {
      lineCap: "round",
    },
  };

  // radical chart2
  var radicalChart2 = {
    series: [
      revenueCustomerWiseCityWiseSecond?.firstRevenue,
      revenueCustomerWiseCityWiseSecond?.secondRevenue,
    ],
    labels: [
      revenueCustomerWiseCityWiseSecond?.firstCity,
      revenueCustomerWiseCityWiseSecond?.secondCity,
    ],
    colors: ["#FFC764", "#EB455F"],
    height: 300,
    chart: {
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
          },
          total: {
            show: true,
            label: "",
            formatter: function (w) {
              return;
            },
          },
        },
      },
    },
    stroke: {
      lineCap: "round",
    },
  };

  return (
    <div className="widget widget-chart-one ">
      <div className="widget-heading">
        <div className="">
          <h5 className="mb-2">Revenue Performace</h5>
          <p>Client wise Revenue Growth</p>
        </div>

        <ul className="tabs">
          <li className="mx-5">
            <p>Total Revenue</p>
            <h3 className="mb-2">
              ₹{numberWithCommas(revenuePerformance.totalRevenue)}
            </h3>
          </li>
          <li className="my-2">
            <label>Month</label>
            <Select
              name="month"
              defaultValue={months[currentMonth - 1]}
              value={selectedRevenueMonth}
              onChange={(value) => {
                setSelectedRevenueMonth(value);
              }}
              options={months}
            />
          </li>
          <li className="mx-2">
            <label>Year</label>
            <Select
              id="inputYear"
              name="year"
              defaultValue={currentYear}
              value={selectedRevenueYear}
              onChange={(value) => {
                setSelectedRevenueYear(value);
              }}
              options={[
                {
                  value: 2023,
                  label: "2023",
                },
                {
                  value: 2022,
                  label: "2022",
                },
                {
                  value: 2021,
                  label: "2021",
                },
              ]}
            />
          </li>
          <li>
            <a id="tb_1" className="tabmenu">
              Monthly
            </a>
          </li>
        </ul>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-6">
            <div className="radial-charts-section ">
              <div className="radialchart m-2 px-2">
                <ul className=" row list-unstyled">
                  <li className=" col">
                    <label>Year and Month</label>
                    <Input
                      type="month"
                      name="yearMonth"
                      value={selectedRevenueFilterForFirst.yearMonth}
                      onChange={(value) =>
                        setSelectedRevenueFilterForFirst({
                          ...selectedRevenueFilterForFirst,
                          yearMonth: value,
                        })
                      }
                    />
                  </li>
                  <li className="mx-2 col">
                    <label>Customers</label>
                    <Select
                      name="customers"
                      value={selectedRevenueFilterForFirst.customer}
                      onChange={(value) =>
                        setSelectedRevenueFilterForFirst({
                          ...selectedRevenueFilterForFirst,
                          customer: value,
                        })
                      }
                      options={customerList}
                    />
                  </li>
                </ul>
                <ul className="row list-unstyled">
                  <li className="mx-2 col">
                    <label>City1</label>
                    <Select
                      name="city"
                      value={selectedRevenueFilterForFirst.city1}
                      onChange={(value) =>
                        setSelectedRevenueFilterForFirst({
                          ...selectedRevenueFilterForFirst,
                          city1: value,
                        })
                      }
                      options={
                        updatedcityList.cityList1.length > 0
                          ? updatedcityList.cityList1
                          : cityList
                      }
                    />
                  </li>
                  <li className="mx-2 col">
                    <label>City2</label>
                    <Select
                      name="city"
                      value={selectedRevenueFilterForFirst.city2}
                      onChange={(value) =>
                        setSelectedRevenueFilterForFirst({
                          ...selectedRevenueFilterForFirst,
                          city2: value,
                        })
                      }
                      options={
                        updatedcityList.cityList2.length > 0
                          ? updatedcityList.cityList2
                          : cityList
                      }
                    />
                  </li>
                </ul>
                {selectedRevenueFilterForFirst.city1 &&
                selectedRevenueFilterForFirst.city2 ? (
                  <Chart
                    options={radicalChart1}
                    type="radialBar"
                    series={radicalChart1.series}
                    height={300}
                  />
                ) : (
                  <p className="m-4 d-flex justify-content-center align-items-center">
                    Please Select Customer and Cities
                  </p>
                )}

                {revenueCustomerWiseCityWiseFirst?.firstCity ? (
                  <div className="text-center d-flex justify-content-center align-items-center">
                    <div
                      className="mx-4"
                      style={{
                        background: "#FFC764",
                        borderRadius: "50%",
                        width: "10px",
                        height: "10px",
                      }}
                    ></div>
                    <p>{revenueCustomerWiseCityWiseFirst?.firstCity}</p>
                  </div>
                ) : (
                  ""
                )}
                {revenueCustomerWiseCityWiseFirst?.secondCity ? (
                  <div className="text-center d-flex justify-content-center align-items-center">
                    <div
                      className="mx-4"
                      style={{
                        background: "#EB455F",
                        borderRadius: "50%",
                        width: "10px",
                        height: "10px",
                      }}
                    ></div>
                    <p>{revenueCustomerWiseCityWiseFirst?.secondCity}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="radialchart m-2 px-2">
                <ul className=" row list-unstyled">
                  <li className=" col">
                    <label>Year and Month</label>
                    <Input
                      type="month"
                      name="yearMonth"
                      value={selectedRevenueFilterForSecond.yearMonth}
                      onChange={(value) =>
                        setSelectedRevenueFilterForSecond({
                          ...selectedRevenueFilterForSecond,
                          yearMonth: value,
                        })
                      }
                    />
                  </li>
                  <li className="mx-2 col">
                    <label>Customers</label>
                    <Select
                      name="customers"
                      value={selectedRevenueFilterForSecond.customer}
                      onChange={(value) =>
                        setSelectedRevenueFilterForSecond({
                          ...selectedRevenueFilterForSecond,
                          customer: value,
                        })
                      }
                      options={customerList}
                    />
                  </li>
                </ul>
                <ul className="row list-unstyled">
                  <li className="mx-2 col">
                    <label>City1</label>
                    <Select
                      name="city"
                      value={selectedRevenueFilterForSecond.city1}
                      onChange={(value) =>
                        setSelectedRevenueFilterForSecond({
                          ...selectedRevenueFilterForSecond,
                          city1: value,
                        })
                      }
                      options={
                        updatedcityListForSecond.cityList1.length > 0
                          ? updatedcityListForSecond.cityList1
                          : cityList
                      }
                    />
                  </li>
                  <li className="mx-2 col">
                    <label>City2</label>
                    <Select
                      name="city"
                      value={selectedRevenueFilterForSecond.city2}
                      onChange={(value) =>
                        setSelectedRevenueFilterForSecond({
                          ...selectedRevenueFilterForSecond,
                          city2: value,
                        })
                      }
                      options={
                        updatedcityListForSecond.cityList2.length > 0
                          ? updatedcityListForSecond.cityList2
                          : cityList
                      }
                    />
                  </li>
                </ul>

                {selectedRevenueFilterForSecond.city1 &&
                selectedRevenueFilterForSecond.city2 ? (
                  <Chart
                    options={radicalChart2}
                    type="radialBar"
                    series={radicalChart2.series}
                    height={300}
                  />
                ) : (
                  <p className="m-4 d-flex justify-content-center align-items-center">
                    Please Select Customer and Cities
                  </p>
                )}
                {revenueCustomerWiseCityWiseSecond?.firstCity ? (
                  <div className="text-center d-flex justify-content-center align-items-center">
                    <div
                      className="mx-4"
                      style={{
                        background: "#FFC764",
                        borderRadius: "50%",
                        width: "10px",
                        height: "10px",
                      }}
                    ></div>
                    <p>{revenueCustomerWiseCityWiseSecond?.firstCity}</p>
                  </div>
                ) : (
                  ""
                )}
                {revenueCustomerWiseCityWiseSecond?.secondCity ? (
                  <div className="text-center d-flex justify-content-center align-items-center">
                    <div
                      className="mx-4"
                      style={{
                        background: "#EB455F",
                        borderRadius: "50%",
                        width: "10px",
                        height: "10px",
                      }}
                    ></div>
                    <p>{revenueCustomerWiseCityWiseSecond?.secondCity}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          {/* client perfromance cards */}
          <div className="col-6 client-performance" id="chart">
            <div className=" m-2 customer-revenue ">
              {revenuePerformance.data?.length > 0 ? (
                revenuePerformance.data?.map((item) => {
                  return (
                    <div key={item.id} className="customer-card m-2 p-4">
                      <div className=" d-flex justify-content-between align-items-center">
                        <p className="textWhite">
                          {months[item.month - 1].label}
                        </p>
                        <p className="textWhite">
                          {selectedRevenueYear
                            ? selectedRevenueYear.label
                            : currentYear.label}
                        </p>
                      </div>
                      <div className="textWhite mt-2 text-center">
                        <h3>₹{numberWithCommas(item.revenue)}</h3>
                        <h6 className="my-2">{item.growth.toFixed(2)}%</h6>
                        {item.growth > 0 ? (
                          <TbCircleArrowUpRightFilled
                            size={30}
                            color="#97DECE"
                          />
                        ) : (
                          <TbCircleArrowDownRightFilled
                            size={30}
                            color="#FFA0A0"
                          />
                        )}
                        <h6 className="py-2">{item.name}</h6>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className=" ">
                  <h6 className="text-center m-auto">No Data Found</h6>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenuePerformance;
