import React, { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { format } from "date-fns";
import Footer from "../../DashBoard/Footer";
import Header from "../../DashBoard/Header";
import Sub_Header from "../../DashBoard/Sub_Header";
import SideBar from "../../DashBoard/SideBar";
import Loader from "../../common/Loader/Loader";
import { GoogleMap, LoadScriptNext } from "@react-google-maps/api";
import { Marker, InfoWindow } from "@react-google-maps/api";
import "../../../assets/css/users/user-profile.css";
import "../../../assets/plugins/table/datatable/datatables.css";
import "../../../assets/plugins/table/datatable/dt-global_style.css";
import * as action from "../../../action/Driver/driver_action";
import $ from "jquery";
import { renderToString } from "react-dom/server";
function Issue_Category_List() {
  const inputRef = useRef();
  $.DataTable = require("datatables.net");
  const dispatch = useDispatch();
  const { loading } = useSelector(
    (state) => state.DriverIssueCategoryListReducer
  );
  const { Addloading } = useSelector(
    (state) => state.DriverAddIssueCategoryReducer
  );
  const { Editloading } = useSelector(
    (state) => state.DriverUpdateIssueCategoryReducer
  );
  const { Deleteloading } = useSelector(
    (state) => state.DriverDeleteIssueCategoryReducer
  );
  const tableRef = useRef();
  const [disabled, setDisabled] = useState(true);
  const [editButtonHide, setEditButtonHide] = useState(false);
  const [cancelButtonHide, setCancelButtonHide] = useState(true);
  const [submitButtonHide, setSubmitButtonHide] = useState(true);
  const [hubList, setHubList] = useState([]);
  const [errors, setErrors] = useState({});
  const categoryFocus = useRef();
  const categoryEditFocus = useRef();
  const [addHub, setAddHub] = useState({
    category: "",
  });
  const [editHub, setEditHub] = useState({
    id: "",
    category: "",
  });
  const customFilter = (option, inputValue) => {
    const input = inputValue.toLowerCase();
    const filterText = new RegExp("^" + input + ".*");
    const label = option.data.label.toLowerCase();
    return filterText.test(label) && option.label.toLowerCase().includes(input);
  };
  const setEditFormEditable = (type) => {
    if (type) {
      setDisabled(false);
      setCancelButtonHide(false);
      setSubmitButtonHide(false);
      setEditButtonHide(true);
    } else {
      setDisabled(true);
      setCancelButtonHide(true);
      setSubmitButtonHide(true);
      setEditButtonHide(false);
    }
  };
  const setUpdateHub = (hub) => {
    let new_hub = hub[4];
    setEditHub({
      id: new_hub._id,
      category: new_hub.category,
    });
  };
  const changeDateFormate = (date) => {
    if (date) {
      let new_date = String(date);
      let ndate = new_date.split("T");
      return ndate[0];
    }
  };
  const ClearModelData = () => {
    setAddHub({
      category: "",
    });
  };
  const deletecategory = (data) => {
    let id = data[4]._id;
    dispatch(action.Driver_delete_issues_category(id))
      .then((data) => {
        toast.success(data.msg);
        let newHubList = [];
        hubList.map((item) => {
          if (item[4]._id !== id) {
            newHubList.push(item);
          }
        });
        setHubList(newHubList);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const tableName = "vehicle-hub-list";
  useEffect(() => {
    dispatch(action.Driver_issues_category_list())
      .then((data) => {
        let newHubList = [];
        data.data.map((item) => {
          newHubList.push([
            item.category,
            `<a style="cursor: pointer;" href="/adminside/category/view/${item._id}" class="btn btn-info btn-sm view-btn view-act-btn" data-id="${item._id}">View</a>`,
            `<a style="cursor: pointer;" href="/adminside/category/view/${item._id}" class="btn btn-danger btn-sm " data-id="${item._id}">Delete</a>`,
            item._id,
            item,
          ]);
        });
        setHubList(newHubList);
      })
      .catch({});
  }, []);

  useEffect(() => {
    const table = $(`#${tableName}`).DataTable({
      dom: "Bfrtip",
      buttons: ["copyHtml5", "excelHtml5", "pdfHtml5", "csvHtml5"],
      data: hubList,
      columns: [{ title: "Category Name" }, { title: "Action" }],
      columnDefs: [
        {
          targets: [1],
          className: "center",
          createdCell: (td, cellData, rowData) => {
            const root = createRoot(td);
            root.render(
              <>
                <a
                  style={{ cursor: "pointer" }}
                  data-toggle="modal"
                  onClick={() => setUpdateHub(rowData)}
                  data-target="#editHubModalCenter"
                  className="btn btn-info btn-sm view-btn view-act-btn"
                >
                  View
                </a>
                &nbsp;
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => deletecategory(rowData)}
                  className="btn btn-danger btn-sm"
                >
                  Delete
                </a>
              </>
            );
          },
        },
      ],
      destroy: true, // I think some clean up is happening here
      searching: true,
      oLanguage: {
        oPaginate: {
          sPrevious:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>',
          sNext:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>',
        },
        //sInfo: "Showing page _PAGE_ of _PAGES_",
        sSearch:
          '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
        sSearchPlaceholder: "Search...",
        sLengthMenu: "Results :  _MENU_",
      },
      fnInitComplete: function (oSettings) {
        $("#vehicle-hub-list_filter").prepend(
          `<button type="button" id="ClearData" className="btn btn-warning btn-sm add-act-btn" data-toggle="modal" data-target="#addHubModalCenter">Add Category</button>`
        );
        const myButton = document.querySelector("#ClearData");
        myButton.addEventListener("click", ClearModelData);
      },
    });
    // Extra step to do extra clean-up.
    return function () {
      table.destroy();
    };
  }, [hubList]);

  const onsubmitHandler = (e) => {
    e.preventDefault();
    setErrors({});
    let customErrors = {};
    if (!/^[0-9a-zA-Z\-\s]+$/.test(addHub.category)) {
      customErrors = { ...customErrors, category: "Please enter category" };
      categoryFocus.current.focus();
    }
    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }
    dispatch(action.Driver_add_issues_category(addHub))
      .then((data) => {
        toast.success(data.msg);
        let response = data.data;
        setHubList([
          ...hubList,
          [
            response.category,
            `<a style="cursor: pointer;" href="/adminside/category/view/${response._id}" class="btn btn-info btn-sm view-btn view-act-btn" data-id="${response._id}">View</a>`,
            `<a style="cursor: pointer;" href="/adminside/category/view/${response._id}" class="btn btn-danger btn-sm " data-id="${response._id}">Delete</a>`,
            response._id,
            response,
          ],
        ]);
        setAddHub({
          category: " ",
        });
        $("#addHubModalCenter .close").click();
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const onUpdateHandler = (e) => {
    e.preventDefault();
    setErrors({});
    let customErrors = {};
    if (!/^[0-9a-zA-Z\-\s]+$/.test(editHub.category)) {
      customErrors = { ...customErrors, editCategory: "Please enter category" };
      categoryEditFocus.current.focus();
    }
    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }
    dispatch(action.Driver_update_issues_category(editHub))
      .then((data) => {
        toast.success(data.msg);
        let newHubList = [];
        hubList.map((item) => {
          if (item[3] === editHub.id) {
            let hub_data = data.data;
            newHubList.push([
              hub_data.category,
              `<a style="cursor: pointer;" href="/adminside/category/view/${hub_data._id}" class="btn btn-info btn-sm view-btn view-act-btn" data-id="${hub_data._id}">View</a>`,
              `<a style="cursor: pointer;" href="/adminside/category/view/${hub_data._id}" class="btn btn-danger btn-sm " data-id="${hub_data._id}">Delete</a>`,
              hub_data._id,
              hub_data,
            ]);
          } else {
            newHubList.push(item);
          }
        });
        setHubList(newHubList);
        setEditHub({
          id: "",
          category: "",
        });
        setEditFormEditable(false);
        $("#editHubModalCenter .close").click();
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Sub_Header />
      <div className="main-container" id="container">
        {loading ? <Loader /> : null}
        {Addloading ? <Loader /> : null}
        {Editloading ? <Loader /> : null}
        {Deleteloading ? <Loader /> : null}
        <div className="overlay"></div>
        <div className="search-overlay"></div>
        <SideBar />
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                <div className="widget-content widget-content-area br-6">
                  <h5 className="" style={{ margin: "10px" }}>
                    Categories List
                  </h5>
                  <div className="table-responsive mb-4 mt-4">
                    <table
                      id="vehicle-hub-list"
                      className="table table-hover"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>Category</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
      <div
        className="modal fade"
        id="addHubModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addHubModalCenterTitle"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <form onSubmit={onsubmitHandler}>
              <div className="modal-header">
                <h5 className="modal-title" id="addHubModalCenterTitle">
                  Add Category
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <svg
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              </div>

              <div className="modal-body">
                <div
                  className="customer-address-class"
                  id="customer-address-div-id"
                >
                  <div className="row" id="defaultaddressdiv0">
                    <div className="col-md-12">
                      <div className="form-group required">
                        <label htmlFor="inputName-add">Category Name *</label>
                        <input
                          type="text"
                          className="form-control"
                          ref={categoryFocus}
                          name="name"
                          id="inputName-add"
                          placeholder="Enter Category Name"
                          onChange={(e) =>
                            setAddHub({ ...addHub, category: e.target.value })
                          }
                          value={addHub.category}
                        />
                        <span style={{ color: "red" }}>{errors?.category}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn" data-dismiss="modal">
                  <i className="flaticon-cancel-12"></i>Discard
                </button>
                <button type="submit" className="btn btn-primary view-btn">
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="editHubModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editHubModalCenterTitle"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <form onSubmit={onUpdateHandler}>
              <div className="modal-header">
                <h5 className="modal-title" id="editHubModalCenterTitle">
                  Update Category
                </h5>
                <button
                  type="button"
                  className="btn btn-primary view-btn"
                  hidden={editButtonHide}
                  onClick={() => setEditFormEditable(true)}
                >
                  Edit
                </button>
              </div>

              <div className="modal-body">
                <div
                  className="customer-address-class"
                  id="customer-address-div-id"
                >
                  <div className="row" id="defaultaddressdiv0">
                    <div className="col-md-12">
                      <div className="form-group required">
                        <label htmlFor="inputName-add">Category Name *</label>
                        <input
                          disabled={disabled}
                          type="text"
                          ref={categoryEditFocus}
                          className="form-control"
                          name="name"
                          id="inputName-add"
                          placeholder="Enter Name"
                          onChange={(e) =>
                            setEditHub({ ...editHub, category: e.target.value })
                          }
                          value={editHub.category}
                        />
                        <span style={{ color: "red" }}>
                          {errors?.editCategory}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-dark close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary view-btn"
                  hidden={submitButtonHide}
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default Issue_Category_List;
