import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import DashBoard from "./component/DashBoard/DashBoard";
import OwnersDashBoard from "./component/Owners_DashBoard/DashBoard";
import HubDashBoard from "./component/Admin/VehicleHubDashboard/Dashboard";
import HubVehicleList from "./component/Admin/VehicleHubDashboard/Vehicle_List";
import HubViewVehicle from "./component/Admin/VehicleHubDashboard/View_Vehicle";
import LoginPage from "./component/Admin/Auth/Login";
import AddCustomer from "./component/Admin/Customer/Add_Customer";
import CustomerList from "./component/Admin/Customer/Customer_List";
import ViewCustomer from "./component/Admin/Customer/View_Customer";
import VehicleList from "./component/Admin/Vehicle/Vehicle_List";
import AddVehicle from "./component/Admin/Vehicle/Add_Vehicle";
import ViewVehicle from "./component/Admin/Vehicle/View_Vehicle";
import AddVehicleLeaseCompany from "./component/Admin/VehicleLeaseCompany/Add_Vehicle_Lease_Company";
import VehicleLeaseCompanyList from "./component/Admin/VehicleLeaseCompany/Vehicle_Lease_Company_List";
import ViewVehicleLeaseCompany from "./component/Admin/VehicleLeaseCompany/View_Vehicle_Lease_Company";
import VehicleTelematicsUnitList from "./component/Admin/VehicleTelematicsUnit/Vehicle_Telematics_Unit_List";
import VehicleHubList from "./component/Admin/VehicleHub/Vehicle_Hub_List";
import AdminList from "./component/Admin/Admin/Admin_List";
import AdminCreate from "./component/Admin/Admin/Admin_Create";
import AdminView from "./component/Admin/Admin/Admin_View";
import DriverList from "./component/Admin/Driver/Driver_List";
import DriverUnverified from "./component/Admin/Driver/Driver_Unverified";
import AddDriver from "./component/Admin/Driver/Add_Driver";
import ViewDriver from "./component/Admin/Driver/View_Driver";
import DriverMultipleAssignCustomer from "./component/Admin/Driver/Customer_Assign";
import AllDriverWorkHistory from "./component/Admin/Driver/All_Driver_Work_History";
import ViewDriverWorkHistory from "./component/Admin/Driver/View_Driver_Work_History";
import ViewDriverWorkImages from "./component/Admin/Driver/View_Driver_Work_Images";
import DriverLoginRequests from "./component/Admin/Driver/Driver_Login_Requests";
import TechnicianDashBoard from "./component/Admin/VehicleTechnicianDashboard/Dashboard";
import TechnicianVehicleList from "./component/Admin/VehicleTechnicianDashboard/Vehicle_List";
import WatchmanDashBoard from "./component/Admin/VehicleWatchmanDashboard/Dashboard";
import BikeTypeList from "./component/Admin/BikeType/Bike_Type_List";
import UpdateProfileDetails from "./component/Admin/Admin/Admin_Profile";
import ViewVehicleUse from "./component/Admin/Vehicle/View_Vehicle_Use";
import DriverIssuesList from "./component/Admin/Driver/Driver_Issues";
import DriverIssuesImages from "./component/Admin/Driver/Driver_Issues_Images";
import AllDriverAttendanceHistory from "./component/Admin/Driver/All_Driver_Attendance_History";
import AllCustomerReport from "./component/Admin/Customer/Customer_Report";
import IssueCategoryList from "./component/Admin/Driver/Issue_Category_List";
import AdminSetting from "./component/Admin/Admin/Admin_Setting";
import PageNotFound from "./component/PageNotFound/PageNotFound";
import AddSalesPayout from "./component/Admin/Admin/Admin_Add_Sales_Payout";
import Sales_Payout_View from "./component/Admin/Admin/Admin_sales_payout_list";
import ViewSalesPayout from "./component/Admin/Admin/Admin_sales_payout_view";
import SettingVehicle from "./component/Admin/Vehicle/SettingVehicle";
import ExpenseTracker from "./component/Admin/Setting/ExpenseTracker";
import ExpenseForm from "./component/Admin/Vehicle/ExpenseForm";
import Add_Sales_Payout from "./component/Admin/SalesPayout/Add_Sales_Payout";
import Sales_Payout_List from "./component/Admin/SalesPayout/Sales_Payout_List";
import SalesPayoutView from "./component/Admin/SalesPayout/SalesPayoutView";
import RiderParcel from "./component/Admin/Driver/RiderParcel";
import RiderEarning from "./component/Admin/Driver/RiderEarning";
import FleetEfficiency from "./component/Admin/Vehicle/FleetEfficiency";
import BillingAnalysis from "./component/Admin/BillingAnalysis/BillingAnalysis";
import CODOrders from "./component/Admin/Driver/CODOrders";

function RoleBasedRedirect() {
  const storageRole = localStorage.getItem("role");
  if (storageRole === "H") {
    return <Navigate to="/adminside/hub/dashboard" />;
  } else if (storageRole === "T") {
    return <Navigate to="/adminside/technician/dashboard" />;
  } else if (storageRole === "W") {
    return <Navigate to="/adminside/watchman/dashboard" />;
  } else if (storageRole === "O") {
    return <Navigate to="/adminside/owners/dashboard" />;
  } else if (storageRole === "A") {
    return <Navigate to="/adminside/dashboard" />;
  } else {
    return <Navigate to="/adminside/notFound" />;
  }
}

function RequireAuth({ children, redirectTo, role }) {
  let isAuthenticated = localStorage.getItem("token");
  return isAuthenticated && isAuthenticated !== "" ? (
    role && role.length && role.includes(localStorage.getItem("role")) ? (
      children
    ) : (
      RoleBasedRedirect()
    )
  ) : (
    <Navigate to={redirectTo} />
  );
}

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <RequireAuth
                redirectTo="/adminside/login"
                role={["A", "M", "S", "H", "T", "W", "O"]}
              >
                <RoleBasedRedirect />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/"
            element={
              <RequireAuth
                redirectTo="/adminside/login"
                role={["A", "M", "S", "H", "T", "W", "O"]}
              >
                <RoleBasedRedirect />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/dashboard"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <DashBoard />
              </RequireAuth>
            }
          ></Route>

          <Route
            exact
            path="/adminside/billing-analysis"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <BillingAnalysis />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/hub/dashboard"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["H"]}>
                <HubDashBoard />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/hub/vehicle"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["H"]}>
                <HubVehicleList />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/vehicle/hub/view/:vehicle_id"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["H"]}>
                <HubViewVehicle />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/owners/dashboard"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["O"]}>
                <OwnersDashBoard />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/owners/setting"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["O"]}>
                <AdminSetting />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/salesPayout/view/:id"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["O"]}>
                <ViewSalesPayout />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/AddSalesPayout"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["O"]}>
                <AddSalesPayout />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/ViewSalesPayout"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["O"]}>
                <Sales_Payout_View />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/AddFleetEffciency"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["O"]}>
                <Sales_Payout_View />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/ViewFleetEffciency"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["O"]}>
                <Sales_Payout_View />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/salesPayout/use"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["O"]}>
                <Sales_Payout_View />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/technician/dashboard"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["T"]}>
                <TechnicianDashBoard />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/technician/vehicle"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["T"]}>
                <TechnicianVehicleList />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/customer/add"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <AddCustomer />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/watchman/dashboard"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["W"]}>
                <WatchmanDashBoard />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/customer/view/:cust_id"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <ViewCustomer />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/customer"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <CustomerList />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/customer/report"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <AllCustomerReport />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/vehicle"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <VehicleList />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/vehicle/add"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <AddVehicle />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/vehicle/settings"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <SettingVehicle />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/vehicle/expenseForm"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <ExpenseForm />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/vehicle/fleet-efficiency"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <FleetEfficiency />
              </RequireAuth>
            }
          ></Route>

          <Route
            exact
            path="/adminside/vehicle/view/:vehicle_id"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <ViewVehicle />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/vehicle/use/:vehicle_id"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <ViewVehicleUse />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/vehicleLeaseCompany/add"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <AddVehicleLeaseCompany />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/vehicleLeaseCompany"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <VehicleLeaseCompanyList />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/vehicleLeaseCompany/view/:vehicle_lease_company_id"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <ViewVehicleLeaseCompany />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/vehicleTelematicsUnit"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <VehicleTelematicsUnitList />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/vehicleHub"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <VehicleHubList />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/admin"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <AdminList />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/admin/add"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <AdminCreate />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/admin/view/:admin_id"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <AdminView />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/driver"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <DriverList />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/driver/toBeVerify"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <DriverUnverified />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/driver/parcels"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <RiderParcel />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/driver/earning"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <RiderEarning />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/driver/cod-orders"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <CODOrders />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/driver/allDriverAttendance"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <AllDriverAttendanceHistory />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/driver/add"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <AddDriver />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/driver/view/:driver_id"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <ViewDriver />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/driver/assign/:driver_id"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <DriverMultipleAssignCustomer />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/driver/dailyWorks"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <AllDriverWorkHistory />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/driver/dailyWorksView/:driver_id"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <ViewDriverWorkHistory />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/driver/View_Driver_Work_Images/:driver_id"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <ViewDriverWorkImages />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/driver/loginRequest"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <DriverLoginRequests />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/driver/issueList"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <DriverIssuesList />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/driver/Issueview/:issue_id"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <DriverIssuesImages />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/driver/Category"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <IssueCategoryList />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/bikeType"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <BikeTypeList />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/settings/expense-configuration"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A", "M", "S"]}>
                <ExpenseTracker />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/sales-and-payout/view/:id"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A"]}>
                <SalesPayoutView />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/sales-and-payout/add-sales-payout"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A"]}>
                <Add_Sales_Payout />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/sales-and-payout/view-sales-payout"
            element={
              <RequireAuth redirectTo="/adminside/login" role={["A"]}>
                <Sales_Payout_List />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path="/adminside/profile"
            element={
              <RequireAuth
                redirectTo="/adminside/login"
                role={["A", "M", "S", "H", "T", "W", "O"]}
              >
                <UpdateProfileDetails />
              </RequireAuth>
            }
          ></Route>
          <Route exact path="/adminside/login" element={<LoginPage />}></Route>
          {/* <Route exact path='/adminside/vehicle' element={<Vehicle_List/>}></Route> */}
          <Route
            exact
            path="/adminside/notFound"
            element={<PageNotFound />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
