import * as AuthService from "../../service/services";
import {
  ADD_BBNOW_BILLING_ERROR,
  ADD_BBNOW_BILLING_LOADING,
  ADD_BBNOW_BILLING_SUCCESS,
  ADD_BIGBASKET_BILLING_ERROR,
  ADD_BIGBASKET_BILLING_LOADING,
  ADD_BIGBASKET_BILLING_SUCCESS,
  ADD_BLUEDART_BILLING_ERROR,
  ADD_BLUEDART_BILLING_LOADING,
  ADD_BLUEDART_BILLING_SUCCESS,
  ADD_FLIPKART_BILLING_ERROR,
  ADD_FLIPKART_BILLING_LOADING,
  ADD_FLIPKART_BILLING_SUCCESS,
  ADD_SWIGGY_BILLING_ERROR,
  ADD_SWIGGY_BILLING_LOADING,
  ADD_SWIGGY_BILLING_SUCCESS,
  ADD_ZOMATO_BILLING_ERROR,
  ADD_ZOMATO_BILLING_LOADING,
  ADD_ZOMATO_BILLING_SUCCESS,
  EDIT_BBNOW_BILLING_ERROR,
  EDIT_BBNOW_BILLING_LOADING,
  EDIT_BBNOW_BILLING_SUCCESS,
  EDIT_BIGBASKET_BILLING_ERROR,
  EDIT_BIGBASKET_BILLING_LOADING,
  EDIT_BIGBASKET_BILLING_SUCCESS,
  EDIT_BLUEDART_BILLING_ERROR,
  EDIT_BLUEDART_BILLING_LOADING,
  EDIT_BLUEDART_BILLING_SUCCESS,
  EDIT_FLIPKART_BILLING_ERROR,
  EDIT_FLIPKART_BILLING_LOADING,
  EDIT_FLIPKART_BILLING_SUCCESS,
  EDIT_SWIGGY_BILLING_ERROR,
  EDIT_SWIGGY_BILLING_LOADING,
  EDIT_SWIGGY_BILLING_SUCCESS,
  EDIT_ZOMATO_BILLING_ERROR,
  EDIT_ZOMATO_BILLING_LOADING,
  EDIT_ZOMATO_BILLING_SUCCESS,
  GET_BBNOW_BILLING_ERROR,
  GET_BBNOW_BILLING_LOADING,
  GET_BBNOW_BILLING_SUCCESS,
  GET_BIGBASKET_BILLING_ERROR,
  GET_BIGBASKET_BILLING_LOADING,
  GET_BIGBASKET_BILLING_SUCCESS,
  GET_BLUEDART_BILLING_ERROR,
  GET_BLUEDART_BILLING_LOADING,
  GET_BLUEDART_BILLING_SUCCESS,
  GET_FLIPKART_BILLING_ERROR,
  GET_FLIPKART_BILLING_LOADING,
  GET_FLIPKART_BILLING_SUCCESS,
  GET_SWIGGY_BILLING_ERROR,
  GET_SWIGGY_BILLING_LOADING,
  GET_SWIGGY_BILLING_SUCCESS,
  GET_ZOMATO_BILLING_ERROR,
  GET_ZOMATO_BILLING_LOADING,
  GET_ZOMATO_BILLING_SUCCESS,
} from "../type";

export const add_swiggy_billing = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADD_SWIGGY_BILLING_LOADING,
        data: true,
      });
      AuthService.AddSwiggyBilling(data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ADD_SWIGGY_BILLING_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: ADD_SWIGGY_BILLING_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const edit_swiggy_billing = (id, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: EDIT_SWIGGY_BILLING_LOADING,
        data: true,
      });
      AuthService.EditSwiggyBilling(id, data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: EDIT_SWIGGY_BILLING_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: EDIT_SWIGGY_BILLING_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const get_swiggy_billing = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: GET_SWIGGY_BILLING_LOADING,
        data: true,
      });
      AuthService.GetSwiggyBilling(data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_SWIGGY_BILLING_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: GET_SWIGGY_BILLING_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const add_zomato_billing = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADD_ZOMATO_BILLING_LOADING,
        data: true,
      });
      AuthService.AddZomatoBilling(data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ADD_ZOMATO_BILLING_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: ADD_ZOMATO_BILLING_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const edit_zomato_billing = (id, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: EDIT_ZOMATO_BILLING_LOADING,
        data: true,
      });
      AuthService.EditZomatoBilling(id, data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: EDIT_ZOMATO_BILLING_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: EDIT_ZOMATO_BILLING_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const get_zomato_billing = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: GET_ZOMATO_BILLING_LOADING,
        data: true,
      });
      AuthService.GetZomatoBilling()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_ZOMATO_BILLING_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: GET_ZOMATO_BILLING_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const add_bigbasket_billing = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADD_BIGBASKET_BILLING_LOADING,
        data: true,
      });
      AuthService.AddBigBasketBilling(data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ADD_BIGBASKET_BILLING_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: ADD_BIGBASKET_BILLING_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const edit_bigbasket_billing = (id, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: EDIT_BIGBASKET_BILLING_LOADING,
        data: true,
      });
      AuthService.EditBigBasketBilling(id, data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: EDIT_BIGBASKET_BILLING_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: EDIT_BIGBASKET_BILLING_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const get_bigbasket_billing = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: GET_BIGBASKET_BILLING_LOADING,
        data: true,
      });
      AuthService.GetBigBasketBilling()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_BIGBASKET_BILLING_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: GET_BIGBASKET_BILLING_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const add_bbnow_billing = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADD_BBNOW_BILLING_LOADING,
        data: true,
      });
      AuthService.AddBBNowBilling(data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ADD_BBNOW_BILLING_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: ADD_BBNOW_BILLING_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const edit_bbnow_billing = (id, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: EDIT_BBNOW_BILLING_LOADING,
        data: true,
      });
      AuthService.EditBBNowBilling(id, data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: EDIT_BBNOW_BILLING_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: EDIT_BBNOW_BILLING_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const get_bbnow_billing = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: GET_BBNOW_BILLING_LOADING,
        data: true,
      });
      AuthService.GetBBNowBilling()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_BBNOW_BILLING_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: GET_BBNOW_BILLING_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const add_flipkart_billing = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADD_FLIPKART_BILLING_LOADING,
        data: true,
      });
      AuthService.AddFlipkartBilling(data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ADD_FLIPKART_BILLING_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: ADD_FLIPKART_BILLING_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const edit_flipkart_billing = (id, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: EDIT_FLIPKART_BILLING_LOADING,
        data: true,
      });
      AuthService.EditFlipkartBilling(id, data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: EDIT_FLIPKART_BILLING_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: EDIT_FLIPKART_BILLING_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const get_flipkart_billing = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: GET_FLIPKART_BILLING_LOADING,
        data: true,
      });
      AuthService.GetFlipkartBilling()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_FLIPKART_BILLING_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: GET_FLIPKART_BILLING_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const add_bluedart_billing = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADD_BLUEDART_BILLING_LOADING,
        data: true,
      });
      AuthService.AddBlueDartBilling(data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ADD_BLUEDART_BILLING_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: ADD_BLUEDART_BILLING_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const edit_bluedart_billing = (id, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: EDIT_BLUEDART_BILLING_LOADING,
        data: true,
      });
      AuthService.EditBlueDartBilling(id, data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: EDIT_BLUEDART_BILLING_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: EDIT_BLUEDART_BILLING_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const get_bluedart_billing = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: GET_BLUEDART_BILLING_LOADING,
        data: true,
      });
      AuthService.GetBlueDartBilling()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_BLUEDART_BILLING_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: GET_BLUEDART_BILLING_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
