import React, { useEffect, useState } from "react";
import * as action from "../../../../action/Dashboard/dashboard_action";
import { useDispatch } from "react-redux";
import { RiEBike2Line } from "react-icons/ri";
import { HiOutlineLightBulb } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
import { GiAutoRepair } from "react-icons/gi";

const NoOfVehicle = () => {
  const dispatch = useDispatch();

  const [dashboardVehicleDetails, setDashboardVehiclesDetails] = useState({});
  // No of Vehicle
  useEffect(() => {
    dispatch(action.dashboard_vehicles_details())
      .then((data) => {
        setDashboardVehiclesDetails(data.data);
      })
      .catch({});
  }, []);

  return (
    <div className="stats-card">
      <div className="widget-heading">
        <h5 className="mb-3">No. of Vehicle</h5>
      </div>
      <div className="row">
        <div className="col  layout-spacing">
          <div className=" stats-data">
            <div className="widget-content">
              <div className="w-numeric-value">
                <div className="w-icon">
                  <RiEBike2Line />
                </div>
                <div className="w-content">
                  <span className="w-numeric-title">Total</span>
                  <span className="w-value" id="totalParcelsId">
                    {dashboardVehicleDetails.totalVehicles}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" col  layout-spacing">
          <div className="stats-data">
            <div className="widget-content">
              <div className="w-numeric-value">
                <div className="w-icon">
                  <HiOutlineLightBulb />
                </div>
                <div className="w-content">
                  <span className="w-numeric-title">Active</span>
                  <span className="w-value" id="totalParcelsId">
                    {dashboardVehicleDetails.totalActiveVehicles}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" col  layout-spacing">
          <div className="stats-data">
            <div className="widget-content">
              <div className="w-numeric-value">
                <div className="w-icon">
                  <RxCross2 />
                </div>
                <div className="w-content">
                  <span className="w-numeric-title">Deactive</span>
                  <span className="w-value" id="totalParcelsId">
                    {dashboardVehicleDetails.totalDeactiveVehicles}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" col  layout-spacing">
          <div className="stats-data">
            <div className="widget-content">
              <div className="w-numeric-value">
                <div className="w-icon">
                  <GiAutoRepair />
                </div>
                <div className="w-content">
                  <span className="w-numeric-title">Under Maintenance</span>
                  <span className="w-value" id="totalParcelsId">
                    {dashboardVehicleDetails.totalUnderMaintainenceVehicles}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoOfVehicle;
