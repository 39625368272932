import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Header from "./Header";
import Sub_Header from "./Sub_Header";
import { ToastContainer } from "react-toastify";
import SideBar from "./SideBar";
import Footer from "./Footer";
import "./DashBoard.css";
import "../../assets/css/users/user-profile.css";
import "../../assets/plugins/table/datatable/datatables.css";
import "../../assets/plugins/table/datatable/dt-global_style.css";
import * as action from "../../action/Dashboard/dashboard_action";
import * as customeraction from "../../action/Customer/customer_action";

import RevenuePerformance from "./components/RevenuePerformance/RevenuePerformance";
import ClientPerformance from "./components/ClientPerformance/ClientPerformance";
import NoOfDrivers from "./components/NoOfDrivers/NoOfDrivers";
import BillingAnalysis from "./components/BillingAnalysis/BillingAnalysis";
import ParcelByRiders from "./components/CustomerWiseParcelByRiders/ParcelByRiders";
import CustomerGrowth from "./components/CustomerGrowth/CustomerGrowth";
import RiderAnalysis from "./components/RiderAnalysis/RiderAnalysis";
import RidersParcelAndEarning from "./components/RidersParcelAndEarning/RidersParcelAndEarning";
import CustomerWiseRiders from "./components/CustomerWiseRiders/CustomerWiseRiders";
import ExpenseChart from "./components/ExpenseChart/ExpenseChart";
import NoOfVehicle from "./components/NoOfVehicle/NoOfVehicle";
import UtilisedFleetAnalysis from "./components/UtilisedFleetAnalysis/UtilisedFleetAnalysis";
import TotalFleetVsDeployedFleet from "./components/TotalFleetVsDeployedFleet/TotalFleetVsDeployedFleet";
import DriverWiseSubmitParcel from "./components/DriverWiseSubmitParcel/DriverWiseSubmitParcel";
import NoOfSubmitParcel from "./components/NoOfSubmitParcel/NoOfSubmitParcel";

const DashBoard = () => {
  const dispatch = useDispatch();
  // customer list
  const [customerList, setCustomerList] = useState([]);
  // city list
  const [cityList, setCityList] = useState([]);

  // get customer list
  useEffect(() => {
    dispatch(customeraction.customer_list())
      .then((data) => {
        let newCustomerList = [];
        data.data.map((item) => {
          newCustomerList.push({ value: item._id, label: item.businessName });
        });
        setCustomerList(newCustomerList);
      })
      .catch({});
  }, []);

  // get city list
  useEffect(() => {
    dispatch(action.city_list())
      .then((data) => {
        let citylist = data.data;
        let newCityList = [];
        citylist.map((item) => {
          newCityList.push({ value: item.cityId, label: item.cityName });
        });
        setCityList(newCityList);
      })
      .catch({});
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Header />
      <Sub_Header />

      <div className="main-container" id="container">
        <div className="overlay"></div>
        <div className="search-overlay"></div>
        <SideBar />
        {/* DashBoardContent */}
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="row layout-top-spacing">
              {/* Client Performace, cutomer growth */}
              <div className="container-fluid">
                <div className="row">
                  {/* Client Performace */}
                  <div className="col">
                    <ClientPerformance
                      cityList={cityList}
                      customerList={customerList}
                    />
                  </div>
                  {/* Customer Wise Growth Percentage */}
                  <div className="col layout-spacing">
                    <CustomerGrowth />
                  </div>
                </div>
              </div>

              {/* Revenue Performace */}
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing ">
                <RevenuePerformance
                  customerList={customerList}
                  cityList={cityList}
                />
              </div>

              {/* no of drivers stats, riders analysis(new-left),customer wise riders, expense chart, fulltime vs part time riders parcel */}
              <div className="container-fluid">
                <div className="row">
                  <div className="col">
                    {/* No of drivers */}
                    <NoOfDrivers />
                    {/* Rider Analysis - new driver vs left driver  */}
                    <RiderAnalysis />
                    {/* Monthly Customer wise Rider in each Company Chart */}
                    <CustomerWiseRiders />
                  </div>
                  {/* Monthly Repair Expense , Riders Parcel Delivery, Riders Earning */}
                  <div className="col layout-spacing h-auto">
                    {/* Repair Expense */}
                    <ExpenseChart />
                    {/* Parcel delivery stats - Riders Parcel Delivery, Riders Earning */}
                    <div className="mt-4 layout-spacing">
                      <RidersParcelAndEarning
                        customerList={customerList}
                        cityList={cityList}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* No of Vehicle */}
              <div className=" col layout-spacing">
                <NoOfVehicle />
              </div>

              {/*  Fleet analysis(total vs deployed), tilised fleet */}
              <div className="container-fluid ">
                <div className="row h-100">
                  {/* Utilisation Efficiency Of Fleet */}
                  <div className=" col-5 layout-spacing">
                    <UtilisedFleetAnalysis cityList={cityList} />
                  </div>
                  {/* Total fleet Vs Deployed fleet (Active vs Scan) */}
                  <div className="col-7  layout-spacing">
                    <TotalFleetVsDeployedFleet />
                  </div>
                </div>
              </div>

              {/*  monthly driver wise parcel , parcel delivery by full time vs part time riders */}
              <div className="container-fluid h-auto">
                <div className="row">
                  {/* Monthly Driver wise submitted parcel */}
                  <div className="col-7 layout-spacing ">
                    <DriverWiseSubmitParcel />
                  </div>
                  {/*  No of submitted parcel */}
                  <div className="col-5 layout-spacing h-auto">
                    <NoOfSubmitParcel />
                  </div>
                </div>
              </div>

              {/* Parcel delivery stats - No of submitted parcel */}
              <div className="container-fluid ">
                <ParcelByRiders cityList={cityList} />
              </div>

              {/* Billing Analysis */}
              <div className="container-fluid">
                <BillingAnalysis cityList={cityList} />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default DashBoard;
