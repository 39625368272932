import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../DashBoard/Header";
import Sub_Header from "../../DashBoard/Sub_Header";
import SideBar from "../../DashBoard/SideBar";
import Footer from "../../DashBoard/Footer";
import Loader from "../../common/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import Select from "react-select";
import { DatePicker } from "rsuite";
import * as Daction from "../../../action/Dashboard/dashboard_action";
import * as customer_action from "../../../action/Customer/customer_action";

const SalesPayoutView = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const riderFocus = useRef();
  const salesFocus = useRef();
  const payoutFocus = useRef();
  const vehicleFocus = useRef();
  const yearMonthFocus = useRef();
  const cityFocus = useRef();
  const customerFocus = useRef();
  const [errors, setErrors] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [editButtonHide, setEditButtonHide] = useState(false);
  const [cancelButtonHide, setCancelButtonHide] = useState(true);
  const [submitButtonHide, setSubmitButtonHide] = useState(true);
  const { loading } = useSelector(
    (state) => state.DashboardEditSalesPayoutReducer
  );
  const { Viewloading } = useSelector(
    (state) => state.DashboardSingleViewSalesPayoutReducer
  );
  const [cityList, setCityList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [values, setValues] = useState({
    city: "",
    customer: "",
  });
  const [input, setInput] = useState({
    id: id,
    year: "",
    month: "",
    city: "",
    customer: "",
    sales: "",
    payout: "",
    vehicle: "",
    rider: "",
    date: "",
  });

  const [DatePick, setDatePick] = useState();

  const changeCityDropDown = (data) => {
    setValues({ ...values, city: data });
    setInput({ ...input, city: data.value });
  };

  const changeCustomerDropDown = (data) => {
    console.log("data", data);
    setValues({ ...values, customer: data });
    setInput({ ...input, customer: data.value });
  };

  useEffect(() => {
    dispatch(Daction.dashboard_view_sales_payout(id))
      .then((data) => {
        let vehicle_details = data.data;
        console.log(
          "vehicle_details.year",
          vehicle_details.year,
          vehicle_details.month
        );
        let year = vehicle_details.year;
        let month = vehicle_details.month;
        let datePick = new Date(`${year}-${month}`);
        setDatePick(datePick);
        setInput({
          id: id,
          year: vehicle_details.year,
          month: vehicle_details.month,
          city: vehicle_details.city,
          customer: vehicle_details.customer,
          sales: vehicle_details.sales,
          payout: vehicle_details.payout,
          vehicle: vehicle_details.vehicle,
          rider: vehicle_details.rider,
          date: vehicle_details.date,
        });

        let customer_data = {};
        if (data.customer) {
          if (vehicle_details.customerId !== "") {
            customer_data = {
              value: data.customer._id ? data.customer._id : "",
              label: data.customer.name ? data.customer.businessName : "",
            };
          } else {
            customer_data = { value: "", label: "" };
          }
        } else {
          customer_data = { value: "", label: "" };
        }
        setValues({
          ...values,
          city: {
            value: data.city.cityId ? data.city.cityId : "",
            label: data.city.cityName ? data.city.cityName : "",
          },
          customer: customer_data,
        });
      })
      .catch({});
  }, []);
  useEffect(() => {
    dispatch(Daction.dashboard_city_list())
      .then((data) => {
        let newCityList = [];
        data.data.map((item) => {
          newCityList.push({
            value: item._id,
            label: item.cityName,
          });
        });
        setCityList(newCityList);
      })
      .catch({});
    dispatch(customer_action.customer_list())
      .then((data) => {
        let newCustomerList = [];
        data.data.map((item) => {
          newCustomerList.push({
            value: item._id,
            label: item.businessName,
          });
        });
        setCustomerList(newCustomerList);
      })
      .catch({});
  }, []);
  const setEditFormEditable = (type) => {
    if (type) {
      setDisabled(false);
      setCancelButtonHide(false);
      setSubmitButtonHide(false);
      setEditButtonHide(true);
    } else {
      setDisabled(true);
      setCancelButtonHide(true);
      setSubmitButtonHide(true);
      setEditButtonHide(false);
    }
  };
  const customFilter = (option, inputValue) => {
    const input = inputValue.toLowerCase();
    const filterText = new RegExp("^" + input + ".*");
    const label = option.data.label.toLowerCase();
    return filterText.test(label) && option.label.toLowerCase().includes(input);
  };
  const onsubmitHandler = (e) => {
    e.preventDefault();
    const getFormattedDate = (date) => {
      const cd = new Date(date);
      const year = cd.getFullYear();
      const month = String(cd.getMonth() + 1).padStart(2, "0");
      const day = String(cd.getDate()).padStart(2, "0");
      return year + "-" + month + "-" + day;
    };
    if (DatePick != null) {
      var inputDate = new Date(DatePick);
      var year = inputDate.getFullYear();
      var month = inputDate.getMonth() + 1;
      var date = getFormattedDate(
        new Date(
          new Date(DatePick.getFullYear(), DatePick.getMonth() + 1, 0).setHours(
            23,
            59,
            59,
            999
          )
        )
      );
    }

    console.log("date", date);
    setErrors({});
    let customErrors = {};
    if (inputDate == "") {
      customErrors = { ...customErrors, yearMonth: "Please select year" };
      yearMonthFocus.current.focus();
    } else if (input.city === "") {
      customErrors = { ...customErrors, city: "Please select city" };
      cityFocus.current.focus();
    } else if (input.customer === "") {
      customErrors = { ...customErrors, customer: "Please select " };
      customerFocus.current.focus();
    } else if (input.sales === "") {
      customErrors = { ...customErrors, sales: "Please enter sales amount" };
      salesFocus.current.focus();
    } else if (input.payout === "") {
      customErrors = { ...customErrors, payout: "Please enter payout amount" };
      payoutFocus.current.focus();
    } else if (input.vehicle === "") {
      customErrors = { ...customErrors, vehicle: "Please enter no of vehicle" };
      vehicleFocus.current.focus();
    } else if (input.rider === "") {
      customErrors = { ...customErrors, rider: "Please enter no of rider" };
      riderFocus.current.focus();
    } else if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }
    let parameter = {
      id: id,
      year: year,
      month: month,
      city: input.city,
      customer: input.customer,
      sales: input.sales,
      payout: input.payout,
      vehicle: input.vehicle,
      rider: input.rider,
      date: date,
    };
    dispatch(Daction.dashboard_edit_sales_payout(parameter))
      .then((data) => {
        if (!data.success) {
          toast.error(data.message);
        } else {
          toast.success(data.message);
          setDisabled(true);
          setCancelButtonHide(true);
          setSubmitButtonHide(true);
          setEditButtonHide(false);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Sub_Header />
      <div className="main-container" id="container">
        {loading ? <Loader /> : null}
        {Viewloading ? <Loader /> : null}
        <div className="overlay"></div>
        <div className="search-overlay"></div>

        <SideBar></SideBar>
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                <div className="widget-content widget-content-area br-6">
                  <div className="input-group edit-btn-group">
                    <h5 className="">Vehicle</h5>
                    <span
                      id="editidspan"
                      className="btn btn-warning btn-sm float-right ms-3 mb-1"
                      hidden={editButtonHide}
                      style={{ cursor: "pointer" }}
                      onClick={() => setEditFormEditable(true)}
                    >
                      {" "}
                      Edit{" "}
                    </span>
                  </div>
                  <br />
                  <form onSubmit={onsubmitHandler}>
                    <div id="append-div-id">
                      <div class="form-group row">
                        <div class="form-group col-md-4 required">
                          <label for="yearMonth">Select Year and Month *</label>

                          <DatePicker
                            name="yearMonth"
                            ref={yearMonthFocus}
                            format="yyyy-MM"
                            disabled={disabled}
                            ranges={[]}
                            value={DatePick}
                            onChange={(value) => {
                              console.log("value", value);
                              setDatePick(value);
                              let inputDate = new Date(value);
                              let year = inputDate.getFullYear();

                              let month = inputDate.getMonth() + 1;
                              setInput({
                                ...input,
                                year: year,
                                month: month,
                              });
                            }}
                            style={{ width: 500 }}
                            placeholder="Select Month and Year"
                          />

                          <span style={{ color: "red" }}>
                            {errors?.yearMonth}
                          </span>
                        </div>
                        <div class="form-group col-md-4 required">
                          <label for="inputFullname">Select City *</label>
                          <Select
                            isDisabled={disabled}
                            id="inputCustomer"
                            ref={cityFocus}
                            value={values.city || "Select"}
                            onChange={(value) => changeCityDropDown(value)}
                            options={cityList}
                            filterOption={customFilter}
                          />
                          <span style={{ color: "red" }}>{errors?.city}</span>
                        </div>
                      </div>

                      <div class="form-group row">
                        <div class="form-group col-md-4 required">
                          <label for="inputPaymentType">
                            Select Customer *
                          </label>
                          <Select
                            id="inputCustomer"
                            ref={customerFocus}
                            isDisabled={disabled}
                            value={values.customer || "Select"}
                            onChange={(value) => changeCustomerDropDown(value)}
                            options={customerList}
                            filterOption={customFilter}
                          />
                          <span style={{ color: "red" }}>
                            {errors?.customer}
                          </span>
                        </div>
                        <div class="form-group col-md-4 required">
                          <label for="inputMobile">Sales Amount *</label>
                          <label
                            class="form-error-msg"
                            id="mobileErrorMsg"
                          ></label>
                          <input
                            type="number"
                            class="form-control"
                            ref={salesFocus}
                            disabled={disabled}
                            id="inputMobile"
                            name="sales"
                            placeholder="Enter Sales Amount"
                            onChange={(e) =>
                              setInput({ ...input, sales: e.target.value })
                            }
                            value={input.sales}
                          />
                          <span style={{ color: "red" }}>{errors?.sales}</span>
                        </div>
                        <div class="form-group col-md-4 required">
                          <label for="inputMobile">Payout Amount *</label>
                          <label
                            class="form-error-msg"
                            id="mobileErrorMsg"
                          ></label>
                          <input
                            type="number"
                            class="form-control"
                            ref={payoutFocus}
                            disabled={disabled}
                            id="inputMobile"
                            name="payout"
                            placeholder="Enter Payout Amount"
                            onChange={(e) =>
                              setInput({ ...input, payout: e.target.value })
                            }
                            value={input.payout}
                          />
                          <span style={{ color: "red" }}>{errors?.payout}</span>
                        </div>
                      </div>

                      <div class="form-group row">
                        <div class="form-group col-md-6 required">
                          <label for="address">No Of Vehicle *</label>
                          <input
                            type="number"
                            name="vehicle"
                            ref={vehicleFocus}
                            disabled={disabled}
                            class="form-control"
                            id="inputaddress"
                            placeholder="Enter No of Vehicle"
                            onChange={(e) =>
                              setInput({ ...input, vehicle: e.target.value })
                            }
                            value={input.vehicle}
                          />
                          <span style={{ color: "red" }}>
                            {errors?.vehicle}
                          </span>
                        </div>
                        <div class="form-group col-md-6 required">
                          <label for="address">No Of Rider *</label>
                          <input
                            type="number"
                            name="rider"
                            ref={riderFocus}
                            disabled={disabled}
                            class="form-control"
                            id="inputaddress"
                            placeholder="Enter No of Rider"
                            onChange={(e) =>
                              setInput({ ...input, rider: e.target.value })
                            }
                            value={input.rider}
                          />
                          <span style={{ color: "red" }}>{errors?.rider}</span>
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={() => setEditFormEditable(false)}
                      hidden={cancelButtonHide}
                      type="reset"
                      className="btn btn-danger mt-3"
                    >
                      Reset
                    </button>
                    &nbsp;
                    <button
                      hidden={submitButtonHide}
                      type="submit"
                      className="btn btn-success mt-3 view-btn"
                    >
                      Update
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </>
  );
};
export default SalesPayoutView;
