import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import * as action from "../../../../../../action/Dashboard/dashboard_action";
import { useDispatch } from "react-redux";

const SwiggyChart = ({ monthNames, cityYearFilterData }) => {
  const dispatch = useDispatch();

  const [swiggyBillingData, setSwiggyBillingData] = useState({
    businessSupportFees: [],
    delfees: [],
    tips: [],
    recovery: [],
    redFlags: [],
  });

  // swiggy billing analysis
  useEffect(() => {
    dispatch(action.dashboard_swiggy_billing_chart(cityYearFilterData))
      .then((data) => {
        let swiggyData = data.data;
        let businessSupportFeesData = [];
        let delfeesData = [];
        let tips = [];
        let recovery = [];
        let redFlags = [];

        swiggyData.map((item) => {
          businessSupportFeesData.push(item.businessSupportFees);
          delfeesData.push(item.delFees);
          tips.push(item.tips);
          recovery.push(item.recovery);
          redFlags.push(item.redFlags);
        });

        setSwiggyBillingData({
          ...swiggyBillingData,
          businessSupportFees: businessSupportFeesData,
          delfees: delfeesData,
          tips: tips,
          recovery: recovery,
          redFlags: redFlags,
        });
      })
      .catch({});
  }, [cityYearFilterData]);

  //  Swiggy - billing analysis
  var billingSwiggyAnalysis = {
    chart: {
      height: 300,
      type: "line",
      fontFamily: "Apple-System, sans-serif",
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "Swiggy",
      align: "center",
      margin: 10,
      offsetX: -10,
      offsetY: 10,
      floating: false,
      style: {
        fontSize: "20px",
        color: "#0e1726",
      },
    },
    stroke: {
      curve: "straight",
    },
    series: [
      {
        name: "Business Support Fees",
        data: swiggyBillingData.businessSupportFees,
      },
      {
        name: "DelFees",
        data: swiggyBillingData.delfees,
      },
      {
        name: "Tips",
        data: swiggyBillingData.tips,
      },
      {
        name: "Recovery",
        data: swiggyBillingData.recovery,
      },
      {
        name: "Red Flags",
        data: swiggyBillingData.redFlags,
      },
    ],
    xaxis: {
      categories: monthNames,
    },
    legend: {
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 1000,
        yaxis: {
          categories: monthNames,
        },
        options: {
          plotOptions: {
            bar: {
              horizontal: true,
              columnWidth: "80%",
            },
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div className="col" id="chart">
      <Chart
        options={billingSwiggyAnalysis}
        type="line"
        series={billingSwiggyAnalysis.series}
        height={300}
      />
    </div>
  );
};

export default SwiggyChart;
