import React from 'react'
import { format } from 'date-fns'
import ReactHTMLTableToExcel from "react-html-table-to-excel";
export const ExportToExcel = props => {
    const apiData = props.apiData;
    const startDate = props.startDate;
    const endDate = props.endDate;
	const changeDateFormate = (date) => {
		if (date) {
			let new_date = String(date);
			let ndate = new_date.split('T');
			let format_date = format(new Date(ndate[0]), 'dd, MMM yyyy');
			return format_date;
		}
	}
    const changeDate = (date) => {
		if (date) {
			let cd = new Date(date);
            let format_date = format(new Date(cd), 'dd, MMM yyyy');
            return format_date;
		}
	}
    return (
      <>
	  	<ReactHTMLTableToExcel id="test-table-xls-button" className="download-table-xls-button btn btn-success" table="driver_left_list" filename="driver_left_list" sheet="tablexls" buttonText="Export as XLS"/>
          <table id="driver_left_list" style={{display:'none', textAlign:"center"}}>
			<thead>
				<tr style={{padding: '45px'}}>
					<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Driver Id</th>
					<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Driver Name</th>
					<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Mobile</th>
					<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Rider Master Code</th>
					<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Left Reason</th>
				</tr>
			</thead>
			<tbody>
				{
					apiData.map((item)=>(
						<tr style={{textAlign: 'center'}}>
							<td style={{border:'1px solid black'}}>{item[0]}</td>
							<td style={{border:'1px solid black'}}>{item[1]}</td>
							<td style={{border:'1px solid black'}}>{item[2]}</td>
							<td style={{border:'1px solid black'}}>{item[3]}</td>
							<td style={{border:'1px solid black'}}>{item[4]}</td>
						</tr>
					))
				}
			</tbody>
		</table>
	  </>
    );
};