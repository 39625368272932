import {
  VIHICLE_LIST_SUCCESSFULL,
  VIHICLE_LIST_ERROR,
  VIHICLE_LIST_LOADING,
  VIHICLE_STATUS_CHANGE_SUCCESSFULL,
  VIHICLE_STATUS_CHANGE_ERROR,
  VIHICLE_STATUS_CHANGE_LOADING,
  VIHICLE_ADD_SUCCESSFULL,
  VIHICLE_ADD_ERROR,
  VIHICLE_ADD_LOADING,
  VIHICLE_DELETE_FILE_SUCCESSFULL,
  VIHICLE_DELETE_FILE_ERROR,
  VIHICLE_DELETE_FILE_LOADING,
  VIHICLE_DETAILS_SUCCESSFULL,
  VIHICLE_DETAILS_ERROR,
  VIHICLE_DETAILS_LOADING,
  VIHICLE_EDIT_SUCCESSFULL,
  VIHICLE_EDIT_ERROR,
  VIHICLE_EDIT_LOADING,
  VIHICLE_LEASE_COMPANY_LIST_SELECT_SUCCESSFULL,
  VIHICLE_LEASE_COMPANY_LIST_SELECT_ERROR,
  VIHICLE_LEASE_COMPANY_LIST_SELECT_LOADING,
  VIHICLE_TELEMATICS_UNIT_LIST_SELECT_SUCCESSFULL,
  VIHICLE_TELEMATICS_UNIT_LIST_SELECT_ERROR,
  VIHICLE_TELEMATICS_UNIT_LIST_SELECT_LOADING,
  VIHICLE_HUB_LIST_SELECT_SUCCESSFULL,
  VIHICLE_HUB_LIST_SELECT_ERROR,
  VIHICLE_HUB_LIST_SELECT_LOADING,
  BIKE_TYPE_LIST_SELECT_SUCCESSFULL,
  BIKE_TYPE_LIST_SELECT_ERROR,
  BIKE_TYPE_LIST_SELECT_LOADING,
  BIKE_TYPE_DETAILS_SUCCESSFULL,
  BIKE_TYPE_DETAILS_ERROR,
  BIKE_TYPE_DETAILS_LOADING,
  VEHICLE_LEASE_COMPANY_DETAILS_LOADING,
  VEHICLE_LEASE_COMPANY_DETAILS_SUCCESSFULL,
  VEHICLE_LEASE_COMPANY_DETAILS_ERROR,
  DRIVER_LIST_VEHICLE_USE_SUCCESSFULL,
  DRIVER_LIST_VEHICLE_USE_ERROR,
  DRIVER_LIST_VEHICLE_USE_LOADING,
  VEHICLE_SCAN_MANDATORY,
  VEHICLE_SCAN_MANDATORY_STATUS_CHANGE,
  VEHICLE_SCAN_MANDATORY_ERROR,
  VEHICLE_SCAN_MANDATORY_CURRENT_STATUS,
  VEHICLE_SCAN_MANDATORY_CURRENT_STATUS_GET_SUCCESS,
  VEHICLE_SCAN_MANDATORY_STATUS_GET_ERROR,
  ADD_EXPENSE_FORM_STATUS,
  ADD_EXPENSE_FORM_SUCCESS,
  ADD_EXPENSE_FORM_ERROR,
  GET_ALL_EXPENSE_FORM_STATUS,
  GET_ALL_EXPENSE_FORM_SUCCESS,
  GET_ALL_EXPENSE_FORM_ERROR,
  EDIT_EXPENSE_ISSUE_STATUS,
  EDIT_EXPENSE_ISSUE_SUCCESS,
  EDIT_EXPENSE_ISSUE_ERROR,
  ADD_FLEET_UTILIZED_STATUS,
  ADD_FLEET_UTILIZED_SUCCESS,
  ADD_FLEET_UTILIZED_ERROR,
  EDIT_FLEET_UTILIZED_STATUS,
  EDIT_FLEET_UTILIZED_SUCCESS,
  EDIT_FLEET_UTILIZED_ERROR,
  GET_FLEET_UTILIZED_STATUS,
  GET_FLEET_UTILIZED_SUCCESS,
  GET_FLEET_UTILIZED_ERROR,
  GET_ONE_FLEET_UTILIZED_STATUS,
  GET_ONE_FLEET_UTILIZED_SUCCESS,
  GET_ONE_FLEET_UTILIZED_ERROR,
  GET_VEHICLE_STATUS_SUMMARY_STATUS,
  GET_VEHICLE_STATUS_SUMMARY_SUCCESS,
  GET_VEHICLE_STATUS_SUMMARY_ERROR,
} from "../../action/type";

import * as AuthService from "../../service/services";

export const Vehicle_list = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: VIHICLE_LIST_LOADING,
        data: true,
      });
      AuthService.VehicleList()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: VIHICLE_LIST_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: VIHICLE_LIST_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const driver_use_vehicle_list = (start_date, end_date, vehicle_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_LIST_VEHICLE_USE_LOADING,
        data: true,
      });
      AuthService.AllVehicleWorkList(start_date, end_date, vehicle_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_LIST_VEHICLE_USE_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_LIST_VEHICLE_USE_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const vehicle_status_change = (vehicle_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: VIHICLE_STATUS_CHANGE_LOADING,
        data: true,
      });
      AuthService.VehicleStatusChange(vehicle_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: VIHICLE_STATUS_CHANGE_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: VIHICLE_STATUS_CHANGE_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const vehicle_file_upload = (credential) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: VIHICLE_STATUS_CHANGE_LOADING,
        data: true,
      });
      AuthService.VehicleFileUpload(credential)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: VIHICLE_STATUS_CHANGE_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: VIHICLE_STATUS_CHANGE_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const add_vehicle = (credential) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: VIHICLE_ADD_LOADING,
        data: true,
      });
      AuthService.AddVehicle(credential)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: VIHICLE_ADD_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: VIHICLE_ADD_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const delete_vehicle_file = (credential) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: VIHICLE_DELETE_FILE_LOADING,
        data: true,
      });
      AuthService.DeleteVehicleFile(credential)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: VIHICLE_DELETE_FILE_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: VIHICLE_DELETE_FILE_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const vehicle_details = (vehicle_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: VIHICLE_DETAILS_LOADING,
        data: true,
      });
      AuthService.VehicleDetails(vehicle_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: VIHICLE_DETAILS_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: VIHICLE_DETAILS_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const edit_vehicle = (credential) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: VIHICLE_EDIT_LOADING,
        data: true,
      });
      AuthService.EditVehicle(credential)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: VIHICLE_EDIT_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: VIHICLE_EDIT_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const vehicle_lease_company_list_select = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: VIHICLE_LEASE_COMPANY_LIST_SELECT_LOADING,
        data: true,
      });
      AuthService.VehicleLeaseCompanyListSelect()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: VIHICLE_LEASE_COMPANY_LIST_SELECT_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: VIHICLE_LEASE_COMPANY_LIST_SELECT_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const vehicle_lease_company_details = (vehicle_lease_company_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: VEHICLE_LEASE_COMPANY_DETAILS_LOADING,
        data: true,
      });
      AuthService.VehicleLeaseCompanyDetails(vehicle_lease_company_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: VEHICLE_LEASE_COMPANY_DETAILS_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: VEHICLE_LEASE_COMPANY_DETAILS_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const vehicle_telematics_unit_list_select = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: VIHICLE_TELEMATICS_UNIT_LIST_SELECT_LOADING,
        data: true,
      });
      AuthService.VehicleTelematicsUnitListSelect()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: VIHICLE_TELEMATICS_UNIT_LIST_SELECT_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: VIHICLE_TELEMATICS_UNIT_LIST_SELECT_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const vehicle_hub_list_select = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: VIHICLE_HUB_LIST_SELECT_LOADING,
        data: true,
      });
      AuthService.VehicleHubsListSelect()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: VIHICLE_HUB_LIST_SELECT_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: VIHICLE_HUB_LIST_SELECT_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const vehicle_bike_type_select = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: BIKE_TYPE_LIST_SELECT_LOADING,
        data: true,
      });
      AuthService.GetBikeTypeListSelect()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: BIKE_TYPE_LIST_SELECT_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: BIKE_TYPE_LIST_SELECT_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const bike_type_details = (bike_type_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: BIKE_TYPE_DETAILS_LOADING,
        data: true,
      });
      AuthService.BikeTypeDetails(bike_type_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: BIKE_TYPE_DETAILS_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: BIKE_TYPE_DETAILS_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const Scan_Bike_Mandatory = (statusVehicals) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: VEHICLE_SCAN_MANDATORY,
        data: true,
      });
      AuthService.Scan_Bike_Status_Change(statusVehicals)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: VEHICLE_SCAN_MANDATORY_STATUS_CHANGE,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: VEHICLE_SCAN_MANDATORY_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const Scan_Bike_Mandatory_Status = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: VEHICLE_SCAN_MANDATORY_CURRENT_STATUS,
        data: true,
      });
      AuthService.Scan_Bike_Status_Get()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: VEHICLE_SCAN_MANDATORY_CURRENT_STATUS_GET_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: VEHICLE_SCAN_MANDATORY_STATUS_GET_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const Add_Expense_Form = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADD_EXPENSE_FORM_STATUS,
        data: true,
      });
      AuthService.AddExpenseForm(data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ADD_EXPENSE_FORM_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: ADD_EXPENSE_FORM_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const Get_All_Expense_Form = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: GET_ALL_EXPENSE_FORM_STATUS,
        data: true,
      });
      AuthService.GetAllExpenseForm()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_ALL_EXPENSE_FORM_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: GET_ALL_EXPENSE_FORM_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const Edit_Expense_Issue = (id, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: EDIT_EXPENSE_ISSUE_STATUS,
        data: true,
      });
      AuthService.EditExpenseIssue(id, data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: EDIT_EXPENSE_ISSUE_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: EDIT_EXPENSE_ISSUE_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const Add_Fleet_Efficiency = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADD_FLEET_UTILIZED_STATUS,
        data: true,
      });
      AuthService.AddFleetEfficiency(data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ADD_FLEET_UTILIZED_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: ADD_FLEET_UTILIZED_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const Edit_Fleet_Efficiency = (id, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: EDIT_FLEET_UTILIZED_STATUS,
        data: true,
      });
      AuthService.EditFleetEfficiency(id, data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: EDIT_FLEET_UTILIZED_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: EDIT_FLEET_UTILIZED_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const Get_Fleet_Efficiency = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: GET_FLEET_UTILIZED_STATUS,
        data: true,
      });
      AuthService.GetFleetEfficiency()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_FLEET_UTILIZED_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: GET_FLEET_UTILIZED_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const Get_One_Fleet_Efficiency = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: GET_ONE_FLEET_UTILIZED_STATUS,
        data: true,
      });
      AuthService.GetOneFleetEfficiency(id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_ONE_FLEET_UTILIZED_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: GET_ONE_FLEET_UTILIZED_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const get_vehicle_status_summary = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: GET_VEHICLE_STATUS_SUMMARY_STATUS,
        data: true,
      });
      AuthService.VehicleStatusSummary()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_VEHICLE_STATUS_SUMMARY_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: GET_VEHICLE_STATUS_SUMMARY_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
