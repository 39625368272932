import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import Header from "../../DashBoard/Header";
import Sub_Header from "../../DashBoard/Sub_Header";
import SideBar from "../../DashBoard/SideBar";
import { ToastContainer, toast } from "react-toastify";
import * as action from "../../../action/Dashboard/dashboard_action";
import * as Customeraction from "../../../action/Customer/customer_action";
import * as driveraction from "../../../action/Driver/driver_action";
import { Input } from "rsuite";
import { RxCross2 } from "react-icons/rx";
import $ from "jquery";
import Pagination from "../../common/Pagination/Pagination";
import * as common from "../../../action/common/common_action";
import Footer from "../../DashBoard/Footer";

const RiderEarning = () => {
  const yearAndMonthFocus = useRef();
  const cityFocus = useRef();
  const customerFocus = useRef();
  const fullTimeUpperLimitFocus = useRef();
  const noOfFullTimeRidersFocus = useRef();
  const partTimeUpperLimitFocus = useRef();
  const noOfPartTimeRidersFocus = useRef();

  const edityearAndMonthFocus = useRef();
  const editCityFocus = useRef();
  const editCustomerFocus = useRef();
  const editfullTimeUpperLimitFocus = useRef();
  const editnoOfFullTimeRidersFocus = useRef();
  const editpartTimeUpperLimitFocus = useRef();
  const editnoOfPartTimeRidersFocus = useRef();

  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

  const upperLimitOptions = [
    {
      value: 3000,
      label: "₹0-₹3000",
    },
    {
      value: 6000,
      label: "₹3001-₹6000",
    },
    {
      value: 9000,
      label: "₹6001-₹9000",
    },
    {
      value: 12000,
      label: "₹9001-₹12000",
    },
    {
      value: 15000,
      label: "₹12001-₹15000",
    },
    {
      value: 18000,
      label: "₹15001-₹18000",
    },
    {
      value: 21000,
      label: "₹18001-₹21000",
    },
    {
      value: 24000,
      label: "₹21001-₹24000",
    },
    {
      value: 27000,
      label: "₹24001-₹27000",
    },
    {
      value: 30000,
      label: "₹27001-₹30000",
    },
  ];

  const [cityList, setCityList] = useState([]);
  const [customerNameList, setCustomerNameList] = useState([]);
  const [formId, setFormId] = useState();
  const [earningData, setEarningData] = useState([]);
  const [addRiderEarning, setAddRiderEarning] = useState({
    yearAndMonth: "",
    cityId: "",
    customerId: "",
    fullTimeUpperLimit: "",
    noOfFullTimeRiders: "",
    partTimeUpperLimit: "",
    noOfPartTimeRiders: "",
  });

  const [editRiderEarning, setEditRiderEarning] = useState({
    yearAndMonth: "",
    cityId: "",
    customerId: "",
    fullTimeUpperLimit: "",
    noOfFullTimeRiders: "",
    partTimeUpperLimit: "",
    noOfPartTimeRiders: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [disabled, setDisabled] = useState(true);
  const [editButtonHide, setEditButtonHide] = useState(false);
  const [submitButtonHide, setSubmitButtonHide] = useState(true);

  const setEditFormEditable = (type) => {
    if (type) {
      setDisabled(false);
      setSubmitButtonHide(false);
      setEditButtonHide(true);
    } else {
      setDisabled(true);
      setSubmitButtonHide(true);
      setEditButtonHide(false);
    }
  };

  // city list
  useEffect(() => {
    dispatch(common.common_city_list())
      .then((data) => {
        let newCustomerList = [];
        data.data.map((item) => {
          newCustomerList.push({ value: item._id, label: item.cityName });
        });
        setCityList(newCustomerList);
      })
      .catch({});
  }, {});

  // customer list
  useEffect(() => {
    dispatch(Customeraction.customer_list())
      .then((data) => {
        let newCustomerList = [];
        data.data.map((item) => {
          newCustomerList.push({ value: item._id, label: item.businessName });
        });
        setCustomerNameList(newCustomerList);
      })
      .catch({});
  }, []);

  useEffect(() => {
    dispatch(driveraction.driver_get_riders_earning())
      .then((data) => {
        let parcels = data.data;
        setEarningData(parcels);
      })
      .catch((err) => {});
  }, []);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setErrors({});
    let customErrors = {};

    if (!addRiderEarning.yearAndMonth) {
      customErrors = {
        ...customErrors,
        yearAndMonth: "Please Select Year Month",
      };
      yearAndMonthFocus.current.focus();
    } else if (!addRiderEarning.cityId) {
      customErrors = { ...customErrors, cityId: "Please Select City" };
      cityFocus.current.focus();
    } else if (!addRiderEarning.customerId) {
      customErrors = { ...customErrors, customerId: "Please Select Customer" };
      customerFocus.current.focus();
    } else if (!addRiderEarning.fullTimeUpperLimit) {
      customErrors = {
        ...customErrors,
        fullTimeUpperLimit: "Please Select Payment Slab",
      };
      fullTimeUpperLimitFocus.current.focus();
    } else if (!addRiderEarning.noOfFullTimeRiders) {
      customErrors = {
        ...customErrors,
        noOfFullTimeRiders: "Enter No. of Riders",
      };
      noOfFullTimeRidersFocus.current.focus();
    } else if (!addRiderEarning.partTimeUpperLimit) {
      customErrors = {
        ...customErrors,
        partTimeUpperLimit: "Please Select Payment Slab",
      };
      partTimeUpperLimitFocus.current.focus();
    } else if (!addRiderEarning.noOfPartTimeRiders) {
      customErrors = {
        ...customErrors,
        noOfPartTimeRiders: "Enter No. of Riders",
      };
      noOfPartTimeRidersFocus.current.focus();
    }

    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }

    dispatch(driveraction.driver_add_riders_earning(addRiderEarning))
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          setEarningData(data?.data);
          $("#addEarningForm .close").click();
        } else if (!data.success) {
          toast.error(data.message);
        }
      })
      .catch((err) => {});

    setAddRiderEarning({
      yearAndMonth: "",
      cityId: "",
      customerId: "",
      fullTimeUpperLimit: "",
      noOfFullTimeRiders: "",
      partTimeUpperLimit: "",
      noOfPartTimeRiders: "",
    });
    setErrors({});
  };

  const onUpdateHandler = (e) => {
    e.preventDefault();
    setErrors({});
    let customErrors = {};

    if (!editRiderEarning.yearAndMonth) {
      customErrors = {
        ...customErrors,
        yearAndMonth: "Please Select Year Month",
      };
      edityearAndMonthFocus.current.focus();
    } else if (!editRiderEarning.cityId) {
      customErrors = { ...customErrors, cityId: "Please Select City" };
      editCityFocus.current.focus();
    } else if (!editRiderEarning.customerId) {
      customErrors = { ...customErrors, customerId: "Please Select Customer" };
      editCustomerFocus.current.focus();
    } else if (!editRiderEarning.fullTimeUpperLimit) {
      customErrors = {
        ...customErrors,
        fullTimeUpperLimit: "Please Select Payment Slab",
      };
      editfullTimeUpperLimitFocus.current.focus();
    } else if (!editRiderEarning.noOfFullTimeRiders) {
      customErrors = {
        ...customErrors,
        noOfFullTimeRiders: "Enter No. of Riders",
      };
      editnoOfFullTimeRidersFocus.current.focus();
    } else if (!editRiderEarning.partTimeUpperLimit) {
      customErrors = {
        ...customErrors,
        partTimeUpperLimit: "Please Select Payment Slab",
      };
      editpartTimeUpperLimitFocus.current.focus();
    } else if (!editRiderEarning.noOfPartTimeRiders) {
      customErrors = {
        ...customErrors,
        noOfPartTimeRiders: "Enter No. of Riders",
      };
      editnoOfPartTimeRidersFocus.current.focus();
    }

    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }

    dispatch(driveraction.driver_edit_riders_earning(formId, editRiderEarning))
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          setEarningData(data?.data);
          $("#editEarningForm .close").click();
          setEditFormEditable(false);
        } else if (!data.success) {
          toast.error(data.message);
        }
      })
      .catch((err) => {});

    setErrors({});
  };

  const handleEditClick = (item) => {
    setFormId(item._id);
    const formattedMonth =
      item.month < 10 && item.month > 0 ? `0${item.month}` : item.month;

    let yearAndMonth = `${item.year}-${formattedMonth}`;

    setEditRiderEarning({
      yearAndMonth: yearAndMonth,
      cityId: item.cityId,
      customerId: item.customerId,
      fullTimeUpperLimit: item.fullTimeUpperLimit,
      noOfFullTimeRiders: item.noOfFullTimeRiders,
      partTimeUpperLimit: item.partTimeUpperLimit,
      noOfPartTimeRiders: item.noOfPartTimeRiders,
    });

    setErrors({});
  };

  const handleCancelClick = () => {
    setEditFormEditable(false);
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * 10;
    const lastPageIndex = firstPageIndex + 10;
    return earningData?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, earningData]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Sub_Header />
      <div className="main-container" id="container">
        <div className="overlay"></div>
        <div className="search-overlay"></div>
        <SideBar />
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                <div className="widget-content widget-content-area br-6">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="" style={{ margin: "10px" }}>
                      Drivers Earning
                    </h5>
                    <a
                      href="/"
                      className="btn btn-warning btn-sm add-act-btn"
                      data-toggle="modal"
                      data-target="#addEarningForm"
                    >
                      Add
                    </a>
                  </div>

                  <br />
                  <div className="table-responsive mb-4 mt-4">
                    {1 ? (
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>Customer</th>
                            <th>Month</th>
                            <th>Year</th>
                            <th>City</th>
                            <th>Full Time PaymentSlab</th>
                            <th>Full Time Riders</th>
                            <th>Part Time PaymentSlab</th>
                            <th>Part Time Riders</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentTableData?.map((item, i) => {
                            const fulltimepaymentSlab = upperLimitOptions.find(
                              (element) =>
                                element.value === item.fullTimeUpperLimit
                            );
                            const parttimepaymentSlab = upperLimitOptions.find(
                              (element) =>
                                element.value === item.partTimeUpperLimit
                            );
                            return (
                              <tr>
                                <td>{item.customerName}</td>
                                <td>{item.month}</td>
                                <td>{item.year}</td>
                                <td>{item.cityName}</td>
                                <td>{fulltimepaymentSlab?.label}</td>
                                <td>{item.noOfFullTimeRiders}</td>
                                <td>{parttimepaymentSlab?.label}</td>
                                <td>{item.noOfPartTimeRiders}</td>
                                <td>
                                  <a
                                    href="/"
                                    onClick={() => handleEditClick(item)}
                                    className="btn btn-info btn-sm edit-btn edit-act-btn"
                                    data-toggle="modal"
                                    data-target="#editEarningForm"
                                  >
                                    Edit
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <p className="text-center">No data Available</p>
                    )}
                    {/* pagination */}
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={earningData.length}
                      pageSize={10}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>

      {/* add model */}
      <div
        className="modal fade"
        id="addEarningForm"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addEarningFormTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <form onSubmit={onSubmitHandler}>
              <div className="modal-header">
                <h5 className="modal-title" id="addHubModalCenterTitle">
                  Add Driver Earning
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <RxCross2 />
                </button>
              </div>
              <div className="modal-body">
                <div
                  className="customer-address-class"
                  id="customer-address-div-id"
                >
                  <div className="row" id="defaultaddressdiv0">
                    <div className="col-12">
                      <div id="append-div-id">
                        <div class="form-group row">
                          <div class="form-group col-4 required">
                            <label>Select Year and Month *</label>
                            <Input
                              type="month"
                              ref={yearAndMonthFocus}
                              name="yearAndMonth"
                              value={addRiderEarning?.yearAndMonth}
                              onChange={(value) =>
                                setAddRiderEarning({
                                  ...addRiderEarning,
                                  yearAndMonth: value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.yearAndMonth}
                            </span>
                          </div>
                          <div class="form-group col-md-4 required">
                            <label>Select City *</label>
                            <select
                              id="cityId"
                              ref={cityFocus}
                              className="form-control selectpicker"
                              value={addRiderEarning?.cityId}
                              onChange={(e) => {
                                setAddRiderEarning({
                                  ...addRiderEarning,
                                  cityId: e.target.value,
                                });
                              }}
                            >
                              <option value="">Select City</option>
                              {cityList.map((item) => {
                                return (
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            <span style={{ color: "red" }}>
                              {errors?.cityId}
                            </span>
                          </div>

                          <div class="form-group col-md-4 required">
                            <label>Select Customer *</label>
                            <select
                              id="customerId"
                              ref={customerFocus}
                              className="form-control selectpicker"
                              value={addRiderEarning?.customerId}
                              onChange={(e) =>
                                setAddRiderEarning({
                                  ...addRiderEarning,
                                  customerId: e.target.value,
                                })
                              }
                            >
                              <option value="">Select Customer</option>
                              {customerNameList.map((item) => {
                                return (
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            <span style={{ color: "red" }}>
                              {errors?.customerId}
                            </span>
                          </div>
                        </div>
                        <h6 className="mb-2">Full Time</h6>
                        <div class="form-group row">
                          <div class="form-group col-md-4 required">
                            <label for="fullTimeUpperLimit">
                              Payment Slab *
                            </label>
                            <select
                              id="fullTimeUpperLimit"
                              ref={fullTimeUpperLimitFocus}
                              className="form-control selectpicker"
                              value={addRiderEarning?.fullTimeUpperLimit}
                              onChange={(e) =>
                                setAddRiderEarning({
                                  ...addRiderEarning,
                                  fullTimeUpperLimit: e.target.value,
                                })
                              }
                            >
                              <option value="">Select Payment Slab</option>
                              {upperLimitOptions.map((item) => {
                                return (
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            <span style={{ color: "red" }}>
                              {errors?.fullTimeUpperLimit}
                            </span>
                          </div>
                          <div class="form-group col-md-4 required">
                            <label>No of Riders</label>
                            <input
                              type="number"
                              min="0"
                              name="noOfFullTimeRiders"
                              className="form-control"
                              ref={noOfFullTimeRidersFocus}
                              placeholder="No of Pickup Parcel"
                              value={addRiderEarning?.noOfFullTimeRiders}
                              onChange={(e) =>
                                setAddRiderEarning({
                                  ...addRiderEarning,
                                  noOfFullTimeRiders: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.noOfFullTimeRiders}
                            </span>
                          </div>
                        </div>
                        <h6 className="mb-2">Part Time</h6>
                        <div class="form-group row">
                          <div class="form-group col-md-4 required">
                            <label for="partTimeUpperLimit">
                              Payment Slab *
                            </label>
                            <select
                              id="partTimeUpperLimit"
                              ref={partTimeUpperLimitFocus}
                              className="form-control selectpicker"
                              value={addRiderEarning?.partTimeUpperLimit}
                              onChange={(e) =>
                                setAddRiderEarning({
                                  ...addRiderEarning,
                                  partTimeUpperLimit: e.target.value,
                                })
                              }
                            >
                              <option value="">Select Payment Slab</option>
                              {upperLimitOptions.map((item) => {
                                return (
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            <span style={{ color: "red" }}>
                              {errors?.partTimeUpperLimit}
                            </span>
                          </div>
                          <div class="form-group col-md-4 required">
                            <label>No of Riders</label>
                            <input
                              type="number"
                              min="0"
                              name="noOfPartTimeRiders"
                              className="form-control"
                              ref={noOfPartTimeRidersFocus}
                              placeholder="No of Pickup Parcel"
                              value={addRiderEarning?.noOfPartTimeRiders}
                              onChange={(e) =>
                                setAddRiderEarning({
                                  ...addRiderEarning,
                                  noOfPartTimeRiders: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.noOfPartTimeRiders}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="reset"
                  data-dismiss="modal"
                  className="btn btn-danger "
                  onClick={handleCancelClick}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success save-btn">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* update model */}
      <div
        className="modal fade"
        id="editEarningForm"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editEarningFormTitle"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <form onSubmit={onUpdateHandler}>
              <div className="modal-header">
                <h5 className="modal-title" id="editEarningFormTitle">
                  Driver Earning
                </h5>
                <button
                  type="button"
                  className="btn btn-primary view-btn"
                  hidden={editButtonHide}
                  onClick={() => setEditFormEditable(true)}
                >
                  Edit
                </button>
              </div>

              <div className="modal-body">
                <div
                  className="customer-address-class"
                  id="customer-address-div-id"
                >
                  <div className="row" id="defaultaddressdiv0">
                    <div className="col-12">
                      <div id="append-div-id">
                        <div class="form-group row">
                          <div class="form-group col-4 required">
                            <label>Select Year and Month *</label>
                            <Input
                              disabled={disabled}
                              type="month"
                              ref={edityearAndMonthFocus}
                              name="yearAndMonth"
                              value={editRiderEarning?.yearAndMonth}
                              onChange={(value) =>
                                setEditRiderEarning({
                                  ...editRiderEarning,
                                  yearAndMonth: value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.yearAndMonth}
                            </span>
                          </div>
                          <div class="form-group col-md-4 required">
                            <label for="cityId">Select City *</label>
                            <select
                              disabled={disabled}
                              id="cityId"
                              ref={editCityFocus}
                              className="form-control selectpicker"
                              value={editRiderEarning?.cityId}
                              onChange={(e) => {
                                setEditRiderEarning({
                                  ...editRiderEarning,
                                  cityId: e.target.value,
                                });
                              }}
                            >
                              <option value="">Select City</option>
                              {cityList.map((item) => {
                                return (
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            <span style={{ color: "red" }}>
                              {errors?.cityId}
                            </span>
                          </div>

                          <div class="form-group col-md-4 required">
                            <label for="inputPaymentType">
                              Select Customer *
                            </label>

                            <select
                              disabled={disabled}
                              id="customerId"
                              ref={editCustomerFocus}
                              className="form-control selectpicker"
                              value={editRiderEarning?.customerId}
                              onChange={(e) =>
                                setEditRiderEarning({
                                  ...editRiderEarning,
                                  customerId: e.target.value,
                                })
                              }
                            >
                              <option value="">Select Customer</option>
                              {customerNameList.map((item) => {
                                return (
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            <span style={{ color: "red" }}>
                              {errors?.customerId}
                            </span>
                          </div>
                        </div>
                        <h6 className="mb-2">Full Time</h6>
                        <div class="form-group row">
                          <div class="form-group col-md-4 required">
                            <label for="fullTimeUpperLimit">
                              Payment Slab *
                            </label>
                            <select
                              disabled={disabled}
                              id="fullTimeUpperLimit"
                              ref={editfullTimeUpperLimitFocus}
                              className="form-control selectpicker"
                              value={editRiderEarning?.fullTimeUpperLimit}
                              onChange={(e) =>
                                setEditRiderEarning({
                                  ...editRiderEarning,
                                  fullTimeUpperLimit: e.target.value,
                                })
                              }
                            >
                              <option value="">Select Payment Slab</option>
                              {upperLimitOptions.map((item) => {
                                return (
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            <span style={{ color: "red" }}>
                              {errors?.fullTimeUpperLimit}
                            </span>
                          </div>
                          <div class="form-group col-md-4 required">
                            <label>No of Riders</label>
                            <input
                              disabled={disabled}
                              type="number"
                              min="0"
                              name="noOfFullTimeRiders"
                              ref={editnoOfFullTimeRidersFocus}
                              className="form-control"
                              placeholder="No of Pickup Parcel"
                              value={editRiderEarning?.noOfFullTimeRiders}
                              onChange={(e) =>
                                setEditRiderEarning({
                                  ...editRiderEarning,
                                  noOfFullTimeRiders: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.noOfFullTimeRiders}
                            </span>
                          </div>
                        </div>
                        <h6 className="mb-2">Part Time</h6>
                        <div class="form-group row">
                          <div class="form-group col-md-4 required">
                            <label for="partTimeUpperLimit">
                              Payment Slab *
                            </label>
                            <select
                              disabled={disabled}
                              id="partTimeUpperLimit"
                              ref={editpartTimeUpperLimitFocus}
                              className="form-control selectpicker"
                              value={editRiderEarning?.partTimeUpperLimit}
                              onChange={(e) =>
                                setEditRiderEarning({
                                  ...editRiderEarning,
                                  partTimeUpperLimit: e.target.value,
                                })
                              }
                            >
                              <option value="">Select Payment Slab</option>
                              {upperLimitOptions.map((item) => {
                                return (
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            <span style={{ color: "red" }}>
                              {errors?.partTimeUpperLimit}
                            </span>
                          </div>
                          <div class="form-group col-md-4 required">
                            <label for="noOfPartTimeRiders">No of Riders</label>
                            <input
                              disabled={disabled}
                              type="number"
                              min="0"
                              name="noOfPartTimeRiders"
                              ref={editnoOfPartTimeRidersFocus}
                              className="form-control"
                              placeholder="No of Pickup Parcel"
                              value={editRiderEarning?.noOfPartTimeRiders}
                              onChange={(e) =>
                                setEditRiderEarning({
                                  ...editRiderEarning,
                                  noOfPartTimeRiders: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.noOfPartTimeRiders}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary view-btn"
                  hidden={submitButtonHide}
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default RiderEarning;
