import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Chart from "react-apexcharts";
import * as action from "../../../../action/Dashboard/dashboard_action";

const NoOfSubmitParcel = () => {
  const dispatch = useDispatch();
  // no of submitted parcel details
  const [dashboardCustomerDetails, setDashboardCustomerDetails] = useState({});
  const [totalSubmitedParcel, setTotalSubmittedParcel] = useState(0);
  const [customername, setCustomerName] = useState([]);
  const [totalParcel, setTotalParcel] = useState([]);
  const [totalSuccessfullParcel, setSuccessfullParcel] = useState([]);
  const [totalDrivers, setTotalDriver] = useState([]);
  // No of submitted parcel
  useEffect(() => {
    dispatch(action.dashboard_driver_details())
      .then((data) => {
        let newList = [];
        let custmernames = [];
        let totalP = [];
        let totalS = [];
        let totalD = [];
        let total = [];
        let customerData = data?.data?.submittedParcel;

        customerData.map((item) => {
          total.push(item.successfulParcels);
          newList.push([
            item.customerBusinessName,
            item.totalDrivers,
            item.totalParcels,
            item.successfulParcels,
            item.percentage,
          ]);
          let customer_name = item.customerBusinessName.split(" ");

          custmernames.push(customer_name);
          totalP.push(parseInt(item.totalParcels));
          totalS.push(parseInt(item.successfulParcels));
          totalD.push(parseInt(item.totalDrivers));
        });

        total.pop();
        let newTotal = total.reduce((a, v) => (a = a + v), 0);
        setTotalSubmittedParcel(newTotal);
        custmernames.pop();
        setCustomerName(custmernames);
        totalP.pop();
        setTotalParcel(totalP);
        totalS.pop();
        setSuccessfullParcel(totalS);
        totalD.pop();
        setTotalDriver(totalD);
        setDashboardCustomerDetails(newList);
      })
      .catch({});
  }, []);

  // Parcel delivery stats - No of submitted parcel
  var SubmitParceloptions = {
    chart: {
      width: "100%",
      height: 385,
      type: "bar",
      fontFamily: "Apple-System, sans-serif",
    },
    colors: ["#FFB84C", "#57C5B6", "#1363DF"],
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    subtitle: {
      text: "Total Delivered Parcel",
      align: "left",
      margin: 0,
      offsetX: -10,
      offsetY: 35,
      floating: false,
      style: {
        fontSize: "14px",
        color: "#888ea8",
      },
    },
    title: {
      text: totalSubmitedParcel,
      align: "left",
      margin: 0,
      offsetX: -10,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "25px",
        color: "#0e1726",
      },
    },
    series: [
      {
        name: "Total Parcels",
        data: totalParcel,
      },
      {
        name: "Successfull Parcel",
        data: totalSuccessfullParcel,
      },
      {
        name: "Total Drivers",
        data: totalDrivers,
      },
    ],
    xaxis: {
      categories: customername,
    },
    legend: {
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 1000,
        yaxis: {
          categories: customername,
        },
        options: {
          plotOptions: {
            bar: {
              horizontal: true,
              columnWidth: "80%",
            },
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div className="widget widget-chart-one">
      <div className="widget-heading">
        <h5 className="">No. Of Submit Parcel</h5>
        <ul className="tabs tab-pills">
          <li>
            <a id="tb_1" className="tabmenu">
              Daily
            </a>
          </li>
        </ul>
      </div>
      <div id="chart">
        <Chart
          options={SubmitParceloptions}
          type="bar"
          series={SubmitParceloptions.series}
          height={385}
        />
      </div>
    </div>
  );
};

export default NoOfSubmitParcel;
