import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import Chart from "react-apexcharts";
import * as action from "../../../../action/Dashboard/dashboard_action";
import * as Driveraction from "../../../../action/Driver/driver_action";

const DriverWiseSubmitParcel = () => {
  const dispatch = useDispatch();
  //monthly driver wise parcel
  const [driverParcel, setDriverParcel] = useState([]);
  const [parcelYear, setParcelYear] = useState({
    value: 2023,
    label: "2023",
  });
  const [monthsListForCustomer, setMonthsListForCustomer] = useState();
  const [customerListforDriverWiseParcel, setCustomerListforDriverWiseParcel] =
    useState([]);
  const [customerAddressList, setCustomerAddressList] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [selectMonths, setSelectMonths] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [selectedCustomerAddress, setSelectedCustomerAddress] = useState();
  const [selectedDrivers, setSelectedDrivers] = useState();
  const [applyFilterData, setApplyFilterData] = useState({
    drivers: JSON.stringify([]),
    customer: JSON.stringify([]),
    customerAddress: JSON.stringify([]),
    inputMonth: JSON.stringify([]),
  });

  // months List
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const months = [
    {
      value: 1,
      label: "January",
    },
    {
      value: 2,
      label: "February",
    },
    {
      value: 3,
      label: "March",
    },
    {
      value: 4,
      label: "April",
    },
    {
      value: 5,
      label: "May",
    },
    {
      value: 6,
      label: "June",
    },
    {
      value: 7,
      label: "July",
    },
    {
      value: 8,
      label: "August",
    },
    {
      value: 9,
      label: "September",
    },
    {
      value: 10,
      label: "October",
    },
    {
      value: 11,
      label: "November",
    },
    {
      value: 12,
      label: "December",
    },
  ];

  //current Year
  const currentYear = {
    value: new Date().getFullYear(),
    label: new Date().getFullYear(),
  };

  // set driver wise parcel filter data
  useEffect(() => {
    let months = [];
    let customers = [];
    let drivers = [];
    let address = [];
    if (selectMonths?.length > 0) {
      for (let i = 0; i < selectMonths?.length; i++) {
        months.push(selectMonths[i].value);
      }
    }

    if (selectedCustomer?.length > 0) {
      for (let i = 0; i < selectedCustomer?.length; i++) {
        customers.push(selectedCustomer[i].value);
      }
    }

    if (selectedDrivers?.length > 0) {
      for (let i = 0; i < selectedDrivers?.length; i++) {
        drivers.push(selectedDrivers[i].value);
      }
    }

    if (selectedCustomerAddress?.length > 0) {
      for (let i = 0; i < selectedCustomerAddress?.length; i++) {
        address.push(selectedCustomerAddress[i].value);
      }
    }

    setApplyFilterData({
      ...applyFilterData,
      drivers: JSON.stringify(drivers),
      customer: JSON.stringify(customers),
      customerAddress: JSON.stringify(address),
      inputMonth: JSON.stringify(months),
    });
  }, [
    selectMonths,
    selectedCustomer,
    selectedDrivers,
    selectedCustomerAddress,
  ]);

  // driver wise submitted parcel
  useEffect(() => {
    dispatch(
      action.driver_wise_submited_parcel(parcelYear.value, applyFilterData)
    )
      .then((data) => {
        let customerBusinessName = data?.data?.businessName;
        let addresList = data?.data?.addressList;

        let addressOptions = [];
        if (addresList?.length > 0) {
          addresList.map((item) => {
            addressOptions.push({
              value: item?._id,
              label: item?.dispatchAddress,
            });
          });
        }

        let custList = customerBusinessName?.map((customer) => ({
          value: customer._id,
          label: customer.customerName,
        }));

        setCustomerAddressList(addressOptions);
        setCustomerListforDriverWiseParcel(custList);

        let parcelData = data?.data?.data;
        let monthList = [];
        let driverParcelData = [];

        parcelData?.map((item) => {
          item.topDrivers.map((d) => {
            driverParcelData.push({
              name: d?.driver,
              data: [],
              month: item.month,
            });
          });
          monthList.push(monthNames[item.month - 1]);
        });

        for (const c of driverParcelData) {
          for (const i of parcelData) {
            if (i.topDrivers.length === 0) {
              c.data.push(0);
            } else {
              let condition = i.topDrivers.findIndex(
                (x) => x.driver === c.name && i.month === c.month
              );
              if (condition === -1) {
                c.data.push(0);
              } else {
                c.data.push(i.topDrivers[condition].parcelCount);
              }
            }
          }
        }

        let dataDArray = [];
        driverParcelData.map((item) => {
          if (item.month >= 10 && item.month <= 12) {
            dataDArray.push(item);
          }
        });

        setMonthsListForCustomer(monthList);
        setDriverParcel(driverParcelData);
      })
      .catch({});
  }, [parcelYear, applyFilterData]);

  // get all drivers list
  useEffect(() => {
    dispatch(Driveraction.Driver_list_select())
      .then((data) => {
        let driver_details = data.data;
        let newDriverList = [];
        driver_details.map((item) => {
          newDriverList.push({
            value: item._id,
            label: "EV" + item.Driver_Id + " - " + item.name,
          });
        });
        setDriverList(newDriverList);
      })
      .catch({});
  }, []);

  // Monthly Driver wise submitted parcel
  var driverParcelChartoptions = {
    chart: {
      fontFamily: "Apple-System, sans-serif",
      height: 400,
      type: "bar",
      toolbar: {
        show: true,
        tools: {
          download: true,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    series: driverParcel,
    xaxis: {
      categories: monthsListForCustomer,
    },
    legend: {
      show: false,
    },
  };

  return (
    <div className="widget widget-chart-one ">
      <div className="widget-heading">
        <h5>Monthly Driver Wise Submitted Parcel</h5>
        <ul className="tabs">
          <li>
            <a id="tb_1" className="tabmenu">
              Monthly
            </a>
          </li>
        </ul>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-8" id="chart">
            <Chart
              options={driverParcelChartoptions}
              series={driverParcelChartoptions.series}
              height={400}
              type="bar"
            />
          </div>
          <div className="col-4">
            <ul className="d-flex flex-column list-unstyled">
              <li className="my-2">
                <label>Drivers</label>
                <Select
                  isMulti
                  name="drivers"
                  value={selectedDrivers}
                  onChange={(value) => {
                    setSelectedDrivers(value);
                  }}
                  options={driverList}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  // styles={customDriversStyles}
                />
              </li>
              <li className="my-2">
                <label>Customers</label>
                <Select
                  isMulti
                  name="customers"
                  value={selectedCustomer}
                  onChange={(value) => {
                    setSelectedCustomer(value);
                  }}
                  options={customerListforDriverWiseParcel}
                  // styles={customDriversStyles}
                />
              </li>
              <li className="my-2">
                <label>Customers Address</label>
                <Select
                  isMulti
                  name="customersAddress"
                  value={selectedCustomerAddress}
                  onChange={(value) => {
                    setSelectedCustomerAddress(value);
                  }}
                  options={customerAddressList}
                  // styles={customDriversStyles}
                />
              </li>
              <li className="my-2">
                <label>Month</label>
                <Select
                  isMulti
                  name="month"
                  value={selectMonths}
                  onChange={(value) => {
                    setSelectMonths(value);
                  }}
                  options={months}
                  // styles={customDriversStyles}
                />
              </li>
              <li className="my-2">
                <label>Year</label>
                <Select
                  id="inputYear"
                  name="year"
                  defaultValue={currentYear}
                  value={parcelYear}
                  onChange={(value) => {
                    setParcelYear(value);
                  }}
                  options={[
                    {
                      value: 2023,
                      label: "2023",
                    },
                    {
                      value: 2022,
                      label: "2022",
                    },
                    {
                      value: 2021,
                      label: "2021",
                    },
                  ]}
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverWiseSubmitParcel;
