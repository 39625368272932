import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../DashBoard/Header";
import Sub_Header from "../../DashBoard/Sub_Header";
import SideBar from "../../DashBoard/SideBar";
import Footer from "../../DashBoard/Footer";
import Loader from "../../common/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import * as action from "../../../action/HubVehiclesAdmin/vehicle_action";
import _ from "lodash";
import "./DashBoard.css";
const AddAdmin = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dashboardDetails, setDashboardDetails] = useState({});
  useEffect(() => {
    dispatch(action.Vehicle_hub_dashboard_details())
      .then((data) => {
        setDashboardDetails(data.data);
      })
      .catch({});
  }, []);
  return (
    <>
      <Header />
      <Sub_Header />
      <div className="main-container" id="container">
        <div className="overlay"></div>
        <div className="search-overlay"></div>
        <SideBar />
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="row layout-top-spacing">
              <div className="col-xl-4 col-lg-12 col-md-6 col-sm-12 col-12 layout-spacing">
                <div className="widget-one" style={{ background: "#198754" }}>
                  <div className="widget-content">
                    <div className="w-numeric-value">
                      <div className="w-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-archive"
                        >
                          <polyline points="21 8 21 21 3 21 3 8"></polyline>
                          <rect x="1" y="3" width="22" height="5"></rect>
                          <line x1="10" y1="12" x2="14" y2="12"></line>
                        </svg>
                      </div>
                      <div className="w-content">
                        <span className="w-value" id="totalParcelsId">
                          {dashboardDetails.totalNoOfVehicles}
                        </span>
                        <span className="w-numeric-title">
                          Total No. Of Vehicles
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-12 col-md-6 col-sm-12 col-12 layout-spacing">
                <div className="widget-one">
                  <div className="widget-content">
                    <div className="w-numeric-value">
                      <div className="w-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-archive"
                        >
                          <polyline points="21 8 21 21 3 21 3 8"></polyline>
                          <rect x="1" y="3" width="22" height="5"></rect>
                          <line x1="10" y1="12" x2="14" y2="12"></line>
                        </svg>
                      </div>
                      <div className="w-content">
                        <span className="w-value" id="totalParcelsId">
                          {dashboardDetails.parkedVehicles}
                        </span>
                        <span className="w-numeric-title">
                          No. Of Parked Vehicles
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-12 col-md-6 col-sm-12 col-12 layout-spacing">
                <div className="widget-one">
                  <div className="w-numeric-value">
                    <div className="w-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-check"
                      >
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                    </div>
                    <div className="w-content">
                      <span className="w-value" id="successfulParcelsId">
                        {dashboardDetails.activeVehicles}
                      </span>
                      <span className="w-numeric-title">Active Vehicles</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-12 col-md-6 col-sm-12 col-12 layout-spacing">
                <div className="widget-one">
                  <div className="w-numeric-value">
                    <div className="w-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-x-square"
                      >
                        <rect
                          x="3"
                          y="3"
                          width="18"
                          height="18"
                          rx="2"
                          ry="2"
                        ></rect>
                        <line x1="9" y1="9" x2="15" y2="15"></line>
                        <line x1="15" y1="9" x2="9" y2="15"></line>
                      </svg>
                    </div>
                    <div className="w-content">
                      <span className="w-value" id="cancelOnDoorParcelsId">
                        {dashboardDetails.InActiveVehicles}
                      </span>
                      <span className="w-numeric-title">
                        In-Active Vehicles
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-12 col-md-6 col-sm-12 col-12 layout-spacing">
                <div className="widget-one">
                  <div className="w-numeric-value">
                    <div className="w-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        class="feather feather-hard-drive"
                      >
                        <line x1="22" y1="12" x2="2" y2="12"></line>
                        <path d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"></path>
                        <line x1="6" y1="16" x2="6.01" y2="16"></line>
                        <line x1="10" y1="16" x2="10.01" y2="16"></line>
                      </svg>
                    </div>
                    <div className="w-content">
                      <span className="w-value" id="cancelOnWayParcelsId">
                        {dashboardDetails.OnFieldVehicles}
                      </span>
                      <span className="w-numeric-title">On Field Vehicles</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-12 col-md-6 col-sm-12 col-12 layout-spacing">
                <div className="widget-one">
                  <div className="w-numeric-value">
                    <div className="w-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        class="feather feather-tool"
                      >
                        <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path>
                      </svg>
                    </div>
                    <div className="w-content">
                      <span className="w-value" id="returnParcelsId">
                        {dashboardDetails.underMaintenance}
                      </span>
                      <span className="w-numeric-title">
                        Under Maintenance Vehicles
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default AddAdmin;
