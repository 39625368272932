import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import Chart from "react-apexcharts";
import { Input } from "rsuite";
import * as action from "../../../../action/Dashboard/dashboard_action";

const UtilisedFleetAnalysis = ({ cityList }) => {
  const dispatch = useDispatch();
  // utilised fleet
  const [utilisedFleetDataFirst, setUtilisedFleetDataFirst] = useState({
    deployed: 0,
    repaired: 0,
    unutilized: 0,
    status: false,
  });
  const [selectedUtilisedFleetDataFirst, setSelectedUtilisedFleetDataFirst] =
    useState({
      yearMonth: 0,
      city: "",
    });

  const [utilisedFleetDataSecond, setUtilisedFleetDataSecond] = useState({
    deployed: 0,
    repaired: 0,
    unutilized: 0,
    status: false,
  });
  const [selectedUtilisedFleetDataSecond, setSelectedUtilisedFleetDataSecond] =
    useState({
      yearMonth: 0,
      city: "",
    });

  // utilisation of fleet
  const utilisationFleetOption = ["Deployed", "Repair", "Unutilised"];

  // utilised fleet chart1
  useEffect(() => {
    dispatch(action.dashboard_utilised_fleet(selectedUtilisedFleetDataFirst))
      .then((data) => {
        console.log("data: ", data);
        let fleetUtilisedData = data.data[0];
        let fleetData = {
          deployed: +fleetUtilisedData?.deployed.toFixed(2),
          repaired: +fleetUtilisedData?.repaired.toFixed(2),
          unutilized: +fleetUtilisedData?.unutilized.toFixed(2),
        };

        setUtilisedFleetDataFirst({
          deployed: fleetData?.deployed ? fleetData?.deployed : 0,
          repaired: fleetData?.repaired ? fleetData?.repaired : 0,
          unutilized: fleetData?.unutilized ? fleetData?.unutilized : 0,
          status: true,
        });
      })
      .catch((error) => {});
  }, [selectedUtilisedFleetDataFirst]);

  // utilised fleet chart2
  useEffect(() => {
    dispatch(action.dashboard_utilised_fleet(selectedUtilisedFleetDataSecond))
      .then((data) => {
        let fleetUtilisedData = data.data[0];

        let fleetData = {
          deployed: +fleetUtilisedData?.deployed.toFixed(2),
          repaired: +fleetUtilisedData?.repaired.toFixed(2),
          unutilized: +fleetUtilisedData?.unutilized.toFixed(2),
        };

        setUtilisedFleetDataSecond({
          deployed: fleetData?.deployed ? fleetData?.deployed : 0,
          repaired: fleetData?.repaired ? fleetData?.repaired : 0,
          unutilized: fleetData?.unutilized ? fleetData?.unutilized : 0,
          status: true,
        });
      })
      .catch((error) => {});
  }, [selectedUtilisedFleetDataSecond]);

  // utilisation of fleet 1
  var utilisationFleet1 = {
    chart: {
      fontFamily: "Apple-System, sans-serif",
      height: 500,
      type: "donut",
    },
    plotOptions: {
      pie: {
        donut: {
          size: "40%",
        },
      },
    },
    colors: ["#3C79F5", "#00B7C2", "#FDCB9E"],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      position: "bottom",
    },
    series: [
      utilisedFleetDataFirst.deployed ? utilisedFleetDataFirst.deployed : 0,
      utilisedFleetDataFirst.repaired ? utilisedFleetDataFirst.repaired : 0,
      utilisedFleetDataFirst.unutilized ? utilisedFleetDataFirst.unutilized : 0,
    ],
    labels: utilisationFleetOption,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  // utilisation of fleet 2
  var utilisationFleet2 = {
    chart: {
      fontFamily: "Apple-System, sans-serif",
      height: 500,
      type: "donut",
    },
    colors: ["#3C79F5", "#00B7C2", "#FDCB9E"],
    plotOptions: {
      pie: {
        donut: {
          size: "40%",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      position: "bottom",
    },
    series: [
      utilisedFleetDataSecond.deployed ? utilisedFleetDataSecond.deployed : 0,
      utilisedFleetDataSecond.repaired ? utilisedFleetDataSecond.repaired : 0,
      utilisedFleetDataSecond.unutilized
        ? utilisedFleetDataSecond.unutilized
        : 0,
    ],
    labels: utilisationFleetOption,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div className="widget widget-chart-one">
      <div className="widget-heading ">
        <h5 className="">Utilisation Efficiency Of Fleet</h5>
        <ul className="tabs tab-pills ">
          <li>
            <a id="tb_1" className="tabmenu" style={{ marginLeft: "15px" }}>
              Monthly
            </a>
          </li>
        </ul>
      </div>

      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
          <div className="mb-2 d-flex flex-column justify-content-center align-items-center">
            <div className="text-center my-4">
              <label>City</label>
              <Select
                name="city"
                value={selectedUtilisedFleetDataFirst.city}
                onChange={(value) => {
                  setSelectedUtilisedFleetDataFirst({
                    ...selectedUtilisedFleetDataFirst,
                    city: value,
                  });
                }}
                options={cityList}
              />
            </div>
            <div className="d-flex flex-column col-7 ">
              <label>Year and Month</label>
              <Input
                type="month"
                name="yearAndMonth"
                value={selectedUtilisedFleetDataFirst.yearMonth}
                onChange={(value) => {
                  setSelectedUtilisedFleetDataFirst({
                    ...selectedUtilisedFleetDataFirst,
                    yearMonth: value,
                  });
                }}
              />
            </div>
          </div>
          {utilisedFleetDataFirst.status ? (
            <Chart
              options={utilisationFleet1}
              type="donut"
              series={utilisationFleet1.series}
              height={500}
            />
          ) : (
            <p>Select City and Year Month</p>
          )}
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
          <div className="mb-2 d-flex flex-column justify-content-center align-items-center">
            <div className="text-center my-4">
              <label>City</label>
              <Select
                name="city"
                value={selectedUtilisedFleetDataSecond.city}
                onChange={(value) => {
                  setSelectedUtilisedFleetDataSecond({
                    ...selectedUtilisedFleetDataSecond,
                    city: value,
                  });
                }}
                options={cityList}
              />
            </div>
            <div className="d-flex flex-column col-7 ">
              <label>Year and Month</label>
              <Input
                type="month"
                name="yearAndMonth"
                value={selectedUtilisedFleetDataSecond.yearMonth}
                onChange={(value) => {
                  setSelectedUtilisedFleetDataSecond({
                    ...selectedUtilisedFleetDataSecond,
                    yearMonth: value,
                  });
                }}
              />
            </div>
          </div>
          {utilisedFleetDataSecond.status ? (
            <Chart
              options={utilisationFleet2}
              type="donut"
              series={utilisationFleet2.series}
              height={500}
            />
          ) : (
            <p>Select City and Year Month</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default UtilisedFleetAnalysis;
