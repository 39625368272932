import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../common/Loader/Loader";
import * as action from "../../../action/Customer/customer_action";
import { format } from "date-fns";
import "../../../assets/css/users/user-profile.css";
import "../../../assets/plugins/table/datatable/datatables.css";
import "../../../assets/plugins/table/datatable/dt-global_style.css";
import $ from "jquery";

const CustomerAddresses = (props) => {
  const { loading } = useSelector((state) => state.addressListReducer);
  $.DataTable = require("datatables.net");
  const dispatch = useDispatch();
  const [invoiceList, setInvoiceList] = useState([]);
  const tableName = "customer-invoice-list";
  const changeDateFormate = (date) => {
    if (date) {
      let new_date = String(date);
      let ndate = new_date.split("T");
      let format_date = format(new Date(ndate[0]), "dd MMM yyyy");
      return format_date;
    }
  };
  const deleteInvoice = (InvoiceId) => {
    dispatch(action.delete_customer_invoice(InvoiceId))
      .then((data) => {
        toast.success(data.msg);
        let newInvoiceList = [];
        invoiceList.map((item) => {
          if (item[2] !== InvoiceId) {
            newInvoiceList.push(item);
          }
        });
        setInvoiceList(newInvoiceList);
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  const ChangeCustomerInvoiceFilter = (e) => {
    const type = e.target.value;
    dispatch(action.customer_invoice_list(props.customer))
      .then((data) => {
        let newInvoiceList = [];
        data.customerInvoicesList.map((item) => {
          if (type === "1") {
            if (item.isPaid === true) {
              newInvoiceList.push([
                item.invoiceUrl,
                changeDateFormate(item.invoiceDate),
                item._id,
                item,
              ]);
            }
          } else if (type === "0") {
            if (item.isPaid === false) {
              newInvoiceList.push([
                item.invoiceUrl,
                changeDateFormate(item.invoiceDate),
                item._id,
                item,
              ]);
            }
          } else if (type === "2") {
            newInvoiceList.push([
              item.invoiceUrl,
              changeDateFormate(item.invoiceDate),
              item._id,
              item,
            ]);
          }
        });
        setInvoiceList(newInvoiceList);
      })
      .catch({});
  };
  useEffect(() => {
    dispatch(action.customer_invoice_list(props.customer))
      .then((data) => {
        let newInvoiceList = [];
        data.customerInvoicesList.map((item) => {
          newInvoiceList.push([
            item.invoiceUrl,
            changeDateFormate(item.invoiceDate),
            item._id,
            item,
          ]);
        });
        setInvoiceList(newInvoiceList);
      })
      .catch({});
  }, []);
  useEffect(() => {
    console.log("invoiceList", invoiceList);
    const table = $(`#${tableName}`).DataTable({
      data: invoiceList,
      columns: [{ title: "Invoice" }, { title: "Date" }, { title: "Action" }],
      columnDefs: [
        {
          targets: [0],
          className: "center",
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <>
                <a
                  style={{ cursor: "pointer" }}
                  href={`${rowData[0]}`}
                  className="badge badge-primary text-color-white"
                  target="_blank"
                >
                  {rowData[3].invoiceNumber}
                </a>{" "}
                &nbsp;
                {rowData[3].isPaid ? (
                  <a
                    style={{ cursor: "pointer" }}
                    className="badge badge-success text-color-white ml-1"
                  >
                    Paid
                  </a>
                ) : (
                  <a
                    style={{ cursor: "pointer" }}
                    className="badge badge-danger text-color-white ml-1"
                  >
                    Pending
                  </a>
                )}
              </>,
              td
            ),
        },
        {
          targets: [2],
          className: "center",
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <>
                {console.log("rowData", rowData[0])}
                <a
                  style={{ cursor: "pointer" }}
                  className="btn btn-success btn-sm view-btn view-act-btn"
                  href={`${rowData[0]}`}
                  target="_blank"
                >
                  Download
                </a>
                &nbsp;
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => deleteInvoice(rowData[2])}
                  className="btn btn-danger btn-sm delete-act-btn"
                >
                  Delete
                </a>
                &nbsp;
                {rowData[3].isPaid ? (
                  ""
                ) : (
                  <a
                    style={{ cursor: "pointer" }}
                    className="btn btn-warning btn-sm mb-1 view-act-btn"
                    data-toggle="modal"
                    data-target="#markAsPaidModalCenter"
                  >
                    Mark As Paid
                  </a>
                )}
              </>,
              td
            ),
        },
      ],
      destroy: true, // I think some clean up is happening here
      searching: true,
      buttons: [
        {
          extend: "excel",
          className:
            "excelButton view-btn btn text-white float-right mt-2 ml-2",
        },
      ],
      oLanguage: {
        oPaginate: {
          sPrevious:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>',
          sNext:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>',
        },
        //sInfo: "Showing page _PAGE_ of _PAGES_",
        sSearch:
          '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
        sSearchPlaceholder: "Search...",
        sLengthMenu: "Results :  _MENU_",
      },
      fnInitComplete: function (oSettings) {
        $("#customer-addresses-list_filter").prepend(
          '<button type="button" class="btn btn-warning btn-sm add-act-btn" data-toggle="modal" data-target="#addAddressModalCenter">Add Address</button>'
        );
      },
    });
    // Extra step to do extra clean-up.
    return function () {
      table.destroy();
    };
  }, [invoiceList]);
  return (
    <>
      {loading ? <Loader /> : null}
      {/* <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light"/> */}
      <div className="form-group p-2 list-filter" id="list-filter-id">
        <label for="inputInvoiceStatusSelect" className="ml-1">
          Status
        </label>
        <select
          name="inputInvoiceStatusSelect"
          id="inputInvoiceStatusSelect"
          className="form-control selectpicker ml-2"
          style={{ width: "130px" }}
          onChange={(e) => ChangeCustomerInvoiceFilter(e)}
        >
          <option value="2" selected>
            All
          </option>
          <option value="1">Paid</option>
          <option value="0">Pending</option>
        </select>
      </div>
      <div className="table-responsive mb-4 mt-4">
        <table
          id="customer-invoice-list"
          className="table table-hover"
          style={{ width: "100%" }}
        >
          <thead>
            <tr>
              <th>Invoice</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </>
  );
};
export default CustomerAddresses;
