import axios from "axios";
let baseurl = null;

if (window.location.origin === "http://localhost:3000") {
  baseurl = process.env.REACT_APP_BACKEND_URL;
} else {
  baseurl = window.location.origin;
}

export const baseURL = `${baseurl}/`;
const baseService = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
});
export default baseService;
