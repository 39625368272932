import {
  COMMON_CITY_LIST_LOADING,
  COMMON_CITY_LIST_SUCCESSFULL,
  COMMON_CITY_LIST_ERROR,
} from "../../action/type";

import * as AuthService from "../../service/services";

export const common_city_list = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: COMMON_CITY_LIST_LOADING,
        data: true,
      });
      AuthService.DashboardCityList()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: COMMON_CITY_LIST_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: COMMON_CITY_LIST_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
