import {
  ADD_EXPENSE_CATEGORY_LOADING,
  ADD_EXPENSE_CATEGORY_SUCCESSFULL,
  ADD_EXPENSE_CATEGORY_ERROR,
  GET_ALL_EXPENSE_CATEGORY_LOADING,
  GET_ALL_EXPENSE_CATEGORY_SUCCESSFULL,
  GET_ALL_EXPENSE_CATEGORY_ERROR,
  EDIT_EXPENSE_CATEGORY_LOADING,
  EDIT_EXPENSE_CATEGORY_SUCCESSFULL,
  EDIT_EXPENSE_CATEGORY_ERROR,
} from "../../action/type";

import * as AuthService from "../../service/services";

export const Add_Expense_category = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADD_EXPENSE_CATEGORY_LOADING,
        data: true,
      });
      AuthService.AddExpenseCategory(data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ADD_EXPENSE_CATEGORY_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: ADD_EXPENSE_CATEGORY_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const Get_All_Expense_category = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: GET_ALL_EXPENSE_CATEGORY_LOADING,
        data: true,
      });
      AuthService.GetAllExpenseCategory()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_ALL_EXPENSE_CATEGORY_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: GET_ALL_EXPENSE_CATEGORY_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const Edit_Expense_category = (id, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: EDIT_EXPENSE_CATEGORY_LOADING,
        data: true,
      });
      AuthService.EditExpenseCategory(id, data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: EDIT_EXPENSE_CATEGORY_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: EDIT_EXPENSE_CATEGORY_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const Get_Single_Expense_category = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: GET_ALL_EXPENSE_CATEGORY_LOADING,
        data: true,
      });
      AuthService.GetSingleExpenseCategory(id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_ALL_EXPENSE_CATEGORY_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: GET_ALL_EXPENSE_CATEGORY_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
