import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Input } from "rsuite";
import { RxCross2 } from "react-icons/rx";
import Pagination from "../../../../common/Pagination/Pagination";
import $ from "jquery";
import * as billingaction from "../../../../../action/BillingAnalysis/billing_analysis";

const SwiggyTab = ({
  cityList,
  errors,
  setErrors,
  disabled,
  setEditFormEditable,
  editButtonHide,
  submitButtonHide,
}) => {
  const dispatch = useDispatch();

  // swiggy add
  const yearMonthSwiggyFocus = useRef();
  const citySwiggyFocus = useRef();
  const businessSupportFeesFocus = useRef();
  const delFeesFocus = useRef();
  const tipsFocus = useRef();
  const recoveryFocus = useRef();
  const redFlagsFocus = useRef();

  // swiggy edit
  const edityearMonthSwiggyFocus = useRef();
  const editcitySwiggyFocus = useRef();
  const editbusinessSupportFeesFocus = useRef();
  const editdelFeesFocus = useRef();
  const edittipsFocus = useRef();
  const editrecoveryFocus = useRef();
  const editredFlagsFocus = useRef();

  const [swiggyData, setSwiggyData] = useState({
    currentPage: 1,
    billingData: [],
    formId: "",
  });

  const [addSwiggyBillingData, setAddSwiggyBillingData] = useState({
    yearMonth: 0,
    city: "",
    businessSupportFees: 0,
    delFees: 0,
    tips: 0,
    recovery: 0,
    redFlags: 0,
  });

  const [editSwiggyBillingData, setEditSwiggyBillingData] = useState({
    yearMonth: 0,
    city: "",
    businessSupportFees: 0,
    delFees: 0,
    tips: 0,
    recovery: 0,
    redFlags: 0,
  });

  // swiggy billing data
  useEffect(() => {
    dispatch(billingaction.get_swiggy_billing())
      .then((data) => {
        let billingData = data.data;
        setSwiggyData({ ...swiggyData, billingData: billingData });
      })
      .catch((err) => {
        toast.error(err);
      });
  }, []);

  // swiggy add and update
  const onSubmitHandlerSwiggy = (e) => {
    e.preventDefault();
    setErrors({});
    let customErrors = {};

    if (!addSwiggyBillingData.yearMonth) {
      customErrors = { ...customErrors, yearMonth: "Please Select yearMonth" };
      yearMonthSwiggyFocus.current.focus();
    } else if (!addSwiggyBillingData.city) {
      customErrors = { ...customErrors, city: "Please Select city" };
      citySwiggyFocus.current.focus();
    } else if (!addSwiggyBillingData.businessSupportFees) {
      customErrors = {
        ...customErrors,
        businessSupportFees: "Please Enter Business Support Fees",
      };
      businessSupportFeesFocus.current.focus();
    } else if (!addSwiggyBillingData.tips) {
      customErrors = { ...customErrors, tips: "Please Enter Tips" };
      tipsFocus.current.focus();
    } else if (!addSwiggyBillingData.recovery) {
      customErrors = { ...customErrors, recovery: "Please Enter Recovery" };
      recoveryFocus.current.focus();
    } else if (!addSwiggyBillingData.redFlags) {
      customErrors = { ...customErrors, redFlags: "Please Enter RedFlags" };
      redFlagsFocus.current.focus();
    }

    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }

    dispatch(billingaction.add_swiggy_billing(addSwiggyBillingData))
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          $("#addExpenseFormSwiggy .close").click();
          let billingData = data.data;
          setSwiggyData({ ...swiggyData, billingData: billingData });
          setEditFormEditable(false);
        } else if (!data.success) {
          toast.error(data.message);
        }
        setErrors({});
      })
      .catch((err) => {});

    setErrors({});
    setAddSwiggyBillingData({
      yearMonth: 0,
      city: "",
      businessSupportFees: 0,
      delFees: 0,
      tips: 0,
      recovery: 0,
      redFlags: 0,
    });
  };

  const onUpdateHandlerSwiggy = (e) => {
    e.preventDefault();
    setErrors({});
    let customErrors = {};

    if (!editSwiggyBillingData.yearMonth) {
      customErrors = { ...customErrors, yearMonth: "Please Select yearMonth" };
      edityearMonthSwiggyFocus.current.focus();
    } else if (!editSwiggyBillingData.city) {
      customErrors = { ...customErrors, city: "Please Select city" };
      editcitySwiggyFocus.current.focus();
    } else if (!editSwiggyBillingData.businessSupportFees) {
      customErrors = {
        ...customErrors,
        businessSupportFees: "Please Enter Business Support Fees",
      };
      editbusinessSupportFeesFocus.current.focus();
    } else if (!editSwiggyBillingData.tips) {
      customErrors = { ...customErrors, tips: "Please Enter Tips" };
      edittipsFocus.current.focus();
    } else if (!editSwiggyBillingData.recovery) {
      customErrors = { ...customErrors, recovery: "Please Enter Recovery" };
      editrecoveryFocus.current.focus();
    } else if (!editSwiggyBillingData.redFlags) {
      customErrors = { ...customErrors, redFlags: "Please Enter RedFlags" };
      editredFlagsFocus.current.focus();
    }

    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }

    dispatch(
      billingaction.edit_swiggy_billing(
        swiggyData.formId ? swiggyData.formId : null,
        editSwiggyBillingData
      )
    )
      .then((data) => {
        if (data.success) {
          toast.success(data.message);

          $("#editExpenseFormSwiggy .close").click();
          let billingData = data.data;
          setSwiggyData({ ...swiggyData, billingData: billingData });
          setEditFormEditable(false);
        } else if (!data.success) {
          toast.error(data.message);
        }
        setErrors({});
      })
      .catch((err) => {});

    setErrors({});
  };

  // swiggy edit item
  const handleSwiggyEditClick = (item) => {
    setSwiggyData({ ...swiggyData, formId: item._id });

    const formattedMonth =
      item.month < 10 && item.month > 0 ? `0${item.month}` : item.month;

    let yearMonth = `${item.year}-${formattedMonth}`;

    setEditSwiggyBillingData({
      yearMonth: yearMonth,
      city: item.city,
      businessSupportFees: item.businessSupportFees,
      delFees: item.delFees,
      tips: item.tips,
      recovery: item.recovery,
      redFlags: item.redFlags,
    });
  };

  const currentSwiggyTableData = useMemo(() => {
    const firstPageIndexSwiggy = (swiggyData.currentPage - 1) * 10;
    const lastPageIndexSwiggy = firstPageIndexSwiggy + 10;
    return swiggyData.billingData?.slice(
      firstPageIndexSwiggy,
      lastPageIndexSwiggy
    );
  }, [swiggyData.currentPage, swiggyData.billingData]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="" style={{ margin: "10px" }}>
          Swiggy Billing Analysis
        </h5>
        <a
          href="/"
          className="btn btn-warning btn-sm add-act-btn"
          data-toggle="modal"
          data-target="#addExpenseFormSwiggy"
        >
          Add
        </a>
      </div>
      <br />
      <div className="table-responsive mb-4 mt-4">
        {currentSwiggyTableData.length > 0 ? (
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Month</th>
                <th>Year</th>
                <th>City</th>
                <th>Business Support Fees</th>
                <th>DelFees</th>
                <th>Tips</th>
                <th>Recovery</th>
                <th>Red Flag</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentSwiggyTableData.map((item) => {
                return (
                  <tr key={item._id}>
                    <td>{item.month}</td>
                    <td>{item.year}</td>
                    <td>{item.cityName}</td>
                    <td>{item.businessSupportFees}</td>
                    <td>{item.delFees}</td>
                    <td>{item.tips}</td>
                    <td>{item.recovery}</td>
                    <td>{item.redFlags}</td>

                    <td>
                      <a
                        href="/"
                        onClick={() => handleSwiggyEditClick(item)}
                        className="btn btn-info btn-sm edit-btn edit-act-btn"
                        data-toggle="modal"
                        data-target="#editExpenseFormSwiggy"
                      >
                        Edit
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className="text-center">No data Available</p>
        )}
        {/* pagination */}
        <Pagination
          className="pagination-bar"
          currentPage={swiggyData.currentPage}
          totalCount={swiggyData.billingData?.length}
          pageSize={10}
          onPageChange={(page) =>
            setSwiggyData({ ...swiggyData, currentPage: page })
          }
        />
      </div>

      {/* swiggy - add model */}
      <div
        className="modal fade"
        id="addExpenseFormSwiggy"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addExpenseFormTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <form onSubmit={onSubmitHandlerSwiggy}>
              <div className="modal-header">
                <h5 className="modal-title">Add Swiggy Billing</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <RxCross2 />
                </button>
              </div>
              <div className="modal-body">
                <div
                  className="customer-address-class"
                  id="customer-address-div-id"
                >
                  <div className="row" id="defaultaddressdiv0">
                    <div className="col-12">
                      <div id="append-div-id">
                        <div class="form-group row">
                          <div class="form-group col-4 required">
                            <label>Select Year and Month *</label>
                            <Input
                              type="month"
                              ref={yearMonthSwiggyFocus}
                              name="yearMonth"
                              value={addSwiggyBillingData?.yearMonth}
                              onChange={(value) =>
                                setAddSwiggyBillingData({
                                  ...addSwiggyBillingData,
                                  yearMonth: value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.yearMonth}
                            </span>
                          </div>
                          <div class="form-group col-md-4 required">
                            <label>Select City *</label>
                            <select
                              id="city"
                              ref={citySwiggyFocus}
                              className="form-control selectpicker"
                              value={addSwiggyBillingData?.city}
                              onChange={(e) => {
                                setAddSwiggyBillingData({
                                  ...addSwiggyBillingData,
                                  city: e.target.value,
                                });
                              }}
                            >
                              <option value="">Select City</option>
                              {cityList.map((item) => {
                                return (
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            <span style={{ color: "red" }}>{errors?.city}</span>
                          </div>
                        </div>

                        <div class="form-group row">
                          <div class="form-group col-md-4 required">
                            <label>Businnes Supoort Fees</label>
                            <input
                              type="number"
                              min="0"
                              name="businessSupportFees"
                              className="form-control"
                              ref={businessSupportFeesFocus}
                              placeholder="Enter Business Support Fees"
                              value={addSwiggyBillingData?.businessSupportFees}
                              onChange={(e) =>
                                setAddSwiggyBillingData({
                                  ...addSwiggyBillingData,
                                  businessSupportFees: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.businessSupportFees}
                            </span>
                          </div>

                          <div class="form-group col-md-4 required">
                            <label>DelFees</label>
                            <input
                              type="number"
                              min="0"
                              name="delFees"
                              className="form-control"
                              ref={delFeesFocus}
                              placeholder="Enter Del Fees"
                              value={addSwiggyBillingData?.delFees}
                              onChange={(e) =>
                                setAddSwiggyBillingData({
                                  ...addSwiggyBillingData,
                                  delFees: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.delFees}
                            </span>
                          </div>
                          <div class="form-group col-md-4 required">
                            <label>Tips</label>
                            <input
                              type="number"
                              min="0"
                              name="tips"
                              className="form-control"
                              ref={tipsFocus}
                              placeholder="Enter Tips"
                              value={addSwiggyBillingData?.tips}
                              onChange={(e) =>
                                setAddSwiggyBillingData({
                                  ...addSwiggyBillingData,
                                  tips: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>{errors?.tips}</span>
                          </div>
                          <div class="form-group col-md-4 required">
                            <label>Recovery</label>
                            <input
                              type="number"
                              min="0"
                              name="recovery"
                              className="form-control"
                              ref={recoveryFocus}
                              placeholder="No of Orders"
                              value={addSwiggyBillingData?.recovery}
                              onChange={(e) =>
                                setAddSwiggyBillingData({
                                  ...addSwiggyBillingData,
                                  recovery: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.recovery}
                            </span>
                          </div>
                          <div class="form-group col-md-4 required">
                            <label>Red Flags</label>
                            <input
                              type="number"
                              min="0"
                              name="redFlags"
                              className="form-control"
                              ref={redFlagsFocus}
                              placeholder="No of Orders"
                              value={addSwiggyBillingData?.redFlags}
                              onChange={(e) =>
                                setAddSwiggyBillingData({
                                  ...addSwiggyBillingData,
                                  redFlags: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.redFlags}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="reset"
                  data-dismiss="modal"
                  className="btn btn-danger "
                  onClick={() => setEditFormEditable(false)}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success save-btn">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* swiggy - update model */}
      <div
        className="modal fade"
        id="editExpenseFormSwiggy"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editExpenseFormTitle"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <form onSubmit={onUpdateHandlerSwiggy}>
              <div className="modal-header">
                <h5 className="modal-title" id="editExpenseFormTitle">
                  Swiggy Billing
                </h5>
                <button
                  type="button"
                  className="btn btn-primary view-btn"
                  hidden={editButtonHide}
                  onClick={() => setEditFormEditable(true)}
                >
                  Edit
                </button>
              </div>

              <div className="modal-body">
                <div
                  className="customer-address-class"
                  id="customer-address-div-id"
                >
                  <div className="row" id="defaultaddressdiv0">
                    <div className="col-12">
                      <div id="append-div-id">
                        <div class="form-group row">
                          <div class="form-group col-4 required">
                            <label>Select Year and Month *</label>
                            <Input
                              disabled={disabled}
                              type="month"
                              ref={edityearMonthSwiggyFocus}
                              name="yearMonth"
                              value={editSwiggyBillingData?.yearMonth}
                              onChange={(value) =>
                                setEditSwiggyBillingData({
                                  ...editSwiggyBillingData,
                                  yearMonth: value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.yearMonth}
                            </span>
                          </div>
                          <div class="form-group col-md-4 required">
                            <label>Select City *</label>
                            <select
                              disabled={disabled}
                              id="city"
                              ref={editcitySwiggyFocus}
                              className="form-control selectpicker"
                              value={editSwiggyBillingData?.city}
                              onChange={(e) => {
                                setEditSwiggyBillingData({
                                  ...editSwiggyBillingData,
                                  city: e.target.value,
                                });
                              }}
                            >
                              <option value="">Select City</option>
                              {cityList.map((item) => {
                                return (
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            <span style={{ color: "red" }}>{errors?.city}</span>
                          </div>
                        </div>

                        <div class="form-group row">
                          <div class="form-group col-md-4 required">
                            <label>Businnes Supoort Fees</label>
                            <input
                              disabled={disabled}
                              type="number"
                              min="0"
                              name="businessSupportFees"
                              className="form-control"
                              ref={editbusinessSupportFeesFocus}
                              placeholder="Enter Business Support Fees"
                              value={editSwiggyBillingData?.businessSupportFees}
                              onChange={(e) =>
                                setEditSwiggyBillingData({
                                  ...editSwiggyBillingData,
                                  businessSupportFees: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.businessSupportFees}
                            </span>
                          </div>

                          <div class="form-group col-md-4 required">
                            <label>DelFees</label>
                            <input
                              disabled={disabled}
                              type="number"
                              min="0"
                              name="delFees"
                              className="form-control"
                              ref={editdelFeesFocus}
                              placeholder="Enter Del Fees"
                              value={editSwiggyBillingData?.delFees}
                              onChange={(e) =>
                                setEditSwiggyBillingData({
                                  ...editSwiggyBillingData,
                                  delFees: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.delFees}
                            </span>
                          </div>
                          <div class="form-group col-md-4 required">
                            <label>Tips</label>
                            <input
                              disabled={disabled}
                              type="number"
                              min="0"
                              name="tips"
                              className="form-control"
                              ref={edittipsFocus}
                              placeholder="Enter Tips"
                              value={editSwiggyBillingData?.tips}
                              onChange={(e) =>
                                setEditSwiggyBillingData({
                                  ...editSwiggyBillingData,
                                  tips: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>{errors?.tips}</span>
                          </div>
                          <div class="form-group col-md-4 required">
                            <label>Recovery</label>
                            <input
                              disabled={disabled}
                              type="number"
                              min="0"
                              name="recovery"
                              className="form-control"
                              ref={editrecoveryFocus}
                              placeholder="No of Orders"
                              value={editSwiggyBillingData?.recovery}
                              onChange={(e) =>
                                setEditSwiggyBillingData({
                                  ...editSwiggyBillingData,
                                  recovery: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.recovery}
                            </span>
                          </div>
                          <div class="form-group col-md-4 required">
                            <label>Red Flags</label>
                            <input
                              disabled={disabled}
                              type="number"
                              min="0"
                              name="redFlags"
                              className="form-control"
                              ref={editredFlagsFocus}
                              placeholder="No of Orders"
                              value={editSwiggyBillingData?.redFlags}
                              onChange={(e) =>
                                setEditSwiggyBillingData({
                                  ...editSwiggyBillingData,
                                  redFlags: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.redFlags}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary view-btn"
                  hidden={submitButtonHide}
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SwiggyTab;
