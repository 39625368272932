import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../DashBoard/Header";
import Sub_Header from "../../DashBoard/Sub_Header";
import SideBar from "../../DashBoard/SideBar";
import Footer from "../../DashBoard/Footer";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import Loader from "../../common/Loader/Loader";
import { ExportToExcel } from "./ExportToExcel";
import "../../../assets/css/users/user-profile.css";
import "../../../assets/plugins/table/datatable/datatables.css";
import "../../../assets/plugins/table/datatable/dt-global_style.css";
import * as action from "../../../action/Driver/driver_action";
import $ from "jquery";

const All_Driver_Work_history = () => {
  $.DataTable = require("datatables.net");
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.AllDriverWorkListReduces);
  const start = new Date();
  start.setHours(0, 0, 0, 0);
  let end = new Date();
  end.setHours(23, 59, 59, 999);
  const [driverList, setDriverList] = useState([]);
  const [driverId, setDriverId] = useState("");
  const [values, setValues] = useState({
    driverValues: "",
  });

  const changeDriverDropDown = (data) => {
    setValues({ ...values, driverValues: data });
    setDriverId(data.value);
  };
  const setInputData = (e) => {
    setInput([new Date(e.target.value), new Date(e.target.value)]);
  };
  const [input, setInput] = useState([new Date(start), new Date(end)]);
  const today = new Date();
  const defaultValue = new Date(today).toISOString().split("T")[0];

  const [totalDeliveredCount, setTotalDeliveredCount] = useState(0);

  useEffect(() => {
    dispatch(action.Driver_list_select())
      .then((data) => {
        let driver_details = data.data;
        let newDriverList = [];
        driver_details.map((item) => {
          newDriverList.push({
            value: item._id,
            label: "EV" + item.Driver_Id + " - " + item.name,
          });
        });
        setDriverList(newDriverList);
      })
      .catch({});
  }, []);

  const [driverWorkList, setDriverWorkList] = useState([]);
  const [driverWorkExcelList, setDriverWorkExcelList] = useState([]);

  // Function to pad single digits with leading zeros
  function padZero(value) {
    return value.toString().padStart(2, "0");
  }

  useEffect(() => {
    dispatch(action.all_driver_work_list(input[0], input[1], driverId))
      .then((data) => {
        let newDriverWorkList = [];
        setTotalDeliveredCount(data.totalDeliveredCount);
        data?.data?.map((item) => {
          const startTime = new Date(item.startTime);
          const endTime = new Date(item.endTime);

          let timeDifference = Math.abs(endTime - startTime);
          // Convert the time difference from milliseconds to hours, minutes, and seconds
          const hours = Math.floor(timeDifference / (1000 * 60 * 60));
          const minutes = Math.floor(
            (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

          const formattedTimeDifference = `${padZero(hours)}:${padZero(
            minutes
          )}:${padZero(seconds)}`;

          newDriverWorkList.push([
            item?.driverData?.name ? item?.driverData?.name : "",
            item?.customerData?.businessName ||
            item?.customerData?.name ||
            item?.customerData?.mobile
              ? `${item?.customerData?.businessName} <br> ${item?.customerData?.name} <br> ${item?.customerData?.mobile}`
              : "",
            item.pickupParcelCount ? item.pickupParcelCount : 0,
            item.deliveredParcelCount ? item.deliveredParcelCount : 0,
            item.undeliveredOnDoorParcelCount
              ? item.undeliveredOnDoorParcelCount
              : 0,
            item.undeliveredOnWayParcelCount
              ? item.undeliveredOnWayParcelCount
              : 0,
            item.undeliveredParcelCount ? item.undeliveredParcelCount : 0,
            item.startTime
              ? new Date(item.startTime).toTimeString().split(" ")[0]
              : 0,
            item.endTime
              ? new Date(item.endTime).toTimeString().split(" ")[0]
              : 0,
            formattedTimeDifference,
            `<a style="cursor: pointer;" href="/adminside/driver/View_Driver_Work_Images/${item._id}" class="btn btn-info btn-sm view-btn view-act-btn">View</a>`,
          ]);
        });
        setDriverWorkList(newDriverWorkList);
      })
      .catch({});
  }, [input, driverId]);
  useEffect(() => {
    dispatch(action.all_driver_work_list(input[0], input[1], driverId))
      .then((data) => {
        setDriverWorkExcelList(data.data);
      })
      .catch({});
  }, [input, driverId]);
  useEffect(() => {
    const tableName = "driver-works-list";
    const table = $(`#${tableName}`).DataTable({
      dom: "Bfrtip",
      buttons: ["copyHtml5", "excelHtml5", "pdfHtml5", "csvHtml5"],
      data: driverWorkList,
      columns: [
        { title: "Driver" },
        // {title: "Vehicle"},
        { title: "Customer" },
        // {title: "Customer Address"},
        { title: "Pickup" },
        { title: "Done" },
        { title: "Door Cancel" },
        { title: "Route Cancel" },
        { title: "Total Cancel" },
        { title: "Start Time" },
        { title: "End Time" },
        { title: "Total Time" },
        { title: "Action" },
      ],
      destroy: true, // I think some clean up is happening here
      searching: true,
      oLanguage: {
        oPaginate: {
          sPrevious:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>',
          sNext:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>',
        },
        //sInfo: "Showing page _PAGE_ of _PAGES_",
        sSearch:
          '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
        sSearchPlaceholder: "Search...",
        sLengthMenu: "Results :  _MENU_",
      },
      fnInitComplete: function (oSettings) {
        $("#driver-works-list_filter").prepend(
          `No. Of Deliveries: ${totalDeliveredCount}`
        );
      },
    });
    // Extra step to do extra clean-up.
    return function () {
      table.destroy();
    };
  }, [driverWorkList]);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Sub_Header />
      <div className="main-container" id="container">
        {loading ? <Loader /> : null}
        <div className="overlay"></div>
        <div className="search-overlay"></div>
        <SideBar />
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                <div className="widget-content widget-content-area br-6">
                  <h5 className="" style={{ margin: "10px" }}>
                    Driver Daily Works
                  </h5>
                  <div className="table-responsive mb-4 mt-4">
                    <div className="form-group row col-12 ml-1">
                      <input
                        type="date"
                        className="form-control"
                        defaultValue={defaultValue}
                        onChange={(e) => setInputData(e)}
                        style={{ width: 300 }}
                        placeholder="Select Date Range"
                      />
                      &nbsp;
                      <div style={{ width: "300px" }}>
                        <Select
                          id="inputDriverSelect"
                          value={values.driverValues || "Select"}
                          onChange={(value) => changeDriverDropDown(value)}
                          options={driverList}
                        />
                      </div>
                      &nbsp;
                      {driverWorkExcelList && driverWorkExcelList.length ? (
                        <ExportToExcel
                          apiData={driverWorkExcelList}
                          startDate={input[0]}
                        />
                      ) : (
                        <div className="btn btn-success disabled">
                          Export to XLS
                        </div>
                      )}
                    </div>
                    <table
                      id="driver-works-list"
                      className="table table-hover"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>Driver</th>
                          <th>Customer</th>
                          <th>Pickup</th>
                          <th>Done</th>
                          <th>Door Cancel</th>
                          <th>Route Cancel</th>
                          <th>Total Cancel</th>
                          <th>Start Time</th>
                          <th>End Time</th>
                          <th>Total Time</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </>
  );
};
export default All_Driver_Work_history;
