import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Owners_DashBoard/Header";
import Sub_Header from "../../Owners_DashBoard/Sub_Header";
import SideBar from "../../Owners_DashBoard/SideBar";
import Select from "react-select";
import Footer from "../../Owners_DashBoard/Footer";
import Loader from "../../common/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
// import './DashBoard.css';
import "../../../assets/css/users/user-profile.css";
import "../../../assets/plugins/table/datatable/datatables.css";
import "../../../assets/plugins/table/datatable/dt-global_style.css";
import * as action from "../../../action/Admin/admin_action";
import $ from "jquery";

function Admin_Setting() {
  const dispatch = useDispatch();
  const repairFocus = useRef();
  const repairchargesEditFocus = useRef();
  const [chargesList, setChargesList] = useState([]);
  const [errors, setErrors] = useState({});
  const { AddLoading } = useSelector((state) => state.AdminAddChargesReducer);
  const { ListLoading } = useSelector((state) => state.AdminChargesListReducer);
  const { EditLoading } = useSelector((state) => state.AdminEditChargesReducer);
  const [input, setInput] = useState({
    id: "",
    repairCharges: "",
  });
  const onsubmitHandler = (e) => {
    e.preventDefault();
    setErrors({});
    let customErrors = {};
    if (input.repair === "") {
      customErrors = { ...customErrors, repair: "Please enter repair charges" };
      repairFocus.current.focus();
    }
    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }
    let parameter = {
      repairCharges: input.repairCharges,
    };
    dispatch(action.admin_add_charges(parameter))
      .then((data) => {
        toast.success(data.msg);
        let response = data.data;
        setChargesList(data.data);
        setInput({
          id: response._id,
          repairCharges: response.repairCharges,
        });
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  $.DataTable = require("datatables.net");
  useEffect(() => {
    dispatch(action.admin_chargesList())
      .then((data) => {
        setInput({
          id: data.data[0]._id,
          repairCharges: data.data[0].repairCharges,
        });
        setChargesList(data.data);
      })
      .catch({});
  }, []);
  const onUpdateHandler = (e) => {
    e.preventDefault();
    setErrors({});
    let customErrors = {};
    if (input.repairCharges === "") {
      customErrors = {
        ...customErrors,
        repairCharges: "Please enter Repair Charges",
      };
      repairchargesEditFocus.current.focus();
    }
    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }
    dispatch(action.admin_edit_charges(input))
      .then((data) => {
        toast.success(data.msg);
        let response = data.data;
        setInput({
          id: response._id,
          repairCharges: response.repairCharges,
        });
        $("#editchargesmodelCenter .close").click();
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  return (
    <>
      {AddLoading ? <Loader /> : null}
      {ListLoading ? <Loader /> : null}
      {EditLoading ? <Loader /> : null}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Sub_Header />
      <div className="main-container" id="container">
        <div className="overlay"></div>
        <div className="search-overlay"></div>
        <SideBar />
        <div id="content" className="main-content">
          <div
            className="row layout-top-spacing"
            style={{ padding: "0 20px 0 18px" }}
          >
            <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
              <div className="widget-content widget-content-area br-6">
                <h5 className="">Admin Setting</h5>
                <br />

                {chargesList.length === 0 ? (
                  <form onSubmit={onsubmitHandler}>
                    <div class="form-group row">
                      <div class="form-group col-md-12 required">
                        <label for="address">Repair Charges *</label>
                        <input
                          type="text"
                          name="repairCharges"
                          ref={repairFocus}
                          class="form-control"
                          id="inputrepairCharges"
                          placeholder="Enter Repair Charges"
                          onChange={(e) =>
                            setInput({
                              ...input,
                              repairCharges: e.target.value,
                            })
                          }
                          value={input.repairCharges}
                        />
                        <span style={{ color: "red" }}>{errors?.repair}</span>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-success mt-3 view-btn"
                    >
                      Add
                    </button>
                  </form>
                ) : (
                  <form onSubmit={onUpdateHandler}>
                    <div class="form-group row">
                      <div class="form-group col-md-12 required">
                        <label for="inputName-edit">Repair Charges *</label>
                        <input
                          type="text"
                          ref={repairchargesEditFocus}
                          className="form-control"
                          name="repairCharges"
                          id="inputrepairCharges-edit"
                          placeholder="Enter Repair Charges"
                          onChange={(e) =>
                            setInput({
                              ...input,
                              repairCharges: e.target.value,
                            })
                          }
                          value={input.repairCharges}
                        />
                        <span style={{ color: "red" }}>
                          {errors?.repairCharges}
                        </span>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-success mt-3 view-btn"
                    >
                      Update
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Admin_Setting;
