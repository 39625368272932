import React, { useEffect, useMemo, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Footer from "../../DashBoard/Footer";
import Header from "../../DashBoard/Header";
import SideBar from "../../DashBoard/SideBar";
import SubHeader from "../../DashBoard/Sub_Header";
import * as action from "../../../action/Setting/setting_action";
import { useDispatch } from "react-redux";
import $ from "jquery";
import Pagination from "../../common/Pagination/Pagination";

let PageSize = 10;

const ExpenseTracker = () => {
  const dispatch = useDispatch();

  const categoryFocus = useRef();
  const chargeFocus = useRef();
  const categoryEditFocus = useRef();
  const chargeEditFocus = useRef();

  const [disabled, setDisabled] = useState(true);
  const [errors, setErrors] = useState({});
  const [submitButtonHide, setSubmitButtonHide] = useState(true);

  const [expenseCategories, setExpenseCategories] = useState([]);

  const [addExpenseCategory, setAddExpenseCategory] = useState({
    category: "",
    repairCharges: "",
  });
  const [editExpenseCategory, setEditExpenseCategory] = useState({
    id: "",
    category: "",
    repairCharges: "",
  });
  const [editButtonHide, setEditButtonHide] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const setEditFormEditable = (type) => {
    if (type) {
      setDisabled(false);
      //   setCancelButtonHide(false);
      setSubmitButtonHide(false);
      setEditButtonHide(true);
    } else {
      setDisabled(true);
      //   setCancelButtonHide(true);
      setSubmitButtonHide(true);
      setEditButtonHide(false);
    }
  };

  useEffect(() => {
    dispatch(action.Get_All_Expense_category())
      .then((data) => {
        let allExpense = data.data;
        setExpenseCategories(allExpense);
      })
      .catch((err) => {
        toast.error(err);
      });
  }, []);

  // called on add form
  const onsubmitHandler = (e) => {
    e.preventDefault();

    setErrors({});
    let customErrors = {};

    if (!/^[0-9a-zA-Z\-\s]+$/.test(addExpenseCategory.category)) {
      customErrors = { ...customErrors, category: "Please Enter Category" };
      categoryFocus.current.focus();
    } else if (!/^[0-9a-zA-Z\-\s]+$/.test(addExpenseCategory.repairCharges)) {
      customErrors = {
        ...customErrors,
        repairCharges: "Please Enter Repair Charges",
      };
      chargeFocus.current.focus();
    }

    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }

    dispatch(action.Add_Expense_category(addExpenseCategory))
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          $("#addHubModalCenter .close").click();
          setExpenseCategories(data.data);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });

    setAddExpenseCategory({
      ...addExpenseCategory,
      category: "",
      repairCharges: "",
    });
  };

  //called on update form
  const onUpdateHandler = (e) => {
    e.preventDefault();

    setErrors({});
    let customErrors = {};

    if (!/^[0-9a-zA-Z\-\s]+$/.test(editExpenseCategory.category)) {
      customErrors = { ...customErrors, editCategory: "Please enter category" };
      categoryEditFocus.current.focus();
    } else if (!/^[0-9a-zA-Z\-\s]+$/.test(editExpenseCategory.repairCharges)) {
      customErrors = {
        ...customErrors,
        repairCharges: "Please enter repair charges",
      };
      chargeEditFocus.current.focus();
    }

    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }

    dispatch(
      action.Edit_Expense_category(
        editExpenseCategory?.id ? editExpenseCategory.id : null,
        {
          category: editExpenseCategory.category,
          repairCharges: editExpenseCategory.repairCharges,
        }
      )
    )
      .then((data) => {
        toast.success(data.message);
        setExpenseCategories(data.data);
        setEditFormEditable(false);
        $("#editHubModalCenter .close").click();
      })
      .catch((error) => {
        toast.error(error);
      });
    setErrors({});
  };

  const handleEditClick = (item) => {
    setEditExpenseCategory({
      id: item._id,
      category: item.category,
      repairCharges: item.repairCharges,
    });
  };

  const handleDiscardClick = (e) => {
    e.preventDefault();
    setAddExpenseCategory({
      ...addExpenseCategory,
      category: "",
      repairCharges: "",
    });
    setErrors({});
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return expenseCategories?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, expenseCategories]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <SubHeader />
      <div className="main-container" id="container">
        <div className="overlay"></div>
        <div className="search-overlay"></div>
        <SideBar />
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                <div className="widget-content widget-content-area br-6">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="" style={{ margin: "10px" }}>
                      Expense Category List
                    </h5>
                    <a
                      href="/"
                      className="btn btn-warning btn-sm add-act-btn"
                      data-toggle="modal"
                      data-target="#addHubModalCenter"
                    >
                      Add
                    </a>
                  </div>
                  <div className="table-responsive mb-4 mt-4">
                    {currentTableData?.length > 0 ? (
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>Category</th>
                            <th>Charge</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentTableData?.map((item) => {
                            return (
                              <tr>
                                <td>{item.category}</td>
                                <td>{item.repairCharges}</td>
                                <td>
                                  <a
                                    href="/"
                                    onClick={() => handleEditClick(item)}
                                    className="btn btn-info btn-sm edit-btn edit-act-btn"
                                    data-toggle="modal"
                                    data-target="#editHubModalCenter"
                                  >
                                    Edit
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <p className="text-center">No data Available</p>
                    )}

                    {/* pagination */}
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={expenseCategories.length}
                      pageSize={PageSize}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                </div>

                {/* add model */}
                <div
                  className="modal fade"
                  id="addHubModalCenter"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="addHubModalCenterTitle"
                  aria-hidden="true"
                  onOpen={() =>
                    setAddExpenseCategory({ category: "", repairCharges: "" })
                  }
                >
                  <div
                    className="modal-dialog modal-dialog-centered modal-md"
                    role="document"
                  >
                    <div className="modal-content">
                      <form onSubmit={onsubmitHandler}>
                        <div className="modal-header">
                          <h5
                            className="modal-title"
                            id="addHubModalCenterTitle"
                          >
                            Add Category
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <svg
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-x"
                            >
                              <line x1="18" y1="6" x2="6" y2="18"></line>
                              <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                          </button>
                        </div>

                        <div className="modal-body">
                          <div
                            className="customer-address-class"
                            id="customer-address-div-id"
                          >
                            <div className="row" id="defaultaddressdiv0">
                              <div className="col-md-12">
                                <div className="form-group required">
                                  <label htmlFor="inputName-add">
                                    Category Name *
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    ref={categoryFocus}
                                    name="name"
                                    id="inputName-add"
                                    placeholder="Enter Category Name"
                                    onChange={(e) =>
                                      setAddExpenseCategory({
                                        ...addExpenseCategory,
                                        category: e.target.value,
                                      })
                                    }
                                    value={addExpenseCategory.category}
                                  />
                                  <span style={{ color: "red" }}>
                                    {errors?.category}
                                  </span>
                                  <br />
                                  <label
                                    className="mt-2"
                                    htmlFor="inputCharge-add"
                                  >
                                    Charge *
                                  </label>
                                  <input
                                    type="number"
                                    min="0"
                                    className="form-control"
                                    ref={chargeFocus}
                                    name="repairCharges"
                                    id="inputCharge-add"
                                    placeholder="Enter Charge Amount"
                                    onChange={(e) =>
                                      setAddExpenseCategory({
                                        ...addExpenseCategory,
                                        repairCharges: e.target.value,
                                      })
                                    }
                                    pattern="[0-9]+"
                                    value={addExpenseCategory.repairCharges}
                                  />
                                  <span style={{ color: "red" }}>
                                    {errors?.repairCharges}
                                  </span>
                                  <br />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            className="btn"
                            data-dismiss="modal"
                            onClick={handleDiscardClick}
                          >
                            <i className="flaticon-cancel-12"></i>Discard
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary view-btn"
                          >
                            Add
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                {/* update model */}
                <div
                  className="modal fade"
                  id="editHubModalCenter"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="editHubModalCenterTitle"
                  style={{ display: "none" }}
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered modal-md"
                    role="document"
                  >
                    <div className="modal-content">
                      <form onSubmit={onUpdateHandler}>
                        <div className="modal-header">
                          <h5
                            className="modal-title"
                            id="editHubModalCenterTitle"
                          >
                            Update Category
                          </h5>
                          <button
                            type="button"
                            className="btn btn-primary view-btn"
                            hidden={editButtonHide}
                            onClick={() => setEditFormEditable(true)}
                          >
                            Edit
                          </button>
                        </div>

                        <div className="modal-body">
                          <div
                            className="customer-address-class"
                            id="customer-address-div-id"
                          >
                            <div className="row" id="defaultaddressdiv0">
                              <div className="col-md-12">
                                <div className="form-group required">
                                  <label htmlFor="inputName-add">
                                    Category Name *
                                  </label>
                                  <input
                                    disabled={disabled}
                                    type="text"
                                    ref={categoryEditFocus}
                                    className="form-control"
                                    name="name"
                                    id="inputName-add"
                                    placeholder="Enter Name"
                                    onChange={(e) =>
                                      setEditExpenseCategory({
                                        ...editExpenseCategory,
                                        category: e.target.value,
                                      })
                                    }
                                    value={editExpenseCategory.category}
                                  />
                                  <span style={{ color: "red" }}>
                                    {errors?.editCategory}
                                  </span>
                                  <br />
                                  <label
                                    className="mt-4"
                                    htmlFor="inputCharge-add"
                                  >
                                    Charge *
                                  </label>
                                  <input
                                    disabled={disabled}
                                    type="number"
                                    min="0"
                                    className="form-control"
                                    ref={chargeEditFocus}
                                    name="repairCharges"
                                    id="inputCharge-add"
                                    placeholder="Enter Charge Amount"
                                    onChange={(e) =>
                                      setEditExpenseCategory({
                                        ...editExpenseCategory,
                                        repairCharges: e.target.value,
                                      })
                                    }
                                    value={editExpenseCategory.repairCharges}
                                  />
                                  <span style={{ color: "red" }}>
                                    {errors?.repairCharges}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-dark close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary view-btn"
                            hidden={submitButtonHide}
                          >
                            Update
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </>
  );
};

export default ExpenseTracker;
