import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import Loader from "../../common/Loader/Loader";
import { GoogleMap, LoadScriptNext } from "@react-google-maps/api";
import { Marker, InfoWindow } from "@react-google-maps/api";
import * as action from "../../../action/Customer/customer_action";
import * as login_action from "../../../action/Auth/login_action";
import "../../../assets/css/users/user-profile.css";
import "../../../assets/plugins/table/datatable/datatables.css";
import "../../../assets/plugins/table/datatable/dt-global_style.css";
import $ from "jquery";
const CustomerAddresses = (props) => {
  const { loading } = useSelector((state) => state.addressListReducer);
  const { Addloading } = useSelector(
    (state) => state.addCustomerAddressReducer
  );
  const { Editloading } = useSelector((state) => state.EditAddressReduces);
  $.DataTable = require("datatables.net");
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const pincodeFocus = useRef();
  const stateFocus = useRef();
  const cityFocus = useRef();
  const addressFocus = useRef();
  const dispatchAddressFocus = useRef();
  const landmarkFocus = useRef();
  const zoneFocus = useRef();
  const contactPersonNameFocus = useRef();
  const contactMobileFocus = useRef();
  const latitudeFocus = useRef();
  const longitudeFocus = useRef();
  const pincodeEditFocus = useRef();
  const stateEditFocus = useRef();
  const cityEditFocus = useRef();
  const addressEditFocus = useRef();
  const dispatchAddressEditFocus = useRef();
  const landmarkEditFocus = useRef();
  const zoneEditFocus = useRef();
  const contactPersonNameEditFocus = useRef();
  const contactMobileEditFocus = useRef();
  const latitudeEditFocus = useRef();
  const longitudeEditFocus = useRef();
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [center, setCenter] = useState({ lat: 21.17024, lng: 72.831062 });
  const [values, setValues] = useState({
    cityValue: "",
    editCityValue: "",
    stateValue: "",
    editStateValue: "",
  });
  useEffect(() => {
    dispatch(login_action.state_list())
      .then((data) => {
        let newStateList = [];
        data.data.map((item) => {
          newStateList.push({
            value: item.stateId,
            label: item.stateName,
          });
        });
        setStateList(newStateList);
      })
      .catch({});
  }, []);
  const addChangeStateDropDown = (data) => {
    setValues({ ...values, stateValue: data });
    setAddAddress({ ...addAddress, stateId: data.value, state: data.label });
    dispatch(login_action.city_list_by_state(data.value))
      .then((data) => {
        let newCityList = [];
        data.data.map((item) => {
          newCityList.push({
            value: item.cityId,
            label: item.cityName,
          });
        });
        setCityList(newCityList);
      })
      .catch({});
  };
  const addChangeCityDropDown = (data) => {
    setValues({ ...values, cityValue: data });
    setAddAddress({ ...addAddress, cityId: data.value, city: data.label });
  };
  const editChangeStateDropDown = (data) => {
    setValues({ ...values, editStateValue: data });
    setEditAddress({ ...editAddress, stateId: data.value, state: data.label });
    dispatch(login_action.city_list_by_state(data.value))
      .then((data) => {
        let newCityList = [];
        data.data.map((item) => {
          newCityList.push({
            value: item.cityId,
            label: item.cityName,
          });
        });
        setCityList(newCityList);
      })
      .catch({});
  };
  const editChangeCityDropDown = (data) => {
    setValues({ ...values, editCityValue: data });
    setEditAddress({ ...editAddress, cityId: data.value, city: data.label });
  };
  const [disabled, setDisabled] = useState(true);
  const [editButtonHide, setEditButtonHide] = useState(false);
  const [submitButtonHide, setSubmitButtonHide] = useState(true);
  const setEditFormEditable = (type) => {
    if (type) {
      setDisabled(false);
      setSubmitButtonHide(false);
      setEditButtonHide(true);
    } else {
      setDisabled(true);
      setSubmitButtonHide(true);
      setEditButtonHide(false);
    }
  };
  const containerStyle = {
    width: "100%",
    height: "400px",
  };
  const [addAddress, setAddAddress] = useState({
    customerId: props.customer,
    address: "",
    dispatchAddress: "",
    pincode: "",
    country: "India",
    stateId: "",
    state: "",
    cityId: "",
    city: "",
    zone: "",
    landmark: "",
    contactPersonName: "",
    contactPersonMobile: "",
    latitude: "",
    longitude: "",
    isDefault: true,
  });
  const [editAddress, setEditAddress] = useState({
    addressId: "",
    address: "",
    dispatchAddress: "",
    pincode: "",
    country: "India",
    stateId: "",
    state: "",
    cityId: "",
    city: "",
    zone: "",
    landmark: "",
    contactPersonName: "",
    contactPersonMobile: "",
    latitude: 21.17024,
    longitude: 72.831062,
    isDefault: true,
  });
  const setUpdateAddress = (address) => {
    let new_address = address[6];
    setEditAddress({
      addressId: new_address._id,
      address: new_address.address,
      dispatchAddress: new_address.dispatchAddress,
      pincode: new_address.pincode,
      country: "India",
      stateId: new_address.stateId,
      state: new_address.state,
      cityId: new_address.cityId,
      city: new_address.city,
      zone: new_address.zone,
      landmark: new_address.landmark,
      contactPersonName: new_address.contactPersonName,
      contactPersonMobile: new_address.contactPersonMobile,
      latitude: new_address.latitude,
      longitude: new_address.longitude,
      isDefault: new_address.isDefault === "false" ? false : true,
    });
    setCenter({ lat: new_address.latitude, lng: new_address.longitude });
    dispatch(login_action.city_list_by_state(new_address.stateId))
      .then((data) => {
        let newCityList = [];
        data.data.map((item) => {
          newCityList.push({
            value: item.cityId,
            label: item.cityName,
          });
        });
        setCityList(newCityList);
      })
      .catch({});
    setValues({
      editCityValue: { value: new_address.cityId, label: new_address.city },
      editStateValue: { value: new_address.stateId, label: new_address.state },
    });
  };
  const changeLetLong = (e) => {
    let latitude = e.latLng.lat();
    let longitude = e.latLng.lng();
    setCenter({ lat: latitude, lng: longitude });
    setAddAddress({ ...addAddress, latitude: latitude, longitude: longitude });
  };
  const changeUpdateLetLong = (e) => {
    let latitude = e.latLng.lat();
    let longitude = e.latLng.lng();
    setCenter({ lat: latitude, lng: longitude });
    setEditAddress({
      ...editAddress,
      latitude: latitude,
      longitude: longitude,
    });
  };
  const customFilter = (option, inputValue) => {
    const input = inputValue.toLowerCase();
    const filterText = new RegExp("^" + input + ".*");
    const label = option.data.label.toLowerCase();
    return filterText.test(label) && option.label.toLowerCase().includes(input);
  };
  const ClearModelData = () => {
    setAddAddress({
      customerId: props.customer,
      address: "",
      dispatchAddress: "",
      pincode: "",
      country: "India",
      stateId: "",
      state: "",
      cityId: "",
      city: "",
      zone: "",
      landmark: "",
      contactPersonName: "",
      contactPersonMobile: "",
      latitude: "",
      longitude: "",
      isDefault: true,
    });
  };
  const [addressList, setAddressList] = useState([]);
  const activeDeactiveAlertPopup = (customer) => {
    dispatch(action.address_status_change(customer[5]))
      .then((data) => {
        toast.success(data.msg);
        let newAddressList = [];
        addressList.map((item) => {
          if (item[5] === customer[5]) {
            let address_data = data.data;
            newAddressList.push([
              address_data.address,
              address_data.pincode,
              address_data.city,
              address_data.isActive,
              `<a style="cursor: pointer;" data-toggle="modal" data-target="#addressModalCenter" class="btn btn-info btn-sm view-btn view-act-btn">View</a>`,
              address_data._id,
              address_data,
            ]);
          } else {
            newAddressList.push(item);
          }
        });
        setAddressList(newAddressList);
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  const tableName = "customer-addresses-list";
  useEffect(() => {
    dispatch(action.address_list(props.customer))
      .then((data) => {
        let newAddressList = [];
        data.data.map((item) => {
          newAddressList.push([
            item.address,
            item.pincode,
            item.city,
            item.isActive,
            `<a style="cursor: pointer;" data-toggle="modal" data-target="#addressModalCenter" class="btn btn-info btn-sm view-btn view-act-btn">View</a>`,
            item._id,
            item,
          ]);
        });
        setAddressList(newAddressList);
      })
      .catch({});
  }, []);
  useEffect(() => {
    const table = $(`#${tableName}`).DataTable({
      data: addressList,
      columns: [
        { title: "Address" },
        { title: "Pincode" },
        { title: "City" },
        { title: "Status" },
        { title: "Action" },
      ],
      columnDefs: [
        {
          targets: [3],
          className: "center",
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <a
                style={{ cursor: "pointer" }}
                className={
                  rowData[3] === 1
                    ? "btn btn-success btn-sm status-act-btn"
                    : "btn btn-danger btn-sm status-act-btn"
                }
                onClick={() => activeDeactiveAlertPopup(rowData)}
              >
                {" "}
                {rowData[3] === 1 ? "Active" : "Deactive"}{" "}
              </a>,
              td
            ),
        },
        {
          targets: [4],
          className: "center",
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <a
                style={{ cursor: "pointer" }}
                data-toggle="modal"
                onClick={() => setUpdateAddress(rowData)}
                data-target="#addressModalCenter"
                class="btn btn-info btn-sm view-btn view-act-btn"
              >
                View
              </a>,
              td
            ),
        },
      ],
      destroy: true, // I think some clean up is happening here
      searching: true,
      buttons: [
        {
          extend: "excel",
          className:
            "excelButton view-btn btn text-white float-right mt-2 ml-2",
        },
      ],
      oLanguage: {
        oPaginate: {
          sPrevious:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>',
          sNext:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>',
        },
        //sInfo: "Showing page _PAGE_ of _PAGES_",
        sSearch:
          '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
        sSearchPlaceholder: "Search...",
        sLengthMenu: "Results :  _MENU_",
      },
      fnInitComplete: function (oSettings) {
        $("#customer-addresses-list_filter").prepend(
          '<button id="addressBtn" type="button" class="btn btn-warning btn-sm add-act-btn" data-toggle="modal" data-target="#addAddressModalCenter">Add Address</button>'
        );
        const myButton = document.querySelector("#addressBtn");
        myButton.addEventListener("click", ClearModelData);
      },
    });
    // Extra step to do extra clean-up.
    return function () {
      table.destroy();
    };
  }, [addressList]);
  const onsubmitHandler = (e) => {
    e.preventDefault();
    setErrors({});
    let customErrors = {};
    if (!/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/.test(addAddress.pincode)) {
      customErrors = { ...customErrors, pincode: "Please enter valid pincode" };
      pincodeFocus.current.focus();
    } else if (addAddress.state === "") {
      customErrors = { ...customErrors, state: "Please select state" };
      stateFocus.current.focus();
    } else if (addAddress.city === "") {
      customErrors = { ...customErrors, city: "Please select city" };
      cityFocus.current.focus();
    } else if (addAddress.address === "") {
      customErrors = { ...customErrors, address: "Please enter address" };
      addressFocus.current.focus();
    } else if (addAddress.dispatchAddress === "") {
      customErrors = {
        ...customErrors,
        dispatchAddress: "Please enter dispatch address",
      };
      dispatchAddressFocus.current.focus();
      // } else if(addAddress.landmark === '') {
      //     customErrors = {...customErrors, landmark: "Please enter landmark"}
      //     landmarkFocus.current.focus();
    } else if (addAddress.zone === "") {
      customErrors = { ...customErrors, zone: "Please enter zone" };
      zoneFocus.current.focus();
      // } else if(addAddress.contactPersonName === '') {
      //     customErrors = {...customErrors, contactPersonName: "Please enter contact person name"}
      //     contactMobileFocus.current.focus();
    } else if (!/^[6789]\d{9}$/.test(addAddress.contactPersonMobile)) {
      customErrors = {
        ...customErrors,
        mobile: "Please enter valid mobile number",
      };
      contactMobileFocus.current.focus();
    } else if (addAddress.latitude === "") {
      customErrors = { ...customErrors, latitude: "Please enter latitude" };
      latitudeFocus.current.focus();
    } else if (addAddress.longitude === "") {
      customErrors = { ...customErrors, longitude: "Please enter longitude" };
      longitudeFocus.current.focus();
    }
    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }
    dispatch(action.add_customer_address(addAddress))
      .then((data) => {
        toast.success(data.msg);
        let response = data.data;
        setAddressList([
          ...addressList,
          [
            response.address,
            response.pincode,
            response.city,
            response.isActive,
            `<a style="cursor: pointer;" data-toggle="modal" data-target="#addressModalCenter" class="btn btn-info btn-sm view-btn view-act-btn">View</a>`,
            response._id,
            response,
          ],
        ]);
        setAddAddress({
          customerId: props.customer,
          address: "",
          dispatchAddress: "",
          pincode: "",
          country: "India",
          stateId: "",
          state: "",
          cityId: "",
          city: "",
          zone: "",
          landmark: "",
          contactPersonName: "",
          contactPersonMobile: "",
          latitude: "",
          longitude: "",
          isDefault: true,
        });
        $("#addAddressModalCenter .close").click();
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  const onUpdateHandler = (e) => {
    e.preventDefault();
    setErrors({});
    let customErrors = {};
    if (!/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/.test(editAddress.pincode)) {
      customErrors = {
        ...customErrors,
        editPincode: "Please enter valid pincode",
      };
      pincodeEditFocus.current.focus();
    } else if (editAddress.state === "") {
      customErrors = { ...customErrors, editState: "Please select state" };
      stateEditFocus.current.focus();
    } else if (editAddress.city === "") {
      customErrors = { ...customErrors, editCity: "Please select city" };
      cityEditFocus.current.focus();
    } else if (editAddress.address === "") {
      customErrors = { ...customErrors, editAddress: "Please enter address" };
      addressEditFocus.current.focus();
    } else if (editAddress.dispatchAddress === "") {
      customErrors = {
        ...customErrors,
        editDispatchAddress: "Please enter dispatch address",
      };
      dispatchAddressEditFocus.current.focus();
      // } else if(editAddress.landmark === '') {
      //     customErrors = {...customErrors, editLandmark: "Please enter landmark"}
      //     landmarkEditFocus.current.focus();
    } else if (editAddress.zone === "") {
      customErrors = { ...customErrors, editZone: "Please enter zone" };
      zoneEditFocus.current.focus();
      // } else if(editAddress.contactPersonName === '') {
      //     customErrors = {...customErrors, editContactPersonName: "Please enter contact person name"}
      //     contactPersonNameEditFocus.current.focus();
    } else if (!/^[6789]\d{9}$/.test(editAddress.contactPersonMobile)) {
      customErrors = {
        ...customErrors,
        editMobile: "Please enter valid mobile number",
      };
      contactMobileEditFocus.current.focus();
    } else if (editAddress.latitude === "") {
      customErrors = { ...customErrors, editLatitude: "Please enter latitude" };
      latitudeEditFocus.current.focus();
    } else if (editAddress.longitude === "") {
      customErrors = {
        ...customErrors,
        editLongitude: "Please enter longitude",
      };
      longitudeEditFocus.current.focus();
    }
    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }
    dispatch(action.edit_customer_address(editAddress))
      .then((data) => {
        toast.success(data.msg);
        let newAddressList = [];
        addressList.map((item) => {
          if (item[5] === editAddress.addressId) {
            let address_data = data.data;
            newAddressList.push([
              address_data.address,
              address_data.pincode,
              address_data.city,
              address_data.isActive,
              `<a style="cursor: pointer;" data-toggle="modal" data-target="#addressModalCenter" class="btn btn-info btn-sm view-btn view-act-btn">View</a>`,
              address_data._id,
              address_data,
            ]);
          } else {
            newAddressList.push(item);
          }
        });
        setAddressList(newAddressList);
        setEditAddress({
          addressId: "",
          address: "",
          dispatchAddress: "",
          pincode: "",
          country: "India",
          stateId: "",
          state: "",
          cityId: "",
          city: "",
          zone: "",
          landmark: "",
          contactPersonName: "",
          contactPersonMobile: "",
          latitude: 21.17024,
          longitude: 72.831062,
          isDefault: true,
        });
        setEditFormEditable(false);
        $("#addressModalCenter .close").click();
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  return (
    <>
      {loading ? <Loader /> : null}
      {Addloading ? <Loader /> : null}
      {Editloading ? <Loader /> : null}
      {/* <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light"/> */}
      <div className="table-responsive mb-4 mt-4">
        <table
          id="customer-addresses-list"
          className="table table-hover"
          style={{ width: "100%" }}
        >
          <thead>
            <tr>
              <th>Address</th>
              <th>Pincode</th>
              <th>City</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
      <div
        className="modal fade"
        id="addressModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="addressModalCenterTitle"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content">
            <form onSubmit={onUpdateHandler}>
              <div className="modal-header">
                <h5 className="modal-title" id="addressModalCenterTitle">
                  Update Address
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <svg
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              </div>

              <div className="modal-body">
                <div
                  className="customer-address-class"
                  id="customer-address-div-id"
                >
                  <div className="row" id="defaultaddressdiv0">
                    <div className="col-sm-3">
                      <div className="form-group required">
                        <label for="inputPincode">Pincode *</label>
                        <input
                          disabled={disabled}
                          type="text"
                          name="pincode"
                          ref={pincodeEditFocus}
                          className="form-control"
                          id="inputPincode"
                          placeholder="Enter Pincode"
                          onChange={(e) =>
                            setEditAddress({
                              ...editAddress,
                              pincode: e.target.value,
                            })
                          }
                          value={editAddress.pincode}
                        />
                        <span style={{ color: "red" }}>
                          {errors?.editPincode}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group required">
                        <label for="inputCountry">Country *</label>
                        <input
                          disabled
                          value="India"
                          name="country"
                          type="text"
                          className="form-control"
                          id="inputCountry"
                          placeholder="Enter Country"
                          required
                          onChange={(e) =>
                            setEditAddress({
                              ...editAddress,
                              country: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group required">
                        <label for="inputState">State *</label>
                        <Select
                          id="inputState"
                          isDisabled={disabled}
                          value={values.editStateValue || "Select"}
                          onChange={(value) => editChangeStateDropDown(value)}
                          options={stateList}
                          filterOption={customFilter}
                          ref={stateEditFocus}
                        />
                        {/* <input disabled={disabled} type="text" name="state" className="form-control" id="inputState" placeholder="Enter State" required onChange={(e)=> setEditAddress({ ...editAddress, state: e.target.value, }) } value={editAddress.state} pattern="^[a-zA-Z]+$"/> */}
                        <span style={{ color: "red" }}>
                          {errors?.editState}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group required">
                        <label for="inputCity">City *</label>
                        <Select
                          id="inputState"
                          isDisabled={disabled}
                          ref={cityEditFocus}
                          value={values.editCityValue || "Select"}
                          onChange={(value) => editChangeCityDropDown(value)}
                          options={cityList}
                          filterOption={customFilter}
                          required
                        />
                        {/* <input disabled={disabled} type="text" name="city" className="form-control" id="inputCity" placeholder="Enter City" required onChange={(e)=> setEditAddress({ ...editAddress, city: e.target.value, }) } value={editAddress.city} pattern="^[a-zA-Z]+$"/> */}
                        <span style={{ color: "red" }}>{errors?.editCity}</span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3 required">
                    <label for="inputAddress">Address *</label>
                    <input
                      disabled={disabled}
                      type="text"
                      name="address"
                      className="form-control"
                      id="inputAddress"
                      placeholder="1234 Main St"
                      ref={addressEditFocus}
                      onChange={(e) =>
                        setEditAddress({
                          ...editAddress,
                          address: e.target.value,
                        })
                      }
                      value={editAddress.address}
                    />
                    <span style={{ color: "red" }}>{errors?.editAddress}</span>
                  </div>
                  <div className="form-group mb-3 required">
                    <label for="inputAddress">Godown/Dispatch Address *</label>
                    <input
                      disabled={disabled}
                      type="text"
                      ref={dispatchAddressEditFocus}
                      className="form-control"
                      name="dispatchAddress"
                      id="inputDispatchAddress"
                      placeholder="1234 Main St"
                      onChange={(e) =>
                        setEditAddress({
                          ...editAddress,
                          dispatchAddress: e.target.value,
                        })
                      }
                      value={editAddress.dispatchAddress}
                    />
                    <span style={{ color: "red" }}>
                      {errors?.editDispatchAddress}
                    </span>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label for="inputLandmark">Landmark</label>
                        <input
                          disabled={disabled}
                          type="text"
                          ref={landmarkEditFocus}
                          className="form-control"
                          name="landmark"
                          id="inputLandmark"
                          placeholder="Landmark"
                          onChange={(e) =>
                            setEditAddress({
                              ...editAddress,
                              landmark: e.target.value,
                            })
                          }
                          value={editAddress.landmark}
                        />
                        <span style={{ color: "red" }}>
                          {errors?.editLandmark}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group required">
                        <label for="inputZone">Zone *</label>
                        <input
                          disabled={disabled}
                          type="text"
                          ref={zoneEditFocus}
                          className="form-control"
                          name="zone"
                          id="inputZone"
                          placeholder="Enter Zone"
                          onChange={(e) =>
                            setEditAddress({
                              ...editAddress,
                              zone: e.target.value,
                            })
                          }
                          value={editAddress.zone}
                        />
                        <span style={{ color: "red" }}>{errors?.editZone}</span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group ">
                        <label for="inputContactPersonName">
                          Contact Person Name
                        </label>
                        <input
                          disabled={disabled}
                          type="text"
                          ref={contactPersonNameEditFocus}
                          className="form-control"
                          name="contactPersonName"
                          id="inputContactPersonName"
                          placeholder="Enter Contact Person Name"
                          onChange={(e) =>
                            setEditAddress({
                              ...editAddress,
                              contactPersonName: e.target.value,
                            })
                          }
                          value={editAddress.contactPersonName}
                        />
                        <span style={{ color: "red" }}>
                          {errors?.editContactPersonName}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group ">
                        <label for="inputContactPersonNumber">
                          Contact Person Number
                        </label>
                        <input
                          disabled={disabled}
                          ref={contactMobileEditFocus}
                          type="text"
                          className="form-control"
                          id="inputContactPersonNumber"
                          placeholder="Enter Contact Person Number"
                          name="contactPersonMobile"
                          onChange={(e) =>
                            setEditAddress({
                              ...editAddress,
                              contactPersonMobile: e.target.value,
                            })
                          }
                          value={editAddress.contactPersonMobile}
                        />
                        <span style={{ color: "red" }}>
                          {errors?.editMobile}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-sm-12">
                      {/* <div className="map" id="map"></div> */}
                      <LoadScriptNext googleMapsApiKey="AIzaSyB6PMCguHWiTCN0nLcEasXVxUmWOpHS5gY">
                        <GoogleMap
                          onClick={(e) => changeUpdateLetLong(e)}
                          mapContainerStyle={containerStyle}
                          center={center}
                          zoom={11}
                        >
                          {/* {
                                                    currentPosition.lat &&
                                                    ( 
                                                        <Marker position={currentPosition}></Marker>
                                                    ) 
                                                } */}
                          <Marker
                            visible={false}
                            zIndex={1111}
                            position={{
                              lat: editAddress.latitude,
                              lng: editAddress.longitude,
                            }}
                          >
                            <InfoWindow
                              position={{
                                lat: editAddress.latitude,
                                lng: editAddress.longitude,
                              }}
                            >
                              {editAddress.longitude ? (
                                <label>
                                  {" "}
                                  "let" : {editAddress.latitude} , "long":{" "}
                                  {editAddress.longitude}
                                </label>
                              ) : (
                                <label>
                                  Click the map to get latitude / longitude!
                                </label>
                              )}
                            </InfoWindow>
                          </Marker>
                        </GoogleMap>
                      </LoadScriptNext>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group required">
                        <label for="inputLatitude">Latitude *</label>
                        <input
                          disabled={disabled}
                          type="text"
                          ref={latitudeEditFocus}
                          className="form-control"
                          name="latitude"
                          id="inputLatitude"
                          placeholder="Enter Latitude"
                          onChange={(e) =>
                            setEditAddress({
                              ...editAddress,
                              latitude: e.target.value,
                            })
                          }
                          value={editAddress.latitude}
                        />
                        <span style={{ color: "red" }}>
                          {errors?.editLatitude}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group required">
                        <label for="inputLongitude">Longitude *</label>
                        <input
                          disabled={disabled}
                          type="text"
                          ref={longitudeEditFocus}
                          className="form-control"
                          name="longitude"
                          id="inputLongitude"
                          placeholder="Enter Longitude"
                          required
                          onChange={(e) =>
                            setEditAddress({
                              ...editAddress,
                              longitude: e.target.value,
                            })
                          }
                          value={editAddress.longitude}
                        />
                        <span style={{ color: "red" }}>
                          {errors?.editLongitude}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-check pl-0">
                    <div className="custom-control custom-checkbox checkbox-info">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="gridCheck"
                        checked
                        disabled={disabled}
                        onChange={(e) =>
                          setEditAddress({
                            ...editAddress,
                            isDefault: e.target.value,
                          })
                        }
                        value={editAddress.isDefault}
                      />
                      <label className="custom-control-label" for="gridCheck">
                        This is a default address
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  id="editAddressBtn"
                  className="btn btn-primary view-btn"
                  hidden={editButtonHide}
                  onClick={() => setEditFormEditable(true)}
                >
                  Edit
                </button>
                <button
                  type="submit"
                  id="updateAddressBtn"
                  className="btn btn-primary view-btn"
                  hidden={submitButtonHide}
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="addAddressModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="addAddressModalCenterTitle"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content">
            <form onSubmit={onsubmitHandler}>
              <div className="modal-header">
                <h5 className="modal-title" id="addAddressModalCenterTitle">
                  Add Address
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <svg
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              </div>

              <div className="modal-body">
                <div
                  className="customer-address-class"
                  id="customer-address-div-id"
                >
                  <div className="row" id="defaultaddressdiv0">
                    <div className="col-sm-3">
                      <div className="form-group required">
                        <label for="inputPincode-add">Pincode *</label>
                        <input
                          type="text"
                          className="form-control"
                          ref={pincodeFocus}
                          name="pincode"
                          id="inputPincode-add"
                          placeholder="Enter Pincode"
                          onChange={(e) =>
                            setAddAddress({
                              ...addAddress,
                              pincode: e.target.value,
                            })
                          }
                          value={addAddress.pincode}
                        />
                        <span style={{ color: "red" }}>{errors?.pincode}</span>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group required">
                        <label for="inputCountry-add">Country *</label>
                        <input
                          disabled
                          type="text"
                          name="country"
                          className="form-control"
                          id="inputCountry-add"
                          placeholder="Enter Country"
                          required
                          onChange={(e) =>
                            setAddAddress({
                              ...addAddress,
                              country: e.target.value,
                            })
                          }
                          value={addAddress.country}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group required">
                        <label for="inputState-add">State *</label>
                        <Select
                          id="inputState-add"
                          ref={stateFocus}
                          value={values.stateValue || "Select"}
                          onChange={(value) => addChangeStateDropDown(value)}
                          options={stateList}
                          filterOption={customFilter}
                        />
                        {/* <input type="text" className="form-control" name="state" id="inputState-add" placeholder="Enter State" required onChange={(e)=>setAddAddress({...addAddress ,  state:e.target.value})} value={addAddress.state}/> */}
                        <span style={{ color: "red" }}>{errors?.state}</span>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group required">
                        <label for="inputCity-add">City *</label>
                        <Select
                          id="inputCity-add"
                          ref={cityFocus}
                          value={values.cityValue || "Select"}
                          onChange={(value) => addChangeCityDropDown(value)}
                          options={cityList}
                          filterOption={customFilter}
                        />
                        {/* <input type="text" className="form-control" name="city" id="inputCity-add" placeholder="Enter City" required onChange={(e)=>setAddAddress({...addAddress ,  city:e.target.value})} value={addAddress.city} pattern="^[a-zA-Z]+$"/> */}
                        <span style={{ color: "red" }}>{errors?.city}</span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3 required">
                    <label for="inputAddress-add">Address *</label>
                    <input
                      type="text"
                      ref={addressFocus}
                      className="form-control"
                      name="address"
                      id="inputAddress-add"
                      placeholder="1234 Main St"
                      onChange={(e) =>
                        setAddAddress({
                          ...addAddress,
                          address: e.target.value,
                        })
                      }
                      value={addAddress.address}
                    />
                    <span style={{ color: "red" }}>{errors?.address}</span>
                  </div>
                  <div className="form-group mb-3 required">
                    <label for="inputAddress-add">
                      Godown/Dispatch Address *
                    </label>
                    <input
                      type="text"
                      ref={dispatchAddressFocus}
                      className="form-control"
                      name="dispatchAddress"
                      id="inputDispatchAddress-add"
                      placeholder="1234 Main St"
                      onChange={(e) =>
                        setAddAddress({
                          ...addAddress,
                          dispatchAddress: e.target.value,
                        })
                      }
                      value={addAddress.dispatchAddress}
                    />
                    <span style={{ color: "red" }}>
                      {errors?.dispatchAddress}
                    </span>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label for="inputLandmark-add">Landmark</label>
                        <input
                          type="text"
                          className="form-control"
                          ref={landmarkFocus}
                          name="landmark"
                          id="inputLandmark-add"
                          placeholder="Landmark"
                          onChange={(e) =>
                            setAddAddress({
                              ...addAddress,
                              landmark: e.target.value,
                            })
                          }
                          value={addAddress.landmark}
                        />
                        <span style={{ color: "red" }}>{errors?.landmark}</span>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group required">
                        <label for="inputZone-add">Zone *</label>
                        <input
                          type="text"
                          className="form-control"
                          ref={zoneFocus}
                          id="inputZone-add"
                          name="zone"
                          placeholder="Enter Zone"
                          onChange={(e) =>
                            setAddAddress({
                              ...addAddress,
                              zone: e.target.value,
                            })
                          }
                          value={addAddress.zone}
                        />
                        <span style={{ color: "red" }}>
                          {errors?.zoneFocus}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group ">
                        <label for="inputContactPersonName-add">
                          Contact Person Name
                        </label>
                        <input
                          type="text"
                          ref={contactPersonNameFocus}
                          className="form-control"
                          name="contactPersonName"
                          id="inputContactPersonName-add"
                          placeholder="Enter Contact Person Name"
                          onChange={(e) =>
                            setAddAddress({
                              ...addAddress,
                              contactPersonName: e.target.value,
                            })
                          }
                          value={addAddress.contactPersonName}
                        />
                        <span style={{ color: "red" }}>
                          {errors?.contactPersonName}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group ">
                        <label for="inputContactPersonNumber-add">
                          Contact Person Number
                        </label>
                        <input
                          type="text"
                          ref={contactMobileFocus}
                          name="contactPersonMobile"
                          className="form-control"
                          id="inputContactPersonNumber-add"
                          placeholder="Enter Contact Person Number"
                          onChange={(e) =>
                            setAddAddress({
                              ...addAddress,
                              contactPersonMobile: e.target.value,
                            })
                          }
                          value={addAddress.contactPersonMobile}
                        />
                        <span style={{ color: "red" }}>{errors?.mobile}</span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-sm-12">
                      {/* <div className="map" id="map-add"></div> */}
                      {/* <div className="map" id="map"></div> */}
                      <LoadScriptNext googleMapsApiKey="AIzaSyB6PMCguHWiTCN0nLcEasXVxUmWOpHS5gY">
                        <GoogleMap
                          onClick={(e) => changeLetLong(e)}
                          mapContainerStyle={containerStyle}
                          center={center}
                          zoom={11}
                        >
                          {/* {
                                                    currentPosition.lat &&
                                                    ( 
                                                        <Marker position={currentPosition}></Marker>
                                                    ) 
                                                } */}
                          <Marker
                            visible={false}
                            zIndex={1111}
                            position={center}
                          >
                            <InfoWindow position={center}>
                              {addAddress.longitude ? (
                                <label>
                                  {" "}
                                  "let" : {addAddress.latitude} , "long":{" "}
                                  {addAddress.longitude}
                                </label>
                              ) : (
                                <label>
                                  Click the map to get latitude / longitude!
                                </label>
                              )}
                            </InfoWindow>
                          </Marker>
                        </GoogleMap>
                      </LoadScriptNext>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group required">
                        <label for="inputLatitude-add">Latitude *</label>
                        <input
                          type="text"
                          className="form-control"
                          ref={latitudeFocus}
                          id="inputLatitude-add"
                          name="latitude"
                          placeholder="Enter latitude"
                          onChange={(e) =>
                            setAddAddress({
                              ...addAddress,
                              latitude: e.target.value,
                            })
                          }
                          value={addAddress.latitude}
                        />
                        <span style={{ color: "red" }}>{errors?.latitude}</span>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group required">
                        <label for="inputLongitude-add">Longitude *</label>
                        <input
                          type="text"
                          className="form-control"
                          ref={longitudeFocus}
                          id="inputLongitude-add"
                          name="longitude"
                          placeholder="Enter Longitude"
                          onChange={(e) =>
                            setAddAddress({
                              ...addAddress,
                              longitude: e.target.value,
                            })
                          }
                          value={addAddress.longitude}
                        />
                        <span style={{ color: "red" }}>
                          {errors?.longitude}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-check pl-0">
                    <div className="custom-control custom-checkbox checkbox-info">
                      <input
                        type="checkbox"
                        name="isDefault"
                        className="custom-control-input"
                        id="gridCheck-add"
                        onChange={(e) =>
                          setAddAddress({
                            ...addAddress,
                            isDefault: e.target.value,
                          })
                        }
                        checked={addAddress.isDefault}
                      />
                      <label
                        className="custom-control-label"
                        for="gridCheck-add"
                      >
                        This is a default address
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button className="btn" data-dismiss="modal">
                  <i className="flaticon-cancel-12"></i>Discard
                </button>
                <button type="submit" className="btn btn-primary view-btn">
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default CustomerAddresses;
