import {
  DASHBOARD_VEHICLE_DETAILS_LOADING,
  DASHBOARD_VEHICLE_DETAILS_SUCCESSFULL,
  DASHBOARD_VEHICLE_DETAILS_ERROR,
  DASHBOARD_DRIVER_DETAILS_LOADING,
  DASHBOARD_DRIVER_DETAILS_SUCCESSFULL,
  DASHBOARD_DRIVER_DETAILS_ERROR,
  DASHBOARD_CUSTOMER_DETAILS_DAILY_LOADING,
  DASHBOARD_CUSTOMER_DETAILS_DAILY_SUCCESSFULL,
  DASHBOARD_CUSTOMER_DETAILS_DAILY_ERROR,
  DASHBOARD_RIDER_DETAILS_MONTHLY_LOADING,
  DASHBOARD_RIDER_DETAILS_MONTHLY_SUCCESSFULL,
  DASHBOARD_RIDER_DETAILS_MONTHLY_ERROR,
  DASHBOARD_VEHICLE_FLEET_MONTHLY_LOADING,
  DASHBOARD_VEHICLE_FLEET_MONTHLY_SUCCESSFULL,
  DASHBOARD_VEHICLE_FLEET_MONTHLY_ERROR,
  DASHBOARD_DRIVER_WORK_MONTHLY_LOADING,
  DASHBOARD_DRIVER_WORK_MONTHLY_SUCCESSFULL,
  DASHBOARD_DRIVER_WORK_MONTHLY_ERROR,
  DASHBOARD_REPAIRE_EXPENSE_MONTHLY_LOADING,
  DASHBOARD_REPAIRE_EXPENSE_MONTHLY_SUCCESSFULL,
  DASHBOARD_REPAIRE_EXPENSE_MONTHLY_ERROR,
  DASHBOARD_MONTH_LOADING,
  DASHBOARD_MONTH_SUCCESSFULL,
  DASHBOARD_MONTH_ERROR,
  DASHBOARD_ADD_SALES_PAYOUT_LOADING,
  DASHBOARD_ADD_SALES_PAYOUT_SUCCESSFULL,
  DASHBOARD_ADD_SALES_PAYOUT_ERROR,
  DASHBOARD_SALES_PAYOUT_LIST_LOADING,
  DASHBOARD_SALES_PAYOUT_LIST_SUCCESSFULL,
  DASHBOARD_SALES_PAYOUT_LIST_ERROR,
  DASHBOARD_VIEW_SALES_PAYOUT_LOADING,
  DASHBOARD_VIEW_SALES_PAYOUT_SUCCESSFULL,
  DASHBOARD_VIEW_SALES_PAYOUT_ERROR,
  DASHBOARD_EDIT_SALES_PAYOUT_LOADING,
  DASHBOARD_EDIT_SALES_PAYOUT_SUCCESSFULL,
  DASHBOARD_EDIT_SALES_PAYOUT_ERROR,
  DASHBOARD_EXCEL_SALES_PAYOUT_LOADING,
  DASHBOARD_EXCEL_SALES_PAYOUT_SUCCESSFULL,
  DASHBOARD_EXCEL_SALES_PAYOUT_ERROR,
  DASHBOARD_CUSTOMER_GROWTH_LOADING,
  DASHBOARD_CUSTOMER_GROWTH_SUCCESSFULL,
  DASHBOARD_CUSTOMER_GROWTH_ERROR,
  DRIVER_WISE_SUBMITED_PARCEL_LOADING,
  DRIVER_WISE_SUBMITED_PARCEL_SUCCESS,
  DRIVER_WISE_SUBMITED_PARCEL_ERROR,
  DASHBOARD_CLIENT_PERFORMANCE_LOADING,
  DASHBOARD_CLIENT_PERFORMANCE_SUCCESS,
  DASHBOARD_CLIENT_PERFORMANCE_ERROR,
  DASHBOARD_LAST_MONTH_SALES_LOADING,
  DASHBOARD_LAST_MONTH_SALES_SUCCESS,
  DASHBOARD_LAST_MONTH_SALES_ERROR,
  DASHBOARD_LAST_QUARTER_SALES_LOADING,
  DASHBOARD_LAST_QUARTER_SALES_SUCCESS,
  DASHBOARD_LAST_QUARTER_SALES_ERROR,
  DASHBOARD_REVENUE_PERFORMANCE_LOADING,
  DASHBOARD_REVENUE_PERFORMANCE_SUCCESS,
  DASHBOARD_REVENUE_PERFORMANCE_ERROR,
  DASHBOARD_REVENUE_CITIES_WISE_LOADING,
  DASHBOARD_REVENUE_CITIES_WISE_SUCCESS,
  DASHBOARD_REVENUE_CITIES_WISE_ERROR,
  DASHBOARD_UTILISED_FLEET_LOADING,
  DASHBOARD_UTILISED_FLEET_SUCCESS,
  DASHBOARD_UTILISED_FLEET_ERROR,
  DASHBOARD_RIDERS_PARCEL_DELIVERY_LOADING,
  DASHBOARD_RIDERS_PARCEL_DELIVERY_SUCCESS,
  DASHBOARD_RIDERS_PARCEL_DELIVERY_ERROR,
  DASHBOARD_RIDERS_EARNING_CHART_LOADING,
  DASHBOARD_RIDERS_EARNING_CHART_SUCCESS,
  DASHBOARD_RIDERS_EARNING_CHART_ERROR,
  CITIES_LIST_LOADING,
  CITIES_LIST_SUCCESSFULL,
  CITIES_LIST_ERROR,
  DASHBOARD_SWIGGY_BILLING_CHART_LOADING,
  DASHBOARD_SWIGGY_BILLING_CHART_SUCCESS,
  DASHBOARD_SWIGGY_BILLING_CHART_ERROR,
  DASHBOARD_ZOMATO_BILLING_CHART_LOADING,
  DASHBOARD_ZOMATO_BILLING_CHART_SUCCESS,
  DASHBOARD_ZOMATO_BILLING_CHART_ERROR,
  DASHBOARD_BIGBASKET_BILLING_CHART_LOADING,
  DASHBOARD_BIGBASKET_BILLING_CHART_SUCCESS,
  DASHBOARD_BIGBASKET_BILLING_CHART_ERROR,
  DASHBOARD_BBNOW_BILLING_CHART_LOADING,
  DASHBOARD_BBNOW_BILLING_CHART_SUCCESS,
  DASHBOARD_BBNOW_BILLING_CHART_ERROR,
  DASHBOARD_FLIPKART_BILLING_CHART_LOADING,
  DASHBOARD_FLIPKART_BILLING_CHART_SUCCESS,
  DASHBOARD_FLIPKART_BILLING_CHART_ERROR,
  DASHBOARD_BLUEDART_BILLING_CHART_LOADING,
  DASHBOARD_BLUEDART_BILLING_CHART_SUCCESS,
  DASHBOARD_BLUEDART_BILLING_CHART_ERROR,
  DASHBOARD_FULLTIME_PARCEL_CHART_LOADING,
  DASHBOARD_FULLTIME_PARCEL_CHART_SUCCESS,
  DASHBOARD_FULLTIME_PARCEL_CHART_ERROR,
  DASHBOARD_PARTTIME_PARCEL_CHART_LOADING,
  DASHBOARD_PARTTIME_PARCEL_CHART_SUCCESS,
  DASHBOARD_PARTTIME_PARCEL_CHART_ERROR,
} from "../../action/type";

import * as AuthService from "../../service/services";

export const city_list = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: CITIES_LIST_LOADING,
        data: true,
      });
      AuthService.CityList()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: CITIES_LIST_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: CITIES_LIST_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const dashboard_vehicles_details = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_VEHICLE_DETAILS_LOADING,
        data: true,
      });
      AuthService.DashboardVehicleDetails()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_VEHICLE_DETAILS_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_VEHICLE_DETAILS_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const dashboard_driver_details = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_DRIVER_DETAILS_LOADING,
        data: true,
      });
      AuthService.DashboardDriverDetails()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_DRIVER_DETAILS_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_DRIVER_DETAILS_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const dashboard_customer_details_daily = (year) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_CUSTOMER_DETAILS_DAILY_LOADING,
        data: true,
      });
      AuthService.DashboardCustomerDetailsDaily(year)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_CUSTOMER_DETAILS_DAILY_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_CUSTOMER_DETAILS_DAILY_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const dashboard_newrider_leftrider_monthly = (year) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_RIDER_DETAILS_MONTHLY_LOADING,
        data: true,
      });
      AuthService.DashboardNewRiderleftRiderMonthly(year)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_RIDER_DETAILS_MONTHLY_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_RIDER_DETAILS_MONTHLY_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const dashboard_vehicle_fleet_monthly = (year, month) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_VEHICLE_FLEET_MONTHLY_LOADING,
        data: true,
      });
      AuthService.DashboardVehicleFleetMonthly(year, month)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_VEHICLE_FLEET_MONTHLY_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_VEHICLE_FLEET_MONTHLY_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const dashboard_driver_work_monthly = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_DRIVER_WORK_MONTHLY_LOADING,
        data: true,
      });
      AuthService.DashboardDriverWorkMonthly(id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_DRIVER_WORK_MONTHLY_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_DRIVER_WORK_MONTHLY_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const dashboard_repair_expense_monthly = (repairFilterData) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_REPAIRE_EXPENSE_MONTHLY_LOADING,
        data: true,
      });
      AuthService.DashboardRepairMonthly(repairFilterData)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_REPAIRE_EXPENSE_MONTHLY_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_REPAIRE_EXPENSE_MONTHLY_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const dashboard_month = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_MONTH_LOADING,
        data: true,
      });
      AuthService.DashboardMonth()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_MONTH_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_MONTH_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const dashboard_add_sales_payout = (credential) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_ADD_SALES_PAYOUT_LOADING,
        data: true,
      });
      AuthService.DashboardAddSalesPayout(credential)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_ADD_SALES_PAYOUT_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_ADD_SALES_PAYOUT_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const dashboard_city_list = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_ADD_SALES_PAYOUT_LOADING,
        data: true,
      });
      AuthService.DashboardCityList()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_ADD_SALES_PAYOUT_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_ADD_SALES_PAYOUT_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const dashboard_sales_payout_view = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_SALES_PAYOUT_LIST_LOADING,
        data: true,
      });
      AuthService.DashboardSalesPayoutList()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_SALES_PAYOUT_LIST_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_SALES_PAYOUT_LIST_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const dashboard_view_sales_payout = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_VIEW_SALES_PAYOUT_LOADING,
        data: true,
      });
      AuthService.DashboardviewSalesPayout(id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_VIEW_SALES_PAYOUT_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_VIEW_SALES_PAYOUT_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const dashboard_edit_sales_payout = (credential) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_EDIT_SALES_PAYOUT_LOADING,
        data: true,
      });
      AuthService.DashboardEditSalesPayout(credential)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_EDIT_SALES_PAYOUT_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_EDIT_SALES_PAYOUT_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const dashboard_export_excel_sales_payout = (credential) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_EXCEL_SALES_PAYOUT_LOADING,
        data: true,
      });
      AuthService.DashboardExportExcelSalesPayout(credential)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_EXCEL_SALES_PAYOUT_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_EXCEL_SALES_PAYOUT_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const dashboard_customer_growth = (year, month) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_CUSTOMER_GROWTH_LOADING,
        data: true,
      });
      AuthService.DashboardCustomerGrowth(year, month)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_CUSTOMER_GROWTH_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_CUSTOMER_GROWTH_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const driver_wise_submited_parcel = (year, applyFilterData) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_WISE_SUBMITED_PARCEL_LOADING,
        data: true,
      });
      AuthService.DriverWiseSubmitedParcel(year, applyFilterData)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_WISE_SUBMITED_PARCEL_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_WISE_SUBMITED_PARCEL_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const dashboard_client_performance = (year, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_CLIENT_PERFORMANCE_LOADING,
        data: true,
      });
      AuthService.DashboardClientPerFormace(year, data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_CLIENT_PERFORMANCE_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_CLIENT_PERFORMANCE_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const dashboard_lastMonth_Sales = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_LAST_MONTH_SALES_LOADING,
        data: true,
      });
      AuthService.DashboardLastMonthSales()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_LAST_MONTH_SALES_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_LAST_MONTH_SALES_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const dashboard_lastQuarter_Sales = (year, startMonth, endMonth) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_LAST_QUARTER_SALES_LOADING,
        data: true,
      });
      AuthService.DashboardLastQuarterSales(year, startMonth, endMonth)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_LAST_QUARTER_SALES_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_LAST_QUARTER_SALES_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const dashboard_revenue_performace = (year, month) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_REVENUE_PERFORMANCE_LOADING,
        data: true,
      });
      AuthService.DashboardRevenuePerformance(year, month)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_REVENUE_PERFORMANCE_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_REVENUE_PERFORMANCE_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const dashboard_revenue_cities_wise = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_REVENUE_CITIES_WISE_LOADING,
        data: true,
      });
      AuthService.DashboardRevenueCityWise(data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_REVENUE_CITIES_WISE_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_REVENUE_CITIES_WISE_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const dashboard_utilised_fleet = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_UTILISED_FLEET_LOADING,
        data: true,
      });
      AuthService.UtilisedFleetEfficiency(data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_UTILISED_FLEET_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_UTILISED_FLEET_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const dashboard_riders_parcel_delivery_chart = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_RIDERS_PARCEL_DELIVERY_LOADING,
        data: true,
      });
      AuthService.RiderParcelDeliveryChart(data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_RIDERS_PARCEL_DELIVERY_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_RIDERS_PARCEL_DELIVERY_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const dashboard_riders_earning_chart = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_RIDERS_EARNING_CHART_LOADING,
        data: true,
      });
      AuthService.RiderEarningChart(data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_RIDERS_EARNING_CHART_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_RIDERS_EARNING_CHART_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const dashboard_swiggy_billing_chart = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_SWIGGY_BILLING_CHART_LOADING,
        data: true,
      });
      AuthService.GetSwiggyBillingChartData(data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_SWIGGY_BILLING_CHART_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_SWIGGY_BILLING_CHART_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const dashboard_zomato_billing_chart = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_ZOMATO_BILLING_CHART_LOADING,
        data: true,
      });
      AuthService.GetZomatoBillingChartData(data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_ZOMATO_BILLING_CHART_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_ZOMATO_BILLING_CHART_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const dashboard_bigbasket_billing_chart = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_BIGBASKET_BILLING_CHART_LOADING,
        data: true,
      });
      AuthService.GetBigBasketBillingChartData(data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_BIGBASKET_BILLING_CHART_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_BIGBASKET_BILLING_CHART_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const dashboard_bbnow_billing_chart = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_BBNOW_BILLING_CHART_LOADING,
        data: true,
      });
      AuthService.GetBBNowBillingChartData(data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_BBNOW_BILLING_CHART_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_BBNOW_BILLING_CHART_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const dashboard_flipkart_billing_chart = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_FLIPKART_BILLING_CHART_LOADING,
        data: true,
      });
      AuthService.GetFlipkartBillingChartData(data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_FLIPKART_BILLING_CHART_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_FLIPKART_BILLING_CHART_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const dashboard_bluedart_billing_chart = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_BLUEDART_BILLING_CHART_LOADING,
        data: true,
      });
      AuthService.GetBlueDartBillingChartData(data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_BLUEDART_BILLING_CHART_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_BLUEDART_BILLING_CHART_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const dashboard_fulltimeparcel_chart = (year, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_FULLTIME_PARCEL_CHART_LOADING,
        data: true,
      });
      AuthService.GetFullTimeRidersParcelMonthlyData(year, data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_FULLTIME_PARCEL_CHART_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_FULLTIME_PARCEL_CHART_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const dashboard_parttimeparcel_chart = (year, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DASHBOARD_PARTTIME_PARCEL_CHART_LOADING,
        data: true,
      });
      AuthService.GetPartTimeRidersParcelMonthlyData(year, data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DASHBOARD_PARTTIME_PARCEL_CHART_SUCCESS,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DASHBOARD_PARTTIME_PARCEL_CHART_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
