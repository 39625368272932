import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import Select from "react-select";
import withReactContent from "sweetalert2-react-content";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../DashBoard/Header";
import Sub_Header from "../../DashBoard/Sub_Header";
import SideBar from "../../DashBoard/SideBar";
import Footer from "../../DashBoard/Footer";
import Loader from "../../common/Loader/Loader";
import "../../../assets/css/users/user-profile.css";
import "../../../assets/plugins/table/datatable/datatables.css";
import "../../../assets/plugins/table/datatable/dt-global_style.css";
import * as action from "../../../action/Driver/driver_action";
import $ from "jquery";
import { renderToString } from "react-dom/server";

const Customer_List = (props) => {
  $.DataTable = require("datatables.net");
  const dispatch = useDispatch();
  const { driver_id } = useParams();
  const MySwal = withReactContent(Swal);
  const { loading } = useSelector(
    (state) => state.DriverAssignMultipleListReduces
  );
  const { Addloading } = useSelector(
    (state) => state.DriverAssignSingleReduces
  );
  const tableRef = useRef();
  const [assignCustomerList, setAssignCustomerList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [customerAddressList, setCustomerAddressList] = useState([]);
  const [customerShiftList, setCustomerShiftList] = useState([]);
  const [assignDriver, setAssignDriver] = useState({
    driverName: "",
    driverId: driver_id,
    customerId: "",
    customerAddressId: "",
    customerShiftId: "",
  });
  const [assignDriverValues, setAssignDriverValues] = useState({
    customerValue: "",
    customerAddressValue: "",
    customerShiftValue: "",
  });
  const assignMultipleCustomerDelete = (assign_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete assign customer!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(action.driver_multiple_assign_delete(assign_id))
          .then((data) => {
            toast.success(data.msg);
            let newCustomerList = [];
            assignCustomerList.map((item) => {
              if (assign_id !== item[4]) {
                newCustomerList.push(item);
              }
            });
            setAssignCustomerList(newCustomerList);
          })
          .catch((error) => {
            toast.error(error);
          });
      }
    });
  };
  const assignDefaultCustomer = (assignId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to set default this customer!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(action.set_driver_default_customer({ assignId: assignId }))
          .then((data) => {
            toast.success(data.msg);
            let newCustomerList = [];
            assignCustomerList.map((item) => {
              if (assignId === item[4]) {
                item[3] = 1;
                newCustomerList.push(item);
              } else {
                item[3] = 0;
                newCustomerList.push(item);
              }
            });
            setAssignCustomerList(newCustomerList);
          })
          .catch((error) => {
            toast.error(error);
          });
      } else {
        dispatch(action.driver_multiple_assign_list(driver_id))
          .then((data) => {
            let newCustomerList = [];
            data.data.map((item) => {
              newCustomerList.push([
                item.customerData.businessName + " | " + item.customerData.name,
                item.addressData.address,
                item.shiftData.shiftName +
                  " | " +
                  item.shiftData.shiftStartTime +
                  " | " +
                  item.shiftData.shiftEndTime,
                item.isDefault ? item.isDefault : 0,
                item._id,
                item,
                item.customerId,
              ]);
            });
            setAssignCustomerList(newCustomerList);
          })
          .catch({});
      }
    });
  };
  useEffect(() => {
    dispatch(action.driver_details(driver_id))
      .then((data) => {
        let driver_details = data.data;
        setAssignDriver({
          driverName: driver_details.name,
          driverId: driver_id,
          customerId: driver_details.customerId
            ? driver_details.customerId
            : "",
          customerAddressId: driver_details.customerAddressId
            ? driver_details.customerAddressId
            : "",
          customerShiftId: driver_details.customerShiftId
            ? driver_details.customerShiftId
            : "",
        });
      })
      .catch({});
  }, []);
  const changeAddressDropDown = (data) => {
    setAssignDriver({ ...assignDriver, customerAddressId: data.value });
    setAssignDriverValues({
      ...assignDriverValues,
      customerAddressValue: data,
    });
  };
  const changeShiftDropDown = (data) => {
    setAssignDriver({ ...assignDriver, customerShiftId: data.value });
    setAssignDriverValues({ ...assignDriverValues, customerShiftValue: data });
  };
  const changeCustomerDropDown = (data) => {
    const customer_id = data.value;
    setAssignDriver({
      driverName: assignDriver.driverName,
      driverId: assignDriver.driverId,
      customerId: customer_id,
      customerAddressId: "",
      customerShiftId: "",
    });
    setAssignDriverValues({
      ...assignDriverValues,
      customerValue: data,
      customerAddressValue: "",
      customerShiftValue: "",
    });
    dispatch(action.customer_address_list(customer_id))
      .then((data) => {
        let newAddressList = [];
        data.data.map((item) => {
          newAddressList.push({
            value: item._id,
            label: item.address,
          });
        });
        setCustomerAddressList(newAddressList);
      })
      .catch({});
    dispatch(action.customer_shift_list(customer_id))
      .then((data) => {
        let newShiftList = [];
        data.data.map((item) => {
          newShiftList.push({
            value: item._id,
            label:
              item.shiftName +
              " | " +
              item.shiftStartTime +
              " | " +
              item.shiftEndTime,
          });
        });
        setCustomerShiftList(newShiftList);
      })
      .catch({});
  };
  useEffect(() => {
    dispatch(action.customer_list())
      .then((data) => {
        let newCustomerList = [];
        data.data.map((item) => {
          newCustomerList.push({
            value: item._id,
            label: item.businessName + " | " + item.name,
          });
        });
        setCustomerList(newCustomerList);
      })
      .catch({});
  }, []);
  const ClearModelData = () => {
    setAssignDriver({
      driverName: "",
      driverId: driver_id,
      customerId: "",
      customerAddressId: "",
      customerShiftId: "",
    });
    setAssignDriverValues({
      customerValue: "",
      customerAddressValue: "",
      customerShiftValue: "",
    });
  };
  const tableName = "customer-assign-list";
  useEffect(() => {
    dispatch(action.driver_multiple_assign_list(driver_id))
      .then((data) => {
        let newCustomerList = [];
        data.data.map((item) => {
          newCustomerList.push([
            item.customerData.businessName + " | " + item.customerData.name,
            item.addressData.address,
            item.shiftData.shiftName +
              " | " +
              item.shiftData.shiftStartTime +
              " | " +
              item.shiftData.shiftEndTime,
            item.isDefault ? item.isDefault : 0,
            item._id,
            item,
            item.customerId,
          ]);
        });
        setAssignCustomerList(newCustomerList);
      })
      .catch({});
  }, []);
  useEffect(() => {
    const table = $(`#${tableName}`).DataTable({
      data: assignCustomerList,
      columns: [
        { title: "Customer" },
        { title: "Address" },
        { title: "Shift" },
        { title: "Default" },
        { title: "Action" },
      ],
      columnDefs: [
        {
          targets: [3],
          className: "center",
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <>
                {rowData[3] === 1 ? (
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      checked={rowData[3] === 1}
                      className="custom-control-input"
                      id={`customSwitches${rowData[4]}`}
                    />
                    <label
                      className="custom-control-label"
                      for={`customSwitches${rowData[4]}`}
                    ></label>
                  </div>
                ) : (
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      checked={false}
                      onChange={() => assignDefaultCustomer(rowData[4])}
                      className="custom-control-input"
                      id={`customSwitches${rowData[4]}`}
                    />
                    <label
                      className="custom-control-label"
                      for={`customSwitches${rowData[4]}`}
                    ></label>
                  </div>
                )}
              </>,
              td
            ),
        },
        {
          targets: [4],
          className: "center",
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <>
                {rowData[3] === 1 ? (
                  <a
                    style={{ cursor: "pointer" }}
                    className="btn btn-danger btn-sm status-act-btn disabled"
                  >
                    {" "}
                    Delete{" "}
                  </a>
                ) : (
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => assignMultipleCustomerDelete(rowData[4])}
                    className="btn btn-danger btn-sm status-act-btn"
                  >
                    {" "}
                    Delete{" "}
                  </a>
                )}
              </>,
              td
            ),
        },
      ],
      destroy: true, // I think some clean up is happening here
      searching: true,
      buttons: [
        {
          extend: "excel",
          className:
            "excelButton view-btn btn text-white float-right mt-2 ml-2",
        },
      ],
      oLanguage: {
        oPaginate: {
          sPrevious:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>',
          sNext:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>',
        },
        //sInfo: "Showing page _PAGE_ of _PAGES_",
        sSearch:
          '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
        sSearchPlaceholder: "Search...",
        sLengthMenu: "Results :  _MENU_",
      },
      fnInitComplete: function (oSettings) {
        $("#customer-assign-list_filter").prepend(
          '<button type="button" id="assignCustomerBtn" class="btn btn-warning btn-sm add-act-btn" data-toggle="modal" data-target="#assignCustomerModalCenter">Assign New Customer</button>'
        );
        const myButton = document.querySelector("#assignCustomerBtn");
        myButton.addEventListener("click", ClearModelData);
      },
    });
    // Extra step to do extra clean-up.
    return function () {
      table.destroy();
    };
  }, [assignCustomerList]);
  const onsubmitHandler = (e) => {
    e.preventDefault();
    let parameter = {
      driverId: assignDriver.driverId,
      customerId: assignDriver.customerId,
      customerAddressId: assignDriver.customerAddressId,
      customerShiftId: assignDriver.customerShiftId,
    };
    dispatch(action.driver_single_assign(parameter))
      .then((data) => {
        toast.success(data.msg);
        let response = data.data;
        setAssignCustomerList([
          ...assignCustomerList,
          [
            response.customerData.businessName +
              " | " +
              response.customerData.name,
            response.addressData.address,
            response.shiftData.shiftName +
              " | " +
              response.shiftData.shiftStartTime +
              " | " +
              response.shiftData.shiftEndTime,
            response.isDefault ? response.isDefault : 0,
            response._id,
            response,
          ],
        ]);
        $("#assignCustomerModalCenter .close").click();
        setAssignDriver({
          driverName: "",
          driverId: driver_id,
          customerId: "",
          customerAddressId: "",
          customerShiftId: "",
        });
        setAssignDriverValues({
          customerValue: "",
          customerAddressValue: "",
          customerShiftValue: "",
        });
        setCustomerShiftList([]);
        setCustomerShiftList([]);
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Sub_Header />
      <div className="main-container" id="container">
        {loading ? <Loader /> : null}
        {Addloading ? <Loader /> : null}
        <div className="overlay"></div>
        <div className="search-overlay"></div>
        <SideBar />
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                <div className="widget-content widget-content-area br-6">
                  <h5 className="" style={{ margin: "10px" }}>
                    Driver Multiple Customer Assign List
                  </h5>
                  <div className="table-responsive mb-4 mt-4">
                    <table
                      id="customer-assign-list"
                      className="table table-hover"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>Customer</th>
                          <th>Address</th>
                          <th>Shift</th>
                          <th>Default</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="assignCustomerModalCenter"
              role="dialog"
              aria-labelledby="assignCustomerModalCenterTitle"
              style={{ display: "none" }}
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <form onSubmit={onsubmitHandler}>
                    <div className="modal-header">
                      <h5
                        className="modal-title"
                        id="assignCustomerModalCenterTitle"
                      >
                        Assign Customer/Company
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <svg
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-x"
                        >
                          <line x1="18" y1="6" x2="6" y2="18"></line>
                          <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div
                        className="customer-list-class"
                        id="customer-list-div-id"
                      >
                        <div className="form-group col-12 required">
                          <label for="inputDriverFullName">Driver: </label>
                          <label id="inputDriverFullName">
                            {assignDriver.driverName !== ""
                              ? assignDriver.driverName
                              : "test driver"}
                          </label>
                        </div>

                        <div className="form-group col-md-12 required">
                          <label for="inputCustomerSelect">
                            Select Customer/Company *
                          </label>
                          <div className="form-group">
                            <Select
                              value={
                                assignDriverValues.customerValue || "Select"
                              }
                              onChange={(value) =>
                                changeCustomerDropDown(value)
                              }
                              options={customerList}
                              required
                            />
                          </div>
                        </div>

                        <div className="form-group col-md-12 required">
                          <label for="inputCustomerAddressSelect">
                            Select Address *
                          </label>
                          <div className="form-group">
                            <Select
                              value={
                                assignDriverValues.customerAddressValue ||
                                "Select"
                              }
                              onChange={changeAddressDropDown}
                              options={customerAddressList}
                              required
                            />
                          </div>
                        </div>

                        <div className="form-group col-md-12 required">
                          <label for="inputCustomerShiftSelect">
                            Select Shift *
                          </label>
                          <div className="form-group">
                            <Select
                              value={
                                assignDriverValues.customerShiftValue ||
                                "Select"
                              }
                              onChange={changeShiftDropDown}
                              options={customerShiftList}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn"
                        data-dismiss="modal"
                      >
                        <i className="flaticon-cancel-12"></i>Close
                      </button>
                      <button
                        type="submit"
                        id="assignCustomerToDriverBtn"
                        className="btn btn-primary view-btn"
                      >
                        Assign
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </>
  );
};
export default Customer_List;
