import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Input } from "rsuite";
import { RxCross2 } from "react-icons/rx";
import Pagination from "../../../../common/Pagination/Pagination";
import $ from "jquery";
import * as billingaction from "../../../../../action/BillingAnalysis/billing_analysis";

const ZomatoTab = ({
  cityList,
  errors,
  setErrors,
  disabled,
  setEditFormEditable,
  editButtonHide,
  submitButtonHide,
}) => {
  const dispatch = useDispatch();

  // zomato add
  const yearMonthZomatoFocus = useRef();
  const cityZomatoFocus = useRef();
  const revenueZomatoFocus = useRef();
  const kmsZomatoFocus = useRef();
  const ordersZomatoFocus = useRef();

  // zomato edit
  const edityearMonthZomatoFocus = useRef();
  const editcityZomatoFocus = useRef();
  const editrevenueZomatoFocus = useRef();
  const editkmsZomatoFocus = useRef();
  const editordersZomatoFocus = useRef();

  const [zomatoData, setZomatoData] = useState({
    currentPage: 1,
    billingData: [],
    formId: "",
  });

  const [addZomatoBillingData, setAddZomatoBillingData] = useState({
    yearMonth: 0,
    city: "",
    revenue: 0,
    kms: 0,
    orders: 0,
  });

  const [editZomatoBillingData, setEditZomatoBillingData] = useState({
    yearMonth: 0,
    city: "",
    revenue: 0,
    kms: 0,
    orders: 0,
  });

  // zomato billing data
  useEffect(() => {
    dispatch(billingaction.get_zomato_billing())
      .then((data) => {
        let zomatobillingData = data.data;
        setZomatoData({ ...zomatoData, billingData: zomatobillingData });
      })
      .catch((err) => {
        toast.error(err);
      });
  }, []);

  // zomato add and update
  const onSubmitHandlerZomato = (e) => {
    e.preventDefault();
    setErrors({});
    let customErrors = {};

    if (!addZomatoBillingData.yearMonth) {
      customErrors = { ...customErrors, yearMonth: "Please Select yearMonth" };
      yearMonthZomatoFocus.current.focus();
    } else if (!addZomatoBillingData.city) {
      customErrors = { ...customErrors, city: "Please Select city" };
      cityZomatoFocus.current.focus();
    } else if (!addZomatoBillingData.revenue) {
      customErrors = { ...customErrors, revenue: "Please Enter revenue" };
      revenueZomatoFocus.current.focus();
    } else if (!addZomatoBillingData.kms) {
      customErrors = { ...customErrors, kms: "Please Enter kms" };
      kmsZomatoFocus.current.focus();
    } else if (!addZomatoBillingData.orders) {
      customErrors = { ...customErrors, orders: "Please Enter orders" };
      ordersZomatoFocus.current.focus();
    }

    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }

    dispatch(billingaction.add_zomato_billing(addZomatoBillingData))
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          $("#addExpenseFormZomato .close").click();
          let zomatobillingData = data.data;
          setZomatoData({ ...zomatoData, billingData: zomatobillingData });
          setEditFormEditable(false);
        } else if (!data.success) {
          toast.error(data.message);
        }
        setErrors({});
      })
      .catch((err) => {});

    setErrors({});

    setAddZomatoBillingData({
      yearMonth: 0,
      city: "",
      revenue: 0,
      kms: 0,
      orders: 0,
    });
  };

  const onUpdateHandlerZomato = (e) => {
    e.preventDefault();
    setErrors({});
    let customErrors = {};

    if (!editZomatoBillingData.yearMonth) {
      customErrors = { ...customErrors, yearMonth: "Please Select yearMonth" };
      edityearMonthZomatoFocus.current.focus();
    } else if (!editZomatoBillingData.city) {
      customErrors = { ...customErrors, city: "Please Select city" };
      editcityZomatoFocus.current.focus();
    } else if (!editZomatoBillingData.revenue) {
      customErrors = { ...customErrors, revenue: "Please Enter revenue" };
      editrevenueZomatoFocus.current.focus();
    } else if (!editZomatoBillingData.kms) {
      customErrors = { ...customErrors, kms: "Please Enter kms" };
      editkmsZomatoFocus.current.focus();
    } else if (!editZomatoBillingData.orders) {
      customErrors = { ...customErrors, orders: "Please Enter orders" };
      editordersZomatoFocus.current.focus();
    }

    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }

    dispatch(
      billingaction.edit_zomato_billing(
        zomatoData.formId ? zomatoData.formId : null,
        editZomatoBillingData
      )
    )
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          $("#editExpenseFormZomato .close").click();
          let zomatobillingData = data.data;
          setZomatoData({ ...zomatoData, billingData: zomatobillingData });
          setEditFormEditable(false);
        } else if (!data.success) {
          toast.error(data.message);
        }
        setErrors({});
      })
      .catch((err) => {});

    setErrors({});
  };

  // zomato edit item
  const handleZomatoEditClick = (item) => {
    setZomatoData({ ...zomatoData, formId: item._id });

    const formattedMonth =
      item.month < 10 && item.month > 0 ? `0${item.month}` : item.month;

    let yearMonth = `${item.year}-${formattedMonth}`;

    setEditZomatoBillingData({
      yearMonth: yearMonth,
      city: item.city,
      revenue: item.revenue,
      kms: item.kms,
      orders: item.orders,
    });
  };

  const currentZomatoTableData = useMemo(() => {
    const firstPageIndexZomato = (zomatoData.currentPage - 1) * 10;
    const lastPageIndexZomato = firstPageIndexZomato + 10;
    return zomatoData.billingData?.slice(
      firstPageIndexZomato,
      lastPageIndexZomato
    );
  }, [zomatoData.currentPage, zomatoData.billingData]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="" style={{ margin: "10px" }}>
          Zomato Billing Analysis
        </h5>
        <a
          href="/"
          className="btn btn-warning btn-sm add-act-btn"
          data-toggle="modal"
          data-target="#addExpenseFormZomato"
        >
          Add
        </a>
      </div>
      <br />
      <div className="table-responsive mb-4 mt-4">
        {currentZomatoTableData.length > 0 ? (
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Month</th>
                <th>Year</th>
                <th>Revenue</th>
                <th>Orders</th>
                <th>KMs</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentZomatoTableData.map((item) => {
                return (
                  <tr key={item._id}>
                    <td>{item.month}</td>
                    <td>{item.year}</td>
                    <td>{item.revenue}</td>
                    <td>{item.orders}</td>
                    <td>{item.kms}</td>
                    <td>
                      <a
                        href="/"
                        onClick={() => handleZomatoEditClick(item)}
                        className="btn btn-info btn-sm edit-btn edit-act-btn"
                        data-toggle="modal"
                        data-target="#editExpenseFormZomato"
                      >
                        Edit
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className="text-center">No data Available</p>
        )}
        {/* pagination */}
        <Pagination
          className="pagination-bar"
          currentPage={zomatoData.currentPage}
          totalCount={zomatoData.billingData.length}
          pageSize={10}
          onPageChange={(page) =>
            setZomatoData({ ...zomatoData, currentPage: page })
          }
        />
      </div>
      {/* zomato - add model */}
      <div
        className="modal fade"
        id="addExpenseFormZomato"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addExpenseFormTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <form onSubmit={onSubmitHandlerZomato}>
              <div className="modal-header">
                <h5 className="modal-title">Add Zomato Billing</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <RxCross2 />
                </button>
              </div>

              <div className="modal-body">
                <div
                  className="customer-address-class"
                  id="customer-address-div-id"
                >
                  <div className="row" id="defaultaddressdiv0">
                    <div className="col-12">
                      <div id="append-div-id">
                        <div class="form-group row">
                          <div class="form-group col-6 required">
                            <label>Select Year and Month *</label>
                            <Input
                              type="month"
                              ref={yearMonthZomatoFocus}
                              name="yearMonth"
                              value={addZomatoBillingData?.yearMonth}
                              onChange={(value) =>
                                setAddZomatoBillingData({
                                  ...addZomatoBillingData,
                                  yearMonth: value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.yearMonth}
                            </span>
                          </div>
                          <div class="form-group col-md-6 required">
                            <label>Select City *</label>
                            <select
                              id="city"
                              ref={cityZomatoFocus}
                              className="form-control selectpicker"
                              value={addZomatoBillingData?.city}
                              onChange={(e) => {
                                setAddZomatoBillingData({
                                  ...addZomatoBillingData,
                                  city: e.target.value,
                                });
                              }}
                            >
                              <option value="">Select City</option>
                              {cityList.map((item) => {
                                return (
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            <span style={{ color: "red" }}>{errors?.city}</span>
                          </div>
                        </div>

                        <div class="form-group row">
                          <div class="form-group col-md-4 required">
                            <label>Revenue</label>
                            <input
                              type="number"
                              min="0"
                              name="revenue"
                              className="form-control"
                              ref={revenueZomatoFocus}
                              placeholder="Enter Revenue"
                              value={addZomatoBillingData?.revenue}
                              onChange={(e) =>
                                setAddZomatoBillingData({
                                  ...addZomatoBillingData,
                                  revenue: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.revenue}
                            </span>
                          </div>

                          <div class="form-group col-md-4 required">
                            <label>Kms</label>
                            <input
                              type="number"
                              min="0"
                              name="kms"
                              className="form-control"
                              ref={kmsZomatoFocus}
                              placeholder="Kms"
                              value={addZomatoBillingData?.kms}
                              onChange={(e) =>
                                setAddZomatoBillingData({
                                  ...addZomatoBillingData,
                                  kms: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>{errors?.kms}</span>
                          </div>

                          <div class="form-group col-md-4 required">
                            <label>Orders</label>
                            <input
                              type="number"
                              min="0"
                              name="orders"
                              className="form-control"
                              ref={ordersZomatoFocus}
                              placeholder="No of Orders"
                              value={addZomatoBillingData?.orders}
                              onChange={(e) =>
                                setAddZomatoBillingData({
                                  ...addZomatoBillingData,
                                  orders: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.orders}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="reset"
                  data-dismiss="modal"
                  className="btn btn-danger "
                  onClick={() => setEditFormEditable(false)}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success save-btn">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* zomato - update model */}
      <div
        className="modal fade"
        id="editExpenseFormZomato"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editExpenseFormTitle"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <form onSubmit={onUpdateHandlerZomato}>
              <div className="modal-header">
                <h5 className="modal-title" id="editExpenseFormTitle">
                  Zomato Billing
                </h5>
                <button
                  type="button"
                  className="btn btn-primary view-btn"
                  hidden={editButtonHide}
                  onClick={() => setEditFormEditable(true)}
                >
                  Edit
                </button>
              </div>

              <div className="modal-body">
                <div
                  className="customer-address-class"
                  id="customer-address-div-id"
                >
                  <div className="row" id="defaultaddressdiv0">
                    <div className="col-12">
                      <div id="append-div-id">
                        <div class="form-group row">
                          <div class="form-group col-4 required">
                            <label>Select Year and Month *</label>
                            <Input
                              disabled={disabled}
                              type="month"
                              ref={edityearMonthZomatoFocus}
                              name="yearMonth"
                              value={editZomatoBillingData?.yearMonth}
                              onChange={(value) =>
                                setEditZomatoBillingData({
                                  ...editZomatoBillingData,
                                  yearMonth: value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.yearMonth}
                            </span>
                          </div>
                          <div class="form-group col-md-4 required">
                            <label>Select City *</label>
                            <select
                              disabled={disabled}
                              id="city"
                              ref={editcityZomatoFocus}
                              className="form-control selectpicker"
                              value={editZomatoBillingData?.city}
                              onChange={(e) => {
                                setEditZomatoBillingData({
                                  ...editZomatoBillingData,
                                  city: e.target.value,
                                });
                              }}
                            >
                              <option value="">Select City</option>
                              {cityList.map((item) => {
                                return (
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            <span style={{ color: "red" }}>{errors?.city}</span>
                          </div>
                        </div>

                        <div class="form-group row">
                          <div class="form-group col-md-4 required">
                            <label>Revenue</label>
                            <input
                              disabled={disabled}
                              type="number"
                              min="0"
                              name="revenue"
                              className="form-control"
                              ref={editrevenueZomatoFocus}
                              placeholder="Enter Revenue"
                              value={editZomatoBillingData?.revenue}
                              onChange={(e) =>
                                setEditZomatoBillingData({
                                  ...editZomatoBillingData,
                                  revenue: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.revenue}
                            </span>
                          </div>

                          <div class="form-group col-md-4 required">
                            <label>Kms</label>
                            <input
                              disabled={disabled}
                              type="number"
                              min="0"
                              name="kms"
                              className="form-control"
                              ref={editkmsZomatoFocus}
                              placeholder="No of Orders"
                              value={editZomatoBillingData?.kms}
                              onChange={(e) =>
                                setEditZomatoBillingData({
                                  ...editZomatoBillingData,
                                  kms: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>{errors?.kms}</span>
                          </div>
                          <div class="form-group col-md-4 required">
                            <label>Orders</label>
                            <input
                              disabled={disabled}
                              type="number"
                              min="0"
                              name="orders"
                              className="form-control"
                              ref={editordersZomatoFocus}
                              placeholder="No of Orders"
                              value={editZomatoBillingData?.orders}
                              onChange={(e) =>
                                setEditZomatoBillingData({
                                  ...editZomatoBillingData,
                                  orders: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.orders}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary view-btn"
                  hidden={submitButtonHide}
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ZomatoTab;
