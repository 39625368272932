import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../DashBoard/Header";
import Sub_Header from "../../DashBoard/Sub_Header";
import SideBar from "../../DashBoard/SideBar";
import Footer from "../../DashBoard/Footer";
import Loader from "../../common/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import Select from "react-select";
import * as action from "../../../action/Vehicle/vehicle_action";
import * as common from "../../../action/common/common_action";
import * as customer_action from "../../../action/Customer/customer_action";
import { baseURL } from "../../../service/baseService";
const ViewVehicle = (props) => {
  const dispatch = useDispatch();
  const { vehicle_id } = useParams();
  const nameFocus = useRef();
  const numberFocus = useRef();
  const typeFocus = useRef();
  const insuranceNoFocus = useRef();
  const batteryNoFocus = useRef();
  const secondBatteryNoFocus = useRef();
  const chasisNoFocus = useRef();
  const cityFocus = useRef();
  const customerFocus = useRef();
  const bikeTypeIdFocus = useRef();
  const rcBookFileFocus = useRef();
  const insuranceFileFocus = useRef();
  const srNumberFocus = useRef();
  const telematicsUnitFocus = useRef();
  const leaseCompanyIdFocus = useRef();
  const leaseCompanyBatchIdFocus = useRef();
  const insuranceExpiryDateFocus = useRef();
  const vehicleHubIdFocus = useRef();
  const deactivateReasonFocus = useRef();
  const deactivateReasonInputFocus = useRef();
  const rcBookNoFocus = useRef();
  const DocumentsFocus = useRef();
  const [errors, setErrors] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [editButtonHide, setEditButtonHide] = useState(false);
  const [addDocButtonHide, setAddDocButtonHide] = useState(true);
  const [cancelButtonHide, setCancelButtonHide] = useState(true);
  const [submitButtonHide, setSubmitButtonHide] = useState(true);
  const { loading } = useSelector((state) => state.VehicleEditReducer);
  const [modalImgURL, setModalImgURL] = useState("");
  const [otherDocumentRow, setOtherDocumentRow] = useState([]);
  const [telematicsUnitList, setTelematicsUnitList] = useState([]);
  const [leaseCompanyList, setLeaseCompanyList] = useState([]);
  const [leaseCompanyBatchList, setLeaseCompanyBatchList] = useState([]);
  const [hubList, setHubList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [values, setValues] = useState({
    cityValue: "",
    customerValue: "",
  });
  const [bikeTypeList, setBikeType] = useState([]);
  const [bikeTypeDetails, setBikeDetails] = useState({
    registered: "",
    isActive: "",
    _id: "",
    bikeName: "",
    noOfBattery: "",
  });
  const [input, setInput] = useState({
    id: vehicle_id,
    number: "",
    name: "",
    type: "",
    details: "",
    ownerName: "",
    rcBookFile: "",
    insuranceFile: "",
    permitFile: "",
    insuranceExpiryDate: "",
    permitExpiryDate: "",
    qrCodeSrc: "",
    insuranceNo: "",
    batteryNo: "",
    chasisNo: "",
    rcBookNo: "",
    tracknowurl: "",
    city: "",
    registered: 1,
    owned: 1,
    isActive: 1,
    leaseCompanyId: "",
    leaseCompanyBatchId: "",
    telematicsUnit: "",
    sr_number: "",
    vehicleHubId: "",
    bikeTypeId: "",
    secondBatteryNo: "",
    deactivateReason: "",
    deactivateReasonInput: "",
    customerId: "",
  });
  const changeDateFormate = (date) => {
    if (date) {
      let new_date = String(date);
      let ndate = new_date.split("T");
      return ndate[0];
    }
  };
  useEffect(() => {
    dispatch(action.vehicle_bike_type_select())
      .then((data) => {
        setBikeType(data.data);
      })
      .catch({});
  }, []);

  const changeBikeType = (e) => {
    let bikeTypeId = e.target.value;
    if (bikeTypeId) {
      dispatch(action.bike_type_details(bikeTypeId))
        .then((data) => {
          let bike_type_details = data.data;
          setBikeDetails({
            registered: bike_type_details.registered,
            isActive: bike_type_details.isActive,
            _id: bike_type_details._id,
            bikeName: bike_type_details.bikeName,
            noOfBattery: bike_type_details.noOfBattery,
          });
        })
        .catch({});
      setInput({ ...input, bikeTypeId: bikeTypeId, number: "" });
    } else {
      setBikeDetails({
        registered: "",
        isActive: "",
        _id: "",
        bikeName: "",
        noOfBattery: "",
      });
      setInput({ ...input, bikeTypeId: bikeTypeId, number: "" });
    }
  };

  const handleToChange = (e) => {
    let leaseCompanyId = e.target.value;
    let leaseCompanyname =
      e.target[e.target.selectedIndex].getAttribute("name");
    setInput({
      ...input,
      leaseCompanyId: leaseCompanyId,
      ownerName: leaseCompanyname,
      leaseCompanyBatchId: "",
    });
    dispatch(action.vehicle_lease_company_details(leaseCompanyId))
      .then((data) => {
        let vehicle_lease_company_details = data.data.batch;
        let newBetchList = [];
        vehicle_lease_company_details.map((item) => {
          newBetchList.push({
            value: item._id,
            label: item.batchNumber,
          });
        });
        setLeaseCompanyBatchList(newBetchList);
      })
      .catch({});
  };
  const changeCityDropDown = (data) => {
    setValues({ ...values, cityValue: data });
    setInput({ ...input, city: data.value });
  };
  const changeCustomerDropDown = (data) => {
    setValues({ ...values, customerValue: data });
    setInput({ ...input, customerId: data.value });
  };
  useEffect(() => {
    dispatch(action.vehicle_telematics_unit_list_select())
      .then((data) => {
        setTelematicsUnitList(data.data);
      })
      .catch({});
  }, []);
  useEffect(() => {
    dispatch(action.vehicle_lease_company_list_select())
      .then((data) => {
        setLeaseCompanyList(data.data);
      })
      .catch({});
  }, []);
  useEffect(() => {
    dispatch(action.vehicle_hub_list_select())
      .then((data) => {
        setHubList(data.data);
      })
      .catch({});
  }, []);
  useEffect(() => {
    dispatch(action.vehicle_details(vehicle_id))
      .then((data) => {
        let vehicle_details = data.data;
        setInput({
          id: vehicle_id,
          number: vehicle_details.number,
          name: vehicle_details.name,
          type: vehicle_details.type,
          details: vehicle_details.details,
          ownerName: vehicle_details.ownerName,
          rcBookFile: vehicle_details.rcBookFile,
          insuranceFile: vehicle_details.insuranceFile,
          permitFile: vehicle_details.permitFile,
          insuranceExpiryDate: vehicle_details.insuranceExpiryDate,
          permitExpiryDate: vehicle_details.permitExpiryDate,
          qrCodeSrc: vehicle_details.qrCodeSrc,
          insuranceNo: vehicle_details.insuranceNo,
          batteryNo: vehicle_details.batteryNo,
          chasisNo: vehicle_details.chasisNo,
          tracknowurl: vehicle_details.tracknowurl,
          rcBookNo: vehicle_details.rcBookNo,
          city: vehicle_details.city,
          registered: vehicle_details.registered,
          owned: vehicle_details.owned,
          isActive: vehicle_details.isActive,
          leaseCompanyId: vehicle_details.leaseCompanyId,
          leaseCompanyBatchId: vehicle_details.leaseCompanyBatchId,
          telematicsUnit: vehicle_details.telematicsUnit,
          sr_number: vehicle_details.sr_number,
          vehicleHubId: vehicle_details.vehicleHubId,
          bikeTypeId: vehicle_details.bikeTypeId
            ? vehicle_details.bikeTypeId
            : "",
          secondBatteryNo: vehicle_details.secondBatteryNo
            ? vehicle_details.secondBatteryNo
            : "",
          deactivateReason: vehicle_details.deactivateReason,
          deactivateReasonInput: vehicle_details.deactivateReasonInput,
          customerId: vehicle_details.customerId,
        });
        let customer_data = {};
        if (data.customer) {
          if (vehicle_details.customerId !== "") {
            customer_data = {
              value: data.customer._id ? data.customer._id : "",
              label: data.customer.name
                ? data.customer.businessName + " | " + data.customer.name
                : "",
            };
          } else {
            customer_data = { value: "", label: "" };
          }
        } else {
          customer_data = { value: "", label: "" };
        }
        setValues({
          ...values,
          cityValue: {
            value: data.city.cityId ? data.city.cityId : "",
            label: data.city.cityName ? data.city.cityName : "",
          },
          customerValue: customer_data,
        });
        let newDocList = [];
        let docCount = 1;
        data.data.otherDocuments.map((item) => {
          newDocList.push({
            id: docCount,
            docName: item.docName,
            docUrl: item.docUrl,
          });
          docCount++;
        });
        setOtherDocumentRow(newDocList);
        if (vehicle_details.bikeTypeId) {
          dispatch(action.bike_type_details(vehicle_details.bikeTypeId))
            .then((data) => {
              let bike_type_details = data.data;
              setBikeDetails({
                registered: bike_type_details.registered,
                isActive: bike_type_details.isActive,
                _id: bike_type_details._id,
                bikeName: bike_type_details.bikeName,
                noOfBattery: bike_type_details.noOfBattery,
              });
            })
            .catch({});
        }
        if (vehicle_details.leaseCompanyId) {
          dispatch(
            action.vehicle_lease_company_details(vehicle_details.leaseCompanyId)
          )
            .then((data) => {
              let vehicle_lease_company_details = data.data.batch;
              let newBetchList = [];
              vehicle_lease_company_details.map((item) => {
                newBetchList.push({
                  value: item._id,
                  label: item.batchNumber,
                });
              });
              setLeaseCompanyBatchList(newBetchList);
            })
            .catch({});
        }
      })
      .catch({});
  }, []);
  useEffect(() => {
    // city list
    dispatch(common.common_city_list())
      .then((data) => {
        let newCustomerList = [];
        data.data.map((item) => {
          newCustomerList.push({ value: item._id, label: item.cityName });
        });
        setCityList(newCustomerList);
      })
      .catch({});
    dispatch(customer_action.customer_list())
      .then((data) => {
        let newCustomerList = [];
        data.data.map((item) => {
          newCustomerList.push({
            value: item._id,
            label: item.businessName + " | " + item.name,
          });
        });
        setCustomerList(newCustomerList);
      })
      .catch({});
  }, []);
  const setEditFormEditable = (type) => {
    if (type) {
      setDisabled(false);
      setAddDocButtonHide(false);
      setCancelButtonHide(false);
      setSubmitButtonHide(false);
      setEditButtonHide(true);
    } else {
      setDisabled(true);
      setAddDocButtonHide(true);
      setCancelButtonHide(true);
      setSubmitButtonHide(true);
      setEditButtonHide(false);
    }
  };
  const uploadRCBookFile = (e) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file, file.name);
    dispatch(action.vehicle_file_upload(formData))
      .then((data) => {
        setInput({ ...input, rcBookFile: data.data.url });
      })
      .catch({});
  };
  const uploadInsuranceFile = (e) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file, file.name);
    dispatch(action.vehicle_file_upload(formData))
      .then((data) => {
        setInput({ ...input, insuranceFile: data.data.url });
      })
      .catch({});
  };
  const uploadPermitFile = (e) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file, file.name);
    dispatch(action.vehicle_file_upload(formData))
      .then((data) => {
        setInput({ ...input, permitFile: data.data.url });
      })
      .catch({});
  };
  const uploadOtherDocFile = (e, otherDocId) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file, file.name);
    dispatch(action.vehicle_file_upload(formData))
      .then((data) => {
        let oldotherDocument = otherDocumentRow.filter(
          (item) => item.id === otherDocId
        )?.[0];
        oldotherDocument = { ...oldotherDocument, docUrl: data.data.url };
        let newOtherDocument = [];
        otherDocumentRow.map((item) => {
          if (item.id === otherDocId) {
            newOtherDocument.push(oldotherDocument);
          } else newOtherDocument.push(item);
        });
        setOtherDocumentRow(newOtherDocument);
      })
      .catch({});
  };
  const addExtraDocumentsInForm = () => {
    if (otherDocumentRow.length > 0) {
      setOtherDocumentRow([
        ...otherDocumentRow,
        { id: otherDocumentRow.length + 1, docName: "", docUrl: "" },
      ]);
    } else {
      setOtherDocumentRow([{ id: 1, docName: "", docUrl: "" }]);
    }
  };
  const deleteExtraDocumentsInForm = (otherDocId, docUrl) => {
    if (docUrl != "") {
      const fparr = docUrl.split("/");
      const imageName = fparr[fparr.length - 1];
      const sendData = { name: imageName };

      dispatch(action.delete_vehicle_file(sendData))
        .then((data) => {})
        .catch({});
    }
    setOtherDocumentRow(
      otherDocumentRow.filter((item) => item.id !== otherDocId)
    );
  };
  const addExtraDocumentsValue = (e, otherDocId) => {
    let oldotherDocument = otherDocumentRow.filter(
      (item) => item.id === otherDocId
    )?.[0];
    oldotherDocument = { ...oldotherDocument, [e.target.name]: e.target.value };
    let newOtherDocument = [];
    otherDocumentRow.map((item) => {
      if (item.id === otherDocId) {
        newOtherDocument.push(oldotherDocument);
      } else newOtherDocument.push(item);
    });
    setOtherDocumentRow(newOtherDocument);
  };
  const changeVehicleNumber = (e) => {
    let vehicle_number = e.target.value;
    let result = vehicle_number.toUpperCase();
    setInput({ ...input, number: result });
  };
  const customFilter = (option, inputValue) => {
    const input = inputValue.toLowerCase();
    const filterText = new RegExp("^" + input + ".*");
    const label = option.data.label.toLowerCase();
    return filterText.test(label) && option.label.toLowerCase().includes(input);
  };
  const createPDFfromHTML = (id, filename, wantPdfData) => {
    var HTML_Width = id.offsetWidth;
    var HTML_Height = id.offsetHeight;
    var top_left_margin = 15;
    var PDF_Width = HTML_Width + top_left_margin * 2;
    var PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;

    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    return html2canvas(id).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      // const pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
      const pdf = new jsPDF({
        format: "a6",
        unit: "px",
      });
      pdf.addImage(
        imgData,
        "JPG",
        top_left_margin,
        top_left_margin,
        canvas_image_width,
        canvas_image_height
      );
      // for (var i = 1; i <= totalPDFPages; i++) {
      //     pdf.addPage(PDF_Width, PDF_Height);
      //     pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
      // }
      if (wantPdfData) return pdf.output("blob");
      else pdf.save(`${filename}.pdf`);
    });
  };
  const downloadCustomerInvoice = async () => {
    const input = document.getElementById("qrCodeDiv");
    const filename = `download`;
    const pdfData = await createPDFfromHTML(input, filename, false);
  };
  const onsubmitHandler = (e) => {
    e.preventDefault();
    setErrors({});
    let customErrors = {};
    let documentErrors = {};
    otherDocumentRow.map((item) => {
      let errors = {};
      if (item.docName === "") {
        errors = { ...errors, docName: "Please enter document Name" };
      }
      if (item.docUrl === "") {
        errors = { ...errors, docUrl: "Please enter document URL" };
      }
      if (Object.keys(errors).length > 0) {
        documentErrors[item.id] = errors;
      }
    });
    if (input.bikeTypeId === "") {
      customErrors = { ...customErrors, bikeTypeId: "Please select bike type" };
      bikeTypeIdFocus.current.focus();
    }
    if (
      !/^[A-Z]{2}\d{2}[A-Z]{2}\d{4}$/.test(input.number) &&
      bikeTypeDetails.registered === 1
    ) {
      customErrors = { ...customErrors, number: "Please enter vehicle number" };
      numberFocus.current.focus();
    }
    if (input.name === "" && bikeTypeDetails.registered === 2) {
      customErrors = { ...customErrors, name: "Please enter vehicle number" };
      nameFocus.current.focus();
    }
    if (input.sr_number === "") {
      customErrors = { ...customErrors, sr_number: "Please enter sr number" };
      srNumberFocus.current.focus();
    }
    if (input.type === "") {
      customErrors = { ...customErrors, type: "Please select vehicle type" };
      typeFocus.current.focus();
    }
    if (
      !/[0-9a-zA-Z]{10,}/.test(input.insuranceNo) &&
      input.insuranceNo == ""
    ) {
      customErrors = {
        ...customErrors,
        insuranceNo: "Please enter at least 10 character",
      };
      insuranceNoFocus.current.focus();
    }
    if (input.vehicleHubId === "") {
      customErrors = {
        ...customErrors,
        vehicleHubId: "Please select vehicle hub",
      };
      vehicleHubIdFocus.current.focus();
    }
    if (input.leaseCompanyId === "" && input.owned === 2) {
      customErrors = {
        ...customErrors,
        leaseCompanyId: "Please select lease company id",
      };
      leaseCompanyIdFocus.current.focus();
    }
    if (!/^[0-9a-zA-Z-/]+$/.test(input.batteryNo)) {
      customErrors = {
        ...customErrors,
        batteryNo: "Please enter battery number",
      };
      batteryNoFocus.current.focus();
    }
    if (bikeTypeDetails.noOfBattery === 2) {
      if (!/^[0-9a-zA-Z-/]+$/.test(input.secondBatteryNo)) {
        customErrors = {
          ...customErrors,
          secondBatteryNo: "Please enter second battery number",
        };
        secondBatteryNoFocus.current.focus();
      }
    }
    if (
      !/^[0-9a-zA-Z/]+$/.test(input.rcBookNo) &&
      bikeTypeDetails.registered === 1
    ) {
      customErrors = {
        ...customErrors,
        rcBookNo: "Please enter rc book number",
      };
      rcBookNoFocus.current.focus();
    }
    if (
      input.leaseCompanyBatchId === "" &&
      input.owned === 2 &&
      input.leaseCompanyId !== ""
    ) {
      customErrors = {
        ...customErrors,
        leaseCompanyBatchId: "Please select lease company batch id",
      };
      leaseCompanyBatchIdFocus.current.focus();
    }
    if (input.chasisNo === "") {
      customErrors = {
        ...customErrors,
        chasisNo: "Please enter chasis number",
      };
      chasisNoFocus.current.focus();
    }
    if (input.city === "") {
      customErrors = { ...customErrors, city: "Please select city" };
      cityFocus.current.focus();
    }
    if (input.telematicsUnit === "") {
      customErrors = {
        ...customErrors,
        telematicsUnit: "Please enter telematic unit",
      };
      telematicsUnitFocus.current.focus();
    }
    if (input.insuranceFile === "") {
      customErrors = {
        ...customErrors,
        insuranceFile: "Please select insurance file",
      };
      insuranceFileFocus.current.focus();
    }
    if (input.insuranceExpiryDate === "") {
      customErrors = {
        ...customErrors,
        insuranceExpiryDate: "Please select insurance expiry date",
      };
      insuranceExpiryDateFocus.current.focus();
    }
    if (input.rcBookFile === "" && bikeTypeDetails.registered === 1) {
      customErrors = {
        ...customErrors,
        rcBookFile: "Please select rc book file",
      };
      rcBookFileFocus.current.focus();
    }

    if (Object.keys(documentErrors).length > 0) {
      customErrors = { ...customErrors, document: documentErrors };
      DocumentsFocus.current.focus();
    }
    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }
    let vehicle_number = new Date().getTime();
    vehicle_number = vehicle_number.toString();
    let documents = [];
    otherDocumentRow.map((item) => {
      documents.push({
        docName: item.docName,
        docUrl: item.docUrl,
      });
    });
    let parameter = {};
    if (input.owned == 1) {
      parameter = {
        id: vehicle_id,
        number: input.number ? input.number : vehicle_number,
        name: input.name,
        type: input.type,
        details: input.details,
        ownerName: input.ownerName,
        rcBookFile: input.rcBookFile,
        insuranceFile: input.insuranceFile,
        // permitFile:input.permitFile,
        insuranceExpiryDate: input.insuranceExpiryDate,
        // permitExpiryDate:input.permitExpiryDate,
        insuranceNo: input.insuranceNo,
        batteryNo: input.batteryNo,
        chasisNo: input.chasisNo,
        tracknowurl: input.tracknowurl,
        rcBookNo: input.rcBookNo,
        city: input.city,
        registered: bikeTypeDetails.registered ? bikeTypeDetails.registered : 0,
        owned: input.owned,
        isActive: input.isActive,
        telematicsUnit: input.telematicsUnit,
        sr_number: input.sr_number,
        deactivateReason: input.deactivateReason,
        deactivateReasonInput: input.deactivateReasonInput,
        customerId: input.customerId,
      };
    } else {
      parameter = {
        id: vehicle_id,
        number: input.number ? input.number : vehicle_number,
        name: input.name,
        type: input.type,
        details: input.details,
        ownerName: input.ownerName,
        rcBookFile: input.rcBookFile,
        insuranceFile: input.insuranceFile,
        // permitFile:input.permitFile,
        insuranceExpiryDate: input.insuranceExpiryDate,
        // permitExpiryDate:input.permitExpiryDate,
        insuranceNo: input.insuranceNo,
        batteryNo: input.batteryNo,
        chasisNo: input.chasisNo,
        tracknowurl: input.tracknowurl,
        rcBookNo: input.rcBookNo,
        city: input.city,
        registered: bikeTypeDetails.registered ? bikeTypeDetails.registered : 0,
        owned: input.owned,
        isActive: input.isActive,
        leaseCompanyId: input.leaseCompanyId,
        telematicsUnit: input.telematicsUnit,
        sr_number: input.sr_number,
        deactivateReason: input.deactivateReason,
        deactivateReasonInput: input.deactivateReasonInput,
        customerId: input.customerId,
      };
    }
    parameter.bikeTypeId = input.bikeTypeId;
    if (input.secondBatteryNo !== "") {
      parameter.secondBatteryNo = input.secondBatteryNo;
    }
    if (input.vehicleHubId !== "") {
      parameter.vehicleHubId = input.vehicleHubId;
    }
    if (documents.length > 0) {
      parameter.otherDocuments = documents;
    }
    dispatch(action.edit_vehicle(parameter))
      .then((data) => {
        toast.success(data.msg);
        setDisabled(true);
        setAddDocButtonHide(true);
        setCancelButtonHide(true);
        setSubmitButtonHide(true);
        setEditButtonHide(false);
        setInput({ ...input, qrCodeSrc: data.data.qrCodeSrc });
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Sub_Header />
      <div className="main-container" id="container">
        {loading ? <Loader /> : null}
        <div className="overlay"></div>
        <div className="search-overlay"></div>

        <SideBar></SideBar>
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                <div className="widget-content widget-content-area br-6">
                  <div className="input-group edit-btn-group">
                    <h5 className="">Vehicle</h5>
                    <span
                      id="editidspan"
                      className="btn btn-warning btn-sm float-right ms-3 mb-1"
                      hidden={editButtonHide}
                      style={{ cursor: "pointer" }}
                      onClick={() => setEditFormEditable(true)}
                    >
                      Edit
                    </span>
                  </div>
                  <br />
                  <form onSubmit={onsubmitHandler}>
                    <div id="append-div-id">
                      <div className="row">
                        <div className="col-md-2">
                          <div className="form-group row">
                            <div className="form-group col-md-12 required">
                              <label for="inputBikeType">
                                Select Bike Type *
                              </label>
                              <select
                                disabled={disabled}
                                ref={bikeTypeIdFocus}
                                id="inputVehicleHub"
                                name="bikeTypeId"
                                className="form-control selectpicker"
                                onChange={(e) => changeBikeType(e)}
                                value={input.bikeTypeId}
                              >
                                <option value="">Select Bike Type</option>
                                {bikeTypeList.map((bikeType) => (
                                  <>
                                    <option value={bikeType._id}>
                                      {bikeType.bikeName}
                                    </option>
                                  </>
                                ))}
                              </select>
                              <span style={{ color: "red" }}>
                                {errors?.bikeTypeId}
                              </span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="form-group col-md-12 required">
                              <label for="inputVehicleType">
                                Select Vehicle Type *
                              </label>
                              <select
                                disabled={disabled}
                                ref={typeFocus}
                                id="inputVehicleType"
                                name="type"
                                className="form-control selectpicker"
                                onChange={(e) =>
                                  setInput({ ...input, type: e.target.value })
                                }
                                value={input.type}
                              >
                                <option value="">Select Vehicle Type</option>
                                <option value="2">2 Wheeler</option>
                                <option value="3">3 Wheeler</option>
                                <option value="4">4 Wheeler</option>
                              </select>
                              <span style={{ color: "red" }}>
                                {errors?.type}
                              </span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="form-group col-md-12 required">
                              <label for="inputVehicleBatteryNumber">
                                Vehicle Battery No. 1*
                              </label>
                              <input
                                disabled={disabled}
                                ref={batteryNoFocus}
                                className="form-control"
                                id="inputVehicleBatteryNumber"
                                name="batteryNo"
                                placeholder="Enter Battery No."
                                onChange={(e) =>
                                  setInput({
                                    ...input,
                                    batteryNo: e.target.value,
                                  })
                                }
                                value={input.batteryNo}
                              />
                              <span style={{ color: "red" }}>
                                {errors?.batteryNo}
                              </span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="form-group col-md-12 required">
                              <label for="inputVehicleChasisNumber">
                                Vehicle Chasis No.*
                              </label>
                              <input
                                disabled={disabled}
                                ref={chasisNoFocus}
                                className="form-control"
                                id="inputVehicleChasisNumber"
                                name="chasisNo"
                                placeholder="Enter Chasis No."
                                onChange={(e) =>
                                  setInput({
                                    ...input,
                                    chasisNo: e.target.value,
                                  })
                                }
                                value={input.chasisNo}
                              />
                              <span style={{ color: "red" }}>
                                {errors?.chasisNo}
                              </span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="form-group col-md-12 required">
                              <label for="tracknowurl">Track Now URL </label>
                              <input
                                disabled={disabled}
                                className="form-control"
                                type="url"
                                id="tracknowurl"
                                name="tracknowurl"
                                placeholder="Enter Track Now URL"
                                onChange={(e) =>
                                  setInput({
                                    ...input,
                                    tracknowurl: e.target.value,
                                  })
                                }
                                value={input.tracknowurl}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2">
                          {bikeTypeDetails.registered === 1 ? (
                            <div className="form-group row">
                              <div className="form-group col-md-12 required">
                                <label for="inputVehicleNumber">
                                  Vehicle Number *
                                </label>
                                <input
                                  disabled={disabled}
                                  ref={numberFocus}
                                  className="form-control"
                                  id="inputVehicleNumber"
                                  name="number"
                                  placeholder="GJ05EF1234"
                                  onChange={(e) => changeVehicleNumber(e)}
                                  value={input.number}
                                />
                                <span style={{ color: "red" }}>
                                  {errors?.number}
                                </span>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {bikeTypeDetails.registered === 0 ? (
                            <div className="form-group row">
                              <div className="form-group col-md-12 required">
                                <label for="inputVehicleName">
                                  Vehicle Name *
                                </label>
                                <input
                                  disabled={disabled}
                                  ref={nameFocus}
                                  className="form-control"
                                  id="inputVehiclename"
                                  name="name"
                                  placeholder="Enter Vehicle Name"
                                  onChange={(e) =>
                                    setInput({ ...input, name: e.target.value })
                                  }
                                  value={input.name}
                                />
                                <span style={{ color: "red" }}>
                                  {errors?.name}
                                </span>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="form-group row">
                            <div className="form-group col-md-12 required">
                              <label for="inputInsuranceNo">
                                Insurance Number *
                              </label>
                              <input
                                disabled={disabled}
                                ref={insuranceNoFocus}
                                className="form-control"
                                id="inputInsuranceNo"
                                name="text"
                                placeholder="Enter Vehicle Insurance Number"
                                onChange={(e) =>
                                  setInput({
                                    ...input,
                                    insuranceNo: e.target.value,
                                  })
                                }
                                value={input.insuranceNo}
                              />
                              <span style={{ color: "red" }}>
                                {errors?.insuranceNo}
                              </span>
                            </div>
                          </div>
                          {/* <div className="form-group row"> */}
                          {/* <div className="form-group col-md-12 required">
                                                        <label for="inputVehicleTelematicsUnit">Telematics Unit *</label>
                                                        <select disabled={disabled} id="inputVehicleTelematicsUnit" name="type" className="form-control selectpicker" required onChange={(e)=>setInput({...input ,  telematicsUnitId:e.target.value})} value={input.telematicsUnitId}>
                                                            <option value="">Select Vehicle Telematics Unit</option>
                                                            {
                                                                telematicsUnitList.map((telematicsUnit)=>(
                                                                    <>
                                                                        <option value={telematicsUnit._id}>{telematicsUnit.name}</option>
                                                                    </>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>       */}

                          {/* </div> */}
                          {bikeTypeDetails.noOfBattery === 2 ? (
                            <div className="form-group row">
                              <div className="form-group col-md-12 required">
                                <label for="inputVehicleBatteryNumber">
                                  Vehicle Second Battery No.*
                                </label>
                                <input
                                  disabled={disabled}
                                  className="form-control"
                                  id="inputVehicleBatteryNumber"
                                  name="batteryNo"
                                  placeholder="Enter Battery No."
                                  ref={secondBatteryNoFocus}
                                  onChange={(e) =>
                                    setInput({
                                      ...input,
                                      secondBatteryNo: e.target.value,
                                    })
                                  }
                                  value={input.secondBatteryNo}
                                />
                                <span style={{ color: "red" }}>
                                  {errors?.secondBatteryNo}
                                </span>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="form-group row">
                            <div className="form-group col-md-12 required">
                              <label for="inputCity">Select City *</label>
                              <Select
                                id="inputCity"
                                isDisabled={disabled}
                                ref={cityFocus}
                                value={values.cityValue || "Select"}
                                onChange={(value) => changeCityDropDown(value)}
                                options={cityList}
                                filterOption={customFilter}
                              />
                              <span style={{ color: "red" }}>
                                {errors?.city}
                              </span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="form-group col-md-12 required">
                              <label for="inputCustomer">Select Customer</label>
                              <Select
                                id="inputCustomer"
                                ref={customerFocus}
                                isDisabled={disabled}
                                value={values.customerValue || "Select"}
                                onChange={(value) =>
                                  changeCustomerDropDown(value)
                                }
                                options={customerList}
                                filterOption={customFilter}
                              />
                              {/* <input className="form-control" id="inputCity" name="text" placeholder="Enter City" required onChange={(e)=>setInput({...input ,  city:e.target.value})} value={input.city}/> */}
                              <span style={{ color: "red" }}>
                                {errors?.customer}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group row">
                            <div className="form-group col-md-12 required">
                              <label for="inputVehicleSrNumber">
                                Vehicle SR No.*
                              </label>
                              <input
                                type="number"
                                disabled={disabled}
                                ref={srNumberFocus}
                                className="form-control"
                                id="inputVehicleSrNumber"
                                name="sr_number"
                                placeholder="Enter Sr No."
                                onChange={(e) =>
                                  setInput({
                                    ...input,
                                    sr_number: e.target.value,
                                  })
                                }
                                value={input.sr_number}
                              />
                              <span style={{ color: "red" }}>
                                {errors?.sr_number}
                              </span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="form-group col-md-12 required">
                              <label for="inputVehicleHub">
                                Select Vehicle Hub *
                              </label>
                              <select
                                disabled={disabled}
                                ref={vehicleHubIdFocus}
                                id="inputVehicleHub"
                                name="type"
                                className="form-control selectpicker"
                                onChange={(e) =>
                                  setInput({
                                    ...input,
                                    vehicleHubId: e.target.value,
                                  })
                                }
                                value={input.vehicleHubId}
                              >
                                <option value="">Select Vehicle Hub</option>
                                {hubList.map((hub) => (
                                  <>
                                    <option value={hub._id}>{hub.name}</option>
                                  </>
                                ))}
                              </select>
                              <span style={{ color: "red" }}>
                                {errors?.vehicleHubId}
                              </span>
                            </div>
                          </div>
                          {bikeTypeDetails.registered === 1 ? (
                            <div className="form-group row">
                              <div className="form-group col-md-12 required">
                                <label for="inputVehicleRCBookNumber">
                                  Vehicle RC Book No.*
                                </label>
                                <input
                                  disabled={disabled}
                                  ref={rcBookNoFocus}
                                  className="form-control"
                                  id="inputVehicleRCBookNumber"
                                  name="rcBookNo"
                                  placeholder="Enter RC Book No."
                                  onChange={(e) =>
                                    setInput({
                                      ...input,
                                      rcBookNo: e.target.value,
                                    })
                                  }
                                  value={input.rcBookNo}
                                />
                                <span style={{ color: "red" }}>
                                  {errors?.rcBookNo}
                                </span>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="form-group col-md-12 required">
                            <label for="inputVehicleTelematicsUnit">
                              Vehicle Telematics Unit *
                            </label>
                            <input
                              disabled={disabled}
                              className="form-control"
                              ref={telematicsUnitFocus}
                              id="inputVehicleTelematicsUnit"
                              name="text"
                              placeholder="Enter Vehicle TelematicUnit"
                              onChange={(e) =>
                                setInput({
                                  ...input,
                                  telematicsUnit: e.target.value,
                                })
                              }
                              value={input.telematicsUnit}
                            />
                            <span style={{ color: "red" }}>
                              {errors?.telematicsUnit}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group row">
                            <div
                              className="form-group col-md-12 required"
                              id="vehicleStatusRegisted"
                            >
                              <label>Owned /Leased *</label>
                              <br />
                              <div
                                className="custom-control custom-radio custom-control-inline"
                                id="vehicleOwnedLeased"
                              >
                                {input.owned === 1 ? (
                                  <input
                                    disabled={disabled}
                                    type="radio"
                                    id="statusOwnedLeased1"
                                    name="vehicleOwnedLeasedStatus"
                                    className="custom-control-input"
                                    value="1"
                                    onClick={(e) =>
                                      setInput({
                                        ...input,
                                        owned: 1,
                                        ownerName: "EVIFY Logitech Pvt Ltd",
                                        leaseCompanyId: "",
                                      })
                                    }
                                    checked
                                  />
                                ) : (
                                  <input
                                    disabled={disabled}
                                    type="radio"
                                    id="statusOwnedLeased1"
                                    name="vehicleOwnedLeasedStatus"
                                    className="custom-control-input"
                                    value="1"
                                    onClick={(e) =>
                                      setInput({
                                        ...input,
                                        owned: 1,
                                        ownerName: "EVIFY Logitech Pvt Ltd",
                                        leaseCompanyId: "",
                                      })
                                    }
                                  />
                                )}
                                <label
                                  className="custom-control-label status-radio"
                                  for="statusOwnedLeased1"
                                >
                                  Owned
                                </label>
                              </div>
                              <div className="custom-control custom-radio custom-control-inline">
                                {input.owned === 2 ? (
                                  <input
                                    disabled={disabled}
                                    type="radio"
                                    id="statusOwnedLeased0"
                                    name="vehicleOwnedLeasedStatus"
                                    className="custom-control-input"
                                    value="0"
                                    onClick={(e) =>
                                      setInput({ ...input, owned: 2 })
                                    }
                                    checked
                                  />
                                ) : (
                                  <input
                                    disabled={disabled}
                                    type="radio"
                                    id="statusOwnedLeased0"
                                    name="vehicleOwnedLeasedStatus"
                                    className="custom-control-input"
                                    value="0"
                                    onClick={(e) =>
                                      setInput({ ...input, owned: 2 })
                                    }
                                  />
                                )}
                                <label
                                  className="custom-control-label status-radio"
                                  for="statusOwnedLeased0"
                                >
                                  Leased
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            {input.owned === 2 ? (
                              <div className="form-group col-md-12 required">
                                <label for="inputVehicleLeaseCompany">
                                  Lease Company *
                                </label>
                                <select
                                  disabled={disabled}
                                  ref={leaseCompanyIdFocus}
                                  id="inputVehicleLeaseCompany"
                                  name="type"
                                  className="form-control selectpicker"
                                  onChange={(e) => handleToChange(e)}
                                  value={input.leaseCompanyId}
                                >
                                  <option value="">
                                    Select Vehicle Lease Company
                                  </option>
                                  {leaseCompanyList.map((leaseCompany) => (
                                    <>
                                      <option
                                        name={leaseCompany.name}
                                        value={leaseCompany._id}
                                      >
                                        {leaseCompany.name}
                                      </option>
                                    </>
                                  ))}
                                </select>
                                <span style={{ color: "red" }}>
                                  {errors?.leaseCompanyId}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="form-group row">
                            {input.owned === 2 &&
                            input.leaseCompanyId !== "" ? (
                              <div className="form-group col-md-12 required">
                                <label for="inputVehicleLeaseCompanyBatch">
                                  Select Batch *
                                </label>
                                <select
                                  disabled={disabled}
                                  ref={leaseCompanyBatchIdFocus}
                                  id="inputVehicleLeaseCompanyBatch"
                                  name="type"
                                  className="form-control selectpicker"
                                  onChange={(e) =>
                                    setInput({
                                      ...input,
                                      leaseCompanyBatchId: e.target.value,
                                    })
                                  }
                                  value={input.leaseCompanyBatchId}
                                >
                                  <option value="">Select Batch</option>
                                  {leaseCompanyBatchList.map(
                                    (leaseCompanyBatch) => (
                                      <>
                                        <option value={leaseCompanyBatch.value}>
                                          {leaseCompanyBatch.label}
                                        </option>
                                      </>
                                    )
                                  )}
                                </select>
                                <span style={{ color: "red" }}>
                                  {errors?.leaseCompanyBatchId}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                          <div className="card component-card_2">
                            <div className="card-header">QR Code</div>
                            <div
                              className="card-body"
                              style={{
                                display: "flex",
                                "justify-content": "center",
                                "align-content": "center",
                                "flex-direction": "column",
                                "text-align": "center",
                                width: "50mm",
                                minHeight: "50mm",
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                              id="qrCodeDiv"
                            >
                              <span>({input.sr_number})</span>
                              <span>
                                {input.registered === 1
                                  ? input.number
                                  : input.name}
                              </span>
                              <img
                                src={input.qrCodeSrc}
                                alt="no-image"
                                id="qr_img_src"
                                className="img-responsive"
                                style={{ width: "100%", height: "100%" }}
                              />
                            </div>
                            {/* <a href={input.qrCodeSrc} className="btn btn-dark view-btn" id="qr_img_src_download" data-href="" download>Download</a> */}
                            <button
                              type="button"
                              id="downloadInvoiceBtn"
                              className="btn btn-dark view-btn"
                              onClick={() => downloadCustomerInvoice()}
                            >
                              Download
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="form-group col-md-12 required">
                          <label for="inputVehicleOwnername">
                            Owner Name *
                          </label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            name="ownerName"
                            id="inputVehicleOwnername"
                            placeholder="Enter vehicle owner name"
                            required
                            onChange={(e) =>
                              setInput({ ...input, ownerName: e.target.value })
                            }
                            value={input.ownerName}
                          />
                        </div>
                      </div>

                      {bikeTypeDetails.registered === 1 ? (
                        <div className="form-group row">
                          <div className="form-group col-md-12 required">
                            <label for="rcBookFile">RC Book *</label>
                            {input.rcBookFile !== "" ? (
                              <input
                                disabled={disabled}
                                ref={rcBookFileFocus}
                                accept="image/jpg,image/jpeg,image/png,image/bmp,application/pdf"
                                multiple=""
                                type="file"
                                onChange={(e) => uploadRCBookFile(e)}
                                name="rcBookFile[]"
                                className="form-control p-2"
                                id="rcBookFile"
                              />
                            ) : (
                              <input
                                disabled={disabled}
                                ref={rcBookFileFocus}
                                accept="image/jpg,image/jpeg,image/png,image/bmp,application/pdf"
                                multiple=""
                                type="file"
                                onChange={(e) => uploadRCBookFile(e)}
                                name="rcBookFile[]"
                                className="form-control p-2"
                                id="rcBookFile"
                              />
                            )}
                            <span style={{ color: "red" }}>
                              {errors?.rcBookFile}
                            </span>
                            <br />
                            {input.rcBookFile !== "" ? (
                              input.rcBookFile.toString().endsWith("pdf") ? (
                                <a
                                  href={`${input.rcBookFile}`}
                                  className="btn btn-info"
                                  target="_blank"
                                >
                                  Show
                                </a>
                              ) : (
                                <img
                                  src={`${input.rcBookFile}`}
                                  alt="no-image"
                                  id="rcBookImgId"
                                  className="form-group p-2 img-responsive imgFile"
                                  onClick={() =>
                                    setModalImgURL(input.rcBookFile)
                                  }
                                  data-toggle="modal"
                                  data-target="#showImageModalCenter"
                                  style={{ width: "70px", height: "70px" }}
                                />
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="form-group row">
                        <div className="form-group col-md-8 required">
                          <label for="insuranceFile">Insurance *</label>
                          <input
                            disabled={disabled}
                            accept="image/jpg,image/jpeg,image/png,image/bmp,application/pdf"
                            multiple=""
                            type="file"
                            onChange={(e) => uploadInsuranceFile(e)}
                            name="insuranceFile[]"
                            className="form-control p-2"
                            id="insuranceFile"
                            ref={insuranceFileFocus}
                          />
                          <span style={{ color: "red" }}>
                            {errors?.insuranceFile}
                          </span>
                          <br />
                          {input.insuranceFile !== "" ? (
                            input.insuranceFile.toString().endsWith("pdf") ? (
                              <a
                                href={`${input.insuranceFile}`}
                                className="btn btn-info"
                                target="_blank"
                              >
                                Show
                              </a>
                            ) : (
                              <img
                                src={`${input.insuranceFile}`}
                                alt="no-image"
                                id="insuranceImgId"
                                className="form-group p-2 img-responsive imgFile"
                                onClick={() =>
                                  setModalImgURL(input.insuranceFile)
                                }
                                data-toggle="modal"
                                data-target="#showImageModalCenter"
                                style={{ width: "70px", height: "70px" }}
                              />
                            )
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="form-group col-md-4 required">
                          <label for="insuranceExpiry">Expiry Date *</label>
                          <span
                            hidden={addDocButtonHide}
                            id="addAnotherDocIdspan"
                            className="btn btn-warning btn-sm float-right ms-3 mb-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => addExtraDocumentsInForm()}
                          >
                            {" "}
                            Add Other Document{" "}
                          </span>
                          <input
                            ref={insuranceExpiryDateFocus}
                            disabled={disabled}
                            type="date"
                            name="insuranceExpiryDate"
                            className="form-control datepicker"
                            id="insuranceExpiry"
                            onChange={(e) =>
                              setInput({
                                ...input,
                                insuranceExpiryDate: e.target.value,
                              })
                            }
                            value={changeDateFormate(input.insuranceExpiryDate)}
                            autocomplete="off"
                          />
                          <span style={{ color: "red" }}>
                            {errors?.insuranceExpiryDate}
                          </span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="form-group col-md-12 required">
                          <label for="inputVehicleDetails">Details</label>
                          <input
                            disabled={disabled}
                            type="text"
                            className="form-control"
                            id="inputVehicleDetails"
                            placeholder="Enter vehicle details"
                            name="details"
                            onChange={(e) =>
                              setInput({ ...input, details: e.target.value })
                            }
                            value={input.details}
                          />
                        </div>
                      </div>
                      {/* <div className="form-group row">
                                            <div className="form-group col-md-8 required">
                                                <label for="permitFile">Permit </label>
                                                <input disabled={disabled} accept="image/jpg,image/jpeg,image/png,image/bmp" multiple="" type="file" onChange={(e)=>uploadPermitFile(e)} name="permitFile[]" className="form-control p-2" id="permitFile"/>
                                                {
                                                    input.permitFile !== '' ? <img src={`${input.permitFile}`} alt="no-image" id="permitImgId" className="form-group p-2 img-responsive imgFile" onClick={ ()=>setModalImgURL(input.permitFile)} data-toggle="modal" data-target="#showImageModalCenter" style={{width: "70px", height: "70px"}} /> : ''
                                                }
                                            </div>
                                            <div className="form-group col-md-4 required">
                                                <label for="permitExpiry">Expiry Date </label>
                                                <input disabled={disabled} type="date" name="permitExpiryDate" className="form-control datepicker" id="permitExpiry" onChange={(e)=>setInput({...input ,  permitExpiryDate:e.target.value})} value={changeDateFormate(input.permitExpiryDate)} autocomplete="off" />
                                            </div>
                                        </div> */}
                      {otherDocumentRow.map((otherDocumentItem) => (
                        <>
                          <div
                            className="form-group row"
                            id={`doc${otherDocumentItem.id}`}
                            ref={DocumentsFocus}
                            tabIndex="-1"
                          >
                            <div className="form-group col-md-4 required">
                              <label
                                for={`inputDocumentName${otherDocumentItem.id}`}
                              >
                                Document Name
                              </label>
                              <input
                                disabled={disabled}
                                type="text"
                                className="form-control"
                                name="docName"
                                id={`inputDocumentName${otherDocumentItem.id}`}
                                placeholder="Enter document name"
                                onChange={(e) =>
                                  addExtraDocumentsValue(
                                    e,
                                    otherDocumentItem.id
                                  )
                                }
                                value={otherDocumentItem.docName}
                              />
                              <span style={{ color: "red" }}>
                                {errors.document
                                  ? errors.document[otherDocumentItem.id]
                                      ?.docName
                                    ? errors.document[otherDocumentItem.id]
                                        ?.docName
                                    : ""
                                  : ""}
                              </span>
                            </div>
                            <div className="form-group col-md-8 required">
                              <label for={`otherFile${otherDocumentItem.id}`}>
                                Document File{" "}
                              </label>
                              <span
                                hidden={addDocButtonHide}
                                className="badge badge-danger float-right"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  deleteExtraDocumentsInForm(
                                    otherDocumentItem.id,
                                    otherDocumentItem.docUrl
                                  )
                                }
                                id={`delethisidspan${otherDocumentItem.id}`}
                              >
                                {" "}
                                Delete This
                              </span>
                              <input
                                disabled={disabled}
                                accept="image/jpg,image/jpeg,image/png,image/bmp"
                                multiple=""
                                type="file"
                                className="form-control p-2"
                                id={`otherFile${otherDocumentItem.id}`}
                                onChange={(e) =>
                                  uploadOtherDocFile(e, otherDocumentItem.id)
                                }
                              />
                              <span style={{ color: "red" }}>
                                {errors.document
                                  ? errors.document[otherDocumentItem.id]
                                      ?.docUrl
                                    ? errors.document[otherDocumentItem.id]
                                        ?.docUrl
                                    : ""
                                  : ""}
                              </span>
                              {otherDocumentItem.docUrl !== "" ? (
                                <img
                                  src={`${otherDocumentItem.docUrl}`}
                                  alt="no-image"
                                  id={`otherImgId${otherDocumentItem.id}`}
                                  className="form-group p-2 img-responsive imgFile"
                                  onClick={() =>
                                    setModalImgURL(otherDocumentItem.docUrl)
                                  }
                                  data-toggle="modal"
                                  data-target="#showImageModalCenter"
                                  style={{ width: "70px", height: "70px" }}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                      {/* <div className="form-group row">
                                            <div className="form-group col-md-12 required">
                                                <div className="form-group required" id="vehicleStatusRegisted">
                                                    <label>Vehicle Registered *</label><br/>
                                                    <div className="custom-control custom-radio custom-control-inline" id="vehicleStatusRegisted">
                                                        {
                                                            input.registered === 1 ? <input disabled={disabled} type="radio" id="statusRegisted1" name="vehicleRegisteredStatus" className="custom-control-input" value="1" onClick={(e)=>setInput({...input ,  registered:1})} checked/> : <input disabled={disabled} type="radio" id="statusRegisted1" name="vehicleRegisteredStatus" className="custom-control-input" value="1" onClick={(e)=>setInput({...input ,  registered:1})}/>
                                                        }
                                                        <label className="custom-control-label status-radio" for="statusRegisted1">Registered</label>
                                                    </div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        {
                                                            input.registered === 0 ? <input disabled={disabled} type="radio" id="statusRegisted0" name="vehicleRegisteredStatus" className="custom-control-input" value="0" onClick={(e)=>setInput({...input ,  registered:0})} checked/> : <input disabled={disabled} type="radio" id="statusRegisted0" name="vehicleRegisteredStatus" className="custom-control-input" value="0" onClick={(e)=>setInput({...input ,  registered:0})}/>
                                                        }
                                                        <label className="custom-control-label status-radio" for="statusRegisted0">Un-Registered</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                      <div className="form-group row">
                        <div className="form-group col-md-12 required">
                          <div
                            className="form-group required"
                            id="vehicleStatusMain"
                          >
                            <label>Vehicle Status *</label>
                            <br />
                            <div
                              className="custom-control custom-radio custom-control-inline"
                              id="vehicleStatus"
                            >
                              {input.isActive === 1 ? (
                                <input
                                  disabled={disabled}
                                  type="radio"
                                  id="status1"
                                  name="vehicleStatus"
                                  className="custom-control-input"
                                  onClick={(e) =>
                                    setInput({ ...input, isActive: 1 })
                                  }
                                  value="1"
                                  checked
                                />
                              ) : (
                                <input
                                  disabled={disabled}
                                  type="radio"
                                  id="status1"
                                  name="vehicleStatus"
                                  className="custom-control-input"
                                  onClick={(e) =>
                                    setInput({ ...input, isActive: 1 })
                                  }
                                  value="1"
                                />
                              )}
                              <label
                                className="custom-control-label status-radio"
                                for="status1"
                              >
                                Active
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              {input.isActive === 0 ? (
                                <input
                                  disabled={disabled}
                                  type="radio"
                                  id="status0"
                                  name="vehicleStatus"
                                  className="custom-control-input"
                                  value="0"
                                  onClick={(e) =>
                                    setInput({ ...input, isActive: 0 })
                                  }
                                  checked
                                />
                              ) : (
                                <input
                                  disabled={disabled}
                                  type="radio"
                                  id="status0"
                                  name="vehicleStatus"
                                  className="custom-control-input"
                                  value="0"
                                  onClick={(e) =>
                                    setInput({ ...input, isActive: 0 })
                                  }
                                />
                              )}
                              <label
                                className="custom-control-label status-radio"
                                for="status0"
                              >
                                Deactive
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="form-group col-md-12 required">
                          {input.isActive === 0 ? (
                            <div
                              className="form-group required"
                              id="vehicleStatusDeactiveMain"
                            >
                              <label>Vehicle Deactivate Reason *</label>
                              <div class="form-check">
                                {input.deactivateReason === "At Hub" ? (
                                  <input
                                    disabled={disabled}
                                    class="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    value="At Hub"
                                    onClick={(e) =>
                                      setInput({
                                        ...input,
                                        deactivateReason: "At Hub",
                                      })
                                    }
                                    id="flexRadioDefault1"
                                    checked
                                  />
                                ) : (
                                  <input
                                    disabled={disabled}
                                    class="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    value="At Hub"
                                    onClick={(e) =>
                                      setInput({
                                        ...input,
                                        deactivateReason: "At Hub",
                                      })
                                    }
                                    id="flexRadioDefault1"
                                  />
                                )}
                                <label
                                  class="form-check-label"
                                  for="flexRadioDefault1"
                                >
                                  At Hub
                                </label>
                              </div>
                              <div class="form-check">
                                {input.deactivateReason ===
                                "At Dealership For Repair" ? (
                                  <input
                                    disabled={disabled}
                                    class="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    value="At Dealership For Repair"
                                    id="flexRadioDefault2"
                                    onClick={(e) =>
                                      setInput({
                                        ...input,
                                        deactivateReason:
                                          "At Dealership For Repair",
                                      })
                                    }
                                    checked
                                  />
                                ) : (
                                  <input
                                    disabled={disabled}
                                    class="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    value="At Dealership For Repair"
                                    id="flexRadioDefault2"
                                    onClick={(e) =>
                                      setInput({
                                        ...input,
                                        deactivateReason:
                                          "At Dealership For Repair",
                                      })
                                    }
                                  />
                                )}
                                <label
                                  class="form-check-label"
                                  for="flexRadioDefault2"
                                >
                                  At Dealership For Repair
                                </label>
                              </div>
                              <div class="form-check">
                                {input.deactivateReason ===
                                "Waiting For Spair Parts" ? (
                                  <input
                                    disabled={disabled}
                                    class="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    value="Waiting For Spair Parts"
                                    id="flexRadioDefault3"
                                    onClick={(e) =>
                                      setInput({
                                        ...input,
                                        deactivateReason:
                                          "Waiting For Spair Parts",
                                      })
                                    }
                                    checked
                                  />
                                ) : (
                                  <input
                                    disabled={disabled}
                                    class="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    value="Waiting For Spair Parts"
                                    id="flexRadioDefault3"
                                    onClick={(e) =>
                                      setInput({
                                        ...input,
                                        deactivateReason:
                                          "Waiting For Spair Parts",
                                      })
                                    }
                                  />
                                )}
                                <label
                                  class="form-check-label"
                                  for="flexRadioDefault3"
                                >
                                  Waiting For Spair Parts
                                </label>
                              </div>
                              <div class="form-check">
                                {input.deactivateReason ===
                                "Insurance Claim" ? (
                                  <input
                                    disabled={disabled}
                                    class="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    value="Insurance Claim"
                                    id="flexRadioDefault4"
                                    onClick={(e) =>
                                      setInput({
                                        ...input,
                                        deactivateReason: "Insurance Claim",
                                      })
                                    }
                                    checked
                                  />
                                ) : (
                                  <input
                                    disabled={disabled}
                                    class="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    value="Insurance Claim"
                                    id="flexRadioDefault4"
                                    onClick={(e) =>
                                      setInput({
                                        ...input,
                                        deactivateReason: "Insurance Claim",
                                      })
                                    }
                                  />
                                )}
                                <label
                                  class="form-check-label"
                                  for="flexRadioDefault4"
                                >
                                  Insurance Claim
                                </label>
                              </div>
                              <div class="form-check">
                                {input.deactivateReason === "Others" ? (
                                  <input
                                    disabled={disabled}
                                    class="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    value="Others"
                                    id="flexRadioDefault4"
                                    onClick={(e) =>
                                      setInput({
                                        ...input,
                                        deactivateReason: "Others",
                                      })
                                    }
                                    checked
                                  />
                                ) : (
                                  <input
                                    disabled={disabled}
                                    class="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    value="Others"
                                    id="flexRadioDefault4"
                                    onClick={(e) =>
                                      setInput({
                                        ...input,
                                        deactivateReason: "Others",
                                      })
                                    }
                                  />
                                )}
                                <label
                                  class="form-check-label"
                                  for="flexRadioDefault4"
                                >
                                  Others
                                </label>
                              </div>
                              {input.deactivateReason === "Others" ? (
                                <div class="form-check">
                                  <input
                                    disabled={disabled}
                                    class="form-control col-md-12"
                                    type="text"
                                    name="deactivateReasonInput"
                                    id="deactivateReasonInput"
                                    onChange={(e) =>
                                      setInput({
                                        ...input,
                                        deactivateReasonInput: e.target.value,
                                      })
                                    }
                                    value={input.deactivateReasonInput}
                                  />
                                  <br />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div
                        className="modal fade"
                        id="showImageModalCenter"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="showImageModalCenterLabel"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered modal-lg"
                          role="img"
                        >
                          <div className="modal-content">
                            <div className="modal-body">
                              {modalImgURL !== "" ? (
                                <img
                                  src={`${modalImgURL}`}
                                  alt="no-image"
                                  id="modalImgId"
                                  className="img-responsive"
                                  style={{ width: "100%", height: "100%" }}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group" id="saveAndCancleButtons">
                      <button
                        onClick={() => setEditFormEditable(false)}
                        hidden={cancelButtonHide}
                        type="reset"
                        class="btn btn-danger mt-3"
                      >
                        Cancel
                      </button>
                      &nbsp;
                      <button
                        hidden={submitButtonHide}
                        type="submit"
                        class="btn btn-success mt-3 view-btn"
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </>
  );
};
export default ViewVehicle;
