import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FiCamera } from "react-icons/fi";
import { MdOutlineDirectionsBike } from "react-icons/md";
import { RiQrScan2Line } from "react-icons/ri";
import * as action from "../../../../action/Dashboard/dashboard_action";

const NoOfDrivers = () => {
  const dispatch = useDispatch();
  const [dashboardDetails, setDashboardDetails] = useState({});

  // No of drivers
  useEffect(() => {
    dispatch(action.dashboard_driver_details())
      .then((data) => {
        setDashboardDetails(data?.data);
      })
      .catch({});
  }, []);

  return (
    <div className="  layout-spacing">
      <div className="stats-card">
        <div className="widget-heading">
          <h5 className="mb-3">No. of Drivers</h5>
        </div>
        <div className="row">
          <div className=" col-md-12 col-lg-4  layout-spacing">
            <div className="stats-data" style={{ background: "#198754" }}>
              <div className="widget-content">
                <div className="w-numeric-value">
                  <div className="w-icon">
                    <MdOutlineDirectionsBike />
                  </div>
                  <div className="w-content">
                    <span className="w-numeric-title">Total</span>
                    <span className="w-value" id="totalParcelsId">
                      {dashboardDetails.totalDrivers}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" col-md-12 col-lg-4 layout-spacing">
            <div className="stats-data" style={{ background: "#198754" }}>
              <div className="widget-content">
                <div className="w-numeric-value">
                  <div className="w-icon">
                    <FiCamera />
                  </div>

                  <div className="w-content">
                    <span className="w-numeric-title">Taken Selfies</span>
                    <span className="w-value" id="totalParcelsId">
                      {dashboardDetails.totalSelfies}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" col-md-12 col-lg-4 layout-spacing">
            <div className="stats-data" style={{ background: "#198754" }}>
              <div className="widget-content">
                <div className="w-numeric-value">
                  <div className="w-icon">
                    <RiQrScan2Line />
                  </div>
                  <div className="w-content">
                    <span className="w-numeric-title">Scanned Bike</span>
                    <span className="w-value" id="totalParcelsId">
                      {dashboardDetails.totalVehicleScanned}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoOfDrivers;
