import {DASHBOARD_MONTH_SUCCESSFULL, DASHBOARD_MONTH_ERROR,DASHBOARD_MONTH_LOADING} from '../../action/type';

const INITIAL_STATE={
    userData: {},
    error_msg:"",
    loading: false
}

export default(state=INITIAL_STATE,action)=>{
    switch(action.type)
    {
        case DASHBOARD_MONTH_LOADING:{
            return Object.assign({},state,{loading: true})
        }
        case DASHBOARD_MONTH_SUCCESSFULL:{
            return Object.assign({},state,{DashboardVehicleData: action.data, loading: false})
        }
        case DASHBOARD_MONTH_ERROR:{
            return Object.assign({},state,{error_msg:action.data.error_msg, loading: false})
        }
        default:
            return state;
    }
}