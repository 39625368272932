import {
  DRIVER_LIST_SUCCESSFULL,
  DRIVER_LIST_ERROR,
  DRIVER_LIST_LOADING,
  DRIVER_STATUS_CHANGE_SUCCESSFULL,
  DRIVER_STATUS_CHANGE_ERROR,
  DRIVER_STATUS_CHANGE_LOADING,
  DRIVER_FILE_UPLOAD_SUCCESSFULL,
  DRIVER_FILE_UPLOAD_ERROR,
  DRIVER_FILE_UPLOAD_LOADING,
  DRIVER_DELETE_FILE_SUCCESSFULL,
  DRIVER_DELETE_FILE_ERROR,
  DRIVER_DELETE_FILE_LOADING,
  DRIVER_ADD_SUCCESSFULL,
  DRIVER_ADD_ERROR,
  DRIVER_ADD_LOADING,
  DRIVER_DETACHED_VEHICLE_SUCCESSFULL,
  DRIVER_DETACHED_VEHICLE_ERROR,
  DRIVER_DETACHED_VEHICLE_LOADING,
  DRIVER_LOGOFF_SUCCESSFULL,
  DRIVER_LOGOFF_ERROR,
  DRIVER_LOGOFF_LOADING,
  DRIVER_SELFIE_REQUEST_SUCCESSFULL,
  DRIVER_SELFIE_REQUEST_ERROR,
  DRIVER_SELFIE_REQUEST_LOADING,
  CUSTOMER_LIST_SUCCESSFULL,
  CUSTOMER_LIST_ERROR,
  CUSTOMER_LIST_LOADING,
  ADDRESS_LIST_SUCCESSFULL,
  ADDRESS_LIST_ERROR,
  ADDRESS_LIST_LOADING,
  SHIFT_LIST_SUCCESSFULL,
  SHIFT_LIST_ERROR,
  SHIFT_LIST_LOADING,
  DRIVER_ASSIGN_CUSTOMER_SUCCESSFULL,
  DRIVER_ASSIGN_CUSTOMER_ERROR,
  DRIVER_ASSIGN_CUSTOMER_LOADING,
  DRIVER_DETAILS_SUCCESSFULL,
  DRIVER_DETAILS_ERROR,
  DRIVER_DETAILS_LOADING,
  DRIVER_EDIT_SUCCESSFULL,
  DRIVER_EDIT_ERROR,
  DRIVER_EDIT_LOADING,
  UNVERIFIED_DRIVER_LIST_SUCCESSFULL,
  UNVERIFIED_DRIVER_LIST_ERROR,
  UNVERIFIED_DRIVER_LIST_LOADING,
  DRIVER_VERIFY_SUCCESSFULL,
  DRIVER_VERIFY_ERROR,
  DRIVER_VERIFY_LOADING,
  DRIVER_DELETE_SUCCESSFULL,
  DRIVER_DELETE_ERROR,
  DRIVER_DELETE_LOADING,
  DRIVER_WORK_LIST_SUCCESSFULL,
  DRIVER_WORK_LIST_ERROR,
  DRIVER_WORK_LIST_LOADING,
  DRIVER_ATTENDANCE_LIST_SUCCESSFULL,
  DRIVER_ATTENDANCE_LIST_ERROR,
  DRIVER_ATTENDANCE_LIST_LOADING,
  DRIVER_MULTIPLE_ASSIGN_LIST_SUCCESSFULL,
  DRIVER_MULTIPLE_ASSIGN_LIST_ERROR,
  DRIVER_MULTIPLE_ASSIGN_LIST_LOADING,
  DRIVER_MULTIPLE_ASSIGN_DELETE_SUCCESSFULL,
  DRIVER_MULTIPLE_ASSIGN_DELETE_ERROR,
  DRIVER_MULTIPLE_ASSIGN_DELETE_LOADING,
  DRIVER_ASSIGN_SINGLE_SUCCESSFULL,
  DRIVER_ASSIGN_SINGLE_ERROR,
  DRIVER_ASSIGN_SINGLE_LOADING,
  ALL_DRIVER_WORK_LIST_SUCCESSFULL,
  ALL_DRIVER_WORK_LIST_ERROR,
  ALL_DRIVER_WORK_LIST_LOADING,
  ALL_UNDELIVERED_REASON_LIST_SUCCESSFULL,
  ALL_UNDELIVERED_REASON_LIST_ERROR,
  ALL_UNDELIVERED_REASON_LIST_LOADING,
  DRIVER_WORK_DETAILS_SUCCESSFULL,
  DRIVER_WORK_DETAILS_ERROR,
  DRIVER_WORK_DETAILS_LOADING,
  DRIVER_MULTIPLE_FILE_UPLOAD_SUCCESSFULL,
  DRIVER_MULTIPLE_FILE_UPLOAD_ERROR,
  DRIVER_MULTIPLE_FILE_UPLOAD_LOADING,
  GET_DRIVER_LOCATION_SUCCESSFULL,
  GET_DRIVER_LOCATION_ERROR,
  GET_DRIVER_LOCATION_LOADING,
  GET_DRIVER_LOGIN_REQUEST_LIST_SUCCESSFULL,
  GET_DRIVER_LOGIN_REQUEST_LIST_ERROR,
  GET_DRIVER_LOGIN_REQUEST_LIST_LOADING,
  APPROVE_DRIVER_LOGIN_REQUEST_SUCCESSFULL,
  APPROVE_DRIVER_LOGIN_REQUEST_ERROR,
  APPROVE_DRIVER_LOGIN_REQUEST_LOADING,
  DELETE_DRIVER_LOGIN_REQUEST_SUCCESSFULL,
  DELETE_DRIVER_LOGIN_REQUEST_ERROR,
  DELETE_DRIVER_LOGIN_REQUEST_LOADING,
  DRIVER_SET_DEFAULT_CUSTOMER_SUCCESSFULL,
  DRIVER_SET_DEFAULT_CUSTOMER_ERROR,
  DRIVER_SET_DEFAULT_CUSTOMER_LOADING,
  DRIVER_LIST_SELECT_SUCCESSFULL,
  DRIVER_LIST_SELECT_ERROR,
  DRIVER_LIST_SELECT_LOADING,
  DRIVER_ISSUES_LIST_SUCCESSFULL,
  DRIVER_ISSUES_LIST_ERROR,
  DRIVER_ISSUES_LIST_LOADING,
  DRIVER_ISSUES_IMAGES_SUCCESSFULL,
  DRIVER_ISSUES_IMAGES_ERROR,
  DRIVER_ISSUES_IMAGES_LOADING,
  GET_ALL_ATTENDANCE_SUCCESSFULL,
  GET_ALL_ATTENDANCE_ERROR,
  GET_ALL_ATTENDANCE_LOADING,
  GET_ALL_DRIVER_ATTENDANCE_SUCCESSFULL,
  GET_ALL_DRIVER_ATTENDANCE_ERROR,
  GET_ALL_DRIVER_ATTENDANCE_LOADING,
  GET_ALL_DRIVER_ATTENDANCE_AND_PARCEL_SUCCESSFULL,
  GET_ALL_DRIVER_ATTENDANCE_AND_PARCEL_ERROR,
  GET_ALL_DRIVER_ATTENDANCE_AND_PARCEL_LOADING,
  DRIVER_ISSUES_CATEGORY_LIST_SUCCESSFULL,
  DRIVER_ISSUES_CATEGORY_LIST_LOADING,
  DRIVER_ISSUES_CATEGORY_LIST_ERROR,
  DRIVER_ADD_ISSUES_CATEGORY_SUCCESSFULL,
  DRIVER_ADD_ISSUES_CATEGORY_LOADING,
  DRIVER_ADD_ISSUES_CATEGORY_ERROR,
  DRIVER_DELETE_ISSUES_CATEGORY_SUCCESSFULL,
  DRIVER_DELETE_ISSUES_CATEGORY_LOADING,
  DRIVER_DELETE_ISSUES_CATEGORY_ERROR,
  DRIVER_UPDATE_ISSUES_CATEGORY_SUCCESSFULL,
  DRIVER_UPDATE_ISSUES_CATEGORY_LOADING,
  DRIVER_UPDATE_ISSUES_CATEGORY_ERROR,
  DRIVER_LEFT_SURVEY_ADD_LOADING,
  DRIVER_LEFT_SURVEY_ADD_SUCCESSFULL,
  DRIVER_LEFT_SURVEY_ADD_ERROR,
  DRIVER_LEFT_SURVEY_LIST_LOADING,
  DRIVER_LEFT_SURVEY_LIST_SUCCESSFULL,
  DRIVER_LEFT_SURVEY_LIST_ERROR,
  DRIVER_NOT_TAKEN_SELFIE_LIST_LOADING,
  DRIVER_NOT_TAKEN_SELFIE_LIST_SUCCESSFULL,
  DRIVER_NOT_TAKEN_SELFIE_LIST_ERROR,
  DRIVER_SINGLE_SURVEY_LIST_LOADING,
  DRIVER_SINGLE_SURVEY_LIST_SUCCESSFULL,
  DRIVER_SINGLE_SURVEY_LIST_ERROR,
  ADD_RIDERS_EARNING_LOADING,
  ADD_RIDERS_EARNING_SUCCESSFULL,
  ADD_RIDERS_EARNING_ERROR,
  GET_RIDERS_EARNING_LOADING,
  GET_RIDERS_EARNING_SUCCESSFULL,
  GET_RIDERS_EARNING_ERROR,
  EDIT_RIDERS_EARNING_LOADING,
  EDIT_RIDERS_EARNING_SUCCESSFULL,
  EDIT_RIDERS_EARNING_ERROR,
  ADD_RIDERS_PARCEL_LOADING,
  ADD_RIDERS_PARCEL_SUCCESSFULL,
  ADD_RIDERS_PARCEL_ERROR,
  GET_RIDERS_PARCEL_LOADING,
  GET_RIDERS_PARCEL_SUCCESSFULL,
  GET_RIDERS_PARCEL_ERROR,
  EDIT_RIDERS_PARCEL_LOADING,
  EDIT_RIDERS_PARCEL_SUCCESSFULL,
  EDIT_RIDERS_PARCEL_ERROR,
  GET_COD_ORDERS_LOADING,
  GET_COD_ORDERS_SUCCESSFULL,
  GET_COD_ORDERS_ERROR,
  ADD_COD_ORDERS_LOADING,
  ADD_COD_ORDERS_SUCCESSFULL,
  ADD_COD_ORDERS_ERROR,
  EDIT_COD_ORDERS_LOADING,
  EDIT_COD_ORDERS_SUCCESSFULL,
  EDIT_COD_ORDERS_ERROR,
} from "../../action/type";

import * as AuthService from "../../service/services";

export const Driver_issues_list = (driver_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_ISSUES_LIST_LOADING,
        data: true,
      });
      AuthService.DriverIssuesList(driver_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_ISSUES_LIST_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_ISSUES_LIST_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const Driver_issues_category_list = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_ISSUES_CATEGORY_LIST_LOADING,
        data: true,
      });
      AuthService.DriverIssuesCategoryList()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_ISSUES_CATEGORY_LIST_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_ISSUES_CATEGORY_LIST_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const Driver_add_issues_category = (credential) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_ADD_ISSUES_CATEGORY_LOADING,
        data: true,
      });
      AuthService.DriverAddIssuesCategory(credential)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_ADD_ISSUES_CATEGORY_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_ADD_ISSUES_CATEGORY_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const Left_Driver_survey_form = (credential) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_LEFT_SURVEY_ADD_LOADING,
        data: true,
      });
      AuthService.DriverSarveyForm(credential)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_LEFT_SURVEY_ADD_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_LEFT_SURVEY_ADD_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const Driver_Single_Survey_List = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_SINGLE_SURVEY_LIST_LOADING,
        data: true,
      });
      AuthService.DriverSingleSarveyList(id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_SINGLE_SURVEY_LIST_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_SINGLE_SURVEY_LIST_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const Driver_Not_Taken_Selfie_List = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_NOT_TAKEN_SELFIE_LIST_LOADING,
        data: true,
      });
      AuthService.DriverNotTakenSelfieList()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_NOT_TAKEN_SELFIE_LIST_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_NOT_TAKEN_SELFIE_LIST_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const Left_Driver_survey_List = (start_date, end_date) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_LEFT_SURVEY_LIST_LOADING,
        data: true,
      });
      AuthService.DriverSarveyList(start_date, end_date)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_LEFT_SURVEY_LIST_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_LEFT_SURVEY_LIST_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const Driver_delete_issues_category = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_DELETE_ISSUES_CATEGORY_LOADING,
        data: true,
      });
      AuthService.DriverDeleteIssuesCategory(id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_DELETE_ISSUES_CATEGORY_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_DELETE_ISSUES_CATEGORY_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const Driver_update_issues_category = (credential) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_UPDATE_ISSUES_CATEGORY_LOADING,
        data: true,
      });
      AuthService.DriverUpdateIssuesCategory(credential)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_UPDATE_ISSUES_CATEGORY_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_UPDATE_ISSUES_CATEGORY_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const Get_All_Driver_Attendance = (start_date, end_date) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: GET_ALL_DRIVER_ATTENDANCE_LOADING,
        data: true,
      });
      AuthService.GetAllDriverAttendance(start_date, end_date)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_ALL_DRIVER_ATTENDANCE_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: GET_ALL_DRIVER_ATTENDANCE_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const Get_All_Driver_Attendance_Parcel = (start_date, end_date) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: GET_ALL_DRIVER_ATTENDANCE_AND_PARCEL_LOADING,
        data: true,
      });
      AuthService.getAllAttendanceAndParcel(start_date, end_date)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_ALL_DRIVER_ATTENDANCE_AND_PARCEL_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: GET_ALL_DRIVER_ATTENDANCE_AND_PARCEL_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const Get_All_Attendance = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: GET_ALL_ATTENDANCE_LOADING,
        data: true,
      });
      AuthService.GetAllAttendance()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_ALL_ATTENDANCE_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: GET_ALL_ATTENDANCE_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const Driver_issues_images = (issue_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_ISSUES_IMAGES_LOADING,
        data: true,
      });
      AuthService.DriverIssuesImages(issue_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_ISSUES_IMAGES_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_ISSUES_IMAGES_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const Driver_list = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_LIST_LOADING,
        data: true,
      });
      AuthService.DriverList()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_LIST_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_LIST_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const Driver_list_select = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_LIST_SELECT_LOADING,
        data: true,
      });
      AuthService.DriverList()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_LIST_SELECT_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_LIST_SELECT_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const unverified_driver_list = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: UNVERIFIED_DRIVER_LIST_LOADING,
        data: true,
      });
      AuthService.UnverifiedDriverList()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: UNVERIFIED_DRIVER_LIST_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: UNVERIFIED_DRIVER_LIST_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const driver_login_request_list = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: GET_DRIVER_LOGIN_REQUEST_LIST_LOADING,
        data: true,
      });
      AuthService.AllDriverRequestList()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_DRIVER_LOGIN_REQUEST_LIST_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: GET_DRIVER_LOGIN_REQUEST_LIST_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const driver_approve_login_request = (driverId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: APPROVE_DRIVER_LOGIN_REQUEST_LOADING,
        data: true,
      });
      AuthService.ApproveDriverRequest(driverId)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: APPROVE_DRIVER_LOGIN_REQUEST_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: APPROVE_DRIVER_LOGIN_REQUEST_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const driver_delete_login_request = (driverId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DELETE_DRIVER_LOGIN_REQUEST_LOADING,
        data: true,
      });
      AuthService.DeleteDriverRequest(driverId)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DELETE_DRIVER_LOGIN_REQUEST_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DELETE_DRIVER_LOGIN_REQUEST_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const DriverActiveDeactive = (driverId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_STATUS_CHANGE_LOADING,
        data: true,
      });
      AuthService.DriverStatusChange(driverId)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_STATUS_CHANGE_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_STATUS_CHANGE_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const driver_file_upload = (credential) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_FILE_UPLOAD_LOADING,
        data: true,
      });
      AuthService.FileUpload(credential)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_FILE_UPLOAD_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_FILE_UPLOAD_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const delete_driver_file = (credential) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_DELETE_FILE_LOADING,
        data: true,
      });
      AuthService.DeleteFile(credential)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_DELETE_FILE_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_DELETE_FILE_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const add_driver = (credential) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_ADD_LOADING,
        data: true,
      });
      AuthService.AddDriver(credential)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_ADD_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_ADD_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const driver_detached_vehicle = (driver_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_DETACHED_VEHICLE_LOADING,
        data: true,
      });
      AuthService.detachedVehicleFromDriver(driver_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_DETACHED_VEHICLE_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_DETACHED_VEHICLE_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const driver_logoff = (driver_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_LOGOFF_LOADING,
        data: true,
      });
      AuthService.DriverLogoff(driver_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_LOGOFF_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_LOGOFF_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const driver_selfie_request = (driver_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_SELFIE_REQUEST_LOADING,
        data: true,
      });
      AuthService.DriverSelfieRequest(driver_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_SELFIE_REQUEST_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_SELFIE_REQUEST_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const driver_verify = (driver_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_VERIFY_LOADING,
        data: true,
      });
      AuthService.DriverVerify(driver_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_VERIFY_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_VERIFY_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const driver_delete = (driver_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_DELETE_LOADING,
        data: true,
      });
      AuthService.DriverDelete(driver_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_DELETE_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_DELETE_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const customer_list = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: CUSTOMER_LIST_LOADING,
        data: true,
      });
      AuthService.CustomerListForDropdown()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: CUSTOMER_LIST_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: CUSTOMER_LIST_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const customer_address_list = (customer_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADDRESS_LIST_LOADING,
        data: true,
      });
      AuthService.CustomerAddressListForDropdown(customer_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ADDRESS_LIST_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: ADDRESS_LIST_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const customer_shift_list = (customer_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SHIFT_LIST_LOADING,
        data: true,
      });
      AuthService.CustomerShiftListForDropdown(customer_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: SHIFT_LIST_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: SHIFT_LIST_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const driver_assign_customer = (credential) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_ASSIGN_CUSTOMER_LOADING,
        data: true,
      });
      AuthService.DriverAssignCustomer(credential)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_ASSIGN_CUSTOMER_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_ASSIGN_CUSTOMER_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const driver_details = (driver_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_DETAILS_LOADING,
        data: true,
      });
      AuthService.DriverDetails(driver_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_DETAILS_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_DETAILS_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const edit_driver = (credential) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_EDIT_LOADING,
        data: true,
      });
      AuthService.EditDriver(credential)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_EDIT_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_EDIT_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const driver_work_list = (driver_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_WORK_LIST_LOADING,
        data: true,
      });
      AuthService.DriverWorkList(driver_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_WORK_LIST_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_WORK_LIST_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const driver_attendance_list = (driver_id, start_date, end_date) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_ATTENDANCE_LIST_LOADING,
        data: true,
      });
      AuthService.DriveAttendanceList(driver_id, start_date, end_date)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_ATTENDANCE_LIST_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_ATTENDANCE_LIST_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const driver_multiple_assign_list = (driver_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_MULTIPLE_ASSIGN_LIST_LOADING,
        data: true,
      });
      AuthService.DriverMultipleAssignList(driver_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_MULTIPLE_ASSIGN_LIST_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_MULTIPLE_ASSIGN_LIST_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const driver_multiple_assign_delete = (assign_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_MULTIPLE_ASSIGN_DELETE_LOADING,
        data: true,
      });
      AuthService.DriverMultipleAssignDelete(assign_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_MULTIPLE_ASSIGN_DELETE_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_MULTIPLE_ASSIGN_DELETE_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const driver_single_assign = (credential) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_ASSIGN_SINGLE_LOADING,
        data: true,
      });
      AuthService.DriverSingleAssign(credential)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_ASSIGN_SINGLE_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_ASSIGN_SINGLE_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const all_driver_work_list = (start_date, end_date, driver_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ALL_DRIVER_WORK_LIST_LOADING,
        data: true,
      });
      AuthService.AllDriverWorkList(start_date, end_date, driver_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ALL_DRIVER_WORK_LIST_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: ALL_DRIVER_WORK_LIST_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const undelivered_reason_list = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ALL_UNDELIVERED_REASON_LIST_LOADING,
        data: true,
      });
      AuthService.GetUndeliveredReasonList()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ALL_UNDELIVERED_REASON_LIST_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: ALL_UNDELIVERED_REASON_LIST_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const driver_work_details = (driver_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_WORK_DETAILS_LOADING,
        data: true,
      });
      AuthService.DriverWorkDetails(driver_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_WORK_DETAILS_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_WORK_DETAILS_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const driver_multiple_file_upload = (credential) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_MULTIPLE_FILE_UPLOAD_LOADING,
        data: true,
      });
      AuthService.MultipleFileUpload(credential)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_MULTIPLE_FILE_UPLOAD_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_MULTIPLE_FILE_UPLOAD_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const get_driver_location = (driver_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: GET_DRIVER_LOCATION_LOADING,
        data: true,
      });
      AuthService.GetDriverLocationDetails(driver_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_DRIVER_LOCATION_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: GET_DRIVER_LOCATION_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const set_driver_default_customer = (credential) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DRIVER_SET_DEFAULT_CUSTOMER_LOADING,
        data: true,
      });
      AuthService.driverSetDefaultCustomer(credential)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DRIVER_SET_DEFAULT_CUSTOMER_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DRIVER_SET_DEFAULT_CUSTOMER_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const driver_add_riders_earning = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADD_RIDERS_EARNING_LOADING,
        data: true,
      });
      AuthService.AddRidersEarning(data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ADD_RIDERS_EARNING_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: ADD_RIDERS_EARNING_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const driver_get_riders_earning = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: GET_RIDERS_EARNING_LOADING,
        data: true,
      });
      AuthService.GetRidersEarning()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_RIDERS_EARNING_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: GET_RIDERS_EARNING_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const driver_edit_riders_earning = (id, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: EDIT_RIDERS_EARNING_LOADING,
        data: true,
      });
      AuthService.EditRidersEarning(id, data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: EDIT_RIDERS_EARNING_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: EDIT_RIDERS_EARNING_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const driver_add_riders_parcel = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADD_RIDERS_PARCEL_LOADING,
        data: true,
      });
      AuthService.AddRidersParcel(data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ADD_RIDERS_PARCEL_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: ADD_RIDERS_PARCEL_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const driver_get_riders_parcel = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: GET_RIDERS_PARCEL_LOADING,
        data: true,
      });
      AuthService.GetRidersParcel()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_RIDERS_PARCEL_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: GET_RIDERS_PARCEL_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const driver_edit_riders_parcel = (id, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: EDIT_RIDERS_PARCEL_LOADING,
        data: true,
      });
      AuthService.EditRidersParcel(id, data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: EDIT_RIDERS_PARCEL_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: EDIT_RIDERS_PARCEL_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const get_cod_oders = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: GET_COD_ORDERS_LOADING,
        data: true,
      });
      AuthService.GetCODOrdersData(data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_COD_ORDERS_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: GET_COD_ORDERS_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const add_cod_orders = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADD_COD_ORDERS_LOADING,
        data: true,
      });
      AuthService.AddCODOrdersData(data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ADD_COD_ORDERS_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: ADD_COD_ORDERS_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};

export const edit_cod_orders = (id, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: EDIT_COD_ORDERS_LOADING,
        data: true,
      });
      AuthService.EditCODOrdersData(id, data)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: EDIT_COD_ORDERS_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: EDIT_COD_ORDERS_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
