import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../DashBoard/Header";
import Sub_Header from "../../DashBoard/Sub_Header";
import SideBar from "../../DashBoard/SideBar";
import Footer from "../../DashBoard/Footer";
import Loader from "../../common/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import * as action from "../../../action/Driver/driver_action";

const AddVehicle = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalImgURL, setModalImgURL] = useState("");
  const [otherDocumentRow, setOtherDocumentRow] = useState([]);
  const [errors, setErrors] = useState({});
  const mobileFocus = useRef();
  const paymentTypeFocus = useRef();
  const nameFocus = useRef();
  const priceAmountFocus = useRef();
  const paymentDaysFocus = useRef();
  const addressFocus = useRef();
  const profileImgFocus = useRef();
  const aadharcardFileFocus = useRef();
  const DocumentsFocus = useRef();
  const dlFileFocus = useRef();
  const panFileFocus = useRef();
  const { loading } = useSelector((state) => state.AddDriverReduces);
  const [input, setInput] = useState({
    name: "",
    mobile: "",
    paymentType: "",
    priceAmount: "",
    paymentDays: "",
    address: "",
    profileImg: "",
    aadharCardFile: "",
    dlFile: "",
    panFile: "",
    type: 1,
  });
  const uploadProfileImageFile = (e) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file, file.name);
    dispatch(action.driver_file_upload(formData))
      .then((data) => {
        setInput({ ...input, profileImg: data.data.url });
      })
      .catch({});
  };
  const uploadAadharCardFile = (e) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file, file.name);
    dispatch(action.driver_file_upload(formData))
      .then((data) => {
        setInput({ ...input, aadharCardFile: data.data.url });
      })
      .catch({});
  };
  const uploadDlFile = (e) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file, file.name);
    dispatch(action.driver_file_upload(formData))
      .then((data) => {
        setInput({ ...input, dlFile: data.data.url });
      })
      .catch({});
  };
  const uploadPanFile = (e) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file, file.name);
    dispatch(action.driver_file_upload(formData))
      .then((data) => {
        setInput({ ...input, panFile: data.data.url });
      })
      .catch({});
  };
  const deleteExtraDocumentsInForm = (otherDocId, docUrl) => {
    if (docUrl != "") {
      const fparr = docUrl.split("/");
      const imageName = fparr[fparr.length - 1];
      const sendData = { name: imageName };
      console.log(sendData);
      dispatch(action.delete_driver_file(sendData))
        .then((data) => {})
        .catch({});
    }
    setOtherDocumentRow(
      otherDocumentRow.filter((item) => item.id !== otherDocId)
    );
  };
  const uploadOtherDocFile = (e, otherDocId) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file, file.name);
    dispatch(action.driver_file_upload(formData))
      .then((data) => {
        let oldotherDocument = otherDocumentRow.filter(
          (item) => item.id === otherDocId
        )?.[0];
        oldotherDocument = { ...oldotherDocument, docUrl: data.data.url };
        let newOtherDocument = [];
        otherDocumentRow.map((item) => {
          if (item.id === otherDocId) {
            newOtherDocument.push(oldotherDocument);
          } else newOtherDocument.push(item);
        });
        setOtherDocumentRow(newOtherDocument);
      })
      .catch({});
  };
  const addExtraDocumentsInForm = () => {
    if (otherDocumentRow.length > 0) {
      setOtherDocumentRow([
        ...otherDocumentRow,
        { id: otherDocumentRow.length + 1, docName: "", docUrl: "" },
      ]);
    } else {
      setOtherDocumentRow([{ id: 1, docName: "", docUrl: "" }]);
    }
  };
  const addExtraDocumentsValue = (e, otherDocId) => {
    let oldotherDocument = otherDocumentRow.filter(
      (item) => item.id === otherDocId
    )?.[0];
    oldotherDocument = { ...oldotherDocument, [e.target.name]: e.target.value };
    let newOtherDocument = [];
    otherDocumentRow.map((item) => {
      if (item.id === otherDocId) {
        newOtherDocument.push(oldotherDocument);
      } else newOtherDocument.push(item);
    });
    setOtherDocumentRow(newOtherDocument);
  };
  const onsubmitHandler = (e) => {
    e.preventDefault();
    setErrors({});
    let customErrors = {};
    let documentErrors = {};

    otherDocumentRow.map((item) => {
      let errors = {};
      if (item.docName === "") {
        errors = { ...errors, docName: "Please enter document name" };
      } else if (item.docUrl === "") {
        errors = { ...errors, docUrl: "Please select document url" };
      }
      if (Object.keys(errors).length > 0) {
        documentErrors[item.id] = errors;
      }
    });

    if (input.name === "") {
      customErrors = { ...customErrors, name: "Please enter your full name" };
      nameFocus.current.focus();
    } else if (!/^[6789]\d{9}$/.test(input.mobile)) {
      customErrors = {
        ...customErrors,
        mobile: "Please enter valid mobile number",
      };
      mobileFocus.current.focus();
    } else if (input.paymentType === "") {
      customErrors = {
        ...customErrors,
        paymentType: "Please select payment type",
      };
      paymentTypeFocus.current.focus();
    } else if (input.priceAmount === "") {
      customErrors = { ...customErrors, priceAmount: "Please enter price" };
      priceAmountFocus.current.focus();
    } else if (input.paymentDays === "" && input.paymentType === "1") {
      customErrors = {
        ...customErrors,
        paymentDays: "Please enter payment days",
      };
      paymentDaysFocus.current.focus();
    } else if (input.address === "") {
      customErrors = { ...customErrors, address: "Please enter address" };
      addressFocus.current.focus();
    } else if (input.profileImg === "") {
      customErrors = {
        ...customErrors,
        profileImg: "Please select profile img",
      };
      profileImgFocus.current.focus();
    } else if (input.aadharCardFile === "") {
      customErrors = {
        ...customErrors,
        aadharCardFile: "Please select aadhar file",
      };
      aadharcardFileFocus.current.focus();
    } else if (input.dlFile === "") {
      customErrors = {
        ...customErrors,
        dlFile: "Please select driving license",
      };
      dlFileFocus.current.focus();
      // } else if(input.panFile === ''){
      //     customErrors = {...customErrors, panFile : 'Please select pan card file'}
      //     panFileFocus.current.focus();
    } else if (Object.keys(documentErrors).length > 0) {
      customErrors = { ...customErrors, document: documentErrors };
      DocumentsFocus.current.focus();
    }
    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }
    let documents = [];
    otherDocumentRow.map((item) => {
      documents.push({
        docName: item.docName,
        docUrl: item.docUrl,
      });
    });
    let parameter = {
      name: input.name,
      mobile: input.mobile,
      paymentType: input.paymentType,
      priceAmount: input.priceAmount,
      profileImg: input.profileImg,
      aadharCardFile: input.aadharCardFile,
      dlFile: input.dlFile,
      panFile: input.panFile,
      address: input.address,
      type: input.type,
    };
    if (input.paymentType !== "2") {
      parameter.paymentDays = input.paymentDays;
    }
    if (documents.length > 0) {
      parameter.otherDocuments = documents;
    }

    dispatch(action.add_driver(parameter))
      .then((data) => {
        navigate("/adminside/driver");
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Sub_Header />
      <div className="main-container" id="container">
        {loading ? <Loader /> : null}
        <div className="overlay"></div>
        <div className="search-overlay"></div>
        <SideBar />
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                <div className="widget-content widget-content-area br-6">
                  <h5 className="">Add Driver</h5>
                  <br />

                  <form onSubmit={onsubmitHandler}>
                    <div id="append-div-id">
                      <div className="form-group row">
                        <div
                          className="form-group col-md-4 required"
                          id="driverTypeMain"
                        >
                          <label>Driver Type *</label>
                          <br />
                          <div
                            className="custom-control custom-radio custom-control-inline"
                            id="driverType"
                          >
                            {parseInt(input.type) === 1 ? (
                              <input
                                type="radio"
                                id="driverType1"
                                name="driverType"
                                className="custom-control-input"
                                onClick={(e) => setInput({ ...input, type: 1 })}
                                value="1"
                                defaultChecked
                              />
                            ) : (
                              <input
                                type="radio"
                                id="driverType1"
                                name="driverType"
                                className="custom-control-input"
                                onClick={(e) => setInput({ ...input, type: 1 })}
                                value="1"
                              />
                            )}
                            <label
                              className="custom-control-label status-radio"
                              htmlFor="driverType1"
                            >
                              On Role
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline">
                            {parseInt(input.type) === 0 ? (
                              <input
                                type="radio"
                                id="driverType0"
                                name="driverType"
                                className="custom-control-input"
                                value="0"
                                onClick={(e) => setInput({ ...input, type: 0 })}
                                defaultChecked
                              />
                            ) : (
                              <input
                                type="radio"
                                id="driverType0"
                                name="driverType"
                                className="custom-control-input"
                                value="0"
                                onClick={(e) => setInput({ ...input, type: 0 })}
                              />
                            )}
                            <label
                              className="custom-control-label status-radio"
                              htmlFor="driverType0"
                            >
                              Off Role
                            </label>
                          </div>
                        </div>
                        <div className="form-group col-md-4 required">
                          <label htmlFor="inputFullname">Full Name *</label>
                          <input
                            type="text"
                            className="form-control"
                            ref={nameFocus}
                            id="inputFullname"
                            name="name"
                            placeholder="Enter Full Name"
                            onChange={(e) =>
                              setInput({ ...input, name: e.target.value })
                            }
                            value={input.name}
                          />
                          <span style={{ color: "red" }}>{errors?.name}</span>
                        </div>
                        <div className="form-group col-md-4 required">
                          <label htmlFor="inputMobile">Mobile Number *</label>
                          <label
                            className="form-error-msg"
                            id="mobileErrorMsg"
                          ></label>
                          <input
                            type="text"
                            className="form-control"
                            ref={mobileFocus}
                            id="inputMobile"
                            name="mobile"
                            placeholder="Enter Mobile Number"
                            onChange={(e) =>
                              setInput({ ...input, mobile: e.target.value })
                            }
                            value={input.mobile}
                          />
                          <span style={{ color: "red" }}>{errors?.mobile}</span>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="form-group col-md-4 required">
                          <label htmlFor="inputPaymentType">
                            Select Payment Type *
                          </label>
                          <select
                            name="inputPaymentType"
                            id="inputPaymentType"
                            ref={paymentTypeFocus}
                            className="form-control selectpicker"
                            onChange={(e) =>
                              setInput({
                                ...input,
                                paymentType: e.target.value,
                              })
                            }
                            value={input.paymentType}
                          >
                            <option value="">Select Payment Type</option>
                            <option value="1">Monthly</option>
                            <option value="2">Per Parcel</option>
                          </select>
                          <span style={{ color: "red" }}>
                            {errors?.paymentType}
                          </span>
                        </div>
                        <div className="form-group col-md-4 required">
                          <label htmlFor="inputPriceAmount">
                            Price Amount *
                          </label>
                          <input
                            type="number"
                            name="priceAmount"
                            ref={priceAmountFocus}
                            className="form-control"
                            id="inputPriceAmount"
                            placeholder="Enter Price"
                            onChange={(e) =>
                              setInput({
                                ...input,
                                priceAmount: e.target.value,
                              })
                            }
                            value={input.priceAmount}
                          />
                          <span style={{ color: "red" }}>
                            {errors?.priceAmount}
                          </span>
                        </div>
                        {input.paymentType !== "2" ? (
                          <div className="form-group col-md-4 days-div required">
                            <label htmlFor="inputPaymentDays">Days *</label>
                            <input
                              type="number"
                              name="paymentDays"
                              ref={paymentDaysFocus}
                              className="form-control"
                              id="inputPaymentDays"
                              placeholder="Enter Days"
                              max="30"
                              min="0"
                              onKeyPress={(event) => {
                                return (
                                  (event.charCode >= 48 &&
                                    event.charCode <= 57) ||
                                  event.keyCode === 8
                                );
                              }}
                              onChange={(e) =>
                                setInput({
                                  ...input,
                                  paymentDays: e.target.value,
                                })
                              }
                              value={input.paymentDays}
                            />
                            <span style={{ color: "red" }}>
                              {errors?.paymentDays}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group row">
                        <div className="form-group col-md-12 required">
                          <label htmlFor="address">Address *</label>
                          <input
                            type="text"
                            name="address"
                            ref={addressFocus}
                            className="form-control"
                            id="inputaddress"
                            placeholder="Enter Address"
                            onChange={(e) =>
                              setInput({ ...input, address: e.target.value })
                            }
                            value={input.address}
                          />
                          <span style={{ color: "red" }}>
                            {errors?.address}
                          </span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="form-group col-md-12 required">
                          <label htmlFor="profileFile">Profile Picture *</label>
                          <span
                            id="addAnotherDocIdspan"
                            ref={profileImgFocus}
                            className="btn btn-warning btn-sm float-right ms-3 mb-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => addExtraDocumentsInForm()}
                          >
                            {" "}
                            Add Other Document{" "}
                          </span>
                          <input
                            accept="image/jpg,image/jpeg,image/png,image/bmp"
                            multiple=""
                            type="file"
                            name="profileFile[]"
                            className="form-control p-2"
                            id="profileFile"
                            onChange={(e) => uploadProfileImageFile(e)}
                          />
                          <span style={{ color: "red" }}>
                            {errors?.profileImg}
                          </span>
                          <br />
                          {input.profileImg !== "" ? (
                            <img
                              src={`${input.profileImg}`}
                              alt="no-image"
                              id="profileImgId"
                              className="form-group p-2 img-responsive imgFile"
                              onClick={() => setModalImgURL(input.profileImg)}
                              data-toggle="modal"
                              data-target="#showImageModalCenter"
                              style={{ width: "70px", height: "70px" }}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="form-group col-md-12 required">
                          <label htmlFor="aadharCardFile">Aadhar Card *</label>
                          <input
                            accept="image/jpg,image/jpeg,image/png,image/bmp"
                            ref={aadharcardFileFocus}
                            multiple=""
                            type="file"
                            name="aadharCardFile[]"
                            className="form-control p-2"
                            id="aadharCardFile"
                            onChange={(e) => uploadAadharCardFile(e)}
                          />
                          <span style={{ color: "red" }}>
                            {errors?.aadharCardFile}
                          </span>
                          <br />
                          {input.aadharCardFile !== "" ? (
                            <img
                              src={`${input.aadharCardFile}`}
                              alt="no-image"
                              id="aadharCardImgId"
                              className="form-group p-2 img-responsive imgFile"
                              onClick={() =>
                                setModalImgURL(input.aadharCardFile)
                              }
                              data-toggle="modal"
                              data-target="#showImageModalCenter"
                              style={{ width: "70px", height: "70px" }}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="form-group col-md-12 required">
                          <label htmlFor="dlFile">Driving License *</label>
                          <input
                            accept="image/jpg,image/jpeg,image/png,image/bmp"
                            ref={dlFileFocus}
                            multiple=""
                            type="file"
                            name="dlFile[]"
                            className="form-control p-2"
                            id="dlFile"
                            onChange={(e) => uploadDlFile(e)}
                          />
                          <span style={{ color: "red" }}>{errors?.dlFile}</span>
                          <br />
                          {input.dlFile !== "" ? (
                            <img
                              src={`${input.dlFile}`}
                              alt="no-image"
                              id="dlImgId"
                              className="form-group p-2 img-responsive imgFile"
                              onClick={() => setModalImgURL(input.dlFile)}
                              data-toggle="modal"
                              data-target="#showImageModalCenter"
                              style={{ width: "70px", height: "70px" }}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="form-group col-md-12 required">
                          <label htmlFor="panFile">Pan Card </label>
                          <input
                            accept="image/jpg,image/jpeg,image/png,image/bmp"
                            ref={panFileFocus}
                            multiple=""
                            type="file"
                            name="panFile[]"
                            className="form-control p-2"
                            id="panFile"
                            onChange={(e) => uploadPanFile(e)}
                          />
                          <span style={{ color: "red" }}>
                            {errors?.panFile}
                          </span>
                          <br />
                          {input.panFile !== "" ? (
                            <img
                              src={`${input.panFile}`}
                              alt="no-image"
                              id="panImgId"
                              className="form-group p-2 img-responsive imgFile"
                              onClick={() => setModalImgURL(input.panFile)}
                              data-toggle="modal"
                              data-target="#showImageModalCenter"
                              style={{ width: "70px", height: "70px" }}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {otherDocumentRow.map((otherDocumentItem) => (
                        <>
                          <div
                            className="form-group row"
                            id={`doc${otherDocumentItem.id}`}
                            ref={DocumentsFocus}
                          >
                            <div className="form-group col-md-4 required">
                              <label
                                htmlFor={`inputDocumentName${otherDocumentItem.id}`}
                              >
                                Document Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="docName"
                                id={`inputDocumentName${otherDocumentItem.id}`}
                                placeholder="Enter document name"
                                onChange={(e) =>
                                  addExtraDocumentsValue(
                                    e,
                                    otherDocumentItem.id
                                  )
                                }
                              />
                              <span style={{ color: "red" }}>
                                {errors.document
                                  ? errors.document[otherDocumentItem.id]
                                      ?.docName
                                    ? errors.document[otherDocumentItem.id]
                                        ?.docName
                                    : ""
                                  : ""}
                              </span>
                            </div>
                            <div className="form-group col-md-8 required">
                              <label
                                htmlFor={`otherFile${otherDocumentItem.id}`}
                              >
                                Document File{" "}
                              </label>
                              <span
                                className="badge badge-danger float-right"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  deleteExtraDocumentsInForm(
                                    otherDocumentItem.id,
                                    otherDocumentItem.docUrl
                                  )
                                }
                                id={`delethisidspan${otherDocumentItem.id}`}
                              >
                                {" "}
                                Delete This
                              </span>
                              <input
                                accept="image/jpg,image/jpeg,image/png,image/bmp"
                                multiple=""
                                type="file"
                                className="form-control p-2"
                                id={`otherFile${otherDocumentItem.id}`}
                                onChange={(e) =>
                                  uploadOtherDocFile(e, otherDocumentItem.id)
                                }
                              />
                              <span style={{ color: "red" }}>
                                {errors.document
                                  ? errors.document[otherDocumentItem.id]
                                      ?.docUrl
                                    ? errors.document[otherDocumentItem.id]
                                        ?.docUrl
                                    : ""
                                  : ""}
                              </span>
                              <br />
                              {otherDocumentItem.docUrl !== "" ? (
                                <img
                                  src={`${otherDocumentItem.docUrl}`}
                                  alt="no-image"
                                  id={`otherImgId${otherDocumentItem.id}`}
                                  className="form-group p-2 img-responsive imgFile"
                                  onClick={() =>
                                    setModalImgURL(otherDocumentItem.docUrl)
                                  }
                                  data-toggle="modal"
                                  data-target="#showImageModalCenter"
                                  style={{ width: "70px", height: "70px" }}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                      <div
                        className="form-group required"
                        id="driverStatusMain"
                      >
                        <label>Driver Status *</label>
                        <div
                          className="custom-control custom-radio custom-control-inline"
                          id="driverStatus"
                        >
                          <input
                            type="radio"
                            id="status1"
                            name="driverStatus"
                            className="custom-control-input"
                            value="1"
                            disabled
                            defaultChecked
                          />
                          <label
                            className="custom-control-label status-radio"
                            htmlFor="status1"
                          >
                            Active
                          </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="status0"
                            name="driverStatus"
                            className="custom-control-input"
                            value="0"
                            disabled
                          />
                          <label
                            className="custom-control-label status-radio"
                            htmlFor="status0"
                          >
                            Deactive
                          </label>
                        </div>
                      </div>
                      <div
                        className="modal fade"
                        id="showImageModalCenter"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="showImageModalCenterLabel"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered modal-lg"
                          role="img"
                        >
                          <div className="modal-content">
                            <div className="modal-body">
                              {modalImgURL !== "" ? (
                                <img
                                  src={`${modalImgURL}`}
                                  alt="no-image"
                                  id="modalImgId"
                                  className="img-responsive"
                                  style={{ width: "100%", height: "100%" }}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button type="reset" className="btn btn-danger mt-3">
                      Reset
                    </button>
                    &nbsp;
                    <button
                      type="submit"
                      className="btn btn-success mt-3 view-btn"
                    >
                      Add
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </>
  );
};
export default AddVehicle;
