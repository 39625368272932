import React, { useEffect, useState } from "react";
import Select from "react-select";
import SwiggyChart from "./components/SwiggyChart/SwiggyChart";
import ZomatoChart from "./components/ZomatoChart/ZomatoChart";
import BigBasketChart from "./components/BigBasketChart/BigBasketChart";
import BBNowChart from "./components/BBNowChart/BBNowChart";
import FlipkartChart from "./components/FlipkartChart/FlipkartChart";
import BlueDartChart from "./components/BlueDartChart/BlueDartChart";

const BillingAnalysis = ({ cityList }) => {
  // months List
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  //current Year
  const currentYear = {
    value: new Date().getFullYear(),
    label: new Date().getFullYear(),
  };

  const [selectedCityYear, setSelectedCityYear] = useState({
    city: [],
    year: {
      value: new Date().getFullYear(),
      label: new Date().getFullYear(),
    },
  });

  const [cityYearFilterData, setCityYearFilterData] = useState({
    cityArray: [],
    year: new Date().getFullYear(),
  });

  useEffect(() => {
    const createCityArray = () => {
      let cities = selectedCityYear?.city;
      let year = selectedCityYear?.year?.value;

      const citiesArray = cities?.map((item) => {
        return item.value;
      });

      setCityYearFilterData({
        ...cityYearFilterData,
        cityArray: JSON.stringify(citiesArray),
        year: JSON.stringify(year),
      });
    };
    createCityArray();
  }, [selectedCityYear]);

  return (
    <div className=" col-12 layout-spacing">
      <div className="widget widget-chart-one">
        <div className="row">
          <h5 className="col">Billing Analysis</h5>
          <ul className="col row list-unstyled">
            <li className="col">
              <label>City</label>
              <Select
                isMulti
                name="city"
                styles={{ width: 200 }}
                value={selectedCityYear.city}
                onChange={(value) =>
                  setSelectedCityYear({
                    ...selectedCityYear,
                    city: value,
                  })
                }
                options={cityList}
              />
            </li>
            <li className="mr-5 ml-2 col">
              <label>Year</label>
              <Select
                id="inputYear"
                defaultValue={currentYear}
                value={selectedCityYear.year}
                onChange={(value) =>
                  setSelectedCityYear({
                    ...selectedCityYear,
                    year: value,
                  })
                }
                options={[
                  {
                    value: 2023,
                    label: "2023",
                  },
                  {
                    value: 2022,
                    label: "2022",
                  },
                  {
                    value: 2021,
                    label: "2021",
                  },
                ]}
              />
            </li>
          </ul>
        </div>
        <ul className="nav nav-tabs  " id="simpletab" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              id="swiggy-tab"
              data-toggle="tab"
              href="#swiggy"
              role="tab"
              aria-controls="swiggy"
              aria-selected="true"
            >
              Swiggy
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="zomato-tab"
              data-toggle="tab"
              href="#zomato"
              role="tab"
              aria-controls="zomato"
              aria-selected="false"
            >
              Zomato
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="bigBasket-tab"
              data-toggle="tab"
              href="#bigBasket"
              role="tab"
              aria-controls="bigBasket"
              aria-selected="false"
            >
              BigBasket
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="bbNow-tab"
              data-toggle="tab"
              href="#bbNow"
              role="tab"
              aria-controls="bbNow"
              aria-selected="false"
            >
              BB Now
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="flipkart-tab"
              data-toggle="tab"
              href="#flipkart"
              role="tab"
              aria-controls="flipkart"
              aria-selected="false"
            >
              Flipkart
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="blueDart-tab"
              data-toggle="tab"
              href="#blueDart"
              role="tab"
              aria-controls="blueDart"
              aria-selected="false"
            >
              BlueDart
            </a>
          </li>
        </ul>
        <div className="tab-content" id="simpletabContent">
          {/* swiggy tab */}
          <div
            className="tab-pane fade show active"
            id="swiggy"
            role="tabpanel"
            aria-labelledby="swiggy-tab"
          >
            <SwiggyChart
              monthNames={monthNames}
              cityYearFilterData={cityYearFilterData}
            />
          </div>
          {/* zomato tab */}
          <div
            className="tab-pane "
            id="zomato"
            role="tabpanel"
            aria-labelledby="zomato-tab"
          >
            <ZomatoChart
              monthNames={monthNames}
              cityYearFilterData={cityYearFilterData}
            />
          </div>
          {/* big basket tab */}
          <div
            className="tab-pane "
            id="bigBasket"
            role="tabpanel"
            aria-labelledby="bigBasket-tab"
          >
            <BigBasketChart
              monthNames={monthNames}
              cityYearFilterData={cityYearFilterData}
            />
          </div>
          {/* BB Now tab */}
          <div
            className="tab-pane "
            id="bbNow"
            role="tabpanel"
            aria-labelledby="bbNow-tab"
          >
            <BBNowChart
              monthNames={monthNames}
              cityYearFilterData={cityYearFilterData}
            />
          </div>
          {/* Flipkart Now tab */}
          <div
            className="tab-pane "
            id="flipkart"
            role="tabpanel"
            aria-labelledby="flipkart-tab"
          >
            <FlipkartChart
              monthNames={monthNames}
              cityYearFilterData={cityYearFilterData}
            />
          </div>
          {/* BlueDart Now tab */}
          <div
            className="tab-pane "
            id="blueDart"
            role="tabpanel"
            aria-labelledby="blueDart-tab"
          >
            <BlueDartChart
              monthNames={monthNames}
              cityYearFilterData={cityYearFilterData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingAnalysis;
