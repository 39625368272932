import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import Chart from "react-apexcharts";
import { Input } from "rsuite";
import * as action from "../../../../action/Dashboard/dashboard_action";

const RidersParcelAndEarning = ({ customerList, cityList }) => {
  const dispatch = useDispatch();

  const upperLimitOptions = [
    {
      value: 3000,
      label: "₹0-₹3000",
    },
    {
      value: 6000,
      label: "₹3001-₹6000",
    },
    {
      value: 9000,
      label: "₹6001-₹9000",
    },
    {
      value: 12000,
      label: "₹9001-₹12000",
    },
    {
      value: 15000,
      label: "₹12001-₹15000",
    },
    {
      value: 18000,
      label: "₹15001-₹18000",
    },
    {
      value: 21000,
      label: "₹18001-₹21000",
    },
    {
      value: 24000,
      label: "₹21001-₹24000",
    },
    {
      value: 27000,
      label: "₹24001-₹27000",
    },
    {
      value: 30000,
      label: "₹27001-₹30000",
    },
  ];

  // riders parcel and earning
  const [ridersParcelDeliveryData, setridersParcelDeliveryData] = useState({
    fullTimeRiders: [],
    partTimeRiders: [],
    customerList: [],
  });

  const [
    selectedridersParcelDeliveryData,
    setSelectedridersParcelDeliveryData,
  ] = useState({
    yearAndMonth: "",
    city: [],
  });

  const [ridersParcelFilter, setRidersParcelFilter] = useState({
    yearAndMonth: JSON.stringify(""),
    city: JSON.stringify([]),
  });

  const [ridersEarningData, setridersEarningData] = useState({
    fullTimeRiders: [],
    partTimeRiders: [],
    paymentSLab: [],
  });

  const [selectedridersEarningData, setSelectedridersEarningData] = useState({
    yearAndMonth: "",
    customer: [],
    city: [],
  });

  const [ridersEarningFilter, setRidersEarningFilter] = useState({
    yearAndMonth: JSON.stringify(""),
    city: JSON.stringify([]),
    customer: JSON.stringify([]),
  });

  // riders parcels delivery data - full time and part time
  useEffect(() => {
    dispatch(action.dashboard_riders_parcel_delivery_chart(ridersParcelFilter))
      .then((data) => {
        let parcelData = data.data;
        let fullTimeRiders = [];
        let partTimeRiders = [];
        let customerList = [];

        parcelData.map((item) => {
          fullTimeRiders.push(item?.fulltimeParcels);
          partTimeRiders.push(item?.partTimeParcels);
          customerList.push(item?.customerName);
        });

        setridersParcelDeliveryData({
          fullTimeRiders: fullTimeRiders,
          partTimeRiders: partTimeRiders,
          customerList: customerList,
        });
      })
      .catch((error) => {});
  }, [ridersParcelFilter]);

  // riders earning data - full time and part time
  useEffect(() => {
    dispatch(action.dashboard_riders_earning_chart(ridersEarningFilter))
      .then((data) => {
        let earningData = data.data;
        let fullTimeRiders = [];
        let partTimeRiders = [];
        let paymentSlab = [];

        const earningSortedData = earningData.sort(
          (a, b) => a.lowerLimit - b.lowerLimit
        );

        earningSortedData.map((item) => {
          fullTimeRiders.push(item.fullTimeRiders);
          partTimeRiders.push(item.partTimeRiders);
          const payment = upperLimitOptions.find(
            (element) => element.value === item.upperLimit
          );
          paymentSlab.push(payment.label);
        });

        setridersEarningData({
          fullTimeRiders: fullTimeRiders,
          partTimeRiders: partTimeRiders,
          paymentSLab: paymentSlab,
        });
      })
      .catch((error) => {});
  }, [ridersEarningFilter]);

  // set driver wise parcel filter data
  useEffect(() => {
    let city = [];
    let yearAndMonth = "";

    yearAndMonth = selectedridersParcelDeliveryData.yearAndMonth;
    if (selectedridersParcelDeliveryData.city?.length > 0) {
      for (let i = 0; i < selectedridersParcelDeliveryData.city?.length; i++) {
        city.push(selectedridersParcelDeliveryData.city[i].value);
      }
    }

    setRidersParcelFilter({
      ...ridersParcelFilter,
      city: JSON.stringify(city),
      yearAndMonth: JSON.stringify(yearAndMonth),
    });
  }, [selectedridersParcelDeliveryData]);

  // set driver wise earning filter data
  useEffect(() => {
    let city = [];
    let yearAndMonth = "";
    let customer = [];

    yearAndMonth = selectedridersEarningData.yearAndMonth;

    if (selectedridersEarningData.city.length > 0) {
      for (let i = 0; i < selectedridersEarningData.city.length; i++) {
        city.push(selectedridersEarningData.city[i].value);
      }
    }

    if (selectedridersEarningData.customer.length > 0) {
      for (let i = 0; i < selectedridersEarningData.customer.length; i++) {
        customer.push(selectedridersEarningData.customer[i].value);
      }
    }

    setRidersEarningFilter({
      ...ridersEarningFilter,
      city: JSON.stringify(city),
      yearAndMonth: JSON.stringify(yearAndMonth),
      customer: JSON.stringify(customer),
    });
  }, [selectedridersEarningData]);

  //  rider - parcelDeliver
  var ridersparcelDeliver = {
    chart: {
      width: "100%",
      height: 250,
      type: "bar",
      fontFamily: "Apple-System, sans-serif",
    },
    colors: ["#E97777", "#F8C4B4"],
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },

    title: {
      text: "Parcel Deliverey",
      align: "center",
      margin: 10,
      offsetX: -10,
      offsetY: 10,
      floating: false,
      style: {
        fontSize: "20px",
        color: "#0e1726",
      },
    },
    series: [
      {
        name: "Full Time Riders",
        data: ridersParcelDeliveryData.fullTimeRiders,
      },
      {
        name: "Part Time Riders",
        data: ridersParcelDeliveryData.partTimeRiders,
      },
    ],
    xaxis: {
      categories: ridersParcelDeliveryData.customerList,
    },
    legend: {
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 1000,
        yaxis: {
          categories: ridersParcelDeliveryData.customerList,
        },
        options: {
          plotOptions: {
            bar: {
              horizontal: true,
              columnWidth: "80%",
            },
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  //  rider - earning
  var ridersEarning = {
    chart: {
      width: "100%",
      height: 250,
      type: "bar",
      fontFamily: "Apple-System, sans-serif",
    },
    colors: ["#0F4C75", "#00B7C2"],
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },

    title: {
      text: "Earning Scale",
      align: "center",
      margin: 10,
      offsetX: -10,
      offsetY: 10,
      floating: false,
      style: {
        fontSize: "20px",
        color: "#0e1726",
      },
    },
    series: [
      {
        name: "Full Time Riders",
        data: ridersEarningData.fullTimeRiders,
      },
      {
        name: "Part Time Riders",
        data: ridersEarningData.partTimeRiders,
      },
    ],
    xaxis: {
      categories: ridersEarningData.paymentSLab,
    },
    legend: {
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 1000,
        yaxis: {
          categories: ridersEarningData.paymentSLab,
        },
        options: {
          plotOptions: {
            bar: {
              horizontal: true,
              columnWidth: "80%",
            },
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div className="widget widget-chart-one">
      <div className="widget-heading">
        <h5 className="">
          Rider Analysis - Full Time Riders vs Part Time Riders
        </h5>
        <ul className="tabs tab-pills">
          <li>
            <a id="tb_1" className="tabmenu">
              Daily
            </a>
          </li>
        </ul>
      </div>
      <div className="row">
        <div className="col" id="chart">
          <ul className=" row list-unstyled">
            <li className="mx-2 col">
              <label>City</label>
              <Select
                isMulti
                name="city"
                value={selectedridersParcelDeliveryData.city}
                onChange={(value) =>
                  setSelectedridersParcelDeliveryData({
                    ...selectedridersParcelDeliveryData,
                    city: value,
                  })
                }
                options={cityList}
              />
            </li>
            <li className="col">
              <label>Year-Month</label>
              <Input
                type="month"
                name="yearMonth"
                value={selectedridersParcelDeliveryData.yearAndMonth}
                onChange={(value) =>
                  setSelectedridersParcelDeliveryData({
                    ...selectedridersParcelDeliveryData,
                    yearAndMonth: value,
                  })
                }
              />
            </li>
          </ul>
          {ridersParcelDeliveryData.fullTimeRiders.length > 0 ||
          ridersParcelDeliveryData.partTimeRiders.length > 0 ? (
            <Chart
              options={ridersparcelDeliver}
              type="bar"
              series={ridersparcelDeliver.series}
              height={250}
            />
          ) : (
            <p className="text-center">No Data Found</p>
          )}
        </div>

        <div className="w-100"></div>

        <div className="col" id="chart">
          <ul className=" row list-unstyled">
            <li className="mx-2 col">
              <label>Customers</label>
              <Select
                isMulti
                name="customers"
                value={selectedridersEarningData.customer}
                onChange={(value) =>
                  setSelectedridersEarningData({
                    ...selectedridersEarningData,
                    customer: value,
                  })
                }
                options={customerList}
              />
            </li>
            <li className="mx-2 col">
              <label>City</label>
              <Select
                isMulti
                name="city"
                value={selectedridersEarningData.city}
                onChange={(value) =>
                  setSelectedridersEarningData({
                    ...selectedridersEarningData,
                    city: value,
                  })
                }
                options={cityList}
              />
            </li>
            <li className=" col">
              <label>Year-Month</label>
              <Input
                type="month"
                name="yearMonth"
                value={selectedridersEarningData.yearAndMonth}
                onChange={(value) =>
                  setSelectedridersEarningData({
                    ...selectedridersEarningData,
                    yearAndMonth: value,
                  })
                }
              />
            </li>
          </ul>
          {ridersEarningData.fullTimeRiders.length > 0 ||
          ridersEarningData.partTimeRiders.length > 0 ? (
            <Chart
              options={ridersEarning}
              type="bar"
              series={ridersEarning.series}
              height={250}
            />
          ) : (
            <p className="text-center">No Data Found</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RidersParcelAndEarning;
