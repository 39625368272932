import {
  CUSTOMER_LIST_SUCCESSFULL,
  CUSTOMER_LIST_ERROR,
  CUSTOMER_LIST_LOADING,
  CUSTOMER_DETAILS_SUCCESSFULL,
  CUSTOMER_DETAILS_ERROR,
  CUSTOMER_DETAILS_LOADING,
  ADDRESS_LIST_SUCCESSFULL,
  ADDRESS_LIST_ERROR,
  ADDRESS_LIST_LOADING,
  SHIFT_LIST_SUCCESSFULL,
  SHIFT_LIST_ERROR,
  SHIFT_LIST_LOADING,
  CUSTOMER_EDIT_SUCCESSFULL,
  CUSTOMER_EDIT_ERROR,
  CUSTOMER_EDIT_LOADING,
  CUSTOMER_ADD_ADDRESS_SUCCESSFULL,
  CUSTOMER_ADD_ADDRESS_ERROR,
  CUSTOMER_ADD_ADDRESS_LOADING,
  CUSTOMER_ADD_SHIFT_SUCCESSFULL,
  CUSTOMER_ADD_SHIFT_ERROR,
  CUSTOMER_ADD_SHIFT_LOADING,
  CUSTOMER_STATUS_CHANGE_SUCCESSFULL,
  CUSTOMER_STATUS_CHANGE_ERROR,
  CUSTOMER_STATUS_CHANGE_LOADING,
  ADDRESS_STATUS_CHANGE_SUCCESSFULL,
  ADDRESS_STATUS_CHANGE_ERROR,
  ADDRESS_STATUS_CHANGE_LOADING,
  SHIFT_STATUS_CHANGE_SUCCESSFULL,
  SHIFT_STATUS_CHANGE_ERROR,
  SHIFT_STATUS_CHANGE_LOADING,
  CUSTOMER_EDIT_SHIFT_SUCCESSFULL,
  CUSTOMER_EDIT_SHIFT_ERROR,
  CUSTOMER_EDIT_SHIFT_LOADING,
  CUSTOMER_EDIT_ADDRESS_SUCCESSFULL,
  CUSTOMER_EDIT_ADDRESS_ERROR,
  CUSTOMER_EDIT_ADDRESS_LOADING,
  CUSTOMER_GENERATE_INVOICE_SUCCESSFULL,
  CUSTOMER_GENERATE_INVOICE_ERROR,
  CUSTOMER_GENERATE_INVOICE_LOADING,
  CUSTOMER_LATEST_INVOICE_NO_SUCCESSFULL,
  CUSTOMER_LATEST_INVOICE_NO_ERROR,
  CUSTOMER_LATEST_INVOICE_NO_LOADING,
  UPLOAD_CUSTOMER_INVOICE_LOADING,
  UPLOAD_CUSTOMER_INVOICE_SUCCESSFULL,
  UPLOAD_CUSTOMER_INVOICE_ERROR,
  CUSTOMER_INVOICE_ADDED_LOADING,
  CUSTOMER_INVOICE_ADDED_SUCCESSFULL,
  CUSTOMER_INVOICE_ADDED_ERROR,
  CUSTOMER_INVOICE_LIST_SUCCESSFULL,
  CUSTOMER_INVOICE_LIST_ERROR,
  CUSTOMER_INVOICE_LIST_LOADING,
  DELETE_CUSTOMER_INVOICE_SUCCESSFULL,
  DELETE_CUSTOMER_INVOICE_ERROR,
  DELETE_CUSTOMER_INVOICE_LOADING,
} from "../../action/type";

import * as AuthService from "../../service/services";

export const customer_list = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: CUSTOMER_LIST_LOADING,
        data: true,
      });
      AuthService.CustomerList()
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: CUSTOMER_LIST_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: CUSTOMER_LIST_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const customer_details = (cust_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: CUSTOMER_DETAILS_LOADING,
        data: true,
      });
      AuthService.CustomerDetails(cust_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: CUSTOMER_DETAILS_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: CUSTOMER_DETAILS_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const address_list = (cust_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADDRESS_LIST_LOADING,
        data: true,
      });
      AuthService.AddressList(cust_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ADDRESS_LIST_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: ADDRESS_LIST_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const shift_list = (cust_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SHIFT_LIST_LOADING,
        data: true,
      });
      AuthService.ShiftList(cust_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: SHIFT_LIST_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: SHIFT_LIST_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const edit_customer = (credentail) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: CUSTOMER_EDIT_LOADING,
        data: true,
      });
      AuthService.EditCustomerList(credentail)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: CUSTOMER_EDIT_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: CUSTOMER_EDIT_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const add_customer_address = (credentail) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: CUSTOMER_ADD_ADDRESS_LOADING,
        data: true,
      });
      AuthService.AddCustomerAddress(credentail)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: CUSTOMER_ADD_ADDRESS_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: CUSTOMER_ADD_ADDRESS_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const add_customer_shift = (credentail) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: CUSTOMER_ADD_SHIFT_LOADING,
        data: true,
      });
      AuthService.AddCustomerShift(credentail)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: CUSTOMER_ADD_SHIFT_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: CUSTOMER_ADD_SHIFT_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const customer_status_change = (cust_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: CUSTOMER_STATUS_CHANGE_LOADING,
        data: true,
      });
      AuthService.CustomerStatusChange(cust_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: CUSTOMER_STATUS_CHANGE_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: CUSTOMER_STATUS_CHANGE_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const address_status_change = (address_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADDRESS_STATUS_CHANGE_LOADING,
        data: true,
      });
      AuthService.AddressStatusChange(address_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ADDRESS_STATUS_CHANGE_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: ADDRESS_STATUS_CHANGE_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const shift_status_change = (shift_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SHIFT_STATUS_CHANGE_LOADING,
        data: true,
      });
      AuthService.ShiftStatusChange(shift_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: SHIFT_STATUS_CHANGE_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: SHIFT_STATUS_CHANGE_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const edit_customer_shift = (credentail) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: CUSTOMER_EDIT_SHIFT_LOADING,
        data: true,
      });
      AuthService.EditCustomerShift(credentail)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: CUSTOMER_EDIT_SHIFT_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: CUSTOMER_EDIT_SHIFT_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const edit_customer_address = (credentail) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: CUSTOMER_EDIT_ADDRESS_LOADING,
        data: true,
      });
      AuthService.EditCustomerAddress(credentail)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: CUSTOMER_EDIT_ADDRESS_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: CUSTOMER_EDIT_ADDRESS_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const generate_customer_invoice = (cust_id, start_date, end_date) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: CUSTOMER_GENERATE_INVOICE_LOADING,
        data: true,
      });
      AuthService.GenerateCustomerInvoice(cust_id, start_date, end_date)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: CUSTOMER_GENERATE_INVOICE_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: CUSTOMER_GENERATE_INVOICE_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const customer_latest_invoice_no = (cust_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: CUSTOMER_LATEST_INVOICE_NO_LOADING,
        data: true,
      });
      AuthService.CustomerLetestInvoiceNo(cust_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: CUSTOMER_LATEST_INVOICE_NO_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: CUSTOMER_LATEST_INVOICE_NO_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const upload_customer_invoice = (invoicefile) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: UPLOAD_CUSTOMER_INVOICE_LOADING,
        data: true,
      });
      AuthService.uploadCustomerInvoice(invoicefile)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: UPLOAD_CUSTOMER_INVOICE_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: UPLOAD_CUSTOMER_INVOICE_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const add_customer_invoice = (customerId, customerInvoiceData) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: CUSTOMER_INVOICE_ADDED_LOADING,
        data: true,
      });
      AuthService.addCustomerInvoice(customerId, customerInvoiceData)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: CUSTOMER_INVOICE_ADDED_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: CUSTOMER_INVOICE_ADDED_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const customer_invoice_list = (cust_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: CUSTOMER_INVOICE_LIST_LOADING,
        data: true,
      });
      AuthService.CustomerInvoiceList(cust_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: CUSTOMER_INVOICE_LIST_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: CUSTOMER_INVOICE_LIST_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
export const delete_customer_invoice = (invoice_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DELETE_CUSTOMER_INVOICE_LOADING,
        data: true,
      });
      AuthService.DeleteCustomerInvoice(invoice_id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DELETE_CUSTOMER_INVOICE_SUCCESSFULL,
              data: response.data,
            });
            return resolve(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: DELETE_CUSTOMER_INVOICE_ERROR,
              data: { error_msg: error.response.data.msg },
            });
          }
          return reject(error.response.data.msg);
        });
    });
  };
};
