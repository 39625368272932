import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useDispatch } from "react-redux";
import Select from "react-select";
import * as action from "../../../../../../action/Dashboard/dashboard_action";

const ParcelFullTime = ({ monthNames, cityList }) => {
  const dispatch = useDispatch();
  //current Year
  const currentYear = {
    value: new Date().getFullYear(),
    label: new Date().getFullYear(),
  };
  const [selectedFullTimeParcelCityYear, setSelectedFullTimeParcelCityYear] =
    useState({
      city: [],
      year: {
        value: new Date().getFullYear(),
        label: new Date().getFullYear(),
      },
    });

  const [
    cityYearFullTimeParcelFilterData,
    setCityYearFullTimeParcelFilterData,
  ] = useState({
    cityArray: [],
    year: new Date().getFullYear(),
  });

  const [fullTimeParcelData, setFullTimeParcelData] = useState({
    clients: [],
    colorCode: [],
  });

  useEffect(() => {
    const createCityArray = () => {
      let cities = selectedFullTimeParcelCityYear?.city;
      let year = selectedFullTimeParcelCityYear?.year?.value;

      const citiesArray = cities?.map((item) => {
        return item.value;
      });

      setCityYearFullTimeParcelFilterData({
        ...cityYearFullTimeParcelFilterData,
        cityArray: JSON.stringify(citiesArray),
        year: JSON.stringify(year),
      });
    };
    createCityArray();
  }, [selectedFullTimeParcelCityYear]);

  useEffect(() => {
    dispatch(
      action.dashboard_fulltimeparcel_chart(
        cityYearFullTimeParcelFilterData.year,
        cityYearFullTimeParcelFilterData.cityArray
      )
    )
      .then((data) => {
        let fullTimeData = data.data;
        let colorCodeData = data.colorCode;
        let colorCode = [];

        // colorCodeData.sort((a, b) =>
        //   a.name.localeCompare(b.name, "en", { sensitivity: "base" })
        // );

        colorCodeData.map((item) => {
          colorCode.push(item.colorCode);
        });

        const seriesData = fullTimeData.map((item) => {
          let customerName = item._id.customerName;

          let parcelData = item?.data?.map((data) => {
            return data.parcels;
          });

          return {
            name: customerName,
            data: parcelData,
          };
        });

        setFullTimeParcelData({
          ...fullTimeParcelData,
          clients: seriesData,
          colorCode: colorCode,
        });
      })
      .catch({});
  }, [cityYearFullTimeParcelFilterData]);

  //  full time rider - no of parcelDelivery
  var fullTimeRidersParcelDeliver = {
    chart: {
      height: 300,
      type: "line",
      fontFamily: "Apple-System, sans-serif",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: fullTimeParcelData.colorCode,
    title: {
      text: "Parcel Delivered By Full Time Riders",
      align: "center",
      margin: 10,
      offsetX: -10,
      offsetY: 10,
      floating: false,
      style: {
        fontSize: "20px",
        color: "#0e1726",
      },
    },
    stroke: {
      curve: "straight",
    },
    series: fullTimeParcelData.clients,
    xaxis: {
      categories: monthNames,
    },
    legend: {
      position: "bottom",
    },
  };

  return (
    <div className="col" id="chart">
      <ul className=" row list-unstyled">
        <li className="col">
          <label>City</label>
          <Select
            isMulti
            name="city"
            style={{ width: 200 }}
            value={selectedFullTimeParcelCityYear.city}
            onChange={(value) =>
              setSelectedFullTimeParcelCityYear({
                ...selectedFullTimeParcelCityYear,
                city: value,
              })
            }
            options={cityList}
          />
        </li>
        <li className="mr-5 ml-2 col">
          <label>Year</label>
          <Select
            id="inputYear"
            defaultValue={currentYear}
            value={selectedFullTimeParcelCityYear.year}
            onChange={(value) =>
              setSelectedFullTimeParcelCityYear({
                ...selectedFullTimeParcelCityYear,
                year: value,
              })
            }
            options={[
              {
                value: 2023,
                label: "2023",
              },
              {
                value: 2022,
                label: "2022",
              },
              {
                value: 2021,
                label: "2021",
              },
            ]}
          />
        </li>
      </ul>
      <Chart
        options={fullTimeRidersParcelDeliver}
        type="line"
        series={fullTimeRidersParcelDeliver.series}
        height={300}
      />
    </div>
  );
};

export default ParcelFullTime;
