import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import Chart from "react-apexcharts";
import { Input } from "rsuite";
import * as action from "../../../../action/Dashboard/dashboard_action";
import {
  TbCircleArrowDownRightFilled,
  TbCircleArrowUpRightFilled,
} from "react-icons/tb";

const ClientPerformance = ({ cityList, customerList }) => {
  const dispatch = useDispatch();

  // months List
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // months list
  const months = [
    {
      value: 1,
      label: "January",
    },
    {
      value: 2,
      label: "February",
    },
    {
      value: 3,
      label: "March",
    },
    {
      value: 4,
      label: "April",
    },
    {
      value: 5,
      label: "May",
    },
    {
      value: 6,
      label: "June",
    },
    {
      value: 7,
      label: "July",
    },
    {
      value: 8,
      label: "August",
    },
    {
      value: 9,
      label: "September",
    },
    {
      value: 10,
      label: "October",
    },
    {
      value: 11,
      label: "November",
    },
    {
      value: 12,
      label: "December",
    },
  ];

  // client performance
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [clientRevenuePayoutsData, setClientRevenuePayoutsData] = useState({
    revenue: [],
    payouts: [],
    cities: [],
  });

  const [clientPeformanceFilter, setClientPeformanceFilter] = useState({
    city: JSON.stringify([]),
    customer: JSON.stringify([]),
  });
  const [selectedCientPerFormanceYear, setSelectedClientPerFormanceYear] =
    useState({
      value: 2023,
      label: "2023",
    });
  const [lastMonthValue, setLastMonthValue] = useState(new Date().getMonth());
  const [lastQuarterMonth, setLastQuarterMonth] = useState({
    start: 0,
    end: 0,
    year: 0,
  });

  const [quarterData, setQuarterData] = useState({
    months: [],
    revenue: [],
    totalRevenueForQuarter: 0,
    growthPercentage: "",
  });

  const [lastMonthData, setLastMonthData] = useState({
    growthPercentage: 0,
    year: 0,
    revenue: 0,
  });

  // rupee display to indian number system
  function numberWithCommas(x) {
    return x.toString().split(".")[0].length > 3
      ? x
          .toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  }

  // client performance -revenue and payouts
  useEffect(() => {
    dispatch(
      action.dashboard_client_performance(
        selectedCientPerFormanceYear.value,
        clientPeformanceFilter
      )
    )
      .then((data) => {
        let performance = data?.data?.performanceData[0];
        let citiesData = data?.data?.cityList;
        let cities = [];
        // let performaceUpdateData = [];
        let revenues = performance?.revenue;
        let payouts = performance?.payout;

        citiesData.map((item) => {
          cities.push({
            value: item.cityDetails._id,
            label: item.cityDetails.cityName,
          });
        });

        // let i = 0;
        // for (let j = 1; j <= 12; j++) {
        //   if (performanceData[i] && performanceData[i].month === j) {
        //     performaceUpdateData.push({
        //       month: performanceData[i].month,
        //       payout: performanceData[i].payout,
        //       revenue: performanceData[i].revenue,
        //     });
        //     i++;
        //   } else {
        //     performaceUpdateData.push({
        //       month: j,
        //       payout: 0,
        //       revenue: 0,
        //     });
        //   }
        // }

        // for (let p = 0; p < performaceUpdateData.length; p++) {
        //   let k = 0;
        //   let m = 0;
        //   var sizepayout = 12 - performaceUpdateData[p].payout.length;
        //   var sizerevenue = 12 - performaceUpdateData[p].revenue.length;

        //   while (k < sizepayout) {
        //     performaceUpdateData[p].payout.push(0);
        //     k++;
        //   }

        //   while (m < sizerevenue) {
        //     performaceUpdateData[p].revenue.push(0);
        //     m++;
        //   }
        // }

        // performaceUpdateData.map((item) => {
        //   payouts.push(item.payout.toFixed());
        //   revenues.push(item.revenue.toFixed());
        // });

        setClientRevenuePayoutsData({
          revenue: revenues,
          payouts: payouts,
          cities: cities,
        });
      })
      .catch({});
  }, [selectedCientPerFormanceYear, clientPeformanceFilter]);

  // client sales - last month, last quarter
  useEffect(() => {
    dispatch(action.dashboard_lastMonth_Sales())
      .then((data) => {
        let lastMonthData = data.data;
        let growthPercentage = lastMonthData.growth;
        let revenueDataForLastMonth = lastMonthData.revenueData;

        setLastMonthData({
          ...lastMonthData,
          year: revenueDataForLastMonth.year,
          growthPercentage: growthPercentage,
          revenue: revenueDataForLastMonth.revenue,
        });
      })
      .catch({});

    dispatch(
      action.dashboard_lastQuarter_Sales(
        lastQuarterMonth.year,
        lastQuarterMonth.start,
        lastQuarterMonth.end
      )
    )
      .then((data) => {
        let quarterData = data.data;
        let monthsName = [];
        let revenue = [];
        let totalRevenueForQuarter = 0;

        quarterData.map((item) => {
          monthsName.push(months[item.month - 1].label);
          revenue.push(item.revenue);
          totalRevenueForQuarter += item.revenue;
        });

        setQuarterData({
          ...quarterData,
          revenue: revenue,
          months: monthsName,
          totalRevenueForQuarter: totalRevenueForQuarter,
          growthPercentage: 20,
        });
      })
      .catch({});
  }, [lastMonthValue, lastQuarterMonth]);

  // caculate quarter
  useEffect(() => {
    const calculateMonthandQuarter = () => {
      // Get the current date
      const currentDate = new Date();
      // Get the current month (0-11, where 0 is January)

      // Get the current year
      let currentYear = currentDate.getFullYear();

      let currentMonth = currentDate.getMonth();

      setLastMonthValue(currentMonth);
      // Calculate the starting month of the last quarter
      let lastQuarterStartMonth = (Math.floor(currentMonth / 3) - 1) * 3 + 1;

      // Adjust the starting month if it falls in the previous year
      if (lastQuarterStartMonth < 0) {
        lastQuarterStartMonth += 12;
        currentYear--;
      }

      // Calculate the ending month of the last quarter
      let lastQuarterEndMonth = lastQuarterStartMonth + 2;

      // Adjust the ending month if it falls in the previous year
      if (lastQuarterEndMonth < 0) {
        lastQuarterEndMonth += 12;
      }
      setLastQuarterMonth({
        ...lastQuarterMonth,
        start: lastQuarterStartMonth,
        end: lastQuarterEndMonth,
        year: currentYear,
      });
    };

    calculateMonthandQuarter();
  }, []);

  // set driver wise parcel filter data
  useEffect(() => {
    let city = [];
    let customer = [];

    if (selectedCity?.length > 0) {
      for (let i = 0; i < selectedCity?.length; i++) {
        city.push(selectedCity[i].value);
      }
    }

    if (selectedCustomers?.length > 0) {
      for (let i = 0; i < selectedCustomers?.length; i++) {
        customer.push(selectedCustomers[i].value);
      }
    }

    setClientPeformanceFilter({
      ...clientPeformanceFilter,
      city: JSON.stringify(city),
      customer: JSON.stringify(customer),
    });
  }, [selectedCity, selectedCustomers]);

  // clientPerformace
  var clientPerformace = {
    chart: {
      fontFamily: "Apple-System, sans-serif",
      width: "100%",
      height: 500,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    colors: ["#50D890", "#FFD460"],
    dataLabels: {
      enabled: false,
    },

    series: [
      {
        name: "Revenue",
        // data: [2, 9, 21, 6, 9, 14, 3, 18, 4, 2, 10, 9],
        data: clientRevenuePayoutsData.revenue,
      },
      {
        name: "Payouts",
        data: clientRevenuePayoutsData.payouts,
        // data: [8, 20, 35, 13, 20, 27, 17, 30, 29, 45, 76, 20],
      },
    ],
    stroke: {
      curve: "smooth",
    },
    legend: {
      position: "bottom",
    },
    xaxis: {
      categories: monthNames,
    },
    responsive: [
      {
        breakpoint: 1000,
        yaxis: {
          categories: monthNames,
        },
        options: {
          plotOptions: {
            line: {
              horizontal: true,
              columnWidth: "80%",
            },
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  //last quarter sales
  var lastQuarterSalesOptions = {
    chart: {
      fontFamily: "Apple-System, sans-serif",
      height: 80,
      type: "area",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: quarterData.months,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    colors: ["#ffffff"],

    dataLabels: {
      enabled: false,
    },

    series: [
      {
        name: "Revenue",
        data: quarterData.revenue,
      },
    ],
    stroke: {
      curve: "straight",
    },
    legend: {
      show: false,
    },
  };

  return (
    <div className="layout-spacing ">
      <div className="widget widget-chart-one ">
        <div className="widget-heading">
          <div>
            <h5 className="mb-2">Client Performace</h5>
            <p>Monthly Sales Vs Payouts</p>
          </div>

          <ul className="tabs">
            <li className="mx-2">
              <label>City</label>
              <Select
                isMulti
                name="city"
                value={selectedCity}
                onChange={(value) => {
                  setSelectedCity(value);
                }}
                options={cityList}
              />
            </li>
            <li className="mx-2">
              <label>Customers</label>
              <Select
                isMulti
                name="customers"
                value={selectedCustomers}
                onChange={(value) => {
                  setSelectedCustomers(value);
                }}
                options={customerList}
              />
            </li>
            <li className="mx-2">
              <label>Year</label>
              <Select
                id="inputYear"
                name="year"
                defaultValue={selectedCientPerFormanceYear}
                value={selectedCientPerFormanceYear}
                onChange={(value) => {
                  setSelectedClientPerFormanceYear(value);
                }}
                options={[
                  {
                    value: 2023,
                    label: "2023",
                  },
                  {
                    value: 2022,
                    label: "2022",
                  },
                  {
                    value: 2021,
                    label: "2021",
                  },
                ]}
              />
            </li>
          </ul>
        </div>
        <div className="">
          <div className=" client-performance" id="chart">
            <Chart
              options={clientPerformace}
              type="line"
              series={clientPerformace.series}
              height={220}
            />
          </div>
          <div className="">
            <div className="row">
              <div className="client-card-1 m-2 p-2 col">
                <div className="text-white pt-2  d-flex justify-content-between align-items-center">
                  <div>Last Month Sales</div>
                  <div>{lastMonthData.year}</div>
                </div>
                <div className="text-white text-center pt-2 d-flex flex-column">
                  <h3 className="py-2">
                    ₹{numberWithCommas(lastMonthData.revenue)}
                  </h3>
                  <div className="d-flex justify-content-center align-items-center ">
                    <h6 className="mx-2">
                      {numberWithCommas(
                        lastMonthData.growthPercentage.toFixed(2)
                      )}
                      %
                    </h6>
                    {lastMonthData.growthPercentage > 0 ? (
                      <TbCircleArrowUpRightFilled size={30} color="#97DECE" />
                    ) : (
                      <TbCircleArrowDownRightFilled size={30} color="#D14D72" />
                    )}
                  </div>
                </div>
              </div>
              <div className="client-card-2 m-2 px-2 col">
                <div className="text-white pt-2  d-flex justify-content-between align-items-center">
                  <div>Last Quarter Sales</div>
                  <div>{lastQuarterMonth.year}</div>
                </div>
                <div className="text-white my-2 d-flex justify-content-around align-items-center">
                  <h4 className="text-white">
                    ₹{numberWithCommas(quarterData.totalRevenueForQuarter)}
                  </h4>
                  <div className="d-flex justify-content-center align-items-center ">
                    <h6 className="mx-2">{quarterData.growthPercentage}%</h6>
                    {quarterData.growthPercentage > 0 ? (
                      <TbCircleArrowUpRightFilled size={20} color="#97DECE" />
                    ) : (
                      <TbCircleArrowDownRightFilled size={20} color="#D14D72" />
                    )}
                  </div>
                </div>
                <Chart
                  options={lastQuarterSalesOptions}
                  type="area"
                  series={lastQuarterSalesOptions.series}
                  height={80}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientPerformance;
