import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { format } from "date-fns";
import Footer from "../../Owners_DashBoard/Footer";
import Header from "../../Owners_DashBoard/Header";
import Sub_Header from "../../Owners_DashBoard/Sub_Header";
import SideBar from "../../Owners_DashBoard/SideBar";
import Loader from "../../common/Loader/Loader";
import { ExportToExcel } from "./ExportToExcelForSalesPayout";
import "../../../assets/css/users/user-profile.css";
import "../../../assets/plugins/table/datatable/datatables.css";
import "../../../assets/plugins/table/datatable/dt-global_style.css";
import * as action from "../../../action/Dashboard/dashboard_action";
import $ from "jquery";
import { renderToString } from "react-dom/server";
function Sales_Payout_View() {
  $.DataTable = require("datatables.net");
  const dispatch = useDispatch();
  const { loading } = useSelector(
    (state) => state.DashboardSalesPayoutListReducer
  );
  const { ExcelLoading } = useSelector(
    (state) => state.DashboardExcelSalesPayoutReducer
  );
  const tableRef = useRef();
  const [vehicleList, setVihicleList] = useState([]);
  const [dataListExcel, setDataListExcel] = useState([]);
  const changeDateFormate = (date) => {
    if (date) {
      let new_date = String(date);
      let ndate = new_date.split("T");
      let format_date = format(new Date(ndate[0]), "dd MMM yyyy");
      let format_time = new Date(date).toLocaleTimeString("en-US");
      return format_date + "<br/>" + format_time;
    }
  };
  const tableName = "vehicle-list";
  useEffect(() => {
    dispatch(action.dashboard_sales_payout_view())
      .then((data) => {
        var monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        let newVehicleList = [];

        data.data.map((item) => {
          newVehicleList.push([
            item.customerData.businessName,
            item.cityData.cityName,
            monthNames[item.month - 1],
            item.year,
            item.sales,
            item.payout,
            item.rider,
            item.vehicle,
            `<a style="cursor: pointer;" href="/adminside/salesPayout/view/${item._id}" class="btn btn-info btn-sm view-btn view-act-btn" data-id="${item._id}">View</a>`,
            item._id,
            item,
          ]);
        });
        setVihicleList(newVehicleList);
      })
      .catch({});
  }, []);
  useEffect(() => {
    dispatch(action.dashboard_export_excel_sales_payout())
      .then((data) => {
        setDataListExcel(data.data);
      })
      .catch({});
  }, []);
  useEffect(() => {
    const table = $(`#${tableName}`).DataTable({
      dom: "Bfrtip",
      buttons: ["copyHtml5", "excelHtml5", "pdfHtml5", "csvHtml5"],
      data: vehicleList,
      columns: [
        { title: "Customer Name" },
        { title: "City" },
        { title: "Month" },
        { title: "Year" },
        { title: "Sales" },
        { title: "Payout" },
        { title: "No. of Rider" },
        { title: "No. of Vehicle" },
        { title: "Action" },
      ],
      destroy: true, // I think some clean up is happening here
      searching: true,
      oLanguage: {
        oPaginate: {
          sPrevious:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>',
          sNext:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>',
        },
        //sInfo: "Showing page _PAGE_ of _PAGES_",

        sSearch:
          '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
        sSearchPlaceholder: "Search...",
        sLengthMenu: "Results :  _MENU_",
      },
    });
    // Extra step to do extra clean-up.
    return function () {
      table.destroy();
    };
  }, [vehicleList]);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Sub_Header />
      <div className="main-container" id="container">
        {loading ? <Loader /> : null}
        {ExcelLoading ? <Loader /> : null}
        <div className="overlay"></div>
        <div className="search-overlay"></div>
        <SideBar />
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                <div className="widget-content widget-content-area br-6">
                  <div className="d-flex justify-content-between">
                    <h5 className="" style={{ margin: "10px" }}>
                      Vehicle List
                    </h5>
                    {ExcelLoading ? (
                      <button disabled class="btn btn-success">
                        <i class="fa fa-spinner fa-spin"></i>{" "}
                      </button>
                    ) : dataListExcel && dataListExcel.length ? (
                      <ExportToExcel vehicleData={dataListExcel} />
                    ) : (
                      <div className="btn btn-success disabled">
                        Export to XLS
                      </div>
                    )}
                  </div>
                  <div className="table-responsive mb-4 mt-4">
                    <table
                      id="vehicle-list"
                      className="table table-hover"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>Customer Name</th>
                          <th>City</th>
                          <th>Month</th>
                          <th>Year</th>
                          <th>Sales</th>
                          <th>Payout</th>
                          <th>No. of Rider</th>
                          <th>No. of Vehicle</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </>
  );
}
export default Sales_Payout_View;
