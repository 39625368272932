import baseService from "./baseService";

export function LogIn(credentail) {
  return baseService.post("/admin/login", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function logout() {
  return baseService.patch(
    "/admin/logout",
    {},
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function CityList() {
  return baseService.get("admin/getCityList", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function NotificationList() {
  return baseService.get("/admin/getAllNotification", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function CustomerList() {
  return baseService.get("/admin/customer/getAll", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function AddCustomerList(credentail) {
  return baseService.post("/admin/customer", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function AddCustomerAddress(credentail) {
  return baseService.post("/admin/customer/address", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function AddCustomerShift(credentail) {
  return baseService.post("/admin/customer/shift", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function EditCustomerList(credentail) {
  return baseService.put("/admin/customer/details", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function EditCustomerShift(credentail) {
  return baseService.put("/admin/customer/shift", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function EditCustomerAddress(credentail) {
  return baseService.put("/admin/customer/address", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function CustomerStatusChange(cust_id) {
  return baseService.put(
    "/admin/customer/" + cust_id + "/status",
    {},
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}
export function CustomerLetestInvoiceNo(cust_id) {
  return baseService.get(
    "/admin/customer/" + cust_id + "/latestInvoiceNumber",
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}
export function CustomerInvoiceList(cust_id) {
  return baseService.get(
    "/admin/customer/" + cust_id + "/invoicesNotPagination",
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}
export function DeleteCustomerInvoice(invoice_id) {
  return baseService.delete("/admin/customer/invoices/" + invoice_id, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function AddressStatusChange(address_id) {
  return baseService.put(
    "/admin/customer/address/" + address_id + "/status",
    {},
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}
export function ShiftStatusChange(ShiftId) {
  return baseService.put(
    "/admin/customer/shift/" + ShiftId + "/status",
    {},
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}
export function GenerateCustomerInvoice(cust_id, start_date, end_date) {
  return baseService.get(
    "/admin/customer/" +
      cust_id +
      "/invoice/generate?startDate=" +
      start_date +
      "&&endDate=" +
      end_date,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}
export function CustomerDetails(cust_id) {
  return baseService.get("/admin/customer/getOneCustomer/" + cust_id, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function AddressList(cust_id) {
  return baseService.get("/admin/customer/addresses/" + cust_id, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function ShiftList(cust_id) {
  return baseService.get("/admin/customer/shifts/" + cust_id, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function uploadCustomerInvoice(invoiceFile) {
  return baseService.post("/upload/pdffile", invoiceFile, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function addCustomerInvoice(customerId, invoiceData) {
  return baseService.post(
    "/admin/customer/" + customerId + "/invoice",
    invoiceData,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}
export function VehicleList() {
  return baseService.get("/admin/vehicle/getAll", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function VehicleLeaseCompanyListSelect() {
  return baseService.get("/admin/newVehicleLeaseCompany/all", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function VehicleTelematicsUnitListSelect() {
  return baseService.get("/admin/vehicleTelematicsUnit/all", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function VehicleHubsListSelect() {
  return baseService.get("/admin/vehicleHub/all", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function VehicleStatusChange(vehicle_id) {
  return baseService.put(
    "/admin/vehicle/" + vehicle_id + "/status",
    {},
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}
export function VehicleFileUpload(credentail) {
  return baseService.post("/upload/file", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function AddVehicle(credentail) {
  return baseService.post("/admin/vehicle", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function DeleteVehicleFile(credentail) {
  return baseService.delete("/upload/file", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function VehicleDetails(vehicle_id) {
  return baseService.get("/admin/vehicle/getVehicleData/" + vehicle_id, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function EditVehicle(credentail) {
  return baseService.put("/admin/vehicle", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function AddVehicleLeaseCompany(credentail) {
  return baseService.post("/admin/newVehicleLeaseCompany", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function VehicleLeaseCompanyList() {
  return baseService.get("/admin/newVehicleLeaseCompany/getAll", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function VehicleLeaseCompanyStatusChange(vehicle_lease_company_id) {
  return baseService.put(
    "/admin/vehicleLeaseCompany/" + vehicle_lease_company_id + "/status",
    {},
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}
export function VehicleLeaseCompanyDetails(vehicle_lease_company_id) {
  return baseService.get(
    "/admin/newVehicleLeaseCompany/" + vehicle_lease_company_id,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}
export function EditVehicleLeaseCompany(credentail) {
  return baseService.put("/admin/newVehicleLeaseCompany", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function AddVehicleTelematicsUnit(credentail) {
  return baseService.post("/admin/vehicleTelematicsUnit", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function VehicleTelematicsUnitList() {
  return baseService.get("/admin/vehicleTelematicsUnit/getAll", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function VehicleTelematicsUnitStatusChange(vehicle_lease_company_id) {
  return baseService.put(
    "/admin/vehicleTelematicsUnit/" + vehicle_lease_company_id + "/status",
    {},
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}
export function VehicleTelematicsUnitDetails(vehicle_lease_company_id) {
  return baseService.get(
    "/admin/vehicleTelematicsUnit/" + vehicle_lease_company_id,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}
export function EditVehicleTelematicsUnit(credentail) {
  return baseService.put("/admin/vehicleTelematicsUnit", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function AddVehicleHub(credentail) {
  return baseService.post("/admin/vehicleHub", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function VehicleHubList() {
  return baseService.get("/admin/vehicleHub/getAll", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function VehicleHubStatusChange(vehicle_hub_id) {
  return baseService.put(
    "/admin/vehicleHub/" + vehicle_hub_id + "/status",
    {},
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}
export function VehicleHubDetails(vehicle_hub_id) {
  return baseService.get("/admin/vehicleHub/" + vehicle_hub_id, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function EditVehicleHub(credentail) {
  return baseService.put("/admin/vehicleHub", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function VehicleHubManagerList() {
  return baseService.get("/admin/vehicleHub/allManagers", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function AdminList() {
  return baseService.get("/admin/getAll", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function AddAdmin(credentail) {
  return baseService.post("/admin/admin", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function AdminDetails(admin_id) {
  return baseService.get("/admin/admin/" + admin_id, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function EditAdmin(credentail) {
  return baseService.put("/admin/admin", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function DriverList() {
  return baseService.get("/admin/driver/getAll", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function UnverifiedDriverList() {
  return baseService.get("/admin/driver/getUnverifiedAll", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function DriverStatusChange(driverId) {
  return baseService.put("/admin/driver/" + driverId + "/status", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function FileUpload(credentail) {
  return baseService.post("/upload/file", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function DeleteFile(credentail) {
  return baseService.delete("/upload/file", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function AddDriver(credentail) {
  return baseService.post("/admin/driver", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function detachedVehicleFromDriver(driver_id) {
  return baseService.post(
    "/admin/driver/" + driver_id + "/detachedVehicle",
    {},
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}
export function DriverLogoff(driver_id) {
  return baseService.patch(
    "/admin/driver/logout/" + driver_id,
    {},
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}
export function DriverSelfieRequest(driver_id) {
  return baseService.post(
    "/admin/driver/" + driver_id + "/selfieRequest",
    {},
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function DriverVerify(driver_id) {
  return baseService.put(
    "/admin/driver/" + driver_id + "/verify",
    {},
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function DriverDelete(driver_id) {
  return baseService.delete("/admin/driver/deleteOneDriver/" + driver_id, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function CustomerListForDropdown() {
  return baseService.get("/admin/customer/all", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function CustomerAddressListForDropdown(customer_id) {
  return baseService.get("/admin/customer/" + customer_id + "/addresses/all", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function CustomerShiftListForDropdown(customer_id) {
  return baseService.get("/admin/customer/" + customer_id + "/shifts/all", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function DriverAssignCustomer(credentail) {
  return baseService.put("/admin/driver/assignCustomer", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function DriverListForSelect() {
  return baseService.get("/admin/driver/all", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function DriverDetails(driver_id) {
  return baseService.get("/admin/driver/getOneDriver/" + driver_id, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function EditDriver(credentail) {
  return baseService.put("/admin/driver", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function DriverWorkList(driver_id) {
  return baseService.get("/admin/driverWork/" + driver_id + "/getAll", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function DriveAttendanceList(driver_id, start_date, end_date) {
  return baseService.get(
    "/admin/driverWork/" +
      driver_id +
      "/allAttendance?startDate=" +
      start_date +
      "&endDate=" +
      end_date,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}
export function DriverMultipleAssignList(driver_id) {
  return baseService.get("/admin/driver/assignMultipleDriver/" + driver_id, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function DriverMultipleAssignDelete(assign_id) {
  return baseService.delete("/admin/driver/assignMultipleDriver/" + assign_id, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function DriverSingleAssign(credentail) {
  return baseService.put("/admin/driver/assignSingleCustomer", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function AllDriverWorkList(start_date, end_date, driver_id) {
  return baseService.get(
    "/admin/driverWork/getAll?startDate=" +
      start_date +
      "&endDate=" +
      end_date +
      "&driverId=" +
      driver_id,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}
export function GetUndeliveredReasonList() {
  return baseService.get("/admin/undeliveredReason/all", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function DriverWorkDetails(driver_id) {
  return baseService.get("admin/driverWork/" + driver_id + "/details", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function MultipleFileUpload(credentail) {
  return baseService.post("/upload/multiplefile", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function GetDriverLocationDetails(driver_id) {
  return baseService.get("admin/driver/location/" + driver_id, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function AllDriverRequestList() {
  return baseService.get("/admin/driver/getAllLoginRequest", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function ApproveDriverRequest(driver_id) {
  return baseService.put(
    "/admin/driver/" + driver_id + "/approvedLoginRequest",
    {},
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}
export function DeleteDriverRequest(driver_id) {
  return baseService.delete("/admin/driver/deleteOneDriver/" + driver_id, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function HubVehicleList() {
  return baseService.get("/admin/vehicle/hub/list", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function VehicleHubParkedChange(vehicle_id) {
  return baseService.put(
    "/admin/vehicle/" + vehicle_id + "/on_filed_status",
    {},
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}
export function HubVehicleTechnicianList() {
  return baseService.get("/admin/vehicleHub/allTechnician", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function HubVehicleDashboardDetails() {
  return baseService.get("/admin/vehicle/hub/dashboard/details", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function TechnicianVehicleDashboardDetails() {
  return baseService.get("/admin/vehicle/technician/dashboard/details", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function TechnicianVehicleList() {
  return baseService.get("/admin/vehicle/technician/list", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function VehicleTechnicianRepairedChange(vehicle_id) {
  return baseService.put(
    "/admin/vehicle/" + vehicle_id + "/is_repaired_status",
    {},
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}
export function UpdateVehicleDeactiveReason(credentail) {
  return baseService.put("/admin/vehicle/updateReason", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function UpdateVehicleTechnician(credentail) {
  return baseService.put("/admin/vehicle/updateTechnician", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function UpdateVehicleHub(credentail) {
  return baseService.put("/admin/vehicle/updateHub", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function UpdateVehicleCustomer(credentail) {
  return baseService.put("/admin/vehicle/updateCustomer", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function WatchmanVehicleDashboardDetails() {
  return baseService.get("/admin/vehicle/watchman/dashboard/details", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function vehicleWatchmanChangeStatusOnField(vehicle_id) {
  return baseService.put(
    "/admin/vehicle/" + vehicle_id + "/on_field",
    {},
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}
export function vehicleWatchmanChangeStatusParked(vehicle_id) {
  return baseService.put(
    "/admin/vehicle/" + vehicle_id + "/parked",
    {},
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}
export function getVehicleDetails(vehicle_id) {
  return baseService.get("/admin/vehicle/getVehicleData/" + vehicle_id, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function Scan_Bike_Status_Change(statusValue) {
  return baseService.post(
    "/admin/dashboard/allowParcelWithoutScan/" + statusValue,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function Scan_Bike_Status_Get() {
  return baseService.get("/admin/dashboard/allowParcelWithoutScanStatus/", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function AddBikeType(credentail) {
  return baseService.post("/admin/bikeType", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function BikeTypeList() {
  return baseService.get("/admin/bikeType/getAll");
}
export function BikeTypeStatusChange(bike_type_id) {
  return baseService.put(
    "/admin/bikeType/" + bike_type_id + "/status",
    {},
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}
export function BikeTypeDetails(bike_type_id) {
  return baseService.get("/admin/bikeType/" + bike_type_id, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function EditBikeType(credentail) {
  return baseService.put("/admin/bikeType", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function deleteBikeType(credentail) {
  return baseService.put("/admin/bikeType/delete", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function getStateList() {
  return baseService.get("/admin/getAdminState", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function getCityList() {
  return baseService.get("/admin/getAdminCity", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function getCityListByState(stateId) {
  return baseService.get("/admin/cities/" + stateId, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function driverSetDefaultCustomer(credentail) {
  return baseService.put("/admin/driver/setIsDefalutCustomer", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function AdminProfileDetails() {
  return baseService.get("/admin/details", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function AdminProfileUpdate(credentail) {
  return baseService.put("/admin/details", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function GetBikeTypeListSelect() {
  return baseService.get("/admin/bikeType/all", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function AllVehicleWorkList(start_date, end_date, vehicle_id) {
  return baseService.get(
    "/admin/driverWork/getAllVehicles?startDate=" +
      start_date +
      "&endDate=" +
      end_date +
      "&vehicle_id=" +
      vehicle_id,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}
export function DashboardVehicleDetails() {
  return baseService.get("/admin/dashboard/vehicles", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function DashboardDriverDetails() {
  return baseService.get("/admin/dashboard/drivers", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function DriverIssuesList(driver_id) {
  return baseService.get("/admin/issues?driverId=" + driver_id, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function DriverIssuesCategoryList() {
  return baseService.get("/admin/driver/getissuecatergorylist", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function DriverAddIssuesCategory(credentail) {
  return baseService.post("/admin/driver/addIssueCategory", credentail, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function DriverDeleteIssuesCategory(id) {
  return baseService.delete("/admin/driver/deleteissuecatergory/" + id, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function DriverUpdateIssuesCategory(credential) {
  return baseService.put("/admin/driver/updateissuecatergory", credential, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function DriverIssuesImages(issue_id) {
  return baseService.get("/admin/issues/viweimage/" + issue_id, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function GetAllAttendance() {
  return baseService.get("/admin/dashboard/getAttendanceAndParcel", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function GetAllDriverAttendance(start_date, end_date) {
  return baseService.get(
    "/admin/driverWork/getAllDriverAttendance?startDate=" +
      start_date +
      "&endDate=" +
      end_date,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}
export function getAllAttendanceAndParcel(start_date, end_date) {
  return baseService.get(
    "/admin/driverWork/getAttendanceAndParcel?startDate=" +
      start_date +
      "&endDate=" +
      end_date,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function DashboardCustomerDetailsDaily(year) {
  return baseService.get(
    `/admin/driverWork/monthlyCustomerWiseRidersYearly/${year}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function DashboardNewRiderleftRiderMonthly(year) {
  return baseService.get(
    `/admin/driverWork/getdriverWorkCountMonthly/${year}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function DashboardVehicleFleetMonthly(year, month) {
  return baseService.get(
    `/admin/driverWork/getvehicletotalfleetmonthly/${year}/${month}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function DashboardDriverWorkMonthly(id) {
  return baseService.get(
    "/admin/driverWork/getdriverWorkMonthly?customerId=" + id,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function AdminAddCharges(credential) {
  return baseService.post("/admin/addCharges", credential, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function AdminChargesList() {
  return baseService.get("/admin/viewCharges", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}
export function AdminEditCharges(credential) {
  return baseService.put("/admin/editCharges", credential, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function AdminDeleteCharges(id) {
  return baseService.delete("/admin/deleteCharges/" + id, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function DashboardRepairMonthly(repairFilterData) {
  return baseService.post(
    `/admin/driverWork/getRepairExpenseMonthly`,
    repairFilterData,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function DashboardMonth() {
  return baseService.get("/admin/driverWork/getLastTwelvMonths", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function DashboardAddSalesPayout(credential) {
  return baseService.post("/admin/addSalesPayout", credential, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function DashboardCityList() {
  return baseService.get("/user/getUserCity", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function DashboardSalesPayoutList() {
  return baseService.get("/admin/viewSalesPayout", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function DashboardviewSalesPayout(id) {
  return baseService.get("/admin/viewSingleSalesPayout/" + id, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function DashboardEditSalesPayout(credential) {
  return baseService.put(`/admin/EditSalesPayout`, credential, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function DashboardExportExcelSalesPayout() {
  return baseService.get("/admin/exportExcelSalesPayout", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function DashboardCustomerGrowth(year, month) {
  return baseService.get(`/admin/customerGrowth/${year}/${month}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function DriverWiseSubmitedParcel(year, applyFilterData) {
  return baseService.post(
    `/admin/driverWork/getmonthlydriversubmitparcels/${year}`,
    applyFilterData,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function DriverSarveyForm(credential) {
  return baseService.post("/admin/driver/surveyDetail", credential, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function DriverSarveyList(start_date, end_date) {
  return baseService.get(
    "/admin/driver/getSurveyDetail?startDate=" +
      start_date +
      "&endDate=" +
      end_date,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function DriverNotTakenSelfieList() {
  return baseService.get("/admin/driver/getnotTakenSalfieList", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function DriverSingleSarveyList(id) {
  return baseService.get("/admin/driver/getSingleSurveyDetails/" + id, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function AddExpenseCategory(data) {
  return baseService.post("/admin/issues/addIssueCategoryToMaster", data, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

// get all expense categories
export function GetAllExpenseCategory() {
  return baseService.get("/admin/issues/getAllIssueCategoriesFromMaster", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

// edit expense categories
export function EditExpenseCategory(id, data) {
  return baseService.put(
    `/admin/issues/editIssueCategoryToMaster/${id}`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

// get single expense categories
export function GetSingleExpenseCategory(id) {
  return baseService.get(`/admin/issues/getOneIssueCategoryFromMaster/${id}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function AddExpenseForm(data) {
  return baseService.post("/admin/issues/addIssueForm", data, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function GetAllExpenseForm() {
  return baseService.get("/admin/issues/getAllIssueForms", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

// edit expense categories
export function EditExpenseIssue(id, data) {
  return baseService.put(`/admin/issues/editIssueForm/${id}`, data, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function DashboardClientPerFormace(year, data) {
  return baseService.post(`/admin/getClientPerformanceData/${year}`, data, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function DashboardLastMonthSales() {
  return baseService.get(`admin/getTotalRevenueOfLastMonth`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function DashboardLastQuarterSales(year, startMonth, endMonth) {
  return baseService.get(
    `admin/getRevenueDataQuarterOrMonthly?year=${year}&fromMonth=${startMonth}&toMonth=${endMonth}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function DashboardRevenuePerformance(year, month) {
  return baseService.get(
    `admin/getCustomerWiseRevenuePerformance/${year}/${month}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function DashboardRevenueCityWise(filterData) {
  return baseService.get(
    `admin/getClientRevenuePerformanceDifferenceMonthWise/?yearAndMonth=${filterData.yearMonth}&city1=${filterData.city1.value}&city2=${filterData.city2.value}&customer=${filterData.customer.value}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function AddFleetEfficiency(data) {
  return baseService.post(`admin/vehicle/addFleetUtilizationData`, data, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function EditFleetEfficiency(id, data) {
  return baseService.put(
    `admin/vehicle/updateFleetUtilizationData/?id=${id}`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function GetFleetEfficiency() {
  return baseService.get(`admin/vehicle/getAllFleetUtilizationData`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function GetOneFleetEfficiency(id) {
  return baseService.get(`admin/vehicle/getOneFleetUtilizationData/?id=${id}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function UtilisedFleetEfficiency(data) {
  return baseService.get(
    `admin/vehicle/getFleetUtilizationEfficiency?yearAndMonth=${data?.yearMonth}&cityId=${data?.city?.value}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function AddRidersEarning(data) {
  return baseService.post(`admin/driver/addEarningScaleOfDriver`, data, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function GetRidersEarning() {
  return baseService.get(`admin/driver/getAllEarningScaleOfDrivers`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function EditRidersEarning(id, data) {
  return baseService.put(
    `admin/driver/updateEarningScaleOfDrivers/?id=${id}`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function AddRidersParcel(data) {
  return baseService.post(`admin/driver/addParcelsDeliveredByRiders`, data, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function GetRidersParcel() {
  return baseService.get(`admin/driver/getAllParcelDeliveryData`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function EditRidersParcel(id, data) {
  return baseService.put(
    `admin/driver/updateParcelsDeliveredByRiders?id=${id}`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function RiderParcelDeliveryChart(data) {
  return baseService.post(
    `admin/driver/getParcelDeliveryDataCustomerWise`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function RiderEarningChart(data) {
  return baseService.post(`admin/driver/getEarningScaleWiseRidersData`, data, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function AddSwiggyBilling(data) {
  return baseService.post(`admin/customer/addSwiggyBillingAnalysisData`, data, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function EditSwiggyBilling(id, data) {
  return baseService.put(
    `admin/customer/updateSwiggyBillingAnalysisData?id=${id}`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function GetSwiggyBilling() {
  return baseService.get(`admin/customer/getAllSwiggyBillingAnalysisData`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function AddZomatoBilling(data) {
  return baseService.post(`admin/customer/addZomatoBillingAnalysisData`, data, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function EditZomatoBilling(id, data) {
  return baseService.put(
    `admin/customer/updateZomatoBillingAnalysisData?id=${id}`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function GetZomatoBilling() {
  return baseService.get(`admin/customer/getAllZomatoBillingAnalysisData`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function AddBigBasketBilling(data) {
  return baseService.post(
    `admin/customer/addBigBasketBillingAnalysisData`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function EditBigBasketBilling(id, data) {
  return baseService.put(
    `admin/customer/updateBigBasketBillingAnalysisData?id=${id}`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function GetBigBasketBilling() {
  return baseService.get(`admin/customer/getAllBigBasketBillingAnalysisData`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function AddBBNowBilling(data) {
  return baseService.post(`admin/customer/addBBNowBillingAnalysisData`, data, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function EditBBNowBilling(id, data) {
  return baseService.put(
    `admin/customer/updateBBNowBillingAnalysisData?id=${id}`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function GetBBNowBilling() {
  return baseService.get(`admin/customer/getAllBBNowBillingAnalysisData`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function AddFlipkartBilling(data) {
  return baseService.post(
    `admin/customer/addFlipkartBillingAnalysisData`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function EditFlipkartBilling(id, data) {
  return baseService.put(
    `admin/customer/updateFlipkartBillingAnalysisData?id=${id}`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function GetFlipkartBilling() {
  return baseService.get(`admin/customer/getAllFlipkartBillingAnalysisData`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function AddBlueDartBilling(data) {
  return baseService.post(
    `admin/customer/addBlueDartBillingAnalysisData`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function EditBlueDartBilling(id, data) {
  return baseService.put(
    `admin/customer/updateBlueDartBillingAnalysisData?id=${id}`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function GetBlueDartBilling() {
  return baseService.get(`admin/customer/getAllBlueDartBillingAnalysisData`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function GetSwiggyBillingChartData(data) {
  return baseService.post(
    `admin/customer/getSwiggyBillingAnalysisDataForChart`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function GetZomatoBillingChartData(data) {
  return baseService.post(
    `admin/customer/getZomatoBillingAnalysisDataForChart`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function GetBigBasketBillingChartData(data) {
  return baseService.post(
    `admin/customer/getBigBasketBillingAnalysisDataForChart`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function GetBBNowBillingChartData(data) {
  return baseService.post(
    `admin/customer/getBBNowBillingAnalysisDataForChart`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function GetFlipkartBillingChartData(data) {
  return baseService.post(
    `admin/customer/getFlipkartBillingAnalysisDataForChart`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function GetBlueDartBillingChartData(data) {
  return baseService.post(
    `admin/customer/getBlueDartBillingAnalysisDataForChart`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function GetFullTimeRidersParcelMonthlyData(year, data) {
  return baseService.post(
    `admin/driver/getParcelsDeliveredByFullTimeRiders?year=${year}`,
    { cityArray: data },
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function GetPartTimeRidersParcelMonthlyData(year, data) {
  return baseService.post(
    `admin/driver/getParcelsDeliveredByPartTimeRiders?year=${year}`,
    { cityArray: data },
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
}

export function GetCODOrdersData(data) {
  return baseService.post(`admin/dashboard/getAllCodOrders`, data, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function AddCODOrdersData(data) {
  return baseService.post(`admin/dashboard/addCodOrdersData`, data, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function EditCODOrdersData(id, data) {
  return baseService.put(`admin/dashboard/updateCodOrders?id=${id}`, data, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

export function VehicleStatusSummary() {
  return baseService.get(
    `http://gaddi24.com/api/v1/TokenizedReports/VehicleStatusSummary`,
    {
      headers: { TGToken: `${process.env.REACT_APP_TRACKGADDI_TOKEN}` },
    }
  );
}
