import React from "react";
import ParcelFullTime from "./components/ParcelFullTime/ParcelFullTime";
import ParcelPartTime from "./components/ParcelPartTime/ParcelPartTime";

const ParcelByRiders = ({ cityList }) => {
  // months List
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className="  layout-spacing">
      <div className="widget widget-chart-one">
        <div className="widget-heading">
          <h5 className="">
            Customer Wise Rider Analysis - Full Time Riders vs Part Time Riders
          </h5>

          <ul className="tabs tab-pills">
            <li>
              <a id="tb_1" className="tabmenu">
                Monthly
              </a>
            </li>
          </ul>
        </div>

        <div className="row">
          <ParcelFullTime monthNames={monthNames} cityList={cityList} />
          <ParcelPartTime monthNames={monthNames} cityList={cityList} />
        </div>
      </div>
    </div>
  );
};

export default ParcelByRiders;
