import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Input } from "rsuite";
import { RxCross2 } from "react-icons/rx";
import Pagination from "../../../../common/Pagination/Pagination";
import $ from "jquery";
import * as billingaction from "../../../../../action/BillingAnalysis/billing_analysis";

const BlueDartTab = ({
  cityList,
  errors,
  setErrors,
  disabled,
  setEditFormEditable,
  editButtonHide,
  submitButtonHide,
}) => {
  // bluedart add
  const yearMonthBlueDartFocus = useRef();
  const cityBlueDartFocus = useRef();
  const revenueBlueDartFocus = useRef();
  const ordersBlueDartFocus = useRef();

  // bluedart edit
  const edityearMonthBlueDartFocus = useRef();
  const editcityBlueDartFocus = useRef();
  const editrevenueBlueDartFocus = useRef();
  const editordersBlueDartFocus = useRef();

  const dispatch = useDispatch();

  const [blueDartData, setBlueDartData] = useState({
    currentPage: 1,
    billingData: [],
    formId: "",
  });

  const [addBlueDartBillingData, setAddBlueDartBillingData] = useState({
    yearMonth: 0,
    city: "",
    revenue: 0,
    orders: 0,
  });

  const [editBlueDartBillingData, setEditBlueDartBillingData] = useState({
    yearMonth: 0,
    city: "",
    revenue: 0,
    orders: 0,
  });

  // bluedart billing data
  useEffect(() => {
    dispatch(billingaction.get_bluedart_billing())
      .then((data) => {
        let blueDartbillingData = data.data;
        setBlueDartData({
          ...blueDartData,
          billingData: blueDartbillingData,
        });
      })
      .catch((err) => {
        toast.error(err);
      });
  }, []);

  // BlueDart add and update
  const onSubmitHandlerBlueDart = (e) => {
    e.preventDefault();
    setErrors({});
    let customErrors = {};

    if (!addBlueDartBillingData.yearMonth) {
      customErrors = {
        ...customErrors,
        yearMonth: "Please Select Year and Month",
      };
      yearMonthBlueDartFocus.current.focus();
    } else if (!addBlueDartBillingData.city) {
      customErrors = { ...customErrors, city: "Please Select city" };
      cityBlueDartFocus.current.focus();
    } else if (!addBlueDartBillingData.revenue) {
      customErrors = { ...customErrors, revenue: "Please Enter revenue" };
      revenueBlueDartFocus.current.focus();
    } else if (!addBlueDartBillingData.orders) {
      customErrors = { ...customErrors, orders: "Please Enter orders" };
      ordersBlueDartFocus.current.focus();
    }

    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }

    dispatch(billingaction.add_bluedart_billing(addBlueDartBillingData))
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          $("#addExpenseFormBlueDart .close").click();
          let blueDartBillingData = data.data;
          setBlueDartData({
            ...blueDartData,
            billingData: blueDartBillingData,
          });
          setEditFormEditable(false);
        } else if (!data.success) {
          toast.error(data.message);
        }
        setErrors({});
      })
      .catch((err) => {});

    setErrors({});

    setAddBlueDartBillingData({
      yearMonth: 0,
      city: "",
      revenue: 0,
      orders: 0,
    });
  };

  const onUpdateHandlerBlueDart = (e) => {
    e.preventDefault();
    setErrors({});
    let customErrors = {};

    if (!editBlueDartBillingData.yearMonth) {
      customErrors = {
        ...customErrors,
        yearMonth: "Please Select Year and Month",
      };
      edityearMonthBlueDartFocus.current.focus();
    } else if (!editBlueDartBillingData.city) {
      customErrors = { ...customErrors, city: "Please Select city" };
      editcityBlueDartFocus.current.focus();
    } else if (!editBlueDartBillingData.revenue) {
      customErrors = { ...customErrors, revenue: "Please Enter revenue" };
      editrevenueBlueDartFocus.current.focus();
    } else if (!editBlueDartBillingData.orders) {
      customErrors = { ...customErrors, orders: "Please Enter orders" };
      editordersBlueDartFocus.current.focus();
    }

    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }

    dispatch(
      billingaction.edit_bluedart_billing(
        blueDartData.formId,
        editBlueDartBillingData
      )
    )
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          $("#editExpenseFormBlueDart .close").click();
          let blueDartBillingData = data.data;
          setBlueDartData({
            ...blueDartData,
            billingData: blueDartBillingData,
          });
          setEditFormEditable(false);
        } else if (!data.success) {
          toast.error(data.message);
        }
        setErrors({});
      })
      .catch((err) => {});

    setErrors({});
  };

  // bluedart edit item
  const handleBlueDartEditClick = (item) => {
    setBlueDartData({ ...blueDartData, formId: item._id });

    const formattedMonth =
      item.month < 10 && item.month > 0 ? `0${item.month}` : item.month;

    let yearMonth = `${item.year}-${formattedMonth}`;

    setEditBlueDartBillingData({
      yearMonth: yearMonth,
      city: item.city,
      revenue: item.revenue,
      orders: item.orders,
    });
  };

  const currentBlueDartTableData = useMemo(() => {
    const firstPageIndexBlueDart = (blueDartData.currentPage - 1) * 10;
    const lastPageIndexBlueDart = firstPageIndexBlueDart + 10;
    return blueDartData.billingData?.slice(
      firstPageIndexBlueDart,
      lastPageIndexBlueDart
    );
  }, [blueDartData.currentPage, blueDartData.billingData]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="" style={{ margin: "10px" }}>
          BlueDart Billing Analysis
        </h5>
        <a
          href="/"
          className="btn btn-warning btn-sm add-act-btn"
          data-toggle="modal"
          data-target="#addExpenseFormBlueDart"
        >
          Add
        </a>
      </div>

      <br />
      <div className="table-responsive mb-4 mt-4">
        {currentBlueDartTableData.length > 0 ? (
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Month</th>
                <th>Year</th>
                <th>Revenue</th>
                <th>Orders</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentBlueDartTableData.map((item) => {
                return (
                  <tr key={item._id}>
                    <td>{item.month}</td>
                    <td>{item.year}</td>
                    <td>{item.revenue}</td>
                    <td>{item.orders}</td>
                    <td>
                      <a
                        href="/"
                        onClick={() => handleBlueDartEditClick(item)}
                        className="btn btn-info btn-sm edit-btn edit-act-btn"
                        data-toggle="modal"
                        data-target="#editExpenseFormBlueDart"
                      >
                        Edit
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className="text-center">No data Available</p>
        )}
        {/* pagination */}
        <Pagination
          className="pagination-bar"
          currentPage={blueDartData.currentPage}
          totalCount={blueDartData.billingData.length}
          pageSize={10}
          onPageChange={(page) =>
            setBlueDartData({
              ...blueDartData,
              currentPage: page,
            })
          }
        />
      </div>
      {/* Blue Dart - add model */}
      <div
        className="modal fade"
        id="addExpenseFormBlueDart"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addExpenseFormTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <form onSubmit={onSubmitHandlerBlueDart}>
              <div className="modal-header">
                <h5 className="modal-title">Add Blue Dart Billing</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <RxCross2 />
                </button>
              </div>
              <div className="modal-body">
                <div
                  className="customer-address-class"
                  id="customer-address-div-id"
                >
                  <div className="row" id="defaultaddressdiv0">
                    <div className="col-12">
                      <div id="append-div-id">
                        <div class="form-group row">
                          <div class="form-group col-6 required">
                            <label>Select Year and Month *</label>
                            <Input
                              type="month"
                              ref={yearMonthBlueDartFocus}
                              name="yearMonth"
                              value={addBlueDartBillingData?.yearMonth}
                              onChange={(value) =>
                                setAddBlueDartBillingData({
                                  ...addBlueDartBillingData,
                                  yearMonth: value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.yearMonth}
                            </span>
                          </div>
                          <div class="form-group col-md-6 required">
                            <label>Select City *</label>
                            <select
                              id="city"
                              ref={cityBlueDartFocus}
                              className="form-control selectpicker"
                              value={addBlueDartBillingData?.city}
                              onChange={(e) => {
                                setAddBlueDartBillingData({
                                  ...addBlueDartBillingData,
                                  city: e.target.value,
                                });
                              }}
                            >
                              <option value="">Select City</option>
                              {cityList.map((item) => {
                                return (
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            <span style={{ color: "red" }}>{errors?.city}</span>
                          </div>
                        </div>

                        <div class="form-group row">
                          <div class="form-group col-md-6 required">
                            <label>Revenue</label>
                            <input
                              type="number"
                              min="0"
                              name="revenue"
                              className="form-control"
                              ref={revenueBlueDartFocus}
                              placeholder="Enter Revenue"
                              value={addBlueDartBillingData?.revenue}
                              onChange={(e) =>
                                setAddBlueDartBillingData({
                                  ...addBlueDartBillingData,
                                  revenue: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.revenue}
                            </span>
                          </div>

                          <div class="form-group col-md-6 required">
                            <label>Orders</label>
                            <input
                              type="number"
                              min="0"
                              name="orders"
                              className="form-control"
                              ref={ordersBlueDartFocus}
                              placeholder="No of Orders"
                              value={addBlueDartBillingData?.orders}
                              onChange={(e) =>
                                setAddBlueDartBillingData({
                                  ...addBlueDartBillingData,
                                  orders: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.orders}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="reset"
                  data-dismiss="modal"
                  className="btn btn-danger "
                  onClick={() => setEditFormEditable(false)}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success save-btn">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Blue Dart - update model */}
      <div
        className="modal fade"
        id="editExpenseFormBlueDart"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editExpenseFormTitle"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <form onSubmit={onUpdateHandlerBlueDart}>
              <div className="modal-header">
                <h5 className="modal-title">Blue Dart Billing</h5>
                <button
                  type="button"
                  className="btn btn-primary view-btn"
                  hidden={editButtonHide}
                  onClick={() => setEditFormEditable(true)}
                >
                  Edit
                </button>
              </div>

              <div className="modal-body">
                <div
                  className="customer-address-class"
                  id="customer-address-div-id"
                >
                  <div className="row" id="defaultaddressdiv0">
                    <div className="col-12">
                      <div id="append-div-id">
                        <div class="form-group row">
                          <div class="form-group col-6 required">
                            <label>Select Year and Month *</label>
                            <Input
                              disabled={disabled}
                              type="month"
                              ref={edityearMonthBlueDartFocus}
                              name="yearMonth"
                              value={editBlueDartBillingData?.yearMonth}
                              onChange={(value) =>
                                setEditBlueDartBillingData({
                                  ...editBlueDartBillingData,
                                  yearMonth: value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.yearMonth}
                            </span>
                          </div>
                          <div class="form-group col-md-6 required">
                            <label>Select City *</label>
                            <select
                              disabled={disabled}
                              id="city"
                              ref={editcityBlueDartFocus}
                              className="form-control selectpicker"
                              value={editBlueDartBillingData?.city}
                              onChange={(e) => {
                                setEditBlueDartBillingData({
                                  ...editBlueDartBillingData,
                                  city: e.target.value,
                                });
                              }}
                            >
                              <option value="">Select City</option>
                              {cityList.map((item) => {
                                return (
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            <span style={{ color: "red" }}>{errors?.city}</span>
                          </div>
                        </div>

                        <div class="form-group row">
                          <div class="form-group col-md-6 required">
                            <label>Revenue</label>
                            <input
                              disabled={disabled}
                              type="number"
                              min="0"
                              name="revenue"
                              className="form-control"
                              ref={editrevenueBlueDartFocus}
                              placeholder="Enter Revenue"
                              value={editBlueDartBillingData?.revenue}
                              onChange={(e) =>
                                setEditBlueDartBillingData({
                                  ...editBlueDartBillingData,
                                  revenue: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.revenue}
                            </span>
                          </div>

                          <div class="form-group col-md-6 required">
                            <label>Orders</label>
                            <input
                              disabled={disabled}
                              type="number"
                              min="0"
                              name="orders"
                              className="form-control"
                              ref={editordersBlueDartFocus}
                              placeholder="No of Orders"
                              value={editBlueDartBillingData?.orders}
                              onChange={(e) =>
                                setEditBlueDartBillingData({
                                  ...editBlueDartBillingData,
                                  orders: e.target.value,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors?.orders}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary view-btn"
                  hidden={submitButtonHide}
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlueDartTab;
