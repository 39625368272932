import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Header from "../../DashBoard/Header";
import Sub_Header from "../../DashBoard/Sub_Header";
import SideBar from "../../DashBoard/SideBar";
import Footer from "../../DashBoard/Footer";
import Loader from "../../common/Loader/Loader";
import Customer_Addresses from "./Customer_Addresses";
import Customer_Shifts from "./Customer_Shifts";
import Generate_Invoice from "./Generate_Invoice";
import Customer_Invoice from "./Customer_Invoice";
import * as action from "../../../action/Customer/customer_action";
import "../../../assets/css/users/user-profile.css";
import "../../../assets/plugins/table/datatable/datatables.css";
import "../../../assets/plugins/table/datatable/dt-global_style.css";
import $ from "jquery";
const View_Customer = (props) => {
  $.DataTable = require("datatables.net");
  const navigate = useNavigate();
  const { cust_id } = useParams();
  const [errors, setErrors] = useState({});
  const gstNumberFocus = useRef();
  const nameFocus = useRef();
  const ownershipFocus = useRef();
  const businessNameFocus = useRef();
  const businessTypeFocus = useRef();
  const emailFocus = useRef();
  const mobileFocus = useRef();
  const paymentTypeFocus = useRef();
  const planFocus = useRef();
  const [customerDetails, setCustomerDetails] = useState({});
  const [planRow, setPlanRow] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [editButtonHide, setEditButtonHide] = useState(false);
  const [addPlanButtonHide, setAddPlanButtonHide] = useState(true);
  const [cancelButtonHide, setCancelButtonHide] = useState(true);
  const [submitButtonHide, setSubmitButtonHide] = useState(true);
  const [currentTab, setCurrentTab] = useState("view");
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.editCustomerReducer);
  useEffect(() => {
    dispatch(action.customer_details(cust_id))
      .then((data) => {
        setCustomerDetails(data.data);
        let newPlanList = [];
        let planCount = 1;
        data.data.plans.map((item) => {
          if (data.data.paymentType === 2) {
            newPlanList.push({
              id: planCount,
              plan: item.planName,
              customer_price: item.planPriceCustomer,
              days: item.planDays,
              driver_price: item.planPriceDriver,
              planUniqueId: item.planUniqueId,
            });
          } else {
            newPlanList.push({
              id: planCount,
              plan: item.planName,
              customer_price: item.planPriceCustomer,
              days: item.planDays,
              driver_price: item.planPriceDriver,
              planUniqueId: item.planUniqueId,
            });
          }
          planCount++;
        });
        setPlanRow(newPlanList);
      })
      .catch({});
  }, []);
  const changePaymentType = (e) => {
    let payment_type = e.target.value;
    setCustomerDetails({ ...customerDetails, paymentType: payment_type });
    let newPlan = [];
    let planCount = 1;
    planRow.map((item) => {
      newPlan.push({
        id: planCount,
        plan: item.plan,
        customer_price: item.customer_price,
        days: "",
        driver_price: "",
        planUniqueId: item.planUniqueId,
      });
      planCount++;
    });
    setPlanRow(newPlan);
  };
  const addExtraPlanInForm = () => {
    setPlanRow([
      ...planRow,
      {
        id: planRow.length + 1,
        plan: "",
        customer_price: "",
        days: "",
        driver_price: "",
        planUniqueId: "",
      },
    ]);
  };
  const setEditFormEditable = (type) => {
    if (type) {
      setDisabled(false);
      setAddPlanButtonHide(false);
      setCancelButtonHide(false);
      setSubmitButtonHide(false);
      setEditButtonHide(true);
    } else {
      setDisabled(true);
      setAddPlanButtonHide(true);
      setCancelButtonHide(true);
      setSubmitButtonHide(true);
      setEditButtonHide(false);
    }
  };
  const addPlanValue = (e, planId) => {
    let oldPlan = planRow.filter((item) => item.id === planId)?.[0];
    oldPlan = { ...oldPlan, [e.target.name]: e.target.value };
    let newPlan = [];
    planRow.map((item) => {
      if (item.id === planId) {
        newPlan.push(oldPlan);
      } else newPlan.push(item);
    });
    setPlanRow(newPlan);
  };
  const onEditSubmitHandler = (e) => {
    e.preventDefault();
    setErrors({});
    let customErrors = {};
    let planErrors = {};
    planRow.map((item) => {
      let errors = {};
      if (customerDetails.paymentType == 2) {
        if (item.plan === "") {
          errors = { ...errors, plan: "Please enter plan name" };
        }
        if (item.customer_price === "") {
          errors = { ...errors, customer_price: "Please enter customer price" };
        }
        if (item.driver_price === "") {
          errors = { ...errors, driver_price: "Please enter driver price" };
        }
      } else {
        if (item.plan === "") {
          errors = { ...errors, plan: "Please enter plan name" };
        }
        if (item.customer_price === "") {
          errors = { ...errors, customer_price: "Please enter customer price" };
        }
        if (item.days === "") {
          errors = { ...errors, days: "Please enter days" };
        }
      }
      if (Object.keys(errors).length > 0) {
        planErrors[item.id] = errors;
      }
    });
    if (
      !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(
        customerDetails.gstNumber
      )
    ) {
      customErrors = {
        ...customErrors,
        gstNumber: "Please enter valid GST Number",
      };
      gstNumberFocus.current.focus();
    } else if (customerDetails.name === "") {
      customErrors = { ...customErrors, name: "Please enter name" };
      nameFocus.current.focus();
    } else if (customerDetails.ownership === "") {
      customErrors = { ...customErrors, ownership: "Please enter ownership" };
      ownershipFocus.current.focus();
    } else if (customerDetails.businessName === "") {
      customErrors = {
        ...customErrors,
        businessName: "Please enter business name",
      };
      businessNameFocus.current.focus();
    } else if (customerDetails.businessType === "") {
      customErrors = {
        ...customErrors,
        businessType: "Please enter business type",
      };
      businessTypeFocus.current.focus();
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        customerDetails.email
      )
    ) {
      customErrors = {
        ...customErrors,
        email: "Plase enter valid email address",
      };
      emailFocus.current.focus();
    } else if (!/^[6789]\d{9}$/.test(customerDetails.mobile)) {
      customErrors = {
        ...customErrors,
        mobile: "Please enter valid mobile number",
      };
      mobileFocus.current.focus();
    } else if (customerDetails.paymentType === "") {
      customErrors = {
        ...customErrors,
        paymentType: "Please select payment type",
      };
      paymentTypeFocus.current.focus();
    } else if (Object.keys(planErrors).length > 0) {
      customErrors = { ...customErrors, plan: planErrors };
      planFocus.current.focus();
    }
    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }
    let plan = [];
    planRow.map((item) => {
      if (parseInt(customerDetails.paymentType) === 2) {
        plan.push({
          planUniqueId: item.planUniqueId,
          planName: item.plan,
          planPriceCustomer: item.customer_price,
          planPriceDriver: item.driver_price,
        });
      } else {
        plan.push({
          planUniqueId: item.planUniqueId,
          planName: item.plan,
          planPriceCustomer: item.customer_price,
          planDays: item.days,
        });
      }
    });
    let parameter = {
      id: cust_id,
      gstNumber: customerDetails.gstNumber,
      colorCode: customerDetails.colorCode,
      name: customerDetails.name,
      ownership: customerDetails.ownership,
      businessName: customerDetails.businessName,
      businessType: customerDetails.businessType,
      email: customerDetails.email,
      mobile: customerDetails.mobile,
      paymentType: customerDetails.paymentType,
      plans: plan,
      isActive: parseInt(customerDetails.isActive),
    };
    dispatch(action.edit_customer(parameter))
      .then((data) => {
        toast.success(data.msg);
        setEditFormEditable(false);
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Sub_Header />
      <div className="main-container" id="container">
        {loading ? <Loader /> : null}
        <div className="overlay"></div>
        <div className="search-overlay"></div>

        <SideBar></SideBar>
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                <div className="widget-content widget-content-area br-6">
                  <ul
                    className="nav nav-tabs  mb-3 mt-3"
                    id="simpletab"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="view-tab"
                        style={{ cursor: "pointer" }}
                        data-toggle="tab"
                        onClick={() => setCurrentTab("view")}
                        role="tab"
                        aria-controls="view"
                        aria-selected="true"
                      >
                        View
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="addresses-tab"
                        style={{ cursor: "pointer" }}
                        data-toggle="tab"
                        onClick={() => setCurrentTab("addresses")}
                        role="tab"
                        aria-controls="addresses"
                        aria-selected="false"
                      >
                        Addresses
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="shifts-tab"
                        style={{ cursor: "pointer" }}
                        data-toggle="tab"
                        onClick={() => setCurrentTab("shifts")}
                        role="tab"
                        aria-controls="shifts"
                        aria-selected="false"
                      >
                        Shifts
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="invoice-tab"
                        style={{ cursor: "pointer" }}
                        data-toggle="tab"
                        onClick={() => setCurrentTab("invoice")}
                        role="tab"
                        aria-controls="invoice"
                        aria-selected="false"
                      >
                        Generate Invoice
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="invoice-list-tab"
                        style={{ cursor: "pointer" }}
                        data-toggle="tab"
                        onClick={() => setCurrentTab("invoiceList")}
                        role="tab"
                        aria-controls="invoice-list"
                        aria-selected="false"
                      >
                        Invoice List
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="transactions-tab"
                        style={{ cursor: "pointer" }}
                        data-toggle="tab"
                        onClick={() => setCurrentTab("transactions")}
                        role="tab"
                        aria-controls="transactions"
                        aria-selected="false"
                      >
                        Ledger
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content" id="simpletabContent">
                    {currentTab === "view" ? (
                      <div id="view" role="tabpanel" aria-labelledby="view-tab">
                        <div className="pb-5">
                          <span
                            id="editidspan"
                            className="btn btn-warning btn-sm float-right ms-3 mb-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => setEditFormEditable(true)}
                            hidden={editButtonHide}
                          >
                            {" "}
                            Edit{" "}
                          </span>
                        </div>
                        <form onSubmit={onEditSubmitHandler}>
                          <div className="form-group row">
                            <div className="form-group col-md-9 required">
                              <label for="inputGstNumber">GST Number *</label>
                              <input
                                type="text"
                                className="form-control"
                                ref={gstNumberFocus}
                                id="gstNumber"
                                name="gstNumber"
                                placeholder="Enter a valid GST Number"
                                required
                                value={customerDetails.gstNumber}
                                disabled={disabled}
                                onChange={(e) =>
                                  setCustomerDetails({
                                    ...customerDetails,
                                    gstNumber: e.target.value,
                                  })
                                }
                              />
                              <span style={{ color: "red" }}>
                                {errors?.gstNumber}
                              </span>
                            </div>
                            <div className="form-group col-md-3 required">
                              <label for="inputColorCode">Color Code *</label>
                              <div className="input-group">
                                <input
                                  type="color"
                                  disabled={disabled}
                                  className="form-control"
                                  id="inputColorCode"
                                  name="colorCode"
                                  placeholder="Enter a valid Color Code"
                                  required
                                  onChange={(e) =>
                                    setCustomerDetails({
                                      ...customerDetails,
                                      colorCode: e.target.value,
                                    })
                                  }
                                  value={customerDetails.colorCode}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="form-group col-md-6 required">
                              <label for="inputFullname">Full Name *</label>
                              <input
                                type="text"
                                className="form-control"
                                ref={nameFocus}
                                name="name"
                                id="name"
                                placeholder="Enter Full Name"
                                value={customerDetails.name}
                                disabled={disabled}
                                onChange={(e) =>
                                  setCustomerDetails({
                                    ...customerDetails,
                                    name: e.target.value,
                                  })
                                }
                              />
                              <span style={{ color: "red" }}>
                                {errors?.name}
                              </span>
                            </div>
                            <div className="form-group col-md-6 required">
                              <label for="inputOwnership">OwnerShip *</label>
                              <input
                                type="text"
                                className="form-control"
                                ref={ownershipFocus}
                                id="ownership"
                                name="ownership"
                                placeholder="i.e. Proprietorship"
                                value={customerDetails.ownership}
                                disabled={disabled}
                                onChange={(e) =>
                                  setCustomerDetails({
                                    ...customerDetails,
                                    ownership: e.target.value,
                                  })
                                }
                              />
                              <span style={{ color: "red" }}>
                                {errors?.ownership}
                              </span>
                            </div>
                          </div>

                          <div className="form-group row">
                            <div className="form-group col-md-6 required">
                              <label for="inputBusinessName">
                                Business Name *
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                ref={businessNameFocus}
                                name="businessName"
                                id="businessName"
                                placeholder="Enter Business Name"
                                value={customerDetails.businessName}
                                disabled={disabled}
                                onChange={(e) =>
                                  setCustomerDetails({
                                    ...customerDetails,
                                    businessName: e.target.value,
                                  })
                                }
                              />
                              <span style={{ color: "red" }}>
                                {errors?.businessName}
                              </span>
                            </div>
                            <div className="form-group col-md-6 required">
                              <label for="inputBusinessType">
                                Business Type *
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                ref={businessTypeFocus}
                                name="businessType"
                                id="businessType"
                                placeholder="i.e. Retail Business"
                                value={customerDetails.businessType}
                                disabled={disabled}
                                onChange={(e) =>
                                  setCustomerDetails({
                                    ...customerDetails,
                                    businessType: e.target.value,
                                  })
                                }
                              />
                              <span style={{ color: "red" }}>
                                {errors?.businessType}
                              </span>
                            </div>
                          </div>

                          <div className="form-group row">
                            <div className="form-group col-md-6 required">
                              <label for="inputEmail">Email </label>
                              <label
                                className="form-error-msg"
                                id="emailErrorMsg"
                              ></label>
                              <input
                                type="text"
                                className="form-control"
                                ref={emailFocus}
                                id="email"
                                placeholder="Enter Valid Email Address"
                                name="email"
                                value={customerDetails.email}
                                disabled={disabled}
                                onChange={(e) =>
                                  setCustomerDetails({
                                    ...customerDetails,
                                    email: e.target.value,
                                  })
                                }
                              />
                              <span style={{ color: "red" }}>
                                {errors?.email}
                              </span>
                            </div>
                            <div className="form-group col-md-6 required">
                              <label for="inputMobile">Mobile Number *</label>
                              <label
                                className="form-error-msg"
                                id="mobileErrorMsg"
                              ></label>
                              <input
                                type="text"
                                name="mobile"
                                ref={mobileFocus}
                                className="form-control"
                                id="inputMobile"
                                placeholder="Enter Mobile Number"
                                required
                                value={customerDetails.mobile}
                                disabled={disabled}
                                onChange={(e) =>
                                  setCustomerDetails({
                                    ...customerDetails,
                                    mobile: e.target.value,
                                  })
                                }
                              />
                              <span style={{ color: "red" }}>
                                {errors?.mobile}
                              </span>
                            </div>
                          </div>

                          <div className="form-group row">
                            <div className="form-group col-md-6 required">
                              <label for="inputPaymentType">
                                Select Payment Type *
                              </label>
                              <select
                                disabled={disabled}
                                ref={paymentTypeFocus}
                                name="inputPaymentType"
                                id="inputPaymentType"
                                className="form-control selectpicker"
                                onchange="updatePaymentInputFields(this)"
                                required
                                onChange={(e) => changePaymentType(e)}
                              >
                                {customerDetails.paymentType === 1 ? (
                                  <option value="1" selected>
                                    Monthly
                                  </option>
                                ) : (
                                  <option value="1">Monthly</option>
                                )}
                                {customerDetails.paymentType === 2 ? (
                                  <option value="2" selected>
                                    Per Parcel
                                  </option>
                                ) : (
                                  <option value="2">Per Parcel</option>
                                )}
                              </select>
                              <span style={{ color: "red" }}>
                                {errors?.paymentType}
                              </span>
                            </div>
                          </div>

                          <div className="form-group" id="payment-div-id">
                            <div className="form-group row" id="plan0">
                              {planRow.map((planItem) => (
                                <>
                                  <div
                                    className="form-group col-md-4 required"
                                    ref={planFocus}
                                    tabIndex="-1"
                                  >
                                    <label for={`inputPlanName${planItem.id}`}>
                                      Plan *
                                    </label>
                                    {planItem.id === 1 ? (
                                      <span
                                        hidden={addPlanButtonHide}
                                        className="btn btn-warning btn-sm float-right ms-3 mb-1"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => addExtraPlanInForm()}
                                      >
                                        {" "}
                                        Add Extra Plan{" "}
                                      </span>
                                    ) : (
                                      ""
                                    )}

                                    <input
                                      disabled={disabled}
                                      type="text"
                                      className="form-control"
                                      id={`inputPlanName${planItem.id}`}
                                      placeholder="Enter Plan"
                                      name="plan"
                                      value={planItem.plan}
                                      onChange={(e) =>
                                        addPlanValue(e, planItem.id)
                                      }
                                    />
                                    <span style={{ color: "red" }}>
                                      {errors.plan
                                        ? errors.plan[planItem.id]?.plan
                                          ? errors.plan[planItem.id]?.plan
                                          : ""
                                        : ""}
                                    </span>
                                  </div>
                                  <div className="form-group col-md-4 required">
                                    <label
                                      for={`inputPriceCustomer${planItem.id}`}
                                    >
                                      Customer Price *
                                    </label>
                                    <input
                                      disabled={disabled}
                                      type="number"
                                      className="form-control allowdecimal"
                                      name="customer_price"
                                      value={planItem.customer_price}
                                      id={`inputPriceCustomer${planItem.id}`}
                                      placeholder="Enter Customer Price"
                                      maxlength="10"
                                      onChange={(e) =>
                                        addPlanValue(e, planItem.id)
                                      }
                                    />
                                    <span style={{ color: "red" }}>
                                      {errors.plan
                                        ? errors.plan[planItem.id]
                                            ?.customer_price
                                          ? errors.plan[planItem.id]
                                              ?.customer_price
                                          : ""
                                        : ""}
                                    </span>
                                  </div>
                                  {customerDetails.paymentType == 1 ? (
                                    <div className="form-group col-md-4 days-div required">
                                      <label for={`inputDays${planItem.id}`}>
                                        Days *
                                      </label>
                                      <input
                                        disabled={disabled}
                                        type="number"
                                        className="form-control"
                                        id={`inputDays${planItem.id}`}
                                        name="days"
                                        value={planItem.days}
                                        placeholder="enter days"
                                        max="30"
                                        min="0"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.keyCode === 8;"
                                        onChange={(e) =>
                                          addPlanValue(e, planItem.id)
                                        }
                                      />
                                      <span style={{ color: "red" }}>
                                        {errors.plan
                                          ? errors.plan[planItem.id]?.days
                                            ? errors.plan[planItem.id]?.days
                                            : ""
                                          : ""}
                                      </span>
                                    </div>
                                  ) : customerDetails.paymentType == 2 ? (
                                    <div className="form-group col-md-4 driver-price-div required">
                                      <label
                                        for={`inputPriceDriver${planItem.id}`}
                                      >
                                        Driver Price *
                                      </label>
                                      <input
                                        disabled={disabled}
                                        type="number"
                                        name="driver_price"
                                        value={planItem.driver_price}
                                        className="form-control allowdecimal"
                                        id={`inputPriceDriver${planItem.id}`}
                                        placeholder="Enter Driver Price"
                                        maxlength="10"
                                        onChange={(e) =>
                                          addPlanValue(e, planItem.id)
                                        }
                                      />
                                      <span style={{ color: "red" }}>
                                        {errors.plan
                                          ? errors.plan[planItem.id]
                                              ?.driver_price
                                            ? errors.plan[planItem.id]
                                                ?.driver_price
                                            : ""
                                          : ""}
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="form-group col-md-4 days-div required">
                                      <label for={`inputDays${planItem.id}`}>
                                        Days *
                                      </label>
                                      <input
                                        disabled={disabled}
                                        type="number"
                                        className="form-control"
                                        id={`inputDays${planItem.id}`}
                                        name="days"
                                        value={planItem.days}
                                        placeholder="enter days"
                                        max="30"
                                        min="0"
                                        onChange={(e) =>
                                          addPlanValue(e, planItem.id)
                                        }
                                      />
                                      <span style={{ color: "red" }}>
                                        {errors.plan
                                          ? errors.plan[planItem.id]?.days
                                            ? errors.plan[planItem.id]?.days
                                            : ""
                                          : ""}
                                      </span>
                                    </div>
                                  )}
                                </>
                              ))}
                            </div>
                          </div>

                          <div className="form-group required">
                            <label>Customer Status *</label>
                            <div
                              className="custom-control custom-radio custom-control-inline"
                              id="customerStatus"
                            >
                              <input
                                disabled={disabled}
                                type="radio"
                                name="isActive"
                                onChange={(e) =>
                                  setCustomerDetails({
                                    ...customerDetails,
                                    isActive: e.target.value,
                                  })
                                }
                                id="status1"
                                className="custom-control-input"
                                value="1"
                                checked
                              />
                              <label
                                className="custom-control-label status-radio"
                                for="status1"
                              >
                                Active
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                disabled={disabled}
                                type="radio"
                                id="status0"
                                name="isActive"
                                onChange={(e) =>
                                  setCustomerDetails({
                                    ...customerDetails,
                                    isActive: e.target.value,
                                  })
                                }
                                className="custom-control-input"
                                value="0"
                              />
                              <label
                                className="custom-control-label status-radio"
                                for="status0"
                              >
                                Deactive
                              </label>
                            </div>
                          </div>

                          <div className="form-group" id="saveAndCancelButtons">
                            <button
                              hidden={cancelButtonHide}
                              className="btn btn-danger mt-3"
                              onClick={() => setEditFormEditable(false)}
                              type="button"
                            >
                              Cancel
                            </button>
                            <button
                              hidden={submitButtonHide}
                              type="submit"
                              className="btn btn-success mt-3 ml-3 view-btn"
                            >
                              Update
                            </button>
                          </div>
                        </form>
                      </div>
                    ) : null}
                    {currentTab === "addresses" ? (
                      <div id="addresses" aria-labelledby="addresses-tab">
                        {/* <div className="table-responsive mb-4 mt-4">
                                        <table id="customer-addresses-list" className="table table-hover" style={{width:'100%'}}>
                                            <thead>
                                                <tr>
                                                    <th>Address</th>
                                                    <th>Pincode</th>
                                                    <th>City</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            </tbody>
                                        </table>
                                    </div> */}
                        <Customer_Addresses customer={cust_id} />
                      </div>
                    ) : null}
                    {currentTab === "shifts" ? (
                      <div
                        role="tabpanel"
                        id="shifts"
                        aria-labelledby="shifts-tab"
                      >
                        {/* <div className="table-responsive mb-4 mt-4">
                                            <table id="customer-shifts-list" className="table table-hover" style={{width:'100%'}}>
                                                <thead>
                                                    <tr>
                                                        <th>Shift Name</th>
                                                        <th>Start Time</th>
                                                        <th>End Time</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                </tbody>
                                            </table>
                                        </div> */}
                        <Customer_Shifts customer={cust_id} />
                      </div>
                    ) : null}
                    {currentTab === "invoice" ? (
                      <div
                        role="tabpanel"
                        id="invoice"
                        aria-labelledby="invoice-tab"
                      >
                        <Generate_Invoice customer={cust_id} />
                      </div>
                    ) : null}
                    {currentTab === "invoiceList" ? (
                      <div
                        role="tabpanel"
                        id="invoicelist"
                        aria-labelledby="invoice-list-tab"
                      >
                        <Customer_Invoice customer={cust_id} />
                      </div>
                    ) : null}
                    {currentTab === "transactions" ? (
                      <div
                        role="tabpanel"
                        id="transaction"
                        aria-labelledby="transactions-tab"
                      >
                        <div
                          className="form-group p-3 list-filter"
                          id="list-filter-id"
                        >
                          <div className="row">
                            <div className="col-md-3">
                              <label for="bankSelectFilter" className="ml-1">
                                Banks
                              </label>
                              <div>
                                <select
                                  name="bankSelectFilter"
                                  id="bankSelectFilter"
                                  className="form-control selectpicker"
                                  onchange="refreshListByBank(event);"
                                >
                                  <option value="0">All</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <label
                                for="categorySelectFilter"
                                className="ml-1"
                              >
                                Category
                              </label>
                              <div>
                                <select
                                  name="categorySelectFilter"
                                  id="categorySelectFilter"
                                  className="form-control selectpicker"
                                  onchange="refreshListByCategory(event);"
                                >
                                  <option value="0">All</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <label for="typeSelectFilter" className="ml-1">
                                Type
                              </label>
                              <div>
                                <select
                                  name="typeSelectFilter"
                                  id="typeSelectFilter"
                                  className="form-control selectpicker"
                                  onchange="refreshListByType(event);"
                                >
                                  <option value="0">All</option>
                                  <option value="2">Expense</option>
                                  <option value="1">Income</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-5">
                              <label for="typeSelectFilter" className="ml-1">
                                Date
                              </label>
                              <div className="row">
                                <select
                                  name="transactionDateSelectFilter"
                                  id="transactionDateSelectFilter"
                                  className="selectpicker"
                                  data-width="160px"
                                  onchange="refreshListByDateFilter(event, 'transactionDateSelectFilter', 'transactionDateFilter');"
                                >
                                  <option value="0">All</option>
                                  <option value="1">Today</option>
                                  <option value="2">Current Month</option>
                                  <option value="3">Last Month</option>
                                  <option value="4">Last 30 Days</option>
                                  <option value="5">Last 60 Days</option>
                                  <option value="6">Custom Range</option>
                                </select>
                                <input
                                  id="transactionDateFilter"
                                  className="form-control flatpickr flatpickr-input active col-md-6 ml-3"
                                  type="text"
                                  placeholder="Select Date.."
                                  autocomplete="off"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3" id="exportBttonsId">
                            <button
                              type="button"
                              className="btn btn-warning btn-sm view-btn ml-3"
                              onclick="exportData('csv')"
                            >
                              Export CSV
                            </button>
                            <button
                              type="button"
                              className="btn btn-warning btn-sm view-btn ml-3"
                              onclick="exportData('excel')"
                            >
                              Export Excel
                            </button>
                          </div>
                        </div>
                        <div className="table-responsive mb-4 mt-4">
                          <table
                            id="customer-transactions-list"
                            className="table table-hover"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th>Amount</th>
                                <th>Date</th>
                                <th>Details</th>
                                <th>Category & Type</th>
                                <th>Bill No</th>
                                <th>Description</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody></tbody>
                          </table>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div
                    className="modal fade"
                    id="markAsPaidModalCenter"
                    role="dialog"
                    aria-labelledby="markAsPaidModalCenterTitle"
                    style={{ display: "none" }}
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered modal-lg"
                      role="document"
                    >
                      <div className="modal-content">
                        <form>
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="markAsPaidModalCenterTitle"
                            >
                              Mark As Payment Paid
                            </h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <svg
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-x"
                              >
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                              </svg>
                            </button>
                          </div>

                          <div className="modal-body">
                            <div
                              className="customer-markaspaid-class"
                              id="customer-markaspaid-div-id"
                            >
                              <div
                                className="form-group"
                                id="defaultmarkaspaiddiv"
                              >
                                <div className="form-group row">
                                  <div className="form-group col-md-12">
                                    <label
                                      className="form-group form-error-msg"
                                      id="invoicePaymentCategotyErrorMsg"
                                    ></label>
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label for="inputPaymentDate">
                                      Payment Date *
                                    </label>
                                    <input
                                      id="inputPaymentDate"
                                      className="form-control flatpickr flatpickr-input active"
                                      type="text"
                                      placeholder="Select Date.."
                                      required
                                    />
                                  </div>
                                  <div className="form-group col-md-6 required">
                                    <label for="inputBankAccount">
                                      Bank Account *
                                    </label>
                                    <div className="form-group">
                                      <select
                                        name="inputBankAccount"
                                        id="inputBankAccount"
                                        className="form-control selectpicker"
                                        required
                                      ></select>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <div className="form-group col-md-6">
                                    <label for="inputPaymentMethod">
                                      Payment Method *
                                    </label>
                                    <select
                                      name="inputPaymentMethod"
                                      id="inputPaymentMethod"
                                      className="form-control selectpicker"
                                      onchange="updateMarkAsPaidFieldsOnPaymentMethodUpdate(this.id)"
                                      required
                                    >
                                      <option value="CHEQUE">CHEQUE</option>
                                      <option value="RTGS">RTGS</option>
                                      <option value="NEFT">NEFT</option>
                                      <option value="CASH">CASH</option>
                                      <option value="CARD">CARD</option>
                                      <option value="ONLINE">ONLINE</option>
                                    </select>
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label for="inputChequeNo">
                                      Cheque Number
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="inputChequeNo"
                                      placeholder="Enter Cheque No"
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <div className="form-group col-md-12">
                                    <label for="inputPaymentComment">
                                      Payment Comment
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="inputPaymentComment"
                                      placeholder="Enter Payment Comment"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="modal-footer">
                            <button className="btn" data-dismiss="modal">
                              <i className="flaticon-cancel-12"></i>Discard
                            </button>
                            <button
                              type="button"
                              id="markAsPaymentPaidBtn"
                              className="btn btn-primary view-btn"
                              onclick="markAsPaymentPaid(event)"
                            >
                              Paid
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </>
  );
};
export default View_Customer;
