import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { format } from "date-fns";
import Footer from "../../DashBoard/Footer";
import Header from "../../DashBoard/Header";
import Sub_Header from "../../DashBoard/Sub_Header";
import SideBar from "../../DashBoard/SideBar";
import Loader from "../../common/Loader/Loader";
import { GoogleMap, LoadScriptNext } from "@react-google-maps/api";
import { Marker, InfoWindow } from "@react-google-maps/api";
import "../../../assets/css/users/user-profile.css";
import "../../../assets/plugins/table/datatable/datatables.css";
import "../../../assets/plugins/table/datatable/dt-global_style.css";
import * as action from "../../../action/VehicleHub/vehicle_hub_action";
import * as login_action from "../../../action/Auth/login_action";
import $ from "jquery";
import { renderToString } from "react-dom/server";
function Vehicle_Telematics_Unit() {
  const inputRef = useRef();

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      console.log(place.formatted_address);
      console.log(place.geometry.location.lat());
      console.log(place.geometry.location.lng());
    }
  };
  $.DataTable = require("datatables.net");
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.VehicleHubListReducer);
  const { Addloading } = useSelector((state) => state.AddVehicleHubReducer);
  const { Editloading } = useSelector((state) => state.VehicleHubEditReducer);
  const tableRef = useRef();
  const [disabled, setDisabled] = useState(true);
  const [editButtonHide, setEditButtonHide] = useState(false);
  const [center, setCenter] = useState({ lat: 21.17024, lng: 72.831062 });
  const [cancelButtonHide, setCancelButtonHide] = useState(true);
  const [submitButtonHide, setSubmitButtonHide] = useState(true);
  const [hubList, setHubList] = useState([]);
  const [hubManagerList, setHubManagerList] = useState([]);
  const [selectedHubManagerList, setSelectedHubManagerList] = useState({});
  const [cityList, setCityList] = useState([]);
  const [values, setValues] = useState({
    cityValue: "",
    editCityValue: "",
  });
  const [errors, setErrors] = useState({});
  const nameFocus = useRef();
  const cityFocus = useRef();
  const locationFocus = useRef();
  const hubInchargeFocus = useRef();
  const latitudeFocus = useRef();
  const longitudeFocus = useRef();
  const nameEditFocus = useRef();
  const cityEditFocus = useRef();
  const locationEditFocus = useRef();
  const hubInchargeEditFocus = useRef();
  const latitudeEditFocus = useRef();
  const longitudeEditFocus = useRef();
  const [addHub, setAddHub] = useState({
    name: "",
    city: "",
    cityId: "",
    location: "",
    hubIncharge: "",
    noOfVehicles: 0,
    latitude: "",
    longitude: "",
    isActive: 1,
  });
  const [editHub, setEditHub] = useState({
    id: "",
    name: "",
    city: "",
    cityId: "",
    location: "",
    hubIncharge: "",
    noOfVehicles: 0,
    latitude: "",
    longitude: "",
    isActive: 1,
  });
  const changeCityDropDown = (data) => {
    setValues({ ...values, cityValue: data });
    setAddHub({ ...addHub, cityId: data.value, city: data.label });
  };
  const editChangeCityDropDown = (data) => {
    setValues({ ...values, editCityValue: data });
    setEditHub({ ...editHub, cityId: data.value, city: data.label });
  };
  const customFilter = (option, inputValue) => {
    const input = inputValue.toLowerCase();
    const filterText = new RegExp("^" + input + ".*");
    const label = option.data.label.toLowerCase();
    return filterText.test(label) && option.label.toLowerCase().includes(input);
  };
  const setEditFormEditable = (type) => {
    if (type) {
      setDisabled(false);
      setCancelButtonHide(false);
      setSubmitButtonHide(false);
      setEditButtonHide(true);
    } else {
      setDisabled(true);
      setCancelButtonHide(true);
      setSubmitButtonHide(true);
      setEditButtonHide(false);
    }
  };
  const setUpdateHub = (hub) => {
    let new_hub = hub[6];
    setEditHub({
      id: new_hub._id,
      name: new_hub.name,
      city: new_hub.city,
      cityId: new_hub.cityId,
      location: new_hub.location,
      noOfVehicles: new_hub.noOfVehicles,
      hubIncharge: new_hub.hubIncharge,
      latitude: new_hub.latitude,
      longitude: new_hub.longitude,
      isActive: parseInt(new_hub.isActive),
    });
    setCenter({ lat: new_hub.latitude, lng: new_hub.longitude });
    setSelectedHubManagerList({
      value: new_hub.hubAdmin._id,
      label: new_hub.hubAdmin.name,
    });
    setValues({
      ...values,
      editCityValue: { value: new_hub.cityId, label: new_hub.city },
    });
  };
  const changeLetLong = (e) => {
    let latitude = e.latLng.lat();
    let longitude = e.latLng.lng();
    setCenter({ lat: latitude, lng: longitude });
    setAddHub({ ...addHub, latitude: latitude, longitude: longitude });
  };
  const changeUpdateLetLong = (e) => {
    let latitude = e.latLng.lat();
    let longitude = e.latLng.lng();
    setCenter({ lat: latitude, lng: longitude });
    setEditHub({ ...editHub, latitude: latitude, longitude: longitude });
  };
  const changeDateFormate = (date) => {
    if (date) {
      let new_date = String(date);
      let ndate = new_date.split("T");
      return ndate[0];
    }
  };
  const activeDeactiveAlertPopup = (hub) => {
    dispatch(action.vehicle_hub_status_change(hub[5]))
      .then((data) => {
        toast.success(data.msg);
        let newHubList = [];
        hubList.map((item) => {
          if (item[5] === hub[5]) {
            let hub_data = data.data;
            newHubList.push([
              hub_data.name,
              hub_data.city,
              hub_data.location,
              hub_data.hubAdmin.name,
              `<a style="cursor: pointer;" href="/adminside/vehicleHub/view/${hub_data._id}" class="btn btn-info btn-sm view-btn view-act-btn" data-id="${hub_data._id}">View</a>`,
              hub_data._id,
              hub_data,
            ]);
          } else {
            newHubList.push(item);
          }
        });
        setHubList(newHubList);
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  useEffect(() => {
    dispatch(action.vehicle_hub_manager_list())
      .then((data) => {
        let newHubManagerList = [];
        data.data.map((item) => {
          newHubManagerList.push({
            value: item._id,
            label: item.name,
          });
        });
        setHubManagerList(newHubManagerList);
      })
      .catch({});
  }, []);
  useEffect(() => {
    dispatch(login_action.city_list())
      .then((data) => {
        let newCityList = [];
        data.data.map((item) => {
          newCityList.push({
            value: item.cityId,
            label: item.cityName,
          });
        });
        setCityList(newCityList);
      })
      .catch({});
  }, []);
  const containerStyle = {
    width: "100%",
    height: "400px",
  };
  const changeHubManagerAddDropDown = (data) => {
    const hub_manager_id = data.value;
    setAddHub({ ...addHub, hubIncharge: hub_manager_id });
    setSelectedHubManagerList(data);
  };
  const updateHubManagerAddDropDown = (data) => {
    const hub_manager_id = data.value;
    setEditHub({ ...editHub, hubIncharge: hub_manager_id });
    setSelectedHubManagerList(data);
  };
  const ClearModelData = () => {
    setAddHub({
      name: "",
      city: "",
      cityId: "",
      location: "",
      hubIncharge: "",
      noOfVehicles: 0,
      latitude: "",
      longitude: "",
      isActive: 1,
    });
    setValues({ cityValue: "", editCityValue: "" });
  };
  const tableName = "vehicle-hub-list";
  useEffect(() => {
    dispatch(action.vehicle_hub_list())
      .then((data) => {
        let newHubList = [];
        data.data.map((item) => {
          newHubList.push([
            item.name,
            item.city,
            item.location,
            item.hubAdmin.name,
            `<a style="cursor: pointer;" href="/adminside/vehicleHub/view/${item._id}" class="btn btn-info btn-sm view-btn view-act-btn" data-id="${item._id}">View</a>`,
            item._id,
            item,
          ]);
        });
        setHubList(newHubList);
      })
      .catch({});
  }, []);
  useEffect(() => {
    const table = $(`#${tableName}`).DataTable({
      dom: "Bfrtip",
      buttons: ["copyHtml5", "excelHtml5", "pdfHtml5", "csvHtml5"],
      data: hubList,
      columns: [
        { title: "Name" },
        { title: "City" },
        { title: "Location" },
        { title: "Hub In-charge" },
        // { title: "No. Of Vehicles"},
        // { title: "Start Time"},
        // { title: "End Time"},
        // { title: "Status"},
        { title: "Action" },
      ],
      columnDefs: [
        // {
        //     targets: [7],
        //     className: 'center',
        //     createdCell: (td, cellData, rowData) => ReactDOM.render(
        //         <a style={{cursor: "pointer"}} className={rowData[7] === 1 ? 'btn btn-success btn-sm status-act-btn' : 'btn btn-danger btn-sm status-act-btn'} onClick={() => activeDeactiveAlertPopup(rowData)}> {rowData[7] === 1 ? 'Active' : 'Deactive'} </a>,td
        //     )
        // },
        {
          targets: [4],
          className: "center",
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <a
                style={{ cursor: "pointer" }}
                data-toggle="modal"
                onClick={() => setUpdateHub(rowData)}
                data-target="#editHubModalCenter"
                class="btn btn-info btn-sm view-btn view-act-btn"
              >
                View
              </a>,
              td
            ),
        },
      ],
      destroy: true, // I think some clean up is happening here
      searching: true,
      oLanguage: {
        oPaginate: {
          sPrevious:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>',
          sNext:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>',
        },
        //sInfo: "Showing page _PAGE_ of _PAGES_",
        sSearch:
          '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
        sSearchPlaceholder: "Search...",
        sLengthMenu: "Results :  _MENU_",
      },
      fnInitComplete: function (oSettings) {
        $("#vehicle-hub-list_filter").prepend(
          `<button type="button" id="ClearData" class="btn btn-warning btn-sm add-act-btn" data-toggle="modal" data-target="#addHubModalCenter">Add Hub</button>`
        );
        const myButton = document.querySelector("#ClearData");
        myButton.addEventListener("click", ClearModelData);
      },
    });
    // Extra step to do extra clean-up.
    return function () {
      table.destroy();
    };
  }, [hubList]);
  const onsubmitHandler = (e) => {
    e.preventDefault();
    setErrors({});
    let customErrors = {};
    if (!/^[0-9a-zA-Z\-\s]+$/.test(addHub.name)) {
      customErrors = { ...customErrors, name: "Please enter name" };
      nameFocus.current.focus();
    } else if (addHub.city === "") {
      customErrors = { ...customErrors, city: "Please Select city" };
      cityFocus.current.focus();
    } else if (addHub.location === "") {
      customErrors = { ...customErrors, location: "Please enter address" };
      locationFocus.current.focus();
    } else if (addHub.hubIncharge === "") {
      customErrors = {
        ...customErrors,
        hubIncharge: "Please select hub manager",
      };
      hubInchargeFocus.current.focus();
    } else if (addHub.latitude === "") {
      customErrors = { ...customErrors, latitude: "Please enter latitude" };
      latitudeFocus.current.focus();
    } else if (addHub.longitude === "") {
      customErrors = { ...customErrors, longitude: "Please enter longitude" };
      longitudeFocus.current.focus();
    }
    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }
    dispatch(action.add_vehicle_hub(addHub))
      .then((data) => {
        toast.success(data.msg);
        let response = data.data;
        setHubList([
          ...hubList,
          [
            response.name,
            response.city,
            response.location,
            data.hubAdmin.name,
            `<a style="cursor: pointer;" href="/adminside/vehicleHub/view/${response._id}" class="btn btn-info btn-sm view-btn view-act-btn" data-id="${response._id}">View</a>`,
            response._id,
            response,
          ],
        ]);
        setAddHub({
          name: "",
          city: "",
          cityId: "",
          location: "",
          hubIncharge: "",
          noOfVehicles: 0,
          latitude: "",
          longitude: "",
          isActive: 1,
        });
        setValues({ cityValue: "", editCityValue: "" });
        $("#addHubModalCenter .close").click();
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  const onUpdateHandler = (e) => {
    e.preventDefault();
    setErrors({});
    let customErrors = {};
    if (!/^[0-9a-zA-Z\-\s]+$/.test(editHub.name)) {
      customErrors = { ...customErrors, editName: "Please enter name" };
      nameEditFocus.current.focus();
    } else if (editHub.city === "") {
      customErrors = { ...customErrors, editCity: "Please Select city" };
      cityEditFocus.current.focus();
    } else if (editHub.location === "") {
      customErrors = { ...customErrors, editLocation: "Please enter address" };
      locationEditFocus.current.focus();
    } else if (editHub.hubIncharge === "") {
      customErrors = {
        ...customErrors,
        editHubIncharge: "Please select hub manager",
      };
      hubInchargeEditFocus.current.focus();
    } else if (editHub.latitude === "") {
      customErrors = { ...customErrors, editLatitude: "Please enter latitude" };
      latitudeEditFocus.current.focus();
    } else if (editHub.longitude === "") {
      customErrors = {
        ...customErrors,
        editLongitude: "Please enter longitude",
      };
      longitudeEditFocus.current.focus();
    }
    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }
    dispatch(action.edit_vehicle_hub(editHub))
      .then((data) => {
        toast.success(data.msg);
        let newHubList = [];
        hubList.map((item) => {
          if (item[5] === editHub.id) {
            let hub_data = data.data;
            newHubList.push([
              hub_data.name,
              hub_data.city,
              hub_data.location,
              data.hubAdmin.name,
              `<a style="cursor: pointer;" href="/adminside/vehicleHub/view/${hub_data._id}" class="btn btn-info btn-sm view-btn view-act-btn" data-id="${hub_data._id}">View</a>`,
              hub_data._id,
              hub_data,
            ]);
          } else {
            newHubList.push(item);
          }
        });
        setHubList(newHubList);
        setEditHub({
          id: "",
          name: "",
          city: "",
          cityId: "",
          location: "",
          hubIncharge: "",
          noOfVehicles: 0,
          latitude: "",
          longitude: "",
          isActive: 1,
        });
        setEditFormEditable(false);
        setValues({ cityValue: "", editCityValue: "" });
        $("#editHubModalCenter .close").click();
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Sub_Header />
      <div className="main-container" id="container">
        {loading ? <Loader /> : null}
        {Addloading ? <Loader /> : null}
        {Editloading ? <Loader /> : null}
        <div className="overlay"></div>
        <div className="search-overlay"></div>
        <SideBar />
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                <div className="widget-content widget-content-area br-6">
                  <h5 className="" style={{ margin: "10px" }}>
                    Vehicle Hub List
                  </h5>
                  <div className="table-responsive mb-4 mt-4">
                    <table
                      id="vehicle-hub-list"
                      className="table table-hover"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>City</th>
                          <th>Address</th>
                          <th>Hub In-Charge</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
      <div
        className="modal fade"
        id="addHubModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="addHubModalCenterTitle"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <form onSubmit={onsubmitHandler}>
              <div className="modal-header">
                <h5 className="modal-title" id="addHubModalCenterTitle">
                  Add Hub
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <svg
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              </div>

              <div className="modal-body">
                <div
                  className="customer-address-class"
                  id="customer-address-div-id"
                >
                  <div className="row" id="defaultaddressdiv0">
                    <div className="col-md-12">
                      <div className="form-group required">
                        <label for="inputName-add">Name *</label>
                        <input
                          type="text"
                          className="form-control"
                          ref={nameFocus}
                          name="name"
                          id="inputName-add"
                          placeholder="Enter Name"
                          onChange={(e) =>
                            setAddHub({ ...addHub, name: e.target.value })
                          }
                          value={addHub.name}
                        />
                        <span style={{ color: "red" }}>{errors?.name}</span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group required">
                        <label for="inputCity">Select City *</label>
                        <Select
                          id="inputCity"
                          ref={cityFocus}
                          value={values.cityValue || "Select"}
                          onChange={(value) => changeCityDropDown(value)}
                          options={cityList}
                          filterOption={customFilter}
                        />
                        <span style={{ color: "red" }}>{errors?.city}</span>
                        {/* <label for="inputCity-add">City *</label>
                                                <input type="text" name="city" className="form-control" id="inputCity-add" placeholder="Enter City" required onChange={(e)=>setAddHub({...addHub ,  city:e.target.value})} value={addHub.city}/> */}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group required">
                        <label for="inputLocation-add">Address *</label>
                        <input
                          type="text"
                          name="location"
                          ref={locationFocus}
                          className="form-control"
                          id="inputLocation-add"
                          placeholder="Enter Location"
                          onChange={(e) =>
                            setAddHub({ ...addHub, location: e.target.value })
                          }
                          value={addHub.location}
                        />
                        <span style={{ color: "red" }}>{errors?.location}</span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group required">
                        <label for="inputHubInCharge-add">Hub Manager *</label>
                        <div className="form-group" ref={hubInchargeFocus}>
                          <Select
                            value={selectedHubManagerList || "Select"}
                            onChange={(value) =>
                              changeHubManagerAddDropDown(value)
                            }
                            options={hubManagerList}
                            placeholder="Select"
                          />
                          <span style={{ color: "red" }}>
                            {errors?.hubIncharge}
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-12">
                                            <div className="form-group required">
                                                <label for="inputNoOfVehicles-add">No. of Vehicles *</label>
                                                <input type="number" name="noOfVehicles" className="form-control" id="inputNoOfVehicles-add" placeholder="Enter No. of Vehicles" required onChange={(e)=>setAddHub({...addHub ,  noOfVehicles:e.target.value})} value={addHub.noOfVehicles}/>
                                            </div>
                                        </div> */}
                    {/* <div className="col-md-12">
                                            <div className="form-group required">
                                                <label for="inputStartDate-add">Start Time *</label>
                                                <input type="time" name="startDate" className="form-control" id="inputStartDate-add" placeholder="Enter Start Time" required onChange={(e)=>setAddHub({...addHub ,  startDate:e.target.value})} value={addHub.startDate}/>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group required">
                                                <label for="inputEndDate-add">End Time *</label>
                                                <input type="time" name="endDate" className="form-control" id="inputEndDate-add" placeholder="Enter End Time" onChange={(e)=>setAddHub({...addHub ,  endDate:e.target.value})} value={addHub.endDate} required/>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-12 required">
                                            <label>Vehicle Hub Status *</label>
                                            <div className="custom-control custom-radio custom-control-inline" id="vehicleStatus">
                                                <input disabled type="radio" id="status2" name="vehicleStatus" className="custom-control-input" value="1" checked/>
                                                <label className="custom-control-label status-radio" for="status2">Active</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input disabled type="radio" id="status3" name="vehicleStatus" className="custom-control-input" value="0"/>
                                                <label className="custom-control-label status-radio" for="status3">Deactive</label>
                                            </div>
                                        </div> */}
                    {/* <LoadScript googleMapsApiKey="AIzaSyB6PMCguHWiTCN0nLcEasXVxUmWOpHS5gY" libraries={["places"]}>
                                                <StandaloneSearchBox
                                                    onLoad={ref => inputRef.current = ref}
                                                    onPlacesChanged={handlePlaceChanged}
                                                >
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Location"
                                                    />
                                                </StandaloneSearchBox>
                                        </LoadScript> */}
                  </div>
                </div>
              </div>
              <div className="row p-3">
                <div className="form-group col-sm-12">
                  {/* <div className="map" id="map-add"></div> */}
                  {/* <div className="map" id="map"></div> */}
                  <LoadScriptNext googleMapsApiKey="AIzaSyB6PMCguHWiTCN0nLcEasXVxUmWOpHS5gY">
                    <GoogleMap
                      onClick={(e) => changeLetLong(e)}
                      mapContainerStyle={containerStyle}
                      center={center}
                      zoom={11}
                    >
                      {/* {
                                            currentPosition.lat &&
                                            ( 
                                                <Marker position={currentPosition}></Marker>
                                            ) 
                                        } */}
                      <Marker visible={false} zIndex={1111} position={center}>
                        <InfoWindow position={center}>
                          {addHub.longitude ? (
                            <label>
                              {" "}
                              "let" : {addHub.latitude} , "long":{" "}
                              {addHub.longitude}
                            </label>
                          ) : (
                            <label>
                              Click the map to get latitude / longitude!
                            </label>
                          )}
                        </InfoWindow>
                      </Marker>
                    </GoogleMap>
                  </LoadScriptNext>
                </div>
              </div>
              <div className="row p-3">
                <div className="col-sm-6">
                  <div className="form-group required">
                    <label for="inputLatitude-add">Latitude *</label>
                    <input
                      type="text"
                      className="form-control"
                      ref={latitudeFocus}
                      id="inputLongitude-add"
                      name="latitude"
                      placeholder="Enter Latitude"
                      onChange={(e) =>
                        setAddHub({ ...addHub, latitude: e.target.value })
                      }
                      value={addHub.latitude}
                    />
                    <span style={{ color: "red" }}>{errors?.latitude}</span>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group required">
                    <label for="inputLongitude-add">Longitude *</label>
                    <input
                      type="text"
                      className="form-control"
                      ref={longitudeFocus}
                      id="inputLongitude-add"
                      name="longitude"
                      placeholder="Enter Longitude"
                      onChange={(e) =>
                        setAddHub({ ...addHub, longitude: e.target.value })
                      }
                      value={addHub.longitude}
                    />
                    <span style={{ color: "red" }}>{errors?.longitude}</span>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn" data-dismiss="modal">
                  <i className="flaticon-cancel-12"></i>Discard
                </button>
                <button type="submit" className="btn btn-primary view-btn">
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="editHubModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="editHubModalCenterTitle"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <form onSubmit={onUpdateHandler}>
              <div className="modal-header">
                <h5 className="modal-title" id="editHubModalCenterTitle">
                  Update Vehicle Hub
                </h5>
                <button
                  type="button"
                  className="btn btn-primary view-btn"
                  hidden={editButtonHide}
                  onClick={() => setEditFormEditable(true)}
                >
                  Edit
                </button>
              </div>

              <div className="modal-body">
                <div
                  className="customer-address-class"
                  id="customer-address-div-id"
                >
                  <div className="row" id="defaultaddressdiv0">
                    <div className="col-md-12">
                      <div className="form-group required">
                        <label for="inputName-add">Name *</label>
                        <input
                          disabled={disabled}
                          type="text"
                          ref={nameEditFocus}
                          className="form-control"
                          name="name"
                          id="inputName-add"
                          placeholder="Enter Name"
                          onChange={(e) =>
                            setEditHub({ ...editHub, name: e.target.value })
                          }
                          value={editHub.name}
                        />
                        <span style={{ color: "red" }}>{errors?.editName}</span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group required">
                        <label for="inputCity-add">City *</label>
                        <Select
                          id="inputCity"
                          isDisabled={disabled}
                          ref={cityEditFocus}
                          value={values.editCityValue || "Select"}
                          onChange={(value) => editChangeCityDropDown(value)}
                          options={cityList}
                          filterOption={customFilter}
                        />
                        <span style={{ color: "red" }}>{errors?.editCity}</span>
                        {/* <input disabled={disabled} type="text" name="city" className="form-control" id="inputCity-add" placeholder="Enter City" required onChange={(e)=>setEditHub({...editHub ,  city:e.target.value})} value={editHub.city}/> */}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group required">
                        <label for="inputLocation-add">Address *</label>
                        <input
                          disabled={disabled}
                          type="text"
                          ref={locationEditFocus}
                          name="location"
                          className="form-control"
                          id="inputLocation-add"
                          placeholder="Enter Location"
                          onChange={(e) =>
                            setEditHub({ ...editHub, location: e.target.value })
                          }
                          value={editHub.location}
                        />
                        <span style={{ color: "red" }}>
                          {errors?.editLocation}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group required">
                        <label for="inputHubInCharge-add">Hub Manager *</label>
                        <div className="form-group" ref={hubInchargeEditFocus}>
                          <Select
                            isDisabled={disabled}
                            value={selectedHubManagerList || "Select"}
                            onChange={(value) =>
                              updateHubManagerAddDropDown(value)
                            }
                            options={hubManagerList}
                            placeholder="Select"
                          />
                          <span style={{ color: "red" }}>
                            {errors?.editHubIncharge}
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-12">
                                            <div className="form-group required">
                                                <label for="inputNoOfVehicles-add">No. of Vehicles *</label>
                                                <input disabled={disabled} type="number" name="noOfVehicles" className="form-control" id="inputNoOfVehicles-add" placeholder="Enter No. of Vehicles" required onChange={(e)=>setEditHub({...editHub ,  noOfVehicles:e.target.value})} value={editHub.noOfVehicles}/>
                                            </div>
                                        </div> */}
                    {/* <div className="col-md-12">
                                            <div className="form-group required">
                                                <label for="inputStartDate-add">Start Time *</label>
                                                <input disabled={disabled} type="time" name="startDate" className="form-control" id="inputStartDate-add" placeholder="Enter Start Time" required onChange={(e)=>setEditHub({...editHub ,  startDate:e.target.value})} value={editHub.startDate}/>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group required">
                                                <label for="inputEndDate-add">End Time *</label>
                                                <input disabled={disabled} type="time" name="endDate" className="form-control" id="inputEndDate-add" placeholder="Enter End Time" onChange={(e)=>setEditHub({...editHub ,  endDate:e.target.value})} value={editHub.endDate} required/>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-12 required">
                                            <label>Vehicle Hub Status *</label>
                                            <div className="custom-control custom-radio custom-control-inline" id="vehicleStatus">
                                                {
                                                    editHub.isActive === 1 ? <input disabled={disabled} type="radio" id="status1" name="vehicleStatus" className="custom-control-input" onClick={(e)=>setEditHub({...editHub ,  isActive:e.target.value})} value="1" checked/> : <input disabled={disabled} type="radio" id="status1" name="vehicleStatus" className="custom-control-input" onClick={(e)=>setEditHub({...editHub ,  isActive:e.target.value})} value="1" />
                                                }
                                                <label className="custom-control-label status-radio" for="status1">Active</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                {
                                                    editHub.isActive === 0 ? <input disabled={disabled} type="radio" id="status0" name="vehicleStatus" className="custom-control-input" value="0" onClick={(e)=>setEditHub({...editHub ,  isActive:e.target.value})} checked/> : <input disabled={disabled} type="radio" id="status0" name="vehicleStatus" className="custom-control-input" value="0" onClick={(e)=>setEditHub({...editHub ,  isActive:e.target.value})} />
                                                }
                                                <label className="custom-control-label status-radio" for="status0">Deactive</label>
                                            </div>
                                        </div> */}
                  </div>
                </div>
              </div>
              <div className="row p-3">
                <div className="form-group col-sm-12">
                  {/* <div className="map" id="map-add"></div> */}
                  {/* <div className="map" id="map"></div> */}
                  <LoadScriptNext googleMapsApiKey="AIzaSyB6PMCguHWiTCN0nLcEasXVxUmWOpHS5gY">
                    <GoogleMap
                      onClick={(e) => changeUpdateLetLong(e)}
                      mapContainerStyle={containerStyle}
                      center={center}
                      zoom={11}
                    >
                      {/* {
                                            currentPosition.lat &&
                                            ( 
                                                <Marker position={currentPosition}></Marker>
                                            ) 
                                        } */}
                      <Marker visible={false} zIndex={1111} position={center}>
                        <InfoWindow position={center}>
                          {editHub.longitude ? (
                            <label>
                              {" "}
                              "let" : {editHub.latitude} , "long":{" "}
                              {editHub.longitude}
                            </label>
                          ) : (
                            <label>
                              Click the map to get latitude / longitude!
                            </label>
                          )}
                        </InfoWindow>
                      </Marker>
                    </GoogleMap>
                  </LoadScriptNext>
                </div>
              </div>
              <div className="row p-3">
                <div className="col-sm-6">
                  <div className="form-group required">
                    <label for="inputLatitude-edit">Latitude *</label>
                    <input
                      disabled={disabled}
                      type="text"
                      ref={latitudeEditFocus}
                      className="form-control"
                      id="inputLongitude-edit"
                      name="latitude"
                      placeholder="Enter Latitude"
                      onChange={(e) =>
                        setEditHub({ ...editHub, latitude: e.target.value })
                      }
                      value={editHub.latitude}
                    />
                    <span style={{ color: "red" }}>{errors?.editLatitude}</span>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group required">
                    <label for="inputLongitude-edit">Longitude *</label>
                    <input
                      disabled={disabled}
                      type="text"
                      ref={longitudeEditFocus}
                      className="form-control"
                      id="inputLongitude-edit"
                      name="longitude"
                      placeholder="Enter Longitude"
                      onChange={(e) =>
                        setEditHub({ ...editHub, longitude: e.target.value })
                      }
                      value={editHub.longitude}
                    />
                    <span style={{ color: "red" }}>
                      {errors?.editLongitude}
                    </span>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-dark close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary view-btn"
                  hidden={submitButtonHide}
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default Vehicle_Telematics_Unit;
