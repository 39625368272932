import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { DateRangePicker } from "rsuite";
import { format } from "date-fns";
import subDays from "date-fns/subDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
import { ExportToExcel } from "./Driver_Attendance_History_Excel";
import Loader from "../../common/Loader/Loader";
import "../../../assets/css/users/user-profile.css";
import "../../../assets/plugins/table/datatable/datatables.css";
import "../../../assets/plugins/table/datatable/dt-global_style.css";
import * as action from "../../../action/Driver/driver_action";
import $ from "jquery";
const Driver_Work_history = (props) => {
  $.DataTable = require("datatables.net");
  const driver_id = props.driver_id;
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.DriverAttendanceListReduces);
  var date = new Date();
  const [input, setInput] = useState([
    new Date(date.getFullYear(), date.getMonth(), 1),
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
  ]);
  const changeDateFormate = (date) => {
    if (date) {
      let new_date = String(date);
      let ndate = new_date.split("T");
      let format_date = format(new Date(ndate[0]), "dd MMM yyyy");
      return format_date;
    }
  };
  const [driverDetails, setDriverDetail] = useState({});
  useEffect(() => {
    dispatch(action.driver_details(driver_id))
      .then((data) => {
        let driver_details = data.data;
        setDriverDetail(driver_details);
      })
      .catch({});
  }, []);
  const predefinedRanges = [
    {
      label: "Current month",
      value: [startOfMonth(new Date()), new Date()],
      placement: "left",
    },
    {
      label: "Last month",
      value: [
        startOfMonth(addMonths(new Date(), -1)),
        endOfMonth(addMonths(new Date(), -1)),
      ],
      placement: "left",
    },
    {
      label: "Last 30 days",
      value: [subDays(new Date(), 29), new Date()],
      placement: "left",
    },
    {
      label: "Last 60 days",
      value: [subDays(new Date(), 59), new Date()],
      placement: "left",
    },
  ];
  const [driverAttendancesList, setDriverAttendancesList] = useState([]);
  const [driverAttendancesListExcel, setDriverAttendancesListExcel] = useState(
    []
  );
  const [total, setTotalExcel] = useState(0);
  useEffect(() => {
    dispatch(action.driver_attendance_list(driver_id, input[0], input[1]))
      .then((data) => {
        let newDriverAttendancesList = [];
        let newDriverAttendancesListExcel = [];
        let total = 0;
        data.data.map((item) => {
          newDriverAttendancesList.push([
            changeDateFormate(item.startDate),
            item.isActive == 1
              ? `<p class="text-success">Present</p>`
              : `<p class="text-danger">Absent</p>`,
          ]);
          newDriverAttendancesListExcel.push({
            date: item.startDate,
            status: item.isActive == 1 ? "P" : "A",
          });
          if (item.isActive == 1) {
            total += 1;
          }
        });
        setTotalExcel(total);
        setDriverAttendancesList(newDriverAttendancesList);
        setDriverAttendancesListExcel(newDriverAttendancesListExcel);
      })
      .catch({});
  }, [input]);
  useEffect(() => {
    const tableName = "driver-attendances-list";
    const table = $(`#${tableName}`).DataTable({
      dom: "Bfrtip",
      buttons: ["copyHtml5", "excelHtml5", "pdfHtml5", "csvHtml5"],
      data: driverAttendancesList,
      columns: [{ title: "Date" }, { title: "Present/Absent" }],
      destroy: true, // I think some clean up is happening here
      searching: true,
      oLanguage: {
        oPaginate: {
          sPrevious:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>',
          sNext:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>',
        },
        //sInfo: "Showing page _PAGE_ of _PAGES_",
        sSearch:
          '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
        sSearchPlaceholder: "Search...",
        sLengthMenu: "Results :  _MENU_",
      },
    });
    // Extra step to do extra clean-up.
    return function () {
      table.destroy();
    };
  }, [driverAttendancesList]);
  return (
    <>
      <div class="table-responsive mb-4 mt-4">
        <div class="form-group row col-12 ml-1">
          <DateRangePicker
            ranges={predefinedRanges}
            value={input}
            onChange={setInput}
            style={{ width: 300 }}
            placeholder="Select Date Range"
          />
          &nbsp;
          {loading ? (
            <button disabled class="btn btn-success">
              <i class="fa fa-spinner fa-spin"></i>{" "}
            </button>
          ) : driverAttendancesListExcel &&
            driverAttendancesListExcel.length ? (
            <ExportToExcel
              apiData={driverAttendancesListExcel}
              driverDetails={driverDetails}
              startDate={input[0]}
              endDate={input[1]}
              total={total}
            />
          ) : (
            <div className="btn btn-success disabled">Export to XLS</div>
          )}
        </div>
        <table
          id="driver-attendances-list"
          class="table table-hover"
          style={{ width: "100%" }}
        >
          <thead>
            <tr>
              <th>Date</th>
              <th>Present/Absent</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="5">
                  <div className="d-flex justify-content-center align-items-center">
                    <i className="fa fa-spinner fa-spin m-4"></i>
                  </div>
                </td>
              </tr>
            ) : (
              ""
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default Driver_Work_history;
