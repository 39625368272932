import React, { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../DashBoard/Header";
import Sub_Header from "../../DashBoard/Sub_Header";
import SideBar from "../../DashBoard/SideBar";
import Footer from "../../DashBoard/Footer";
import Loader from "../../common/Loader/Loader";
import "../../../assets/css/users/user-profile.css";
import "../../../assets/plugins/table/datatable/datatables.css";
import "../../../assets/plugins/table/datatable/dt-global_style.css";
import * as action from "../../../action/Customer/customer_action";
import $ from "jquery";
import { renderToString } from "react-dom/server";

const Customer_List = (props) => {
  $.DataTable = require("datatables.net");
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.customerReducer);
  const tableRef = useRef();
  const [customerList, setCustomerList] = useState([]);

  const activeDeactiveAlertPopup = (customer) => {
    dispatch(action.customer_status_change(customer[5]))
      .then((data) => {
        toast.success(data.msg);
        let newCustomerList = [];
        customerList.map((item) => {
          if (item[5] === customer[5]) {
            let customer_data = data.data;
            newCustomerList.push([
              customer_data.businessName,
              customer_data.gstNumber,
              customer_data.name,
              customer_data.mobile,
              customer_data.isActive,
              customer_data._id,
              `<a style="cursor: pointer;" href="/adminside/customer/view/${customer_data._id}" class="btn btn-info btn-sm view-btn view-act-btn" data-id="${customer_data._id}">View</a>`,
            ]);
          } else {
            newCustomerList.push(item);
          }
        });
        setCustomerList(newCustomerList);
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  const tableName = "customer-list";
  useEffect(() => {
    dispatch(action.customer_list())
      .then((data) => {
        let newCustomerList = [];
        data.data.map((item) => {
          newCustomerList.push([
            item.businessName,
            item.gstNumber,
            item.name,
            item.mobile,
            item.isActive,
            item._id,
            `<a style="cursor: pointer;" href="/adminside/customer/view/${item._id}" class="btn btn-info btn-sm view-btn view-act-btn" data-id="${item._id}">View</a>`,
          ]);
        });
        setCustomerList(newCustomerList);
      })
      .catch({});
  }, []);
  useEffect(() => {
    const table = $(`#${tableName}`).DataTable({
      data: customerList,
      columns: [
        { title: "Business Name" },
        { title: "GST Number" },
        { title: "Full Name" },
        { title: "Mobile" },
        { title: "Status" },
        { title: "Customer_Id" },
        { title: "Action" },
      ],
      columnDefs: [
        {
          targets: [4],
          className: "center",
          createdCell: (td, cellData, rowData) => {
            const root = createRoot(td);
            root.render(
              <a
                style={{ cursor: "pointer" }}
                className={
                  rowData[4] === 1
                    ? "btn btn-success btn-sm status-act-btn"
                    : "btn btn-danger btn-sm status-act-btn"
                }
                onClick={() => activeDeactiveAlertPopup(rowData)}
              >
                {" "}
                {rowData[4] === 1 ? "Active" : "Deactive"}{" "}
              </a>
            );
          },
        },
        {
          targets: [5],
          visible: false,
        },
      ],
      destroy: true, // I think some clean up is happening here
      searching: true,
      buttons: [
        {
          extend: "excel",
          className:
            "excelButton view-btn btn text-white float-right mt-2 ml-2",
        },
      ],
      oLanguage: {
        oPaginate: {
          sPrevious:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>',
          sNext:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>',
        },
        //sInfo: "Showing page _PAGE_ of _PAGES_",
        sSearch:
          '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
        sSearchPlaceholder: "Search...",
        sLengthMenu: "Results :  _MENU_",
      },
    });
    // Extra step to do extra clean-up.
    return function () {
      table.destroy();
    };
  }, [customerList]);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Sub_Header />
      <div className="main-container" id="container">
        {loading ? <Loader /> : null}
        <div className="overlay"></div>
        <div className="search-overlay"></div>
        <SideBar />
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                <div className="widget-content widget-content-area br-6">
                  <h5 className="" style={{ margin: "10px" }}>
                    Customer List
                  </h5>
                  <div className="table-responsive mb-4 mt-4">
                    <table
                      className="display table table-hover"
                      style={{ width: "100%" }}
                      id={tableName}
                      ref={tableRef}
                    ></table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </>
  );
};
export default Customer_List;
