import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import Select from "react-select";
import withReactContent from "sweetalert2-react-content";
import Footer from "../../DashBoard/Footer";
import Header from "../../DashBoard/Header";
import Sub_Header from "../../DashBoard/Sub_Header";
import SideBar from "../../DashBoard/SideBar";
import Driver_Work_History from "./Driver_Work_History";
import Driver_Vehicle_History from "./Driver_Vehicle_History";
import Driver_Issue_History from "./Driver_Issue_History";
import Driver_Attendance_History from "./Driver_Attendance_History";
import Loader from "../../common/Loader/Loader";
import { GoogleMap, LoadScriptNext } from "@react-google-maps/api";
import { Marker, InfoWindow } from "@react-google-maps/api";
import "../../../assets/css/users/user-profile.css";
import "../../../assets/plugins/table/datatable/datatables.css";
import "../../../assets/plugins/table/datatable/dt-global_style.css";
import * as action from "../../../action/Driver/driver_action";
import * as login_action from "../../../action/Auth/login_action";
import $ from "jquery";
import { renderToString } from "react-dom/server";
import { baseURL } from "../../../service/baseService";
const View_Driver = (props) => {
  const navigate = useNavigate();
  const { driver_id } = useParams();
  const [disabled, setDisabled] = useState(true);
  const [verified, setVerified] = useState(false);
  const [editButtonHide, setEditButtonHide] = useState(false);
  const [addDocButtonHide, setAddDocButtonHide] = useState(true);
  const [cancelButtonHide, setCancelButtonHide] = useState(true);
  const [submitButtonHide, setSubmitButtonHide] = useState(true);
  const [CityList, setCityList] = useState([]);
  const dispatch = useDispatch();
  const [modalImgURL, setModalImgURL] = useState("");
  const [otherDocumentRow, setOtherDocumentRow] = useState([]);
  const [errors, setErrors] = useState({});
  const nameFocus = useRef();
  const mobileFocus = useRef();
  const addressFocus = useRef();
  const cityFocus = useRef();
  const paymentTypeFocus = useRef();
  const priceAmountFocus = useRef();
  const paymentDaysFocus = useRef();
  const profileImgFocus = useRef();
  const aadharCardFileFocus = useRef();
  const dlFileFocus = useRef();
  const panFileFocus = useRef();
  const { loading } = useSelector((state) => state.DriverEditReduces);
  const [driverLocationDetails, setDriverLocationDetails] = useState({
    latitude: 21.17024,
    longitude: 72.831062,
  });
  const [input, setInput] = useState({
    Driver_Id: "",
    name: "",
    mobile: "",
    address: "",
    paymentType: "",
    priceAmount: "",
    paymentDays: "",
    profileImg: "",
    aadharCardFile: "",
    dlFile: "",
    panFile: "",
    isActive: "",
    type: 1,
    cityId: "",
  });
  const [values, setValues] = useState({
    cityValue: "",
    // customerValue:""
  });

  const containerStyle = {
    width: "100%",
    height: "400px",
  };
  const center = { lat: 21.17024, lng: 72.831062 };
  useEffect(() => {
    dispatch(action.driver_details(driver_id))
      .then((data) => {
        let driver_details = data.data;
        setInput({
          id: driver_id,
          Driver_Id: driver_details.Driver_Id ? driver_details.Driver_Id : "",
          name: driver_details.name,
          mobile: driver_details.mobile,
          address: driver_details.address,
          paymentType: driver_details.paymentType,
          priceAmount: driver_details.priceAmount,
          paymentDays: driver_details.paymentDays
            ? driver_details.paymentDays
            : "",
          profileImg: driver_details.profileImg,
          aadharCardFile: driver_details.aadharCardFile,
          dlFile: driver_details.dlFile,
          panFile: driver_details.panFile,
          isActive: driver_details.isActive,
          type: driver_details.type,
          cityId: driver_details.cityId ? driver_details.cityId : "",
        });
        setValues({
          ...values,
          cityValue: {
            value: data.city.cityId ? data.city.cityId : "",
            label: data.city.cityName ? data.city.cityName : "",
          },
        });
        if (driver_details.isVerified) {
          setVerified(true);
        }
        let newDocList = [];
        let docCount = 1;
        data.data.otherDocuments.map((item) => {
          newDocList.push({
            id: docCount,
            docName: item.docName,
            docUrl: item.docUrl,
          });
          docCount++;
        });
        setOtherDocumentRow(newDocList);
      })
      .catch({});
  }, []);
  useEffect(() => {
    dispatch(action.get_driver_location(driver_id))
      .then((data) => {
        if (data.data) {
          let driver_location = data.data.driverData;
          setDriverLocationDetails({
            latitude: 12.0215463,
            longitude: 13.021546,
          });
        }
      })
      .catch({});
  }, []);
  useEffect(() => {
    dispatch(login_action.city_list())
      .then((data) => {
        let newCityList = [];
        data.data.map((item) => {
          newCityList.push({
            value: item._id,
            label: item.cityName,
          });
        });
        setCityList(newCityList);
      })
      .catch({});
  }, []);
  const setEditFormEditable = (type) => {
    if (type) {
      setDisabled(false);
      setAddDocButtonHide(false);
      setCancelButtonHide(false);
      setSubmitButtonHide(false);
      setEditButtonHide(true);
    } else {
      setDisabled(true);
      setAddDocButtonHide(true);
      setCancelButtonHide(true);
      setSubmitButtonHide(true);
      setEditButtonHide(false);
    }
  };
  const changeCityDropDown = (data) => {
    setValues({ ...values, cityValue: data });
    setInput({ ...input, cityId: data.value });
  };
  const customFilter = (option, inputValue) => {
    const input = inputValue.toLowerCase();
    const filterText = new RegExp("^" + input + ".*");
    const label = option.data.label.toLowerCase();
    return filterText.test(label) && option.label.toLowerCase().includes(input);
  };
  const uploadProfileImageFile = (e) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file, file.name);
    dispatch(action.driver_file_upload(formData))
      .then((data) => {
        setInput({ ...input, profileImg: data.data.url });
      })
      .catch({});
  };
  const uploadAadharCardFile = (e) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file, file.name);
    dispatch(action.driver_file_upload(formData))
      .then((data) => {
        setInput({ ...input, aadharCardFile: data.data.url });
      })
      .catch({});
  };
  const uploadDlFile = (e) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file, file.name);
    dispatch(action.driver_file_upload(formData))
      .then((data) => {
        setInput({ ...input, dlFile: data.data.url });
      })
      .catch({});
  };
  const uploadPanFile = (e) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file, file.name);
    dispatch(action.driver_file_upload(formData))
      .then((data) => {
        setInput({ ...input, panFile: data.data.url });
      })
      .catch({});
  };
  const deleteExtraDocumentsInForm = (otherDocId, docUrl) => {
    if (docUrl != "") {
      const fparr = docUrl.split("/");
      const imageName = fparr[fparr.length - 1];
      const sendData = { name: imageName };

      dispatch(action.delete_driver_file(sendData))
        .then((data) => {})
        .catch({});
    }
    setOtherDocumentRow(
      otherDocumentRow.filter((item) => item.id !== otherDocId)
    );
  };
  const uploadOtherDocFile = (e, otherDocId) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file, file.name);
    dispatch(action.driver_file_upload(formData))
      .then((data) => {
        let oldotherDocument = otherDocumentRow.filter(
          (item) => item.id === otherDocId
        )?.[0];
        oldotherDocument = { ...oldotherDocument, docUrl: data.data.url };
        let newOtherDocument = [];
        otherDocumentRow.map((item) => {
          if (item.id === otherDocId) {
            newOtherDocument.push(oldotherDocument);
          } else newOtherDocument.push(item);
        });
        setOtherDocumentRow(newOtherDocument);
      })
      .catch({});
  };
  const addExtraDocumentsInForm = () => {
    if (otherDocumentRow.length > 0) {
      setOtherDocumentRow([
        ...otherDocumentRow,
        { id: otherDocumentRow.length + 1, docName: "", docUrl: "" },
      ]);
    } else {
      setOtherDocumentRow([{ id: 1, docName: "", docUrl: "" }]);
    }
  };
  const addExtraDocumentsValue = (e, otherDocId) => {
    let oldotherDocument = otherDocumentRow.filter(
      (item) => item.id === otherDocId
    )?.[0];
    oldotherDocument = { ...oldotherDocument, [e.target.name]: e.target.value };
    let newOtherDocument = [];
    otherDocumentRow.map((item) => {
      if (item.id === otherDocId) {
        newOtherDocument.push(oldotherDocument);
      } else newOtherDocument.push(item);
    });
    setOtherDocumentRow(newOtherDocument);
  };
  const DriverVerify = () => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Verified!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(action.driver_verify(driver_id))
          .then((data) => {
            toast.success(data.msg);
            setVerified(true);
          })
          .catch((error) => {
            toast.error(error);
          });
      }
    });
  };
  const onsubmitHandler = (e) => {
    e.preventDefault();
    setErrors({});
    let customErrors = {};
    if (input.name === "") {
      customErrors = { ...customErrors, name: "Please enter your name" };
      nameFocus.current.focus();
    }
    if (!/^[6789]\d{9}$/.test(input.mobile)) {
      customErrors = {
        ...customErrors,
        mobile: "Please enter valid mobile number",
      };
      mobileFocus.current.focus();
    }
    if (input.paymentType === "") {
      customErrors = {
        ...customErrors,
        paymentType: "Please select payment type",
      };
      paymentTypeFocus.current.focus();
    }
    if (input.priceAmount === "") {
      customErrors = {
        ...customErrors,
        priceAmount: "Please enter price amount",
      };
      priceAmountFocus.current.focus();
    }
    if (input.paymentDays === "" && parseInt(input.paymentType) === 1) {
      customErrors = {
        ...customErrors,
        paymentDays: "Please enter payment days",
      };
      paymentDaysFocus.current.focus();
    }
    if (input.address === "") {
      customErrors = { ...customErrors, city: "Please select city" };
      cityFocus.current.focus();
    }
    if (input.address === "") {
      customErrors = { ...customErrors, address: "Please enter address" };
      addressFocus.current.focus();
    }
    if (input.profileImg === "") {
      customErrors = {
        ...customErrors,
        profileImg: "Please select Profile Picture",
      };
      profileImgFocus.current.focus();
    }
    if (input.aadharCardFile === "") {
      customErrors = {
        ...customErrors,
        aadharCardFile: "Please select Aadhar Card",
      };
      aadharCardFileFocus.current.focus();
    }
    if (input.dlFile === "") {
      customErrors = {
        ...customErrors,
        dlFile: "Please select Driving Licence",
      };
      dlFileFocus.current.focus();
    }
    // if(input.panFile === '') {
    //     customErrors = {...customErrors, panFile: "Please select pancard file"}
    //     panFileFocus.current.focus();
    // }

    if (Object.keys(customErrors).length > 0) {
      setErrors(customErrors);
      return true;
    }
    let documents = [];
    otherDocumentRow.map((item) => {
      documents.push({
        docName: item.docName,
        docUrl: item.docUrl,
      });
    });
    let parameter = {
      id: driver_id,
      name: input.name,
      mobile: input.mobile,
      address: input.address,
      paymentType: input.paymentType,
      priceAmount: input.priceAmount,
      paymentDays: input.paymentDays,
      profileImg: input.profileImg,
      aadharCardFile: input.aadharCardFile,
      dlFile: input.dlFile,
      panFile: input.panFile,
      isActive: parseInt(input.isActive),
      type: parseInt(input.type),
      cityId: input.cityId,
    };
    if (documents.length > 0) {
      parameter.otherDocuments = documents;
    }
    dispatch(action.edit_driver(parameter))
      .then((data) => {
        toast.success(data.msg);
        setDisabled(true);
        setAddDocButtonHide(true);
        setCancelButtonHide(true);
        setSubmitButtonHide(true);
        setEditButtonHide(false);
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Sub_Header />
      <div class="main-container" id="container">
        {loading ? <Loader /> : null}
        <div class="overlay"></div>
        <div class="search-overlay"></div>
        <SideBar />
        <div id="content" class="main-content">
          <div class="layout-px-spacing">
            <div class="row layout-top-spacing">
              <div class="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                <div class="widget-content widget-content-area br-6">
                  <div className="text-right">
                    <spam>
                      Driver Id : <strong>EV{input.Driver_Id}</strong>
                    </spam>
                    <p>
                      Driver Name : <strong>{input.name}</strong>
                    </p>
                  </div>
                  <ul
                    class="nav nav-tabs  mb-3 mt-3"
                    id="simpletab"
                    role="tablist"
                  >
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        id="view-tab"
                        data-toggle="tab"
                        href="#view"
                        role="tab"
                        aria-controls="view"
                        aria-selected="true"
                      >
                        View
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="tracking-tab"
                        data-toggle="tab"
                        href="#tracking"
                        role="tab"
                        aria-controls="tracking"
                        aria-selected="false"
                      >
                        Tracking
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="workHistory-tab"
                        data-toggle="tab"
                        href="#workHistory"
                        role="tab"
                        aria-controls="workHistory"
                        aria-selected="false"
                      >
                        Work History
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="isssueHistory-tab"
                        data-toggle="tab"
                        href="#issueHistory"
                        role="tab"
                        aria-controls="issueHistory"
                        aria-selected="false"
                      >
                        Issue History
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="vehicleHistory-tab"
                        data-toggle="tab"
                        href="#vehicleHistory"
                        role="tab"
                        aria-controls="vehicleHistory"
                        aria-selected="false"
                      >
                        Vehicle History
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="attendanceHistory-tab"
                        data-toggle="tab"
                        href="#attendanceHistory"
                        role="tab"
                        aria-controls="attendanceHistory"
                        aria-selected="false"
                      >
                        Attendance History
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="payroll-tab"
                        data-toggle="tab"
                        href="#payroll"
                        role="tab"
                        aria-controls="payroll"
                        aria-selected="false"
                      >
                        Payroll
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="payroll-list-tab"
                        data-toggle="tab"
                        href="#payrollList"
                        role="tab"
                        aria-controls="payroll-list"
                        aria-selected="false"
                      >
                        Payroll List
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content" id="simpletabContent">
                    <div
                      class="tab-pane fade show active"
                      id="view"
                      role="tabpanel"
                      aria-labelledby="view-tab"
                    >
                      <div class="input-group">
                        <div class="col-12">
                          <span
                            id="editidspan"
                            class="btn btn-warning btn-sm float-right ms-3 mb-1"
                            hidden={editButtonHide}
                            style={{ cursor: "pointer" }}
                            onClick={() => setEditFormEditable(true)}
                          >
                            Edit{" "}
                          </span>
                          <span
                            hidden={verified}
                            id="verifyidspan"
                            class="btn btn-success btn-sm float-right ms-3 mb-1 view-btn"
                            onClick={() => DriverVerify()}
                            style={{ cursor: "pointer", margin: "0 5px" }}
                          >
                            {" "}
                            Verify{" "}
                          </span>
                        </div>
                      </div>
                      <br />

                      <form onSubmit={onsubmitHandler}>
                        <div id="append-div-id">
                          <div class="form-group row">
                            <div
                              class="form-group col-md-4 required"
                              id="driverTypeMain"
                            >
                              <label>Driver Type *</label>
                              <br />
                              <div
                                class="custom-control custom-radio custom-control-inline"
                                id="driverType"
                              >
                                {parseInt(input.type) === 1 ? (
                                  <input
                                    type="radio"
                                    disabled={disabled}
                                    id="driverType1"
                                    name="driverType"
                                    className="custom-control-input"
                                    onClick={(e) =>
                                      setInput({ ...input, type: 1 })
                                    }
                                    value="1"
                                    checked
                                  />
                                ) : (
                                  <input
                                    disabled={disabled}
                                    type="radio"
                                    id="driverType1"
                                    name="driverType"
                                    className="custom-control-input"
                                    onClick={(e) =>
                                      setInput({ ...input, type: 1 })
                                    }
                                    value="1"
                                  />
                                )}
                                <label
                                  class="custom-control-label status-radio"
                                  for="driverType1"
                                >
                                  On Role
                                </label>
                              </div>
                              <div class="custom-control custom-radio custom-control-inline">
                                {parseInt(input.type) === 0 ? (
                                  <input
                                    disabled={disabled}
                                    type="radio"
                                    id="driverType0"
                                    name="driverType"
                                    className="custom-control-input"
                                    value="0"
                                    onClick={(e) =>
                                      setInput({ ...input, type: 0 })
                                    }
                                    checked
                                  />
                                ) : (
                                  <input
                                    disabled={disabled}
                                    type="radio"
                                    id="driverType0"
                                    name="driverType"
                                    className="custom-control-input"
                                    value="0"
                                    onClick={(e) =>
                                      setInput({ ...input, type: 0 })
                                    }
                                  />
                                )}
                                <label
                                  class="custom-control-label status-radio"
                                  for="driverType0"
                                >
                                  Off Role
                                </label>
                              </div>
                            </div>
                            <div class="form-group col-md-4 required">
                              <label for="inputFullname">Full Name *</label>
                              <input
                                disabled={disabled}
                                type="text"
                                class="form-control"
                                ref={nameFocus}
                                id="inputFullname"
                                name="name"
                                placeholder="Enter Full Name"
                                onChange={(e) =>
                                  setInput({ ...input, name: e.target.value })
                                }
                                value={input.name}
                              />
                              <span style={{ color: "red" }}>
                                {errors?.name}
                              </span>
                            </div>
                            <div class="form-group col-md-4 required">
                              <label for="inputMobile">Mobile Number *</label>
                              <label
                                class="form-error-msg"
                                id="mobileErrorMsg"
                              ></label>
                              <input
                                disabled={disabled}
                                ref={mobileFocus}
                                type="text"
                                class="form-control"
                                id="inputMobile"
                                name="mobile"
                                placeholder="Enter Mobile Number"
                                onChange={(e) =>
                                  setInput({ ...input, mobile: e.target.value })
                                }
                                value={input.mobile}
                              />
                              <span style={{ color: "red" }}>
                                {errors?.mobile}
                              </span>
                            </div>
                          </div>

                          <div class="form-group row">
                            <div class="form-group col-md-4 required">
                              <label for="inputPaymentType">
                                Select Payment Type *
                              </label>
                              <select
                                disabled={disabled}
                                ref={paymentTypeFocus}
                                name="inputPaymentType"
                                id="inputPaymentType"
                                class="form-control selectpicker"
                                onChange={(e) =>
                                  setInput({
                                    ...input,
                                    paymentType: e.target.value,
                                  })
                                }
                                value={input.paymentType}
                              >
                                <option value="">Select Payment Type</option>
                                <option value="1">Monthly</option>
                                <option value="2">Per Parcel</option>
                              </select>
                              <span style={{ color: "red" }}>
                                {errors?.paymentType}
                              </span>
                            </div>
                            <div class="form-group col-md-4 required">
                              <label for="inputPriceAmount">
                                Price Amount *
                              </label>
                              <input
                                disabled={disabled}
                                ref={priceAmountFocus}
                                type="number"
                                name="priceAmount"
                                class="form-control"
                                id="inputPriceAmount"
                                placeholder="Enter Price"
                                onChange={(e) =>
                                  setInput({
                                    ...input,
                                    priceAmount: e.target.value,
                                  })
                                }
                                value={input.priceAmount}
                              />
                              <span style={{ color: "red" }}>
                                {errors?.priceAmount}
                              </span>
                            </div>
                            {parseInt(input.paymentType) !== 2 ? (
                              <div class="form-group col-md-4 days-div required">
                                <label for="inputPaymentDays">Days *</label>
                                <input
                                  disabled={disabled}
                                  type="number"
                                  ref={paymentDaysFocus}
                                  name="paymentDays"
                                  class="form-control"
                                  id="inputPaymentDays"
                                  placeholder="Enter Price"
                                  max="30"
                                  min="0"
                                  onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.keyCode === 8;"
                                  onChange={(e) =>
                                    setInput({
                                      ...input,
                                      paymentDays: e.target.value,
                                    })
                                  }
                                  value={input.paymentDays}
                                />
                                <span style={{ color: "red" }}>
                                  {errors?.paymentDays}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div class="form-group row">
                            <div class="form-group col-md-4 required">
                              <label for="address">Select City *</label>
                              {/* <input disabled={disabled} ref={cityFocus} type="text" name="address" class="form-control" id="inputaddress" placeholder="Enter Address"  onChange={(e)=>setInput({...input ,  cityId:e.target.value})} value={input.address}/>
                                                            <span style={{color: 'red'}}>{errors?.city}</span> */}
                              <Select
                                id="inputCity"
                                isDisabled={disabled}
                                ref={cityFocus}
                                value={values.cityValue || "Select"}
                                onChange={(value) => changeCityDropDown(value)}
                                options={CityList}
                                filterOption={customFilter}
                              />
                              <span style={{ color: "red" }}>
                                {errors?.city}
                              </span>
                            </div>
                          </div>
                          <div class="form-group row">
                            <div class="form-group col-md-12 required">
                              <label for="address">Address *</label>
                              <input
                                disabled={disabled}
                                ref={addressFocus}
                                type="text"
                                name="address"
                                class="form-control"
                                id="inputaddress"
                                placeholder="Enter Address"
                                onChange={(e) =>
                                  setInput({
                                    ...input,
                                    address: e.target.value,
                                  })
                                }
                                value={input.address}
                              />
                              <span style={{ color: "red" }}>
                                {errors?.address}
                              </span>
                            </div>
                          </div>
                          <div class="form-group row">
                            <div class="form-group col-md-12 required">
                              <label for="profileFile">Profile Picture *</label>
                              <span
                                id="addAnotherDocIdspan"
                                hidden={addDocButtonHide}
                                class="btn btn-warning btn-sm float-right ms-3 mb-1"
                                style={{ cursor: "pointer" }}
                                onClick={() => addExtraDocumentsInForm()}
                              >
                                {" "}
                                Add Other Document{" "}
                              </span>
                              <input
                                disabled={disabled}
                                ref={profileImgFocus}
                                accept="image/jpg,image/jpeg,image/png,image/bmp"
                                multiple=""
                                type="file"
                                name="profileFile[]"
                                class="form-control p-2"
                                id="profileFile"
                                onChange={(e) => uploadProfileImageFile(e)}
                              />
                              <span style={{ color: "red" }}>
                                {errors?.profileImg}
                              </span>
                              {input.profileImg !== "" ? (
                                <img
                                  src={`${input.profileImg}`}
                                  alt="no-image"
                                  id="profileImgId"
                                  className="form-group p-2 img-responsive imgFile"
                                  onClick={() =>
                                    setModalImgURL(input.profileImg)
                                  }
                                  data-toggle="modal"
                                  data-target="#showImageModalCenter"
                                  style={{ width: "70px", height: "70px" }}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <div class="form-group row">
                            <div class="form-group col-md-12 required">
                              <label for="aadharCardFile">Aadhar Card *</label>
                              <input
                                disabled={disabled}
                                ref={aadharCardFileFocus}
                                accept="image/jpg,image/jpeg,image/png,image/bmp"
                                multiple=""
                                type="file"
                                name="aadharCardFile[]"
                                class="form-control p-2"
                                id="aadharCardFile"
                                onChange={(e) => uploadAadharCardFile(e)}
                              />
                              <span style={{ color: "red" }}>
                                {errors?.aadharCardFile}
                              </span>
                              {input.aadharCardFile !== "" ? (
                                <img
                                  src={`${input.aadharCardFile}`}
                                  alt="no-image"
                                  id="aadharCardImgId"
                                  className="form-group p-2 img-responsive imgFile"
                                  onClick={() =>
                                    setModalImgURL(input.aadharCardFile)
                                  }
                                  data-toggle="modal"
                                  data-target="#showImageModalCenter"
                                  style={{ width: "70px", height: "70px" }}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <div class="form-group row">
                            <div class="form-group col-md-12 required">
                              <label for="dlFile">Driving License *</label>
                              <input
                                disabled={disabled}
                                ref={dlFileFocus}
                                accept="image/jpg,image/jpeg,image/png,image/bmp"
                                multiple=""
                                type="file"
                                name="dlFile[]"
                                class="form-control p-2"
                                id="dlFile"
                                onChange={(e) => uploadDlFile(e)}
                              />
                              <span style={{ color: "red" }}>
                                {errors?.dlFile}
                              </span>
                              {input.dlFile !== "" ? (
                                <img
                                  src={`${input.dlFile}`}
                                  alt="no-image"
                                  id="dlImgId"
                                  className="form-group p-2 img-responsive imgFile"
                                  onClick={() => setModalImgURL(input.dlFile)}
                                  data-toggle="modal"
                                  data-target="#showImageModalCenter"
                                  style={{ width: "70px", height: "70px" }}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <div class="form-group row">
                            <div class="form-group col-md-12 required">
                              <label for="panFile">Pan Card </label>
                              <input
                                disabled={disabled}
                                ref={panFileFocus}
                                accept="image/jpg,image/jpeg,image/png,image/bmp"
                                multiple=""
                                type="file"
                                name="panFile[]"
                                class="form-control p-2"
                                id="panFile"
                                onChange={(e) => uploadPanFile(e)}
                              />
                              <span style={{ color: "red" }}>
                                {errors?.panFile}
                              </span>
                              {input.panFile !== "" ? (
                                <img
                                  src={`${input.panFile}`}
                                  alt="no-image"
                                  id="panImgId"
                                  className="form-group p-2 img-responsive imgFile"
                                  onClick={() => setModalImgURL(input.panFile)}
                                  data-toggle="modal"
                                  data-target="#showImageModalCenter"
                                  style={{ width: "70px", height: "70px" }}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          {otherDocumentRow.map((otherDocumentItem) => (
                            <>
                              <div
                                className="form-group row"
                                id={`doc${otherDocumentItem.id}`}
                              >
                                <div className="form-group col-md-4 required">
                                  <label
                                    for={`inputDocumentName${otherDocumentItem.id}`}
                                  >
                                    Document Name
                                  </label>
                                  <input
                                    disabled={disabled}
                                    type="text"
                                    className="form-control"
                                    name="docName"
                                    id={`inputDocumentName${otherDocumentItem.id}`}
                                    placeholder="Enter document name"
                                    onChange={(e) =>
                                      addExtraDocumentsValue(
                                        e,
                                        otherDocumentItem.id
                                      )
                                    }
                                    value={otherDocumentItem.docName}
                                  />
                                </div>
                                <div className="form-group col-md-8 required">
                                  <label
                                    for={`otherFile${otherDocumentItem.id}`}
                                  >
                                    Document File{" "}
                                  </label>
                                  <span
                                    className="badge badge-danger float-right"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      deleteExtraDocumentsInForm(
                                        otherDocumentItem.id,
                                        otherDocumentItem.docUrl
                                      )
                                    }
                                    id={`delethisidspan${otherDocumentItem.id}`}
                                  >
                                    {" "}
                                    Delete This
                                  </span>
                                  <input
                                    disabled={disabled}
                                    accept="image/jpg,image/jpeg,image/png,image/bmp"
                                    multiple=""
                                    type="file"
                                    className="form-control p-2"
                                    id={`otherFile${otherDocumentItem.id}`}
                                    onChange={(e) =>
                                      uploadOtherDocFile(
                                        e,
                                        otherDocumentItem.id
                                      )
                                    }
                                  />
                                  {otherDocumentItem.docUrl !== "" ? (
                                    <img
                                      src={`${otherDocumentItem.docUrl}`}
                                      alt="no-image"
                                      id={`otherImgId${otherDocumentItem.id}`}
                                      className="form-group p-2 img-responsive imgFile"
                                      onClick={() =>
                                        setModalImgURL(otherDocumentItem.docUrl)
                                      }
                                      data-toggle="modal"
                                      data-target="#showImageModalCenter"
                                      style={{ width: "70px", height: "70px" }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </>
                          ))}
                          <div
                            class="form-group required"
                            id="driverStatusMain"
                          >
                            <label>Driver Status *</label>
                            <div
                              class="custom-control custom-radio custom-control-inline"
                              id="driverStatus"
                            >
                              {parseInt(input.isActive) === 1 ? (
                                <input
                                  disabled={disabled}
                                  type="radio"
                                  id="status1"
                                  name="driverStatus"
                                  className="custom-control-input"
                                  onClick={(e) =>
                                    setInput({
                                      ...input,
                                      isActive: e.target.value,
                                    })
                                  }
                                  value="1"
                                  checked
                                />
                              ) : (
                                <input
                                  disabled={disabled}
                                  type="radio"
                                  id="status1"
                                  name="driverStatus"
                                  className="custom-control-input"
                                  onClick={(e) =>
                                    setInput({
                                      ...input,
                                      isActive: e.target.value,
                                    })
                                  }
                                  value="1"
                                />
                              )}
                              <label
                                class="custom-control-label status-radio"
                                for="status1"
                              >
                                Active
                              </label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                              {parseInt(input.isActive) === 0 ? (
                                <input
                                  disabled={disabled}
                                  type="radio"
                                  id="status0"
                                  name="driverStatus"
                                  className="custom-control-input"
                                  value="0"
                                  onClick={(e) =>
                                    setInput({
                                      ...input,
                                      isActive: e.target.value,
                                    })
                                  }
                                  checked
                                />
                              ) : (
                                <input
                                  disabled={disabled}
                                  type="radio"
                                  id="status0"
                                  name="driverStatus"
                                  className="custom-control-input"
                                  value="0"
                                  onClick={(e) =>
                                    setInput({
                                      ...input,
                                      isActive: e.target.value,
                                    })
                                  }
                                />
                              )}
                              <label
                                class="custom-control-label status-radio"
                                for="status0"
                              >
                                Deactive
                              </label>
                            </div>
                          </div>

                          <div
                            className="modal fade"
                            id="showImageModalCenter"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="showImageModalCenterLabel"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered modal-lg"
                              role="img"
                            >
                              <div className="modal-content">
                                <div className="modal-body">
                                  {modalImgURL !== "" ? (
                                    <img
                                      src={`${modalImgURL}`}
                                      alt="no-image"
                                      id="modalImgId"
                                      className="img-responsive"
                                      style={{ width: "100%", height: "100%" }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group" id="saveAndCancelButtons">
                          <button
                            type="reset"
                            onClick={() => setEditFormEditable(false)}
                            hidden={cancelButtonHide}
                            class="btn btn-danger mt-3"
                          >
                            Cancel
                          </button>
                          &nbsp;
                          <button
                            hidden={submitButtonHide}
                            type="submit"
                            class="btn btn-success mt-3 view-btn"
                          >
                            Update
                          </button>
                        </div>
                      </form>
                    </div>
                    <div
                      class="tab-pane fade show"
                      id="tracking"
                      role="tabpanel"
                      aria-labelledby="tracking-tab"
                    >
                      <div class="row">
                        <div class="form-group col-sm-12">
                          <LoadScriptNext googleMapsApiKey="AIzaSyB6PMCguHWiTCN0nLcEasXVxUmWOpHS5gY">
                            <GoogleMap
                              mapContainerStyle={containerStyle}
                              center={{
                                lat: driverLocationDetails.latitude,
                                lng: driverLocationDetails.longitude,
                              }}
                              zoom={11}
                            >
                              <Marker
                                visible={true}
                                zIndex={1111}
                                position={{
                                  lat: driverLocationDetails.latitude,
                                  lng: driverLocationDetails.longitude,
                                }}
                              ></Marker>
                            </GoogleMap>
                          </LoadScriptNext>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade show"
                      id="workHistory"
                      role="tabpanel"
                      aria-labelledby="workHistory-tab"
                    >
                      <Driver_Work_History driver_id={driver_id} />
                    </div>
                    <div
                      class="tab-pane fade show"
                      id="issueHistory"
                      role="tabpanel"
                      aria-labelledby="issueHistory-tab"
                    >
                      <Driver_Issue_History driver_id={driver_id} />
                    </div>
                    <div
                      class="tab-pane fade show"
                      id="vehicleHistory"
                      role="tabpanel"
                      aria-labelledby="vehicleHistory-tab"
                    >
                      <Driver_Vehicle_History driver_id={driver_id} />
                    </div>
                    <div
                      class="tab-pane fade show"
                      id="attendanceHistory"
                      role="tabpanel"
                      aria-labelledby="attendanceHistory-tab"
                    >
                      <Driver_Attendance_History driver_id={driver_id} />
                    </div>
                    <div
                      class="tab-pane fade show"
                      id="payroll"
                      role="tabpanel"
                      aria-labelledby="payroll-tab"
                    >
                      <div class="form-group row col-md-12">
                        <select
                          name="inputPayrollFilter"
                          id="inputPayrollFilter"
                          class="selectpicker"
                          data-width="160px"
                          onchange="refreshListByFilter(event, 'inputPayrollFilter', 'payrollDateFilter');"
                        >
                          <option value="0">Current Month</option>
                          <option value="1">Jan</option>
                          <option value="2">Feb</option>
                          <option value="3">March</option>
                          <option value="4">Apr</option>
                          <option value="5">May</option>
                          <option value="6">Jun</option>
                          <option value="7">Jul</option>
                          <option value="8">Aug</option>
                          <option value="9">Sep</option>
                          <option value="10">Oct</option>
                          <option value="11">Nov</option>
                          <option value="12">Dec</option>
                        </select>
                        <div class="form-group" id="">
                          <button
                            type="submit"
                            class="btn btn-success mt-2 ml-3 view-btn"
                            onclick="generatePayroll(event)"
                          >
                            Generate Payout
                          </button>
                        </div>
                      </div>
                      <div id="paymentDetailsMonthlyId">
                        <div id="paymentDetailsMonthlyExportDivId">
                          <div class="mt-5 mb-5 invisible">Payroll Details</div>
                          <table
                            id="driver-payroll"
                            class="table table-hover payroll-table"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th class="col-6">Driver Name</th>
                                <th class="col-6" id="driverNameId">
                                  Values
                                </th>
                              </tr>
                              <tr>
                                <th class="col-6">Month</th>
                                <th class="col-6" id="monthYearId"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>Total Working Days</th>
                                <td id="totalWorkingDaysId"></td>
                              </tr>
                              <tr>
                                <th>Driver Working Days</th>
                                <td id="driverWorkingDaysId"></td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <th>Payment (INR)</th>
                                <th id="paymentId"></th>
                              </tr>
                              <tr>
                                <th>Overtime Payment (INR)</th>
                                <th id="overtimeId"></th>
                              </tr>
                              <tr id="extraPaymentMain" hidden>
                                <th id="extraPaymentKeyId" contenteditable>
                                  Enter Extra Payment For
                                </th>
                                <th
                                  id="extraPaymentId"
                                  contenteditable
                                  onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.keyCode === 8;"
                                  onkeyup="updatePaymentvaluesInPayroll('extraPaymentMain')"
                                >
                                  0
                                </th>
                              </tr>
                              <tr id="extraDeductionMain" hidden>
                                <th id="extraDeductionKeyId">
                                  Deduction (INR)
                                </th>
                                <th
                                  id="extraDeductionId"
                                  contenteditable
                                  onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.keyCode === 8;"
                                  onkeyup="updatePaymentvaluesInPayroll('extraPaymentMain')"
                                >
                                  0
                                </th>
                              </tr>
                              <tr>
                                <th>Total Payment (INR)</th>
                                <th id="totalPaymentId"></th>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                        <div class="form-group" id="exportButtons">
                          <button
                            id="exportPdfBtn"
                            type="button"
                            class="btn btn-dark mt-3"
                            data-toggle="modal"
                            data-target="#payrollModalCenter"
                            onclick="getDriverLatestPayrollNumber('paymentDetailsMonthlyExportDivId')"
                          >
                            Export PDF
                          </button>
                          <button
                            id="showExtraPaymentBtn"
                            type="button"
                            class="btn btn-warning mt-3"
                            onclick="showExtraPaymentRow('extraPaymentMain')"
                          >
                            Update Extra Payment
                          </button>
                          <button
                            id="saveExtraPaymentBtn"
                            type="button"
                            class="btn btn-warning mt-3"
                            onclick="saveExtraPaymentData('extraPaymentMain')"
                            hidden
                          >
                            Save Extra Payment
                          </button>
                          <button
                            id="cancelExtraPaymentBtn"
                            type="button"
                            class="btn mt-3"
                            onclick="hideExtraPaymentRow('extraPaymentMain')"
                            hidden
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                      <div id="paymentDetailsIdPerParcel">
                        <div id="paymentDetailsPerParcelExportDivId">
                          <div class="mt-5 mb-5 invisible">Payroll Details</div>
                          <table
                            id="driver-payroll-per-parcel"
                            class="table table-hover payroll-table"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th class="col-6">Driver Name</th>
                                <th class="col-6" id="driverNameIdPerParcel">
                                  Values
                                </th>
                              </tr>
                              <tr>
                                <th class="col-6">Month</th>
                                <th
                                  class="col-6"
                                  id="monthYearIdPerParcel"
                                ></th>
                              </tr>
                            </thead>
                            <tbody></tbody>
                            <tfoot>
                              <tr>
                                <th>Payment (INR)</th>
                                <th id="paymentIdPerParcel"></th>
                              </tr>
                              <tr>
                                <th>Overtime Payment (INR)</th>
                                <th id="overtimeIdPerParcel"></th>
                              </tr>
                              <tr id="extraPaymentMainPerParcel" hidden>
                                <th
                                  id="extraPaymentKeyIdPerParcel"
                                  contenteditable
                                >
                                  Enter Extra Payment For
                                </th>
                                <th
                                  id="extraPaymentIdPerParcel"
                                  contenteditable
                                  onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.keyCode === 8;"
                                  onkeyup="updatePaymentvaluesInPayroll('extraPaymentMainPerParcel')"
                                >
                                  0
                                </th>
                              </tr>
                              <tr id="extraDeductionMainPerParcel" hidden>
                                <th id="extraDeductionKeyIdPerParcel">
                                  Deduction (INR)
                                </th>
                                <th
                                  id="extraDeductionIdPerParcel"
                                  contenteditable
                                  onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.keyCode === 8;"
                                  onkeyup="updatePaymentvaluesInPayroll('extraPaymentMainPerParcel')"
                                >
                                  0
                                </th>
                              </tr>
                              <tr>
                                <th>Total Payment (INR)</th>
                                <th id="totalPaymentIdPerParcel"></th>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                        <div class="form-group" id="exportButtons">
                          <button
                            id="exportPdfBtnPerParcel"
                            type="button"
                            class="btn btn-dark mt-3"
                            data-toggle="modal"
                            data-target="#payrollModalCenter"
                            onclick="getDriverLatestPayrollNumber('paymentDetailsPerParcelExportDivId')"
                          >
                            Export PDF
                          </button>
                          <button
                            id="showExtraPaymentBtnPerParcel"
                            type="button"
                            class="btn btn-warning mt-3"
                            onclick="showExtraPaymentRow('extraPaymentMainPerParcel')"
                          >
                            Update Extra Payment
                          </button>
                          <button
                            id="saveExtraPaymentBtnPerParcel"
                            type="button"
                            class="btn btn-warning mt-3"
                            onclick="saveExtraPaymentData('extraPaymentMainPerParcel')"
                            hidden
                          >
                            Save Extra Payment
                          </button>
                          <button
                            id="cancelExtraPaymentBtnPerParcel"
                            type="button"
                            class="btn mt-3"
                            onclick="hideExtraPaymentRow('extraPaymentMainPerParcel')"
                            hidden
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade show"
                      id="payrollList"
                      role="tabpanel"
                      aria-labelledby="payroll-list-tab"
                    >
                      <div
                        class="form-group p-2 list-filter"
                        id="list-filter-id"
                      >
                        <label for="inputPayrollStatusSelect" class="ml-1">
                          Status
                        </label>
                        <select
                          name="inputPayrollStatusSelect"
                          id="inputPayrollStatusSelect"
                          class="form-control selectpicker ml-2"
                          data-width="120px"
                          onchange="refreshPayrollListByStatus(event);"
                        >
                          <option value="2" selected>
                            All
                          </option>
                          <option value="1">Paid</option>
                          <option value="0">Pending</option>
                        </select>
                      </div>
                      <div class="table-responsive mb-4 mt-4">
                        <table
                          id="driver-payroll-list"
                          class="table table-hover"
                          style={{ width: "100%" }}
                        >
                          <thead>
                            <tr>
                              <th>Payroll</th>
                              <th>Payroll Month</th>
                              <th>Generated Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody></tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div
                    class="modal fade"
                    id="payrollModalCenter"
                    role="dialog"
                    aria-labelledby="payrollModalCenterTitle"
                    style={{ display: "none" }}
                    aria-hidden="true"
                  >
                    <div
                      class="modal-dialog modal-dialog-centered modal-lg"
                      role="document"
                    >
                      <div class="modal-content">
                        <form>
                          <div class="modal-header">
                            <h5
                              class="modal-title"
                              id="payrollModalCenterTitle"
                            >
                              Payroll
                            </h5>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <svg
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                class="feather feather-x"
                              >
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                              </svg>
                            </button>
                          </div>

                          <div class="modal-body">
                            <div
                              class="customer-payroll-class"
                              id="customer-payroll-div-id"
                            >
                              <div class="form-group" id="defaultpayrolldiv">
                                <div class="form-group col-md-6">
                                  <label for="inputPayrollNumber">
                                    Payroll Number *
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="inputPayrollNumber"
                                    placeholder="Enter Payroll Number"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.keyCode === 8;"
                                    maxlength="20"
                                    required
                                  />
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="inputPayrollDate">
                                    Payroll Date *
                                  </label>
                                  <input
                                    id="inputPayrollDate"
                                    class="form-control flatpickr flatpickr-input active"
                                    type="text"
                                    placeholder="Select Date.."
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="modal-footer">
                            <button class="btn" data-dismiss="modal">
                              <i class="flaticon-cancel-12"></i>Discard
                            </button>
                            <button
                              type="button"
                              id="downloadPayrollBtn"
                              class="btn btn-primary view-btn"
                              onclick="addDriverPayroll(event)"
                            >
                              Download
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    class="modal fade"
                    id="markAsPaidModalCenter"
                    role="dialog"
                    aria-labelledby="markAsPaidModalCenterTitle"
                    style={{ display: "none" }}
                    aria-hidden="true"
                  >
                    <div
                      class="modal-dialog modal-dialog-centered modal-lg"
                      role="document"
                    >
                      <div class="modal-content">
                        <form>
                          <div class="modal-header">
                            <h5
                              class="modal-title"
                              id="markAsPaidModalCenterTitle"
                            >
                              Mark As Payment Paid
                            </h5>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <svg
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                class="feather feather-x"
                              >
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                              </svg>
                            </button>
                          </div>

                          <div class="modal-body">
                            <div
                              class="customer-markaspaid-class"
                              id="customer-markaspaid-div-id"
                            >
                              <div class="form-group" id="defaultmarkaspaiddiv">
                                <div class="form-group row">
                                  <div class="form-group col-md-12">
                                    <label
                                      class="form-group form-error-msg"
                                      id="payrollPaymentCategotyErrorMsg"
                                    ></label>
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="inputPaymentDate">
                                      Payment Date *
                                    </label>
                                    <input
                                      id="inputPaymentDate"
                                      class="form-control flatpickr flatpickr-input active"
                                      type="text"
                                      placeholder="Select Date.."
                                      required
                                    />
                                  </div>
                                  <div class="form-group col-md-6 required">
                                    <label for="inputBankAccount">
                                      Bank Account *
                                    </label>
                                    <div class="form-group">
                                      <select
                                        name="inputBankAccount"
                                        id="inputBankAccount"
                                        class="form-control selectpicker"
                                        required
                                      ></select>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group row">
                                  <div class="form-group col-md-6">
                                    <label for="inputPaymentMethod">
                                      Payment Method *
                                    </label>
                                    <select
                                      name="inputPaymentMethod"
                                      id="inputPaymentMethod"
                                      class="form-control selectpicker"
                                      onchange="updateMarkAsPaidFieldsOnPaymentMethodUpdate(this.id)"
                                      required
                                    >
                                      <option value="CHEQUE">CHEQUE</option>
                                      <option value="RTGS">RTGS</option>
                                      <option value="NEFT">NEFT</option>
                                      <option value="CASH">CASH</option>
                                      <option value="CARD">CARD</option>
                                      <option value="ONLINE">ONLINE</option>
                                    </select>
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="inputChequeNo">
                                      Cheque Number
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="inputChequeNo"
                                      placeholder="Enter Cheque No"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="modal-footer">
                            <button class="btn" data-dismiss="modal">
                              <i class="flaticon-cancel-12"></i>Discard
                            </button>
                            <button
                              type="button"
                              id="markAsPaymentPaidBtn"
                              class="btn btn-primary view-btn"
                              onclick="markAsPaymentPaid(event)"
                            >
                              Paid
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </>
  );
};
export default View_Driver;
